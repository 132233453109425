import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './SelectPlatformReferral.module.css';

interface Props {
  setValue: any;
  label: string;
  placeholder: string;
}
const SelectPlatformReferral = ({ label, placeholder, setValue }: Props) => {
  const [platformReferral, setPlatformReferral] = useState('');
  const [input, setInput] = useState('');
  const [t] = useTranslation('global');
  const options = [
    { value: 'TikTok', text: t('register.platformReferral.option1') },
    { value: 'Instagram', text: t('register.platformReferral.option2') },
    { value: 'Facebook', text: t('register.platformReferral.option3') },
    { value: 'Google Search', text: t('register.platformReferral.option4') },
    {
      value: 'Linkedin Job Opportunity',
      text: t('register.platformReferral.option5'),
    },
    {
      value: 'Linkedin Employee Publication',
      text: t('register.platformReferral.option6'),
    },
    {
      value: 'I was referred by a friend',
      text: t('register.platformReferral.option7'),
    },
    {
      value: 'I was contacted by Simera`s HR team',
      text: t('register.platformReferral.option8'),
    },
    {
      value: 'I was referred by a university',
      text: t('register.platformReferral.option9'),
    },
    { value: 'Other', text: t('register.platformReferral.option10') },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (
      event.target.value === 'Other' ||
      event.target.value === 'I was referred by a university'
    ) {
      setInput('');
    }
    setValue('platformReferral', event.target.value);
    setPlatformReferral(event.target.value);
  };
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      platformReferral === 'Other' ||
      platformReferral === 'I was referred by a university'
    ) {
      setValue(
        'platformReferral',
        `${platformReferral} - ${event.target.value}`
      );
    }
    setInput(event.target.value);
  };
  return (
    <div className={classes.Container}>
      <p className={classes.Ask}>{label}</p>
      <select
        className={classes.Select}
        value={platformReferral}
        onChange={handleChange}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          );
        })}
      </select>
      {(platformReferral === 'Other' ||
        platformReferral === 'I was referred by a university') && (
        <input
          className={classes.Input}
          type="text"
          placeholder={placeholder}
          value={input}
          onChange={handleChangeInput}
        />
      )}
    </div>
  );
};

export default SelectPlatformReferral;
