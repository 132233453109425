import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { jobInterestsDefaultValue } from '../../../../constants/formDefaultValues';
import { FormTypes } from '../../../../types/user_info';
import { errorHandler } from '../../../utils';
import {
  CandidateJobInterestBuilder,
  GetCandidateJobInterestInput,
  GetCandidateJobInterestOutput,
} from '../candidate-job-interest.types';

export const getCandidateJobInterest = (builder: CandidateJobInterestBuilder) =>
  builder.query<GetCandidateJobInterestOutput, GetCandidateJobInterestInput>({
    query: ({ candidateId }) => ({
      url: `/candidate-job-interest/${candidateId}`,
      method: 'GET',
    }),
    transformResponse: (response: FormTypes['jobInterest']) => {
      if (!response) {
        return jobInterestsDefaultValue;
      }
      return response;
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setJobInterestAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        false
      );
    },
    providesTags: (result) =>
      result
        ? [
            { type: 'CandidateJobInterest', id: result.Id },
            { type: 'CandidateJobInterest', id: 'LIST' },
          ]
        : [{ type: 'CandidateJobInterest', id: 'LIST' }],
  });
