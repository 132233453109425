import { CandidateData } from '../../../../types/candidates';

const filterByLanguages = (
  singleCandidate: CandidateData,
  searchedLanguages: string[]
) => {
  return (
    (searchedLanguages &&
      searchedLanguages.every((searchedLanguage: string) => {
        return (
          singleCandidate?.CandidateLanguages &&
          singleCandidate?.CandidateLanguages?.some((candidateLanguage) => {
            return (
              candidateLanguage.LanguageName &&
              candidateLanguage.LanguageName.toLowerCase() ===
                searchedLanguage.toLowerCase()
            );
          })
        );
      })) ??
    false
  );
};

export default filterByLanguages;
