import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  AddCandidateExtracurricularsInput,
  AddCandidateExtracurricularsOutput,
  CandidateExtracurricularBuilder,
} from '../candidate-extracurricular.types';

export const addCandidateExtracurriculars = (
  builder: CandidateExtracurricularBuilder
) =>
  builder.mutation<
    AddCandidateExtracurricularsOutput,
    AddCandidateExtracurricularsInput
  >({
    query: ({ data }) => ({
      url: `/candidate-extracurricular/add-range`,
      method: 'POST',
      body: data.map((extra) => ({
        Id: extra?.Id,
        ExtracurricularType: extra?.ExtracurricularType.val,
        ExtracurricularDetail: extra?.ExtracurricularDetail.val,
      })),
    }),
    invalidatesTags: [
      { type: 'candidateExtracurriculars', id: 'LIST' },
      'Score',
    ],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setExtracurricularsAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
