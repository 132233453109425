const formatCurrency = (value: string | number) => {
  if (!Number.isNaN(Number(value))) {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  return value;
};

export default formatCurrency;
