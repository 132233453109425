import { Link } from 'react-router-dom';
import { ReactComponent as LeftArrow } from '../../icons/LeftArrow.svg';
import classes from './BackButton.module.css';

const BackButton = () => (
  <Link className={classes.BackButton} to="/home">
    <LeftArrow data-testid="left-arrow" />
    Home
  </Link>
);

export default BackButton;
