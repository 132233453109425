import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  SavePositionInput,
  SavePositionOutput,
} from '../companies.types';

export const editPositionInternal = (builder: CompanyBuilder) =>
  builder.mutation<SavePositionOutput, SavePositionInput>({
    query: ({ position }) => ({
      url: '/positions',
      method: 'POST',
      body: position,
    }),
    transformResponse: (queryResponse: SavePositionOutput, _, arg) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEs.EditPosition
          : SuccessMessageEn.EditPosition,
        true
      );
      return queryResponse;
    },
    transformErrorResponse: (error, meta, arg) => {
      errorHandler(
        '[savePosition]',
        arg.lang === 'en'
          ? ErrorMessageEn.PositionSaveError
          : ErrorMessageEs.PositionSaveError,
        error,
        true
      );
      return error.data;
    },
  });
