import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditableContainer from '../EditableContainer/EditableContainer';
import getOsIcon from '../../utils/getOSIcon';
import classes from './TechnicalInfoEditableContainer.module.css';
import TechnicalInformationFormContainer from '../TechnicalInformation/TechnicalInformationFormContainer';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useGetCandidateTechnicalInfoQuery } from '../../store/modules/candidate-technical-info';

interface Props {
  isActive: boolean;
}

const TechnicalInfoEditableContainer: FC<Props> = ({ isActive }) => {
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [show, setShow] = useState(false);
  const [t, i18n] = useTranslation('global');
  const isMobile = useWindowWidth() <= 650;
  const candidateId: number = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;
  const { data } = useGetCandidateTechnicalInfoQuery({
    candidateId,
    lang: i18n.language as 'en' | 'es',
  });

  const handlePencil = () => {
    if (isMobile && window) {
      window.scrollTo(0, 0);
    }
    setIsEdit(false);
  };
  const handleCancel = () => {
    setShow(true);
  };
  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    setShow(false);
  };
  const Icon = getOsIcon(data?.OperatingSystem!);
  return (
    <EditableContainer
      title={t('formNames.technical')}
      isEditable={isEdit}
      onClickPencil={handlePencil}
      isActive={isActive}
    >
      {!isEdit ? (
        <TechnicalInformationFormContainer
          isEdit
          setEdit={setIsEdit}
          onClickCancel={handleCancel}
          onClickCancelModal={handleCancelModal}
          show={show}
          data={data}
        />
      ) : (
        data && (
          <div className={classes.container}>
            <div className={classes.header}>
              <div className={classes.icon}>
                <Icon fill="#232428" />
              </div>
              <p>{data?.OperatingSystem}</p>
            </div>
            <div className={classes.content}>
              <ul>
                <li>{`${t('technical.cpu')}: ${data?.CPUModel}`}</li>
                <li>{`${t('technical.ram')}: ${data?.RAM}`}</li>
                <li>{`${t('technical.storage')}: ${data?.Storage}`}</li>
                <li>{`${t('technical.space')}: ${data?.AvailableSpace}`}</li>
              </ul>
            </div>
          </div>
        )
      )}
    </EditableContainer>
  );
};

export default TechnicalInfoEditableContainer;
