import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import EditableContainer from '../EditableContainer/EditableContainer';
import LanguageReadOnly from './LanguageReadOnly';
import LanguageFormContainer from '../LanguageForm/LanguageFormContainer';
import useWindowWidth from '../../hooks/useWindowWidth';
import NewLanguage from './NewLanguage';
import { useGetCandidateLanguagesQuery } from '../../store/modules/candidate-language';
import CardSkeleton from '../Positions/CandidateSummaryCardList/CardSkeleton/CardSkeleton';

interface LanguageEditableContainerProps {
  isActive: boolean;
}

const LanguageEditableContainer: FC<LanguageEditableContainerProps> = ({
  isActive,
}) => {
  const [t, i18n] = useTranslation('global');

  const [isEdit, setIsEdit] = useState(true);
  const [show, setShow] = useState(false);
  const [newLanguage, setNewLanguage] = useState(false);

  const candidateId: number = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;

  const { data, isLoading } = useGetCandidateLanguagesQuery({
    candidateId,
    lang: i18n.language as 'en' | 'es',
  });

  const isMobile = useWindowWidth() <= 650;

  const onClickPencil = () => {
    if (isMobile && window) {
      window.scrollTo(0, 0);
    }
    setIsEdit(false);
  };

  const onClickCancel = () => {
    setShow(true);
  };
  const onClickCancelModal = (isCancel: boolean, continueEditing: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    if (newLanguage && continueEditing) {
      setNewLanguage(false);
    }
    setShow(false);
  };

  const onClickSave = () => {
    setNewLanguage(false);
  };
  const onClickPlus = () => {
    setNewLanguage(!newLanguage);
  };

  const newLangOrField = () => {
    if (isLoading)
      return <CardSkeleton hasProfilePicture={false} rows={2} padding={0} />;

    if (newLanguage) {
      return (
        <NewLanguage
          onClickCancel={onClickCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={onClickCancelModal}
          show={show}
          onClickSave={onClickSave}
          newLanguage
          countForms={data?.length || 1}
        />
      );
    }
    return data?.map((lang, idx) => (
      <LanguageReadOnly
        key={`languageEditable-${lang?.Id}`}
        language={lang}
        isLastOne={idx === data.length - 1}
      />
    ));
  };

  return (
    <EditableContainer
      title={t('formNames.languages')}
      isEditable={isEdit}
      onClickPencil={onClickPencil}
      onClickPlus={onClickPlus}
      data-testid="language-editableForm"
      newEdit={newLanguage}
      hasAddMore
      addMore={newLanguage ? t('formNames.language') : false}
      isActive={isActive}
    >
      {!isEdit ? (
        <LanguageFormContainer
          onClickCancel={onClickCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={onClickCancelModal}
          show={show}
        />
      ) : (
        newLangOrField()
      )}
    </EditableContainer>
  );
};

export default LanguageEditableContainer;
