import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseBlue } from '$icons/CloseBlue.svg';
import { ReactComponent as CircleDot } from '$icons/CircleDot.svg';
import { ReactComponent as ArrowRight } from '$icons/ArrowRight.svg';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';

interface ModalCandidateActionsProps {
  closeModal: () => void;
  handleOk: () => void;
  candidateFirstName?: string;
  candidateLastName?: string;
  lastJob: {
    title: string;
    company: string | null;
  };
  score: number;
  img?: string;
  type: 'interview' | 'reject' | 'shortlist';
}

const ModalCandidateActions: React.FC<ModalCandidateActionsProps> = ({
  closeModal,
  handleOk,
  lastJob,
  score,
  img,
  type,
  candidateFirstName,
  candidateLastName,
}) => {
  const [t] = useTranslation('global');
  return (
    <div className="bg-white w-[calc(100%-40px)] sm:w-[600px] font-sans mt-auto mb-5 sm:mb-auto">
      <div className="flex justify-between items-center gap-2 border-b-[1px] border-[#E6F0F4] p-6 pb-[17px]">
        <h1 className="text-lg sm:text-[20px] font-semibold text-center sm:text-start">
          {type === 'interview' && t('modalCandidateActions.titleInterview')}
          {type === 'reject' && t('modalCandidateActions.titleReject')}
          {type === 'shortlist' && t('modalCandidateActions.titleShortlist')}
        </h1>
        <CloseBlue
          onClick={closeModal}
          className="hidden sm:block cursor-pointer w-12 h-12 sm:w-6 sm:h-6"
        />
      </div>
      <div className="p-6 items-center flex flex-col gap-8 w-full">
        <div className="flex justify-center sm:justify-between items-center w-full">
          <div className="flex gap-4 flex-col sm:flex-row items-center">
            <div className="w-[60px] h-[60px]">
              <DefaultIcon2
                picture={img}
                firstName={candidateFirstName}
                lastName={candidateLastName}
              />
            </div>
            <div className="flex flex-col gap-1 text-brand-darkgrey">
              <p className="text-xl font-semibold text-center sm:text-start">
                {`${candidateFirstName} ${candidateLastName}`}
              </p>
              <p className="font-normal text-center sm:text-start">
                {lastJob?.title || 'N/A'}
                {lastJob.company ? (
                  <>
                    <CircleDot className="inline-block align-middle mx-2 h-1 w-1" />
                    {lastJob.company}
                  </>
                ) : (
                  ''
                )}
              </p>
            </div>
          </div>
          <div className="hidden bg-tags-green10 text-tags-green20 px-3 py-2 rounded-xl gap-3 font-semibold items-center">
            <p className="hidden sm:block">Demand Score</p>
            <p className="text-2xl">{score}</p>
          </div>
        </div>
        <div className="flex gap-4 justify-end w-full flex-col-reverse sm:flex-row">
          <button
            type="button"
            className="h-12 px-4 font-semibold text-brand-blue100"
            onClick={() => closeModal()}
          >
            {t('modalCandidateActions.notNow')}
          </button>
          <button
            type="button"
            onClick={() => {
              handleOk();
              closeModal();
            }}
            className="h-12 rounded font-semibold text-white flex justify-center items-center gap-2 px-4 bg-brand-blue100"
          >
            <p>
              {type === 'interview' &&
                t('modalCandidateActions.buttonInterview')}
              {type === 'reject' && t('modalCandidateActions.buttonReject')}
              {type === 'shortlist' &&
                t('modalCandidateActions.buttonShortlist')}
            </p>
            <ArrowRight className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

ModalCandidateActions.defaultProps = {
  img: '',
  candidateFirstName: '',
  candidateLastName: '',
};

export default ModalCandidateActions;
