import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { educationalInformationDefaultValues } from '../../../../constants/formDefaultValues';
import { FormTypes } from '../../../../types/user_info';
import { errorHandler } from '../../../utils';
import {
  CandidateEducationBuilder,
  GetCandidateEducationInput,
  GetCandidateEducationOutput,
} from '../candidate-education.types';

export const getCandidateEducation = (builder: CandidateEducationBuilder) =>
  builder.query<GetCandidateEducationOutput, GetCandidateEducationInput>({
    query: ({ candidateId }) => ({
      url: `/candidate-education/${candidateId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map((edu) => ({
              type: 'candidatesEducation' as const,
              id: edu?.Id,
            })),
            { type: 'candidatesEducation', id: 'LIST' },
          ]
        : [{ type: 'candidatesEducation', id: 'LIST' }],

    transformResponse: (response: FormTypes['educationBackendPayload'][]) => {
      if (response.length === 0) {
        return educationalInformationDefaultValues;
      }

      return response.map((edu) => ({
        Id: edu?.Id,
        DegreeType: { val: edu?.DegreeType },
        SchoolName: { val: edu?.EducationalInstitutionName },
        DegreeName: { val: edu?.DegreeName },
        EducationalInstitutionName: {
          val: edu?.EducationalInstitutionName,
        },
        YearGraduated: {
          val: edu?.FinishDate
            ? `${new Date(edu?.FinishDate).getFullYear()}`
            : undefined,
        },
        Enrolled: edu?.CurrentlyEnrolled,
      }));
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setEducationalInfoAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
