import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MakeOfferToCandidateDto from '../../../../../../types/serverTypes/makeOfferToCandidateDto';
import classes from './UpdateToOfferSentConfirmModalContent.module.css';
import { useUpdateCandidatePositionStatusToOfferSentMutation } from '../../../../../../store/modules/candidates';

type Props = {
  handleClose: (show: boolean) => void;
  makeOfferToCandidateDto: MakeOfferToCandidateDto;
};

const UpdateToOfferSentConfirmModalContent: FC<Props> = ({
  handleClose,
  makeOfferToCandidateDto,
}) => {
  const [t, i18n] = useTranslation('global');
  const [
    updateCandidatePositionStatusToOfferSentAsCandidate,
    { isSuccess, isError, isLoading },
  ] = useUpdateCandidatePositionStatusToOfferSentMutation();

  useEffect(() => {
    if (isSuccess || isError) {
      handleClose(false);
    }
  }, [handleClose, isSuccess, isError]);

  const handleConfirm = async () => {
    updateCandidatePositionStatusToOfferSentAsCandidate({
      data: makeOfferToCandidateDto,
      lang: i18n.language as 'en' | 'es',
    });
  };

  return (
    <>
      <strong>{t('MakeOfferModal.ConfirmTitle')}</strong>
      <p>{t('MakeOfferModal.ComfirmText')}</p>
      <p>{t('MakeOfferModal.ConfirmSubText')}</p>
      <div className={classes.actions}>
        <button
          type="button"
          className={classes.normalButton}
          onClick={() => handleClose(false)}
        >
          {t('MakeOfferModal.ConfirmBack')}
        </button>
        <button
          disabled={isLoading}
          type="button"
          onClick={handleConfirm}
          className={classes.ConfirmBtn}
        >
          {t('MakeOfferModal.ConfirmButton')}
        </button>
      </div>
    </>
  );
};

export default UpdateToOfferSentConfirmModalContent;
