import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classes from './FilterCheckbox.module.css';
import { ReactComponent as Check } from '../../../../../icons/CheckIcon.svg';
import { ReactComponent as FlipToggle } from '../../../../../icons/FlipToggle.svg';
import { toPascalCase } from '../../../../../utils/toPascalCase';
import { files } from '../../../../../constants/Files';

interface Props {
  options: { id: string | number; text: string }[] | undefined;
  title: string;
}

export const FilterCheckbox: FC<Props> = ({ options, title }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedOptions, setSelectedOptions] = useState<(string | number)[]>(
    []
  );
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);

  useEffect(() => {
    const optionsParams = searchParams.get(toPascalCase(title))?.split(',');
    if (optionsParams) {
      setSelectedOptions([...optionsParams]);
    }
  }, [searchParams, title]);

  const handleCheckboxChange = (optionId: string | number) => {
    let newSearch;
    if (selectedOptions.includes(optionId)) {
      const newParams = new URLSearchParams(searchParams);
      setSelectedOptions((prevState) =>
        prevState.filter((item) => item !== optionId)
      );

      newSearch = newParams
        .get(toPascalCase(title))
        ?.split(',')
        .filter((item) => item !== optionId)
        ?.join(',');

      if (newSearch && newSearch !== '') {
        newParams.set(toPascalCase(title), newSearch);
        setSearchParams(newParams);
      } else {
        newParams.delete(toPascalCase(title));
        setSearchParams(newParams);
      }
    } else {
      const newParams = new URLSearchParams(searchParams);
      setSelectedOptions((prevState) => prevState.concat(optionId));
      newSearch = newParams.get(toPascalCase(title))
        ? `${newParams.get(toPascalCase(title))},${optionId}`
        : optionId.toString();
      newParams.set(toPascalCase(title), newSearch);
      setSearchParams(newParams);
    }
  };

  const visibleOptions = showAdditionalOptions ? options : options?.slice(0, 2);

  return (
    <div className={classes.FilterContainer}>
      <p className={classes.Title}>{title}</p>
      <label
        key={files.CURRICULUM}
        htmlFor={`checkbox-${files.CURRICULUM}`}
        className={classes.Checkbox}
      >
        <input
          type="checkbox"
          data-testid={`checkbox${files.CURRICULUM}`}
          value={files.CURRICULUM}
          checked={selectedOptions.includes(files.CURRICULUM)}
          onChange={() => {
            handleCheckboxChange(files.CURRICULUM);
          }}
        />
        <span className={classes.Checkmark}>
          {selectedOptions.includes(files.CURRICULUM) && <Check fill="#fff" />}
        </span>
        {files.CURRICULUM}
      </label>
      <label
        key={files.VIDEO}
        htmlFor={`checkbox-${files.VIDEO}`}
        className={classes.Checkbox}
      >
        <input
          type="checkbox"
          data-testid={`checkbox${files.VIDEO}`}
          value={files.CURRICULUM}
          checked={selectedOptions.includes(files.VIDEO)}
          onChange={() => {
            handleCheckboxChange(files.VIDEO);
          }}
        />
        <span className={classes.Checkmark}>
          {selectedOptions.includes(files.VIDEO) && <Check fill="#fff" />}
        </span>
        {files.VIDEO}
      </label>
      {visibleOptions &&
        visibleOptions.map((option, index) => (
          <label
            key={option.id}
            htmlFor={`checkbox-${option.id}`}
            className={classes.Checkbox}
          >
            <input
              type="checkbox"
              data-testid={`checkbox${index}`}
              value={option.id}
              checked={selectedOptions.includes(option.id)}
              onChange={() => {
                handleCheckboxChange(option.id);
              }}
            />
            <span className={classes.Checkmark}>
              {selectedOptions.includes(option.id) && <Check fill="#fff" />}
            </span>
            {option.text}
          </label>
        ))}

      {options && options.length > 2 && (
        <button
          type="button"
          onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
          className={classes.ShowMore}
        >
          {!showAdditionalOptions ? 'Show more' : 'Show less'}
          <FlipToggle transform={`scale(${showAdditionalOptions ? -1 : 1})`} />
        </button>
      )}
    </div>
  );
};
