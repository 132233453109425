import { useState } from 'react';
import { Dayjs } from 'dayjs';

interface InternalTimeOptionProps {
  time: Dayjs;
  setSchedule: (hour: Dayjs) => void;
  isSelected: boolean;
  disable: boolean;
}

const InternalTimeOption = ({
  time,
  setSchedule,
  isSelected,
  disable,
}: InternalTimeOptionProps) => {
  const [confirm, setConfirm] = useState(false);

  const onSetSchedule = () => {
    setSchedule(time);
    setConfirm(false);
  };

  return (
    <div
      className={`grid text-internal-main select-none ${
        confirm ? 'grid-cols-2 gap-1' : 'grid-cols-1'
      }`}
    >
      <button
        type="button"
        data-testid="TimeOption-ConfirmTrigger"
        className={`rounded border border-solid border-internal-main w-full p-2 ${
          isSelected && 'bg-internal-main text-white hover:cursor-default'
        } ${disable && 'bg-gray-200 text-gray-400 !border-gray-400'}`}
        onClick={onSetSchedule}
        disabled={disable}
      >
        <span>{time.format('h:00 a')}</span>
      </button>
    </div>
  );
};

export { InternalTimeOption };
