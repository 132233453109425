/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, ChangeEventHandler, Ref } from 'react';
import classes from './Radio.module.css';
import { ReactComponent as RadioButton } from '../../icons/RadioButton.svg';
import { ReactComponent as RadioButtonChecked } from '../../icons/RadioButtonIcon.svg';

interface IProps {
  name: string;
  label: string;
  disabled?: boolean;
  value: any;
  onChange: ChangeEventHandler<HTMLInputElement>;
  refProp: Ref<any>;
  checked: boolean;
}
const RadioFeedback: FC<IProps> = ({
  name,
  label,
  disabled,
  value,
  onChange,
  refProp,
  checked,
}) => {
  return (
    <div className={classes.radio_buttons}>
      <label className={classes.custom_radio}>
        <input
          className={classes.radio}
          ref={refProp}
          data-testid="radio-container"
          type="radio"
          name={name}
          id={`${name}-${value}`}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
        <span className={classes.radioFeedback_btn}>
          <div className={classes.content}>
            <div className={classes.feedBackLabel} data-testid="radio-label">
              <h2>{label}</h2>
            </div>
            <div className={classes.feedbackIcon_container}>
              {checked ? (
                <RadioButtonChecked
                  data-testid="radio-true-icon"
                  fill="#05668d"
                />
              ) : (
                <RadioButton data-testid="radio-false-icon" />
              )}
            </div>
          </div>
        </span>
      </label>
    </div>
  );
};

RadioFeedback.defaultProps = {
  disabled: false,
};
export default RadioFeedback;
