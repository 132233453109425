import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils';
import { getTasks } from './queries';
import { postTasks } from './mutations';

export const backgroundTasksApi = createApi({
  reducerPath: 'backgroundTasks',
  baseQuery,
  endpoints: (builder) => ({
    getTasks: getTasks(builder),
    postTasks: postTasks(builder),
  }),
});

export const { useGetTasksQuery, usePostTasksMutation } = backgroundTasksApi;
