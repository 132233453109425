import { FC, useRef, useState } from 'react';
import classes from './DropdownButton.module.css';
import { ReactComponent as CloseArrow } from '../../icons/ArrowDropdown.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface Props {
  children: React.ReactNode[] | React.ReactNode;
  label: string;
  isProfileDetails?: boolean;
  isInternal?: boolean;
  isPosition?: boolean;
}

const DropdownButton: FC<Props> = ({
  children,
  label,
  isProfileDetails,
  isInternal,
  isPosition,
}) => {
  const [showContent, setShowContent] = useState<boolean>(false);
  const parentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(parentRef, () => setShowContent(false));

  const handleClick = () => {
    setShowContent((s) => !s);
  };

  return (
    <div className={classes.dropdown} ref={parentRef}>
      <button
        type="button"
        className={`${
          !isProfileDetails
            ? classes.buttonDropdown
            : classes.buttonDropdownDetails
        }`}
        onClick={handleClick}
        style={{
          backgroundColor: isInternal ? 'var(--primary-green)' : '',
          height: isInternal ? '100%' : '',
        }}
        data-testid={`dropDownButton_${label.replace(/ /g, '_')}`}
      >
        <span className={classes.buttonContent}>{label}</span>
        <CloseArrow data-testid="closeArrow" />
      </button>
      {showContent && (
        <div
          className={classes.dropdownContent}
          onClick={() => {
            if (isPosition) {
              setShowContent(false);
            }
          }}
          aria-hidden
          data-testid={`dropDownButtonContent_${label.replace(/ /g, '_')}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

DropdownButton.defaultProps = {
  isProfileDetails: false,
  isInternal: false,
  isPosition: false,
};

export default DropdownButton;
