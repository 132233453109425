import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { Note } from '../../../../types/candidates';
import { successHandler, errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  SaveCandidateNoteInput,
  SaveCandidateNoteOutput,
} from '../candidates.types';

export const saveCandidateNote = (builder: CandidateBuilder) =>
  builder.mutation<SaveCandidateNoteOutput, SaveCandidateNoteInput>({
    query: (options) => ({
      url: '/candidate-notes',
      body: options.data,
      method: 'POST',
    }),
    invalidatesTags: () => ['CandidateNotes'],
    transformResponse: (res: { data: Note }, _, arg) => {
      successHandler(
        (arg.lang ?? 'en') === 'en'
          ? SuccessMessageEn.CREATE_CANDIDATE_NOTE
          : SuccessMessageEs.CREATE_CANDIDATE_NOTE,
        true
      );
      return res.data;
    },
    transformErrorResponse: (err, _, arg) => {
      errorHandler(
        '[saveCandidateNote]',
        (arg.lang ?? 'en') === 'es'
          ? ErrorMessageEs.CREATE_CANDIDATE_NOTE_ERROR
          : ErrorMessageEn.CREATE_CANDIDATE_NOTE_ERROR,
        err,
        true
      );
      return err;
    },
  });
