import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import { ParseCvInput, ParseCvOutput, UploadBuilder } from '../uploads.types';

export const parseCV = (builder: UploadBuilder) =>
  builder.mutation<ParseCvOutput, ParseCvInput>({
    query: (args) => ({
      url: process.env.REACT_APP_RESUME_PARSER_URL as string,
      method: 'POST',
      body: args.data,
    }),
    invalidatesTags: () => [{ type: 'Upload', id: 'List' }],
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[uploadCVAction]',
        args.language === 'es'
          ? ErrorMessageEs.UploadCV
          : ErrorMessageEn.UploadCV,
        err,
        true
      );
      return err.data;
    },
  });
