enum DegreeTypes {
  VOCATIONAL = 'Vocational',
  HIGH_SCHOOL = 'High School',
  ASSOCIATE_DEGREE = 'Associate Degree',
  BACHELORS_DEGREE = 'Bachelors Degree',
  MASTERS_DEGREE = 'Masters Degree',
  PHD = 'PhD',
}

export default DegreeTypes;
