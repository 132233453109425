const enum InternalDashboardPages {
  POSITIONS,
  POSITION,
  CANDIDATES,
  COMPANIES,
  EMPLOYEES,
  EMPLOYERS,
  NOTES,
  COMPANY,
  JOBINTERVIEW,
}

export default InternalDashboardPages;
