import React, { FC, useMemo, useState } from 'react';
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CandidateColumn } from '../../../../constants/CandidatesTable';
import TableFooter from '../../../TableFooter/TableFooter';
import Spinner from '../../../Spinner/Spinner';
import classes from './CandidatesTable.module.css';
import IndeterminateCheckbox from '../IndeterminateCheckbox/IndeterminateCheckbox';
import { Candidate } from '../../../../types/candidates';
import { useOriginPath } from '../../../../hooks/use-origin-path';

interface Props {
  candidatesData: Candidate[];
  loading: boolean;
  pageSize: number;
  pageState: number;
  setPage: (page: number) => void;
  setPageSize: (size: number) => void;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}
const CandidatesTable: FC<Props> = ({
  candidatesData,
  loading,
  pageCount,
  pageSize,
  pageState,
  setPageSize,
  setPage,
  hasPreviousPage,
  hasNextPage,
}) => {
  const { setOriginPath } = useOriginPath();
  const [t] = useTranslation('global');
  const [rowSelection, setRowSelection] = useState({});

  const navigate = useNavigate();

  const data: CandidateColumn[] =
    candidatesData.map((candidate) => ({
      Id: candidate.candidate_Id,
      FirstName: candidate.user_FirstName,
      LastName: candidate.user_LastName,
      PositionCount:
        candidate.cps_Status === 'hired'
          ? `${t('CandidatesTable.Hired')}`
          : candidate.positioncount! || 0,
      Email: candidate.user_Email,
      CreateTime: dayjs(candidate.candidate_CreateTime).format('DD/MM/YYYY'),
      Score: candidate.candidate_Score,
    })) || [];

  const columns = useMemo<ColumnDef<CandidateColumn>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <div className={classes.checkboxContainer}>
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
            </div>
          );
        },
        cell: ({ row }) => {
          return (
            <div className={classes.checkboxContainer}>
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        },
      },
      {
        header: `${t('CandidatesTable.Id')}`,
        accessorKey: 'Id',
      },
      {
        header: `${t('CandidatesTable.FirstName')}`,
        accessorKey: 'FirstName',
      },
      {
        header: `${t('CandidatesTable.LastName')}`,
        accessorKey: 'LastName',
      },
      {
        header: `${t('CandidatesTable.Email')}`,
        accessorKey: 'Email',
      },
      {
        header: `${t('CandidatesTable.ActiveRoster')}`,
        accessorKey: 'PositionCount',
      },
      {
        header: `${t('CandidatesTable.Score')}`,
        accessorKey: 'Score',
      },
      {
        header: `${t('CandidatesTable.CreateTime')}`,
        accessorKey: 'CreateTime',
      },
    ],
    [t]
  );
  const { getHeaderGroups, getRowModel } = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const nextPage = () => {
    setPage(pageState + 1);
  };

  const previousPage = () => {
    setPage(pageState - 1);
  };
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(Number(e.target.value));
  };

  const handleClickRow = (id: number | undefined) => {
    setOriginPath(window.location.pathname);
    navigate(`/simera-dashboard/candidates/${id}`);
  };
  return (
    <div className={classes.tableContainer} data-testid="candidate-table">
      {loading ? (
        <div className={classes.spinnerContainer}>
          <Spinner secondary height="100%" width="100%" />
        </div>
      ) : (
        <table>
          <thead>
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={`${classes.tableHead} ${
                      header.id === 'select' && classes.SelectTd
                    }`}
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  title={t('CandidatesTable.Details')}
                  className={`${classes.clickableRow} ${
                    row.getIsSelected() && classes.rowSelected
                  }`}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        <div
                          className={`${classes.cell} ${
                            row.getIsSelected() && classes.cellSelected
                          } ${
                            cell.column.columnDef.id && classes.checkboxCell
                          } ${cell.column.id === 'Email' && classes.email}`}
                          role="button"
                          tabIndex={0}
                          onKeyDown={
                            cell.column.columnDef.id ||
                            cell.column.id === 'Email'
                              ? undefined
                              : () => handleClickRow(row.original.Id)
                          }
                          onClick={
                            cell.column.columnDef.id ||
                            cell.column.id === 'Email'
                              ? undefined
                              : () => handleClickRow(row.original.Id)
                          }
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <TableFooter
        pageState={pageState}
        pageSize={pageSize}
        pageCount={pageCount}
        hasPreviousPage={hasPreviousPage}
        hasNextPage={hasNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
        handleSelectChange={handleSelectChange}
      />
    </div>
  );
};

export default CandidatesTable;
