import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import classes from './FilterSalary.module.css';
import { useDebounce } from '../../../../../hooks/useDebounce';

export const FilterSalary = () => {
  const [t] = useTranslation('global');
  const [searchParams, setSearchParams] = useSearchParams();
  const [input1Value, setInput1Value] = useState<string>(
    searchParams.get('MinSalary') ?? ''
  );
  const [input2Value, setInput2Value] = useState<string>(
    searchParams.get('MaxSalary') ?? ''
  );
  const [error, setError] = useState<string>('');
  const debouncedValue1 = useDebounce<string>(input1Value, 1_500);
  const debouncedValue2 = useDebounce<string>(input2Value, 1_500);

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    if (debouncedValue1) {
      newParams.set('MinSalary', debouncedValue1);
    } else {
      newParams.delete('MinSalary');
    }
    setSearchParams(newParams);
  }, [debouncedValue1, setSearchParams, searchParams]);

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    if (debouncedValue2) {
      newParams.set('MaxSalary', debouncedValue2);
    } else {
      newParams.delete('MaxSalary');
    }
    setSearchParams(newParams);
  }, [debouncedValue2, setSearchParams, searchParams]);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    setInput: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const inputValue: string = event.target.value;
    setInput(inputValue.replace(/[^0-9]/g, ''));
    setError('');
  };

  useEffect(() => {
    if (
      input1Value &&
      input2Value &&
      Number(input1Value) > Number(input2Value)
    ) {
      setError(t('forms.errors.salary'));
    } else {
      setError('');
    }
  }, [input1Value, input2Value, t]);

  return (
    <div className={classes.FilterSalaryContainer}>
      <p className={classes.Title}>{t('Filters.salary')}</p>
      <div className={classes.InputsContainer}>
        <input
          type="text"
          placeholder="$Min"
          className={classes.FilterInput}
          value={input1Value}
          onChange={(e) => {
            handleInputChange(e, setInput1Value);
          }}
        />
        <span>-</span>
        <input
          type="text"
          placeholder="$Max"
          className={classes.FilterInput}
          value={input2Value}
          onChange={(e) => {
            handleInputChange(e, setInput2Value);
          }}
        />
      </div>
      {error && (
        <p className={classes.ErrorMessage} data-testid="error">
          {error}
        </p>
      )}
    </div>
  );
};
