import React, { FC } from 'react';
import classes from './HoverTooltip.module.css';

interface IProps {
  children: React.ReactNode;
  text: string;
}

const HoverTooltip: FC<IProps> = ({ children, text }) => {
  return (
    <div className={classes.tooltip}>
      {children}
      <span className={classes.tooltiptext}>{text}</span>
    </div>
  );
};

export default HoverTooltip;
