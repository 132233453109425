import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../../../icons/ArrowRight.svg';
import { ReactComponent as CloseBlue } from '../../../../icons/CloseBlue.svg';
import { useApplyCandidateMutation } from '../../../../store/modules/candidates';
import {
  companiesApi,
  useLoadOnePositionQuery,
} from '../../../../store/modules/companies';
import classes from './ModalJobPosition.module.css';
import { useAppDispatch } from '../../../../hooks';
import { segmentTrack } from '../../../../utils/handleSegment';

interface Props {
  positionId: number;
}

const ModalJobPosition = ({ positionId }: Props) => {
  const dispatch = useAppDispatch();
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const [applyCandidate] = useApplyCandidateMutation();

  const { data: position } = useLoadOnePositionQuery({
    id: positionId ?? undefined,
  });

  const close = () => {
    navigate('/job-dashboard');
  };

  const clickApply = () => {
    applyCandidate({
      data: {
        positionId,
      },
      lang: i18n.language as 'es' | 'en',
    }).then(() => {
      const candidateId = JSON.parse(
        localStorage.getItem('ServerUserResponse') || '{}'
      )?.Candidate?.Id;

      segmentTrack('Candidate Applied to JobPosition', {
        candidate_id: candidateId,
        position_id: positionId,
        company_name: position?.Company?.Name,
        job_position_name: position?.JobPosition?.title,
        date_selected: new Date().toISOString(),
      });
      dispatch(companiesApi.util.invalidateTags(['Company']));
    });

    navigate('/job-dashboard');
  };

  return (
    <Modal
      isOpen
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      ariaHideApp={false}
      onRequestClose={close}
    >
      <div className={classes.Container}>
        <div className={classes.Header}>
          <p>{position?.JobPosition.title}</p>
          <CloseBlue onClick={close} className={classes.Close} />
        </div>
        <div className={classes.Information}>
          <div className={classes.Details}>
            <p className={classes.salary_experience}>
              <strong>{t('JobDashboard.ModalViewJob.SalaryRange')}</strong>
              {`${position?.SalaryLowerBound}$ - ${position?.SalaryUpperBound}$`}
            </p>
            <p className={classes.salary_experience}>
              <strong>{t('JobDashboard.ModalViewJob.Experience')}</strong>
              {`${position?.ExperienceYears} ${
                position?.ExperienceYears === 1
                  ? t('JobDashboard.JobSuggestions.Year')
                  : t('JobDashboard.JobSuggestions.Years')
              }`}
            </p>
          </div>
          <p className={classes.Paragraph}>{position?.JobDescription}</p>
          {position?.Responsibilities && (
            <div>
              <p className={classes.SubTitle}>
                {t('JobDashboard.ModalViewJob.Responsibilities')}
              </p>
              <p className={classes.listContainer}>
                {position.Responsibilities}
              </p>
            </div>
          )}
          {position?.Responsibilities && (
            <div>
              <p className={classes.SubTitle}>
                {t('JobDashboard.ModalViewJob.Requirements')}
              </p>
              <p className={classes.listContainer}>
                {position?.Responsibilities}
              </p>
            </div>
          )}
          {position?.Skills_position && (
            <div>
              <p className={classes.SubTitle}>{t('PositionCard.Skills')}</p>
              <ul className={classes.listContainer}>
                {position.Skills_position.map((skill) => (
                  <li key={`${Math.random()}-${skill.Title}`}>{skill.Title}</li>
                ))}
              </ul>
            </div>
          )}
          <p className={classes.Paragraph}>{position?.JobDescription}</p>
        </div>
        <div className={classes.Btns}>
          <button className={classes.Cancel} type="button" onClick={close}>
            {t('JobDashboard.ModalViewJob.Cancel')}
          </button>
          <button className={classes.Apply} type="button" onClick={clickApply}>
            {t('JobDashboard.ModalViewJob.Interested')}
            <ArrowRight />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalJobPosition;
