import { useTranslation } from 'react-i18next';
import { FC, useRef } from 'react';
import { ReactComponent as Close } from '../../../icons/CloseX.svg';
import { ReactComponent as ArrowRight } from '../../../icons/ArrowRight.svg';
import classes from './ConfirmDeletionResumeModal.module.css';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

interface ConfirmDeletionResumeModalProps {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  Delete: (index: number, deleteId: number) => void;
  DeleteId?: number;
  Index?: number;
}

export const ConfirmDeletionResumeModal: FC<
  ConfirmDeletionResumeModalProps
> = ({ setModal, Delete, DeleteId, Index }) => {
  const [t] = useTranslation('global');

  const parentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(parentRef, () => setModal(false));

  const handleDelete = () => {
    Delete(Index!, DeleteId!);
    setModal(false);
  };

  return (
    <div
      className={classes.container}
      onClick={() => setModal(false)}
      aria-hidden
      data-testid="background-container"
    >
      <div
        className={classes.centered}
        onClick={(e) => e.stopPropagation()}
        aria-hidden
      >
        <div className={classes.content}>
          <div className={classes.headerContent}>
            <div className={classes.header}>
              <p>Delete Resume</p>
              <Close
                className={classes.close}
                onClick={() => setModal(false)}
                width={15}
                fill="#05668D"
              />
            </div>
            <div className={classes.divider} />
          </div>

          <div className={classes.deleteContainer}>
            <p className={classes.text}>{t('deleteResume.question')}</p>
            <div className={classes.buttonContainer}>
              <button
                type="button"
                className={classes.cancel}
                onClick={() => setModal(false)}
              >
                {t('deleteResume.cancel')}
              </button>
              <button
                type="button"
                className={classes.yes}
                onClick={handleDelete}
                data-testid="delete"
              >
                {t('deleteResume.delete')}
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmDeletionResumeModal.defaultProps = {
  DeleteId: 0,
  Index: 0,
};
