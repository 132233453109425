import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ProfileScore.module.css';
import ScoreStep from './ScoreStep';

interface StepState {
  name: string;
  atLeastOne: boolean;
}
interface Props {
  stepState: StepState[];
}

const ProfileScore: FC<Props> = ({ stepState }) => {
  const [show, setShow] = useState(2);
  const [t] = useTranslation('global');

  const goToSection = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    n: number
  ) => {
    e.preventDefault();

    if (typeof window !== 'undefined') {
      const element = document.querySelector(`#section-${n}`);

      if (n === 1) {
        window.scrollTo({ top: 0 });
      } else if (element) {
        element.scrollIntoView(true);
      }
    }
  };

  return (
    <div className={`p-4 ${classes.SimeraScoreContainer}`}>
      <div className={classes.ScoreBody}>
        {stepState.map(
          (step, index) =>
            index <= show && (
              <ScoreStep
                key={step.name}
                title={step.name}
                bindTo={(e) => goToSection(e, index + 1)}
                aria-hidden
              />
            )
        )}
      </div>
      <div
        className={classes.ScoreFooter}
        onClick={() => (show === 2 ? setShow(7) : setShow(2))}
        aria-hidden
      >
        {show === 2 ? (
          <h5>{t('footer.showMore')}</h5>
        ) : (
          <h5>{t('footer.showLess')}</h5>
        )}
      </div>
    </div>
  );
};

export default ProfileScore;
