// WARN: THIS COMPONENT IS USED ON GUEST VIEW
import { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Website } from '$icons/captive_portal.svg';
import { ReactComponent as VideoPlay } from '$icons/video_display.svg';
import { ReactComponent as Portfolio } from '$icons/dataset_linked.svg';
import { ReactComponent as Github } from '$icons/github.svg';
import { ReactComponent as Notes } from '$icons/candidate_notes.svg';
import { ReactComponent as ArrowDown } from '$icons/arrow_down.svg';
import { ReactComponent as AI } from '$icons/ai.svg';
import useWindowWidth from '$hooks/useWindowWidth';
import { ReactComponent as CloseVideoModal } from '$icons/close_video_modal.svg';
import {
  CandidateData,
  CandidatesPositionStatusOnGuestMode,
} from '$types/candidates';
import VideoGenericElement from '$components/GenericElements/VideoGenericElement';
import { FileCategoryType } from '$types/categoryType';
import { segmentTrack } from '$utils/handleSegment';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';
import classes from './AttachmentsSummar.module.css';
import SingleAttachmentSummary, {
  IconValidColorsType,
} from './SingleAttachmentSummary/SingleAttachmentSummary';
import CandidateVideosRate from '../CandidateVideosModal/CandidateVideosRate';
import CandidateVideoFeedback from '../CandidateVideosModal/CandidateVideoFeedback';

type SortedAttachmentType = {
  Icon: any;
  Title: string;
  Color: IconValidColorsType;
  Link?: string;
  BehanceFix?: boolean;
  onClick?: () => void;
  index?: number;
  segmentEvent?: () => void;
  isPdf?: boolean;
};

const AttachmentSummary = ({
  candidate,
  positionId,
  isGuest = false,
}: {
  candidate: CandidateData | CandidatesPositionStatusOnGuestMode;
  positionId: number;
  isGuest?: boolean;
}) => {
  const [t] = useTranslation('global');
  const windowWidth = useWindowWidth();

  const [showMore, setShowMore] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<any>();
  const [selectedVideoTitle, setSelectedVideoTitle] = useState('');
  const DISPLAYED_FILE_CATEGORIES_ID = useMemo(() => {
    return [FileCategoryType.REFERENCE, FileCategoryType.WRITING_SAMPLES];
  }, []);
  const [sortedAttachments, setSortedAttachments] = useState<
    SortedAttachmentType[]
  >([]);
  const [displayedFilesAmmount, setDisplayedFilesAmmout] = useState(
    isGuest ? 2 : 3
  );

  const toggleItemsVisibilityHandler = () => {
    if (!showMore) {
      segmentTrack('Candidate Show More Clicked', {
        candidate_id: candidate.Id,
        position_id: positionId,
      });
    } else {
      segmentTrack('Candidate Show Less Clicked', {
        candidate_id: candidate.Id,
        position_id: positionId,
      });
    }
    setShowMore(!showMore);
  };

  useEffect(() => {
    if (isGuest) {
      setDisplayedFilesAmmout(2);
    } else if (windowWidth < 768) {
      setDisplayedFilesAmmout(1);
    } else if (windowWidth < 1024) {
      setDisplayedFilesAmmout(2);
    } else {
      setDisplayedFilesAmmout(3);
    }
  }, [windowWidth, isGuest]);

  useEffect(() => {
    let tempSortedAttachments: SortedAttachmentType[] = [];

    if ('ScorecardUrl' in candidate && candidate.ScorecardUrl)
      tempSortedAttachments.push({
        Icon: <AI />,
        Title: 'AI Scorecard (beta)',
        Color: IconValidColorsType.BLUE,
        Link: candidate.ScorecardUrl,
        segmentEvent: () => {
          segmentTrack('AI Scorecard Link Clicked', {
            candidate_id: candidate.Id,
            position_id: positionId,
          });
        },
      });

    /** Videos */
    if ('AdditionalVideos' in candidate) {
      candidate.AdditionalVideos.forEach((item) => {
        tempSortedAttachments.push({
          Icon: <VideoPlay />,
          Title: item.Name,
          Color: IconValidColorsType.BLUE,
          onClick: () => {
            setSelectedVideoTitle(item.Name);
            setSelectedVideo({
              ...item,
              Name: item.Name,
            });
            segmentTrack('Open Highlight Video Modal', {
              candidate_id: candidate.Id,
              position_id: positionId,
              title: item.Name,
            });
          },
        });
      });
    }

    /** Custom Url */
    if ('CustomUrl' in candidate && candidate.CustomUrl) {
      tempSortedAttachments.push({
        Icon: <Website fill="#D19234" style={{ scale: '1' }} />,
        Title: `${candidate.User?.FirstName}'s Portfolio`,
        Color: IconValidColorsType.YELLOW,
        Link: candidate.CustomUrl,
        segmentEvent: () => {
          segmentTrack('Candidate External Link Clicked', {
            candidate_id: candidate.Id,
            target_url: candidate.CustomUrl,
            target: 'Custom',
            position_id: positionId,
          });
        },
      });
    }

    /** Behance Url */
    if ('BehanceUrl' in candidate && candidate.BehanceUrl) {
      tempSortedAttachments.push({
        Icon: <Portfolio />,
        Title: `${candidate.User?.FirstName}'s Behance Portfolio`,
        Color: IconValidColorsType.YELLOW,
        Link: candidate.BehanceUrl,
        BehanceFix: true,
        segmentEvent: () => {
          segmentTrack('Candidate External Link Clicked', {
            candidate_id: candidate.Id,
            target_url: candidate.BehanceUrl,
            target: 'Behance',
            position_id: positionId,
          });
        },
      });
    }

    /** Github Url */
    if ('GithubUrl' in candidate && candidate.GithubUrl) {
      tempSortedAttachments.push({
        Icon: <Github />,
        Title: `${candidate.User?.FirstName}'s Github`,
        Color: IconValidColorsType.YELLOW,
        Link: candidate.GithubUrl,
        segmentEvent: () => {
          segmentTrack('Candidate External Link Clicked', {
            candidate_id: candidate.Id,
            target_url: candidate.GithubUrl,
            target: 'Github',
            position_id: positionId,
          });
        },
      });
    }

    if ('Files' in candidate && candidate?.Files) {
      candidate?.Files.forEach((singleFile, index) => {
        if (
          DISPLAYED_FILE_CATEGORIES_ID.indexOf(singleFile?.FileCategory?.Id) !==
            -1 &&
          index <= 1 // display only two files
        ) {
          const isPdf = singleFile.Url?.includes('.pdf') ?? false;

          tempSortedAttachments.push({
            Icon: <Notes />,
            Title: `${isPdf ? 'View' : 'Download'} ${
              singleFile.FileCategory.CategoryName
            }`,
            Color: IconValidColorsType.GREEN,
            Link: singleFile.Url,
            segmentEvent: () => {
              if (singleFile?.FileCategory?.Id === FileCategoryType.REFERENCE) {
                segmentTrack('Reference Download', {
                  candidate_id: candidate.Id,
                  file_id: singleFile.Id,
                });
              } else {
                segmentTrack('Write Assessment Download', {
                  candidate_id: candidate.Id,
                  file_id: singleFile.Id,
                });
              }
            },
            isPdf,
          });
        }
      });
    }

    /** Curriculum File */
    if ('Curriculum' in candidate && candidate?.Curriculum?.Url) {
      tempSortedAttachments.push({
        Icon: <Notes />,
        Title: `View ${candidate.User?.FirstName} ${candidate.User?.LastName} Resume`,
        Color: IconValidColorsType.GREEN,
        Link: candidate.Curriculum.Url,
        segmentEvent: () => {
          segmentTrack('CV Downloaded', {
            candidate_id: candidate.Id,
            file_id: candidate?.Curriculum?.Id,
            position_id: positionId,
          });
        },
        isPdf: true,
      });
    }

    tempSortedAttachments = tempSortedAttachments.map((item, index) => {
      item.index = index;
      return item;
    });

    setSortedAttachments(tempSortedAttachments);
  }, [candidate, DISPLAYED_FILE_CATEGORIES_ID, positionId, isGuest]);

  return (
    <>
      {selectedVideo && (
        <Modal
          isOpen={!!selectedVideo}
          onRequestClose={() => setSelectedVideo(undefined)}
          className={classes.modal}
          overlayClassName={classes.modalOverlay}
          contentLabel={selectedVideoTitle}
          ariaHideApp={false}
          onAfterOpen={() => {
            document.body.style.overflow = 'hidden';
          }}
          onAfterClose={() => {
            document.body.style.overflow = 'auto';
          }}
        >
          <div className={classes.modalVideoHeader}>
            <div className={classes.modalVideoTitle}>{selectedVideoTitle}</div>
            <CloseVideoModal
              onClick={() => setSelectedVideo(undefined)}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className={classes.modalVideoBody}>
            <VideoGenericElement
              videoData={selectedVideo}
              style={{
                maxWidth: '80%',
                maxHeight: '25rem',
                alignSelf: 'center',
              }}
              controls
              playsInline
            />
            <div className={classes.candidateProfileData}>
              <div className="w-20 mr-4">
                <DefaultIcon2
                  picture={candidate.User?.ProfilePicture?.Url}
                  firstName={candidate.User?.FirstName}
                  lastName={candidate.User?.LastName}
                  fontSize="text-3xl"
                />
              </div>
              <div className={classes.candidateName}>{`${
                candidate?.User?.FirstName?.split(' ')[0] || ''
              } ${candidate?.User?.LastName?.split(' ')[0][0]}.`}</div>
            </div>
            {!isGuest && (
              <>
                <hr />
                <div className={classes.videoTitleQuestion}>
                  {t('VideoModal.RatingsTitle')}
                </div>
                <CandidateVideosRate
                  primary
                  {...('type' in selectedVideo
                    ? {
                        videoId: selectedVideo.Id,
                      }
                    : { additionalVideoId: selectedVideo.Id })}
                  candidateId={candidate.Id}
                />
                <div className={classes.videoTitleQuestion}>
                  {t('VideoModal.FeedbackTitle')}
                </div>
                <CandidateVideoFeedback
                  videoId={selectedVideo.Id}
                  videoType={
                    'type' in selectedVideo ? 'Video' : 'AdditionalVideos'
                  }
                  candidateId={candidate.Id}
                />
              </>
            )}
          </div>
        </Modal>
      )}
      {sortedAttachments.length > 0 && (
        <div
          style={{ fontSize: '16px', fontWeight: 600, marginBottom: '20px' }}
        >
          {`${candidate.User?.FirstName}'s highlights`}
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {sortedAttachments.map((singleAttachment, index) => {
          if (index < displayedFilesAmmount)
            return (
              <SingleAttachmentSummary
                icon={singleAttachment.Icon}
                title={singleAttachment.Title}
                color={singleAttachment.Color}
                link={singleAttachment.Link}
                onClick={singleAttachment.onClick}
                behanceFix={!!singleAttachment.BehanceFix}
                key={`${candidate.Id}_attachment_${singleAttachment.index}`}
                segmentEvent={singleAttachment.segmentEvent}
                isPdf={singleAttachment.isPdf}
              />
            );
          return (
            <SingleAttachmentSummary
              additionalClassNames={!showMore ? 'hidden' : 'flex'}
              icon={singleAttachment.Icon}
              title={singleAttachment.Title}
              color={singleAttachment.Color}
              link={singleAttachment.Link}
              onClick={singleAttachment.onClick}
              behanceFix={!!singleAttachment.BehanceFix}
              key={`${candidate.Id}_attachment_${singleAttachment.index}`}
              segmentEvent={singleAttachment.segmentEvent}
              isPdf={singleAttachment.isPdf}
            />
          );
        })}
      </div>
      {!isGuest && displayedFilesAmmount < sortedAttachments.length && (
        <button
          type="button"
          className={classes.showMoreButton}
          style={{ ...(showMore && { marginBottom: '20px' }) }}
          onClick={toggleItemsVisibilityHandler}
        >
          {sortedAttachments.length - displayedFilesAmmount}
          {!showMore ? ' more' : ' less'}
          <ArrowDown
            style={{
              width: 15,
              height: 15,
              transition: 'transform 0.3s ease 0s',
              ...(showMore && { transform: 'rotate(180deg)' }),
            }}
          />
        </button>
      )}
    </>
  );
};

AttachmentSummary.defaultProps = {
  isGuest: false,
};

export default AttachmentSummary;
