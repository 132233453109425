import { FC } from 'react';

interface AddIconProps {
  color?: string;
  selected?: boolean;
}

const AddIcon: FC<AddIconProps> = ({ color, selected }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: selected ? 'rotate(45deg)' : '',
        transition: '0.5s ease-in-out',
      }}
    >
      <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill={color} />
    </svg>
  );
};

AddIcon.defaultProps = { color: 'white', selected: false };
export default AddIcon;
