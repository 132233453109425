import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContentContainer from '../../ContentContainer/ContentContainer';
import Home from '../../Home/Home';
import InfoContainer from '../../InfoContainer/InfoContainer';
import InfoTitle from '../../InfoTitle/InfoTitle';
import Steps from '../../Referrals/Steps/Steps';
import ReferModule from '../../Referrals/ReferModule/ReferModule';
import FriendsList from '../../Referrals/FriendsList/FriendsList';
import { ReactComponent as ChevronUp } from '../../../icons/ChevronUp.svg';
import { ReactComponent as ChevronDown } from '../../../icons/ChevronDown.svg';
import Button from '../../Buttons/Button';
import CollapseModule from '../../Referrals/CollapseModule/CollapseModule';
import useWindowWidth from '../../../hooks/useWindowWidth';
import classes from './ReferralsScreen.module.css';
import {
  useGetCandidateReferralIdQuery,
  useGetCandidatesReferralsQuery,
} from '../../../store/modules/referrals/referrals.reducer';

const ReferralsScreen: FC = () => {
  const [t, i18next] = useTranslation('global');

  const { data: referralInfo } = useGetCandidateReferralIdQuery(
    {
      lang: i18next.language || 'en',
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: referrals } = useGetCandidatesReferralsQuery(
    {
      lang: i18next.language || 'en',
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [collapse, setCollapse] = useState(false);

  const isMobile = useWindowWidth() < 768;

  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/home', { replace: false });
  };

  return (
    <Home>
      <InfoContainer
        hideSimeraScore
        hideHomeButton
        referral
        containerStyles={classes.InfoContainer}
      >
        <InfoTitle
          title={t('referrals.title')}
          highlight={t('referrals.titleHighlight')}
          breakLine={false}
          highlightText={false}
          highlightPrimary={false}
        />
        <CollapseModule hide={isMobile && collapse}>
          <p className={classes.DescriptionText}>{t('referrals.subtitle')}</p>
          <Steps />
        </CollapseModule>
        <ReferModule
          link={`${window.location.origin}?referralId=${
            referralInfo?.UniqueId ?? ''
          }`}
        />
        {isMobile && (
          <Button
            showText
            icon={collapse ? ChevronDown : ChevronUp}
            text={
              collapse
                ? t('referrals.collapseBtnShowMore')
                : t('referrals.collapseBtnShowLess')
            }
            size="7.15rem"
            bgColor="transparent"
            styling={classes.CollapseBtn}
            onClick={() => setCollapse((prevState) => !prevState)}
          />
        )}
      </InfoContainer>
      <ContentContainer
        leftFooter={<div />}
        rightFooter={<div />}
        headerStyling={classes.ContentContainerHeader}
        goBackTo={goToHome}
      >
        <FriendsList referralsList={referrals ?? []} />
      </ContentContainer>
    </Home>
  );
};

export default ReferralsScreen;
