import { FC } from 'react';
import classes from './ChipFilter.module.css';

interface Props {
  active: any;
  onChange: (value: any) => void;
  label: string;
  activeValue: any;
  secondary?: boolean;
  disabled?: boolean;
}

const ChipFilter: FC<Props> = ({
  active,
  onChange,
  label,
  activeValue,
  secondary,
  disabled,
}) => {
  const activeClass = secondary ? classes.secondaryActive : classes.active;
  const normalClass = secondary ? classes.secondary : classes.normal;
  return (
    <div
      className={`${classes.chip} ${disabled && classes.disabled} ${
        active === activeValue ? activeClass : normalClass
      }  `}
      onClick={() => (disabled ? null : onChange(activeValue))}
      onKeyDown={() => (disabled ? null : onChange(activeValue))}
      data-testid="chipFilter"
      role="button"
      tabIndex={0}
    >
      <div className={classes.chipContent}>{label}</div>
    </div>
  );
};

ChipFilter.defaultProps = {
  secondary: false,
  disabled: false,
};

export default ChipFilter;
