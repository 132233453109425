import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterInput } from './FilterInput';
import { FilterSelect } from './FilterSelect';
import classes from './Filters.module.css';
import { FilterSalary } from './FilterSalary';
import { FilterCheckbox } from './FilterCheckbox';
import { useGetFileCategoriesQuery } from '../../../../store/modules/form-info';
import { FilterRadio } from './FilterRadio';

export const Filters = () => {
  const [educationLevel, setEducationLevel] = useState<string>('None');
  const [englishLevel, setEnglishLevel] = useState<string>('None');
  const [t] = useTranslation('global');
  const { data } = useGetFileCategoriesQuery({ t });

  const fileCategories = useMemo(() => {
    return (
      data &&
      data.map((option) => ({
        id: `${option.CategoryName}`,
        text: option.CategoryName,
      }))
    );
  }, [data]);

  return (
    <div className={classes.Container} data-testid="filters-section">
      <FilterInput title={t('Filters.name')} nameField />
      <FilterInput title={t('Filters.skills')} matchInput />
      <FilterInput title={t('Filters.languages')} isText />
      <FilterSelect
        title={t('Filters.education')}
        placeholder={educationLevel}
        setCategory={setEducationLevel}
        options={[
          { id: 'PhD', text: t('education.phd') },
          { id: 'Masters Degree', text: t('education.md') },
          { id: 'Bachelors Degree', text: t('education.bd') },
          { id: 'Associate Degree', text: t('education.assocDeg') },
          { id: 'High School', text: t('education.hs') },
        ]}
      />
      <FilterSelect
        title={t('Filters.englishLevel')}
        placeholder={englishLevel}
        setCategory={setEnglishLevel}
        options={[
          { id: 'A1', text: 'A1' },
          { id: 'A2', text: 'A2' },
          { id: 'B1', text: 'B1' },
          { id: 'B2', text: 'B2' },
          { id: 'C1', text: 'C1' },
          { id: 'C2', text: 'C2' },
        ]}
      />
      <FilterSalary />
      <FilterRadio
        title={t('Filters.yearsOfExperience.title')}
        options={[
          { id: '[1, 2]', text: t('Filters.yearsOfExperience.opcion1') },
          { id: '[3, 5]', text: t('Filters.yearsOfExperience.opcion2') },
          { id: '[6, 9]', text: t('Filters.yearsOfExperience.opcion3') },
          { id: '[10, 100]', text: t('Filters.yearsOfExperience.opcion4') },
        ]}
      />
      <FilterCheckbox title={t('Filters.files')} options={fileCategories} />
      <FilterInput title={t('Filters.experience')} />
      <FilterInput title={t('Filters.additionalTags')} matchInput />
    </div>
  );
};
