import { ErrorMessageEn, ErrorMessageEs } from '$constants/alerts';
import { errorHandler } from '$store/utils';
import {
  CandidateReactionsBuilder,
  GetAllCandidatesReactionsInput,
  GetAllCandidatesReactionsOutput,
} from '../candidate-reactions.types';

export const getAllCandidatesReactions = (builder: CandidateReactionsBuilder) =>
  builder.query<
    GetAllCandidatesReactionsOutput,
    GetAllCandidatesReactionsInput
  >({
    query: ({ candidateId }) => ({
      url: `/candidate-reactions/${candidateId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ Id }: any) => ({
              type: 'candidateReactions' as const,
              id: Id,
            })),
            { type: 'candidateReactions' },
          ]
        : [{ type: 'candidateReactions' }],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[getCandidateReactionsEndpoint]',
        lang === 'es'
          ? ErrorMessageEs.ErrorRetrievingReactions
          : ErrorMessageEn.ErrorRetrievingReactions,
        error.data,
        true
      );
      return error.data;
    },
  });
