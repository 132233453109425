import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { getCandidateEducation } from './queries';
import {
  addCandidateEducation,
  addCandidateEducationInternal,
  deleteCandidateEducation,
} from './mutations';

export const candidateEducationApi = createApi({
  reducerPath: ReducerPaths.CANDIDATE_EDUCATION,
  baseQuery,
  endpoints: (builder) => ({
    getCandidateEducation: getCandidateEducation(builder),
    deleteCandidateEducation: deleteCandidateEducation(builder),
    addCandidateEducationInternal: addCandidateEducationInternal(builder),
    addCandidateEducation: addCandidateEducation(builder),
  }),
});

export const {
  useGetCandidateEducationQuery,
  useLazyGetCandidateEducationQuery,
  useDeleteCandidateEducationMutation,
  useAddCandidateEducationInternalMutation,
  useAddCandidateEducationMutation,
} = candidateEducationApi;
