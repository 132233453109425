import React, { FC } from 'react';
import { ReactComponent as EmptyIcon } from '../../../icons/EmptyCandidatesPositions.svg';

import classes from './EmptyState.module.css';

interface Props {
  text: string;
  description: string;
  secondary?: boolean;
  height?: string;
}

const EmptyState: FC<Props> = ({ text, description, secondary, height }) => {
  const fill = secondary ? '#079e88' : '#05668D';
  return (
    <div
      className={classes.NoData}
      style={{ height }}
      data-testid="empty-container"
    >
      <EmptyIcon fill={fill} />
      <p className={classes.NoDataText} data-testid="empty-text">
        {text}
      </p>
      <p className={classes.NoDataSubText} data-testid="empty-description">
        {description}
      </p>
    </div>
  );
};

EmptyState.defaultProps = {
  secondary: false,
  height: '70vh',
};
export default EmptyState;
