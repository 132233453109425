import { toast } from 'react-toastify';

export const warningHandler = (
  from: string,
  customWarningMessage: string,
  showToast: boolean,
  loadingBar?: boolean
) => {
  if (showToast) {
    toast(customWarningMessage, {
      type: 'warning',
      hideProgressBar: loadingBar,
      // if we want to change color to match figma
      // style: { backgroundColor: 'blue' },
    });
  }
};
