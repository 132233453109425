import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { resubscribeUserToEmail, unsubscribeUserFromEmail } from './mutations';

export const userInfoApi = createApi({
  reducerPath: ReducerPaths.USER_INFO,
  baseQuery,
  endpoints: (builder) => ({
    unsubscribeUserFromEmail: unsubscribeUserFromEmail(builder),
    resubscribeUserToEmail: resubscribeUserToEmail(builder),
  }),
});

export const {
  useUnsubscribeUserFromEmailMutation,
  useResubscribeUserToEmailMutation,
} = userInfoApi;
