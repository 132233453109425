import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { LeftArrow, RightArrow } from './Arrows/Arrows';
import JobPosition from './JobPosition/JobPosition';
import classes from './JobSuggestions.module.css';
import { useSwipe } from '../../../../hooks/useSwipe';
import PositionDto from '../../../../types/serverTypes/positionDto';
import usePreventBodyScroll from '../../../../hooks/usePreventBodyScroll';

type ScrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface Props {
  suggestions: PositionDto[] | undefined;
}

export const JobSuggestions = ({ suggestions }: Props) => {
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const { onTouchEnd, onTouchMove, onTouchStart } = useSwipe();

  const onWheel = (
    apiObj: ScrollVisibilityApiType,
    ev: React.WheelEvent
  ): void => {
    const isThouchpad = Math.abs(ev.deltaY) !== 0;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaX < 0) {
      apiObj.scrollPrev();
    } else if (ev.deltaX > 0) {
      apiObj.scrollNext();
    }
  };

  const [t] = useTranslation('global');

  if (!suggestions) return null;

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        {t('JobDashboard.JobSuggestions.Title1')}
      </h2>
      <div
        className={classes.jobsContainer}
        onMouseEnter={disableScroll}
        onMouseLeave={enableScroll}
      >
        <ScrollMenu
          onWheel={onWheel}
          onTouchEnd={onTouchEnd}
          onTouchMove={onTouchMove}
          onTouchStart={onTouchStart}
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          scrollContainerClassName={classes.scrollContainer}
          wrapperClassName={classes.scrollWrapper}
        >
          {suggestions.map((item) => (
            <JobPosition job={item} key={item.Id} />
          ))}
        </ScrollMenu>
      </div>
    </div>
  );
};
