enum StatusCandidatePosition {
  hired = 'hired',
  offerSent = 'offerSent',
  shortlisted = 'shortlisted',
  declined = 'declined',
  new = 'new',
  requestInterview = 'requestInterview',
}

export default StatusCandidatePosition;
