import { createApi } from '@reduxjs/toolkit/query/react';
import { addCandidateLanguageInternal } from './mutations/add-candidate-languages-internal.mutation';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { getCandidateLanguages } from './queries';
import { addCandidateLanguage, deleteCandidateLanguage } from './mutations';

export const candidateLanguageApi = createApi({
  reducerPath: ReducerPaths.CANDIDATE_LANGUAGE,
  baseQuery,
  tagTypes: ['candidateLanguages', 'Score'],
  endpoints: (builder) => ({
    getCandidateLanguages: getCandidateLanguages(builder),
    deleteCandidateLanguage: deleteCandidateLanguage(builder),
    addCandidateLanguages: addCandidateLanguage(builder),
    addCandidateLanguageInternal: addCandidateLanguageInternal(builder),
  }),
});

export const {
  useGetCandidateLanguagesQuery,
  useLazyGetCandidateLanguagesQuery,
  useDeleteCandidateLanguageMutation,
  useAddCandidateLanguageInternalMutation,
  useAddCandidateLanguagesMutation,
} = candidateLanguageApi;
