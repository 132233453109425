import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HoverTooltip from '../../../../Tooltip/HoverTooltip';
import { ReactComponent as EditIcon } from '../../../../../icons/EditIcon.svg';
import classes from './EmployerActionButton.module.css';
import UpdateEmployerModal from '../UpdateEmployerModal/UpdateEmployerModal';
import UpdateEmployerForm from '../UpdateEmployerForm/UpdateEmployerForm';
import { EmployerData } from '../../../../../types/candidates';

interface Props {
  employer: EmployerData;
}

const EmployerActionButtons: FC<Props> = ({ employer }) => {
  const [t] = useTranslation('global');
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className={classes.actions}>
      <HoverTooltip text={t('Employers.UpdateEmployers')}>
        <button
          aria-label="Update employer"
          type="button"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <EditIcon fill="#5d5e61" />
        </button>
      </HoverTooltip>
      <UpdateEmployerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <UpdateEmployerForm employer={employer} setIsOpen={setIsModalOpen} />
      </UpdateEmployerModal>
    </div>
  );
};

export default EmployerActionButtons;
