import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { Candidate } from '../../../../types/candidates';
import { successHandler, errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  UploadCandidateInfoOutput,
  UploadCandidateInfoInput,
} from '../candidates.types';

export const uploadCandidateInfo = (builder: CandidateBuilder) =>
  builder.mutation<UploadCandidateInfoOutput, UploadCandidateInfoInput>({
    query: (options) => ({
      method: 'PATCH',
      url: `/candidates/${options.candidateId}`,
      body: options.data,
    }),
    transformResponse: (queryResponse: Candidate, _, arg) => {
      const output = { ...queryResponse };

      Object.keys(output).forEach(
        (key: string) =>
          output[key as keyof typeof output] == null &&
          delete output[key as keyof typeof output]
      );
      delete output.User;

      successHandler(
        (arg.lang ?? 'en') === 'en'
          ? SuccessMessageEn.UpdateCandidate
          : SuccessMessageEs.UpdateCandidate,
        true
      );

      return output;
    },
    transformErrorResponse: (error, _, arg) => {
      errorHandler(
        '[uploadCandidateInfoEndpoint]',
        (arg.lang ?? 'en') === 'en'
          ? ErrorMessageEn.UpdateCandidatesFail
          : ErrorMessageEs.UpdateCandidateFail,
        error.data,
        true
      );
      return error.data;
    },
    invalidatesTags: (result, error, arg) => [
      { type: 'Candidate', Id: arg.candidateId },
    ],
  });
