import {
  GetCandidateInterviewByCPSInput,
  GetCandidateInterviewByCPSOutput,
  InterviewBuilder,
} from '../interview.types';

export const getCandidateInterviewByCPS = (builder: InterviewBuilder) =>
  builder.query<
    GetCandidateInterviewByCPSOutput,
    GetCandidateInterviewByCPSInput
  >({
    query: ({ candidateId, candidatePositionStatus }) => ({
      url: `/interview/candidate/${candidateId}?candidatePositionStatusId=${candidatePositionStatus}`,
      method: 'GET',
    }),
    providesTags: () => [{ type: 'InterviewStatus' }],
  });
