import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseGreen } from '$icons/CloseGreen.svg';
import { ReactComponent as ArrowRight } from '$icons/ArrowRight.svg';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirm: () => void;
  salaryExceeds: boolean;
}

const ConfirmAddToPositionModal: FC<Props> = ({
  setIsOpen,
  handleConfirm,
  salaryExceeds,
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };
  const [t] = useTranslation('global');

  return (
    <div className="bg-white w-[calc(100%-40px)] sm:w-[700px] font-sans">
      <div className="flex justify-between items-center gap-2 border-b-[1px] border-[#E6F0F4] p-4 pb-[17px]">
        <h1 className="text-lg sm:text-[20px] font-semibold">
          {t('ConfirmAddToPositionModal.title')}
        </h1>
        <CloseGreen
          onClick={handleClose}
          className="cursor-pointer w-12 h-12 sm:w-6 sm:h-6"
        />
      </div>
      <div className="p-4 items-center flex flex-col gap-8 w-full">
        {salaryExceeds ? (
          <p>{t('ConfirmAddToPositionModal.textSalaryExceeds')}</p>
        ) : (
          <div>
            <p>{t('ConfirmAddToPositionModal.text')}</p>
            <p>{t('ConfirmAddToPositionModal.subText')}</p>
          </div>
        )}
        <div className="flex gap-4 justify-end w-full">
          <button
            type="button"
            className="h-12 px-4 font-semibold text-brand-green10"
            onClick={handleClose}
          >
            {t('ConfirmAddToPositionModal.goBack')}
          </button>
          <button
            type="button"
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
            className="h-12 rounded font-semibold text-white flex justify-center items-center gap-2 px-4 bg-brand-green10"
          >
            <p>{t('ConfirmAddToPositionModal.accept')}</p>
            <ArrowRight className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAddToPositionModal;
