import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');
export const TIMEZONES = {
  EASTERN: 'America/New_York',
  CENTRAL: 'America/Chicago',
  PACIFIC: 'America/Los_Angeles',
  MOUNTAIN: 'America/Denver',
};

export enum DAYS {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}
const today = dayjs().day();

// It renders the next 3 working days
export const WORKING_DAYS = () => {
  // friday
  // const today = dayjs().day();

  const week = [
    dayjs().day(DAYS.MONDAY),
    dayjs().day(DAYS.TUESDAY),
    dayjs().day(DAYS.WEDNESDAY),
    dayjs().day(DAYS.THURSDAY),
    dayjs().day(DAYS.FRIDAY),
    dayjs().day(DAYS.SATURDAY),
    dayjs().day(DAYS.SUNDAY).add(1, 'week'),
  ];

  if (today === DAYS.THURSDAY) {
    // remove monday
    week.shift();
    week.push(dayjs().day(DAYS.MONDAY).add(1, 'week'));
  }
  if (today === DAYS.FRIDAY) {
    week.shift();
    week.shift();

    week.push(
      dayjs().day(DAYS.MONDAY).add(1, 'week'),
      dayjs().day(DAYS.TUESDAY).add(1, 'week')
    );
  }

  if (today === DAYS.SATURDAY) {
    return [
      dayjs().day(DAYS.SATURDAY),
      dayjs().day(DAYS.SUNDAY).add(1, 'week'),
      dayjs().day(DAYS.MONDAY).add(1, 'week'),
      dayjs().day(DAYS.TUESDAY).add(1, 'week'),
      dayjs().day(DAYS.WEDNESDAY).add(1, 'week'),
      dayjs().day(DAYS.THURSDAY).add(1, 'week'),
      dayjs().day(DAYS.FRIDAY).add(1, 'week'),
    ];
  }

  if (today === DAYS.SUNDAY) {
    return [
      dayjs().day(DAYS.SATURDAY).subtract(1, 'week'),
      dayjs().day(DAYS.SUNDAY),
      dayjs().day(DAYS.MONDAY),
      dayjs().day(DAYS.TUESDAY),
      dayjs().day(DAYS.WEDNESDAY),
      dayjs().day(DAYS.THURSDAY),
      dayjs().day(DAYS.FRIDAY),
    ];
  }
  return week;
};

export const isDisabled = (day: dayjs.Dayjs): boolean => {
  // weekends
  if (day.day() === DAYS.SATURDAY || day.day() === DAYS.SUNDAY) {
    return true;
  }
  // is in past
  if (day.isBefore(dayjs())) {
    return true;
  }
  const isThreeDaysAhead = day.isAfter(dayjs().add(3, 'day'));
  const isBeforeFourDays = day.isBefore(dayjs().add(5, 'day'));
  const isBeforeThreeDays = day.isBefore(dayjs().add(4, 'day'));

  if (dayjs().day() === DAYS.SATURDAY && isBeforeFourDays) {
    return false;
  }
  if (dayjs().day() === DAYS.SUNDAY && isBeforeThreeDays) {
    return false;
  }
  if (isThreeDaysAhead) {
    if (today === DAYS.FRIDAY || today === DAYS.THURSDAY) {
      return false;
    }
    return true;
  }
  return false;
};

export const WORKING_HOURS = {
  eastern: [
    dayjs().hour(9).tz(TIMEZONES.EASTERN, true),
    dayjs().hour(10).tz(TIMEZONES.EASTERN, true),
    dayjs().hour(11).tz(TIMEZONES.EASTERN, true),
    dayjs().hour(12).tz(TIMEZONES.EASTERN, true),
    dayjs().hour(13).tz(TIMEZONES.EASTERN, true),
    dayjs().hour(14).tz(TIMEZONES.EASTERN, true),
    dayjs().hour(15).tz(TIMEZONES.EASTERN, true),
    dayjs().hour(16).tz(TIMEZONES.EASTERN, true),
    dayjs().hour(17).tz(TIMEZONES.EASTERN, true),
    dayjs().hour(18).tz(TIMEZONES.EASTERN, true),
  ],
  central: [
    dayjs().hour(8).tz(TIMEZONES.CENTRAL, true),
    dayjs().hour(9).tz(TIMEZONES.CENTRAL, true),
    dayjs().hour(10).tz(TIMEZONES.CENTRAL, true),
    dayjs().hour(11).tz(TIMEZONES.CENTRAL, true),
    dayjs().hour(12).tz(TIMEZONES.CENTRAL, true),
    dayjs().hour(13).tz(TIMEZONES.CENTRAL, true),
    dayjs().hour(14).tz(TIMEZONES.CENTRAL, true),
    dayjs().hour(15).tz(TIMEZONES.CENTRAL, true),
    dayjs().hour(16).tz(TIMEZONES.CENTRAL, true),
    dayjs().hour(17).tz(TIMEZONES.CENTRAL, true),
  ],
  pacific: [
    dayjs().hour(6).tz(TIMEZONES.PACIFIC, true),
    dayjs().hour(7).tz(TIMEZONES.PACIFIC, true),
    dayjs().hour(8).tz(TIMEZONES.PACIFIC, true),
    dayjs().hour(9).tz(TIMEZONES.PACIFIC, true),
    dayjs().hour(10).tz(TIMEZONES.PACIFIC, true),
    dayjs().hour(11).tz(TIMEZONES.PACIFIC, true),
    dayjs().hour(12).tz(TIMEZONES.PACIFIC, true),
    dayjs().hour(13).tz(TIMEZONES.PACIFIC, true),
    dayjs().hour(14).tz(TIMEZONES.PACIFIC, true),
    dayjs().hour(15).tz(TIMEZONES.PACIFIC, true),
  ],
  mountain: [
    dayjs().hour(7).tz(TIMEZONES.MOUNTAIN, true),
    dayjs().hour(8).tz(TIMEZONES.MOUNTAIN, true),
    dayjs().hour(9).tz(TIMEZONES.MOUNTAIN, true),
    dayjs().hour(10).tz(TIMEZONES.MOUNTAIN, true),
    dayjs().hour(11).tz(TIMEZONES.MOUNTAIN, true),
    dayjs().hour(12).tz(TIMEZONES.MOUNTAIN, true),
    dayjs().hour(13).tz(TIMEZONES.MOUNTAIN, true),
    dayjs().hour(14).tz(TIMEZONES.MOUNTAIN, true),
    dayjs().hour(15).tz(TIMEZONES.MOUNTAIN, true),
    dayjs().hour(16).tz(TIMEZONES.MOUNTAIN, true),
  ],
};

export const DAYJS_FORMAT_DATETIME = 'YYYY-MM-DDTHH:mm:ss';

export const DAYJS_DATE_FORMAT = 'MMMM DD, YYYY';

export const DAYJS_SHORTDATE_FORMAT = 'MM/DD/YY';

export const DAYJS_TIME_FORMAT = 'HH:mm A';

export const DAYJS_DATE_AND_TIME = 'MMMM\xa0DD, YYYY - hh:mm\xa0A'; // \xa0 stands for unicode for no break space

export const DAYJS_SHORTDATE_AND_TIME = 'MM/DD/YY - hh:mm\xa0A'; // \xa0 stands for unicode for no break space

export const DAYJS_JOB_INTERVIEW_DATE_FORMAT = 'YYYY-MM-DD';
