import { useTranslation } from 'react-i18next';
import Home from '../Home/Home';
import classes from './PasswordReset.module.css';
import logo from '../../images/logoBlue.svg';
import PasswordResetForm from './PasswordResetForm';

const ForgotPasswordScreen = () => {
  const [t] = useTranslation('global');
  return (
    <Home>
      <section className={classes.container}>
        <h1 className={classes.header}>{t('resetPassword.title')} </h1>
        <div className={classes.divider} />
        <p className={classes.subHeader}>{t('resetPassword.instructions')}</p>
        <div className={classes.card}>
          <div>
            <img src={logo} alt="logo" />
          </div>
          <PasswordResetForm />
        </div>
      </section>
    </Home>
  );
};

export default ForgotPasswordScreen;
