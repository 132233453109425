import {
  FC,
  ChangeEventHandler,
  Ref,
  FunctionComponent,
  SVGProps,
} from 'react';
import classes from './Radio.module.css';
import { ReactComponent as RadioButton } from '../../icons/RadioButton.svg';
import { ReactComponent as RadioButtonChecked } from '../../icons/RadioButtonIcon.svg';
// import { ReactComponent as RadioButtonCheck } from '../../icons/RadioButtonCheck.svg';
import useWindowWidth from '../../hooks/useWindowWidth';

type Shape = 'rectangle' | 'square';

interface IProps {
  shape?: Shape;
  name: string;
  label: string;
  disabled?: boolean;
  value: number | string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  refProp: Ref<any>;
  checked: boolean;
  img?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  secondary?: boolean;
}
const Radio: FC<IProps> = ({
  shape,
  name,
  label,
  disabled,
  value,
  onChange,
  refProp,
  checked,
  img: Img,
  secondary,
}) => {
  const isMobile = useWindowWidth() < 548;
  const backgroundClass = secondary
    ? classes.custom_radio_secondary
    : classes.custom_radio;

  const backgroundSquareClass = secondary
    ? classes.custom_radio_square_secondary
    : classes.custom_radio_square;

  const backgroundIcons = secondary
    ? 'var(--brand-green-10)'
    : 'var(--brand-blue-100)';

  return shape === 'rectangle' || isMobile ? (
    <div className={classes.radio_buttons}>
      <label className={backgroundClass} htmlFor={`${name}-${value}`}>
        <input
          ref={refProp}
          data-testid="radio-container"
          type="radio"
          name={name}
          id={`${name}-${value}`}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
        <span className={classes.radio_btn}>
          <div className={classes.content}>
            {Img && (
              <div
                style={{
                  background: checked ? 'rgba(229, 255, 251, 0.2)' : '',
                }}
                data-testid="radio-img"
                className={classes.radio_img}
              >
                <Img
                  fill={checked ? '#fff' : backgroundIcons}
                  style={{ display: 'block', margin: 'auto' }}
                />
              </div>
            )}
            <div className={classes.label} data-testid="radio-label">
              <h2>{label}</h2>
            </div>
            <div className={classes.icon_container}>
              {checked ? (
                <RadioButtonChecked
                  data-testid="radio-true-icon"
                  fill="white"
                />
              ) : (
                <RadioButton data-testid="radio-false-icon" />
              )}
            </div>
          </div>
        </span>
      </label>
    </div>
  ) : (
    <div className={classes.radio_buttons} data-testid="radio-square">
      <label className={backgroundSquareClass} htmlFor={`${name}-${value}`}>
        <input
          ref={refProp}
          type="radio"
          name={name}
          id={`${name}-${value}`}
          checked={checked}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
        <span className={classes.square_btn}>
          <div className={classes.content_square}>
            <div className={classes.icon_container_square}>
              {checked ? <RadioButtonChecked fill="white" /> : <RadioButton />}
            </div>
            <div
              style={{ background: checked ? 'rgba(229, 255, 251, 0.2)' : '' }}
              className={classes.radio_img_square}
            >
              {!Img ? (
                <div />
              ) : (
                <Img
                  fill={checked ? '#fff' : backgroundIcons}
                  style={{ display: 'block', margin: 'auto' }}
                />
              )}
            </div>
          </div>
        </span>
        <div className={classes.label_square}>
          <h2> {label} </h2>
        </div>
      </label>
    </div>
  );
};

Radio.defaultProps = {
  shape: 'rectangle',
  disabled: false,
  img: undefined,
  secondary: false,
};
export default Radio;
