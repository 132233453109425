import { ReactComponent as Mac } from '../icons/MacIcon.svg';
import { ReactComponent as Windows } from '../icons/WindowsIcon.svg';
import { ReactComponent as Linux } from '../icons/LinuxIcon.svg';
import { ReactComponent as Others } from '../icons/SuspensivePoints.svg';

const OperatingSystem: {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> & {
    title?: string | undefined;
  };
} = {
  Mac,
  Windows,
  Linux,
  Others,
};
const getIcon = (os: string) => {
  const Render = OperatingSystem[os];
  return Render || Others;
};
export default getIcon;
