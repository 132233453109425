import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  AdditionalVideoBuilder,
  GetAdditionalVideoByIdInput,
  GetAdditionalVideoByIdOutput,
} from '../additional-videos.types';

export const getAdditionalVideoById = (builder: AdditionalVideoBuilder) =>
  builder.query<GetAdditionalVideoByIdOutput, GetAdditionalVideoByIdInput>({
    query: ({ id }) => ({
      url: `additional-video/${id}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            { type: 'additionalVideos', id: result.Id },
            { type: 'additionalVideos', id: 'LIST' },
          ]
        : [{ type: 'additionalVideos', id: 'LIST' }],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[LOAD_ADDITIONAL_VIDEO_BY_ID]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
