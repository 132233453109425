import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { FormTypes } from '../../../../types/user_info';
import { errorHandler } from '../../../utils';
import {
  CandidateSkillBuilder,
  GetCandidateSkillsInput,
  GetCandidateSkillsOutput,
} from '../candidate-skill.types';

export const getCandidateSkills = (builder: CandidateSkillBuilder) =>
  builder.query<GetCandidateSkillsOutput, GetCandidateSkillsInput>({
    query: ({ candidateId }) => {
      return {
        url: `/candidate-skills/${candidateId}`,
        method: 'GET',
      };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.map((skill) => ({
              type: 'candidateSkills' as const,
              id: skill?.Id || 0,
            })),
            { type: 'candidateSkills', id: 'LIST' },
          ]
        : [{ type: 'candidateSkills', id: 'LIST' }],

    transformResponse: (response: FormTypes['skillsBackendPayload'][]) =>
      response.map((skill) => ({
        Id: skill?.Id,
        CertificationId: { val: skill?.CertificationId },
        Skill: { Title: skill?.Skill.Title },
        SkillLevel: { val: skill?.SkillLevel },
        certDate: {
          val: skill?.CertificationDate
            ? `${skill?.CertificationDate.split('-')[0]}-${
                skill?.CertificationDate.split('-')[1]
              }`
            : '',
        },

        CertificationUrl: { val: skill?.CertificationUrl },
      })),
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setLanguagesAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
