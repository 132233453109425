import { FC } from 'react';
import { TFunction } from 'react-i18next';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';
import classes from './AccountButton.module.css';
import capitalizedName from '../../utils/capitalizedName';
import { useGetProfilePictureQuery } from '../../store/modules/uploads';
import { useAppSelector } from '../../hooks';

interface AccountButtonProps {
  blue?: boolean;
  action?: () => void;
  t: TFunction<'global', undefined>;
  isProfile?: boolean;
}

const defaultProps = {
  blue: false,
  isProfile: false,
  action: () => {},
};
const AccountButton: FC<AccountButtonProps> = ({
  blue,
  action,
  t,
  isProfile,
}) => {
  const { serverUser } = useAppSelector((state) => state.auth);
  const { data: profilePicture } = useGetProfilePictureQuery({});

  const fullName = `${serverUser?.FirstName.split(' ')[0]} ${
    serverUser?.LastName.split(' ')[0]
  }`;
  return (
    <button
      data-testid="account-button"
      type="button"
      className={classes.Container}
      onClick={action}
    >
      <p
        data-testid="title"
        className={blue || isProfile ? classes.BlueTitle : classes.Title}
      >
        {serverUser ? capitalizedName(fullName) : t('session.login')}
      </p>
      <div className={classes.defaultIconContainer} data-testid="defaultIcon">
        <DefaultIcon2
          firstName={serverUser?.FirstName}
          lastName={serverUser?.LastName}
          picture={profilePicture?.Url}
        />
      </div>
    </button>
  );
};

AccountButton.defaultProps = defaultProps;

export default AccountButton;
