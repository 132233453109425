import { GetCvInput, GetCvOutput, UploadBuilder } from '../uploads.types';

export const getCv = (builder: UploadBuilder) =>
  builder.query<GetCvOutput, GetCvInput>({
    query: (option) => ({
      url: `/upload/cv/${option.candidateId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            {
              type: 'Upload',
              id: result.Id,
            },
            { type: 'Upload', id: 'List' },
          ]
        : [{ type: 'Upload', id: 'List' }],
    transformResponse: (response: GetCvOutput) => {
      return response;
    },
  });
