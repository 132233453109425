import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BrokenVideo } from '../../icons/BrokenVideo.svg';
import classes from './VideoErrorScreen.module.css';

const VideoErrorScreen = () => {
  const [t] = useTranslation('global');
  return (
    <section data-testid="video-error-container">
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <BrokenVideo fill="gray" />
        </div>
      </div>
      <hr />
      <p>{t('VideoModal.Error')}</p>
    </section>
  );
};

export default VideoErrorScreen;
