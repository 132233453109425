import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  AddCandidateJobInterestInternalInput,
  AddCandidateJobInterestInternalOutput,
  CandidateJobInterestBuilder,
} from '../candidate-job-interest.types';

export const addCandidateJobInterestInternal = (
  builder: CandidateJobInterestBuilder
) =>
  builder.mutation<
    AddCandidateJobInterestInternalOutput,
    AddCandidateJobInterestInternalInput
  >({
    query: ({ data, candidateId }) => {
      const url = data?.Id
        ? `/candidate-job-interest/${data?.Id}`
        : `/candidate-job-interest/add/internal/${candidateId}`;
      const method = data?.Id ? 'PUT' : 'POST';
      return {
        url,
        method,
        body: {
          ...data,
          Id: data?.Id,
          SalaryLowerBound: data?.Salary[0],
          SalaryUpperBound: data?.Salary[1],
        },
      };
    },
    invalidatesTags: [
      {
        type: 'CandidateJobInterest',
        id: 'LIST',
      },
      'Score',
    ],

    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setJobInterestAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        false
      );
    },
  });
