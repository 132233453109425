import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CandidatesBackButton from '../../../CandidateDetails/BackButton/CandidatesBackButton';
import classes from './AdditionalVideosInternal.module.css';
import {
  VideoSection,
  VideoGalleryFull,
  ActiveVideoModal,
} from '../components';

import SimeraDashboardLayout from '../../../SimeraDashboard/SimeraDashboardLayout';
import InternalDashboardPages from '../../../../constants/InternalDashboardPages';

export const AdditionalVideosInternalScreen = () => {
  const navigate = useNavigate();
  const [t] = useTranslation('global');
  const { candidateId } = useParams();
  return (
    <SimeraDashboardLayout activeTab={InternalDashboardPages.CANDIDATES}>
      <section className={classes.layout}>
        <CandidatesBackButton
          text={`${t('AdditionalVideos.backButton')}`}
          onClick={() =>
            navigate(`/simera-dashboard/candidates/${candidateId}`, {
              replace: true,
            })
          }
          color="#079E88"
        />
        <br />
        <VideoSection>
          <VideoGalleryFull internal />
        </VideoSection>
      </section>
      <ActiveVideoModal internal />
    </SimeraDashboardLayout>
  );
};
