import React, { useState } from 'react';
import capitalizedName from '../../../../../utils/capitalizedName';
import { AdditionalVideo } from '../../../../../types/additional-video/additional-video';
import { useAdditionalVideo } from '../../../../../store/modules/additional-videos/hooks';
import { calculateDays } from '../../utils';
import classes from './VideoCard.module.css';
import { HideVideoModal } from '../HideVideoModal';
import { HideVideo } from '../HideVideo';
import { ShowVideoModal } from '../ShowVideoModal';
import { ShowVideo } from '../ShowVideo';

interface Props {
  video: AdditionalVideo;
  showCheckbox: boolean;
  selectedVideos: number[];
  setSelectedVideos: React.Dispatch<React.SetStateAction<number[]>>;
  internal?: boolean;
  setSelectedShowVideos: React.Dispatch<React.SetStateAction<number[]>>;
  selectedShowVideos: number[];
}
export const VideoCard = ({
  video,
  showCheckbox,
  selectedVideos,
  setSelectedVideos,
  internal,
  setSelectedShowVideos,
  selectedShowVideos,
}: Props) => {
  const { setVideo, openAdditionalVideoModal } = useAdditionalVideo();
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const styleOpacityVideos =
    ((selectedShowVideos.length && video.IsVisible) ||
      (selectedVideos.length && !video.IsVisible)) &&
    showCheckbox
      ? classes.opacityVideos
      : '';

  const handleHideVideos = () => {
    setSelectedVideos((prevSelectedVideos) => {
      if (prevSelectedVideos.includes(video.Id)) {
        return prevSelectedVideos.filter((id) => id !== video.Id);
      }
      return [...prevSelectedVideos, video.Id];
    });
  };

  const handleShowVideos = () => {
    setSelectedShowVideos((prevSelectedVideos) => {
      if (prevSelectedVideos.includes(video.Id)) {
        return prevSelectedVideos.filter((id) => id !== video.Id);
      }
      return [...prevSelectedVideos, video.Id];
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (showCheckbox) {
      if (video.IsVisible && !selectedShowVideos.length) {
        handleHideVideos();
      }
      if (!video.IsVisible && !selectedVideos.length) {
        handleShowVideos();
      }
    } else {
      e.stopPropagation();
      setVideo(video);
      openAdditionalVideoModal();
    }
  };

  return (
    <>
      <div
        className={`${classes.card} ${styleOpacityVideos}`}
        tabIndex={0}
        role="button"
        onKeyDown={() => setVideo(video)}
        onClick={handleClick}
      >
        {internal && video.IsVisible && !selectedShowVideos.length && (
          <HideVideo
            selectedVideos={selectedVideos}
            setSelectedVideos={setSelectedVideos}
            setShowVisibilityModal={setShowVisibilityModal}
            showCheckbox={showCheckbox}
            video={video}
          />
        )}
        {!video.IsVisible && internal && (
          <div className={classes.hideVideo}>
            {!selectedVideos.length && (
              <ShowVideo
                video={video}
                showCheckbox={showCheckbox}
                setShowVideoModal={setShowVideoModal}
                selectedVideos={selectedShowVideos}
                setSelectedVideos={setSelectedShowVideos}
              />
            )}
          </div>
        )}
        <video className={classes.video} muted>
          <source src={video.Url} type="video/mp4" />
        </video>
        <div className={classes.container}>
          <p className={classes.cardTitle}>{capitalizedName(video.Name)}</p>
          <p className={classes.videoDate}>{`${calculateDays(
            video.CreateTime
          )}`}</p>
        </div>
      </div>

      {showVisibilityModal && (
        <HideVideoModal setModal={setShowVisibilityModal} video={video} />
      )}

      {showVideoModal && (
        <ShowVideoModal setModal={setShowVideoModal} video={video} />
      )}
    </>
  );
};

VideoCard.defaultProps = {
  internal: false,
};
