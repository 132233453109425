import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useApplyInterviewSlotMutation } from '$store/modules/interview';
import { ReactComponent as ArrowRight } from '$icons/ArrowRight.svg';
import { useTranslation } from 'react-i18next';
import { DAYJS_DATE_FORMAT, DAYJS_TIME_FORMAT } from '$constants/datetimes';

type AvailableInterviewSlotCardProps = {
  datetime: Dayjs;
  scheduleId: number;
  createTime: Dayjs;
};

const AvailableInterviewSlotCard = ({
  datetime,
  scheduleId,
  createTime,
}: AvailableInterviewSlotCardProps) => {
  dayjs.extend(relativeTime);

  const [t] = useTranslation('global');
  const [applyInterviewSlot] = useApplyInterviewSlotMutation();

  const confirmTimeHandler = async () => {
    await applyInterviewSlot({ scheduleId });
  };

  return (
    <div className="bg-gray-100 min-w-fit p-4 border border-gray-200 rounded">
      <div className="font-semibold leading-5 mb-4">
        <div className="capitalize">
          {datetime.local().format(DAYJS_DATE_FORMAT)}
        </div>
        <div>{`${datetime.local().format(DAYJS_TIME_FORMAT)} ${t(
          'candidateJobInterview.localTime'
        )}`}</div>
      </div>
      <div className="text-gray-500 mb-4 text-sm first-letter:capitalize">
        {createTime.fromNow()}
      </div>
      <button
        onClick={confirmTimeHandler}
        type="button"
        className="bg-candidate-main px-6 py-2 text-white font-semibold rounded shadow flex flex-row items-center gap-4 text-nowrap disabled:bg-gray-400 disabled:cursor-not-allowed"
      >
        {t('candidateJobInterview.confirmTime')} <ArrowRight />
      </button>
    </div>
  );
};

export default AvailableInterviewSlotCard;
