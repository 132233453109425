import {
  ChangeEventHandler,
  FC,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ChangeHandler } from 'react-hook-form';
import {
  AutocompleteOption,
  InitialFormStateTypes,
} from '../../types/user_info';
import classes from './Autocomplete.module.css';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';

interface AutocompleteProps {
  options: AutocompleteOption[];
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur: ChangeHandler;
  refProp: Ref<any>;
  name: string;
  setValue: any;
  placeholder?: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > | null;
  showArrow?: boolean;
  errorText?: string;
  clearErrors?: any;
  currSearchVal?: string;
  disabled?: boolean;
}

const defaultProps = {
  placeholder: '',
  icon: null,
  showArrow: true,
  errorText: '',
  clearErrors: undefined,
  currSearchVal: '',
  disabled: false,
};

const AutocompleteRHF: FC<AutocompleteProps> = ({
  options,
  label,
  name,
  onBlur,
  onChange,
  refProp,
  placeholder,
  setValue,
  icon: Icon,
  showArrow,
  errorText,
  clearErrors,
  currSearchVal = '',
  disabled,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const autocompleteRef = useRef<any>(null);
  const [focused, setFocused] = useState(false);
  const [validSkill, setValidSkill] = useState(true);

  const clickOutsideHandler = useCallback(
    (evt: Event) => {
      if (
        autocompleteRef &&
        autocompleteRef.current &&
        !autocompleteRef.current.contains(evt.target)
      ) {
        const value = options.find((ele) => ele.title === searchVal);
        const replace = name.lastIndexOf('.');
        if (value) {
          setValue(
            replace === -1
              ? name
              : (`${name.substring(
                  0,
                  replace
                )}.Id` as unknown as keyof InitialFormStateTypes),
            value.Id
          );
          setValue(name as keyof InitialFormStateTypes, value.title, {
            shouldDirty: true,
          });
        }
        setShowOptions(false);
      }
    },
    [options, name, searchVal, setValue]
  );

  useEffect(() => {
    window.addEventListener('mousedown', clickOutsideHandler);
    return () => {
      window.removeEventListener('mousedown', clickOutsideHandler);
    };
  }, [clickOutsideHandler]);

  useEffect(() => {
    setSearchVal(currSearchVal);
  }, [currSearchVal]);

  useEffect(() => {
    const valid = options.some((ele) => ele.title === searchVal);
    setValidSkill(valid);
  }, [options, searchVal]);

  const selectOption = (option: AutocompleteOption) => {
    setSearchVal(option.title);
    const replace = name.lastIndexOf('.');
    setValue(
      replace === -1
        ? name
        : (`${name.substring(
            0,
            replace
          )}.Id` as unknown as keyof InitialFormStateTypes),
      option.Id
    );
    setValue(name as keyof InitialFormStateTypes, option.title, {
      shouldDirty: true,
    });

    setShowOptions(false);
    if (clearErrors) {
      clearErrors(name);
    }
  };
  const onChangeInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(evt.target.value);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  const handleInputKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const optionsUl: HTMLElement | null = document.querySelector(
      '#autocompleteOptions'
    ) as HTMLElement;

    const firstLi: HTMLLIElement | null = optionsUl?.querySelector(
      'li:first-child'
    ) as HTMLLIElement;

    if (
      evt.key === 'ArrowDown' &&
      showOptions &&
      optionsUl &&
      optionsUl.childNodes.length > 0 &&
      firstLi
    ) {
      firstLi.focus();
    } else if (evt.key === 'Enter' && !errorText) {
      setShowOptions((s) => !s);
    }
  };

  const handleOptionsKeyDown = (evt: React.KeyboardEvent<HTMLElement>) => {
    const focusedEl = evt.currentTarget;
    const prevEl: HTMLLIElement =
      focusedEl.previousElementSibling as HTMLLIElement;
    const nextEl: HTMLLIElement = focusedEl.nextElementSibling as HTMLLIElement;

    if (evt.key === 'ArrowDown' && showOptions && nextEl) {
      nextEl.focus();
    } else if (evt.key === 'ArrowUp' && showOptions && prevEl) {
      prevEl.focus();
    } else if (evt.key === 'Enter') {
      const option: HTMLLIElement = evt.currentTarget as HTMLLIElement;
      setSearchVal(option.title);
      const replace = name.lastIndexOf('.');
      setValue(
        replace === -1
          ? name
          : (`${name.substring(
              0,
              replace
            )}.Id` as unknown as keyof InitialFormStateTypes),
        option.value
      );
      setValue(name as keyof InitialFormStateTypes, option.title);
      setShowOptions(false);
      if (clearErrors) {
        clearErrors(name);
      }
    }
  };
  return (
    <div
      data-testid="autocomplete"
      ref={autocompleteRef}
      style={{ position: 'relative', width: '100%' }}
      className={classes.InputContainer}
    >
      <p
        className={classes.Label}
        data-testid="input-placeholder"
        onClick={() => {
          if (!validSkill) setSearchVal('');
        }}
        aria-hidden
      >
        {label}
      </p>
      <div
        className={`${classes.AutocompleteInputWrapper} ${
          focused && classes.AutocompleteInputFocus
        } ${errorText && !validSkill && classes.ErrorBorder}`}
      >
        {Icon && (
          <div
            className={`${classes.IconContainer} ${classes.IconContainerLeft}`}
          >
            <Icon />
          </div>
        )}
        <input
          name={name}
          onChange={(evt) => {
            onChangeInput(evt);
            if (onChange) onChange(evt);
          }}
          onBlur={(e) => {
            onBlur(e);
            setFocused(false);
            if (!showOptions && !validSkill) {
              setSearchVal('');
            }
          }}
          disabled={disabled}
          onFocus={() => setFocused(true)}
          value={searchVal}
          ref={refProp}
          autoComplete="off"
          onClick={() => setShowOptions((x) => !x)}
          className={
            Icon
              ? `${classes.AutocompleteInput} ${classes.AutocompleteInputIcon}`
              : classes.AutocompleteInput
          }
          placeholder={placeholder}
          onKeyDown={handleInputKeyDown}
        />
        {showArrow && !disabled && (
          <div
            aria-label="show options"
            className={classes.IconContainer}
            onClick={() => {
              setShowOptions((s) => !s);
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <ArrowDown />
          </div>
        )}
      </div>
      {showOptions && (
        <ul
          id="autocompleteOptions"
          className={classes.options}
          defaultValue={1}
        >
          {options
            .filter(
              (opt) =>
                opt.title.toLowerCase().indexOf(searchVal.toLowerCase()) >= 0
            )
            .map((opt) => (
              <li
                id={`option-${opt.title}`}
                tabIndex={opt.Id}
                title={opt.title}
                value={opt.Id}
                key={opt.Id}
                onClick={() => selectOption(opt)}
                onKeyDown={handleOptionsKeyDown}
                role="presentation"
              >
                {opt.title}
              </li>
            ))}
        </ul>
      )}
      {errorText && !validSkill && (
        <p className={classes.ErrorText} data-testid="input-error">
          {errorText}
        </p>
      )}
    </div>
  );
};

AutocompleteRHF.defaultProps = defaultProps;

export default AutocompleteRHF;
//
