export const SliderHandleStyle = {
  backgroundColor: 'white',
  border: '2.5px solid #05668D',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2),' +
    '0px 2px 2px rgba(0, 0, 0, 0.14),' +
    '0px 1px 5px rgba(0, 0, 0, 0.12)',
};

export const SliderHandleStyleInternal = {
  backgroundColor: 'white',
  border: '2.5px solid #079E88',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2),' +
    '0px 2px 2px rgba(0, 0, 0, 0.14),' +
    '0px 1px 5px rgba(0, 0, 0, 0.12)',
};

export const SliderActiveDotStyle = {
  border: 'unset',
  backgroundColor: 'white',
  width: '2px',
  height: '2px',
  bottom: '1px',
};

export const SliderDotStyle = {
  display: 'none',
};

export const SliderTrackStyle = {
  backgroundColor: '#05668D',
};
export const SliderTrackStyleInternal = {
  backgroundColor: '#079E88',
};

export const SliderRailStyle = {
  backgroundColor: '#BFC1C6',
  // opacity: '38%',
};

export const SliderDefaultValue: number[] = [2000, 3000];
export const SliderMin: number = 0;
export const SliderMax: number = 25000;
export const SliderMarkers = {
  100: '$1',
  2000: '',
  4000: '',
  6000: '',
  8000: '',
  10000: '',
  12000: '',
  14000: '',
  16000: '',
  18000: '',
  20000: '',
  22000: '',
  24000: '',
  25000: '+$25K',
};
