import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReadOnly from '../ReadOnly/ReadOnly';
import EducationalInformationForm from '../EducationalHistoryForm/EducationalInformationForm';
import NewEducation from '../EducationalHistoryForm/NewEducation';
import EditableContainerInternal from '../EditableContainer/EditableContainerInternal';
import useInternalEditableContainer from '../../hooks/useInternalEditableContainer';
import { useGetCandidateEducationQuery } from '../../store/modules/candidate-education';

interface Props {
  isActive: boolean;
  secondary: boolean;
}

const EducationalInfoEditableContainerInternal: FC<Props> = ({
  isActive,
  secondary,
}) => {
  const [t, i18n] = useTranslation('global');
  const { candidateId } = useParams();
  const { data, isLoading } = useGetCandidateEducationQuery({
    lang: i18n.language as 'es' | 'en',
    candidateId: +candidateId!,
  });

  const {
    newFormStep: newEducation,
    handleCancel,
    setIsEdit,
    handleCancelModal,
    show,
    isEdit,
    onClickSave,
    onClickPencil,
    onClickPlus,
  } = useInternalEditableContainer();

  const newEducationOrField = () => {
    if (newEducation) {
      return (
        <NewEducation
          onClickCancel={handleCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={handleCancelModal}
          show={show}
          onClickSave={onClickSave}
          newEducation
          secondary={secondary}
          countForms={data?.length || 1}
        />
      );
    }
    return (
      !isLoading &&
      data?.map(
        (education) =>
          education?.Id && (
            <ReadOnly
              key={`educationalInfo-${education?.Id}`}
              title={education?.SchoolName.val!}
              subtitle={`${education?.DegreeType.val}, ${education?.DegreeName.val}`}
              endDate={
                !education?.Enrolled
                  ? education?.YearGraduated.val!
                  : `${t('formNames.enrolled')}`
              }
              secondary={secondary}
            />
          )
      )
    );
  };
  return (
    <EditableContainerInternal
      title={t('formNames.education')}
      isEditable={secondary && isEdit}
      onClickPencil={onClickPencil}
      onClickPlus={onClickPlus}
      hasAddMore
      addMore={newEducation ? t('education.experience') : false}
      isActive={isActive}
    >
      {!isEdit ? (
        <EducationalInformationForm
          isEdit
          setEdit={setIsEdit}
          onClickCancel={handleCancel}
          onClickCancelModal={handleCancelModal}
          show={show}
          secondary
        />
      ) : (
        newEducationOrField()
      )}
    </EditableContainerInternal>
  );
};

export default EducationalInfoEditableContainerInternal;
