import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessPerson } from '../../../icons/SuccessPerson.svg';
import { ReactComponent as SimeraLogo } from '../../../images/logoBlue.svg';
import classes from './PasswordResetSuccess.module.css';
import Button from '../../Buttons/Button';

interface IProps {
  onClickButton: () => void;
}
const ResetSuccessCard: FC<IProps> = ({ onClickButton }) => {
  const [t] = useTranslation('global');

  return (
    <div className={classes.SuccessCard} data-testid="success-card">
      <SimeraLogo />
      <SuccessPerson />
      <p className={classes.successTitle}>
        {t('forgotPass.resetSuccessTitle')}
      </p>
      <p className={classes.successMessage}>
        {t('forgotPass.resetSuccessMessage')}
      </p>

      <div className={classes.LoginButton}>
        <Button
          customStyles={{ boderRadius: '2px' }}
          onClick={onClickButton}
          text={t('login.login')}
          bgColor="#05668D"
          showText
          submit
        />
      </div>
    </div>
  );
};

export default ResetSuccessCard;
