import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classes from './CandidateResume.module.css';
import { ReactComponent as PDFIcon } from '../../../../icons/PDFIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../../icons/Download.svg';
import { segmentTrack } from '../../../../utils/handleSegment';

interface Props {
  resumeData: {
    Url: string;
    Key: string;
    Id: number;
  };
  secondary?: boolean;
}

const CandidateResume: FC<Props> = ({ resumeData, secondary }) => {
  const { Url: url, Key: key, Id: id } = resumeData;
  const [t] = useTranslation('global');
  const fill = secondary ? '#079e88' : '#05668d';
  const { candidateId } = useParams();

  const onDownloadHandler = () => {
    segmentTrack('CV Downloaded', {
      candidate_id: Number(candidateId),
      file_id: Number(id),
    });
  };

  return (
    <div className={classes.resumeContainer} data-testid="candidate-resume">
      {!resumeData || (!url && !key) ? (
        <h3 className={classes.resumeTitle}>{t('SimeraInternal.NoResume')}</h3>
      ) : (
        <>
          <h3 className={classes.resumeTitle}>{t('SimeraInternal.Resume')}</h3>
          <a
            className={classes.link}
            href={url}
            download
            onClick={onDownloadHandler}
            target="_blank"
            rel="noreferrer"
          >
            <PDFIcon fill={fill} style={{ flex: '0 0 auto' }} />
            <p style={{ overflow: 'hidden', flex: '1' }}>
              {key?.slice()?.split('-')?.pop() || ''}
            </p>
            <DownloadIcon
              className={classes.CheckIcon}
              style={{ flex: '0 0 auto' }}
            />
          </a>
        </>
      )}
    </div>
  );
};

CandidateResume.defaultProps = {
  secondary: false,
};
export default CandidateResume;
