// these are the messages that will be shown on the toasts when there is an error or after an action completed successfully
export enum ErrorMessageEn {
  deleteVideoInternal = 'Could not delete video',
  UploadVideoInternal = 'Upload video failed',
  USER_NOT_CREATED = 'Could not create user',
  UploadCV = 'Error uploading CV',
  UploadProfilePicture = 'Error uploading profile picture',
  AuthFail = 'Email or password are invalid.',
  SessionExpired = 'Your session has expired. Please login again',
  ResetPassword = 'No candidate was found with this email. Check your spelling and try again.',
  InfoFail = "We couldn't get your information",
  SkillExists = "You can't add the same skill twice",
  GetCandidatesFail = "We couldn't find candidates",
  GetTasksFail = "We couldn't find any task",
  PostTaskFail = "We couldn't import files",
  UpdateCandidatesFail = "We couldn't update the candidate",
  UpdateCandidateFail = "We couldn't update your profile",
  WrongUserType = 'Wrong user type, use the corresponding login type for your account',
  UserNotFoundOnServer = 'User not found, please register in order to continue with this email',
  UserAlreadyRegistered = 'Email or password are invalid.',
  DeleteError = 'An error occurred while deleting',
  InvalidToken = 'Invalid token',
  SamePassword = "You can't use a previous password as new password",
  ServerError = 'Something unexpected happened. Please try again',
  ReferralError = 'Referral error',
  PositionSaveError = 'Error saving position',
  loadingCustomRoster = 'Error loading roster',
  PositionDuplicateError = 'Error duplicating position',
  PositionSetError = 'Error getting positions',
  UpdateCompany = 'Error updating company',
  InternalError = 'Please use a simera email account.',
  AddCandidateToPositionError = 'Error adding candidate to position',
  CandidateAlreadyAddedError = 'Candidate has already been added to this position',
  VideoDeleted = 'There was an error deleting the video',
  CandidateDeletionError = 'Error deleting candidate from position',
  CandidatePositionStatusError = 'Unable to complete this request',
  OfferRequestEmailToSimeraError = 'There was an error trying to send the mail',
  CandidatePositionLoadCandidates = 'Error loading candidates',
  CandidatePositionLoadPositions = 'Error loading positions',
  CanditatePositionStatusError = 'Error getting position status',
  CompaniesSetError = 'Error getting companies',
  requestInterview = 'Error requesting interview',
  ErrorUnsubscribing = 'Error unsubscribing',
  ErrorSubscribing = 'Error subscribing',
  ErrorGettingEmployees = 'Error getting employees',
  DeletePosition = 'Error deleting position',
  DeactivatePosition = 'Error deactivating position',
  ActivatePosition = 'Error activating position',
  ErrorGettingInternalCandidates = 'Error getting candidates',
  CREATE_CANDIDATE_NOTE_ERROR = 'Error creating candidate note',
  loadPaginatedCompaniesError = 'Error loading companies',
  updatePaginatedCompaniesError = 'Error updating companies',
  trackingHiredInfoError = 'Error saving  hired info',
  AdditionalDocumentError = 'Error uploading additional document',
  AdditionalDocumentDeleteError = 'Error deleting additional document',
  videoTooShort = 'Error uploading video, the video must be longer than 2 minutes',
  videoNotFound = 'There was an error uploading the vide. Not video found',
  videoUpload = 'There was an error uploading the video',
  setSchedules = 'Error in saving schedules',
  AdditionalVideoSavingError = 'Error uploading additional video',
  AdditionalVideoDeleteError = 'Error deleting additional video',
  UpdateAdditionalVideoError = 'Error updating additional video',
  UpdateAdditionalVideosError = 'Error updating additional videos',
  VideoRated = 'Error rating video',
  ApplyCandidate = 'Error applying to position',
  ErrorRetrievingReactions = 'Error retrieving reactions',
  ErrorSendingReaction = 'Error sending reaction',
  ErrorRemovingReaction = 'Error removing reaction',
  FeedbackError = 'There was an error submiting your feedback. Please try again later',
  ErrorRetrievingFeedback = 'Error retrieving feedback',
  ErrorRemovingFeedback = 'Error removing feedback',
  DuplicateGeneralErrorMessage = 'An error ocurred while trying to duplicate the position',
  AlreadyDuplicatedPosition = 'This position already exists in your account.',
  CandidateInterViewJobError = 'An error ocurred while confirming the time. Please try again later',
  DeleteInterviewSlot = 'Error deleting interview slot. Please try again later',
  SaveInterviewSlots = 'Error while saving schedules. Please try again later',
  ScheduleSaveError = 'Error while saving interviews schedules. Please try again later',
  InterviewWithoutScheduleError = 'Error while creating a slot for the interview.',
}

export enum ErrorMessageEs {
  videoUpload = 'Hubo un error subiendo el video',
  videoNotFound = 'Hubo un error subiendo el video. No se encontró el video',
  deleteVideoInternal = 'No se pudo eliminar el video',
  videoTooShort = 'El video debe ser de al menos 2 minutos',
  UploadVideoInternal = 'Error subiendo el video',
  USER_NOT_CREATED = 'Usuario no se ha podido crear',
  loadingCustomRoster = 'Error cargando la plantilla',
  UploadCV = 'Error subiendo el CV',
  UploadProfilePicture = 'Error subiendo la foto de perfil',
  AuthFail = 'Correo o contraseña inválidos.',
  SessionExpired = 'Tu sesión ha expirado. Por favor inicia sesión nuevamente',
  ResetPassword = 'No se encontró un candidate con este correo. Por favor verifica tu ortgrafía e inténtalo de nuevo.',
  InfoFail = 'No pudimos obtener tu información',
  SkillExists = 'No se puede agregar la misma habilidad dos veces',
  GetCandidatesFail = 'No pudimos encontrar el candidato',
  UpdateCandidatesFail = 'No pudimos actualizar el candidato',
  UpdateCandidateFail = 'No pudimos actualizar tu perfil',
  WrongUserType = 'Usuario incorrecto, utiliza el tipo de autenticación correspondiente de tu cuenta',
  UserNotFoundOnServer = 'Usuario no encontrado, por favor registrate',
  UserAlreadyRegistered = 'Correo o contraseña inválidos.',
  DeleteError = 'Error al tratar de borrar',
  InvalidToken = 'Token inválido',
  SamePassword = 'No puedes usar una contraseña anterior como nueva contraseña',
  ServerError = 'Algo ha ocurrido. Por favor inténtalo de nuevo',
  ReferralError = 'Error de referencia',
  PositionSaveError = 'Error al guardar la posición',
  PositionDuplicateError = 'Error al duplicar posición',
  PositionSetError = 'Error al obtener las posiciones',
  UpdateCompany = 'Error al actualizar compañía',
  InternalError = 'Por favor utiliza una cuenta de correo de simera.',
  AddCandidateToPositionError = 'Error al agregar candidato a la posición',
  CandidateAlreadyAddedError = 'El candidato ya ha sido agregado a esta posición',
  VideoDeleted = 'Error al tratar de borrar el video',
  CandidateDeletionError = 'Error al eliminar candidato de la posición',
  CandidatePositionStatusError = 'No se puede completar esta solicitud',
  OfferRequestEmailToSimeraError = 'Hubo un error al intentar enviar el correo',
  CandidatePositionLoadCandidates = 'Error al cargar candidatos',
  CandidatePositionLoadPositions = 'Error al cargar posiciones',
  CanditatePositionStatusError = 'Error al obtener el estado de la posición',
  CompaniesSetError = 'Error al obtener las compañías',
  requestInterview = 'Error al solicitar entrevista',
  ErrorGettingEmployees = 'Error al obtener empleados',
  DeletePosition = 'Error eliminando la posición',
  DeactivatePosition = 'Error desactivando la posición',
  ActivatePosition = 'Error activando la posición',
  ErrorGettingInternalCandidates = 'Error al obtener candidatos',
  CREATE_CANDIDATE_NOTE_ERROR = 'Error al crear nota',
  loadPaginatedCompaniesError = 'Error al cargar compañías',
  updatePaginatedCompaniesError = 'Error al  actualizar compañías',
  trackingHiredInfoError = 'Error al guardar información de contratación',
  AdditionalDocumentError = 'Error al subir documento adicional',
  AdditionalDocumentDeleteError = 'Error al eliminar documento adicional',
  setSchedules = 'Error al guardar agenda',
  AdditionalVideoDeleteError = 'Error al eliminar video adicional',
  AdditionalVideoSavingError = 'Error al subir video adicional',
  UpdateAdditionalVideoError = 'Error al actualizar video adicional',
  UpdateAdditionalVideosError = 'Error al actualizar los videos adicionales',
  VideoRated = 'Error al valorar el video',
  ApplyCandidate = 'Error al aplicar a posición',
  ErrorRetrievingReactions = 'Error al obtener las reacciones',
  ErrorSendingReaction = 'Error al enviar la reacción',
  ErrorRemovingReaction = 'Error al eliminar la reacción',
  FeedbackError = 'Ha ocurrido un error al enviar tu opinión. Porfavor intenta más tarde',
  ErrorRetrievingFeedback = 'Error al obtener la opinión',
  ErrorRemovingFeedback = 'Error al eliminar la opinión',
  DuplicateGeneralErrorMessage = 'Un error ha sucedido al intentar duplicar la posición',
  AlreadyDuplicatedPosition = 'Esta posición ya existe en tu cuenta',
  CandidateInterViewJobError = 'Ha ocurrido un error al confirmar la hora. Porfavor intenta más tarde',
  DeleteInterviewSlot = 'Error al eliminar el horario. Porfavor intenta más tarde',
  SaveInterviewSlots = 'Error al guardar los horarios. Porfavor intenta más tarde',
  ScheduleSaveError = 'Error al guardar los horarios de las entrevistas. Porfavor intenta más tarde',
  InterviewWithoutScheduleError = 'Error al crear el espacio para la entrevista.',
}

export enum SuccessMessageEn {
  deleteVideoInternal = 'Video deleted successfully',
  UploadVideoInternal = 'video uploaded successfully',
  USER_CREATED = 'User created successfully',
  UploadCV = 'We got your CV!',
  UploadProfilePicture = 'We got your profile picture!',
  STEP1 = 'Step 1 completed!',
  STEP2 = 'Step 2 completed!',
  STEP3 = 'Step 3 completed!',
  STEP4 = 'Step 4 completed!',
  STEP5 = 'Step 5 completed!',
  TECHNICAL_INFO_SAVE = 'Last step!',
  SKILLS_SAVE = 'Changes saved!',
  DeleteSuccess = 'CV deleted successfully',
  PROFILE_UPDATE = 'Profile updated!',
  LoggedIn = 'Logged In!',
  Welcome = 'Welcome!',
  SavePosition = 'Position saved!',
  POSITION_DUPLICATED = 'Position duplicated successfully',
  ADD_CANDIDATE_TO_POSITION = 'Candidate added to position',
  CREATE_CANDIDATE_NOTE = 'Note created successfully',
  HIRE_CANDIDATE = 'Candidate hired successfully',
  MAKE_OFFER = 'Offer request sent successfully',
  UPDATE_STATUS = 'Status updated',
  UPDATE_CANDIDATE_NEW = 'Candidate Removed from Shortlist',
  UPDATE_CANDIDATE_SHORTLISTED = 'Candidate Added to Shortlist',
  MAKE_OFFER_EMAIL_SUCCESS = 'Email sent to the Simera team',
  videoDeleted = 'Video deleted',
  REMOVED_CANDIDATE_FROM_POSITION = 'Removed from Position',
  COMPANY_UPDATE = 'Company updated',
  REQUEST_INTERVIEW = 'Interview requested',
  SUCCESSFULLY_UNSUBSCRIBED = 'Unsubscribed successfully',
  SUCCESSFULLY_SUBSCRIBED = 'Subscribed successfully',
  DeletePosition = 'Position deleted',
  DeactivatePosition = 'Position deactivated',
  ActivatePosition = 'Position activated',
  AdditionalTagAdded = 'Additional tag added',
  AdditionalTagUpdated = 'Changes saved',
  CandidateTrackingSuccess = 'Candidate tracking updated',
  AdditionalDocumentSuccess = 'Additional document added',
  AdditionalDocumentUpdatedSuccess = 'Additional document updated',
  AdditionalDocumentDeletedSuccess = 'Additional document deleted',
  AdditionalVideoUpdatedSuccess = 'Additional video updated',
  AdditionalVideoDeletedSuccess = 'Additional video deleted',
  UpdateCandidate = 'Candidate updated',
  UpdateEmployer = 'Employer updated',
  SuccessfullyDeleted = 'Successfully deleted',
  ScheduleSaved = 'Interview Successfully Requested',
  AdditionalVideoSavingSuccess = 'Additional video uploaded successfully',
  UpdateAdditionalVideo = 'The additional video has been updated successfully',
  UpdateAdditionalVideos = 'The additional videos have been successfully updated',
  EditPosition = 'Position has been edited successfully',
  CandidateDeletion = 'The candidate have been deleted successfully',
  VideoRated = 'Succesfully video rated',
  ApplyCandidate = 'You have applied to position',
  CandidateInterviewConfirmTimeSuccess = 'Time confirmed successfully',
}

export enum SuccessMessageEs {
  deleteVideoInternal = 'Video eliminado exitosamente',
  COMPANY_UPDATE = 'Compañía actualizada',
  UploadVideoInternal = 'video subido exitosamente',
  USER_CREATED = 'Usuario creado satisfactoriamente',
  UploadCV = 'Tenemos tu CV!',
  UploadProfilePicture = 'Tenemos tu foto!',
  STEP1 = 'Paso 1 completado!',
  STEP2 = 'Paso 2 completado!',
  STEP3 = 'Paso 3 completado!',
  STEP4 = 'Paso 4 completado!',
  STEP5 = 'Paso 5 completado!',
  TECHNICAL_INFO_SAVE = 'Ya casi terminas!',
  SKILLS_SAVE = 'Cambios guardados!',
  PROFILE_UPDATE = '¡Pérfil actualizado!',
  DeleteSuccess = 'CV eliminado',
  LoggedIn = 'Iniciaste sesión!',
  Welcome = 'Bienvenido!',
  SavePosition = 'Posición guardada!',
  POSITION_DUPLICATED = 'Posición duplicada con éxito',
  ADD_CANDIDATE_TO_POSITION = 'Candidato agregado a la posición',
  HIRE_CANDIDATE = 'Candidato contratado con éxito',
  MAKE_OFFER = 'Solicitud de oferta enviada con éxito',
  UPDATE_STATUS = 'Estado actualizado',
  UPDATE_CANDIDATE_NEW = 'Candidato removido de preselección',
  UPDATE_CANDIDATE_SHORTLISTED = 'Candidato agregado a preselección',
  MAKE_OFFER_EMAIL_SUCCESS = 'Correo enviado al equipo de Simera',
  REMOVED_CANDIDATE_FROM_POSITION = 'Eliminado de la posición',
  videoDeleted = 'Video eliminado',
  CREATE_CANDIDATE_NOTE = 'Nota creada con éxito',
  REQUEST_INTERVIEW = 'Entrevista solicitada',
  DeletePosition = 'Posición eliminada',
  DeactivatePosition = 'Posición desactivada',
  ActivatePosition = 'Posición activada',
  AdditionalTagAdded = 'Etiqueta agregada',
  AdditionalTagUpdated = 'Cambios Guardados',
  CandidateTrackingSuccess = 'Informacion de seguimiento actualizada',
  AdditionalDocumentSuccess = 'Documento adicional agregado',
  AdditionalDocumentUpdatedSuccess = 'Documento adicional actualizado',
  AdditionalDocumentDeletedSuccess = 'Documento adicional eliminado',
  UpdateCandidate = 'Candidato actualizado',
  UpdateEmployer = 'Employer updated',
  SuccessfullyDeleted = 'Eliminado exitosamente',
  ScheduleSaved = 'Entrevista solicitada con éxito',
  AdditionalVideoSavingSuccess = 'Video adicional subido exitosamente',
  AdditionalVideoUpdatedSuccess = 'Video adicional actualizado',
  AdditionalVideoDeletedSuccess = 'Video adicional eliminado',
  UpdateAdditionalVideo = 'El video adicional ha sido actualizado exitosamente',
  UpdateAdditionalVideos = 'Los videos adicionales han sido actualizados exitosamente',
  EditPosition = 'La posición ha sido editada exitosamente',
  CandidateDeletion = 'El candidato ha sido eliminado exitosamente',
  VideoRated = 'Video valorado satisfactoriamente',
  ApplyCandidate = 'Has aplicado a posición',
  CandidateInterviewConfirmTimeSuccess = 'Horario confirmado exitosamente',
}
