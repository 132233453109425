import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ProfileMenu.module.css';

interface Props {
  link: React.RefObject<HTMLDivElement>;
  highlightedSectionIndex: number;
  updateLinkClicked: (status: boolean) => void;
  setHighlightedSection: React.Dispatch<React.SetStateAction<number>>;
  isEmployer?: boolean;
}

const ProfileMenu: FC<Props> = ({
  link,
  highlightedSectionIndex,
  updateLinkClicked,
  setHighlightedSection,
  isEmployer,
}) => {
  const [t] = useTranslation('global');

  function waitForScrollEnd(): Promise<void> {
    let lastChangedFrame = 0;
    let lastY = window.scrollY;

    return new Promise((resolve) => {
      function tick(frames: number) {
        if (frames >= 500 || frames - lastChangedFrame > 4) {
          resolve();
        } else {
          if (window.scrollY !== lastY) {
            lastChangedFrame = frames;
            lastY = window.scrollY;
          }
          requestAnimationFrame(tick.bind(null, frames + 1));
        }
      }
      tick(0);
    });
  }

  const goToSection = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    n: number
  ) => {
    setHighlightedSection((prev) => (prev !== n ? n : prev));
    e.preventDefault();

    if (typeof window !== 'undefined' && updateLinkClicked) {
      updateLinkClicked(true);
      const element = document.querySelector(`#section-${n}`);

      if (n === 1) {
        window.scrollTo({ top: 0 });
        await waitForScrollEnd();
        updateLinkClicked(false);
      } else if (element) {
        element.scrollIntoView(true);
        await waitForScrollEnd();
        updateLinkClicked(false);
      }
    }
  };

  const menuOptions = [
    ...(!isEmployer ? [{ id: 1, title: t('Resume.title') }] : []),
    { id: 2, title: t('workHistory.menuTitle') },
    { id: 3, title: t('jobs.title') },
    { id: 4, title: t('skills.skills') },
    { id: 5, title: t('education.title') },
    { id: 6, title: t('languages.langs') },
    { id: 7, title: t('extracurricular.menuTitle') },
    { id: 8, title: t('technical.title') },
    { id: 9, title: t('AdditionalDocs.tabTitle') },
  ];

  return (
    <div
      id="menu"
      className={classes.MenuContainer}
      data-testid="profile-menu-container"
      ref={link}
    >
      {menuOptions.map((option) => (
        <div
          aria-hidden="true"
          onClick={(e) => {
            goToSection(e, option.id);
          }}
          key={option.id}
          className={
            option.id === highlightedSectionIndex
              ? `${classes.menuOption} ${classes.selectedMenuOption}`
              : classes.menuOption
          }
        >
          {option.title}
        </div>
      ))}
    </div>
  );
};

ProfileMenu.defaultProps = {
  isEmployer: false,
};

export default ProfileMenu;
