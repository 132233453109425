import { useState } from 'react';
import axios from 'axios';
import { CHUNK_SIZE } from '$constants/VideoChunksUpload';
import {
  useCompleteChunkUploadAdditionalVideoMutation,
  useInitiateChunkUploadAdditionalVideoMutation,
  useLazyGetPresignedAdditionalVideoChunkUrlQuery,
} from '$store/modules/additional-videos';
import { UploadAdditionalVideoByChunksType } from '$types/additional-video/additional-video';

const useAdditionalVideoChunksUploader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [initiateChunkUploadAdditionalVideo] =
    useInitiateChunkUploadAdditionalVideoMutation();
  const [completeChunkUploadAdditionalVideo] =
    useCompleteChunkUploadAdditionalVideoMutation();
  const [getPresignedAdditionalVideoChunkUrl] =
    useLazyGetPresignedAdditionalVideoChunkUrlQuery();

  const uploadAdditionalVideoByChunks = async ({
    videoLabel,
    videoBlob,
    candidateId,
    isVisible = true,
  }: UploadAdditionalVideoByChunksType) => {
    setIsLoading(true);
    const totalParts = Math.ceil(videoBlob.size / CHUNK_SIZE);

    await initiateChunkUploadAdditionalVideo({ key: videoLabel })
      .then(async (initiateResponse) => {
        if ('data' in initiateResponse) {
          const presignedUrlPromises = [];
          for (let partNumber = 1; partNumber <= totalParts; partNumber += 1) {
            const start = (partNumber - 1) * CHUNK_SIZE;
            const end = Math.min(partNumber * CHUNK_SIZE, videoBlob!.size);
            const chunkBlob = videoBlob.slice(start, end);
            presignedUrlPromises.push(
              getPresignedAdditionalVideoChunkUrl({
                partNumber,
                key: initiateResponse?.data?.Key,
                uploadId: initiateResponse?.data?.UploadId,
              }).then((response) => ({
                url: response.data?.Url as string,
                chunkBlob,
                partNumber,
              }))
            );
          }

          return Promise.all(presignedUrlPromises).then((presignedData) => ({
            presignedData,
            initiateData: initiateResponse?.data,
          }));
        }
        return Promise.reject();
      })
      .then(({ presignedData, initiateData }) => {
        const uploadChunksPromises: any = [];
        presignedData.forEach(({ url, chunkBlob, partNumber }) => {
          uploadChunksPromises.push(
            axios.put(url, chunkBlob).then((response) => {
              return { ETag: response.headers.etag, PartNumber: partNumber };
            })
          );
        });

        return Promise.all(uploadChunksPromises).then(
          async (etagsAndPartNumbers) => {
            return completeChunkUploadAdditionalVideo({
              parts: etagsAndPartNumbers,
              key: initiateData.Key,
              uploadId: initiateData.UploadId,
              name: videoLabel,
              candidateId,
              isVisible,
            });
          }
        );
      })
      .then((completeUploadResponse) => {
        setIsLoading(false);
        return completeUploadResponse;
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return {
    uploadAdditionalVideoByChunks,
    isLoading,
  };
};

export default useAdditionalVideoChunksUploader;
