import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditableContainerNotes from '../../../../EditableContainer/EditableContainerNotes';
import NotesContent from '../NotesContent/NotesContent';
import AddNoteModal from '../AddNoteModal/AddNoteModal';
import { Note } from '../../../../../types/candidates';

interface Props {
  title: string;
  notes?: Partial<Note>[];
  positionId: number;
  isGeneralNotes?: boolean;
}
const PositionNotes: FC<Props> = ({
  notes,
  title,
  positionId,
  isGeneralNotes,
}) => {
  const [t] = useTranslation('global');
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <EditableContainerNotes
      title={title}
      onClickPlus={() => {
        setIsModalOpen(true);
      }}
      mobile={false}
      hasAddMore={!isModalOpen}
      pulseEffect={notes?.length === 0}
      hoverText={t('Notes.AddNote')}
      isGeneralNotes={isGeneralNotes}
    >
      <NotesContent notes={notes} isGeneral={positionId === 0} />
      <AddNoteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        positionId={positionId}
      />
    </EditableContainerNotes>
  );
};

PositionNotes.defaultProps = {
  notes: [],
  isGeneralNotes: false,
};

export default PositionNotes;
