import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from './AdditionalFile.module.css';
import { useUpdateVisibilityMutation } from '../../../store/modules/additional-documents';

interface Props {
  fileId: number;
  isCandidateVisibleDefaultValue: boolean;
  isEmployersVisibleDefaultValue: boolean;
}
interface FormData {
  IsCandidatesVisible: boolean;
  IsEmployersVisible: boolean;
}

const VisibilityForm: FC<Props> = ({
  fileId,
  isCandidateVisibleDefaultValue,
  isEmployersVisibleDefaultValue,
}) => {
  const [t, i18n] = useTranslation('global');
  const [updateVisibility] = useUpdateVisibilityMutation();
  const { register, watch } = useForm<FormData>({
    defaultValues: {
      IsCandidatesVisible: isCandidateVisibleDefaultValue,
      IsEmployersVisible: isEmployersVisibleDefaultValue,
    },
  });
  const isCandidateVisible = register('IsCandidatesVisible', {
    required: false,
  });
  const isEmployersVisible = register('IsEmployersVisible', {
    required: false,
  });
  const isCandidateVisibleValue = watch('IsCandidatesVisible');
  const isEmployersVisibleValue = watch('IsEmployersVisible');

  const saveInfo = (data: FormData) => {
    updateVisibility({
      fileId,
      data,
      lang: i18n.language as 'en' | 'es',
    });
  };
  return (
    <form data-testid="visibility-form">
      <div className={classes.visibilityContainer}>
        <label
          htmlFor={`${fileId}-file-candidates`}
          className={classes.checkBox}
        >
          <input
            type="checkbox"
            name={isCandidateVisible.name}
            ref={isCandidateVisible.ref}
            onChange={(e) => {
              isCandidateVisible.onChange(e);
              saveInfo({
                IsCandidatesVisible: e.target.checked,
                IsEmployersVisible: isEmployersVisibleValue,
              });
            }}
            onBlur={isCandidateVisible.onBlur}
            id={`${fileId}-file-candidates`}
          />
          {t('AdditionalDocs.Candidates')}
        </label>
        <label
          className={classes.checkBox}
          htmlFor={`${fileId}-file-employers`}
        >
          <input
            type="checkbox"
            name={isEmployersVisible.name}
            ref={isEmployersVisible.ref}
            onChange={(e) => {
              isEmployersVisible.onChange(e);
              saveInfo({
                IsEmployersVisible: e.target.checked,
                IsCandidatesVisible: isCandidateVisibleValue,
              });
            }}
            onBlur={isEmployersVisible.onBlur}
            id={`${fileId}-file-employers`}
          />
          {t('AdditionalDocs.Employers')}
        </label>
      </div>
    </form>
  );
};

export default VisibilityForm;
