export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const parseOrder = (
  val: string | null,
  defaultValue = Order.DESC
): Order => {
  if (!val) {
    return defaultValue;
  }

  const isValidOrder = [Order.ASC, Order.DESC].includes(val as Order);

  return isValidOrder ? (val as Order) : defaultValue;
};

export interface CandidatesPaginatedOptions {
  order?: Order;
  page?: number;
  take?: number;
  Id?: number;
  Name?: string;
  Email?: string;
  StartDate?: string;
  EndDate?: string;
}
