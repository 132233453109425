import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils';
import { ReducerPaths } from '../../constants/reducerPaths';
import { setInterviewSlots } from './mutations/set-interview-slots.mutation';
import {
  applyInterviewSlot,
  createEmptyInterview,
  deleteInterviewSlot,
  internalCreateAndSetInterview,
  modifySingleInterviewStatus,
} from './mutations';
import { getCandidateInterviews } from './queries/get-candidate-interviews.query';
import {
  getAllInterviews,
  getCandidateInterviewByCPS,
  getInterviewSlots,
  getInterviewSlotsAsInternal,
} from './queries';

export const interviewApi = createApi({
  reducerPath: ReducerPaths.INTERVIEW,
  baseQuery,
  endpoints: (builder) => ({
    getInterviewSlots: getInterviewSlots(builder),
    setInterviewSlots: setInterviewSlots(builder),
    deleteInterviewSlot: deleteInterviewSlot(builder),
    applyInterviewSlot: applyInterviewSlot(builder),
    getCandidateInterviews: getCandidateInterviews(builder),
    getCandidateInterviewByCPS: getCandidateInterviewByCPS(builder),
    getAllInterviews: getAllInterviews(builder),
    modifySingleInterviewStatus: modifySingleInterviewStatus(builder),
    getInterviewSlotsAsInternal: getInterviewSlotsAsInternal(builder),
    internalCreateAndSetInterview: internalCreateAndSetInterview(builder),
    CreateEmptyInterview: createEmptyInterview(builder),
  }),
  tagTypes: ['InterviewStatus', 'Slots', 'JobInterview'],
});

export const {
  useGetInterviewSlotsQuery,
  useSetInterviewSlotsMutation,
  useDeleteInterviewSlotMutation,
  useApplyInterviewSlotMutation,
  useGetCandidateInterviewsQuery,
  useLazyGetCandidateInterviewByCPSQuery,
  useGetAllInterviewsQuery,
  useModifySingleInterviewStatusMutation,
  useLazyGetInterviewSlotsAsInternalQuery,
  useInternalCreateAndSetInterviewMutation,
  useCreateEmptyInterviewMutation,
} = interviewApi;
