import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import dayjs from 'dayjs';
import classes from './WorkHistoryFormContainer.module.css';
import { countryNames } from '../../constants/countries';
import { SaveState } from '../../types/ForrmType';
import {
  AutocompleteOption,
  FieldTypes,
  FormTypes,
} from '../../types/user_info';
import SaveButton from '../Buttons/SaveButton';
import WorkHistoryForm from './WorkHistoryForm';
import FormFooter from '../FormFooter/FormFooter';
import CancelModal from '../CancelModal/CancelModal';
import { useGetJobPositionsQuery } from '../../store/modules/form-info';
import {
  useSaveWorkExperiencesInternalMutation,
  useSaveWorkExperiencesMutation,
} from '../../store/modules/work-experience';
import { errorHandler, successHandler } from '../../store/utils';
import { useAppDispatch } from '../../hooks';
import { candidatesApi } from '../../store/modules/candidates';

const MAX_JOB_EXPERIENCES = 10;
interface WorkHistoryProps {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean, continueEditing: boolean) => void;
  show?: boolean;
  onClickSave: () => void;
  newWorkHistory?: boolean;
  secondary?: boolean;
  countForms: number;
}

const NewWorkHistory: FC<WorkHistoryProps> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  onClickSave,
  newWorkHistory,
  secondary,
  countForms,
}) => {
  const { candidateId } = useParams();

  const [
    saveWorkHistoryInternal,
    {
      isLoading: isLoadingInternal,
      isSuccess: isSuccessInternal,
      isError: isErrorInternal,
    },
  ] = useSaveWorkExperiencesInternalMutation();
  const [saveWorkHistory, { isLoading, isSuccess, isError }] =
    useSaveWorkExperiencesMutation();

  const loadingState = useMemo(() => {
    if (isLoadingInternal || isLoading) {
      return SaveState.PENDING;
    }
    if (isSuccessInternal || isSuccess) {
      return SaveState.FULFILLED;
    }
    if (isErrorInternal || isError) {
      return SaveState.FAILED;
    }
    return SaveState.IDLE;
  }, [
    isError,
    isErrorInternal,
    isLoading,
    isLoadingInternal,
    isSuccess,
    isSuccessInternal,
  ]);

  const {
    register,
    clearErrors,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    defaultValues: {
      JobPosition: { Id: -1, title: '' },
      JobTitle: { val: '', mult: 0 },
      Company: { val: '', mult: 0 },
      Admission: { val: '', mult: 0 },
      Egress: { val: '', mult: 0 },
      Country: { val: '', mult: 0 },
      Description: { val: '', mult: 0 },
    },
    shouldFocusError: false,
  });

  const [t, i18next] = useTranslation('global');
  const { data: jobPositions } = useGetJobPositionsQuery({ t });
  const dispatch = useAppDispatch();

  const saveInfo = async (data: { [x: string]: any }) => {
    if (countForms >= MAX_JOB_EXPERIENCES) {
      errorHandler(
        'WorkHistoryFormContainer',
        'Work history limit reached',
        null,
        true
      );
      return;
    }
    if (data) {
      try {
        if (Number(candidateId)) {
          await saveWorkHistoryInternal({
            candidateId: Number(candidateId),
            lang: i18next.language as 'en' | 'es',
            data: [data as FormTypes['work']],
          });
          dispatch(candidatesApi.util.invalidateTags(['Score']));
        } else {
          await saveWorkHistory({
            lang: i18next.language as 'en' | 'es',
            data: [data as FormTypes['work']],
          });
          dispatch(candidatesApi.util.invalidateTags(['Score']));
        }
        successHandler(t('common.saved'), true);
        if (setEdit) {
          setEdit(true);
        }
        if (newWorkHistory) {
          onClickSave();
        }
        window.analytics.track('Extracurricular form submitted');
      } catch (error) {
        // error
      }
    }
  };

  const renderFields = () => {
    const jobTitle = register(`JobTitle.val`, { required: true });

    const jobPositionName = register(`JobPosition.title`, {
      required: true,
      validate: {
        jobPositionListed: (value) =>
          value
            ? jobPositions?.some(
                (position: { title: string }) => position.title === value
              )
            : false,
      },
    });

    const company = register(`Company.val`, { required: true });

    const admission = register(`Admission.val`, {
      required: {
        value: true,
        message: 'field required',
      },
      validate: {
        isValidDate: (value) =>
          value && getValues(`Egress.val`)
            ? dayjs(getValues(`Egress.val`)).isAfter(value)
            : true,
      },
    });

    const egress = register(`Egress.val`, {
      validate: {
        isValidDate: (value) =>
          value ? dayjs(getValues(`Admission.val`)).isBefore(value) : true,
      },
    });

    const country = register(`Country.val`, {
      validate: {
        isInList: (value) =>
          (value && countryNames.includes(value)) ||
          value === '' ||
          'Select a country from the list',
      },
    });

    const description = register(`Description.val`, {
      required: {
        value: true,
        message: 'Add at least 1 task you performed',
      },
    });

    return (
      <WorkHistoryForm
        row={{ Country: { val: '' } }}
        index={0}
        jobPositionName={jobPositionName}
        jobPositions={jobPositions}
        jobTitle={jobTitle}
        company={company}
        admission={admission}
        egress={egress}
        country={country}
        description={description}
        clearErrors={clearErrors}
        secondary={secondary}
        errors={errors}
        getValues={
          getValues as UseFormGetValues<
            | { workExperience: FormTypes['work'][] }
            | {
                JobPosition: AutocompleteOption;
                JobTitle: FieldTypes;
                Company: FieldTypes;
                Admission: FieldTypes;
                Egress: FieldTypes;
                Country: FieldTypes;
                Description: FieldTypes;
                Id?: number;
              }
          >
        }
        headerText="New WorkHistory"
        removeCard={() => {}}
        setValue={
          setValue as UseFormSetValue<
            | { workExperience: FormTypes['work'][] }
            | {
                JobPosition: AutocompleteOption;
                JobTitle: FieldTypes;
                Company: FieldTypes;
                Admission: FieldTypes;
                Egress: FieldTypes;
                Country: FieldTypes;
                Description: FieldTypes;
                Id?: number;
              }
          >
        }
        t={t}
        Id={0}
        delId={undefined}
        removeUnsavedCard={() => {}}
        newWork
      />
    );
  };

  const onInvalidSubmitHandler = (err: any) => {
    try {
      const registerKey = Object.keys(err);
      const input = err[registerKey[0]].val.ref;
      input.scrollIntoView({ block: 'center' });
    } catch (errored) {
      // nothing to do
    }
  };

  return (
    <div className={classes.MainContainer}>
      <form
        data-testid="form-container"
        className={classes.Container}
        onSubmit={handleSubmit(saveInfo, onInvalidSubmitHandler)}
      >
        <div className={classes.CardContainer}>{renderFields()}</div>
        <div className={classes.ButtonContainer}>
          {isEdit ? (
            <FormFooter
              onClickCancel={onClickCancel}
              disabled={loadingState === SaveState.PENDING || !isDirty}
              saveState={isDirty ? SaveState.IDLE : loadingState}
              translate={t}
              submit
            />
          ) : (
            <SaveButton
              disabled={loadingState === SaveState.PENDING || !isDirty}
              state={isDirty ? SaveState.IDLE : loadingState}
              t={t}
              submit
            />
          )}
        </div>
      </form>
      {show && onClickCancelModal && (
        <CancelModal onClickCancelModal={onClickCancelModal} />
      )}
    </div>
  );
};

NewWorkHistory.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  newWorkHistory: false,
  secondary: false,
};

export default NewWorkHistory;
