import axios, { Method } from 'axios';
import { useState, useMemo } from 'react';

const useAxios = (url: string, method: Method = 'GET') => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useMemo(() => {
    axios
      .request({
        url,
        method,
      })
      .then((response) => {
        setData(response?.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [url, method]);

  return { data, isLoading, error };
};

export default useAxios;
