import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import classes from './TrackHiredCandidateForm.module.css';
import { ReactComponent as ArrowUp } from '../../../../../../icons/CloseArrowColor.svg';
import { ReactComponent as ArrowDown } from '../../../../../../icons/OpenArrowColor.svg';

import BaseInput from '../../../../../Inputs/BaseInput';
import SaveButton from '../../../../../Buttons/SaveButton';
import TerminateFields from './TerminatedFields';
import useTrackingForm from '../../../../../../hooks/useTrackingForm';
import { EmployeesData } from '../../../../../../types/candidates';
import { EmployeeColumn } from '../../../../../../constants/CompanyColumn';

interface Props {
  employee: EmployeesData | undefined;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cps: EmployeeColumn;
}

const TrackHiredCandidateForm: FC<Props> = ({ employee, setIsOpen, cps }) => {
  const [t] = useTranslation('global');

  const {
    register,
    handleSubmit,
    watch,
    saveInfo,
    showContent,
    setShowContent,
    errors,
    isDirty,
    isButtonDisabled,
    buttonState,
  } = useTrackingForm(employee, setIsOpen, cps);

  const salary = register('Salary', {
    shouldUnregister: true,
  });
  const simeraFee = register('SimeraFee', {
    shouldUnregister: true,
  });
  const benefits = register('Benefits', {
    shouldUnregister: true,
  });
  const startDate = register('StartDate');

  return (
    <form onSubmit={handleSubmit(saveInfo)}>
      <div className={classes.formContainer} data-testid="tracking-form">
        <div className={classes.twoColumns}>
          <BaseInput
            label={t('Tracking.Salary')}
            type="number"
            step=".01"
            name={salary.name}
            onChange={salary.onChange}
            onBlur={salary.onBlur}
            refProp={salary.ref}
            errorText={errors.Salary?.message}
          />
          <BaseInput
            label={t('Tracking.SimeraFee')}
            type="number"
            step=".01"
            name={simeraFee.name}
            onChange={simeraFee.onChange}
            onBlur={simeraFee.onBlur}
            refProp={simeraFee.ref}
          />
        </div>
        <div className={classes.twoColumns}>
          <BaseInput
            label={t('Tracking.StartDate')}
            type="date"
            name={startDate.name}
            onChange={startDate.onChange}
            onBlur={startDate.onBlur}
            refProp={startDate.ref}
          />
          <BaseInput
            label={t('Tracking.Benefits')}
            type="number"
            step=".01"
            name={benefits.name}
            onChange={benefits.onChange}
            onBlur={benefits.onBlur}
            refProp={benefits.ref}
          />
        </div>
        <button
          className={classes.accordionTitle}
          type="button"
          onClick={() => setShowContent((v) => !v)}
        >
          {t('Tracking.FormerEmployees')}
          {!showContent ? <ArrowDown /> : <ArrowUp />}
        </button>
        {showContent && (
          <TerminateFields register={register} watch={watch} errors={errors} />
        )}
        <div className={classes.actions}>
          <button
            type="button"
            data-testid="acordeon-button"
            className={classes.normalButton}
            onClick={() => setIsOpen(false)}
          >
            {t('ConfirmAddToPositionModal.goBack')}
          </button>
          <SaveButton
            disabled={isButtonDisabled || !isDirty}
            submit
            t={t}
            state={buttonState}
          />
        </div>
      </div>
    </form>
  );
};

export default TrackHiredCandidateForm;
