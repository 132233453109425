import { ChangeEventHandler, FC, Ref, useState } from 'react';
import { ChangeHandler } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import classes from './BaseInput.module.css';
import PhoneNumberInput from '../RegisterForm/PhoneNumberInput/PhoneNumberInput';

interface LineInputProps {
  label?: string;
  type: React.HTMLInputTypeAttribute | undefined;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur: ChangeHandler;
  refProp: Ref<any>;
  name: string;
  value?: string;
  subText?: string;
  errorText?: string;
  errorDate?: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > | null;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  txtColor?: string;
  isPassword?: boolean;
  hasToolTip?: boolean;
  content?: React.ReactNode;
  minDate?: string;
  maxDate?: string;
  height?: string;
  isRequired?: boolean;
  step?: string | number | undefined;
  phone?: boolean;
  setDialCode?: (args: string) => void;
  dataTestId?: string;
}

const defaultProps = {
  label: undefined,
  subText: '',
  errorText: '',
  errorDate: '',
  icon: null,
  value: '',
  defaultValue: '',
  disabled: false,
  placeholder: '',
  txtColor: 'inherit',
  isPassword: false,
  hasToolTip: false,
  content: null,
  minDate: '',
  maxDate: '',
  height: '',
  isRequired: false,
  step: undefined,
  phone: false,
  setDialCode: undefined,
  dataTestId: 'base-input',
};

const BasicInput: FC<LineInputProps> = ({
  label,
  type,
  onChange,
  refProp,
  onBlur,
  name,
  value,
  subText,
  errorText,
  errorDate,
  icon: Icon,
  defaultValue,
  disabled,
  placeholder,
  txtColor,
  isPassword,
  hasToolTip,
  content: Content,
  minDate,
  maxDate,
  height,
  isRequired,
  step,
  phone,
  setDialCode,
  dataTestId,
}) => {
  const [inputType, setType] = useState<
    React.HTMLInputTypeAttribute | undefined
  >(type);
  const changeTypePass = () => {
    return inputType === 'password' ? setType('text') : setType('password');
  };
  return (
    <div data-testid="input-container" className={classes.InputContainer}>
      {label ? (
        <div className={`${classes.header} input-tt`}>
          <p
            className={`${classes.labelText} ${isRequired && classes.required}`}
            data-testid="input-placeholder"
          >
            {label}
          </p>
          {hasToolTip && <Tooltip>{Content}</Tooltip>}
        </div>
      ) : (
        <div style={{ marginTop: '1rem' }} />
      )}

      <div className={classes.InputFlex}>
        <input
          data-testid={dataTestId}
          type={inputType}
          className={`${
            inputType === 'link' ? classes.LinkStyle : classes.LineStyle
          } ${(errorText || errorDate) && classes.ErrorBorder} ${
            value && classes.InputValue
          } ${phone && classes.phonePadding}`}
          name={name}
          ref={refProp}
          onBlur={onBlur}
          onChange={onChange}
          defaultValue={defaultValue}
          disabled={disabled}
          step={step}
          placeholder={placeholder}
          style={{
            color: txtColor,
            height,
          }}
          min={minDate && minDate}
          max={maxDate && maxDate}
        />

        {Icon && (
          <Icon
            onClick={isPassword ? changeTypePass : undefined}
            className={classes.IconPos}
          />
        )}
        {phone && setDialCode && <PhoneNumberInput setDialCode={setDialCode} />}
      </div>
      {errorText === '' && errorDate === '' && subText !== '' && (
        <p className={classes.SubText} data-testid="input-subtext">
          {subText}
        </p>
      )}

      {errorText !== '' && (
        <p className={classes.ErrorText} data-testid="input-error">
          {errorText}
        </p>
      )}
      {errorDate !== '' && (
        <p className={classes.ErrorText} data-testid="input-error">
          {errorDate}
        </p>
      )}
    </div>
  );
};

BasicInput.defaultProps = defaultProps;

export default BasicInput;
