import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CompanyBuilder,
  GetPositionBySkillInput,
  GetPositionBySkillOutput,
} from '../companies.types';

export const getPreviousApplications = (builder: CompanyBuilder) =>
  builder.query<GetPositionBySkillOutput, GetPositionBySkillInput>({
    query: () => ({
      url: '/positions/previous-applications',
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ Id }) => ({ type: 'Company' as const, Id })),
            { type: 'Company', Id: 'LIST' },
          ]
        : [{ type: 'Company', Id: 'LIST' }],
    transformErrorResponse: (error, meta, args) => {
      errorHandler(
        '[getSuggestions]',
        args.lang === 'en'
          ? ErrorMessageEn.PositionSetError
          : ErrorMessageEs.PositionSetError,
        error,
        true
      );
      return error.data;
    },
  });
