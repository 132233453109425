import { FC, KeyboardEventHandler, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './EditableContainer.module.css';
import { ReactComponent as Pencil } from '../../icons/Pencil.svg';
import { ReactComponent as PlusIcon } from '../../icons/PlusIcon.svg';

interface EditableContainerProps {
  title: string;
  children: React.ReactNode;
  isEditable: boolean;
  newEdit?: boolean;
  onClickPlus?:
    | MouseEventHandler<HTMLDivElement>
    | KeyboardEventHandler<HTMLDivElement>;
  onClickPencil: () => void;
  hasAddMore?: boolean;
  addMore?: boolean | string | null | object;
  isActive?: boolean;
  showHeader?: boolean;
}

const EditableContainer: FC<EditableContainerProps> = ({
  title,
  children,
  isEditable,
  onClickPencil,
  onClickPlus,
  hasAddMore,
  newEdit,
  addMore,
  isActive,
  showHeader,
}) => {
  const [t] = useTranslation('global');

  return (
    <section
      className={`${classes.editableContainer} ${
        !isEditable || !!addMore ? classes.isEditing : ''
      } ${isActive ? classes.activeSection : ''}`}
      data-testid="editable-container"
    >
      {showHeader ? (
        <div className={classes.editableContainerHeader}>
          {isEditable ? (
            <p className={classes.editableContainerTitle}>
              {addMore
                ? `${
                    title === t('formNames.languages')
                      ? t('common.newWithO')
                      : t('common.new')
                  } ${addMore}`
                : title}
            </p>
          ) : (
            <p className={classes.editableContainerTitle}>
              {`${t('formNames.edit')}  ${title}`}
            </p>
          )}

          {isEditable && (
            <div
              data-testid="editable-actions"
              className={classes.editableContainerActions}
            >
              {hasAddMore && !newEdit && (
                <div
                  aria-label="add more"
                  className={classes.editableContainerActionItem}
                  onClick={onClickPlus as MouseEventHandler}
                  onKeyDown={onClickPlus as KeyboardEventHandler}
                  role="button"
                  tabIndex={0}
                >
                  <PlusIcon />
                </div>
              )}
              <div
                aria-label="edit"
                className={classes.editableContainerActionItem}
                onClick={onClickPencil}
                onKeyDown={onClickPencil}
                role="button"
                tabIndex={0}
              >
                <Pencil />
              </div>
            </div>
          )}
        </div>
      ) : undefined}
      {(!isEditable || addMore) && showHeader && (
        <div className={classes.editableContainerDivider} />
      )}
      <div className={classes.editableContainerContent}>{children}</div>
    </section>
  );
};

EditableContainer.defaultProps = {
  onClickPlus: undefined,
  hasAddMore: false,
  newEdit: false,
  addMore: false,
  isActive: false,
  showHeader: true,
};
export default EditableContainer;
