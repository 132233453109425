import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShareBlue } from '$icons/share-blue.svg';
import { ReactComponent as ShareGreen } from '$icons/share-green.svg';
import { segmentTrack } from '$utils/handleSegment';

interface ShareVideoButtonProps {
  internal?: boolean;
  shareReferenceId: string;
  candidateId: number;
}

const ShareVideoButton = ({
  internal,
  shareReferenceId,
  candidateId,
}: ShareVideoButtonProps) => {
  const [t] = useTranslation('global');
  const handleShare = async (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    const { origin } = window.location;
    const urlShare = `${origin}/share/candidate/${candidateId}/video/${shareReferenceId}`;
    try {
      await navigator.clipboard.writeText(urlShare);
      toast(t('buttonShare.alertCopySuccess'), {
        type: 'success',
        hideProgressBar: false,
      });
    } catch (err) {
      toast(t('buttonShare.alertCopyError'), {
        type: 'error',
        hideProgressBar: false,
      });
    } finally {
      segmentTrack('Video Link Shared and Copied', {
        candidate_id: candidateId,
        share_link: urlShare,
      });
    }
  };
  return internal ? (
    <ShareGreen onClick={handleShare} />
  ) : (
    <ShareBlue onClick={handleShare} />
  );
};

ShareVideoButton.defaultProps = {
  internal: false,
};

export default ShareVideoButton;
