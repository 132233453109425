import { CandidateData } from '../../../../types/candidates';

const filterBySkill = (
  singleCandidate: CandidateData,
  searchedSkills: string[]
) => {
  return (
    (singleCandidate.CandidateSkills &&
      searchedSkills.every((searchedSKill: string) => {
        return singleCandidate?.CandidateSkills?.some((skillItem) => {
          return (
            skillItem?.Skill &&
            skillItem?.Skill?.Title &&
            skillItem?.Skill?.Title?.toLowerCase()?.includes(
              searchedSKill.toLowerCase()
            )
          );
        });
      })) ??
    false
  );
};

export default filterBySkill;
