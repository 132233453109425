export const skillsDefaultValue = [
  {
    CertificationId: { val: '', mult: 3 },
    Skill: { val: '', mult: 2, Id: -1, title: '' },
    SkillLevel: { val: '', mult: 1 },
    CertificationUrl: { val: '', mult: 1 },
    certDate: { val: '', mult: 1 },
  },
];

export const languagesDefaultValue = [
  {
    LanguageName: { val: '', mult: 1 },
    LanguageProficiency: { val: '', mult: 2 },
    Score: { val: '1', mult: 3 },
  },
];

export const workHistoryDefaultValue = [
  {
    JobPosition: { Id: -1, title: '' },
    JobTitle: { val: '', mult: 1 },
    Company: { val: '', mult: 1 },
    Admission: { val: '', mult: 1 },
    Egress: { val: '', mult: 1 },
    Country: { val: '', mult: 1 },
    Description: { val: '', mult: 1 },
  },
];

export const educationalInformationDefaultValues = [
  {
    DegreeType: { val: '', mult: 1 },
    DegreeName: { val: '', mult: 1 },
    SchoolName: { val: '', mult: 1 },
    YearGraduated: { val: '', mult: 1 },
    Enrolled: false,
  },
];

export const technicalInfoDefaultValue = {
  CPUModel: '',
  RAM: '',
  Storage: '',
  OperatingSystem: '',
  AvailableSpace: '',
};

export const jobInterestsDefaultValue = {
  JobInterest: [],
  Salary: [2000, 3000],
};

export const extracurricularDefaultValue = [
  {
    ExtracurricularType: { val: '', mult: 1 },
    ExtracurricularDetail: { val: '', mult: 1 },
  },
];
