import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoBlue } from '../../images/logoBlue.svg';
import { ReactComponent as CloseIcon } from '../../icons/CloseBlue.svg';
import classes from './ReadyModal.module.css';

interface ReadyModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ReadyModal = ({ isOpen, setIsOpen }: ReadyModalProps) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const [t] = useTranslation('global');

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      className={classes.modal}
      overlayClassName={classes.overlay}
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
    >
      <div className={classes.header}>
        <LogoBlue />
        <div className={classes.closeIcon} onClick={handleClose} aria-hidden>
          <CloseIcon />
        </div>
      </div>
      <h1 className={classes.title}>{t('ReadyModal.title')}</h1>
      <p className={classes.description}>{t('ReadyModal.description')}</p>
    </Modal>
  );
};

export default ReadyModal;
