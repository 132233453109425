import { createApi } from '@reduxjs/toolkit/query/react';
import {
  getCandidateInfo,
  getCandidatesByPositionId,
  getInternalCandidates,
  loadCandidateNotesByCPS,
  getCandidates,
  getScore,
  findHiredCandidatePositionStatusByCompany,
} from './queries';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import {
  addCandidateToPosition,
  addCandidateToPositionAgain,
  applyCandidate,
  emailInternalAuth,
  hireCandidate,
  removeCandidateFromPosition,
  saveCandidateNote,
  updateCandidatePositionStatusToOfferSent,
  updateCandidatePositionStatus,
  updateCandidateProfile,
  updateCandidateScore,
  updateCandidateWorkSummary,
  uploadCandidateInfo,
} from './mutations';
import { updateCandidateCompleted } from './mutations/update-candidate-completed-mutation';
import { findCandidates } from './queries/find-candidates';
import { getCandidateInfoToken } from './queries/get-candidate-info-token.query';

export const candidatesApi = createApi({
  reducerPath: ReducerPaths.CANDIDATES,
  baseQuery,
  // keepUnusedDataFor: 240,
  refetchOnMountOrArgChange: 30,
  refetchOnReconnect: true,
  tagTypes: [
    'Candidate',
    'Candidates',
    'CandidateNotes',
    'CandidatePositionStatus',
    'Score',
  ],
  endpoints: (builder) => ({
    findHiredCandidatePositionStatusByCompany:
      findHiredCandidatePositionStatusByCompany(builder),
    updateCandidatePositionStatusToOfferSent:
      updateCandidatePositionStatusToOfferSent(builder),
    updateCandidatePositionStatus: updateCandidatePositionStatus(builder),
    addCandidateToPositionAgain: addCandidateToPositionAgain(builder),
    removeCandidateFromPosition: removeCandidateFromPosition(builder),
    updateCandidateWorkSummary: updateCandidateWorkSummary(builder),
    loadCandidateNotesByCPS: loadCandidateNotesByCPS(builder),
    addCandidateToPosition: addCandidateToPosition(builder),
    updateCandidateProfile: updateCandidateProfile(builder),
    getInternalCandidates: getInternalCandidates(builder),
    updateCandidateScore: updateCandidateScore(builder),
    uploadCandidateInfo: uploadCandidateInfo(builder),
    saveCandidateNote: saveCandidateNote(builder),
    emailInternalAuth: emailInternalAuth(builder),
    getCandidateInfo: getCandidateInfo(builder),
    getCandidatesByPositionId: getCandidatesByPositionId(builder),
    hireCandidate: hireCandidate(builder),
    getCandidates: getCandidates(builder),
    getScore: getScore(builder),
    updateCandidateCompleted: updateCandidateCompleted(builder),
    findCandidate: findCandidates(builder),
    applyCandidate: applyCandidate(builder),
    getCandidateInfoToken: getCandidateInfoToken(builder),
  }),
});

export const {
  useGetCandidatesQuery,
  useGetCandidatesByPositionIdQuery,
  useGetCandidateInfoQuery,
  useHireCandidateMutation,
  useEmailInternalAuthMutation,
  useSaveCandidateNoteMutation,
  useGetInternalCandidatesQuery,
  useUploadCandidateInfoMutation,
  useUpdateCandidateScoreMutation,
  useLoadCandidateNotesByCPSQuery,
  useAddCandidateToPositionMutation,
  useUpdateCandidateProfileMutation,
  useUpdateCandidateWorkSummaryMutation,
  useRemoveCandidateFromPositionMutation,
  useAddCandidateToPositionAgainMutation,
  useFindHiredCandidatePositionStatusByCompanyQuery,
  useUpdateCandidatePositionStatusToOfferSentMutation,
  useUpdateCandidatePositionStatusMutation,
  useGetScoreQuery,
  useUpdateCandidateCompletedMutation,
  useLazyFindCandidateQuery,
  useApplyCandidateMutation,
  useGetCandidateInfoTokenQuery,
} = candidatesApi;
