import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import classes from './CandidateFeedBackModal.module.css';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import {
  companiesApi,
  useRejectCandidateFromPositionEmployeeMutation,
} from '../../../../store/modules/companies';
import { FeedbackFormValues } from '../../../../types/candidates';
import { segmentTrack } from '../../../../utils/handleSegment';
import { useAppDispatch } from '../../../../hooks';
import { candidatesApi } from '../../../../store/modules/candidates';
import { ReactComponent as CloseIcon } from '../../../../icons/CloseBlue.svg';

interface Props {
  candidateId: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSwitchingId?: React.Dispatch<React.SetStateAction<number | null>>;
}

const CandidateFeedBackModal: FC<Props> = ({
  candidateId,
  isOpen,
  setIsOpen,
  setSwitchingId,
}) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const [t] = useTranslation('global');
  const [rejectCandidateFromPositionEmployee] =
    useRejectCandidateFromPositionEmployeeMutation();
  const { positionId } = useParams();
  const dispatch = useAppDispatch();
  const [selectedFeedbackText, setSelectedFeedbackText] = useState('');

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FeedbackFormValues>({
    mode: 'onChange',
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = ({ Feedback, FeedbackType }: FeedbackFormValues) => {
    if (positionId) {
      rejectCandidateFromPositionEmployee({
        query: {
          candidateId,
          positionId: +positionId,
          Feedback,
          FeedbackType,
        },
      }).then(() => {
        dispatch(candidatesApi.util.invalidateTags(['Candidates']));
        dispatch(companiesApi.util.invalidateTags(['PositionsList']));

        segmentTrack('Candidate Rejected', {
          candidate_id: candidateId,
          reject_type: parseInt(FeedbackType, 10),
          reject_reason: selectedFeedbackText,
        });
      });

      setTimeout(() => {
        setSwitchingId?.(candidateId);
      }, 1000);
      setIsOpen(false);
    }
  };
  const feedbackType = register('FeedbackType');
  const feedback = register('Feedback');
  return (
    <Modal
      className={classes.modal}
      overlayClassName={classes.overlay}
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
    >
      <div className={classes.closeIcon} onClick={handleClose} aria-hidden>
        <CloseIcon />
      </div>
      <h3 className={classes.title}>{t('FeedbackReject.Title')}</h3>
      <p className={classes.subText}>{t('FeedbackReject.Subtitle')}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FeedbackForm
          feedback={feedback}
          feedbackType={feedbackType}
          control={control}
          errors={errors}
          handleClose={handleClose}
          setSelectedFeedbackText={setSelectedFeedbackText}
        />
      </form>
    </Modal>
  );
};

CandidateFeedBackModal.defaultProps = {
  setSwitchingId: () => null,
};

export default CandidateFeedBackModal;
