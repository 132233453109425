import { useTranslation } from 'react-i18next';
import Home from '../../Home/Home';

import logo from '../../../images/logoBlue.svg';
import classes from './LoginScreen.module.css';
import SimeraLoginForm from '../../LoginForm/SimeraLoginForm';

const SimeraLoginScreen = () => {
  const [t] = useTranslation('global');
  return (
    <Home>
      <div className={classes.SimeraContainer}>
        <div data-testid="login-header" className={classes.loginHeader}>
          <p className={classes.loginHeader}>
            {t('employeeLoginScreen.login')}
          </p>
          <div className={classes.loginHeaderDivider} />
        </div>
        <div className={classes.loginCard}>
          <div className={classes.loginLogo}>
            <img src={logo} alt="logo" />
          </div>
          <SimeraLoginForm />
        </div>
      </div>
    </Home>
  );
};

export default SimeraLoginScreen;
