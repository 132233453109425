import React, { FC, useState, useEffect, useRef } from 'react';
import { ReactComponent as LocationIcon } from '../../../icons/Location.svg';
import { ReactComponent as ArrowDown } from '../../../icons/ArrowDown.svg';
import { ReactComponent as CloseBlue } from '../../../icons/CloseBlue.svg';
import { latamCountries } from '../../../constants/countries';
import classes from './SelectCountry.module.css';

interface Props {
  label: string;
  placeholder: string;
  setValue: any;
  errorText?: string;
}

const SelectCountry: FC<Props> = ({
  label,
  placeholder,
  setValue,
  errorText,
}) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [valueInput, setValueInput] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [showList, setShowList] = useState<boolean>(false);
  const [indexCountry, setIndexCountry] = useState<number>(0);

  const handleCountry = (name: string) => {
    setCountry(name);
    setValue('residenceCountry', name);
    setValueInput('');
    setShowList(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const countryInput = document.getElementById('country');
      const countryButton = document.getElementById('countryButton');
      if (
        countryInput &&
        countryButton &&
        event.target instanceof Node &&
        !countryInput.contains(event.target) &&
        !countryButton.contains(event.target)
      ) {
        setValueInput('');
        setShowList(false);
        setIndexCountry(0);
      }
      if (
        countryInput &&
        event.target instanceof Node &&
        countryInput.contains(event.target)
      ) {
        setShowList(true);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleFocusInput = () => {
    const countryInput = document.getElementById('country');
    if (countryInput) {
      countryInput.focus();
      setShowList(true);
    }
  };

  const handleInputChange = (inputValue: string) => {
    const input = inputValue.toLowerCase();
    const listCompleted = [...latamCountries];
    const found = listCompleted.find((name) =>
      name.toLowerCase().startsWith(input)
    );
    if (found) {
      setValueInput(inputValue);
      setIndexCountry(0);
    }
  };

  useEffect(() => {
    if (listRef.current && listRef.current.children[indexCountry]) {
      const selectedElement = listRef.current.children[
        indexCountry
      ] as HTMLElement;
      listRef.current.scrollTop =
        selectedElement.offsetTop - listRef.current.offsetTop;
    }
  }, [indexCountry]);

  return (
    <div className={classes.Container}>
      <label htmlFor="country" className={classes.Label}>
        {label} <span className={classes.Asterisk}>*</span>
      </label>
      <div
        className={`${classes.Select} ${
          errorText !== '' && classes.SelectError
        }`}
      >
        <LocationIcon />
        {country === '' && (
          <input
            className={classes.InputSelect}
            type="text"
            autoComplete="select-country"
            id="country"
            placeholder={placeholder}
            value={valueInput}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setCountry(valueInput);
                const listCompleted = [...latamCountries];
                const found = listCompleted.find((name) =>
                  name.toLowerCase().startsWith(valueInput.toLowerCase())
                );
                if (found) {
                  setCountry(
                    [...latamCountries].filter((name) =>
                      name.toLowerCase().startsWith(valueInput.toLowerCase())
                    )[indexCountry]
                  );
                  setValue(
                    'residenceCountry',
                    [...latamCountries].filter((name) =>
                      name.toLowerCase().startsWith(valueInput.toLowerCase())
                    )[indexCountry]
                  );
                }
                setValueInput('');
                setShowList(false);
              } else {
                handleFocusInput();
              }
              if (e.key === 'ArrowDown') {
                if (
                  indexCountry <
                  [...latamCountries].filter((name) =>
                    name.toLowerCase().startsWith(valueInput.toLowerCase())
                  ).length -
                    1
                ) {
                  setIndexCountry((index) => index + 1);
                }
              }
              if (e.key === 'ArrowUp') {
                if (indexCountry > 0) {
                  setIndexCountry((index) => index - 1);
                }
              }
            }}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        )}
        {country && (
          <button
            className={classes.CountrySelect}
            type="button"
            onClick={() => {
              setValueInput('');
              setCountry('');
              setValue('residenceCountry', '');
            }}
          >
            {country} <CloseBlue className={classes.CloseBlue} />
          </button>
        )}
        <button
          className={classes.Arrow}
          type="button"
          id="countryButton"
          onClick={handleFocusInput}
          aria-label="Show countries"
        >
          <ArrowDown />
        </button>
        {showList && (
          <div className={classes.List} ref={listRef}>
            {latamCountries
              .filter((name) =>
                name.toLowerCase().startsWith(valueInput.toLowerCase())
              )
              .map((name, i) => {
                const index = name
                  .toLowerCase()
                  .indexOf(valueInput.toLowerCase());
                const matchedPart = name.slice(
                  index,
                  index + valueInput.length
                );
                const beforeMatch = name.slice(0, index);
                const afterMatch = name.slice(index + valueInput.length);
                return (
                  <div
                    key={name}
                    className={
                      i === indexCountry ? classes.OptionActive : classes.Option
                    }
                    onClick={() => handleCountry(name)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => handleCountry(name)}
                  >
                    {beforeMatch}
                    <strong>{matchedPart}</strong>
                    {afterMatch}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {errorText !== '' && (
        <p className={classes.ErrorText} data-testid="input-error">
          {errorText}
        </p>
      )}
    </div>
  );
};

SelectCountry.defaultProps = {
  errorText: '',
};

export default SelectCountry;
