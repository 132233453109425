import {
  CompanyBuilder,
  LoadPositionInput,
  LoadPositionOutput,
} from '../companies.types';
import { errorHandler } from '../../../utils';
import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';

export const loadPositions = (builder: CompanyBuilder) =>
  builder.query<LoadPositionOutput, LoadPositionInput>({
    query: () => ({
      url: '/positions',
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ Id }) => ({ type: 'Company' as const, Id })),
            { type: 'Company', Id: 'LIST' },
          ]
        : [{ type: 'Company', Id: 'LIST' }],
    transformErrorResponse: (error, meta, args) => {
      errorHandler(
        '[setPositions]',
        args.lang === 'en'
          ? ErrorMessageEn.PositionSetError
          : ErrorMessageEs.PositionSetError,
        error,
        true
      );
      return error.data;
    },
  });
