import { ReactElement } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type DragAndDropSortableItemProps = {
  children: ReactElement;
  childrenId: number | string;
};

const DragAndDropSortableItem = ({
  children,
  childrenId,
}: DragAndDropSortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: childrenId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

export default DragAndDropSortableItem;
