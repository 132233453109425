import { FC } from 'react';
import { FileCategory, AdditionalDocument } from '../../../types/candidates';
import { AdditionalDocumentsContainer } from '../AdditionalDocumentsContainer';
import classes from './AdditionalDocuments.module.css';

interface AdditionalDocumentsProps {
  secondary?: boolean;
  fileCategories: FileCategory[];
  data: AdditionalDocument[];
}
const AdditionalDocuments: FC<AdditionalDocumentsProps> = ({
  secondary,
  fileCategories,
  data,
}) => {
  const mappedData = data.reduce((acc, doc) => {
    const category =
      fileCategories.find((cat) => cat.Id === doc.FileCategory?.Id)
        ?.CategoryName ?? 'N/A';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(doc);
    return acc;
  }, {} as { [key: string]: AdditionalDocument[] });

  return (
    <div className={classes.container}>
      {Object.keys(mappedData).map(
        (cat) =>
          mappedData[cat]?.length > 0 && (
            <AdditionalDocumentsContainer
              key={cat}
              title={cat}
              data={mappedData[cat]}
              secondary={secondary}
            />
          )
      )}
    </div>
  );
};

AdditionalDocuments.defaultProps = {
  secondary: false,
};

export default AdditionalDocuments;
