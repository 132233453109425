import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CustomModal from '../../Modal/Modal';
import modalStyles from '../modalStyles.module.css';
import { ReactComponent as CloseX } from '../../../icons/CloseX.svg';
import { ReactComponent as MailboxSvg } from '../../../icons/MailboxBigIcon.svg';
import { ReactComponent as SimeraBlueLogo } from '../../../icons/SimeraLogoBlue.svg';
import Button from '../../Buttons/Button';
import classes from '../RegisterForm.module.css';
import useResendEmailModal from '../../../hooks/useResendEmailModal';
import {
  resendConfirmationEmailAction,
  AuthState,
} from '../../../store/legacy/auth';
import { useAppDispatch } from '../../../hooks';

interface Props {
  showModal: boolean;
  isMobile: boolean;
  closeModal: () => void;
  authState: AuthState;
}

const ResendEmailModal: FC<Props> = ({
  showModal,
  isMobile,
  closeModal,
  authState,
}) => {
  const { disableEmailResendBtn, currentSecond } =
    useResendEmailModal(authState);
  const [t] = useTranslation('global');
  const resendEmailBtn = useRef<HTMLSpanElement>(null);
  const dispatch = useAppDispatch();
  const handleResendEmailConfirmation = () => {
    const employerEmail = authState.employer?.employerEmail;
    if (employerEmail) {
      dispatch(resendConfirmationEmailAction(employerEmail));
    }
  };
  const modalCustomStyles = isMobile
    ? {
        content: {
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          display: 'flex',
          flexDirection: 'column',
        },
        overlay: {
          backgroundColor: 'transparent',
        },
      }
    : {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          boxShadow:
            '0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)',
        },
        overlay: {
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
          zIndex: '1',
        },
      };
  return (
    <CustomModal
      isOpen={showModal}
      closeModal={closeModal}
      customStyles={modalCustomStyles}
      customClass={modalStyles}
      isMobile={isMobile}
      bg
      header={
        <header className={classes.ModalHeader}>
          <nav className={classes.ModalNav}>
            <SimeraBlueLogo width="143" height="35" />
            <Button
              icon={CloseX}
              iconStyles={{
                width: isMobile ? '30' : '14',
                height: isMobile ? '30' : '14',
                fill: '#909193',
              }}
              bgColor="transparent"
              showText={false}
              onClick={closeModal}
              customStyles={{
                paddingLeft: '1rem',
                width: 'auto',
                flexGrow: '0',
                marginBottom: '0',
                height: 'auto',
              }}
            />
          </nav>
        </header>
      }
    >
      <div className={classes.ContentContainer}>
        <div>
          <div className={classes.MailboxSvgContainer}>
            <MailboxSvg />
          </div>
          <h2 className={classes.ModalTitle}>
            {t('employerRegistrationSuccess.title')}
          </h2>
          <p className={classes.Body}>
            {t('employerRegistrationSuccess.bodyPara1')} <br />
            <strong>{`${authState.employer?.employerEmail}`}</strong>
          </p>
          <p className={classes.Body}>
            {t('employerRegistrationSuccess.bodyPara2')}
          </p>
        </div>
        <footer className={classes.Footer}>
          <p>{t('employerRegistrationSuccess.footerPara1')}</p>
          <p>
            {t('employerRegistrationSuccess.footerPara2')}{' '}
            <span
              className={`${classes.ResentEmailLink} ${
                disableEmailResendBtn ? classes.ResentEmailLinkDisabled : ''
              }`}
              role="button"
              onClick={handleResendEmailConfirmation}
              onKeyDown={handleResendEmailConfirmation}
              tabIndex={0}
              ref={resendEmailBtn}
            >
              {t('employerRegistrationSuccess.resendBtn')}
            </span>
            {disableEmailResendBtn && (
              <>
                {' '}
                <span>{currentSecond}</span>
              </>
            )}
          </p>
        </footer>
      </div>
    </CustomModal>
  );
};

export default ResendEmailModal;
