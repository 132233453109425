import { ErrorMessageEn, SuccessMessageEn } from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  UnsuscribeUserFromEmailInput,
  UserInfoBuilder,
} from '../user-info.types';

export const resubscribeUserToEmail = (builder: UserInfoBuilder) =>
  builder.mutation<void, UnsuscribeUserFromEmailInput>({
    query: ({ token }) => ({
      url: '/users/resubscribe',
      body: { token },
      method: 'PUT',
    }),
    transformResponse: () => {
      successHandler(SuccessMessageEn.SUCCESSFULLY_SUBSCRIBED, true);
    },
    transformErrorResponse: (error) => {
      errorHandler(
        '[SubscribeUserToEmails]',
        ErrorMessageEn.ErrorSubscribing,
        error,
        true
      );
    },
  });
