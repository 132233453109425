import { AdditionalVideo } from '../../../../../types/additional-video/additional-video';
import { VideoCard } from '../VideoCard';
import classes from './VideoList.module.css';

interface Props {
  videos: AdditionalVideo[];
  showCheckbox: boolean;
  setSelectedVideos: React.Dispatch<React.SetStateAction<number[]>>;
  selectedVideos: number[];
  internal?: boolean;
  setSelectedShowVideos: React.Dispatch<React.SetStateAction<number[]>>;
  selectedShowVideos: number[];
}
export const VideoList = ({
  videos,
  showCheckbox,
  setSelectedVideos,
  selectedVideos,
  internal,
  setSelectedShowVideos,
  selectedShowVideos,
}: Props) => {
  return (
    <div className={classes.videoCardContainer}>
      {videos.map((video) => (
        <VideoCard
          key={video.Id}
          video={video}
          showCheckbox={showCheckbox}
          selectedVideos={selectedVideos}
          setSelectedVideos={setSelectedVideos}
          internal={internal}
          setSelectedShowVideos={setSelectedShowVideos}
          selectedShowVideos={selectedShowVideos}
        />
      ))}
    </div>
  );
};

VideoList.defaultProps = {
  internal: false,
};
