import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '$constants/alerts';
import { userLanguage } from '$translations/index';
import { errorHandler, successHandler } from '../../../utils';
import {
  AdditionalVideoBuilder,
  CompleteChunkUploadAdditionalVideoInput,
} from '../additional-videos.types';

export const completeChunkUploadAdditionalVideo = (
  builder: AdditionalVideoBuilder
) =>
  builder.mutation<any, CompleteChunkUploadAdditionalVideoInput>({
    query: ({ key, uploadId, parts, name, candidateId, isVisible }) => ({
      url: '/additional-video/presigned-url/complete-upload',
      method: 'POST',
      body: {
        Key: key,
        UploadId: uploadId,
        Parts: parts,
        Name: name,
        CandidateId: candidateId,
        IsVisible: isVisible,
      },
    }),
    invalidatesTags: () => [{ type: 'additionalVideos' }],
    transformResponse: () => {
      successHandler(
        userLanguage === 'es'
          ? SuccessMessageEs.UploadVideoInternal
          : SuccessMessageEn.UploadVideoInternal,
        true
      );
    },
    transformErrorResponse: (error) => {
      errorHandler(
        '[INITIATE UPLOAD ADDITIONAL VIDEO IN CHUNKS]',
        userLanguage === 'es'
          ? ErrorMessageEs.videoUpload
          : ErrorMessageEn.videoUpload,
        error,
        true
      );
    },
  });
