export const handleSalaryRange = (
  minValue: string | null,
  maxValue?: string | null
): number[] | undefined => {
  if (minValue && !maxValue) {
    return [0, parseInt(minValue, 10)];
  }
  if (!minValue && maxValue) {
    return [0, parseInt(maxValue, 10)];
  }
  if (minValue && maxValue) {
    return [parseInt(minValue, 10), parseInt(maxValue, 10)];
  }
  return undefined;
};
