import { GetInterviewOutput, InterviewBuilder } from '../interview.types';

export const getInterviewSlots = (builder: InterviewBuilder) =>
  builder.query<GetInterviewOutput[], void>({
    query: () => ({
      url: '/interview/slots',
      method: 'GET',
    }),
    providesTags: () => [{ type: 'Slots' }],
  });
