import dayjs from 'dayjs';
import {
  UseFormRegister,
  FieldValues,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { FC, useState } from 'react';
import { FieldTypes, InitialFormStateTypes } from '../../types/user_info';
import SkillsForm from './SkillsForm';
import { useGetSkillsQuery } from '../../store/modules/form-info';

interface SkillsProps {
  register: UseFormRegister<
    | FieldValues
    | {
        Id?: number;
        CertificationId: FieldTypes;
        Skill: { val: string; mult: number; Id: number; title?: string };
        SkillLevel: FieldTypes;
        certDate: FieldTypes;
        CertificationUrl: FieldTypes;
      }
  >;
  removeSkill: (index: number, deleteId: number) => void;
  t: TFunction<'global', undefined>;
  setValue: UseFormSetValue<
    | InitialFormStateTypes
    | {
        Id?: number;
        CertificationId: FieldTypes;
        Skill: { val: string; mult: number; Id: number; title?: string };
        SkillLevel: FieldTypes;
        certDate: FieldTypes;
        CertificationUrl: FieldTypes;
      }
  >;
  removeUnsaved: (index: number) => void;
  getValues: UseFormGetValues<
    | InitialFormStateTypes
    | {
        Id?: number;
        CertificationId: FieldTypes;
        Skill: { val: string; mult: number; Id: number; title?: string };
        SkillLevel: FieldTypes;
        certDate: FieldTypes;
        CertificationUrl: FieldTypes;
      }
  >;
  errors: any;
}
const NewSkills: FC<SkillsProps> = ({
  register,
  removeSkill,
  t,
  setValue,
  removeUnsaved,
  errors,
  getValues,
}) => {
  const { data: skills } = useGetSkillsQuery({ t });
  const [Modal, setModal] = useState<boolean>(false);
  const [delId, setDelId] = useState();

  const SkillLevel = register(`SkillLevel.val`, {
    required: true,
  });
  const SkillName = register(`Skill.title`, {
    required: true,
    validate: {
      skillListed: (value) =>
        value ? skills?.some((skill) => skill.title === value) : false,
    },
  });
  const CertificationId = register(`CertificationId.val`);
  const CertificationUrl = register(`CertificationUrl.val`);
  const certDate = register(`certDate.val`, {
    validate: {
      isValidYear: (value) =>
        value
          ? dayjs(value).isBefore(dayjs().add(1, 'month'), 'month') &&
            dayjs(value).isAfter('1950', 'year')
          : true,
      isValidDate: (value) =>
        value ? dayjs(value, 'YYYY-MM', true).isValid() : true,
    },
  });
  return (
    <SkillsForm
      index={0}
      errors={errors}
      removeSkill={removeSkill}
      t={t}
      setValue={
        setValue as UseFormSetValue<
          | InitialFormStateTypes
          | {
              Id?: number;
              CertificationId: FieldTypes;
              Skill: {
                val: string;
                mult: number;
                Id: number;
                title?: string;
              };
              SkillLevel: FieldTypes;
              certDate: FieldTypes;
              CertificationUrl: FieldTypes;
            }
        >
      }
      getValues={
        getValues as UseFormGetValues<
          | InitialFormStateTypes
          | {
              Id?: number;
              CertificationId: FieldTypes;
              Skill: { val: string; mult: number; Id: number; title?: string };
              SkillLevel: FieldTypes;
              certDate: FieldTypes;
              CertificationUrl: FieldTypes;
            }
        >
      }
      skills={skills}
      SkillLevel={SkillLevel}
      SkillName={SkillName}
      CertificationId={CertificationId}
      CertificationUrl={CertificationUrl}
      certDate={certDate}
      row={{ Skill: { val: '', title: '' } }}
      Id={0}
      Modal={Modal}
      delId={delId && delId}
      setDelId={setDelId}
      removeUnsaved={removeUnsaved}
      setModal={setModal}
      newSkill
    />
  );
};

export default NewSkills;
