import React, { FC } from 'react';
import classes from './Button.module.css';

interface AddMoreFooterProps {
  onClick: () => void;
  label: string;
  secondary?: boolean;
}

const AddMoreFooter: FC<AddMoreFooterProps> = ({
  onClick,
  label,
  secondary,
}) => {
  const className = secondary
    ? classes.AddMoreFooterSecondary
    : classes.AddMoreFooter;
  return (
    <button
      data-testid="addMoreButton-test"
      type="button"
      onClick={onClick}
      className={className}
    >
      {label}
    </button>
  );
};

AddMoreFooter.defaultProps = {
  secondary: false,
};
export default AddMoreFooter;
