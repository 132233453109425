import {
  InterviewSlotStatusTypes,
  useModifySingleInterviewStatusMutation,
} from '$store/modules/interview';
import { segmentTrack } from '$utils/handleSegment';
import { ChangeEvent, useMemo } from 'react';
import { useForm } from 'react-hook-form';

type InternalJobInterviewStatusSelectProps = {
  currentStatus: InterviewSlotStatusTypes;
  Id: number;
  onSchedule: () => void;
};

const InternalJobInterviewStatusSelect = ({
  currentStatus,
  Id,
  onSchedule,
}: InternalJobInterviewStatusSelectProps) => {
  const { register, watch, setValue } = useForm({
    defaultValues: {
      [`statusSelect_${Id}`]:
        currentStatus === InterviewSlotStatusTypes.Requested
          ? InterviewSlotStatusTypes.NeedToSchedule
          : (currentStatus as keyof typeof InterviewSlotStatusTypes),
    },
  });

  const InterviewSlotStatusTypesKeys = Object.keys(
    InterviewSlotStatusTypes
  ) as Array<keyof typeof InterviewSlotStatusTypes>;

  const selectStatus = watch();

  const selectStatusBackgroundColor = useMemo(() => {
    switch (selectStatus[`statusSelect_${Id}`] || currentStatus) {
      case InterviewSlotStatusTypes.Rescheduled:
        return 'bg-jobInterviewStatus-rescheduledBg';
      case InterviewSlotStatusTypes.CompleteCandidateLate:
      case InterviewSlotStatusTypes.CompleteClientLate:
      case InterviewSlotStatusTypes.CompleteOnTime:
        return 'bg-jobInterviewStatus-completeBg';
      case InterviewSlotStatusTypes.CancelledCandidate:
      case InterviewSlotStatusTypes.CancelledClient:
        return 'bg-jobInterviewStatus-canceledBg';
      case InterviewSlotStatusTypes.Scheduled:
        return 'bg-jobInterviewStatus-scheduledBg';
      case InterviewSlotStatusTypes.NeedToSchedule:
      default:
        return 'bg-jobInterviewStatus-needToScheduleBg';
    }
  }, [currentStatus, selectStatus, Id]);

  const [modifySingleInterviewStatus] =
    useModifySingleInterviewStatusMutation();

  const onChangeStatusHandler = (ev: ChangeEvent<HTMLSelectElement>) => {
    const status =
      ev.target.value === InterviewSlotStatusTypes.NeedToSchedule
        ? InterviewSlotStatusTypes.Requested
        : ev.target.value;
    if (status === InterviewSlotStatusTypes.Scheduled) {
      onSchedule();
    } else {
      modifySingleInterviewStatus({
        interviewId: Id,
        status,
      }).then(() => {
        segmentTrack('Interviews - Interview Status Change');
        const selectValue = ev.target.value;
        setValue(
          `statusSelect_${Id}`,
          selectValue as keyof typeof InterviewSlotStatusTypes
        );
      });
    }
  };

  return (
    <select
      {...register(`statusSelect_${Id}`)}
      className={`px-4 py-1 rounded-xl ${selectStatusBackgroundColor}`}
      onChange={onChangeStatusHandler}
    >
      {InterviewSlotStatusTypesKeys.map((status) => {
        if (
          InterviewSlotStatusTypes[status] === InterviewSlotStatusTypes.Draft ||
          InterviewSlotStatusTypes[status] ===
            InterviewSlotStatusTypes.Requested
        )
          return null;
        return (
          <option
            value={InterviewSlotStatusTypes[status]}
            key={`${status}_${Id}_`}
          >
            {InterviewSlotStatusTypes[status]}
          </option>
        );
      })}
    </select>
  );
};

export default InternalJobInterviewStatusSelect;
