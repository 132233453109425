import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classes from './ExtracurricularForm.module.css';
import { FormTypes } from '../../types/user_info';
import { SaveState } from '../../types/ForrmType';
import SaveButton from '../Buttons/SaveButton';
import ExtracurricularForm from './ExtracurricularForm';
import CancelModal from '../CancelModal/CancelModal';
import FormFooter from '../FormFooter/FormFooter';
import {
  useAddCandidateExtracurricularsInternalMutation,
  useAddCandidateExtracurricularsMutation,
} from '../../store/modules/candidate-extracurricular';
import { errorHandler, successHandler } from '../../store/utils';
import { candidatesApi } from '../../store/modules/candidates';
import { useAppDispatch } from '../../hooks';

interface ICounters {
  [key: string]: number;
}

const MAX_EXTRACURRICULARS = 5;

interface ExtracurricularContainer {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean, continueEditing: boolean) => void;
  show?: boolean;
  onClickSave: () => void;
  newExtra?: boolean;
  secondary?: boolean;
  countForms: number;
}

const NewExtracurricular: FC<ExtracurricularContainer> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  onClickSave,
  secondary,
  newExtra,
  countForms,
}) => {
  const { candidateId } = useParams();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      ExtracurricularType: { val: '', mult: 0 },
      ExtracurricularDetail: { val: '', mult: 0 },
    },
  });

  const [addCandidateExtracurriculars, { isLoading, isSuccess, isError }] =
    useAddCandidateExtracurricularsMutation();
  const [
    addCandidateExtracurricularsInternal,
    {
      isLoading: isLoadingInternal,
      isSuccess: isSuccessInternal,
      isError: isErrorInternal,
    },
  ] = useAddCandidateExtracurricularsInternalMutation();

  const loadingExtracurriculars = useCallback(() => {
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }
    if (isSuccess || isSuccessInternal) {
      return SaveState.FULFILLED;
    }
    if (isError || isErrorInternal) {
      return SaveState.FAILED;
    }
    return SaveState.IDLE;
  }, [
    isError,
    isErrorInternal,
    isSuccess,
    isSuccessInternal,
    isLoading,
    isLoadingInternal,
  ]);

  const [t, i18next] = useTranslation('global');

  const saveInfo = async (data: { [x: string]: any }) => {
    if (countForms >= MAX_EXTRACURRICULARS) {
      errorHandler(
        '[ExtracurricularForm]',
        'Extracurricular information limit reached',
        null,
        true
      );
      return;
    }

    if (data) {
      try {
        if (Number(candidateId)) {
          await addCandidateExtracurricularsInternal({
            candidateId: Number(candidateId),
            lang: i18next.language as 'en' | 'es',
            data: [data as FormTypes['extracurricular']],
          });
          dispatch(candidatesApi.util.invalidateTags(['Score']));
        } else {
          await addCandidateExtracurriculars({
            lang: i18next.language as 'en' | 'es',
            data: [data as FormTypes['extracurricular']],
          });
          dispatch(candidatesApi.util.invalidateTags(['Score']));
        }
        if (setEdit) {
          setEdit(true);
        }
        if (newExtra) {
          onClickSave();
        }
        successHandler(t('common.saved'), true);
        window.analytics.track('Extracurricular form submitted');
      } catch (error) {
        // error
      }
    }
  };

  const [counter, setCounter] = useState<ICounters>({
    '0': 0,
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
  });

  const renderForms = () => {
    const extracurricularType = register(`ExtracurricularType.val`, {
      required: t('extracurricular.requiredTypeOfActivity').toString(),
    });
    const ExtracurricularDetail = register(`ExtracurricularDetail.val`, {
      required: t('extracurricular.requiredDescription').toString(),
    });

    return (
      <ExtracurricularForm
        t={t}
        index={0}
        extracurricularType={extracurricularType}
        ExtracurricularDetail={ExtracurricularDetail}
        counter={counter}
        setCounter={setCounter}
        control={control}
        remove={() => {}}
        errors={errors}
        formIndex={0}
        removeUnsaved={() => {}}
        deleteId={0}
        secondary={secondary}
        newExtra
      />
    );
  };
  return (
    <div style={{ marginTop: '-1rem' }}>
      <form
        data-testid="extracurriculars-form"
        onSubmit={handleSubmit(saveInfo)}
        className={classes.Container}
      >
        <div className={classes.CardContainer}>{renderForms()}</div>
        <div className={classes.ButtonContainer}>
          {isEdit ? (
            <FormFooter
              onClickCancel={onClickCancel}
              disabled={
                loadingExtracurriculars() === SaveState.PENDING || !isDirty
              }
              saveState={isDirty ? SaveState.IDLE : loadingExtracurriculars()}
              translate={t}
              submit
            />
          ) : (
            <SaveButton
              disabled={
                loadingExtracurriculars() === SaveState.PENDING || !isDirty
              }
              state={isDirty ? SaveState.IDLE : loadingExtracurriculars()}
              t={t}
              submit
            />
          )}
        </div>
      </form>
      {show && onClickCancelModal && (
        <CancelModal onClickCancelModal={onClickCancelModal} />
      )}
    </div>
  );
};

NewExtracurricular.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  secondary: false,
  newExtra: false,
};

export default NewExtracurricular;
