import React from 'react';
import classes from './SectionContainer.module.css';

interface Props {
  isActive: boolean;
  title: string;
  children: React.ReactNode | React.ReactNode[];
}

export const SectionContainer = ({ isActive, title, children }: Props) => {
  return (
    <section
      className={`${classes.container} ${isActive && classes.activeSection}`}
    >
      {title !== '' && (
        <header className={classes.containerHeader}>
          <p className={classes.containerTitle}>{title}</p>
        </header>
      )}
      {children}
    </section>
  );
};
