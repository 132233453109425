import { FC } from 'react';
import { TFunction } from 'react-i18next';

import { ReactComponent as OneIcon } from '../../icons/OneIcon.svg';
import { ReactComponent as TwoIcon } from '../../icons/TwoIcon.svg';
import { ReactComponent as ThreeIcon } from '../../icons/ThreeIcon.svg';
import { ReactComponent as FourIcon } from '../../icons/FourIcon.svg';
import classes from './HelpInfoTable.module.css';

type Spec = 'ram' | 'cpu' | 'storage' | 'space';

interface IProps {
  os?: string;
  spec: Spec;
  t: TFunction<'global', undefined>;
  secondary?: boolean;
}

interface Instruction {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  text: string;
}

interface Specs {
  [key: string]: Instruction[];
}

interface Render {
  [key: string]: Specs;
}
function isValidOs(arg: string): boolean {
  return ['Windows', 'Linux', 'Mac', 'Others'].some((os) => os === arg);
}

const HelpInfoTable: FC<IProps> = ({
  os = 'Windows',
  spec,
  t,
  secondary = false,
}) => {
  let validOs: string = os;
  if (!isValidOs(os)) {
    validOs = 'Others';
  }
  const render: Render = {
    Windows: {
      cpu: [
        { Icon: OneIcon, text: t('technical.windows1') },
        { Icon: TwoIcon, text: t('technical.windows2') },
        { Icon: ThreeIcon, text: t('technical.windows3') },
        { Icon: FourIcon, text: t('technical.windows4') },
      ],
      ram: [
        { Icon: OneIcon, text: t('technical.windows1') },
        { Icon: TwoIcon, text: t('technical.windows2') },
        { Icon: ThreeIcon, text: t('technical.windows3') },
        { Icon: FourIcon, text: t('technical.windows4') },
      ],
      storage: [
        { Icon: OneIcon, text: t('technical.windows1') },
        { Icon: TwoIcon, text: t('technical.windows2') },
        { Icon: ThreeIcon, text: t('technical.windows3') },
        { Icon: FourIcon, text: t('technical.windows4') },
      ],
      space: [
        { Icon: OneIcon, text: t('technical.windows1') },
        { Icon: TwoIcon, text: t('technical.windows2') },
        { Icon: ThreeIcon, text: t('technical.windows3') },
        { Icon: FourIcon, text: t('technical.windows4') },
      ],
    },
    Mac: {
      cpu: [
        { Icon: OneIcon, text: t('technical.mac1') },
        { Icon: TwoIcon, text: t('technical.mac2') },
      ],
      ram: [
        { Icon: OneIcon, text: t('technical.mac1') },
        { Icon: TwoIcon, text: t('technical.mac2') },
      ],
      storage: [
        { Icon: OneIcon, text: t('technical.mac1') },
        { Icon: TwoIcon, text: t('technical.mac2') },
      ],
      space: [
        { Icon: OneIcon, text: t('technical.mac1') },
        { Icon: TwoIcon, text: t('technical.mac2') },
      ],
    },
    Linux: {
      cpu: [
        { Icon: OneIcon, text: t('technical.linux1') },
        { Icon: TwoIcon, text: t('technical.linux2') },
        { Icon: ThreeIcon, text: t('technical.linux3') },
        { Icon: FourIcon, text: t('technical.linux4') },
      ],
      ram: [
        { Icon: OneIcon, text: t('technical.linux1') },
        { Icon: TwoIcon, text: t('technical.linux2') },
        { Icon: ThreeIcon, text: t('technical.linux3') },
        { Icon: FourIcon, text: t('technical.linux4') },
      ],
      storage: [
        { Icon: OneIcon, text: t('technical.linux1') },
        { Icon: TwoIcon, text: t('technical.linux2') },
        { Icon: ThreeIcon, text: t('technical.linux3') },
        { Icon: FourIcon, text: t('technical.linux4') },
      ],
      space: [
        { Icon: OneIcon, text: t('technical.linux1') },
        { Icon: TwoIcon, text: t('technical.linux2') },
        { Icon: ThreeIcon, text: t('technical.linux3') },
        { Icon: FourIcon, text: t('technical.linux4') },
      ],
    },
    Others: {
      cpu: [
        { Icon: OneIcon, text: t('technical.linux1') },
        { Icon: TwoIcon, text: t('technical.linux2') },
        { Icon: ThreeIcon, text: t('technical.linux3') },
        { Icon: FourIcon, text: t('technical.linux4') },
      ],
      ram: [
        { Icon: OneIcon, text: t('technical.linux1') },
        { Icon: TwoIcon, text: t('technical.linux2') },
        { Icon: ThreeIcon, text: t('technical.linux3') },
        { Icon: FourIcon, text: t('technical.linux4') },
      ],
      storage: [
        { Icon: OneIcon, text: t('technical.linux1') },
        { Icon: TwoIcon, text: t('technical.linux2') },
        { Icon: ThreeIcon, text: t('technical.linux3') },
        { Icon: FourIcon, text: t('technical.linux4') },
      ],
      space: [
        { Icon: OneIcon, text: t('technical.linux1') },
        { Icon: TwoIcon, text: t('technical.linux2') },
        { Icon: ThreeIcon, text: t('technical.linux3') },
        { Icon: FourIcon, text: t('technical.linux4') },
      ],
    },
  };
  const fill = secondary ? 'var(--brand-green-10)' : 'var(--brand-blue-100';
  return (
    <table className={classes.table}>
      <tbody>
        {os ? (
          render[validOs][spec].map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={`${idx}-${item.text}`}>
              <td aria-label="icon column" className={classes.tableCell}>
                <item.Icon fill={fill} />
              </td>
              <td className={classes.tableText}>{item.text}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td aria-label="oneicon column">
              <OneIcon fill={fill} />
            </td>
            <td>{t('technical.selectAnOs')}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

HelpInfoTable.defaultProps = {
  os: 'Windows',
  secondary: false,
};

export default HelpInfoTable;
