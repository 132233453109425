import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useGetAllVideoFeedbacksQuery,
  useSendVideoCommentMutation,
} from '$store/modules/video-feedback';
import { successHandler } from '$store/utils';
import { segmentTrack } from '$utils/handleSegment';

type CandidateVideoFeedbackType = {
  videoId: number;
  videoType: 'Video' | 'AdditionalVideos';
  candidateId: number;
};

const CandidateVideoFeedback = ({
  videoId,
  videoType,
  candidateId,
}: CandidateVideoFeedbackType) => {
  const [disableFeedback, setDisableFeedback] = useState(false);
  const [disableFeedbackButton, setDisableFeedbackButton] = useState(false);
  const { control, handleSubmit, setValue, register } = useForm<any>({
    defaultValues: { videoFeedback: '' },
  });
  const [t, i18n] = useTranslation('global');
  const { data, isLoading } = useGetAllVideoFeedbacksQuery({
    videoId,
    videoType,
    lang: i18n.language,
  });
  const [sendComment] = useSendVideoCommentMutation();
  const videoFeedbackInput = register('videoFeedback', {
    onChange: (ev) => {
      if (data && data.length > 0 && data[0].Comment === ev.target.value) {
        setDisableFeedbackButton(true);
      } else {
        setDisableFeedbackButton(false);
      }
    },
  });
  const save = async (saveData: any) => {
    try {
      setDisableFeedback(true);
      await sendComment({
        videoId,
        videoType,
        videoComment: saveData.videoFeedback,
        lang: i18n.language,
      }).then((resp) => {
        if ('data' in resp) {
          successHandler(t('VideoModal.FeedbackSuccess'), true, true);
          segmentTrack('Video Feedback', {
            feedback_id: resp.data.Id,
            entity_name: resp.data.EntityName,
            candidate_id: candidateId,
            video_feedback: saveData.videoFeedback,
            video_id: videoId,
          });
        }
      });
    } finally {
      setDisableFeedback(false);
      setDisableFeedbackButton(false);
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setValue('videoFeedback', data[0].Comment);
      setDisableFeedbackButton(true);
    }
  }, [data, setValue]);
  return (
    <form onSubmit={handleSubmit(save)} className="flex flex-col">
      <Controller
        name="videoFeedback"
        control={control}
        render={() => {
          return (
            <input
              className="bg-tags-white100 rounded-t-lg px-3 py-2 border-b-2 border-tags-black25 mb-4 disabled:opacity-50 outline-none"
              disabled={isLoading || disableFeedback}
              autoComplete="off"
              {...videoFeedbackInput}
            />
          );
        }}
      />
      <button
        className="self-end bg-brand-blue100 rounded text-white px-3 py-1.5 disabled:opacity-50"
        type="submit"
        disabled={isLoading || disableFeedback || disableFeedbackButton}
      >
        {t('VideoModal.SendReview')}
      </button>
    </form>
  );
};

export default CandidateVideoFeedback;
