import { FC, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import CustomSwitch from './components/CustomSwitch';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { initAuth } from './store/legacy/auth';
import { useAppDispatch } from './hooks';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const [, i18n] = useTranslation('global');

  // checking if user is logged in
  useEffect(() => {
    dispatch(initAuth(i18n.language));
  }, [dispatch, i18n.language]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message === 'ResizeObserver loop limit exceeded' ||
        e.message.includes('ResizeObserver')
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  const pathBase = window.location.pathname.split('/')[1];

  return (
    <>
      <Router
        basename={pathBase === 'en' || pathBase === 'es' ? pathBase : '/'}
      >
        <CustomSwitch />
      </Router>
      <ToastContainer
        rtl
        bodyClassName="toast-body"
        className="toast-container"
        closeButton={false}
      />
    </>
  );
};

export default App;
