import { FC } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './TechnicalInformation.module.css';
import { SaveState } from '../../types/ForrmType';
import SaveButton from '../Buttons/SaveButton';
import TechnicalInformationForm from './TechnicalInformationForm';
import FormFooter from '../FormFooter/FormFooter';
import CancelModal from '../CancelModal/CancelModal';
import {
  useSaveCandidateTechnicalInfoInternalMutation,
  useSaveCandidateTechnicalInfoMutation,
} from '../../store/modules/candidate-technical-info';
import { successHandler } from '../../store/utils';
import { useAppDispatch } from '../../hooks';
import { candidatesApi } from '../../store/modules/candidates';

interface TechnicalInformation {
  Id?: number;
  CPUModel: string;
  RAM: string;
  Storage: string;
  OperatingSystem: string;
  AvailableSpace: string;
}
interface TechnicalInfoProps {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean) => void;
  show?: boolean;
  secondary?: boolean;
  data?: {
    Id?: number | undefined;
    CPUModel: string;
    RAM: string;
    Storage: string;
    OperatingSystem: string;
    AvailableSpace: string;
  };
}

const TechnicalInformationFormContainer: FC<TechnicalInfoProps> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  secondary,
  data,
}) => {
  const [t, i18next] = useTranslation('global');
  const { candidateId } = useParams();
  const dispatch = useAppDispatch();

  const [saveTechnicalInfo, { isLoading, isSuccess }] =
    useSaveCandidateTechnicalInfoMutation();
  const [
    saveTechnicalInfoInternal,
    { isLoading: isLoadingInternal, isSuccess: isSuccesInternal },
  ] = useSaveCandidateTechnicalInfoInternalMutation();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: data,
  });

  const os = useWatch({ control, name: 'OperatingSystem' });

  const CPUModel = register('CPUModel', {
    required: 'CPU model is required',
  });
  const RAM = register('RAM', {
    required: 'RAM is required',
  });
  const Storage = register('Storage', {
    required: 'Storage is required',
  });
  const OperatingSystem = register('OperatingSystem');
  const AvailableSpace = register('AvailableSpace', {
    required: 'Available Space is required',
  });

  const saveInfo = async (technicalInformation: TechnicalInformation) => {
    try {
      if (Number(candidateId)) {
        await saveTechnicalInfoInternal({
          candidateId: Number(candidateId),
          data: technicalInformation,
          lang: i18next.language as 'en' | 'es',
        });
        dispatch(candidatesApi.util.invalidateTags(['Score']));
      } else {
        await saveTechnicalInfo({
          data: technicalInformation,
          lang: i18next.language as 'en' | 'es',
        });
        dispatch(candidatesApi.util.invalidateTags(['Score']));
      }
      if (setEdit) {
        setEdit(true);
      }
      successHandler(t('common.saved'), true);
      reset(
        {},
        {
          keepValues: true,
        }
      );
    } catch (error) {
      // error
    }
  };

  const buttonState = () => {
    if (isDirty) {
      return SaveState.IDLE;
    }
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }

    if (isSuccess || isSuccesInternal) {
      return SaveState.FULFILLED;
    }

    return SaveState.IDLE;
  };
  return (
    <div data-testid="container" className={classes.MainContainer}>
      <form
        data-testid="technical-form-container"
        onSubmit={handleSubmit(saveInfo)}
        className={classes.Container}
      >
        <TechnicalInformationForm
          CPUModel={CPUModel}
          RAM={RAM}
          Storage={Storage}
          OperatingSystem={OperatingSystem}
          AvailableSpace={AvailableSpace}
          t={t}
          errors={errors}
          os={os}
          secondary={secondary}
        />

        <div className={classes.ButtonContainer}>
          {isEdit ? (
            <FormFooter
              onClickCancel={onClickCancel}
              disabled={buttonState() === SaveState.PENDING || !isDirty}
              saveState={buttonState()}
              translate={t}
              submit
            />
          ) : (
            <SaveButton
              disabled={buttonState() === SaveState.PENDING || !isDirty}
              state={buttonState()}
              submit
              t={t}
            />
          )}
        </div>
      </form>
      {show && onClickCancelModal && (
        <CancelModal onClickCancelModal={onClickCancelModal} />
      )}
    </div>
  );
};
TechnicalInformationFormContainer.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  secondary: false,
  data: undefined,
};
export default TechnicalInformationFormContainer;
