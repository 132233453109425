import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ModalInTailwind from '$components/ModalInTailwind/ModalInTailwind';
import { ReactComponent as BehanceIcon } from '$icons/BehanceIcon.svg';
import { ReactComponent as LinkedinIcon } from '$icons/LinkedInSmall.svg';
import { ReactComponent as WebsiteIcon } from '$icons/WebsiteIcon.svg';
import { ReactComponent as GithubIcon } from '$icons/GithubIcon.svg';
import { ReactComponent as AI } from '$icons/ai.svg';
import BasicInput from '$components/Inputs/BaseInput';
import FormFooter from '$components/FormFooter/FormFooter';
import { SaveState } from '$types/ForrmType';
import {
  useGetCandidateInfoQuery,
  useUpdateCandidateProfileMutation,
  useUploadCandidateInfoMutation,
} from '$store/modules/candidates';
import CandidateProfileDto from '$types/serverTypes/candidateProfileDto';

const CandidateWebPortfoliosModal = ({
  isOpen,
  onClose,
  candidateId,
  userId,
  isInternal,
}: {
  isOpen: boolean;
  onClose: () => void;
  candidateId: number;
  userId: number;
  isInternal?: boolean;
}) => {
  const [t] = useTranslation('global');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm();

  const custom = register('CustomUrl', {
    pattern:
      /^(http(s)?:\/\/)?(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
  });
  const github = register('GithubUrl', {
    pattern: /^(http(s)?:\/\/)?((www\.)?)(github.com\/)/,
  });
  const linkedin = register('LinkedinUrl', {
    pattern: /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/in\/.+$/,
  });
  const behance = register('BehanceUrl', {
    pattern: /^(http(s)?:\/\/)?(www\.)?(behance\.net\/)/,
  });

  const ScorecardUrl = register('ScorecardUrl', {
    pattern:
      /^(http(s)?:\/\/)?(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
  });

  const { data: candidate, isSuccess } = useGetCandidateInfoQuery(
    candidateId ?? -1,
    {
      skip: !candidateId,
    }
  );

  // Updates as internal
  const [uploadCandidateInfo] = useUploadCandidateInfoMutation();

  // Updates as candidate
  const [updateCandidateProfile, { isError }] =
    useUpdateCandidateProfileMutation();

  const saveState = useMemo(() => {
    if (isDirty) return SaveState.IDLE;
    if (isSuccess) return SaveState.FULFILLED;
    if (isError) return SaveState.FAILED;

    return SaveState.IDLE;
  }, [isDirty, isError, isSuccess]);

  const submitChangesHandler = (formData: CandidateProfileDto) => {
    if (isInternal) {
      uploadCandidateInfo({ data: formData, candidateId });
    } else {
      updateCandidateProfile({
        data: formData,
        userId,
        candidateId,
      });
    }
  };

  useEffect(() => {
    if (isSuccess)
      reset({
        CustomUrl: candidate?.CustomUrl ?? undefined,
        LinkedinUrl: candidate?.LinkedinUrl ?? undefined,
        GithubUrl: candidate?.GithubUrl ?? undefined,
        BehanceUrl: candidate?.BehanceUrl ?? undefined,
        ScorecardUrl: candidate?.ScorecardUrl ?? undefined,
      });
  }, [candidate, reset, isSuccess]);

  return (
    <ModalInTailwind isOpen={isOpen} closeModal={onClose}>
      <form
        className="flex flex-col bg-white p-4 md:p-10"
        onSubmit={handleSubmit(submitChangesHandler)}
      >
        <b>{t('infoContainer.portfolio')}</b>

        <div className="flex flex-row justify-between gap-10">
          <div className="flex items-center mt-4 gap-2">
            <WebsiteIcon />
            <p>Website:</p>
          </div>
          <div className="w-80">
            <BasicInput
              type="link"
              placeholder={t('infoContainer.TypeLink')}
              onChange={custom.onChange}
              onBlur={custom.onBlur}
              refProp={custom.ref}
              name="CustomUrl"
              errorText={errors.CustomUrl && t('infoContainer.customUrlError')}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between gap-10">
          <div className="flex items-center mt-4 gap-2">
            <LinkedinIcon fill="#000000" />
            <p>LinkedIn:</p>
          </div>
          <div className="w-80">
            <BasicInput
              type="link"
              placeholder={t('infoContainer.TypeLink')}
              onChange={linkedin.onChange}
              onBlur={linkedin.onBlur}
              refProp={linkedin.ref}
              errorText={errors.LinkedinUrl && t('infoContainer.LinkedinError')}
              name="LinkedinUrl"
            />
          </div>
        </div>

        <div className="flex flex-row justify-between gap-10">
          <div className="flex items-center mt-4 gap-2">
            <BehanceIcon fill="000000" />
            <p>Behance:</p>
          </div>
          <div className="w-80">
            <BasicInput
              type="link"
              placeholder={t('infoContainer.TypeLink')}
              onChange={behance.onChange}
              onBlur={behance.onBlur}
              refProp={behance.ref}
              name={behance.name}
              errorText={errors.BehanceUrl && t('infoContainer.BehanceError')}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between gap-10">
          <div className="flex items-center mt-4 gap-2">
            <GithubIcon fill="000000" />
            <p>Github:</p>
          </div>
          <div className="w-80">
            <BasicInput
              type="link"
              placeholder={t('infoContainer.TypeLink')}
              onChange={github.onChange}
              onBlur={github.onBlur}
              refProp={github.ref}
              errorText={errors.GithubUrl && t('infoContainer.GithubUrlError')}
              name="GithubUrl"
            />
          </div>
        </div>
        <div className="flex flex-row justify-between gap-10">
          <div className="flex items-center mt-4 gap-2">
            <AI fill="000000" />
            <p>AI Scorecard:</p>
          </div>
          <div className="w-80">
            <BasicInput
              type="link"
              placeholder={t('infoContainer.ScorecardUrlLink')}
              onChange={ScorecardUrl.onChange}
              onBlur={ScorecardUrl.onBlur}
              refProp={ScorecardUrl.ref}
              errorText={
                errors.ScorecardUrl && t('infoContainer.ScorecardUrlError')
              }
              name="ScorecardUrl"
            />
          </div>
        </div>
        <div className="mt-4">
          <FormFooter
            disabled={!isDirty}
            saveState={saveState}
            translate={t}
            submit
            onClickCancel={() => {
              onClose();
            }}
            useMobile={false}
          />
        </div>
      </form>
    </ModalInTailwind>
  );
};

CandidateWebPortfoliosModal.defaultProps = {
  isInternal: false,
};

export default CandidateWebPortfoliosModal;
