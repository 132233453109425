import { FC } from 'react';

interface FooterArrowProps {
  direction?: string;
}

const FooterArrow: FC<FooterArrowProps> = ({ direction }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: direction === 'up' ? 'rotate(90deg)' : 'rotate(-90deg)',
      }}
    >
      <path
        d="M14.25 8.24957C14.6642 8.24957 15 8.58536 15 8.99957C15 9.41378 14.6642 9.74957 14.25 9.74957H6L9.5925 13.3421C9.88659 13.6362 9.88659 14.113 9.5925 14.4071C9.2984 14.7012 8.82159 14.7012 8.52749 14.4071L3.8271 9.70668C3.43658 9.31615 3.43658 8.68299 3.8271 8.29246L8.52749 3.59207C8.82159 3.29798 9.2984 3.29798 9.5925 3.59207C9.88659 3.88616 9.88659 4.36298 9.59249 4.65707L6 8.24957H14.25Z"
        fill="#9F9F9F"
      />
    </svg>
  );
};

FooterArrow.defaultProps = { direction: 'up' };

export default FooterArrow;
