import { FC, useEffect, useState } from 'react';
import { UseFormRegisterReturn, useWatch } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import FormsCard from '../FormsCard/FormsCard';
import BasicInput from '../Inputs/BaseInput';
import Radio from '../Radio/Radio';
import { ReactComponent as TrashIcon } from '../../icons/TrashIcon.svg';
import classes from './FormInputs.module.css';
import ConfirmDeletionModal from '../ConfirmDeletionModal/ConfirmDeletionModal';
import EducationDegreeTypes from '../../types/DegreeTypes';

interface FormInputsProps {
  formId: string;
  formIndex: number;
  degreeType: UseFormRegisterReturn;
  degreeName: UseFormRegisterReturn;
  schoolName: UseFormRegisterReturn;
  yearGraduated: UseFormRegisterReturn;
  setValue: any;
  errors: any;
  remove: (index: number, deleteId: number) => void;
  control: any;
  enrolled: UseFormRegisterReturn;
  t: TFunction<'global', undefined>;
  idx: number;
  deleteId: number;
  removeUnsaved: (index: number) => void;
  newEducation?: boolean;
  secondary?: boolean;
}

const EducationForm: FC<FormInputsProps> = ({
  formId,
  remove,
  control,
  formIndex,
  degreeType,
  degreeName,
  schoolName,
  yearGraduated,
  setValue,
  errors,
  enrolled,
  idx,
  t,
  deleteId,
  removeUnsaved,
  newEducation,
  secondary,
}) => {
  const degType = useWatch({ control, name: degreeType.name });

  const [Modal, setModal] = useState<boolean>(false);
  const checkedForm = useWatch({ control, name: enrolled.name });
  const [checked, setChecked] = useState<boolean>(checkedForm);

  useEffect(() => {
    if (checked) setValue(yearGraduated.name, '');
  }, [checked, setValue, yearGraduated.name]);

  const handleChecked = () => {
    setValue(enrolled.name, !checked);
    setChecked(!checked);
  };

  document.addEventListener('wheel', (e) => {
    if (document.activeElement !== null) {
      if (document.activeElement.getAttribute('type') === 'number') {
        (document.activeElement as HTMLElement).blur();
        e.preventDefault();
      }
    }
  });

  document.addEventListener('keydown', (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      if (document.activeElement !== null) {
        if (document.activeElement.getAttribute('type') === 'number') {
          (document.activeElement as HTMLElement).blur();
          e.preventDefault();
        }
      }
    }
  });

  const headerText: { [key: number]: string } = {
    0: t('education.first'),
    1: t('education.second'),
    2: t('education.third'),
    3: t('education.fourth'),
  };

  const labels = [
    {
      id: 1,
      text: t('education.vocational'),
      type: EducationDegreeTypes.VOCATIONAL,
    },
    { id: 2, text: t('education.hs'), type: EducationDegreeTypes.HIGH_SCHOOL },
    {
      id: 3,
      text: t('education.assocDeg'),
      type: EducationDegreeTypes.ASSOCIATE_DEGREE,
    },
    {
      id: 4,
      text: t('education.bd'),
      type: EducationDegreeTypes.BACHELORS_DEGREE,
    },
    {
      id: 5,
      text: t('education.md'),
      type: EducationDegreeTypes.MASTERS_DEGREE,
    },
    { id: 6, text: t('education.phd'), type: EducationDegreeTypes.PHD },
  ];

  const sliderClass = secondary ? classes.Slider_secondary : classes.Slider;

  return (
    <FormsCard key={idx}>
      {Modal && (
        <ConfirmDeletionModal
          SetModal={setModal}
          Delete={remove}
          Index={formIndex}
          DeleteId={deleteId}
          secondary={secondary}
        />
      )}
      <div className={classes.formHeader}>
        {!newEducation && (
          <div className={classes.formHeaderLabel}>
            <p className={classes.formTitle}>{`${headerText[idx]} ${t(
              'education.experience'
            )}`}</p>
          </div>
        )}
        <div className={classes.formHeaderIconContainer}>
          {idx !== 0 && deleteId !== undefined ? (
            <TrashIcon
              className={classes.formHeaderIcon}
              data-testid="delete-icon"
              onClick={() => {
                setModal(true);
              }}
            />
          ) : (
            idx !== 0 && (
              <TrashIcon
                data-testid="delete-icon"
                className={classes.formHeaderIcon}
                onClick={() => {
                  removeUnsaved(formIndex);
                }}
              />
            )
          )}
        </div>
      </div>
      <div>
        <p className={classes.labelText}>{t('education.degType')}</p>
        <div className={classes.grid_radio_buttons}>
          {labels.map((label) => (
            <Radio
              key={label.id}
              name={degreeType.name}
              onChange={degreeType.onChange}
              refProp={degreeType.ref}
              label={label.text}
              value={label.type}
              checked={degType === `${label.type}`}
              secondary={secondary}
            />
          ))}
          {errors?.educationalInfo?.[formIndex]?.DegreeType && (
            <div className={classes.errorRadio}>
              {errors?.educationalInfo?.[formIndex]?.DegreeType?.val?.message}
            </div>
          )}
          {errors?.DegreeType && (
            <div className={classes.errorRadio}>
              {errors?.DegreeType?.val?.message}
            </div>
          )}
        </div>
      </div>
      <BasicInput
        name={degreeName.name}
        onBlur={degreeName.onBlur}
        onChange={degreeName.onChange}
        refProp={degreeName.ref}
        type="text"
        label={t('education.degName')}
      />
      {errors?.educationalInfo?.[formIndex]?.DegreeName && (
        <div className={classes.errorRadio}>
          {errors?.educationalInfo?.[formIndex]?.DegreeName?.val?.message}
        </div>
      )}
      {errors?.DegreeName && (
        <div className={classes.errorRadio}>
          {errors?.DegreeName?.val?.message}
        </div>
      )}
      <div className={classes.extracurricular__school}>
        <div className={classes.extracurricular__school_name}>
          <BasicInput
            name={schoolName.name}
            onBlur={schoolName.onBlur}
            onChange={schoolName.onChange}
            refProp={schoolName.ref}
            placeholder={t('formNames.languagesPlaceHolder')}
            type="text"
            label={t('education.school')}
          />
        </div>
        <div className={classes.extracurricular__school_year}>
          <BasicInput
            name={yearGraduated.name}
            onBlur={yearGraduated.onBlur}
            onChange={yearGraduated.onChange}
            refProp={yearGraduated.ref}
            type="number"
            placeholder="AAAA"
            label={t('education.graduated')}
            disabled={checked}
            errorText={
              errors?.educationalInfo?.[formIndex]?.YearGraduated?.val
                .message || errors?.YearGraduated?.val.message
            }
          />
        </div>
      </div>
      <div className={classes.SwitchContainer}>
        <label
          aria-label="enrolled slider label"
          className={classes.Switch}
          htmlFor={`${formId}-switch`}
        >
          <input
            data-testid="currently-enrolled-slider"
            name={enrolled.name}
            id={`${formId}-switch`}
            type="checkbox"
            checked={checked}
            onChange={(e) => {
              handleChecked();
              enrolled.onChange(e);
            }}
          />
          <span
            data-testid="slider"
            className={`${sliderClass} ${classes.Round}`}
          />
        </label>
        <p className={classes.SwitchLabel}>{t('education.enrolled')}</p>
      </div>
    </FormsCard>
  );
};

EducationForm.defaultProps = {
  newEducation: false,
  secondary: false,
};

export default EducationForm;
