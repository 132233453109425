import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  UploadBuilder,
  UploadCVOutput,
  UploadProfilePictureInput,
  UploadProfilePictureOutput,
} from '../uploads.types';

export const uploadProfilePicture = (builder: UploadBuilder) =>
  builder.mutation<UploadProfilePictureOutput, UploadProfilePictureInput>({
    query: (args) => ({
      url: '/upload/profile-picture',
      method: 'POST',
      body: args.file,
    }),
    invalidatesTags: () => [{ type: 'Upload', id: 'List' }],
    transformResponse: (res: UploadCVOutput, meta, args) => {
      successHandler(
        (args.language ?? 'en') === 'en'
          ? SuccessMessageEn.UploadProfilePicture
          : SuccessMessageEs.UploadProfilePicture,
        true
      );
      return res;
    },
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[uploadCVAction]',
        args.language === 'es'
          ? ErrorMessageEs.UploadProfilePicture
          : ErrorMessageEn.UploadProfilePicture,
        err,
        true
      );
      return err.data;
    },
  });
