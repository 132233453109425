import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ShareReferenceLocationState } from '$types/LocationState';
import { duplicatePositionAfterLogin } from '$api/auth.service';
import { userLanguage } from '$translations/index';
import { warningHandler } from '$store/utils/warning-handler';
import { ErrorMessageEs, ErrorMessageEn } from '$constants/alerts';
import EmployerLayout from '../EmployerDashboard/EmployerLayout';
import PositionPage from '../Positions/PositionPage';

const PositionPageLayout = () => {
  const [isModalPositionOpen, setIsModalPositionOpen] = useState(false);
  const location = useLocation() as ShareReferenceLocationState;
  const toggleIsModalPositionOpen = useCallback(
    () => setIsModalPositionOpen((prev) => !prev),
    []
  );

  useEffect(() => {
    if (location?.state?.shareReferenceId) {
      duplicatePositionAfterLogin(location?.state?.shareReferenceId).catch(
        (response) => {
          const errorType =
            response?.response?.data?.statusCode === 400
              ? 'AlreadyDuplicatedPosition'
              : 'DuplicateGeneralErrorMessage';
          const errorMessage =
            userLanguage === 'es'
              ? ErrorMessageEs[errorType]
              : ErrorMessageEn[errorType];
          warningHandler(
            '[DuplicatePositionAfterLogin]',
            errorMessage,
            response,
            true
          );
        }
      );
    }

    return undefined;
  }, [location?.state?.shareReferenceId]);

  return (
    <EmployerLayout
      externalIsModalPositionOpen={isModalPositionOpen}
      isPositions
    >
      <PositionPage toggleIsModalPositionOpen={toggleIsModalPositionOpen} />
    </EmployerLayout>
  );
};

export default PositionPageLayout;
