const enum FilterTypeSearchBar {
  COMPANY = 'company',
  JOB_POSITION = 'job-position',
  JOB_ID = 'job-id',
}

export const parseFilterTypeSearchBar = (
  val: string | null,
  defaultValue = FilterTypeSearchBar.COMPANY
): FilterTypeSearchBar => {
  if (!val) {
    return defaultValue;
  }

  const isFilterTypeSearchBar = [
    FilterTypeSearchBar.COMPANY,
    FilterTypeSearchBar.JOB_POSITION,
    FilterTypeSearchBar.JOB_ID,
  ].includes(val as FilterTypeSearchBar);

  return isFilterTypeSearchBar ? (val as FilterTypeSearchBar) : defaultValue;
};

export default FilterTypeSearchBar;
