import { FC } from 'react';
import Modal from 'react-modal';
import classes from './HireConfirmModal.module.css';
import HireConfirmModalContent from './HireConfirmModalContent/HireConfirmModalContent';

interface Props {
  modalIsOpen: boolean;
  handleClose: (show: boolean) => void;
  candidatePositionStatusId: number;
  positionId: number;
  candidateArrayIndex: number;
  candidateId: number;
  setSwitchingId: (candidateId: number) => void;
}

const HireConfirmModal: FC<Props> = ({
  modalIsOpen,
  handleClose,
  candidatePositionStatusId,
  positionId,
  candidateArrayIndex,
  candidateId,
  setSwitchingId,
}) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => handleClose(false)}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      contentLabel="Hire candidate"
      ariaHideApp={false}
    >
      <HireConfirmModalContent
        handleClose={handleClose}
        candidatePositionStatusId={candidatePositionStatusId}
        positionId={positionId}
        candidateArrayIndex={candidateArrayIndex}
        candidateId={candidateId}
        setSwitchingId={setSwitchingId}
      />
    </Modal>
  );
};

export default HireConfirmModal;
