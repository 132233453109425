import type { FC } from 'react';
import classes from './FormsCard.module.css';
// import FormFooter from '../FormFooter/FormFooter';

const FormsCard: FC = ({ children }) => {
  return (
    <div data-testid="forms-card" className={classes.Card}>
      {children}
    </div>
  );
};

export default FormsCard;
