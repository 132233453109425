import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { WhatsappIcon } from 'react-share';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';
import Button from '../Buttons/Button';
import classes from './BasicInformationFormModal.module.css';
import { ReactComponent as LocationIcon } from '../../icons/Location.svg';
import { ReactComponent as CloseX } from '../../icons/CloseX.svg';
import { ReactComponent as TrashIcon } from '../../icons/TrashIcon.svg';
import { ReactComponent as Email } from '../../icons/Mail.svg';
import BasicInput from '../Inputs/BaseInput';
import AutocompleteRHF from '../Autocomplete/AutocompleteRHF';
import CandidateProfileDto from '../../types/serverTypes/candidateProfileDto';
import { SaveState } from '../../types/ForrmType';
import countries from '../../constants/countries';
import FormFooter from '../FormFooter/FormFooter';
import useWindowWidth from '../../hooks/useWindowWidth';
import CancelModal from '../CancelModal/CancelModal';
import { emailRegex, phoneRegex } from '../../constants/regex';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {
  useDeleteProfilePictureMutation,
  useGetProfilePictureQuery,
  useUploadProfilePictureMutation,
} from '../../store/modules/uploads';
import {
  useGetCandidateInfoQuery,
  useUpdateCandidateProfileMutation,
} from '../../store/modules/candidates';
import Spinner from '../Spinner/Spinner';
import { useAppSelector } from '../../hooks';

interface BasicInformationFormModalProps {
  setShowModal: (value: React.SetStateAction<boolean>) => void;
}

const BasicInformationFormModal: FC<BasicInformationFormModalProps> = ({
  setShowModal,
}) => {
  const { serverUser } = useAppSelector((state) => state.auth);
  const [showClickCancel, setClickCancelModal] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const modalRef = useRef<any>(null);
  const isMobile = useWindowWidth() < 768;
  const [t, i18next] = useTranslation('global');
  const { data: profilePicture, refetch: getProfilePictureRefetch } =
    useGetProfilePictureQuery({}, {});
  const [
    uploadProfilePicture,
    {
      isSuccess: uploadProfilePictureSuccess,
      isLoading: uploadProfilePictureLoading,
    },
  ] = useUploadProfilePictureMutation();
  const [updateCandidateProfile, { isLoading, isError }] =
    useUpdateCandidateProfileMutation();
  const [
    deleteProfilePicture,
    {
      isSuccess: deleteProfilePictureSuccess,
      isLoading: deleteProfilePictureLoading,
    },
  ] = useDeleteProfilePictureMutation();
  const { data: candidate, isSuccess } = useGetCandidateInfoQuery(
    serverUser?.Candidate?.Id ?? -1,
    {
      skip: !serverUser?.Candidate?.Id,
    }
  );

  const profilePictureSuccess = useMemo(
    () => uploadProfilePictureSuccess || deleteProfilePictureSuccess,
    [uploadProfilePictureSuccess, deleteProfilePictureSuccess]
  );
  const profilePictureLoading = useMemo(
    () => uploadProfilePictureLoading || deleteProfilePictureLoading,
    [uploadProfilePictureLoading, deleteProfilePictureLoading]
  );

  useEffect(() => {
    if (profilePictureSuccess && !profilePictureLoading) {
      getProfilePictureRefetch();
    }
  }, [getProfilePictureRefetch, profilePictureLoading, profilePictureSuccess]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm();

  useOnClickOutside(modalRef, () => setShowModal(false));

  useEffect(() => {
    if (isSuccess) {
      reset({
        FirstName: candidate?.User?.FirstName ?? undefined,
        LastName: candidate?.User?.LastName ?? undefined,
        ResidenceCountry: candidate?.ResidenceCountry ?? undefined,
        Phone: candidate?.User?.Phone ?? undefined,
        AddresLine1: candidate?.AddresLine1 ?? undefined,
        AddresLine2: candidate?.AddresLine2 ?? undefined,
        WhatsAppNumber: candidate?.WhatsAppNumber ?? undefined,
      });
    }
  }, [
    reset,
    isSuccess,
    candidate?.ResidenceCountry,
    candidate?.AddresLine1,
    candidate?.AddresLine2,
    candidate?.WhatsAppNumber,
    candidate?.User?.FirstName,
    candidate?.User?.LastName,
    candidate?.User?.Phone,
  ]);

  const firstName = register('FirstName', {
    required: true,
    setValueAs: (value) => value.trim().replace(/\s\s+/g, ' '),
  });

  const lastName = register('LastName', {
    required: true,
    setValueAs: (value) => value.trim().replace(/\s\s+/g, ' '),
  });

  const phone = register('Phone', {
    pattern: phoneRegex,
    setValueAs: (value: string | undefined) => {
      return value?.replace(/\s/g, '');
    },
  });

  const residence = register('ResidenceCountry');

  const address1 = register('AddresLine1');
  const address2 = register('AddresLine2');
  const email = register('Email', {
    maxLength: {
      value: 100,
      message: t('register.invalidEmailInput').toString(),
    },
    pattern: {
      value: emailRegex,
      message: t('register.invalidEmailInput'),
    },
  });
  const whatsapp = register('WhatsAppNumber', {
    pattern: phoneRegex,
    setValueAs: (value: string | undefined) => {
      return value?.replace(/\s/g, '');
    },
  });

  const handleCancel = () => {
    setClickCancelModal(true);
  };

  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setShowModal(false);
    }

    setClickCancelModal(false);
  };

  const saveInfo = (
    candidateProfileDto: CandidateProfileDto,
    userId: number,
    candidateId: number
  ) => {
    updateCandidateProfile({
      data: candidateProfileDto,
      userId,
      lang: i18next.language as 'en' | 'es',
      candidateId,
    });
  };

  const convertFileToFormData = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  };

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files![0];
    if (file) {
      setProfileImage(URL.createObjectURL(file));
      const formData = convertFileToFormData(file);
      uploadProfilePicture({ file: formData, language: i18next.language });
    }
  };

  const saveState = useMemo(() => {
    if (isDirty) {
      return SaveState.IDLE;
    }
    if (isLoading) {
      return SaveState.PENDING;
    }
    if (isSuccess) {
      return SaveState.FULFILLED;
    }
    if (isError) {
      return SaveState.FAILED;
    }
    return SaveState.IDLE;
  }, [isDirty, isError, isLoading, isSuccess]);

  return (
    <div className={classes.modal}>
      <div className={classes.modalContent} ref={modalRef}>
        <nav className={classes.modal__nav}>
          <h2 className={classes.modal__nav__title}>
            {t('infoContainer.basicInformation')}
          </h2>
          <Button
            icon={CloseX}
            iconStyles={{
              width: '14',
              height: '14',
              fill: '#232428',
            }}
            bgColor="transparent"
            showText={false}
            onClick={() => setShowModal(false)}
            customStyles={{
              width: '0.875rem',
              height: '0.875rem',
              flexGrow: '0',
              marginBottom: '0',
            }}
          />
        </nav>
        <main className={classes.modal__main}>
          <header className={classes.modal__header}>
            <div className={classes.ImageContainer}>
              <DefaultIcon2
                firstName={serverUser?.FirstName}
                lastName={serverUser?.LastName}
                picture={profileImage || profilePicture?.Url}
                addPadding
              />
            </div>
            <div className={classes.modal__header__profilePictureControls}>
              <label
                htmlFor="profilePicture"
                className={classes.profilePictureControlsLabel}
              >
                {t('profile.replacePhoto')}
              </label>
              <input
                data-testid="profile-picture-upload-input"
                name="profilePicture"
                id="profilePicture"
                type="file"
                accept="image/*"
                className={classes.profilePictureControlsInput}
                onChange={(e) => handlePhotoUpload(e)}
              />
              <Button
                text={t('profile.remove')}
                showText
                bgColor="transparent"
                icon={TrashIcon}
                customStyles={{
                  color: 'var(--black-50)',
                  borderRadius: '0.625rem',
                  padding: '0.5rem 1rem',
                  height: 'auto',
                  flexGrow: '0',
                  marginBottom: '0',
                  marginTop: `${isMobile ? 'unset' : '0.5rem'}`,
                }}
                onClick={() => {
                  setProfileImage('');
                  deleteProfilePicture({
                    lang: i18next.language as 'es' | 'en',
                  });
                }}
              />
            </div>
          </header>
          <section className={classes.modal__formContainer}>
            {!serverUser ? (
              <Spinner secondary />
            ) : (
              <form
                onSubmit={handleSubmit((data) =>
                  saveInfo(data, serverUser.Id, serverUser.Candidate!.Id)
                )}
                className={classes.modal__form}
              >
                <div className={classes.modal__form__fields}>
                  <BasicInput
                    label={t('profile.firstName')}
                    onChange={firstName.onChange}
                    type="text"
                    refProp={firstName.ref}
                    onBlur={firstName.onBlur}
                    name={firstName.name}
                    errorText={errors.FirstName && t('register.nameInputValid')}
                  />
                  <BasicInput
                    label={t('profile.lastName')}
                    type="text"
                    onChange={lastName.onChange}
                    refProp={lastName.ref}
                    onBlur={lastName.onBlur}
                    name={lastName.name}
                    errorText={
                      errors.LastName && t('register.lastNameInputValid')
                    }
                  />
                  <BasicInput
                    label={t('profile.phone')}
                    type="text"
                    onChange={phone.onChange}
                    refProp={phone.ref}
                    onBlur={phone.onBlur}
                    name={phone.name}
                    placeholder="+1 555 555 5555"
                    errorText={errors.Phone && t('register.phoneInputValid')}
                  />
                  <div className={classes.AutocompleteWrapper}>
                    <AutocompleteRHF
                      label={t('infoContainer.ResidenceCountry')}
                      name={residence.name}
                      onBlur={residence.onBlur}
                      onChange={residence.onChange}
                      refProp={residence.ref}
                      options={countries}
                      setValue={setValue}
                      placeholder={t(
                        'infoContainer.ResidenceCountryPlaceholder'
                      )}
                      icon={LocationIcon}
                      currSearchVal={
                        (serverUser &&
                          serverUser.Candidate?.ResidenceCountry) ??
                        ''
                      }
                    />
                  </div>
                  <BasicInput
                    label={t('profile.Address1')}
                    type="text"
                    onChange={address1.onChange}
                    refProp={address1.ref}
                    onBlur={address1.onBlur}
                    name={address1.name}
                  />
                  <BasicInput
                    label={t('profile.Address2')}
                    type="text"
                    onChange={address2.onChange}
                    refProp={address2.ref}
                    onBlur={address2.onBlur}
                    name={address2.name}
                  />
                  <div className={classes.link_item}>
                    <div className={classes.links_label}>
                      <Email fill="000000" />
                      <p>Email:</p>
                    </div>
                    <BasicInput
                      type="link"
                      placeholder={t('register.emailInput')}
                      onChange={email.onChange}
                      onBlur={email.onBlur}
                      refProp={email.ref}
                      errorText={
                        errors.email && t('register.invalidEmailInput')
                      }
                      name={email.name}
                    />
                  </div>
                  <div className={classes.link_item}>
                    <div className={classes.links_label}>
                      <WhatsappIcon
                        round
                        bgStyle={{
                          fill: '#000000',
                        }}
                        size={30}
                      />
                      <p>WhatsApp:</p>
                    </div>
                    <BasicInput
                      type="link"
                      placeholder="+1 555 555 5555"
                      onChange={whatsapp.onChange}
                      onBlur={whatsapp.onBlur}
                      refProp={whatsapp.ref}
                      errorText={
                        errors.WhatsAppNumber &&
                        t('infoContainer.WhatsAppNumberError')
                      }
                      name={whatsapp.name}
                    />
                  </div>
                </div>
                <FormFooter
                  disabled={isLoading || !isDirty}
                  saveState={saveState}
                  translate={t}
                  submit
                  onClickCancel={handleCancel}
                  useMobile={false}
                />
                {showClickCancel && handleCancelModal && (
                  <CancelModal onClickCancelModal={handleCancelModal} />
                )}
              </form>
            )}
          </section>
        </main>
      </div>
    </div>
  );
};

export default BasicInformationFormModal;
