import i18next from 'i18next';
import global_es from './es/global.json';
import global_en from './en/global.json';

const { type } = JSON.parse(localStorage.getItem('ajs_user_traits') || '{}');

const setLanguage = (): string => {
  if (type !== 'Candidate' && type !== undefined) return 'en';
  return navigator.language.split('-')[0] === 'es' ? 'es' : 'en';
};

i18next.init({
  interpolation: { escapeValue: false },
  fallbackLng: 'en',
  lng: setLanguage(),
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

export const i18nextInstance = i18next;

export enum ValidLanguages {
  es = 'es',
  en = 'en',
}

export const userLanguage = (i18next.language as ValidLanguages) ?? 'en';
