import { ErrorMessageEn } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  BackgroundTasksBuilder,
  PostTasksInput,
  PostTasksOutput,
} from '../background-tasks.types';

export const postTasks = (builder: BackgroundTasksBuilder) =>
  builder.mutation<PostTasksOutput, PostTasksInput>({
    query: (options) => {
      const formData = new FormData();
      options.files.forEach((file: File) => {
        formData.append('files', file);
      });

      return {
        url: '/background-tasks/user-migration',
        method: 'POST',
        body: formData,
      };
    },
    invalidatesTags: () => [{ type: 'Task', Id: 'List' }],
    transformErrorResponse: (err) => {
      errorHandler('[postTaskAction]', ErrorMessageEn.GetTasksFail, err, true);
      return err.data;
    },
  });
