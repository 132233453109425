import { FC } from 'react';
import { FormTypes } from '../../types/user_info';
import classes from './ExtracurricularEditableContainer.module.css';

interface ExtracurricularReadOnlyProps {
  extracurricular: FormTypes['extracurricular'];
  idx: number;
  labels: {
    id: number;
    label: string;
  }[];
  extracurricularsLength: number;
}

const ExtracurricularReadOnly: FC<ExtracurricularReadOnlyProps> = ({
  extracurricular,
  idx,
  labels,
  extracurricularsLength,
}) => {
  return (
    <section
      data-testid="extra-readonly"
      className={classes.extraEditContainer}
    >
      <p data-testid="extra-readonly-title" className={classes.extraTitle}>
        {extracurricular?.ExtracurricularType.val !== '' &&
          labels[Number(extracurricular?.ExtracurricularType.val)]?.label}
      </p>
      <p data-testid="extra-readonly-subtitle" className={classes.extraDetail}>
        {extracurricular?.ExtracurricularDetail.val}
      </p>
      {idx + 1 < extracurricularsLength && (
        <div className={classes.editContainerDivider} />
      )}
    </section>
  );
};

export default ExtracurricularReadOnly;
