import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '$hooks/use-app-selector.hook';
import { Role } from '$types/Roles.d';

interface DefaultIcon2Props {
  firstName: string | undefined;
  lastName: string | undefined;
  picture?: string | undefined;
  isEmployer?: boolean | undefined;
  isCandidate?: boolean | undefined;
  isInternal?: boolean | undefined;
  addPadding?: boolean | string | undefined;
  boldText?: boolean | undefined;
  fontSize?: string; // uses tailwind text sizes
  hideBorder?: boolean;
}

const DefaultIcon2 = ({
  firstName,
  lastName,
  picture,
  isEmployer,
  isCandidate,
  isInternal,
  addPadding,
  boldText,
  fontSize,
  hideBorder,
}: DefaultIcon2Props) => {
  const { serverUser } = useAppSelector((state) => state.auth);

  const getRole = useCallback(() => {
    // check first by attributes
    if (isInternal) return Role.INTERNAL;
    if (isEmployer) return Role.EMPLOYER;
    if (isCandidate) return Role.CANDIDATE;
    // check by app state
    if (serverUser?.Internal) return Role.INTERNAL;
    if (serverUser?.Employer) return Role.EMPLOYER;
    return Role.CANDIDATE;
  }, [isInternal, isEmployer, isCandidate, serverUser]);

  const [userFirstName, setUserFirstName] = useState(firstName);
  const [userLastName, setUserLastName] = useState(lastName);
  const [role, setRole] = useState(getRole());

  const nameInitials = useMemo(() => {
    // Get initials of each first name
    const firstNameInitials = userFirstName
      ?.split(' ')
      ?.map((singleWord) => singleWord[0]);

    // Get initials of each last name
    const lastNameInitials = userLastName
      ?.split(' ')
      ?.map((singleWord) => singleWord[0]);

    // Has a first name and last name initial
    if (firstNameInitials && lastNameInitials) {
      return `${firstNameInitials[0]}${lastNameInitials[0]}`;
    }

    // Has two or more first name
    if (firstNameInitials && firstNameInitials.length > 1) {
      return `${firstNameInitials[0]}${firstNameInitials[1]}`;
    }

    // Only has one first name
    if (userFirstName) {
      return `${userFirstName[0]}${userFirstName[1]}`;
    }

    // Doesnt has first name and has two or more last names
    if (lastNameInitials && lastNameInitials.length > 1) {
      return `${lastNameInitials[0]}${lastNameInitials[1]}`;
    }

    // Only has one last name
    if (userLastName) {
      return `${userLastName[0]}${userLastName[1]}`;
    }
    return 'N/A';
  }, [userFirstName, userLastName]);

  useEffect(() => {
    if (firstName !== undefined) setUserFirstName(firstName);
    if (lastName !== undefined) setUserLastName(lastName);
  }, [firstName, lastName]);

  useEffect(() => {
    setRole(getRole());
  }, [isEmployer, isCandidate, isInternal, getRole]);

  return (
    <div
      className={`${
        !hideBorder && 'border'
      } w-full aspect-square rounded-full items-center justify-center flex`}
    >
      {picture ? (
        <div
          className={`rounded-full overflow-hidden ${
            addPadding && (typeof addPadding === 'boolean' ? 'p-2' : addPadding)
          } flex flex-row justify-center max-h-full`}
        >
          <div className="rounded-full overflow-hidden items-center justify-center flex">
            <img src={picture} alt={`${userFirstName} ${userLastName}`} />
          </div>
        </div>
      ) : (
        <div
          className={`h-full w-full flex overflow-hidden rounded-full justify-center items-center text-white ${
            boldText && 'font-bold'
          } ${fontSize || 'text-md'} uppercase ${
            role === Role.INTERNAL && 'bg-internal-main'
          } ${role === Role.EMPLOYER && 'bg-employer-main'} ${
            role === Role.CANDIDATE && 'bg-candidate-main'
          }`}
        >
          {nameInitials.toUpperCase()}
        </div>
      )}
    </div>
  );
};

DefaultIcon2.defaultProps = {
  picture: undefined,
  isEmployer: false,
  isInternal: false,
  isCandidate: false,
  addPadding: false,
  boldText: false,
  fontSize: '',
  hideBorder: false,
};

export default DefaultIcon2;
