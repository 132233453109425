import React, { FC } from 'react';
import classes from './Home.module.css';
import SUPPORT_LINK from '../../constants/externalLinks';

interface DropDownMenuItemProps {
  onClick: () => void;
  icon?:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>> & {
        title?: string | undefined;
      };
  text: string;
  color?: string;
  support?: boolean;
}

const DropdownMenuItem: FC<DropDownMenuItemProps> = ({
  onClick,
  icon: Icon,
  text,
  color,
  support,
}) => {
  return (
    <div
      className={classes.dropdownMenuItem}
      role="button"
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
    >
      {!support ? (
        <>
          {Icon && <Icon fill={color} className="w-6 h-6" />}
          <p className={classes.dropdownMenuItemText} style={{ color }}>
            {text}
          </p>
        </>
      ) : (
        <>
          {Icon && <Icon fill={color} />}
          <a
            href={SUPPORT_LINK}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <p className={classes.dropdownMenuItemText} style={{ color }}>
              {text}
            </p>
          </a>
        </>
      )}
    </div>
  );
};

DropdownMenuItem.defaultProps = {
  color: '',
  icon: undefined,
  support: false,
};
export default DropdownMenuItem;
