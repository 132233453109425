import { useState, useMemo, useCallback, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PositionDetailsCard from '$components/Employers/Position/PositionDetailsCard/PositionDetailsCard';
import Spinner from '../../Spinner/Spinner';
import classes from './CandidatesPositionPage.module.css';
import ActiveValues from '../../../constants/ActiveValues';
import { ReactComponent as RefreshIcon } from '../../../icons/RefreshIcon.svg';
import {
  LoadOnePositionOutput,
  useLoadOnePositionQuery,
} from '../../../store/modules/companies';
import SearchBar from '../SearchBar/SearchBar';
import {
  candidatesApi,
  // useGetCandidatesByPositionIdQuery,
} from '../../../store/modules/candidates';
import { PositionSwitch } from '../../Employers/Position/PositionSwitch';
import CandidatesList from './CandidatesList';
import { CandidateData } from '../../../types/candidates';
import { useAppDispatch } from '../../../hooks';
import { filterNames } from '../../../constants/FiltersNames';
import SearchFilter from '../utils/SearchFilter';
import GetCandidateDataByMultipleFetch from '../utils/GetCandidateDataByMultipleFetch';

const CandidatesPositionPage = () => {
  const { positionId } = useParams();
  const [candidates, setCandidates] = useState<CandidateData[]>([]);
  const [filteredCandidates, setFilteredCandidates] = useState<CandidateData[]>(
    []
  );
  const [candidatesPositionCount, setCandidatesPositionCount] = useState({
    new: 0,
    shortlisted: 0,
    declined: 0,
    requestInterview: 0,
    hired: 0,
    offerSent: 0,
    all: 0,
  });
  const [searchParams] = useSearchParams();
  const [switchingCandidateId, setSwitchingCandidateId] = useState<
    number | null
  >(null);
  const { data: position } = useLoadOnePositionQuery({
    id: positionId,
  });

  const cps = (searchParams.get('cps') ?? ActiveValues.New) as ActiveValues;

  const filters = useMemo(
    () => ({
      cps,
      matchSkill: searchParams.get(filterNames.skillsMatchs) ?? 'ALL',
      matchAdditionalTags:
        searchParams.get(filterNames.additionalTagsMatch) ?? 'ALL',

      positionId: parseInt(positionId ?? '0', 10),
      id:
        parseInt(searchParams.get(filterNames.id) ?? '0', 10) === 0
          ? null
          : parseInt(searchParams.get(filterNames.id) ?? '0', 10),

      query: searchParams.get(filterNames.query) ?? '',
      name: searchParams.get('name') ?? null,
    }),
    [cps, positionId, searchParams]
  );

  // const { data, isLoading, isFetching, refetch } =
  //   useGetCandidatesByPositionIdQuery(
  //     {
  //       positionId: parseInt(positionId!, 10),
  //     },
  //     { refetchOnMountOrArgChange: true }
  //   );
  const { data, isLoading, refetch } =
    GetCandidateDataByMultipleFetch(position);

  const dispatch = useAppDispatch();

  const reloadCandidates = useCallback(() => {
    dispatch(candidatesApi.util.resetApiState());
    setCandidates([]);
    refetch();
  }, [dispatch, refetch]);

  useEffect(() => {
    if (data && !isLoading) {
      setCandidates((prev) => {
        // Added new candidates
        const newCandidates = data.filter(
          (item: any) => !prev.some((prevItem) => prevItem.Id === item.Id)
        );

        // Changed status
        const parsePrev = prev.filter((item) => {
          /** Fix when you request interview on request interview tab, avoids disappearing the card */
          if (item.Id === switchingCandidateId) {
            const hasChangedStatus = data.some(
              (singleData: LoadOnePositionOutput) => {
                return (
                  singleData.Id === switchingCandidateId &&
                  singleData?.CandidatesPositionStatus?.[0]?.Status !==
                    item?.CandidatesPositionStatus?.[0]?.Status
                );
              }
            );
            if (!hasChangedStatus) return true;
          }
          /** End fix */
          return item.Id !== switchingCandidateId;
        });

        return [...parsePrev, ...newCandidates];
      });
    }
  }, [data, isLoading, switchingCandidateId]);

  useEffect(() => {
    if (position && position.CandidatesPositionStatus) {
      const candidatesCount = position?.CandidatesPositionStatus?.reduce(
        (acc, curr) => {
          acc[curr.Status] += 1;

          if (curr.Status !== 'declined') {
            acc.all += 1;
          }

          return acc;
        },
        {
          new: 0,
          shortlisted: 0,
          declined: 0,
          requestInterview: 0,
          hired: 0,
          offerSent: 0,
          all: 0,
        }
      );
      setCandidatesPositionCount(candidatesCount);
    }
  }, [position?.CandidatesPositionStatus, position]);

  const applyFilters = useMemo(() => {
    return SearchFilter(filters, candidates);
  }, [filters, candidates]);

  useEffect(() => {
    setFilteredCandidates(applyFilters);
  }, [applyFilters]);

  return (
    <div className={classes.wrapper} data-testid="candidatePositionPage">
      <div className={classes.container}>
        <PositionDetailsCard
          position={position}
          candidatesCount={data?.data?.length ?? 0}
        />
        <div className={classes.filters}>
          <PositionSwitch
            value={(cps as ActiveValues) ?? ActiveValues.New}
            setSwitchingId={setSwitchingCandidateId}
            candidatesCount={candidatesPositionCount}
          />
          <div className={classes.containerSearch}>
            <div className={classes.search}>
              <SearchBar />
            </div>
            <div
              className={classes.refresh}
              aria-hidden="true"
              onClick={reloadCandidates}
            >
              <RefreshIcon />
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className={classes.spinner}>
            <Spinner height="100%" width="100%" />
          </div>
        ) : (
          <CandidatesList
            activeValue={cps}
            candidates={filteredCandidates}
            setSwitchingId={setSwitchingCandidateId}
            existingOffer={candidatesPositionCount.offerSent > 0}
            position={position}
          />
        )}
      </div>
    </div>
  );
};

export default CandidatesPositionPage;
