import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import classes from './CardSkeleton.module.css';

interface Props {
  hasProfilePicture?: boolean;
  rows?: number;
  padding?: number;
}
const CardSkeleton = ({ hasProfilePicture, rows = 4, padding = 2 }: Props) => (
  <SkeletonTheme baseColor="#F5F5F5" highlightColor="#ffffff">
    <section
      className={classes.skeletonCard}
      style={{ padding: `${padding}rem` }}
      data-testid="card-skeleton"
    >
      {hasProfilePicture && <Skeleton width={80} height={80} circle />}
      <br />
      <Skeleton height={24} />
      {Array.from({ length: rows - 1 }, (_, i) => (
        <Skeleton height={24} key={i} style={{ marginTop: 5 }} />
      ))}
    </section>
  </SkeletonTheme>
);

CardSkeleton.defaultProps = {
  hasProfilePicture: true,
  rows: 4,
  padding: 2,
};

export default CardSkeleton;
