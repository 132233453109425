import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ItemProgressBar.module.css';
import Check from '../../../icons/CheckIconEmployer.svg';
import ProgressState from '../../../types/ProgressState';

interface ItemProgressBarProps {
  title: string;
  icon: string;
  check: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProgressState: React.Dispatch<React.SetStateAction<ProgressState>>;
  handleSection?: React.Dispatch<React.SetStateAction<number>>;
  updateLinkClicked?: (status: boolean) => void;
  to?: string;
  section?: number;
  property?: string;
  copy?: string;
}

const ItemProgressBar: FC<ItemProgressBarProps> = ({
  title,
  icon,
  check,
  setIsOpen,
  setProgressState,
  handleSection,
  updateLinkClicked,
  to,
  section,
  property,
  copy,
}) => {
  const [t] = useTranslation('global');
  const handleClick = () => {
    setProgressState({
      handleSection,
      updateLinkClicked,
      to,
      section,
      title,
      property,
      copy,
    });
    setIsOpen(true);
  };
  return (
    <button
      type="button"
      className={
        title !== t('candidateProgressBar.completeAssessments')
          ? classes.Container
          : classes.ContainerDisabled
      }
      onClick={handleClick}
    >
      <img src={icon} alt={title} className={classes.Icon} />
      <p className={classes.Title}>{title}</p>
      {check ? (
        <div className={classes.Circle}>
          <img src={Check} alt="check" className={classes.Check} />
        </div>
      ) : (
        <div className={classes.CircleDisabled} />
      )}
    </button>
  );
};

ItemProgressBar.defaultProps = {
  section: 0,
  handleSection: undefined,
  to: undefined,
  updateLinkClicked: undefined,
  property: undefined,
  copy: undefined,
};

export default ItemProgressBar;
