import { ThunkDispatch } from 'redux-thunk';
import { UseFormReset } from 'react-hook-form';
import { Auth0UserProfile } from 'auth0-js';
import { AxiosError } from 'axios';
import i18next from 'i18next';
import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../constants/alerts';
import {
  Logout,
  setUser,
  createUser,
  emailLogin,
  emailSignUp,
  googleSignIn,
  linkedInSignIn,
  createEmployer,
  createInternal,
  getUserWithToken,
  confirmEmailByToken,
  getEmployerByUserId,
  getCandidateByUserId,
  submitPasswordChange,
  resendConfirmationEmail,
  checkIfUserExistsByEmail,
} from '../../../api/auth.service';
import {
  combineResponsesEmployer,
  combineResponsesInternal,
  combineResponsesCandidate,
} from '../../../utils/combineResponses';
import saveLocalStorage from '../../../utils/saveLocalStorage';
import ICombinedResponse from '../../../types/combinedResponse';
import CreateEmployerDto from '../../../types/serverTypes/createEmployerDto';
import {
  UserCandidate,
  UserDto,
  UserEmployer,
} from '../../../types/serverTypes/userDto';
import errorMessages from '../../../utils/errorMessages';
import { LoginFormTypes } from '../../../constants/FormTypes';
import ResetPasswordDto from '../../../types/serverTypes/resetPasswordDto';
import CandidateProfileDto from '../../../types/serverTypes/candidateProfileDto';
import { AuthAction, AuthActionTypes } from './auth.types';
import type { IRootState } from '../../index';
import { errorHandler, successHandler } from '../../utils';
import { IAuth0Response, Auth0ResultExt } from '../../../types/candidates';
import { segmentTrack } from '../../../utils/handleSegment';

export interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone?: string;
  residenceCountry?: string;
  platformReferral?: string;
  shareReferenceId?: string;
}

export const emailAuthAction = Object.assign(
  (user: ICreateUser, lang: string, siteVersion: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(emailAuthAction.start());
        const referralId = localStorage.getItem('referralId');
        const emailResponse = await emailSignUp(
          user.email,
          user.password,
          'candidate'
        );
        if (emailResponse) {
          await emailLogin(user.email, user.password).then(
            async ({ idToken }) => {
              emailResponse.idToken = idToken;
              const createUserResponse: UserCandidate = await createUser({
                FirstName: user.firstName,
                LastName: user.lastName,
                Email: user.email,
                Phone: user.phone,
                ResidenceCountry: user.residenceCountry,
                PlatformReferral: user.platformReferral,
                SignUpType: 0,
                ...(referralId && { referrerId: referralId }),
                Auth0Id: emailResponse.Id,
                Score: 0,
              });

              window.analytics.identify(createUserResponse.user.Id.toString(), {
                name: `${createUserResponse.user.FirstName} ${createUserResponse.user.LastName}`,
                email: createUserResponse.user.Email,
                type: 'Candidate',
                role: 'Candidate',
              });

              segmentTrack('User Registered', {
                account_type: 'Candidate',
                register_type: 'email_pass',
                site_version: localStorage.getItem('appVersion') ?? siteVersion,
              });

              const combinedResponses = combineResponsesCandidate(
                emailResponse,
                createUserResponse.user,
                createUserResponse.candidate
              );

              saveLocalStorage(combinedResponses);

              dispatch(emailAuthAction.success(combinedResponses, 0));
            }
          );
        }
      } catch (error) {
        dispatch(emailAuthAction.fail(error as Error));
        let message: string;
        if (
          (error as any)?.code === 'extensibility_error' // this is the auth0 error object
        ) {
          message = (error as any).original.response.body.friendly_message;
        } else {
          message =
            lang === 'es' ? ErrorMessageEs.AuthFail : ErrorMessageEn.AuthFail;
        }

        errorHandler('[EmployerEmailAuthAction]', message, error, true);
      }
    },
  {
    start: () => ({ type: AuthActionTypes.EMAIL_AUTH_START }),
    success: (
      combinedResponses: ICombinedResponse,
      score: number
    ): AuthAction => ({
      type: AuthActionTypes.EMAIL_AUTH_SUCCESS,
      combinedResponses,
      score,
    }),
    fail: (error: Error): AuthAction => ({
      type: AuthActionTypes.EMAIL_AUTH_FAIL,
      error,
    }),
  }
);

export const emailLoginAuthAction = Object.assign(
  (
      email: string,
      password: string,
      reset: UseFormReset<LoginFormTypes>,
      lang: string
    ) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(emailLoginAuthAction.start());
        const loginResponse: Auth0ResultExt = await emailLogin(email, password);
        let user: Auth0UserProfile | null = null;
        if (loginResponse) {
          user = await setUser(loginResponse.accessToken!);
          const getUserResponse: UserDto = await getUserWithToken(
            loginResponse.idToken ?? ''
          );

          if (!getUserResponse.Candidate) {
            throw new Error(ErrorMessageEn.WrongUserType);
          }

          const getCandidateResponse = await getCandidateByUserId(
            getUserResponse.Id
          );

          const combinedResponses = combineResponsesCandidate(
            loginResponse,
            getUserResponse,
            getCandidateResponse
          );

          window.analytics.identify(getUserResponse.Id.toString(), {
            name: `${getUserResponse.FirstName} ${getUserResponse.LastName}`,
            email: getUserResponse.Email,
            type: 'Candidate',
            role: 'Candidate',
          });

          const currScore = getCandidateResponse.Score;

          saveLocalStorage(combinedResponses);
          dispatch(
            emailLoginAuthAction.success(
              user,
              loginResponse,
              combinedResponses,
              currScore
            )
          );
          successHandler(
            lang === 'es'
              ? SuccessMessageEs.LoggedIn
              : SuccessMessageEn.LoggedIn,
            true
          );
        }
      } catch (error) {
        dispatch(emailAuthAction.fail(error as Error));
        reset();
        errorMessages('[EmailLoginAuthAction]', error as Error, lang);
      }
    },
  {
    start: () => ({ type: AuthActionTypes.EMAIL_LOGIN_START }),
    success: (
      auth0User: Auth0UserProfile | null,
      auth0Response: Auth0ResultExt,
      combinedResponses: ICombinedResponse,
      score: number
    ): AuthAction => ({
      type: AuthActionTypes.EMAIL_LOGIN_SUCCESS,
      auth0User,
      auth0Response,
      combinedResponses,
      score,
    }),
    fail: (error: Error): AuthAction => ({
      type: AuthActionTypes.EMAIL_LOGIN_FAIL,
      error,
    }),
  }
);

export const initAuth = Object.assign(
  (lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(initAuth.start());
        const authDataExists = !!localStorage.getItem('Auth0User');
        let isLoggedIn = false;
        let serverUser: UserDto | undefined;
        if (!authDataExists) throw new Error('No data in local storage');

        const localData = JSON.parse(localStorage.getItem('Auth0User')!);

        if (localData.idToken) {
          const { idToken } = localData;

          isLoggedIn = true;
          serverUser = await getUserWithToken(idToken);
        }
        const auth0Response: Auth0ResultExt | Auth0UserProfile = localData;

        if (!serverUser) throw new Error(ErrorMessageEn.UserNotFoundOnServer);
        let combinedResponses;
        let currScore = 0;
        if (serverUser.Candidate) {
          const serverCandidate = await getCandidateByUserId(serverUser.Id);
          combinedResponses = combineResponsesCandidate(
            auth0Response,
            serverUser,
            serverCandidate
          );
          currScore = serverCandidate.Score;
        } else if (serverUser.Employer) {
          const serverEmployer = await getEmployerByUserId(serverUser.Id);
          combinedResponses = combineResponsesEmployer(
            auth0Response,
            serverUser,
            serverEmployer
          );
        } else {
          combinedResponses = combineResponsesInternal(
            auth0Response,
            serverUser,
            serverUser.Internal!
          );
        }

        dispatch(
          initAuth.success(
            isLoggedIn,
            auth0Response,
            combinedResponses,
            currScore
          )
        );
      } catch (error: any) {
        dispatch(initAuth.fail(false));
        if (
          error.message !== 'No data in local storage' &&
          error.message !== 'No user found'
        ) {
          errorHandler(
            'initAuthReducer',
            lang === 'es' ? ErrorMessageEs.AuthFail : ErrorMessageEn.AuthFail,
            error,
            false
          );
        }
      }
    },
  {
    start: () => ({ type: AuthActionTypes.INIT_AUTH_START }),
    success: (
      isLoggedIn: boolean,
      auth0Response: any,
      combinedResponses: ICombinedResponse,
      score: number
    ): AuthAction => ({
      type: AuthActionTypes.INIT_AUTH_SUCCESS,
      isLoggedIn,
      auth0Response,
      combinedResponses,
      score,
    }),
    fail: (isLoggedIn: boolean): AuthAction => ({
      type: AuthActionTypes.INIT_AUTH_FAIL,
      isLoggedIn,
    }),
  }
);

export const googleAuthAction = Object.assign(
  (cv_id: number | undefined, lang: string, siteVersion: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(googleAuthAction.start());
        const referralId = localStorage.getItem('referralId');
        const googleResponse = await googleSignIn('candidate');
        const { givenName, familyName, email, emailVerified } =
          googleResponse.idTokenPayload;

        const userExists = await checkIfUserExistsByEmail(email);

        if (userExists) throw new Error(ErrorMessageEn.UserAlreadyRegistered);

        const createUserResponse: UserCandidate = await createUser({
          FirstName: givenName,
          LastName: familyName,
          Email: email,
          SignUpType: 1,
          ...(referralId && { referrerId: referralId }),
          Score: 0,
          curriculumId: cv_id,
          Auth0Id: googleResponse.idTokenPayload.sub,
          EmailVerified: emailVerified,
        });

        const combinedResponses = combineResponsesCandidate(
          googleResponse,
          createUserResponse.user,
          createUserResponse.candidate
        );

        window.analytics.identify(createUserResponse.user.Id.toString(), {
          name: `${createUserResponse.user.FirstName} ${createUserResponse.user.LastName}`,
          email: createUserResponse.user.Email,
          type: 'Candidate',
          role: 'Candidate',
        });

        segmentTrack('User Registered', {
          account_type: 'Candidate',
          register_type: 'google',
          site_version: localStorage.getItem('appVersion') ?? siteVersion,
        });

        const currScore = createUserResponse.candidate.Score;

        saveLocalStorage(combinedResponses);

        dispatch(googleAuthAction.success(combinedResponses, currScore));
        successHandler(
          lang === 'en' ? SuccessMessageEn.Welcome : SuccessMessageEs.Welcome,
          true
        );
      } catch (error) {
        dispatch(googleAuthAction.error(error as Error));
        errorMessages('[GoogleAuthAction]', error as Error, lang);
      }
    },
  {
    start: () => ({ type: AuthActionTypes.GOOGLE_AUTH_START }),
    success: (
      combinedResponses: ICombinedResponse,
      score: number
    ): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_SUCCESS,
      combinedResponses: {
        ...combinedResponses,
        serverEmployerResponse: null,
      },
      score,
    }),
    error: (error: Error): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_FAIL,
      error,
    }),
  }
);

export const googleAuthLoginAction = Object.assign(
  (lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(googleAuthLoginAction.start());
        const googleResponse: Auth0ResultExt = await googleSignIn('candidate');
        if (googleResponse.idToken) {
          localStorage.setItem('id_token', googleResponse.idToken!);
        }

        let getUserResponse: UserDto | undefined;
        try {
          getUserResponse = await getUserWithToken(
            googleResponse.idToken ?? ''
          );
        } catch (error) {
          throw new Error(ErrorMessageEn.UserNotFoundOnServer);
        }

        if (getUserResponse) {
          if (!getUserResponse.Candidate) {
            throw new Error(ErrorMessageEn.WrongUserType);
          }

          const getCandidateResponse = await getCandidateByUserId(
            getUserResponse.Id
          );

          const combinedResponses = combineResponsesCandidate(
            googleResponse,
            getUserResponse,
            getCandidateResponse
          );

          window.analytics.identify(getUserResponse.Id.toString(), {
            name: `${getUserResponse.FirstName} ${getUserResponse.LastName}`,
            email: getUserResponse.Email,
            type: 'Candidate',
            role: 'Candidate',
          });

          const currScore = getCandidateResponse.Score;

          saveLocalStorage(combinedResponses);
          dispatch(
            googleAuthLoginAction.success(
              googleResponse,
              combinedResponses,
              currScore
            )
          );
        }
        successHandler(
          lang === 'es' ? SuccessMessageEs.LoggedIn : SuccessMessageEn.LoggedIn,
          true
        );
      } catch (error: any) {
        dispatch(googleAuthLoginAction.fail(error as Error));
        errorMessages('[GoogleAuthAction]', error as Error, lang);
      }
    },
  {
    start: (): AuthAction => ({ type: AuthActionTypes.GOOGLE_AUTH_START }),
    success: (
      auth0Response: Auth0ResultExt,
      combinedResponses: ICombinedResponse,
      score: number
    ): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_SUCCESS,
      auth0Response,
      combinedResponses,
      score,
    }),
    fail: (error: Error) => ({
      type: AuthActionTypes.GOOGLE_AUTH_FAIL,
      error,
    }),
  }
);

export const linkedInAuthAction = Object.assign(
  (cv_id: number | undefined, lang: string, siteVersion: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(linkedInAuthAction.start());
        const referralId = localStorage.getItem('referralId');
        const linkedInResponse = await linkedInSignIn('candidate');
        const { givenName, familyName, email, emailVerified } =
          linkedInResponse.idTokenPayload;

        const userExists = await checkIfUserExistsByEmail(email);

        if (userExists) throw new Error(ErrorMessageEn.UserAlreadyRegistered);

        const createUserResponse: UserCandidate = await createUser({
          FirstName: givenName,
          LastName: familyName,
          Email: email,
          SignUpType: 2,
          ...(referralId && { referrerId: referralId }),
          Score: 0,
          curriculumId: cv_id,
          Auth0Id: linkedInResponse.idTokenPayload.sub,
          EmailVerified: emailVerified,
        });

        const combinedResponses = combineResponsesCandidate(
          linkedInResponse,
          createUserResponse.user,
          createUserResponse.candidate
        );

        window.analytics.identify(createUserResponse.user.Id.toString(), {
          name: `${createUserResponse.user.FirstName} ${createUserResponse.user.LastName}`,
          email: createUserResponse.user.Email,
          type: 'Candidate',
          role: 'Candidate',
        });

        segmentTrack('User Registered', {
          account_type: 'Candidate',
          register_type: 'linkedin',
          site_version: localStorage.getItem('appVersion') ?? siteVersion,
        });

        const currScore = createUserResponse.candidate.Score;

        saveLocalStorage(combinedResponses);

        dispatch(linkedInAuthAction.success(combinedResponses, currScore));
        successHandler(
          lang === 'en' ? SuccessMessageEn.Welcome : SuccessMessageEs.Welcome,
          true
        );
      } catch (error) {
        dispatch(linkedInAuthAction.fail(error as Error));
        errorMessages('[linkedInAuthAction]', error as Error, lang);
      }
    },
  {
    start: () => ({ type: AuthActionTypes.LINKEDIN_AUTH_START }),
    success: (combinedResponses: ICombinedResponse, score: number) => ({
      type: AuthActionTypes.LINKEDIN_AUTH_SUCCESS,
      combinedResponses,
      score,
    }),
    fail: (error: Error) => ({
      type: AuthActionTypes.LINKEDIN_AUTH_FAIL,
      error,
    }),
  }
);

export const linkedInAuthLoginAction = Object.assign(
  (lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(linkedInAuthLoginAction.start());
        const linkedInResponse: Auth0ResultExt = await linkedInSignIn(
          'candidate'
        );
        if (linkedInResponse.idToken)
          localStorage.setItem('id_token', linkedInResponse.idToken);

        let getUserResponse: UserDto | undefined;
        try {
          getUserResponse = await getUserWithToken(
            linkedInResponse.idToken ?? ''
          );
        } catch (error) {
          throw new Error(ErrorMessageEn.UserNotFoundOnServer);
        }

        if (getUserResponse) {
          if (!getUserResponse.Candidate) {
            throw new Error(ErrorMessageEn.WrongUserType);
          }

          const getCandidateResponse = await getCandidateByUserId(
            getUserResponse.Id
          );

          const combinedResponses = combineResponsesCandidate(
            linkedInResponse,
            getUserResponse,
            getCandidateResponse
          );

          window.analytics.identify(getUserResponse.Id.toString(), {
            name: `${getUserResponse.FirstName} ${getUserResponse.LastName}`,
            email: getUserResponse.Email,
            type: 'Candidate',
            role: 'Candidate',
          });

          const currScore = getCandidateResponse.Score;

          saveLocalStorage(combinedResponses);

          dispatch(
            linkedInAuthLoginAction.success(
              linkedInResponse,
              combinedResponses,
              currScore
            )
          );
        }
        successHandler(
          lang === 'es' ? SuccessMessageEs.LoggedIn : SuccessMessageEn.LoggedIn,
          true
        );
      } catch (error) {
        dispatch(linkedInAuthLoginAction.fail(error as Error));
        errorMessages('[LinkedInAuthAction]', error as Error, lang);
      }
    },
  {
    start: (): AuthAction => ({ type: AuthActionTypes.LINKEDIN_AUTH_START }),
    success: (
      auth0Response: Auth0ResultExt,
      combinedResponses: ICombinedResponse,
      score: number
    ): AuthAction => ({
      type: AuthActionTypes.LINKEDIN_AUTH_SUCCESS,
      auth0Response,
      combinedResponses,
      score,
    }),
    fail: (error: Error): AuthAction => ({
      type: AuthActionTypes.LINKEDIN_AUTH_FAIL,
      error,
    }),
  }
);

export const logoutAuthAction = Object.assign(
  (lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(logoutAuthAction.start());
        const ServerUserResponse = await Promise.resolve(
          JSON.parse(localStorage.getItem('ServerUserResponse') || '{}')
        );
        let userType = '';
        if (ServerUserResponse.Candidate) {
          userType = 'candidate';
        } else if (ServerUserResponse.Employer) {
          userType = 'employer';
        } else if (ServerUserResponse.Internal) {
          userType = 'internal';
        }

        await Logout(userType);
        localStorage.clear();
        sessionStorage.clear();
        dispatch(logoutAuthAction.success());
        successHandler('Logged out', false);
      } catch (error) {
        dispatch(logoutAuthAction.fail(error as Error));
        errorHandler(
          '[logoutAction]',
          lang === 'es' ? ErrorMessageEs.AuthFail : ErrorMessageEn.AuthFail,
          error,
          true
        );
      }
    },
  {
    start: () => ({ type: AuthActionTypes.LOGOUT_START }),
    success: () => ({ type: AuthActionTypes.LOGOUT_SUCCESS }),
    fail: (error: Error) => ({ type: AuthActionTypes.LOGOUT_FAIL, error }),
  }
);

export const sessionExpiredAction = Object.assign(
  (lang: string) =>
    async (
      dispatch: ThunkDispatch<IRootState, unknown, AuthAction>,
      getState: () => IRootState
    ) => {
      try {
        dispatch(sessionExpiredAction.start());
        localStorage.clear();
        sessionStorage.clear();
        sessionStorage.setItem('sessionExpired', 'true');
        const ServerUserResponse = await Promise.resolve(
          getState().auth.serverUser
        );
        let userType = '';
        if (ServerUserResponse?.Candidate) {
          userType = 'candidate';
        } else if (ServerUserResponse?.Employer) {
          userType = 'employer';
        } else if (ServerUserResponse?.Internal) {
          userType = 'internal';
        }
        await Logout(userType);
        dispatch(sessionExpiredAction.success());
      } catch (error) {
        dispatch(sessionExpiredAction.fail(error as Error));
      } finally {
        errorHandler(
          '[sessionExpiredAction]',
          lang === 'es'
            ? ErrorMessageEs.SessionExpired
            : ErrorMessageEn.SessionExpired,
          new Error('Session expired'),
          true
        );
      }
    },
  {
    start: () => ({ type: AuthActionTypes.SESSION_EXPIRED_IN_BACKEND }),
    success: () => ({ type: AuthActionTypes.SESSION_EXPIRED_IN_BACKEND }),
    fail: (error: Error) => ({
      type: AuthActionTypes.SESSION_EXPIRED_IN_BACKEND,
      error,
    }),
  }
);

export const submitPasswordChangeAction = Object.assign(
  (resetPasswordDto: ResetPasswordDto) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      const { lang } = resetPasswordDto;
      try {
        dispatch(submitPasswordChangeAction.start());
        await submitPasswordChange(resetPasswordDto);
        dispatch(submitPasswordChangeAction.success());
      } catch (error) {
        dispatch(submitPasswordChangeAction.fail(error as Error));
        const err = error as AxiosError;
        switch (err?.response?.status) {
          case 404:
            errorHandler(
              '[submitPasswordChangeAction]',
              lang === 'es'
                ? ErrorMessageEs.InvalidToken
                : ErrorMessageEn.InvalidToken,
              error,
              true
            );
            break;
          case 400:
            errorHandler(
              '[submitPasswordChangeAction]',
              lang === 'es'
                ? ErrorMessageEs.SamePassword
                : ErrorMessageEn.SamePassword,
              error,
              true
            );
            break;
          default:
            break;
        }
      }
    },
  {
    start: () => ({ type: AuthActionTypes.RESET_PASSWORD_AUTH_START }),
    success: (): AuthAction => ({
      type: AuthActionTypes.RESET_PASSWORD_AUTH_SUCCESS,
    }),
    fail: (error: Error) => ({
      type: AuthActionTypes.RESET_PASSWORD_AUTH_FAIL,
      error,
    }),
  }
);

export const employerEmailAuthAction = Object.assign(
  (user: ICreateUser, employer: CreateEmployerDto, lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(employerEmailAuthAction.start());
        const emailResponse: IAuth0Response = await emailSignUp(
          user.email,
          user.password,
          'employer'
        );

        if (emailResponse) {
          const createUserResponse: UserEmployer = await createEmployer({
            FirstName: user.firstName,
            LastName: user.lastName,
            Email: user.email,
            SignUpType: 0,
            Auth0Id: emailResponse.Id,
            ShareReferenceId: user.shareReferenceId,
            ...employer,
          });

          window.analytics.identify(createUserResponse.employer.Id.toString(), {
            company_name: createUserResponse?.employer?.Company?.Name,
            name: `${createUserResponse?.user?.FirstName} ${createUserResponse?.user?.LastName}`,
            email: createUserResponse?.user?.Email,
            type: 'Employer',
            role: 'Employer',
          });

          i18next.changeLanguage('en');

          segmentTrack('User Registered', {
            account_type: 'Employer',
            register_type: 'email_pass',
          });

          dispatch(
            employerEmailAuthAction.success(createUserResponse.user.Email)
          );
        }
      } catch (error) {
        dispatch(employerEmailAuthAction.fail(error as Error));
        let message: string;
        if (
          (error as any).code &&
          (error as any).code === 'extensibility_error' // this is the auth0 error object
        ) {
          message = (error as any).original.response.body.friendly_message;
        } else {
          message =
            lang === 'es' ? ErrorMessageEs.AuthFail : ErrorMessageEn.AuthFail;
        }
        errorHandler('[EmployerEmailAuthAction]', message, error, true);
      }
    },
  {
    start: () => ({ type: AuthActionTypes.EMPLOYER_EMAIL_AUTH_START }),
    success: (email: string): AuthAction => ({
      type: AuthActionTypes.EMPLOYER_EMAIL_AUTH_SUCCESS,
      employerEmail: email,
    }),
    fail: (error: Error): AuthAction => ({
      type: AuthActionTypes.EMPLOYER_EMAIL_AUTH_FAIL,
      error,
    }),
  }
);

export const employerGoogleAuthAction = Object.assign(
  (employer: CreateEmployerDto, lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(employerGoogleAuthAction.start());
        const googleResponse = await googleSignIn('employer');
        const { givenName, familyName, email, emailVerified, sub } =
          googleResponse.idTokenPayload;

        const userExists = await checkIfUserExistsByEmail(email);

        if (userExists) throw new Error(ErrorMessageEn.UserAlreadyRegistered);
        let companyName = email.split('@');
        companyName = companyName[companyName.length - 1];

        const createUserResponse: UserEmployer = await createEmployer({
          FirstName: givenName,
          LastName: familyName,
          Email: email,
          SignUpType: 1,
          ...employer,
          CompanyName: companyName,
          EmailVerified: emailVerified,
          Auth0Id: sub,
          ShareReferenceId: employer?.ShareReferenceId,
        });

        const combinedResponses = combineResponsesEmployer(
          googleResponse,
          createUserResponse.user,
          createUserResponse.employer
        );

        window.analytics.identify(createUserResponse.employer.Id.toString(), {
          CompanyName: createUserResponse?.employer?.Company?.Name,
          company_name: createUserResponse?.employer?.Company?.Name,
          name: `${createUserResponse?.user?.FirstName} ${createUserResponse?.user?.LastName}`,
          email: createUserResponse?.user?.Email,
          type: 'Employer',
          role: 'Employer',
        });

        i18next.changeLanguage('en');

        segmentTrack('User Registered', {
          account_type: 'Employer',
          register_type: 'google',
        });

        saveLocalStorage(combinedResponses);

        dispatch(employerGoogleAuthAction.success(combinedResponses));
        successHandler(
          lang === 'en' ? SuccessMessageEn.Welcome : SuccessMessageEs.Welcome,
          true
        );
      } catch (error) {
        dispatch(employerGoogleAuthAction.error(error as Error));
        if (
          (error as any).code &&
          (error as any).code === 'access_denied' // this is the auth0 error object
        ) {
          const message = (error as any).description;
          errorHandler('[EmployerGoogleAuthAction]', message, error, true);
        } else {
          errorMessages('[EmployerGoogleAuthAction]', error as Error, lang);
        }
      }
    },
  {
    start: () => ({ type: AuthActionTypes.GOOGLE_AUTH_START }),
    success: (combinedResponses: ICombinedResponse): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_SUCCESS,
      combinedResponses,
    }),
    error: (error: Error): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_FAIL,
      error,
    }),
  }
);

export const employerLinkedInAuthAction = Object.assign(
  (employer: CreateEmployerDto, lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(employerLinkedInAuthAction.start());
        const linkedInResponse = await linkedInSignIn('employer');
        const { givenName, familyName, email, emailVerified, sub } =
          linkedInResponse.idTokenPayload;

        const userExists = await checkIfUserExistsByEmail(email);

        if (userExists) throw new Error(ErrorMessageEn.UserAlreadyRegistered);
        let companyName = email.split('@');
        companyName = companyName[companyName.length - 1];

        const createUserResponse: UserEmployer = await createEmployer({
          FirstName: givenName,
          LastName: familyName,
          Email: email,
          SignUpType: 2,
          ...employer,
          CompanyName: companyName,
          EmailVerified: emailVerified,
          Auth0Id: sub,
          ShareReferenceId: employer?.ShareReferenceId,
        });

        const combinedResponses = combineResponsesEmployer(
          linkedInResponse,
          createUserResponse.user,
          createUserResponse.employer
        );

        window.analytics.identify(createUserResponse.employer.Id.toString(), {
          CompanyName: createUserResponse?.employer?.Company?.Name,
          company_name: createUserResponse?.employer?.Company?.Name,
          name: `${createUserResponse?.user?.FirstName} ${createUserResponse?.user?.LastName}`,
          email: createUserResponse?.user?.Email,
          type: 'Employer',
          role: 'Employer',
        });

        i18next.changeLanguage('en');

        segmentTrack('User Registered', {
          account_type: 'Employer',
          register_type: 'linkedin',
        });

        saveLocalStorage(combinedResponses);

        dispatch(employerLinkedInAuthAction.success(combinedResponses));
        successHandler(
          lang === 'en' ? SuccessMessageEn.Welcome : SuccessMessageEs.Welcome,
          true
        );
      } catch (error) {
        dispatch(employerLinkedInAuthAction.fail(error as Error));
        if (
          (error as any).code &&
          (error as any).code === 'access_denied' // this is the auth0 error object
        ) {
          const message = (error as any).description;
          errorHandler('[EmployerLinkedInAuthAction]', message, error, true);
        } else {
          errorMessages('[EmployerLinkedInAuthAction]', error as Error, lang);
        }
      }
    },
  {
    start: () => ({ type: AuthActionTypes.LINKEDIN_AUTH_START }),
    success: (combinedResponses: ICombinedResponse): AuthAction => ({
      type: AuthActionTypes.LINKEDIN_AUTH_SUCCESS,
      combinedResponses,
    }),
    fail: (error: Error) => ({
      type: AuthActionTypes.LINKEDIN_AUTH_FAIL,
      error,
    }),
  }
);

export const employerEmailLoginAuthAction = Object.assign(
  (email: string, password: string, lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(employerEmailLoginAuthAction.start());

        const loginResponse: Auth0ResultExt = await emailLogin(email, password);
        let user: Auth0UserProfile | null = null;
        if (loginResponse) {
          user = await setUser(loginResponse.accessToken!);
        }

        const getUserResponse: UserDto = await getUserWithToken(
          loginResponse.idToken ?? ''
        );

        if (!getUserResponse.Employer) {
          throw new Error(ErrorMessageEn.WrongUserType);
        }

        const getEmployerResponse = await getEmployerByUserId(
          getUserResponse.Id
        );

        const combinedResponses = combineResponsesEmployer(
          loginResponse,
          getUserResponse,
          getEmployerResponse
        );
        window.analytics.identify(
          combinedResponses.serverUserResponse.Id.toString(),
          {
            name: `${combinedResponses?.serverUserResponse?.FirstName} ${combinedResponses?.serverUserResponse?.LastName}`,
            company_name:
              combinedResponses?.serverUserResponse?.Employer?.Company?.Name,
            CompanyName:
              combinedResponses?.serverUserResponse?.Employer?.Company?.Name,
            email: combinedResponses?.serverUserResponse?.Email,
            type: 'Employer',
            role: 'Employer',
          }
        );

        i18next.changeLanguage('en');

        saveLocalStorage(combinedResponses);

        dispatch(
          employerEmailLoginAuthAction.success(
            user,
            loginResponse,
            combinedResponses
          )
        );

        successHandler(
          lang === 'es' ? SuccessMessageEs.LoggedIn : SuccessMessageEn.LoggedIn,
          true
        );
      } catch (error) {
        dispatch(employerEmailLoginAuthAction.fail(error as Error));

        if (
          (error as any).code &&
          (error as any).code === 'access_denied' // this is the auth0 error object
        ) {
          const message = (error as any).description;
          errorHandler('[EmployerEmailLoginAuthAction]', message, error, true);
        } else {
          errorMessages('[EmployerEmailLoginAuthAction]', error as Error, lang);
        }
      }
    },
  {
    start: () => ({ type: AuthActionTypes.EMAIL_LOGIN_START }),
    success: (
      auth0User: Auth0UserProfile | null,
      auth0Response: Auth0ResultExt,
      combinedResponses: ICombinedResponse
    ): AuthAction => ({
      type: AuthActionTypes.EMAIL_LOGIN_SUCCESS,
      auth0User,
      auth0Response,
      combinedResponses,
    }),
    fail: (error: Error): AuthAction => ({
      type: AuthActionTypes.EMAIL_LOGIN_FAIL,
      error,
    }),
  }
);

export const employerGoogleLoginAuthAction = Object.assign(
  (lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(employerGoogleLoginAuthAction.start());
        const googleResponse: Auth0ResultExt = await googleSignIn('employer');
        if (googleResponse.idToken)
          localStorage.setItem('id_token', googleResponse.idToken!);

        let getUserResponse: UserDto | undefined;
        try {
          getUserResponse = await getUserWithToken(
            googleResponse.idToken ?? ''
          );
        } catch (error) {
          throw new Error(ErrorMessageEn.UserNotFoundOnServer);
        }

        if (getUserResponse) {
          if (!getUserResponse.Employer) {
            throw new Error(ErrorMessageEn.WrongUserType);
          }

          const getEmployerResponse = await getEmployerByUserId(
            getUserResponse.Id
          );

          const combinedResponses = combineResponsesEmployer(
            googleResponse,
            getUserResponse,
            getEmployerResponse
          );

          window.analytics.identify(
            combinedResponses.serverUserResponse.Id.toString(),
            {
              name: `${combinedResponses?.serverUserResponse?.FirstName} ${combinedResponses?.serverUserResponse?.LastName}`,
              company_name:
                combinedResponses?.serverUserResponse?.Employer?.Company?.Name,
              CompanyName:
                combinedResponses?.serverUserResponse?.Employer?.Company?.Name,
              email: combinedResponses?.serverUserResponse?.Email,
              type: 'Employer',
              role: 'Employer',
            }
          );

          i18next.changeLanguage('en');

          saveLocalStorage(combinedResponses);
          dispatch(
            employerGoogleLoginAuthAction.success(
              googleResponse,
              combinedResponses
            )
          );
        }
        successHandler(
          lang === 'es' ? SuccessMessageEs.LoggedIn : SuccessMessageEn.LoggedIn,
          true
        );
      } catch (error) {
        dispatch(employerGoogleLoginAuthAction.fail(error as Error));
        errorMessages('[EmployerGoogleAuthAction]', error as Error, lang);
      }
    },
  {
    start: (): AuthAction => ({ type: AuthActionTypes.GOOGLE_AUTH_START }),
    success: (
      auth0Response: Auth0ResultExt,
      combinedResponses: ICombinedResponse
    ): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_SUCCESS,
      auth0Response,
      combinedResponses,
    }),
    fail: (error: Error) => ({
      type: AuthActionTypes.GOOGLE_AUTH_FAIL,
      error,
    }),
  }
);

export const employerLinkedInLoginAuthAction = Object.assign(
  (lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(employerLinkedInLoginAuthAction.start());
        const linkedInResponse: Auth0ResultExt = await linkedInSignIn(
          'employer'
        );
        if (linkedInResponse.idToken)
          localStorage.setItem('id_token', linkedInResponse.idToken);

        let getUserResponse: UserDto | undefined;
        try {
          getUserResponse = await getUserWithToken(
            linkedInResponse.idToken ?? ''
          );
        } catch (error) {
          throw new Error(ErrorMessageEn.UserNotFoundOnServer);
        }

        if (getUserResponse) {
          if (!getUserResponse.Employer) {
            throw new Error(ErrorMessageEn.WrongUserType);
          }

          const getEmployerResponse = await getEmployerByUserId(
            getUserResponse.Id
          );

          const combinedResponses = combineResponsesEmployer(
            linkedInResponse,
            getUserResponse,
            getEmployerResponse
          );

          window.analytics.identify(
            combinedResponses.serverUserResponse.Id.toString(),
            {
              name: `${combinedResponses?.serverUserResponse?.FirstName} ${combinedResponses?.serverUserResponse?.LastName}`,
              CompanyName:
                combinedResponses?.serverUserResponse?.Employer?.Company?.Name,
              company_name:
                combinedResponses?.serverUserResponse?.Employer?.Company?.Name,
              email: combinedResponses?.serverUserResponse?.Email,
              type: 'Employer',
              role: 'Employer',
            }
          );

          i18next.changeLanguage('en');

          saveLocalStorage(combinedResponses);

          dispatch(
            employerLinkedInLoginAuthAction.success(
              linkedInResponse,
              combinedResponses
            )
          );
        }
        successHandler(
          lang === 'es' ? SuccessMessageEs.LoggedIn : SuccessMessageEn.LoggedIn,
          true
        );
      } catch (error) {
        dispatch(employerLinkedInLoginAuthAction.fail(error as Error));
        errorMessages('[EmployerLinkedInAuthAction]', error as Error, lang);
      }
    },
  {
    start: (): AuthAction => ({ type: AuthActionTypes.LINKEDIN_AUTH_START }),
    success: (
      auth0Response: Auth0ResultExt,
      combinedResponses: ICombinedResponse
    ): AuthAction => ({
      type: AuthActionTypes.LINKEDIN_AUTH_SUCCESS,
      auth0Response,
      combinedResponses,
    }),
    fail: (error: Error): AuthAction => ({
      type: AuthActionTypes.LINKEDIN_AUTH_FAIL,
      error,
    }),
  }
);

export const InternalEmailAuthAction = Object.assign(
  (user: ICreateUser, lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(InternalEmailAuthAction.start());
        const emailResponse: IAuth0Response = await emailSignUp(
          user.email,
          user.password,
          'internal'
        );
        if (emailResponse) {
          const createInternalResponse = await createInternal({
            FirstName: user.firstName,
            LastName: user.lastName,
            Email: user.email,
            SignUpType: 0,
            Auth0Id: emailResponse.Id,
          });

          window.analytics.identify(createInternalResponse.Id.toString(), {
            email: createInternalResponse.User.Email,
            name: createInternalResponse.User.FirstName,
            type: 'Internal',
            role: 'Internal',
          });

          i18next.changeLanguage('en');

          segmentTrack('User Registered', {
            account_type: 'Internal',
            register_type: 'email_pass',
          });

          dispatch(
            InternalEmailAuthAction.success(createInternalResponse.User.Email)
          );
        }
      } catch (error) {
        dispatch(InternalEmailAuthAction.fail(error as Error));
        let message: string;
        if (
          (error as any).code &&
          (error as any).code === 'extensibility_error' // this is the auth0 error object
        ) {
          message = (error as any).original.response.body.friendly_message;
        } else {
          message =
            lang === 'es' ? ErrorMessageEs.AuthFail : ErrorMessageEn.AuthFail;
        }
        errorHandler('[InternalEmailAuthAction]', message, error, true);
      }
    },
  {
    start: () => ({ type: AuthActionTypes.INTERNAL_EMAIL_AUTH_START }),
    success: (email: string): AuthAction => ({
      type: AuthActionTypes.INTERNAL_EMAIL_AUTH_SUCCESS,
      internalEmail: email,
    }),
    fail: (error: Error): AuthAction => ({
      type: AuthActionTypes.INTERNAL_EMAIL_AUTH_FAIL,
      error,
    }),
  }
);

export const internalEmailLoginAuthAction = Object.assign(
  (email: string, password: string, lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(internalEmailLoginAuthAction.start());

        const loginResponse: Auth0ResultExt = await emailLogin(email, password);
        let user: Auth0UserProfile | null = null;
        if (loginResponse) {
          user = await setUser(loginResponse.accessToken!);
        }

        const getUserResponse: UserDto = await getUserWithToken(
          loginResponse.idToken ?? ''
        );

        if (!getUserResponse.Internal) {
          throw new Error(ErrorMessageEn.WrongUserType);
        }

        const combinedResponses = combineResponsesInternal(
          loginResponse,
          getUserResponse,
          getUserResponse.Internal
        );

        i18next.changeLanguage('en');

        window.analytics.identify(getUserResponse.Id.toString(), {
          email: getUserResponse.Email,
          name: getUserResponse.FirstName,
          type: 'Internal',
          role: 'Internal',
        });

        saveLocalStorage(combinedResponses);

        dispatch(
          internalEmailLoginAuthAction.success(
            user,
            loginResponse,
            combinedResponses
          )
        );

        successHandler(
          lang === 'es' ? SuccessMessageEs.LoggedIn : SuccessMessageEn.LoggedIn,
          true
        );
      } catch (error) {
        dispatch(internalEmailLoginAuthAction.fail(error as Error));

        if (
          (error as any).code &&
          (error as any).code === 'access_denied' // this is the auth0 error object
        ) {
          const message = (error as any).description;
          errorHandler('[InternalEmailLoginAuthAction]', message, error, true);
        } else {
          const newError: Error = error as Error;
          newError.message = (error as any).description;
          errorMessages('[InternalEmailLoginAuthAction]', error as Error, lang);
        }
      }
    },
  {
    start: () => ({ type: AuthActionTypes.EMAIL_LOGIN_START }),
    success: (
      auth0User: Auth0UserProfile | null,
      auth0Response: Auth0ResultExt,
      combinedResponses: ICombinedResponse
    ): AuthAction => ({
      type: AuthActionTypes.EMAIL_LOGIN_SUCCESS,
      auth0User,
      auth0Response,
      combinedResponses,
    }),
    fail: (error: Error): AuthAction => ({
      type: AuthActionTypes.EMAIL_LOGIN_FAIL,
      error,
    }),
  }
);

export const internalGoogleLoginAuthAction = Object.assign(
  (lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(internalGoogleLoginAuthAction.start());
        const googleResponse: Auth0ResultExt = await googleSignIn('internal');
        if (googleResponse.idToken)
          localStorage.setItem('id_token', googleResponse.idToken!);

        let getUserResponse: UserDto | undefined;
        try {
          getUserResponse = await getUserWithToken(
            googleResponse.idToken ?? ''
          );
        } catch (error) {
          throw new Error(ErrorMessageEn.UserNotFoundOnServer);
        }

        if (getUserResponse) {
          if (!getUserResponse.Internal) {
            throw new Error(ErrorMessageEn.WrongUserType);
          }
          const combinedResponses = combineResponsesInternal(
            googleResponse,
            getUserResponse,
            getUserResponse.Internal
          );

          window.analytics.identify(getUserResponse.Internal.Id.toString(), {
            name: googleResponse.idTokenPayload!.givenName,
            type: 'Internal',
            role: 'Internal',
          });

          i18next.changeLanguage('en');

          saveLocalStorage(combinedResponses);

          dispatch(
            internalGoogleLoginAuthAction.success(
              googleResponse,
              combinedResponses
            )
          );
        }
        successHandler(
          lang === 'es' ? SuccessMessageEs.LoggedIn : SuccessMessageEn.LoggedIn,
          true
        );
      } catch (error) {
        const newError: Error = error as Error;
        newError.message = (error as any).description;
        dispatch(internalGoogleLoginAuthAction.fail(error as Error));
        errorMessages('[internalGoogleLoginAuthAction]', newError, lang);
      }
    },
  {
    start: (): AuthAction => ({ type: AuthActionTypes.GOOGLE_AUTH_START }),
    success: (
      auth0Response: Auth0ResultExt,
      combinedResponses: ICombinedResponse
    ): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_SUCCESS,
      auth0Response,
      combinedResponses,
    }),
    fail: (error: Error) => ({
      type: AuthActionTypes.GOOGLE_AUTH_FAIL,
      error,
    }),
  }
);

export const internalLinkedInLoginAuthAction = Object.assign(
  (lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(internalGoogleLoginAuthAction.start());
        const linkedInResponse: Auth0ResultExt = await linkedInSignIn(
          'internal'
        );
        if (linkedInResponse.idToken)
          localStorage.setItem('id_token', linkedInResponse.idToken!);

        let getUserResponse: UserDto | undefined;
        try {
          getUserResponse = await getUserWithToken(
            linkedInResponse.idToken ?? ''
          );
        } catch (error) {
          throw new Error(ErrorMessageEn.UserNotFoundOnServer);
        }

        if (getUserResponse) {
          if (!getUserResponse.Internal) {
            throw new Error(ErrorMessageEn.WrongUserType);
          }
          const combinedResponses = combineResponsesInternal(
            linkedInResponse,
            getUserResponse,
            getUserResponse.Internal
          );

          window.analytics.identify(getUserResponse.Internal.Id.toString(), {
            name: linkedInResponse.idTokenPayload!.givenName,
            type: 'Internal',
            role: 'Internal',
          });

          i18next.changeLanguage('en');

          saveLocalStorage(combinedResponses);

          dispatch(
            internalGoogleLoginAuthAction.success(
              linkedInResponse,
              combinedResponses
            )
          );
        }
        successHandler(
          lang === 'es' ? SuccessMessageEs.LoggedIn : SuccessMessageEn.LoggedIn,
          true
        );
      } catch (error) {
        const newError: Error = error as Error;
        newError.message = (error as any).description;
        dispatch(internalGoogleLoginAuthAction.fail(error as Error));
        errorMessages('[InternalLinkedInLoginAuthAction]', newError, lang);
      }
    },
  {
    start: (): AuthAction => ({ type: AuthActionTypes.GOOGLE_AUTH_START }),
    success: (
      auth0Response: Auth0ResultExt,
      combinedResponses: ICombinedResponse
    ): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_SUCCESS,
      auth0Response,
      combinedResponses,
    }),
    fail: (error: Error) => ({
      type: AuthActionTypes.GOOGLE_AUTH_FAIL,
      error,
    }),
  }
);

export const internalGoogleAuthAction = Object.assign(
  (lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(internalGoogleAuthAction.start());
        const googleResponse = await googleSignIn('internal');
        const { givenName, familyName, email, emailVerified, sub } =
          googleResponse.idTokenPayload;

        const userExists = await checkIfUserExistsByEmail(email);

        if (userExists) throw new Error(ErrorMessageEn.UserAlreadyRegistered);

        const createInternalResponse = await createInternal({
          FirstName: givenName,
          LastName: familyName,
          Email: email,
          SignUpType: 1,
          Auth0Id: sub,
          EmailVerified: emailVerified,
        });

        const combinedResponses = combineResponsesInternal(
          googleResponse,
          createInternalResponse.User,
          createInternalResponse
        );

        window.analytics.identify(createInternalResponse.Id.toString(), {
          name: givenName,
          type: 'Internal',
          role: 'Internal',
        });

        segmentTrack('User Registered', {
          account_type: 'Internal',
          register_type: 'google',
        });

        i18next.changeLanguage('en');

        saveLocalStorage(combinedResponses);

        dispatch(internalGoogleAuthAction.success(combinedResponses));
        successHandler(
          lang === 'en' ? SuccessMessageEn.Welcome : SuccessMessageEs.Welcome,
          true
        );
      } catch (error) {
        dispatch(internalGoogleAuthAction.error(error as Error));
        if (
          (error as any).code &&
          (error as any).code === 'access_denied' // this is the auth0 error object
        ) {
          const message = (error as any).description;
          errorHandler('[InternalGoogleAuthAction]', message, error, true);
        } else {
          const newError: Error = error as Error;
          newError.message = (error as any).description;
          errorMessages('[InternalGoogleAuthAction]', newError, lang);
        }
      }
    },
  {
    start: () => ({ type: AuthActionTypes.GOOGLE_AUTH_START }),
    success: (combinedResponses: ICombinedResponse): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_SUCCESS,
      combinedResponses,
    }),
    error: (error: Error): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_FAIL,
      error,
    }),
  }
);

export const internalLinkedInAuthAction = Object.assign(
  (lang: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(internalGoogleAuthAction.start());
        const googleResponse = await linkedInSignIn('internal');
        const { givenName, familyName, email, emailVerified, sub } =
          googleResponse.idTokenPayload;

        const userExists = await checkIfUserExistsByEmail(email);

        if (userExists) throw new Error(ErrorMessageEn.UserAlreadyRegistered);

        const createInternalResponse = await createInternal({
          FirstName: givenName,
          LastName: familyName,
          Email: email,
          SignUpType: 1,
          Auth0Id: sub,
          EmailVerified: emailVerified,
        });

        const combinedResponses = combineResponsesInternal(
          googleResponse,
          createInternalResponse.User,
          createInternalResponse
        );

        window.analytics.identify(createInternalResponse.Id.toString(), {
          name: givenName,
          type: 'Internal',
          role: 'Internal',
        });

        i18next.changeLanguage('en');

        segmentTrack('User Registered', {
          account_type: 'Internal',
          register_type: 'google',
        });

        saveLocalStorage(combinedResponses);

        dispatch(internalLinkedInAuthAction.success(combinedResponses));
        successHandler(
          lang === 'en' ? SuccessMessageEn.Welcome : SuccessMessageEs.Welcome,
          true
        );
      } catch (error) {
        dispatch(internalLinkedInAuthAction.error(error as Error));
        if (
          (error as any).code &&
          (error as any).code === 'access_denied' // this is the auth0 error object
        ) {
          const message = (error as any).description;
          errorHandler('[InternalLinkedInAuthAction]', message, error, true);
        } else {
          const newError: Error = error as Error;
          newError.message = (error as any).description;
          errorMessages('[InternalLinkedInAuthAction]', newError, lang);
        }
      }
    },
  {
    start: () => ({ type: AuthActionTypes.GOOGLE_AUTH_START }),
    success: (combinedResponses: ICombinedResponse): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_SUCCESS,
      combinedResponses,
    }),
    error: (error: Error): AuthAction => ({
      type: AuthActionTypes.GOOGLE_AUTH_FAIL,
      error,
    }),
  }
);

export const updateVersionAction = Object.assign(
  (version: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      localStorage.setItem('appVersion', version);
      dispatch(updateVersionAction.update(version));
    },
  {
    update: (version: string): AuthAction => ({
      type: AuthActionTypes.UPDATE_VERSION,
      version,
    }),
  }
);
export const updateBasicInfo = Object.assign(
  (candidateProfileDto: CandidateProfileDto): AuthAction => ({
    type: AuthActionTypes.UPDATE_BASIC_INFO,
    candidateProfileDto,
  })
);

export const confirmEmailAction = Object.assign(
  (token: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(confirmEmailAction.start());
        await confirmEmailByToken(token);
        dispatch(confirmEmailAction.success());
      } catch (error) {
        dispatch(confirmEmailAction.fail(error as Error));
      }
    },
  {
    start: (): AuthAction => ({ type: AuthActionTypes.CONFIRM_EMAIL_START }),
    success: (): AuthAction => ({
      type: AuthActionTypes.CONFIRM_EMAIL_SUCCESS,
    }),
    fail: (error: Error): AuthAction => ({
      type: AuthActionTypes.CONFIRM_EMAIL_FAIL,
      error,
    }),
  }
);

export const resendConfirmationEmailAction = Object.assign(
  (email: string) =>
    async (dispatch: ThunkDispatch<IRootState, unknown, AuthAction>) => {
      try {
        dispatch(resendConfirmationEmailAction.start());
        await resendConfirmationEmail(email);
        dispatch(resendConfirmationEmailAction.success());
      } catch (error) {
        dispatch(resendConfirmationEmailAction.fail(error as Error));
      }
    },
  {
    start: (): AuthAction => ({
      type: AuthActionTypes.RESEND_CONFIRMATION_EMAIL_START,
    }),
    success: (): AuthAction => ({
      type: AuthActionTypes.RESEND_CONFIRMATION_EMAIL_SUCCESS,
    }),
    fail: (error: Error): AuthAction => ({
      type: AuthActionTypes.RESEND_CONFIRMATION_EMAIL_FAIL,
      error,
    }),
  }
);

export const updateCandidateScoreAction = (score: string) => ({
  type: AuthActionTypes.UPDATE_CANDIDATE_SCORE,
  score,
});
