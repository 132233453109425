import { FC, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './ExtracurricularForm.module.css';
import { InitialFormStateTypes } from '../../types/user_info';
import AddButton from '../Buttons/AddButton';
import { SaveState } from '../../types/ForrmType';
import SaveButton from '../Buttons/SaveButton';
import ExtracurricularForm from './ExtracurricularForm';
import CancelModal from '../CancelModal/CancelModal';
import FormFooter from '../FormFooter/FormFooter';
import AddMoreFooter from '../Buttons/AddMoreFooter';
import {
  useAddCandidateExtracurricularsInternalMutation,
  useAddCandidateExtracurricularsMutation,
  useDeleteCandidateExtracurricularMutation,
  useGetCandidateExtracurricularQuery,
} from '../../store/modules/candidate-extracurricular';
import { errorHandler, successHandler } from '../../store/utils';
import { useAppDispatch } from '../../hooks';
import { candidatesApi } from '../../store/modules/candidates';

interface ICounters {
  [key: string]: number;
}

interface ExtracurricularContainer {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean, continueEditing: boolean) => void;
  show?: boolean;
  secondary?: boolean;
}

const ExtracurricularFormContainer: FC<ExtracurricularContainer> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  secondary,
}) => {
  const { candidateId } = useParams();
  const candidateIdFromLocalStorage = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;

  const dispatch = useAppDispatch();

  const id = candidateId || candidateIdFromLocalStorage;

  const [t, i18next] = useTranslation('global');

  const { data: extracurricularData } = useGetCandidateExtracurricularQuery({
    lang: i18next.language as 'es' | 'en',
    candidateId: +id,
  });

  const [addCandidateExtracurriculars, { isLoading, isSuccess, isError }] =
    useAddCandidateExtracurricularsMutation();
  const [
    addCandidateExtracurricularsInternal,
    {
      isLoading: isLoadingInternal,
      isSuccess: isSuccessInternal,
      isError: isErrorInternal,
    },
  ] = useAddCandidateExtracurricularsInternalMutation();
  const [deleteCandidateExtracurricular, { isSuccess: deleteSuccess }] =
    useDeleteCandidateExtracurricularMutation();

  const loadingExtracurriculars = useCallback(() => {
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }
    if (isSuccess || isSuccessInternal) {
      return SaveState.FULFILLED;
    }
    if (isError || isErrorInternal) {
      return SaveState.FAILED;
    }
    return SaveState.IDLE;
  }, [
    isError,
    isErrorInternal,
    isSuccess,
    isSuccessInternal,
    isLoading,
    isLoadingInternal,
  ]);
  const {
    control,
    handleSubmit,
    setValue,
    register,
    reset,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({
    defaultValues: { extracurricular: extracurricularData },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'extracurricular' as never,
  });
  useEffect(() => {
    setValue('extracurricular', extracurricularData);
  }, [setValue, extracurricularData]);

  useEffect(() => {
    if (
      (loadingExtracurriculars() === SaveState.FULFILLED || deleteSuccess) &&
      extracurricularData
    ) {
      reset({ extracurricular: extracurricularData });
    }
  }, [deleteSuccess, extracurricularData, loadingExtracurriculars, reset]);

  const saveInfo = async (data: InitialFormStateTypes) => {
    if (dirtyFields.extracurricular) {
      const modifiedCardIndexes = Object.keys(dirtyFields?.extracurricular);
      if (data.extracurricular) {
        const modifiedCards = data.extracurricular?.filter((_, ind) =>
          modifiedCardIndexes.includes(ind.toString())
        );
        try {
          if (Number(candidateId)) {
            await addCandidateExtracurricularsInternal({
              candidateId: Number(candidateId),
              data: modifiedCards,
              lang: i18next.language as 'es' | 'en',
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
          } else {
            await addCandidateExtracurriculars({
              data: modifiedCards,
              lang: i18next.language as 'es' | 'en',
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
          }
          if (setEdit) {
            setEdit(true);
          }
          successHandler(t('common.saved'), true);
          window.analytics.track('Extracurricular form submitted');
        } catch (error) {
          // error
        }
      }
    }
  };

  const appendField = () => {
    if (fields.length >= 5) {
      return errorHandler(
        '[ExtracurricularForm]',
        'Extracurricular information limit reached',
        null,
        true
      );
    }
    append({
      ExtracurricularType: { val: '', mult: 1 },
      ExtracurricularDetail: { val: '', mult: 1 },
    });

    return null;
  };

  const [counter, setCounter] = useState<ICounters>({
    '0': 0,
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
  });

  const removeCard = async (index: number, deleteId: number) => {
    await deleteCandidateExtracurricular({
      id: deleteId,
      lang: i18next.language as 'es' | 'en',
    });
    dispatch(candidatesApi.util.invalidateTags(['Score']));
    if (deleteSuccess) {
      remove(index);
    }
  };

  const removeUnsavedCard = (index: number) => {
    remove(index);
  };

  const renderForms = () => {
    return fields.map((row: any, index) => {
      const { Id } = row;
      const extracurricularType = register(
        `extracurricular.${index}.ExtracurricularType.val`,
        {
          required: t('extracurricular.requiredTypeOfActivity').toString(),
        }
      );
      const ExtracurricularDetail = register(
        `extracurricular.${index}.ExtracurricularDetail.val`,
        {
          required: t('extracurricular.requiredDescription').toString(),
        }
      );

      return (
        <ExtracurricularForm
          key={row.id}
          t={t}
          index={index}
          extracurricularType={extracurricularType}
          ExtracurricularDetail={ExtracurricularDetail}
          counter={counter}
          setCounter={setCounter}
          control={control}
          remove={removeCard}
          errors={errors}
          formIndex={index}
          removeUnsaved={removeUnsavedCard}
          deleteId={Id}
          secondary={secondary}
        />
      );
    });
  };
  return (
    <div style={{ marginTop: '-1rem' }}>
      <form
        data-testid="extracurriculars-form"
        onSubmit={handleSubmit(saveInfo)}
        className={classes.Container}
      >
        {!isEdit && (
          <div className={classes.buttonContainer}>
            <AddButton onClick={appendField} />
          </div>
        )}
        <div className={classes.CardContainer}>{renderForms()}</div>
        {fields.length > 1 && !isEdit && (
          <AddMoreFooter
            onClick={appendField}
            label={t('extracurricular.add')}
          />
        )}
        <div className={classes.ButtonContainer}>
          {isEdit ? (
            <FormFooter
              onClickCancel={onClickCancel}
              disabled={
                loadingExtracurriculars() === SaveState.PENDING || !isDirty
              }
              saveState={isDirty ? SaveState.IDLE : loadingExtracurriculars()}
              translate={t}
              submit
            />
          ) : (
            <SaveButton
              disabled={
                loadingExtracurriculars() === SaveState.PENDING || !isDirty
              }
              state={isDirty ? SaveState.IDLE : loadingExtracurriculars()}
              t={t}
              submit
            />
          )}
        </div>
      </form>
      {show && onClickCancelModal && (
        <CancelModal onClickCancelModal={onClickCancelModal} />
      )}
    </div>
  );
};

ExtracurricularFormContainer.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  secondary: false,
};

export default ExtracurricularFormContainer;
