import { useState } from 'react';
import useWindowWidth from './useWindowWidth';

const useInternalEditableContainer = () => {
  const [show, setShow] = useState(false);
  const [newFormStep, setNewFormStep] = useState(false);
  const isMobile = useWindowWidth() <= 650;
  const [isEdit, setIsEdit] = useState(true);

  const onClickPencil = () => {
    if (isMobile && window) {
      window.scrollTo(0, 0);
    }
    setIsEdit(false);
  };

  const handleCancel = () => {
    setShow(true);
  };

  const handleCancelModal = (isCancel: boolean, continueEditing: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    if (newFormStep && continueEditing) {
      setNewFormStep(false);
    }
    setShow(false);
  };

  const onClickSave = () => {
    setNewFormStep(false);
  };

  const onClickPlus = () => {
    setNewFormStep(!newFormStep);
  };

  return {
    onClickPencil,
    handleCancel,
    handleCancelModal,
    onClickSave,
    onClickPlus,
    show,
    isEdit,
    newFormStep,
    setIsEdit,
    isMobile,
  };
};

export default useInternalEditableContainer;
