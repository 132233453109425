import { useEffect, useState } from 'react';
import { ReactComponent as StarIcon } from '$icons/star.svg';
import {
  useGetRatingEmployerQuery,
  useGetRatingInternalQuery,
  useUpdateRatingMutation,
} from '$store/modules/ratings';
import { segmentTrack } from '$utils/handleSegment';
import classes from './CandidateVideosRate.module.css';

interface Props {
  additionalVideoId?: number;
  primary?: boolean;
  candidateId?: number | undefined;
}

const defaultProps = {
  additionalVideoId: undefined,
  primary: false,
  candidateId: undefined,
};

const CandidateVideosRate = ({
  additionalVideoId,
  primary,
  candidateId,
}: Props) => {
  const { data: internalRate } = useGetRatingInternalQuery(
    {
      data: {
        ...(additionalVideoId && { additionalVideoId }),
      },
    },
    { skip: primary }
  );

  const { data: employerRate } = useGetRatingEmployerQuery(
    {
      data: {
        ...(additionalVideoId && { additionalVideoId }),
      },
    },
    { skip: !primary }
  );
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  const [updateRating] = useUpdateRatingMutation();

  const rateVideo = () => {
    updateRating({
      data: {
        ...(additionalVideoId && { additionalVideoId }),
        rate: hoverIndex + 1,
      },
    });
    if (primary && candidateId !== undefined) {
      segmentTrack('Video Rating', {
        video_id: additionalVideoId,
        video_type: 'AdditionalVideos',
        candidate_id: candidateId,
        rating: hoverIndex + 1,
      });
    }
  };

  useEffect(() => {
    const data = internalRate ?? employerRate;
    if (data) {
      setHoverIndex(data.Rating - 1);
    }
  }, [internalRate, employerRate]);

  const stars = Array.from({ length: 5 }, (item, index) => (
    <StarIcon
      key={index}
      fill={index <= hoverIndex ? '#FDD835' : '#D3D3D4'}
      width="24px"
      height="24px"
      onMouseEnter={() => setHoverIndex(index)}
      onClick={rateVideo}
    />
  ));
  return <div className={classes.Rate}>{stars}</div>;
};

export default CandidateVideosRate;

CandidateVideosRate.defaultProps = defaultProps;
