import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import classes from './DuplicatePosition.module.css';
import DuplicatePositionForm from './DuplicatePositionForm';
import PositionDto from '../../types/serverTypes/positionDto';
import PositionInternalDto from '../../types/serverTypes/positionInternalDto';

interface Props {
  modalIsOpen: boolean;
  position: PositionDto | PositionInternalDto;
  setModal: (show: boolean) => void;
  setOpenCustomRosterModal?: (show: boolean) => void;
}

const DuplicatePositionModal: FC<Props> = ({
  modalIsOpen,
  position,
  setModal,
  setOpenCustomRosterModal,
}) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const [t] = useTranslation('global');

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModal(false)}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      contentLabel="Duplicate New Position"
      ariaHideApp={false}
    >
      <h1 className={classes.modalTitle} data-testid="add-new-job-title">
        {t('DuplicateJob.Title')}
      </h1>
      <p>{t('DuplicateJob.Description')}</p>
      <p className={classes.modalSubtitle} data-testid="duplicate-job-subtitle">
        {t('EmployerAddPosition.Position')}
      </p>
      <DuplicatePositionForm
        position={position}
        setModal={setModal}
        setOpenCustomRosterModal={setOpenCustomRosterModal}
      />
    </Modal>
  );
};

DuplicatePositionModal.defaultProps = {
  setOpenCustomRosterModal: undefined,
};

export default DuplicatePositionModal;
