import { CandidateData } from '../../../../types/candidates';
import CandidatesSearchFilters from '../../../../types/candidatesPaginatedSearch';
import filterByAdditionalTags from './filterByAdditionalTags';
import filterByEducation from './filterByEducation';
import filterByEmail from './filterByEmail';
import filterByEnglishLevel from './filterByEnglishLevel';
import filterByFiles from './filterByFiles';
import filterById from './filterById';
import filterByLanguages from './filterByLanguages';
import filterByName from './filterByName';
import filterBySalaryRange from './filterBySalaryRange';
import filterBySkill from './filterBySkills';
import filterByYearsOfExperience from './filterByYearsOfExperience';
import filterByPreviousExperience from './fitlerByPreviousExperience';

const SearchFilter = (
  filters: CandidatesSearchFilters,
  candidatesData: CandidateData[]
): CandidateData[] => {
  let filtersBarData = [...candidatesData];
  const SEARCH_EVERYWHERE = !!filters.query;

  /**
   * Search every field
   */
  if (SEARCH_EVERYWHERE) {
    const normalizedQuery = filters?.query?.toLowerCase() ?? '';
    const dataAndFilters: {
      searchQuery: any;
      filter: (param1: any, param2: any) => boolean;
    }[] = [
      { searchQuery: filters?.id, filter: filterById },
      {
        searchQuery: filters?.email?.toLowerCase() ?? '',
        filter: filterByEmail,
      },
      { searchQuery: normalizedQuery.split(' '), filter: filterByName },
      { searchQuery: [normalizedQuery], filter: filterBySkill },
      { searchQuery: normalizedQuery.split(' '), filter: filterByLanguages },
      { searchQuery: normalizedQuery, filter: filterByEducation },
      { searchQuery: normalizedQuery, filter: filterByEnglishLevel },
      //   { searchQuery: normalizedQuery, filter: filterBySalaryRange }, //salary doesnt apply
      //   { searchQuery: normalizedQuery, filter: filterByYearsOfExperience }, //english level doesnt apply
      { searchQuery: [normalizedQuery], filter: filterByFiles },
      { searchQuery: [normalizedQuery], filter: filterByPreviousExperience },
      { searchQuery: [normalizedQuery], filter: filterByAdditionalTags },
    ];

    filtersBarData = candidatesData.filter((singleCandidate) => {
      return dataAndFilters.some(({ searchQuery, filter }) => {
        return filter(singleCandidate, searchQuery);
      });
    });
  }

  /** Apply cps filter */
  if (
    filters.cps &&
    filters.cps !== '' &&
    filters.cps.toLowerCase() !== 'find' &&
    filters.cps.toLowerCase() !== 'all'
  ) {
    filtersBarData = filtersBarData.filter((singleCandidate: CandidateData) => {
      return singleCandidate.CandidatesPositionStatus?.some(
        (singlePositionStatus) => {
          return (
            singlePositionStatus.Status.toLowerCase() ===
            filters?.cps?.toLowerCase()
          );
        }
      );
    });
  }

  /**
   * Apply name filter
   */
  if (filters.name) {
    const normalizedSearchNameText = filters.name.toLowerCase().split(' ');
    filtersBarData = filtersBarData.filter((singleCandidate: CandidateData) =>
      filterByName(singleCandidate, normalizedSearchNameText)
    );
  }

  /**
   * Apply skill filter
   */
  if (filters.skills && filters.skills.length > 0) {
    filtersBarData = filtersBarData.filter((singleCandidate) =>
      filterBySkill(singleCandidate, filters.skills as string[])
    );
  }

  /**
   * Apply languages filter
   */
  if (filters.languages && filters.languages.length > 0) {
    filtersBarData = filtersBarData.filter((singleCandidate) =>
      filterByLanguages(singleCandidate, filters.languages as string[])
    );
  }

  /**
   * Apply education filter
   */
  if (filters.education && filters.education.length > 0) {
    filtersBarData = filtersBarData.filter((singleCandidate) => {
      return (
        filters.education &&
        filterByEducation(singleCandidate, filters.education[0])
      );
    });
  }

  /**
   * Apply english level filter
   */
  if (filters.englishLevel && filters.englishLevel.length > 0) {
    filtersBarData = filtersBarData.filter((singleCandidate) => {
      return (
        filters.englishLevel &&
        filterByEnglishLevel(singleCandidate, filters.englishLevel[0])
      );
    });
  }

  /**
   * Apply salary range filter
   */
  if (filters.salaryRange && filters.salaryRange.length === 2) {
    filtersBarData = filtersBarData.filter(
      (singleCandidate: CandidateData) =>
        filters?.salaryRange &&
        filterBySalaryRange(singleCandidate, filters.salaryRange)
    );
  }

  /**
   * Apply years of experience
   */
  if (filters.yearsOfExperience && filters.yearsOfExperience.length === 2) {
    filtersBarData = filtersBarData.filter((singleCandidate: CandidateData) => {
      return (
        filters.yearsOfExperience &&
        filterByYearsOfExperience(singleCandidate, filters.yearsOfExperience)
      );
    });
  }

  /**
   * Apply files filters
   */
  if (filters.fileCategories && filters.fileCategories.length > 0) {
    filtersBarData = filtersBarData.filter((singleCandidate: CandidateData) => {
      return (
        filters.fileCategories &&
        filterByFiles(singleCandidate, filters.fileCategories)
      );
    });
  }

  /**
   * Apply previous experience
   */
  if (filters.workExperience && filters.workExperience.length > 0) {
    filtersBarData = filtersBarData.filter((singleCandidate: CandidateData) => {
      return (
        filters.workExperience &&
        filterByPreviousExperience(singleCandidate, filters.workExperience)
      );
    });
  }

  /**
   * Apply additional tags filter
   */
  if (filters.additionalTags && filters.additionalTags.length > 0) {
    filtersBarData = filtersBarData.filter((singleCandidate: CandidateData) => {
      return (
        filters.additionalTags &&
        filterByAdditionalTags(singleCandidate, filters.additionalTags)
      );
    });
  }

  /**
   * Apply email filter
   */
  if (filters.email) {
    filtersBarData = filtersBarData.filter((singleCandidate: CandidateData) => {
      return filters.email && filterByEmail(singleCandidate, filters.email);
    });
  }

  /**
   * Apply ID filter
   */
  if (filters.id) {
    filtersBarData = filtersBarData.filter((singleCandidate: CandidateData) => {
      return filters.id && filterById(singleCandidate, filters.id);
    });
  }

  return filtersBarData;
};

export default SearchFilter;
