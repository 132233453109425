import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './PositionCardMenu.module.css';
import { ReactComponent as MenuButtonDots } from '../../../icons/MenuButtonDots.svg';
import PositionCardModal from './PositionCardModal/PositionCardModal';
import ConfirmModalContent from './PositionCardModal/ConfirmModalContent/ConfirmModalContent';
import PositionInternalDto from '../../../types/serverTypes/positionInternalDto';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import PositionDto from '../../../types/serverTypes/positionDto';
import PositionCardMenuOptions from './PositionCardMenuOptions/PositionCardMenuOptions';
import {
  useActivatePositionMutation,
  useDeactivatePositionMutation,
  useDeletePositionMutation,
} from '../../../store/modules/companies';
import { usePositions } from '../../../store/modules/companies/hooks/use-positions';

interface Props {
  isInternal: boolean;
  position: PositionInternalDto | PositionDto;
  setOpenEditModal?: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenDuplicateModal: (show: boolean) => void;
}

const PositionCardMenu: FC<Props> = ({
  isInternal,
  position,
  setOpenEditModal,
  handleOpenDuplicateModal,
}) => {
  const [t] = useTranslation('global');
  const [showMenuOptions, setShowMenuOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const menuContainer = useRef<HTMLDivElement>(null);

  const [activatePosition] = useActivatePositionMutation();
  const [deactivatePosition] = useDeactivatePositionMutation();
  const [deletePosition] = useDeletePositionMutation();

  const {
    activatePositionAction,
    deactivatePositionAction,
    removePositionAction,
  } = usePositions();

  useOnClickOutside(menuContainer, () => setShowMenuOptions(false));

  const handleAction = () => {
    if (selectedAction === 'Activate') {
      activatePosition({ positionId: position.Id });
      activatePositionAction(position.Id);
    } else if (selectedAction === 'Deactivate') {
      deactivatePosition({ positionId: position.Id });
      deactivatePositionAction(position.Id);
    } else if (selectedAction === 'Delete') {
      deletePosition({ positionId: position.Id });
      removePositionAction(position.Id);
    }
    setSelectedAction('');
  };

  const modalContent = () => {
    const capitalized = selectedAction;
    return {
      confirmTitle: t(`PositionCardModal.Title${capitalized}`),
      confirmText: t(`PositionCardModal.Text${capitalized}`),
      confirmSubText: t(`PositionCardModal.Subtext${capitalized}`),
      confirmBack: t(`PositionCardModal.ConfirmBack${capitalized}`),
      confirmButton: t(`PositionCardModal.ConfirmButton${capitalized}`),
    };
  };

  const handleShowModal = (selected: string) => {
    setShowModal(true);
    setSelectedAction(selected);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAction('');
  };

  return (
    <>
      {selectedAction !== '' && (
        <PositionCardModal
          modalIsOpen={showModal}
          handleClose={handleCloseModal}
          contentLabel={`${selectedAction} position`}
        >
          <ConfirmModalContent
            handleClose={handleCloseModal}
            pendingActionState={position?.actionStatus!}
            handleConfirm={handleAction}
            textContent={modalContent()}
          />
        </PositionCardModal>
      )}
      <div
        aria-label="Menu button"
        ref={menuContainer}
        role="button"
        onClick={() => {
          setShowMenuOptions((prev) => !prev);
        }}
        onKeyDown={() => {}}
        tabIndex={0}
        className={classes.MenuButton}
        data-testid="position-card-menu-button"
      >
        <MenuButtonDots />
        <PositionCardMenuOptions
          isInternal={isInternal}
          position={position}
          showMenuOptions={showMenuOptions}
          setOpenEditModal={setOpenEditModal}
          handleShowModal={handleShowModal}
          handleOpenDuplicateModal={handleOpenDuplicateModal}
          setShowMenuOptions={setShowMenuOptions}
        />
      </div>
    </>
  );
};

PositionCardMenu.defaultProps = {
  setOpenEditModal: undefined,
};

export default PositionCardMenu;
