import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './VideoGallery.module.css';
import { NoVideos } from '../NoVideos';
import { useCandidateId } from '../../../../../hooks/useCandidateId';
import { useGetAdditionalVideosByCandidateIdQuery } from '../../../../../store/modules/additional-videos';
import { VideoList } from '../VideoList';
import Spinner from '../../../../Spinner/Spinner';
import { ReactComponent as AddIcon } from '../../../../../icons/Add.svg';
import { ImportVideoModal } from '../ImportVideoModal';
import { useAppSelector } from '../../../../../hooks';
import { HideVideosModal } from '../HideVideosModal';
import { ShowVideosModal } from '../ShowVideosModal';
import { ShowButtons } from '../ShowButtons';
import { useAdditionalVideo } from '../../../../../store/modules/additional-videos/hooks';

interface VideoGalleryProps {
  internal?: boolean;
  employer?: boolean;
}
export const VideoGallery = ({ internal, employer }: VideoGalleryProps) => {
  const id = useCandidateId();
  const [t, i18next] = useTranslation('global');
  const { setAdditionalVideos } = useAdditionalVideo();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [showVideosModal, setShowVideosModal] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState<number[]>([]);
  const [selectedShowVideos, setSelectedShowVideos] = useState<number[]>([]);
  const { activeVideo } = useAppSelector((state) => state.additionalVideoSlice);
  const { data, isLoading, isSuccess } =
    useGetAdditionalVideosByCandidateIdQuery({
      candidateId: id,
      lang: i18next.language as 'en' | 'es',
    });

  useEffect(() => {
    if (isSuccess && data) {
      setAdditionalVideos(data);
    }
  }, [data, isSuccess, setAdditionalVideos]);
  if (isLoading) {
    return (
      <div style={{ display: 'flex', width: '100%', alignContent: 'center' }}>
        <Spinner height="200px" />
      </div>
    );
  }

  return (
    <section className={classes.container}>
      <header className={classes.header}>
        <p className={classes.title}>
          {internal || employer
            ? t('AdditionalVideos.galleryTitleInternal')
            : t('AdditionalVideos.galleryTitle')}
        </p>
        <section className={classes.headerAction}>
          {internal && !activeVideo && (
            <ShowButtons
              selectedShowVideos={selectedShowVideos}
              selectedVideos={selectedVideos}
              showCheckbox={showCheckbox}
              setSelectedVideos={setSelectedVideos}
              setSelectedShowVideos={setSelectedShowVideos}
              setShowCheckbox={setShowCheckbox}
              setShowVisibilityModal={setShowVisibilityModal}
              setShowVideosModal={setShowVideosModal}
            />
          )}
          {internal && !employer && (
            <button
              className={classes.importButton}
              onClick={() => setIsModalOpen(true)}
              type="button"
            >
              {t('AdditionalVideos.buttons.upload')}
              <AddIcon className={classes.addIcon} />
            </button>
          )}
        </section>
      </header>
      <br />
      <ImportVideoModal isModalOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      {data?.length === 0 ? (
        <NoVideos internal={internal} />
      ) : (
        data && (
          <VideoList
            videos={data}
            showCheckbox={showCheckbox}
            setSelectedVideos={setSelectedVideos}
            selectedVideos={selectedVideos}
            internal={internal}
            setSelectedShowVideos={setSelectedShowVideos}
            selectedShowVideos={selectedShowVideos}
          />
        )
      )}
      {showVisibilityModal && (
        <HideVideosModal
          setModal={setShowVisibilityModal}
          setShowCheckbox={setShowCheckbox}
          selectedVideos={selectedVideos}
          setSelectedVideos={setSelectedVideos}
        />
      )}
      {showVideosModal && (
        <ShowVideosModal
          setModal={setShowVideosModal}
          setShowCheckbox={setShowCheckbox}
          selectedVideos={selectedShowVideos}
          setSelectedVideos={setSelectedShowVideos}
        />
      )}
    </section>
  );
};

VideoGallery.defaultProps = {
  internal: false,
  employer: false,
};
