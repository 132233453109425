import { CandidateBuilder } from '../candidates.types';

export const getCandidateInfoToken = (builder: CandidateBuilder) =>
  builder.query<{ token: string }, void>({
    query: () => ({
      url: `/candidates/token`,
      method: 'GET',
    }),
    providesTags: () => [{ type: 'Candidate' }],
  });
