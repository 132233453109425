import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
import classes from './Button.module.css';

interface IButtonProps {
  onClick: () => void;
}

const AddButton: FC<IButtonProps> = ({ onClick }) => {
  const [t] = useTranslation('global');
  return (
    <button
      type="button"
      className={`btn ${classes.addButton}`}
      onClick={onClick}
    >
      <AddIcon />
      {t('forms.addMore')}
    </button>
  );
};

export default AddButton;
