import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import EmptyState from '../../../../Positions/EmptyState/EmptyState';
import NotesReadOnly from '../../../../EditableContainer/NotesReadOnly/NotesReadOnly';
import { Note } from '../../../../../types/candidates';

interface Props {
  notes?: Partial<Note>[];
  isGeneral: boolean;
}

const NotesContent: FC<Props> = ({ notes, isGeneral }) => {
  const capitalize = (str: string) => {
    return `${str.charAt(0)?.toUpperCase()}${str.slice(1).toLowerCase()}`;
  };
  const [t] = useTranslation('global');

  const emptyStateText = !isGeneral
    ? t('Notes.NoPositionNotes')
    : t('Notes.NoPositionGeneralNotes');

  return notes?.length === 0 ? (
    <EmptyState
      text={emptyStateText}
      description={t('Notes.NoPositionNotesSubText')}
      height="30vh"
      secondary
    />
  ) : (
    <div>
      {notes?.map((note, idx) => (
        <NotesReadOnly
          title={note.Title!}
          content={note.Content!}
          date={dayjs(note.CreateTime).format('MM/DD/YYYY')}
          name={`${capitalize(note.CreatedBy!.FirstName)} ${capitalize(
            note.CreatedBy!.LastName
          )}`}
          key={note.Id}
          hasDivider={idx !== (notes?.length || 0) - 1}
        />
      ))}
    </div>
  );
};

NotesContent.defaultProps = {
  notes: [],
};
export default NotesContent;
