import dayjs from 'dayjs';

const isNewCandidate = (timeStamp?: Date, isEmployee = false) => {
  if (!timeStamp) return false;
  const createTime: dayjs.Dayjs = dayjs(timeStamp);
  const now = dayjs();
  const timeDiff = now.diff(createTime, 'day');
  const isNew = isEmployee ? timeDiff < 7 : timeDiff < 1;

  return isNew;
};

export default isNewCandidate;
