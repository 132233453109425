import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';
import ActiveValues from '$constants/ActiveValues';
import { useCreateEmptyInterviewMutation } from '$store/modules/interview';
import classes from './CandidateSummaryCard.module.css';
import getRangeOfColor from '../../../../utils/rangeofColors';
import computeLastJob from '../../../../utils/computeLastJob';
import capitalizedName from '../../../../utils/capitalizedName';
import isNewCandidate from '../../../../utils/isNewCandidate';
import DegreeTypes from '../../../../types/DegreeTypes';
import CandidateVideosModal from '../../CandidateVideosModal/CandidateVideosModal';
import CandidateActionBtnLeft from '../CandidateActionBtns/CandidateActionBtnLeft';
import SwitchTypes from '../../../../constants/CandidatesSwitchTypes';
import HireConfirmModal from './HireConfirmModal/HireConfirmModal';
import HoverTooltip from '../../../Tooltip/HoverTooltip';
import CandidateActionBtnRightGraph from '../CandidateActionBtns/CandidateActionBtnRightGraph';
import {
  companiesApi,
  useLoadOnePositionQuery,
} from '../../../../store/modules/companies';
import type { CandidateData } from '../../../../types/candidates';
import CardSummaryVideoButton from './CardSummaryVideoButton/CardSummaryVideoButton';
import { useOriginPath } from '../../../../hooks/use-origin-path';
import { useAppDispatch } from '../../../../hooks';
import {
  StatusCandidatePositionOptions,
  candidatesApi,
  useUpdateCandidatePositionStatusMutation,
} from '../../../../store/modules/candidates';
import { triggerSegmentOnCloseModalTrack } from '../../../../store/globals/LastPlayedVideo';

interface Props {
  jobPositionStatus: string;
  candidate: CandidateData;
  candidateIndex: number;
  positionId: number;
  setSwitchingId: Dispatch<SetStateAction<number | null>>;
}

const CandidateSummaryCardGraph: FC<Props> = ({
  jobPositionStatus,
  candidate,
  candidateIndex: i,
  positionId,
  setSwitchingId,
}) => {
  const [searchParams] = useSearchParams();
  const skillsParams = searchParams.get('Skills');
  const currentSearchForSkins = skillsParams ? skillsParams.split(',') : [];
  const [showHireModal, setHireModal] = useState<boolean>(false);
  const [dataModalVideo, setDataModalVideo] = useState<{
    isModalOpen: boolean;
    candidateId: number;
    positionId: number;
  }>({ candidateId: candidate.Id, positionId, isModalOpen: false });

  const [lastJob, setLastJob] = useState<{
    title: string;
    company: null | string;
  }>({
    title: '',
    company: null,
  });
  const [t] = useTranslation('global');
  const { setOriginPath } = useOriginPath();
  const cps = useMemo(() => searchParams.get('cps') ?? 'new', [searchParams]);

  const { data } = useLoadOnePositionQuery({
    id: positionId,
  });
  const mappedSkills = data?.Skills_position.map((skill) => skill.Title);
  const dispatch = useAppDispatch();

  const [createEmptyInterview] = useCreateEmptyInterviewMutation();

  const [updateCandidatePositionStatus] =
    useUpdateCandidatePositionStatusMutation();

  useEffect(() => {
    setHireModal(false);
  }, [candidate]);

  const handleCloseModal = () => {
    dispatch(triggerSegmentOnCloseModalTrack());
    setDataModalVideo({ ...dataModalVideo, isModalOpen: false });
  };

  const handleShowHireModal = (show: boolean) => {
    setHireModal(show);
  };

  const handleRequestInterview = async () => {
    createEmptyInterview({
      candidatePositionStatusId: candidate.CandidatesPositionStatus[0].Id,
    }).then((newInterviewData) => {
      if ('error' in newInterviewData) return;
      dispatch(companiesApi.util.invalidateTags(['PositionsList', 'Company']));
      dispatch(candidatesApi.util.invalidateTags(['Candidates']));
      setTimeout(() => {
        setSwitchingId(candidate.Id);
      }, 1000);
    });
  };

  const handleChangePositionStatus = async (
    newStatus: StatusCandidatePositionOptions
  ) => {
    await updateCandidatePositionStatus({
      data: {
        CandidatePositionStatusId: candidate?.CandidatesPositionStatus[0]?.Id,
        Status: newStatus,
      },
    });

    dispatch(companiesApi.util.invalidateTags(['PositionsList']));
    dispatch(candidatesApi.util.invalidateTags(['Candidates']));
    setSwitchingId(candidate.Id);
  };

  const computeHighestDegree = (
    candidateEducation: CandidateData['CandidateEducation']
  ): JSX.Element => {
    if (!candidateEducation) return <> N/A </>;
    const degreeObj = candidateEducation.filter((ed) => {
      const highestDegreeEnumIndex = Math.max(
        ...candidateEducation!.map((o) =>
          Object.keys(DegreeTypes).indexOf(
            o?.DegreeType?.toUpperCase().replace(' ', '_')
          )
        )
      );
      if (highestDegreeEnumIndex === -1) {
        return false;
      }

      return (
        ed.DegreeType ===
        Object.values(DegreeTypes)[highestDegreeEnumIndex].toString()
      );
    })[0];

    if (!degreeObj) return <> N/A </>;

    return (
      <>
        <p className={classes.DegreeeInfo}>{degreeObj.DegreeType}</p>
        {degreeObj.DegreeName && (
          <p className={classes.DegreeeInfo}>{degreeObj.DegreeName}</p>
        )}
      </>
    );
  };

  useEffect(() => {
    if (
      candidate.CandidateWorkExperience &&
      candidate.CandidateWorkExperience.length
    ) {
      const lj = computeLastJob(candidate.CandidateWorkExperience);
      setLastJob(lj);
    } else {
      setLastJob({
        title: 'N/A',
        company: null,
      });
    }
  }, [candidate.CandidateWorkExperience]);

  const feedbackRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (candidate.WorkSummary) {
      const maxLength = 200;
      const truncateText =
        candidate.WorkSummary.length > maxLength
          ? `${candidate.WorkSummary.substring(0, maxLength)} ...`
          : candidate.WorkSummary;
      const feedbackString = truncateText;
      const feedbackArray = feedbackString.split('\n');
      const feedbackHtml = feedbackArray.join('<br>');
      feedbackRef.current!.innerHTML = feedbackHtml;
    }
  }, [candidate.WorkSummary]);

  const validStatus = ['new', 'offerSent', 'requestInterview', 'shortlisted'];

  const isValidStatus = validStatus.includes(cps);
  const isPositionClosed = jobPositionStatus === 'Closed';

  const status = useMemo(() => {
    return {
      offerSent: t('CandidatePositionSummaryCard.offerSent'),
      requestInterview: t('CandidatePositionSummaryCard.requestInterview'),
    }[cps ?? ''];
  }, [cps, t]);
  const showStatus =
    isValidStatus && candidate?.CandidatesPositionStatus?.[0]?.Status !== 'new';

  const handleName = (firstName?: string, lastName?: string) => {
    if (firstName && lastName) {
      return capitalizedName(`${firstName} ${lastName}`);
    }
    if (firstName) {
      return capitalizedName(`${firstName}`);
    }
    if (lastName) {
      return capitalizedName(`${lastName}`);
    }

    return '';
  };
  return (
    <li className={classes.CandidateCard}>
      <CandidateVideosModal
        modalIsOpen={dataModalVideo.isModalOpen && candidate.Videos !== null}
        handleClose={handleCloseModal}
        candidateId={dataModalVideo.candidateId}
        positionId={dataModalVideo.positionId}
        additionalVideos={candidate.AdditionalVideos}
      />
      {Boolean(candidate.CandidatesPositionStatus) && isValidStatus && (
        <HireConfirmModal
          modalIsOpen={showHireModal}
          handleClose={handleShowHireModal}
          candidatePositionStatusId={0}
          positionId={positionId}
          candidateArrayIndex={i}
          candidateId={candidate.Id}
          setSwitchingId={setSwitchingId}
        />
      )}
      <div className={classes.leftCard}>
        <header className={classes.Header}>
          <div className={classes.HeaderLeft}>
            <div className={classes.ImageContainer}>
              <DefaultIcon2
                picture={candidate.User?.ProfilePicture?.Url}
                fontSize="text-3xl"
                firstName={candidate.User?.FirstName}
                lastName={candidate.User?.LastName}
              />
            </div>
            <div className={classes.HeaderData}>
              <div className={classes.HeaderDataLeft}>
                <h3>
                  {handleName(
                    candidate.User?.FirstName,
                    candidate.User?.LastName
                  )}
                </h3>
                <Link
                  role="button"
                  className={classes.ProfileLink}
                  data-testid="profile-link"
                  to={`/simera-dashboard/positions/${positionId}/candidates/${candidate.Id}`}
                  onClick={() => {
                    setOriginPath(
                      `${window.location.pathname}?${searchParams}`
                    );
                  }}
                  aria-hidden
                >
                  {t('CandidatePositionSummaryCard.ViewProfile')}
                </Link>
              </div>
              <div className={classes.CandidateHeaderOtherData}>
                <div className={classes.Row}>
                  <div className={classes.Chip}>
                    <p className={classes.ChipText}>{`ID: ${candidate.Id}`}</p>
                  </div>
                  {showStatus && status && (
                    <div
                      className={classes.Chip}
                      style={{
                        backgroundColor: 'var(--primary-green)',
                      }}
                    >
                      <p className={classes.ChipText}>{status}</p>
                    </div>
                  )}
                  {!showStatus && isNewCandidate(candidate.CreateTime) && (
                    <div
                      className={classes.Chip}
                      style={{
                        backgroundColor: 'var(--primary-green)',
                      }}
                    >
                      <p className={classes.ChipText}>
                        {t('CandidatePositionSummaryCard.NewCandidate')}
                      </p>
                    </div>
                  )}
                  {isNewCandidate(
                    candidate.CandidatesPositionStatus?.[0]?.CreateTime
                  ) && (
                    <div
                      className={`${classes.ChipContainer} ${classes.ChipInfo} `}
                    >
                      <p className={classes.ChipText}>
                        {t('CandidatePositionSummaryCard.RecentlyAdded')}
                      </p>
                    </div>
                  )}
                  {candidate.CandidatesPositionStatus?.[0]?.CreateTime && (
                    <HoverTooltip
                      text={t('CandidatePositionSummaryCard.TooltipText')}
                    >
                      <div
                        className={classes.Date}
                        aria-hidden
                        data-testid="create-date"
                      >
                        {dayjs(
                          candidate.CandidatesPositionStatus?.[0]?.CreateTime
                        ).format('MM/DD/YYYY')}
                      </div>
                    </HoverTooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={classes.ScoreValue}
            style={{
              backgroundColor: getRangeOfColor(candidate.Score),
            }}
          >
            <p>{candidate.Score}</p>
          </div>
        </header>
        <hr className={classes.Hr} />
        <div className={classes.ContentContainer}>
          <div className={classes.leftContent}>
            <div className={classes.LeftContentTop}>
              <div className={classes.Row}>
                <p className={classes.ExtraBold}>Skills:</p>
                {candidate.CandidateSkills?.map((skill) => (
                  <div
                    key={skill.Id}
                    className={`${classes.Chip} ${
                      mappedSkills?.includes(skill.Skill?.Title)
                        ? classes.ChipsSkillsActive
                        : classes.ChipsSkills
                    } ${
                      currentSearchForSkins?.includes(
                        skill.Skill?.Title.trim().toLowerCase()
                      ) &&
                      !mappedSkills?.includes(skill.Skill?.Title) &&
                      classes.ChipsSkillsMatch
                    }`}
                  >
                    <p className={classes.ChipText}>{skill?.Skill?.Title}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.LeftContentBottom}>
              <div className={`${classes.CandidateCVData} ${classes.grid1}`}>
                <p>{t('CandidatePositionSummaryCard.YearsOfExperience')}</p>
                <p>
                  {candidate.CandidateWorkExperience &&
                    `${candidate.YearsOfExperience ?? 0} years +`}
                </p>
              </div>
              <div className={`${classes.CandidateCVData} ${classes.grid2}`}>
                <p>{t('CandidatePositionSummaryCard.LastJob')}</p>
                <p>{lastJob.title}</p>
                {lastJob.company && <p>{lastJob.company}</p>}
              </div>
              <div className={`${classes.CandidateCVData} ${classes.grid3}`}>
                <p>{t('CandidatePositionSummaryCard.SalaryRange')}</p>
                <p>
                  {candidate.CandidateJobInterest
                    ? `$${candidate.CandidateJobInterest.SalaryLowerBound} - $${candidate.CandidateJobInterest.SalaryUpperBound}`
                    : '$'}
                </p>
              </div>
              <div className={`${classes.CandidateCVData} ${classes.grid4}`}>
                <p>{t('CandidatePositionSummaryCard.EducationLevel')}</p>
                {candidate.CandidateEducation
                  ? computeHighestDegree(candidate.CandidateEducation)
                  : ''}
              </div>
              {candidate.WorkSummary && (
                <div className={`${classes.CandidateCVData} ${classes.grid5}`}>
                  <p>{t('CandidatePositionSummaryCard.CandidateSummary')}</p>
                  <p ref={feedbackRef} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.RightContent}>
        <CardSummaryVideoButton
          videos={candidate.AdditionalVideos ?? []}
          openModal={() =>
            setDataModalVideo({
              candidateId: candidate.Id,
              positionId,
              isModalOpen: true,
            })
          }
        />
        <div className={classes.ActionBtnsContainer}>
          {isValidStatus &&
            !isPositionClosed &&
            candidate?.CandidatesPositionStatus && (
              <CandidateActionBtnLeft
                activeValue={cps as ActiveValues}
                handleHireModal={handleShowHireModal}
                handleRequestInterview={handleRequestInterview}
                handleChangePositionStatus={handleChangePositionStatus}
                candidateId={candidate.Id}
              />
            )}
          {cps !== SwitchTypes.HIRED ? (
            <CandidateActionBtnRightGraph
              candidate={candidate}
              positionId={positionId}
              salaryUpperBound={data?.SalaryUpperBound ?? 0}
              candidateArrayIndex={i}
              candidatePositionStatus={cps}
              setSwitchingId={setSwitchingId}
            />
          ) : null}
        </div>
      </div>
    </li>
  );
};

export default CandidateSummaryCardGraph;
