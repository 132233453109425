import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { extracurricularDefaultValue } from '../../../../constants/formDefaultValues';
import { FormTypes } from '../../../../types/user_info';
import { errorHandler } from '../../../utils';
import { GetCandidateAdditionalDocumentInput } from '../../additional-documents';
import {
  CandidateExtracurricularBuilder,
  GetCandidateExtracurricularOutput,
} from '../candidate-extracurricular.types';

export const getCandidateExtracurricular = (
  builder: CandidateExtracurricularBuilder
) =>
  builder.query<
    GetCandidateExtracurricularOutput,
    GetCandidateAdditionalDocumentInput
  >({
    query: ({ candidateId }) => ({
      url: `/candidate-extracurricular/${candidateId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map((extra) => ({
              type: 'candidateExtracurriculars' as const,
              id: extra?.Id,
            })),
            { type: 'candidateExtracurriculars', id: 'LIST' },
          ]
        : [{ type: 'candidateExtracurriculars', id: 'LIST' }],
    transformResponse: (
      response: FormTypes['extracurricularBackendPayload'][]
    ) => {
      if (response.length === 0) {
        return extracurricularDefaultValue;
      }
      return response.map((extra) => ({
        Id: extra?.Id,
        ExtracurricularType: {
          val: extra?.ExtracurricularType.toString(),
        },
        ExtracurricularDetail: { val: extra?.ExtracurricularDetail },
      }));
    },

    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setExtracurricularsAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
