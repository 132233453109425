import { FC } from 'react';
import {
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';

import classes from './SocialMediaShareButtons.module.css';

interface SocialMediaShareButtonsProps {
  link: string;
}

const SocialMediaShareButtons: FC<SocialMediaShareButtonsProps> = ({
  link,
}) => {
  return (
    <div className={classes.ButtonsContainer}>
      <div className={classes.ButtonWrapper}>
        <LinkedinShareButton
          url={link}
          title="Simera Human Capital"
          source="https://app.simera.me"
          className={classes.NetworkButton}
        >
          <LinkedinIcon size={48} round />
        </LinkedinShareButton>
      </div>
      <div className={classes.ButtonWrapper}>
        <FacebookShareButton
          url={link}
          hashtag="#SimeraHumanCapital"
          className={classes.NetworkButton}
        >
          <FacebookIcon size={48} round />
        </FacebookShareButton>
      </div>
      <div className={classes.ButtonWrapper}>
        <TwitterShareButton
          url={link}
          title="Simera Human Capital"
          via="https://app.simera.me"
          hashtags={['#SimeraHumanCapital']}
          className={classes.NetworkButton}
        >
          <TwitterIcon size={48} round />
        </TwitterShareButton>
      </div>
      <div className={classes.ButtonWrapper}>
        <EmailShareButton
          url={link}
          subject="Check out Simera Human Capital"
          body={`Hey, I highly recommend giving SIMERA a try. It’s a platform that connects talent with top companies in the US and the world. You just need to complete your profile in less than 5 minutes and you will be matched with the ideal positions for you.

Follow this link and start building you resume:

`}
          className={classes.NetworkButton}
        >
          <EmailIcon size={48} round />
        </EmailShareButton>
      </div>
      <div className={classes.ButtonWrapper}>
        <WhatsappShareButton
          url={link}
          title="Simera Human Capital"
          separator=":: "
          className={classes.NetworkButton}
        >
          <WhatsappIcon size={48} round />
        </WhatsappShareButton>
      </div>
      <div className={classes.ButtonWrapper}>
        <TelegramShareButton
          url={link}
          title="Simera Human Capital"
          className={classes.NetworkButton}
        >
          <TelegramIcon size={48} round />
        </TelegramShareButton>
      </div>
    </div>
  );
};

export default SocialMediaShareButtons;
