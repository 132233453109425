import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CompanyBuilder,
  LoadOnePositionInput,
  LoadOnePositionOutput,
} from '../companies.types';

export const loadOnePosition = (builder: CompanyBuilder) =>
  builder.query<LoadOnePositionOutput, LoadOnePositionInput>({
    query: (options) => ({
      url: `/positions/one/${options.id}`,
      method: 'GET',
    }),
    providesTags: () => ['PositionsList'],
    transformErrorResponse: (error, meta, arg) => {
      errorHandler(
        '[setPositions]',
        arg.lang === 'en'
          ? ErrorMessageEn.PositionSetError
          : ErrorMessageEs.PositionSetError,
        error,
        true
      );
    },
  });
