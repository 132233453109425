import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DropZoneFile from '../DropZoneFile/DropZoneFile';
import classes from './AdditionalDocumentsFormCandidate.module.css';
import SelectInput from '../../SelectInput/SelectInput';
import { useSaveAdditionalDocumentMutation } from '../../../store/modules/additional-documents';
import { FileCategory } from '../../../types/candidates';
import { AdditionalDocumentFormData } from '../../../constants/FormTypes';

export interface FormData {
  Description: string;
  file: File;
  IsEmployersVisible: boolean;
  IsCandidatesVisible: boolean;
  FileCategory: string;
}

interface Props {
  candidateId: number;

  fileCategories: FileCategory[];
  secondary?: boolean;
}

const AdditionalDocumentsFormCandidate: FC<Props> = ({
  candidateId,
  fileCategories,
  secondary,
}) => {
  const [t, i18n] = useTranslation('global');
  const [validFile, setAcceptedFile] = useState<File | undefined>(undefined);

  const [saveAdditionalDocumentApi] = useSaveAdditionalDocumentMutation();
  const mappedCategories = (fileCategories ?? []).map((category) => ({
    id: category.Id,
    text: category.CategoryName,
  }));
  const {
    register,
    formState: { errors },
    control,
    resetField,
    reset,
    handleSubmit,
    watch,
  } = useForm<AdditionalDocumentFormData>();
  const fileCategoryWatch = watch('FileCategory');

  const fileCategory = register('FileCategory', {
    required: `${t('AdditionalDocs.CategoryRequired')}`,
  });
  const saveAdditionalDocument = (data: AdditionalDocumentFormData) => {
    const formData = new FormData();

    if (!validFile) {
      return;
    }

    formData.append('CandidateId', `${candidateId}`);
    formData.append('Description', data.Description);
    formData.append('FileCategory', data.FileCategory);
    if (data.IsCandidatesVisible) {
      formData.append('IsCandidatesVisible', `${data.IsCandidatesVisible}`);
    }
    if (data.IsEmployersVisible) {
      formData.append('IsEmployersVisible', `${data.IsEmployersVisible}`);
    }
    formData.append('file', validFile);

    saveAdditionalDocumentApi({
      lang: i18n.language as 'en' | 'es',
      data: formData,
    });

    reset();
    setAcceptedFile(undefined);
  };

  return (
    <section
      data-testid="additional-docs-form"
      style={{ paddingBottom: '2rem' }}
    >
      <form onSubmit={handleSubmit(saveAdditionalDocument)}>
        <SelectInput
          onChange={fileCategory.onChange}
          onBlur={fileCategory.onBlur}
          refProp={fileCategory.ref}
          name={fileCategory.name}
          options={mappedCategories}
          label={t('AdditionalDocs.Category')}
          placeholder={t('AdditionalDocs.CategoryPlaceholder')}
          errorText={errors.FileCategory?.message?.toString()}
        />

        <DropZoneFile
          reset={resetField}
          control={control}
          error={errors?.file?.message?.toString()}
          setAcceptedFile={setAcceptedFile}
          secondary={secondary}
          disabled={!fileCategoryWatch}
          candidateId={candidateId}
        />
        <div className={classes.footer} />
      </form>
    </section>
  );
};

AdditionalDocumentsFormCandidate.defaultProps = {
  secondary: false,
};

export default AdditionalDocumentsFormCandidate;
