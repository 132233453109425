import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { AdditionalDocument } from '../../../../types/candidates';
import { successHandler, errorHandler } from '../../../utils';
import {
  AdditionalDocumentBuilder,
  SaveAdditionalDocumentInput,
  SaveAdditionalDocumentOutput,
} from '../additional-document.types';

export const saveAdditionalDocument = (builder: AdditionalDocumentBuilder) =>
  builder.mutation<SaveAdditionalDocumentOutput, SaveAdditionalDocumentInput>({
    query: ({ data }) => {
      return {
        url: '/upload/file/candidate',
        method: 'POST',
        body: data,
      };
    },
    invalidatesTags: [{ type: 'additionalDocuments', id: 'LIST' }],
    transformResponse(resp: AdditionalDocument, meta, { lang }) {
      if (lang === 'en') {
        successHandler(SuccessMessageEn.AdditionalDocumentSuccess, true);
      } else {
        successHandler(SuccessMessageEs.AdditionalDocumentSuccess, true);
      }

      return resp;
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[AdditionalDocumentSave]',
        lang === 'es'
          ? ErrorMessageEs.AdditionalDocumentError
          : ErrorMessageEn.AdditionalDocumentError,
        error,
        true
      );
    },
  });
