import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Home from '../../Home/Home';

import logo from '../../../images/logoBlue.svg';
import classes from './LoginScreen.module.css';
import LoginForm from '../../LoginForm/LoginForm';
import EmployerLoginForm from '../../LoginForm/EmployerLoginForm/EmployerLoginForm';

interface LoginScreenProps {
  isEmployee?: boolean;
}

const LoginScreen: FC<LoginScreenProps> = ({ isEmployee }) => {
  const [t] = useTranslation('global');

  const containerClass = isEmployee
    ? classes.employerContainer
    : classes.container;
  return (
    <Home>
      <div className={containerClass}>
        <div data-testid="login-header" className={classes.loginHeader}>
          <p className={classes.loginHeader}>
            {t('employeeLoginScreen.login')}
          </p>
          <div className={classes.loginHeaderDivider} />
        </div>
        <div className={classes.loginCard}>
          <div className={classes.loginLogo}>
            <img src={logo} alt="logo" />
          </div>
          {!isEmployee ? <LoginForm /> : <EmployerLoginForm />}
        </div>
      </div>
    </Home>
  );
};

LoginScreen.defaultProps = {
  isEmployee: false,
};
export default LoginScreen;
