import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import classes from './UpdateEmployerModal.module.css';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
}
const UpdateEmployerModal: FC<Props> = ({ isOpen, setIsOpen, children }) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const [t] = useTranslation('global');
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      ariaHideApp={false}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <header>
        <h3 className={classes.title}>{t('Employers.UpdateEmployers')}</h3>
      </header>
      {children}
    </Modal>
  );
};

export default UpdateEmployerModal;
