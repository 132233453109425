import { useTranslation } from 'react-i18next';
import { InterviewSlotStatusTypes } from '$store/modules/interview';
import classes from './RightCalendarModal.module.css';
import { Schedule } from './Schedule';
import { SetSchedulesType } from '../CalendarModalTypes';

interface RightCalendarModalProps {
  schedules: SetSchedulesType[];
  setSchedules: React.Dispatch<React.SetStateAction<SetSchedulesType[]>>;
  submitSchedules: () => void;
}

export const RightCalendarModal = ({
  schedules,
  setSchedules,
  submitSchedules,
}: RightCalendarModalProps) => {
  const [t] = useTranslation('global');

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{t('Calendar.selectSchedule')}</h1>
      <div className={classes.schedulesContainer}>
        {schedules.map((el) => {
          if (
            el.status === InterviewSlotStatusTypes.CompleteCandidateLate ||
            el.status === InterviewSlotStatusTypes.CompleteClientLate ||
            el.status === InterviewSlotStatusTypes.CompleteOnTime
          )
            return null;
          return (
            <Schedule
              key={el.date.format()}
              schedule={el}
              setSchedules={setSchedules}
            />
          );
        })}
      </div>
      {schedules.length > 0 && (
        <button
          onClick={submitSchedules}
          className={classes.doneButton}
          type="button"
        >
          Done
        </button>
      )}
    </div>
  );
};
