import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import {
  useFieldArray,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import dayjs from 'dayjs';
import classes from './WorkHistoryFormContainer.module.css';
import { countryNames } from '../../constants/countries';
import AddButton from '../Buttons/AddButton';
import { SaveState } from '../../types/ForrmType';
import { FieldTypes, FormTypes } from '../../types/user_info';
import SaveButton from '../Buttons/SaveButton';
import WorkHistoryForm from './WorkHistoryForm';
import FormFooter from '../FormFooter/FormFooter';
import CancelModal from '../CancelModal/CancelModal';
import AddMoreFooter from '../Buttons/AddMoreFooter';
import { useGetJobPositionsQuery } from '../../store/modules/form-info';
import {
  useDeleteWorkExperienceMutation,
  useGetWorkHistoryByCandidateIdQuery,
  useSaveWorkExperiencesInternalMutation,
  useSaveWorkExperiencesMutation,
} from '../../store/modules/work-experience';
import { errorHandler, successHandler } from '../../store/utils';
import { AutocompleteOption } from '../../types/candidates';
import { useAppDispatch } from '../../hooks';
import { candidatesApi } from '../../store/modules/candidates';

interface WorkHistoryProps {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean, cotinueEditing: boolean) => void;
  show?: boolean;
  secondary?: boolean;
}

const WorkHistoryFormContainer: FC<WorkHistoryProps> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  secondary,
}) => {
  const [t, i18next] = useTranslation('global');
  const { candidateId } = useParams();
  const dispatch = useAppDispatch();

  const candidateIdFromLocalStorage = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;

  const id = candidateId || candidateIdFromLocalStorage;

  const { data: workExperienceData } = useGetWorkHistoryByCandidateIdQuery({
    lang: i18next.language as 'es' | 'en',
    candidateId: +id,
  });

  const [
    saveWorkHistoryInternal,
    { isLoading: isLoadingInternal, isSuccess: isSuccessInternal },
  ] = useSaveWorkExperiencesInternalMutation();
  const [saveWorkHistory, { isLoading, isSuccess }] =
    useSaveWorkExperiencesMutation();
  const [deleteWorkExperience, { isSuccess: deleteSuccess }] =
    useDeleteWorkExperienceMutation();

  const {
    control,
    register,
    clearErrors,
    setValue,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    getValues,
  } = useForm({
    defaultValues: { workExperience: workExperienceData },
    shouldFocusError: false,
  });

  useEffect(() => {
    setValue('workExperience', workExperienceData);
  }, [setValue, workExperienceData]);

  const { data: jobPositions } = useGetJobPositionsQuery({ t });

  const [cookies, setCookie] = useCookies(['experience']);

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'workExperience' as never,
  });

  const appendField = () => {
    if ((fields.length || 1) < 5) {
      append({
        JobPosition: { Id: -1, title: '' },
        JobTitle: { val: '', mult: 1 },
        Company: { val: '', mult: 1 },
        Admission: { val: '', mult: 1 },
        Egress: { val: '', mult: 1 },
        Country: { val: '', mult: 1 },
      });
    } else {
      errorHandler(
        'WorkHistoryFormContainer',
        'Work history limit reached',
        null,
        true
      );
    }
  };

  const removeCard = async (index: number, deleteId: number) => {
    await deleteWorkExperience({
      id: deleteId,
      lang: i18next.language as 'es' | 'en',
    });
    dispatch(candidatesApi.util.invalidateTags(['Score']));

    if (deleteSuccess) {
      remove(index);
    }
  };

  const removeUnsavedCard = (index: number) => {
    remove(index);
  };

  const saveInfo = async (data: { workExperience?: FormTypes['work'][] }) => {
    if (dirtyFields?.workExperience) {
      const modifiedCardIndexes = Object.keys(dirtyFields?.workExperience);
      if (data) {
        const modifiedCards: FormTypes['work'][] = data.workExperience!.filter(
          (_, ind) => modifiedCardIndexes.includes(ind.toString())
        );

        try {
          if (Number(candidateId)) {
            await saveWorkHistoryInternal({
              candidateId: Number(candidateId),
              data: modifiedCards,
              lang: i18next.language as 'es' | 'en',
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
          } else {
            await saveWorkHistory({
              data: modifiedCards,
              lang: i18next.language as 'es' | 'en',
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
          }

          if (setEdit) {
            setEdit(true);
          }
          successHandler(t('common.saved'), true);
          window.analytics.track('Extracurricular form submitted');
        } catch (error) {
          // error
        }
      }
    }
  };

  const renderFields = () => {
    const headerText: { [key: number]: string } = {
      0: t('skills.first'),
      1: t('skills.second'),
      2: t('skills.third'),
      3: t('skills.fourth'),
      4: t('skills.fifth'),
      5: t('skills.sixth'),
      6: t('skills.seventh'),
      7: t('skills.eighth'),
      8: t('skills.nineth'),
      9: t('skills.tenth'),
      10: t('skills.eleventh'),
      11: t('skills.twelveth'),
      12: t('skills.thirteenth'),
      13: t('skills.fourteenth'),
      14: t('skills.fifteenth'),
      15: t('skills.sixteenth'),
      16: t('skills.seventeenth'),
      17: t('skills.eighteenth'),
      18: t('skills.nineteenth'),
      19: t('skills.twentieth'),
      20: t('skills.twentyfirst'),
      21: t('skills.twentysecond'),
      22: t('skills.twentythird'),
      23: t('skills.twentyfourth'),
      24: t('skills.twentyfifth'),
    };

    return fields.map((row: any, index: number) => {
      const { Id } = row;

      const jobPositionName = register(
        `workExperience.${index}.JobPosition.title`,
        {
          required: true,
          validate: {
            jobPositionListed: (value) =>
              value
                ? jobPositions?.some((position) => position.title === value)
                : false,
          },
        }
      );

      const jobTitle = register(`workExperience.${index}.JobTitle.val`, {
        required: true,
      });

      const company = register(`workExperience.${index}.Company.val`, {
        required: true,
      });

      const admission = register(`workExperience.${index}.Admission.val`, {
        required: {
          value: true,
          message: 'field required',
        },
        validate: {
          isValidDate: (value) =>
            value && getValues(`workExperience.${index}.Egress.val`)
              ? dayjs(getValues(`workExperience.${index}.Egress.val`)).isAfter(
                  value
                )
              : true,
          isValidFormat: (value) =>
            value ? dayjs(value, 'YYYY-MM', true).isValid() : true,
        },
      });

      const egress = register(`workExperience.${index}.Egress.val`, {
        validate: {
          isValidDate: (value) =>
            value
              ? dayjs(
                  getValues(`workExperience.${index}.Admission.val`)
                ).isBefore(value)
              : true,
          isValidFormat: (value) =>
            value ? dayjs(value, 'YYYY-MM', true).isValid() : true,
        },
      });

      const country = register(`workExperience.${index}.Country.val`, {
        validate: {
          isInList: (value) =>
            (value && countryNames.includes(value)) ||
            value === '' ||
            'Select a country from the list',
        },
      });

      const description = register(`workExperience.${index}.Description.val`);

      return (
        <WorkHistoryForm
          key={row.id}
          row={row}
          index={index}
          jobPositionName={jobPositionName}
          jobTitle={jobTitle}
          company={company}
          admission={admission}
          egress={egress}
          country={country}
          description={description}
          jobPositions={jobPositions}
          clearErrors={clearErrors}
          errors={errors}
          getValues={
            getValues as UseFormGetValues<
              | { workExperience: FormTypes['work'][] }
              | {
                  JobPosition: AutocompleteOption;
                  JobTitle: FieldTypes;
                  Company: FieldTypes;
                  Admission: FieldTypes;
                  Egress: FieldTypes;
                  Country: FieldTypes;
                  Description: FieldTypes;
                  Id?: number;
                }
            >
          }
          headerText={headerText}
          removeCard={removeCard}
          setValue={
            setValue as UseFormSetValue<
              | { workExperience: FormTypes['work'][] }
              | {
                  JobTitle: FieldTypes;
                  JobPosition: AutocompleteOption;
                  Company: FieldTypes;
                  Admission: FieldTypes;
                  Egress: FieldTypes;
                  Country: FieldTypes;
                  Description: FieldTypes;
                  Id?: number;
                }
            >
          }
          t={t}
          Id={Id}
          delId={Id}
          removeUnsavedCard={removeUnsavedCard}
          secondary={secondary}
        />
      );
    });
  };
  const buttonState = () => {
    if (isDirty) {
      return SaveState.IDLE;
    }
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }

    if (isSuccess || isSuccessInternal) {
      return SaveState.FULFILLED;
    }

    return SaveState.IDLE;
  };

  const onInvalidSubmitHandler = (err: any) => {
    try {
      const registerKey = Object.keys(err);
      const registerObj = err[registerKey[0]].find(
        (item: any) => item !== undefined
      );
      const registerObjKeys = Object.keys(registerObj);
      const input = registerObj[registerObjKeys[0]].val.ref;
      input.scrollIntoView({ block: 'center' });
    } catch (errored) {
      // nothing to do
    }
  };

  return (
    <div className={classes.MainContainer}>
      <form
        data-testid="form-container"
        className={classes.Container}
        onSubmit={handleSubmit(saveInfo, onInvalidSubmitHandler)}
      >
        {!isEdit && (
          <div className={classes.buttonContainer} tabIndex={0} role="button">
            {!getValues('workExperience.0.JobPosition.title') &&
            cookies.experience === 'false' ? (
              <button
                onClick={() => {
                  setCookie('experience', true, { path: '/' });
                  appendField();
                }}
                className={classes.buttonAdd}
                type="button"
                data-testid="addExperience-button"
              >
                {t('workHistory.add')}
              </button>
            ) : (
              <AddButton onClick={appendField} />
            )}
          </div>
        )}
        <div className={classes.CardContainer}>{renderFields()}</div>

        {fields.length > 1 && !isEdit && (
          <AddMoreFooter onClick={appendField} label={t('workHistory.add')} />
        )}
        <div className={classes.ButtonContainer}>
          {isEdit ? (
            <FormFooter
              onClickCancel={onClickCancel}
              disabled={buttonState() === SaveState.PENDING || !isDirty}
              saveState={buttonState()}
              translate={t}
              submit
            />
          ) : (
            <SaveButton
              disabled={buttonState() === SaveState.PENDING || !isDirty}
              state={buttonState()}
              t={t}
              submit
            />
          )}
        </div>
      </form>
      {show && onClickCancelModal && (
        <CancelModal onClickCancelModal={onClickCancelModal} />
      )}
    </div>
  );
};
WorkHistoryFormContainer.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  secondary: false,
};

export default WorkHistoryFormContainer;
