import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FC, useState } from 'react';
import { TFunction } from 'react-i18next';
import LanguagesForm from './LanguagesForm';
import { GetCandidateLanguageOutput } from '../../store/modules/candidate-language';

interface LanguagesProps {
  register: UseFormRegister<{
    languages: GetCandidateLanguageOutput | undefined;
  }>;
  fields: Record<'id', string>[];
  removeLanguage: (index: number, deleteId: number) => void;
  setValue: UseFormSetValue<any>;
  t: TFunction<'global', undefined>;
  removeUnsaved: (index: number) => void;
  errors: any;
  secondary?: boolean;
}
const Languages: FC<LanguagesProps> = ({
  register,
  fields,
  setValue,
  removeLanguage,
  t,
  removeUnsaved,
  errors,
  secondary,
}) => {
  const headerText: { [key: number]: string } = {
    0: t('forms.firstEr'),
    1: t('forms.second'),
    2: t('forms.thirdEr'),
    3: t('forms.fourth'),
    4: t('forms.fifth'),
    5: t('forms.sixth'),
    6: t('forms.seventh'),
    7: t('forms.eighth'),
    8: t('forms.ninth'),
    9: t('forms.tenth'),
  };
  const [Modal, setModal] = useState<boolean>(false);
  const [delId, setDelId] = useState();

  return (
    <>
      {fields.map((row: any, index) => {
        const { Id } = row;
        const language = register(`languages.${index}.LanguageName.val`, {
          required: true,
        });
        const languageProf = register(
          `languages.${index}.LanguageProficiency.val`,
          { required: true }
        );
        return (
          <LanguagesForm
            errors={errors?.[index]}
            key={row.id}
            headerText={headerText}
            index={index}
            language={language}
            languageProf={languageProf}
            removeLanguage={removeLanguage}
            setValue={setValue}
            row={row}
            t={t}
            Id={Id}
            delId={delId}
            setDelId={setDelId}
            modal={Modal}
            setModal={setModal}
            removeUnsaved={removeUnsaved}
            secondary={secondary}
          />
        );
      })}
    </>
  );
};

Languages.defaultProps = {
  secondary: false,
};

export default Languages;
