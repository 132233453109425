import { useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DAYJS_DATE_AND_TIME, TIMEZONES } from '$constants/datetimes';
import {
  GetInterviewsSlotsAsInternalOutput,
  useInternalCreateAndSetInterviewMutation,
} from '$store/modules/interview';
import { ReactComponent as Close } from '$icons/CloseGreen.svg';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './InternalJobInterviewLayout.css';
import useOnClickOutside from '$hooks/useOnClickOutside';
import TimeZoneDropwdown from '$components/Employers/CalendarModal/LeftCalendarModal/TimeZoneDropdown/TimeZoneDropdown';
import { getZone } from '$utils/getZone';
import { ReactComponent as Calendar } from '$icons/CalendarIcon.svg';
import { InternalTimePicker } from './InternalTimePicker/InternalTimePicker';

type InternalScheduleInterviewCalendarProps = {
  schedules:
    | GetInterviewsSlotsAsInternalOutput[number]['UserSchedules']
    | undefined;
  onClose: () => void;
  interviewId: number | undefined;
  takenSlots: GetInterviewsSlotsAsInternalOutput[number]['ReservedUserSchedules'];
};

type SetSelectedScheduleType = {
  date: Dayjs;
  timezone: string;
};

const InternalScheduleInterviewCalendar = ({
  schedules,
  onClose,
  interviewId,
  takenSlots,
}: InternalScheduleInterviewCalendarProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat);

  const [selectedSchedule, setSelectedSchedule] =
    useState<SetSelectedScheduleType>();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [userTimezone, setUserTimezone] = useState(TIMEZONES.EASTERN);
  const calendarRef = useRef(null);
  const [internalCreateAndSetInterview] =
    useInternalCreateAndSetInterviewMutation();
  const zone = getZone(userTimezone);

  const handleDoneBtn = () => {
    if (selectedSchedule && interviewId) {
      internalCreateAndSetInterview({
        startDate: selectedSchedule.date.format('YYYY-MM-DD HH:mm:ss.SSS'),
        endDate: selectedSchedule.date
          .add(1, 'hour')
          .format('YYYY-MM-DD HH:mm:ss.SSS'),
        timezone: selectedSchedule.timezone,
        interviewId,
      }).then(() => {
        onClose();
      });
    }
  };

  useOnClickOutside(calendarRef, () => setIsCalendarOpen(false));

  return (
    <div className="bg-white rounded p-8 flex flex-row gap-8 max-h-screen relative">
      <button
        type="button"
        aria-label="Close"
        className="absolute top-2 right-2"
        onClick={() => onClose()}
      >
        <Close fill="#079e88" />
      </button>
      <div className="flex flex-col">
        <div className="font-semibold text-2xl mb-4">Select a Date & Time</div>
        <div className="relative">
          <div className="flex gap-4 items-center">
            <span className="font-semibold">Select a day:</span>
            <div className="relative flex flex-row items-center">
              <input
                type="text"
                readOnly
                className="border border-solid border-internal-main rounded pl-2 py-1 w-36 outline-none text-internal-main pr-8 hover:cursor-default"
                value={dayjs(selectedDate).format('ddd, MMM D')}
                onClick={() => setIsCalendarOpen((prev) => !prev)}
              />
              <div className="absolute right-2 pointer-events-none">
                <Calendar className="stroke-internal-main" />
              </div>
            </div>
          </div>
          {isCalendarOpen && (
            <div
              ref={calendarRef}
              className="absolute bg-white z-10 border border-solid border-gray-300 shadow mt-2 rounded"
            >
              <DayPicker
                mode="single"
                selected={selectedDate}
                onSelect={(newDate) => {
                  if (newDate) {
                    setSelectedDate(newDate);
                    setSelectedSchedule(undefined);
                  }
                }}
                disabled={{ dayOfWeek: [0, 6] }}
              />
            </div>
          )}
        </div>
        <hr className="m-4" />
        <div>
          <InternalTimePicker
            selectedSchedule={selectedSchedule}
            setSelectedSchedule={setSelectedSchedule}
            timezone={userTimezone}
            zone={zone}
            day={dayjs(selectedDate)}
            takenSlots={takenSlots || []}
          />
          <TimeZoneDropwdown
            timezone={userTimezone}
            setTimezone={setUserTimezone}
            zone={zone}
          />
        </div>
        <button
          type="button"
          disabled={!selectedSchedule}
          className="mt-2 w-full bg-internal-main text-white disabled:bg-gray-200 disabled:text-gray-400 rounded text-center font-semibold py-2 border shadow"
          onClick={handleDoneBtn}
        >
          Done
        </button>
      </div>
      <div>
        <div>
          <div className="text-2xl font-semibold mb-2">
            Times Selected by Client
          </div>
          <div className="flex flex-col max-h-[60%] overflow-y-auto">
            {schedules &&
              schedules.map((singleSchedule) => {
                return (
                  <div key={`employer_single_date_${singleSchedule.Id}`}>
                    {dayjs
                      .tz(singleSchedule.StartDate, singleSchedule.Timezone)
                      .tz(userTimezone)
                      .format(DAYJS_DATE_AND_TIME)}
                  </div>
                );
              })}
          </div>
        </div>
        <hr className="my-4" />
        <div>
          <div className="text-2xl font-semibold mb-2">Confirmed Times</div>
          <div className="overflow-y-auto max-h-32">
            {takenSlots &&
              takenSlots.map((singleReserved) => {
                return (
                  <div key={`reserved_${singleReserved.Id}`}>
                    {dayjs
                      .tz(singleReserved.StartDate, singleReserved.Timezone)
                      .tz(userTimezone)
                      .format(DAYJS_DATE_AND_TIME)}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalScheduleInterviewCalendar;
