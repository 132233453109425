const enum SwitchTypes {
  FIND = 'find',
  SHORTLISTED = 'shortlisted',
  ACTIVE = 'new',
  INACTIVE = 'declined',
  HIRED = 'hired',
  OFFER_SENT = 'offerSent',
  REQUEST_INTERVIEW = 'requestInterview',
}

export default SwitchTypes;
