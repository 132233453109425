import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoBlue } from '../../images/logoBlue.svg';
import AccountButton from '../AccountButton/AccountButton';
import classes from './SimeraDashboardLayout.module.css';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import AddNewPositionButton from '../EmployerDashboard/AddNewPositionButton';
import AddNewJobModal from '../AddNewJobModal/AddNewJobModal';
import InternalDashboardPages from '../../constants/InternalDashboardPages';
import SimeraNavTab from './SimeraNavTab/SimeraNavTab';
import {
  SIMERA_CANDIDATES_ROUTE,
  SIMERA_COMPANIES_ROUTE,
  SIMERA_EMPLOYERS_ROUTE,
  SIMERA_POSITIONS_ROUTE,
  SIMERA_JOB_INTERVIEWS_ROUTE,
} from '../../constants/routes';

interface LayoutProps {
  children?: React.ReactNode;
  activeTab: InternalDashboardPages;
}

const SimeraDashboardLayout: FC<LayoutProps> = ({ children, activeTab }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { t } = useTranslation('global');
  const [isModalPositionOpen, setIsModalPositionOpen] = useState(false);

  const toggleIsModalPositionOpen = useCallback(
    () => setIsModalPositionOpen((prev) => !prev),
    []
  );

  return (
    <div data-testid="home-container" className={classes.Container}>
      {openMenu && (
        <DropdownMenu
          className={classes.DropdownMenu}
          isInternal
          setModal={setOpenMenu}
        />
      )}
      <div className={classes.Nav}>
        <div className={classes.Logo}>
          <LogoBlue />
        </div>
        <p className={classes.AdminText}>{t('SimeraInternal.Admin')}</p>

        <SimeraNavTab
          isActive={activeTab === InternalDashboardPages.POSITIONS}
          route={SIMERA_POSITIONS_ROUTE}
          text={t('SimeraInternal.Positions')}
        />
        <SimeraNavTab
          isActive={activeTab === InternalDashboardPages.CANDIDATES}
          route={SIMERA_CANDIDATES_ROUTE}
          text={t('SimeraInternal.CandidatesTitle')}
        />
        <SimeraNavTab
          isActive={activeTab === InternalDashboardPages.COMPANIES}
          route={SIMERA_COMPANIES_ROUTE}
          text={t('SimeraInternal.EmployersTitle')}
        />
        <SimeraNavTab
          isActive={activeTab === InternalDashboardPages.EMPLOYERS}
          route={SIMERA_EMPLOYERS_ROUTE}
          text={t('SimeraInternal.UsersTitle')}
        />
        <SimeraNavTab
          isActive={activeTab === InternalDashboardPages.JOBINTERVIEW}
          route={SIMERA_JOB_INTERVIEWS_ROUTE}
          text={t('SimeraInternal.InterviewsTitle')}
        />

        <div className={classes.Account}>
          {activeTab === InternalDashboardPages.POSITIONS && (
            <AddNewPositionButton onClick={toggleIsModalPositionOpen} />
          )}
          <AccountButton
            isProfile
            t={t}
            action={() => setOpenMenu(!openMenu)}
          />
        </div>
      </div>
      {children && <div className={classes.Content}>{children}</div>}
      <AddNewJobModal
        onClose={toggleIsModalPositionOpen}
        modalIsOpen={isModalPositionOpen}
      />
    </div>
  );
};

SimeraDashboardLayout.defaultProps = {
  children: null,
};

export default SimeraDashboardLayout;
