import React, { useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classes from './NotesScreen.module.css';

import CandidatesBackButton from '../../../CandidateDetails/BackButton/CandidatesBackButton';
import CandidateInformationCardEmployer from '../../../CandidateDetails/CandidateCard/CandidateInformationCardEmployer';

import GeneralNotes from './GeneralNotes/GeneralNotes';
import CandidateResume from '../../../CandidateDetails/CandidateCard/CandidateResume/CandidateResume';
import ContactInformationModal from '../../../ContactInformationModal/ContactInformationModal';
import SinglePositionNotes from './SinglePositionNotes/SinglePositionNotes';
import {
  useGetCandidateInfoQuery,
  useLoadCandidateNotesByCPSQuery,
} from '../../../../store/modules/candidates';
import Spinner from '../../../Spinner/Spinner';

const NotesScreen = () => {
  const { positionId, candidateId } = useParams();
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const { data: candidate, isLoading: isLoadingCandidate } =
    useGetCandidateInfoQuery(candidateId!);
  const { data: notes, isLoading: isLoadingNotes } =
    useLoadCandidateNotesByCPSQuery(
      { candidateId: candidateId! },
      { refetchOnMountOrArgChange: true }
    );

  const isLoading = useMemo(
    () => isLoadingCandidate || isLoadingNotes,
    [isLoadingCandidate, isLoadingNotes]
  );

  const handleBack = () => {
    navigate(`/simera-dashboard/candidates/${candidateId}`, { replace: true });
  };
  const color = 'var(--primary-green)';

  return isLoading ? (
    <div
      style={{
        display: 'flex',
      }}
      data-testid="loading-page1"
    >
      <Spinner secondary />
    </div>
  ) : (
    <div className={classes.container}>
      <CandidatesBackButton
        color={color}
        onClick={handleBack}
        text={t('Notes.BackCandidates')}
      />
      <h1 className={classes.pageTittle}>{t('Notes.CandidateNotes')}</h1>
      <div className={classes.contentContainer}>
        <div className={classes.infoContainer}>
          <CandidateInformationCardEmployer
            candidate={candidate}
            candidateWorkExperience={undefined}
            secondary
            setShowModal={setShowContactModal}
          />
          <CandidateResume resumeData={candidate?.Curriculum || {}} secondary />
        </div>
        <div className={classes.notesContainer}>
          {Number(positionId!) !== 0 ? (
            <SinglePositionNotes
              positionId={Number(positionId!)}
              notes={notes}
            />
          ) : (
            <GeneralNotes notes={notes} />
          )}
        </div>
      </div>
      {showContactModal && candidate && (
        <ContactInformationModal
          candidate={candidate}
          setShowModal={setShowContactModal}
        />
      )}
    </div>
  );
};

export default NotesScreen;
