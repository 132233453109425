import React, { FC } from 'react';
import classes from './CandidateInfo.module.css';

interface Props {
  name: string;
  degree: string;
  isInternal?: boolean;
  candidateId?: number;
}

const CandidateInfo: FC<Props> = ({
  name,
  degree,
  isInternal = false,
  candidateId,
}) => {
  return (
    <div className={classes.Title} data-testid="candidate-info">
      {name}
      {isInternal && (
        <div data-testid="candidate-info-candidate-id">
          <span className="text-zinc-400 font-medium text-sm border-solid border rounded-full px-3 py-1">
            ID: {candidateId}
          </span>
        </div>
      )}
      <p className={classes.DegreeTitle}>{degree}</p>
    </div>
  );
};

CandidateInfo.defaultProps = {
  isInternal: false,
  candidateId: 0,
};

export default CandidateInfo;
