import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, useTable } from 'react-table';

import { EmployeeColumn } from '../../../../constants/CompanyColumn';
import capitalizedName from '../../../../utils/capitalizedName';
import EmptyState from '../../../Positions/EmptyState/EmptyState';
import ActionButtons from './ActionButtons/ActionButtons';
import formatCurrency from '../../../../utils/formatCurrency';
import { EmployeesData } from '../../../../types/candidates';

interface Props {
  data: EmployeeColumn[];
  rawData?: EmployeesData[];
}

const CapitalizedNameCell: FC<{ value: string }> = ({ value }) => (
  <span>{capitalizedName(value)}</span>
);
const CurrencyCell: FC<{ value: string | number }> = ({ value }) => (
  <span>{formatCurrency(value)}</span>
);

const EmployeesTable: FC<Props> = ({ data, rawData = [] }) => {
  const [t] = useTranslation('global');
  const columns = useMemo(
    (): Column<EmployeeColumn>[] => [
      {
        Header: `${t('CompaniesTable.CandidateId')}`,
        accessor: 'candidateId',
      },
      {
        Header: `${t('CompaniesTable.CandidateName')}`,
        accessor: 'name',
        Cell: CapitalizedNameCell,
      },
      {
        Header: `${t('CompaniesTable.Position')}`,
        accessor: 'positionName',
      },
      {
        Header: `${t('CompaniesTable.StartDate')}`,
        accessor: 'startDate',
      },
      {
        Header: `${t('CompaniesTable.EndDate')}`,
        accessor: 'endDate',
      },
      {
        Header: `${t('CompaniesTable.Salary')}`,
        accessor: 'salary',
        Cell: CurrencyCell,
      },
    ],
    [t]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
    });

  if (data.length === 0) {
    return (
      <EmptyState
        text={t('CompaniesTable.NoEmployees')}
        description={t('CompaniesTable.NoEmployeesText')}
        secondary
      />
    );
  }

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                style={{
                  textAlign: 'center',
                }}
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </th>
            ))}
            <th
              style={{
                textAlign: 'center',
                zIndex: 1,
              }}
            >
              {t('CompaniesTable.Actions')}
            </th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
              <td aria-label="action buttons column">
                <ActionButtons
                  candidateId={row.original.candidateId}
                  employee={rawData?.find(
                    (emp) => emp.Id === row.original.candidatePositionStatusId
                  )}
                  cps={row.original}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

EmployeesTable.defaultProps = {
  rawData: [],
};

export default EmployeesTable;
