import { FC } from 'react';
import classes from './DescriptionText.module.css';

interface DescriptionTextProps {
  text: string;
  darkText?: boolean;
}

const defaultProps = {
  darkText: false,
};

const DescriptionText: FC<DescriptionTextProps> = ({ text, darkText }) => {
  return (
    <p
      className={`${classes.DescriptionText} ${
        darkText ? classes.Dark : classes.Light
      }`}
      data-testid="description-text"
    >
      {text}
    </p>
  );
};

DescriptionText.defaultProps = defaultProps;

export default DescriptionText;
