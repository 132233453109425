import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckSquare } from '../../../../../icons/CheckSquare.svg';
import { ReactComponent as Eye } from '../../../../../icons/Eye.svg';
import classes from './ShowButtons.module.css';

interface Props {
  showCheckbox: boolean;
  setShowCheckbox: React.Dispatch<React.SetStateAction<boolean>>;
  setShowVisibilityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowVideosModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedVideos: number[];
  selectedShowVideos: number[];
  selectedVideosType?: number[];
  selectedShowVideosType?: number[];
  setSelectedVideos: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectedShowVideos: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectedVideosType?: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectedShowVideosType?: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ShowButtons = ({
  showCheckbox,
  setShowCheckbox,
  setShowVisibilityModal,
  setShowVideosModal,
  selectedVideos,
  selectedShowVideos,
  selectedVideosType,
  selectedShowVideosType,
  setSelectedVideos,
  setSelectedShowVideos,
  setSelectedVideosType,
  setSelectedShowVideosType,
}: Props) => {
  const [t] = useTranslation('global');

  const onClickCancel = useCallback(() => {
    setShowCheckbox(false);
    setSelectedVideos([]);
    setSelectedShowVideos([]);
    if (setSelectedShowVideosType && setSelectedVideosType) {
      setSelectedVideosType([]);
      setSelectedShowVideosType([]);
    }
  }, [
    setShowCheckbox,
    setSelectedVideos,
    setSelectedShowVideos,
    setSelectedShowVideosType,
    setSelectedVideosType,
  ]);

  const checkAdditionalVideos =
    selectedVideos.length !== 0 && !selectedShowVideos.length;

  const checkVideos =
    selectedVideosType?.length !== 0 && !selectedShowVideosType?.length;

  const checkShowAdditionalVideos =
    selectedShowVideos.length !== 0 && !selectedVideos.length;

  const checkShowVideos =
    selectedShowVideosType?.length !== 0 && !selectedVideosType?.length;

  return !showCheckbox ? (
    <button
      type="button"
      className={classes.buttonCheck}
      onClick={() => setShowCheckbox(true)}
    >
      {t('AdditionalVideos.buttons.select')}
      <CheckSquare />
    </button>
  ) : (
    <div className={classes.buttonsContainer}>
      {(checkShowAdditionalVideos || checkShowVideos) && (
        <button
          type="button"
          className={classes.hideVideos}
          onClick={() => setShowVideosModal(true)}
        >
          {t('AdditionalVideos.buttons.visible')}
          <Eye />
        </button>
      )}
      {(checkAdditionalVideos || checkVideos) && (
        <button
          type="button"
          className={classes.hideVideos}
          onClick={() => setShowVisibilityModal(true)}
        >
          {t('AdditionalVideos.buttons.hideVideos')}
          <Eye />
        </button>
      )}
      <button type="button" onClick={onClickCancel}>
        {t('AdditionalVideos.buttons.cancel')}
      </button>
    </div>
  );
};

ShowButtons.defaultProps = {
  setSelectedVideosType: null,
  setSelectedShowVideosType: null,
  selectedVideosType: [],
  selectedShowVideosType: [],
};
