import { FC, useRef, useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import ProfileMenu from './Menu/ProfileMenu';
import CandidateInformationCardEmployer from './CandidateCard/CandidateInformationCardEmployer';
import classes from './CandidateDetails.module.css';
import CandidateResume from './CandidateCard/CandidateResume/CandidateResume';
import UpdateToOfferSentConfirmModal from '../Positions/CandidateSummaryCardList/CandidateSummaryCard/UpdateToOfferSentConfirmModal/UpdateToOfferSentConfirmModal';
import MultipleTagSelector from '../MultipleTagSelector/MultipleTagSelector';
import CandidatesBackButton from './BackButton/CandidatesBackButton';
import Button from '../Buttons/Button';
import ContactInformationModal from '../ContactInformationModal/ContactInformationModal';
import AdditionalDocuments from './AdditionalDocuments/AdditionalDocuments';
import WorkHistoryEditableContainerInternal from '../WorkHistoryEditableContainer/WorkHistoryEditableContainerInternal';
import EducationalInfoEditableContainerInternal from '../EducationalInfoEditableContainer/EducationalInfoEditableContainerInternal';
import SkillsEditableContainerInternal from '../SkillsEditableContainer/SkillsEditableContainerInternal';
import LanguageEditableContainerInternal from '../LanguageEditableContainer/LanguageEditableContainerInternal';
import ExtracurricularEditableContainerInternal from '../ExtracurricularEditableContainer/ExtracurricularEditableContainerInternal';
import JobInterestsEditableContainerInternal from '../JobInterestsEditableContainer/JobInterestsEditableContainerInternal';
import TechnicalInfoEditableContainerInternal from '../TechnicalInfoEditableContainer/TechnicalInfoEditableContainerInternal';
import WorkSummaryEditableContainer from '../WorkHistoryEditableContainer/WorkSummaryEditableContainer';
import {
  useGetAdditionalTagsQuery,
  useGetFileCategoriesQuery,
} from '../../store/modules/form-info';

import { useGetAdditionalDocumentsByCandidateIdQuery } from '../../store/modules/additional-documents';
import {
  useGetAdditionalTagsByCandidateIdQuery,
  useSaveAdditionalTagMutation,
  useUpdateAdditionalTagMutation,
} from '../../store/modules/additional-tags';
import { useGetWorkHistoryByCandidateIdQuery } from '../../store/modules/work-experience';
import { useAppSelector } from '../../hooks';
import { AdditionalTag, Candidate } from '../../types/candidates';
import AdditionalDocumentsFormCandidate from './AdditionalDocumentsFormCandidate/AdditionalDocumentsFormCandidate';
import { useOriginPath } from '../../hooks/use-origin-path';
import { CalendarModal } from '../Employers/CalendarModal/CalendarModal';
import CandidateFeedBackModal from '../Positions/CandidateSummaryCardList/CandidateFeedBackModal/CandidateFeedBackModal';
import CurriculumEditableContainer from '../CurriculumEditableContainer/CurriculumEditableContainer';

interface Props {
  secondary?: boolean;
  isEmployee?: boolean;
  candidate: Candidate;
}

const CandidateDetails: FC<Props> = ({ secondary, isEmployee, candidate }) => {
  const { getOriginPath } = useOriginPath();
  const [t, i18Next] = useTranslation('global');
  const { serverUser } = useAppSelector((state) => state.auth);

  const navRef = useRef<HTMLDivElement>(null);
  const [highlightedSection, setHighlightedSection] = useState(0);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [showMakeOfferModal, setMakeOfferModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { candidateId, positionId } = useParams();
  const candidatePositionStatusId = candidate?.CandidatesPositionStatus?.find(
    (positionStatus) =>
      positionStatus?.Position?.Id === parseInt(positionId ?? '0', 10)
  )?.Position?.Id as number;

  const statusId = searchParams.get('statusId');
  const firstRender = useRef(true);
  const { data: fileCategories } = useGetFileCategoriesQuery({ t });
  const { data: additionalTags } = useGetAdditionalTagsQuery({
    lang: i18Next.language as 'en' | 'es',
  });
  const { data: workHistoryData } = useGetWorkHistoryByCandidateIdQuery({
    candidateId: +candidate.Id!,
    lang: i18Next.language as 'en' | 'es',
  });
  const { data: additionalDocuments } =
    useGetAdditionalDocumentsByCandidateIdQuery({
      lang: i18Next.language as 'en' | 'es',
      candidateId: +candidateId!,
    });
  const { data: infoAdditionalTags } = useGetAdditionalTagsByCandidateIdQuery({
    candidateId: candidate.Id,
    lang: i18Next.language as 'en' | 'es',
  });

  const [saveAdditionalTag] = useSaveAdditionalTagMutation();
  const [updateAdditionalTags] = useUpdateAdditionalTagMutation();

  const {
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<{
    additionalTags: AdditionalTag[];
  }>({
    defaultValues: { additionalTags: [] },
  });

  const additionalTagsValue = useWatch({
    control,
    name: 'additionalTags',
  });

  useEffect(() => {
    if (
      (infoAdditionalTags?.length || 0) > additionalTagsValue?.length &&
      firstRender.current
    ) {
      setValue('additionalTags', infoAdditionalTags!, {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: false,
      });
      firstRender.current = false;
      reset({ additionalTags: infoAdditionalTags! });
    }
  }, [
    additionalTagsValue?.length,
    infoAdditionalTags,
    isDirty,
    reset,
    setValue,
  ]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--scroll-padding',
      `${navRef?.current?.offsetHeight}px`
    );
  }, [navRef]);

  const handleMakeOfferModal = (show: boolean) => setMakeOfferModal(show);

  const color = secondary ? 'var(--primary-green)' : 'var(--brand-blue-100)';

  const handleNewAdditionalTag = (title: string) => {
    saveAdditionalTag({
      Name: title,
      lang: i18Next.language as 'en' | 'es',
      candidateId: Number(candidateId!),
    });
    const newOption = {
      Id: (additionalTags?.length || 0) + 1,
      title,
    };
    setValue('additionalTags', [...additionalTagsValue, newOption]);
  };

  const submit = (data: { additionalTags: AdditionalTag[] }) => {
    updateAdditionalTags({
      additionalTags: data.additionalTags,
      candidateId: Number(candidateId!),
      lang: i18Next.language as 'en' | 'es',
    });

    reset({
      additionalTags: data.additionalTags,
    });
  };

  const handleNotes = () =>
    navigate(
      `/simera-dashboard/candidate/${candidateId!}/notes/${positionId || 0}`
    );

  const handleAdditionalVideos = () => {
    const route = isEmployee
      ? `/employer/video/${candidateId!}`
      : `/internal/video/${candidateId!}`;

    navigate(route);
  };

  const handleReject = () => {
    setIsFeedbackModalOpen(true);
  };

  return (
    <>
      <div className="px-8 lg:px-12 py-4">
        <CandidatesBackButton
          onClick={() => {
            const originPath = getOriginPath();
            if (originPath !== null) {
              navigate(originPath);
              return;
            }
            navigate(-1);
            if (window.history.length <= 1) {
              navigate('/');
            }
          }}
          color={color}
          text={t('CandidatesTable.Previous')}
        />
      </div>
      <div
        className={`px-8 lg:px-12 flex-col md:flex-row ${classes.CandidateDetailsContainer}`}
      >
        {candidate && statusId && (
          <UpdateToOfferSentConfirmModal
            modalIsOpen={showMakeOfferModal}
            handleClose={handleMakeOfferModal}
            makeOfferToCandidateDto={{
              candidatePositionStatusId: +statusId,
              user: {
                name: `${serverUser?.FirstName} ${serverUser?.LastName}`,
                email: serverUser?.Email!,
              },
            }}
          />
        )}
        <div
          data-testid="profile-info-container"
          className={`sticky ${classes.infoContainer}`}
        >
          <CandidateInformationCardEmployer
            candidate={candidate}
            secondary={secondary}
            candidateWorkExperience={workHistoryData}
            setShowModal={setShowContactModal}
          />

          {secondary && !isEmployee && (
            <button
              type="button"
              className={classes.notesButton}
              onClick={handleNotes}
            >
              {t('Notes.Notes')}
            </button>
          )}

          {isEmployee && (
            <button
              type="button"
              className={classes.notesButton}
              style={{
                backgroundColor:
                  !secondary && isEmployee
                    ? 'var(--brand-blue-100)'
                    : 'var(--primary-green)',
              }}
              onClick={() => setIsCalendarModalOpen(true)}
            >
              {t('CandidatePositionSummaryCard.ScheduleInterview')}
            </button>
          )}

          {isEmployee && (
            <button
              type="button"
              className={classes.notesButton}
              style={{
                backgroundColor:
                  !secondary && isEmployee
                    ? 'var(--brand-blue-100)'
                    : 'var(--primary-green)',
              }}
              onClick={() => handleReject()}
            >
              {t('CandidatePositionSummaryCard.RejectApplication')}
            </button>
          )}

          <button
            type="button"
            className={`${
              !secondary && isEmployee
                ? '!bg-brand-blue100'
                : '!bg-button-orange'
            } ${classes.notesButton}`}
            onClick={handleAdditionalVideos}
          >
            {t('CandidatePositionSummaryCard.CandidateVideos')}
          </button>

          <CandidateResume
            resumeData={candidate?.Curriculum || {}}
            secondary={secondary}
          />
        </div>
        <div className={classes.formsContainer}>
          <ProfileMenu
            link={navRef}
            highlightedSectionIndex={highlightedSection}
            setHighlightedSection={setHighlightedSection}
            secondary={secondary}
          />
          {!!secondary && (
            <div>
              <CurriculumEditableContainer
                isActive={highlightedSection === 1}
              />
            </div>
          )}

          <WorkSummaryEditableContainer
            isActive={false}
            candidate={candidate}
            secondary={!!secondary}
          />
          <div id="section-1">
            <WorkHistoryEditableContainerInternal
              isActive={highlightedSection === 1}
              secondary={!!secondary}
            />
          </div>
          <div id="section-2">
            <JobInterestsEditableContainerInternal
              isActive={highlightedSection === 2}
              secondary={!!secondary}
              isEmployee={isEmployee}
            />
          </div>
          <div id="section-3">
            <SkillsEditableContainerInternal
              isActive={highlightedSection === 3}
              secondary={!!secondary}
            />
          </div>
          <div id="section-4">
            <EducationalInfoEditableContainerInternal
              isActive={highlightedSection === 4}
              secondary={!!secondary}
            />
          </div>
          <div id="section-5">
            <LanguageEditableContainerInternal
              isActive={highlightedSection === 5}
              secondary={!!secondary}
            />
          </div>
          <div id="section-6">
            <ExtracurricularEditableContainerInternal
              isActive={highlightedSection === 6}
              secondary={!!secondary}
              candidate={candidate}
            />
          </div>
          <div id="section-7">
            <TechnicalInfoEditableContainerInternal
              isActive={highlightedSection === 7}
              secondary={!!secondary}
            />
          </div>
          {secondary && (
            <div className={classes.ReadOnlyContainer} id="section-8">
              <p className={classes.ReadOnlyTitle}>
                {t('formNames.additionalTags')}
              </p>
              <form onSubmit={handleSubmit(submit)}>
                <MultipleTagSelector
                  selectedTags={additionalTagsValue || []}
                  options={additionalTags || []}
                  arrayName="additionalTags"
                  setValue={setValue}
                  secondary
                  handleNewOption={handleNewAdditionalTag}
                />
                <div className={classes.formButtonContainer}>
                  <div
                    style={{
                      width: '120px',
                    }}
                  >
                    <Button
                      disabled={!isDirty}
                      bgColor="#079e88"
                      showText
                      text={t('common.save')}
                      submit
                    />
                  </div>
                </div>
              </form>
            </div>
          )}

          {/* <div id="section-111">
            <VideoShowcase candidate={candidate} />
          </div> */}

          {(additionalDocuments?.length !== 0 || secondary) && (
            <div className={classes.ReadOnlyContainer} id="section-10">
              <p className={classes.ReadOnlyTitle}>
                {t('AdditionalDocs.Title')}
              </p>
              {secondary && (
                <AdditionalDocumentsFormCandidate
                  candidateId={candidate?.Id!}
                  fileCategories={fileCategories!}
                  secondary={secondary}
                />
              )}
            </div>
          )}
          {fileCategories &&
            (additionalDocuments?.length !== 0 || secondary) && (
              <div className={classes.ReadOnlyContainer} id="section-10">
                <p className={classes.ReadOnlyTitle}>
                  {t('AdditionalDocs.Title')}
                </p>
                <AdditionalDocuments
                  data={additionalDocuments || []}
                  fileCategories={fileCategories!}
                  secondary={secondary}
                />
              </div>
            )}
        </div>
      </div>
      {showContactModal && (
        <ContactInformationModal
          candidate={candidate}
          setShowModal={setShowContactModal}
        />
      )}
      {isCalendarModalOpen && (
        <CalendarModal
          candidateId={candidate.Id}
          setIsOpen={setIsCalendarModalOpen}
          isOpen={isCalendarModalOpen}
          setSwitchingId={() => {}}
          candidatePositionStatusId={candidatePositionStatusId}
        />
      )}
      <CandidateFeedBackModal
        candidateId={candidate.Id}
        isOpen={isFeedbackModalOpen}
        setIsOpen={setIsFeedbackModalOpen}
        setSwitchingId={() => {}}
      />
    </>
  );
};

CandidateDetails.defaultProps = {
  secondary: false,
  isEmployee: false,
};

export default CandidateDetails;
