import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowWidth from '../../hooks/useWindowWidth';
import EditableContainer from '../EditableContainer/EditableContainer';
import ReadOnly from '../ReadOnly/ReadOnly';
import NewWorkHistory from '../WorkHistoryFormContainer/NewWorkHistory';
import WorkHistoryFormContainer from '../WorkHistoryFormContainer/WorkHistoryFormContainer';
import classes from './WorkHistoryEditableContainer.module.css';
import { useGetWorkHistoryByCandidateIdQuery } from '../../store/modules/work-experience';

interface Props {
  isActive: boolean;
}

const WorkHistoryEditableContainer: FC<Props> = ({ isActive }) => {
  const [t, i18n] = useTranslation('global');

  const [isEdit, setIsEdit] = useState(true);
  const [show, setShow] = useState(false);
  const [newWorkHistory, setNewWorkHistory] = useState(false);
  const isMobile = useWindowWidth() <= 650;

  const candidateId: number = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;

  const { data } = useGetWorkHistoryByCandidateIdQuery({
    candidateId,
    lang: i18n.language as 'en' | 'es',
  });

  const onClickPencil = () => {
    if (isMobile && window) {
      window.scrollTo(0, 0);
    }
    setIsEdit(false);
  };

  const handleCancel = () => {
    setShow(true);
  };

  const handleCancelModal = (isCancel: boolean, continueEditing: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    if (newWorkHistory && continueEditing) {
      setNewWorkHistory(false);
    }
    setShow(false);
  };

  const onClickSave = () => {
    setNewWorkHistory(false);
  };

  const onClickPlus = () => {
    setNewWorkHistory(!newWorkHistory);
    if (isMobile && window) {
      window.scrollTo(0, 0);
    }
  };

  const newWorkOrField = () => {
    if (newWorkHistory) {
      return (
        <NewWorkHistory
          countForms={data?.length || 1}
          onClickCancel={handleCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={handleCancelModal}
          show={show}
          onClickSave={onClickSave}
          newWorkHistory
        />
      );
    }
    return data?.map((work) => {
      const defaultDate = work?.Admission?.val
        ? `${t('formNames.actually')}`
        : '';
      return (
        <ReadOnly
          key={`workHistory-${work?.Id}`}
          title={work?.JobTitle?.val!}
          subtitle={work?.Company.val!}
          startDate={work?.Admission.val?.split('-')[0]}
          endDate={
            work?.Egress?.val ? work?.Egress.val.split('-')[0] : defaultDate
          }
        />
      );
    });
  };

  return (
    <EditableContainer
      title={t('formNames.work')}
      isEditable={isEdit}
      onClickPencil={onClickPencil}
      onClickPlus={onClickPlus}
      hasAddMore
      addMore={newWorkHistory ? t('workHistory.experience') : false}
      isActive={isActive}
    >
      {!isEdit ? (
        <div className={classes.content}>
          <WorkHistoryFormContainer
            isEdit
            setEdit={setIsEdit}
            onClickCancel={handleCancel}
            onClickCancelModal={handleCancelModal}
            show={show}
          />
        </div>
      ) : (
        newWorkOrField()
      )}
    </EditableContainer>
  );
};

export default WorkHistoryEditableContainer;
