import { useForm } from 'react-hook-form';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../icons/SearchIcon.svg';
import classes from './SearchBar.module.css';
import { isValidNumber } from '../../../utils/isValidNumber';

const SearchBar: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      search: searchParams.get('Query') ?? '',
    },
  });
  const search = register('search', {
    setValueAs: (value: string) => value.trim().replace(/\s\s+/g, ' '),
  });
  const [t] = useTranslation('global');

  const searchValue = watch('search');

  const handleSearch = useCallback(
    (val: { search: string }) => {
      const params = new URLSearchParams(searchParams);
      params.delete('Email');
      params.delete('Id');
      params.delete('Query');
      if (isValidNumber(val.search)) {
        params.set('Id', val.search);
      } else if (val.search.includes('@')) {
        params.set('Email', val.search);
      } else if (val.search) {
        params.set('Query', val.search);
      }
      setSearchParams(params);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    const resetQuery = setTimeout(() => {
      handleSearch({ search: searchValue });
    }, 1000);

    return () => clearTimeout(resetQuery);
  }, [handleSearch, searchValue]);

  return (
    <form onSubmit={handleSubmit(handleSearch)} className={classes.Container}>
      <div className={classes.inputContainer} data-testid="searchbar">
        <SearchIcon className={classes.icon} />
        <input
          ref={search.ref}
          className={classes.inputField}
          type="text"
          placeholder={t('SimeraInternal.SearchBarPlaceholder')}
          name={search.name}
          onChange={search.onChange}
          onBlur={search.onBlur}
        />
      </div>
    </form>
  );
};

export default SearchBar;
