import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import classes from './SimeraCompanyScreen.module.css';
import Spinner from '../../Spinner/Spinner';
import EmployeesTable from './EmployeesTable/EmployeesTable';
import CandidatesBackButton from '../../CandidateDetails/BackButton/CandidatesBackButton';
import { useGetEmployeesByCompanyQuery } from '../../../store/modules/companies';

const SimeraCompanyScreen = () => {
  const { id } = useParams();
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const { data = [], isLoading } = useGetEmployeesByCompanyQuery({
    companyId: Number(id!),
  });
  const employees = useMemo(
    () =>
      data?.map((employee) => ({
        candidateId: employee.Candidate.Id,
        name: `${employee.Candidate.User.FirstName} ${employee.Candidate.User.LastName}`,
        positionName: employee.Position.JobPosition.title,
        startDate: employee.StartDate
          ? dayjs(employee.StartDate).format('MM/DD/YYYY')
          : 'N/A',
        salary: employee.Salary ? employee.Salary : 'N/A',
        endDate: employee.EndDate
          ? dayjs(employee.EndDate).format('MM/DD/YYYY')
          : 'N/A',
        candidatePositionStatusId: employee.Id,
      })),
    [data]
  );

  const color = 'var(--primary-green)';
  return (
    <div className={classes.container}>
      <div>
        <CandidatesBackButton
          text={t('CompaniesTable.Back')}
          onClick={() => {
            navigate('/internal-dashboard/companies', { replace: true });
          }}
          color={color}
        />
      </div>
      <div className={classes.content}>
        <header className={classes.header}>
          <div className={classes.headerText}>
            <h1 className={classes.title}> {t('CompaniesTable.Employees')}</h1>
          </div>
        </header>
        <div className={classes.tableContainer} data-testid="employees-table">
          <div className={classes.tableScroll}>
            {isLoading ? (
              <div className={classes.spinnerContainer}>
                <Spinner secondary height="100%" width="100%" />
              </div>
            ) : (
              <EmployeesTable data={employees} rawData={data} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimeraCompanyScreen;
