import { createApi } from '@reduxjs/toolkit/query/react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { baseQuery } from '../../utils';
import {
  deleteCV,
  uploadCV,
  uploadProfilePicture,
  deleteProfilePicture,
  updateVideo,
} from './mutations';
import { getCv, getProfilePicture } from './queries';
import { UploadState } from './uploads.types';
import { ReducerPaths } from '../../constants/reducerPaths';
import { parseCV } from './mutations/parse-cv.mutation';
import { saveParsedCV } from './mutations/save-parsed-cv.mutation';

const initialState: UploadState = {
  unsavedVideoStatus: false,
  currentSlide: 0,
};

export const uploadsApi = createApi({
  reducerPath: ReducerPaths.UPLOADS,
  baseQuery,
  endpoints: (builder) => ({
    uploadCV: uploadCV(builder),
    deleteCV: deleteCV(builder),
    uploadProfilePicture: uploadProfilePicture(builder),
    getCv: getCv(builder),
    getProfilePicture: getProfilePicture(builder),
    deleteProfilePicture: deleteProfilePicture(builder),
    parseCV: parseCV(builder),
    saveParsedCV: saveParsedCV(builder),
    updateVideo: updateVideo(builder),
  }),
});

export const uploadsSlice = createSlice({
  name: ReducerPaths.UPLOAD,
  initialState,
  reducers: {
    sendVideoAnalytics: (
      state,
      action: PayloadAction<{
        currentSlide: number;
        candidateId: number;
      }>
    ) => {
      return { ...state, ...action.payload };
    },
    setUnsavedVideoStatus: (state, action) => {
      return { ...state, unsavedVideoStatus: action.payload };
    },
  },
});

export const {
  useUploadCVMutation,
  useDeleteCVMutation,
  useUploadProfilePictureMutation,
  useGetCvQuery,
  useLazyGetCvQuery,
  useGetProfilePictureQuery,
  useDeleteProfilePictureMutation,
  useParseCVMutation,
  useSaveParsedCVMutation,
  useUpdateVideoMutation,
} = uploadsApi;

export const { sendVideoAnalytics, setUnsavedVideoStatus } =
  uploadsSlice.actions;
