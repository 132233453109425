import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  AddSchedulesByCpsInput,
  AddSchedulesByCpsOutput,
  CandidateScheduleBuilder,
} from '../candidate-schedule.types';

export const addSchedulesByCps = (builder: CandidateScheduleBuilder) =>
  builder.mutation<AddSchedulesByCpsOutput, AddSchedulesByCpsInput>({
    query: (args) => ({
      url: '/schedule/add-range',
      method: 'POST',
      body: args.data,
    }),
    transformResponse: (res, meta, args) => {
      successHandler(
        (args.lang ?? 'en') === 'en'
          ? SuccessMessageEn.ScheduleSaved
          : SuccessMessageEs.ScheduleSaved,
        true
      );
      return args.data.schedules;
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[AddSchedulesAction]',
        lang === 'es'
          ? ErrorMessageEs.setSchedules
          : ErrorMessageEn.setSchedules,
        error,
        true
      );
    },
    invalidatesTags: () => [{ type: 'candidatesSchedule' }],
  });
