import { FC, useEffect, useRef } from 'react';
import { ReactComponent as PlayIconBig } from '../../../../../icons/PlayIconBig.svg';
import { ReactComponent as NoVideoIcon } from '../../../../../icons/NoVideoIcon.svg';
import classes from './CardSummaryVideoButton.module.css';
import VideoGenericElement from '../../../../GenericElements/VideoGenericElement';
import {
  CandidateAdditionalVideoType,
  CandidateData,
} from '../../../../../types/candidates';

interface Props {
  videos: CandidateData['AdditionalVideos'];
  openModal: () => void;
}

const CardSummaryVideoButton: FC<Props> = ({ videos, openModal }) => {
  const handleClick = () => {
    openModal();
  };
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    videoRef.current?.load?.();
  }, [videos]);

  const disabled = (videos && videos.length === 0) as boolean;

  const findValidVideo = (data: CandidateData['AdditionalVideos']) => {
    if (data && data?.length !== 0) {
      const validVideo = data?.find((url) => url.Url !== null);
      return validVideo;
    }

    return { Id: 0, Name: '', Key: '', Url: '' };
  };

  return (
    <button
      type="button"
      onClick={() => (!disabled ? handleClick() : null)}
      className={`flex justify-center items-center ${classes.buttonContainer}`}
      disabled={disabled}
    >
      {!disabled ? (
        <div
          style={{
            position: 'relative',
          }}
        >
          <VideoGenericElement
            videoRef={videoRef}
            className={classes.video}
            muted
            videoData={findValidVideo(videos) as CandidateAdditionalVideoType}
          />
          <PlayIconBig
            fill="var(--primary-green)"
            width="3rem"
            height="3rem"
            className={classes.playIconContainer}
            onClick={handleClick}
            data-testid="play-icon"
          />
        </div>
      ) : (
        <NoVideoIcon
          stroke="var(--brand-green-20)"
          width="5rem"
          height="5rem"
        />
      )}
    </button>
  );
};
export default CardSummaryVideoButton;
