import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Buttons/Button';
import classes from './VideoSection.module.css';

interface VideoSectionProps {
  onClick: (x: number) => void;
}

const VideoSection: FC<VideoSectionProps> = ({ onClick }) => {
  const [t] = useTranslation('global');

  return (
    <div
      className={classes.SectionContainer}
      data-testid="video-section-container"
    >
      <p className={classes.textP}>{t('videoSection.title')}</p>
      <p className={classes.text}>{t('videoSection.text')}.</p>
      <Button
        text={t('videoSection.yourVideos')}
        showText
        bgColor="#ff8f00"
        onClick={() => onClick(1)}
        customStyles={{ fontSize: '0.875rem' }}
      />
    </div>
  );
};

export default VideoSection;
