import { CandidateData } from '../../../../types/candidates';

const filterByEnglishLevel = (
  singleCandidate: CandidateData,
  englishLevel: string
) => {
  return (
    (singleCandidate.Videos &&
      singleCandidate.Videos.some((singleVideo) => {
        return (
          singleVideo?.EnglishAssessment &&
          singleVideo?.EnglishAssessment?.Score &&
          singleVideo?.EnglishAssessment?.Score?.toLowerCase() ===
            englishLevel.toLowerCase()
        );
      })) ??
    false
  );
};

export default filterByEnglishLevel;
