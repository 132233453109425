const enum RouteTypes {
  DEFAULT,
  AUTH,
  PROTECTED,
  CANDIDATE,
  EMPLOYER,
  INTERNAL,
}

export default RouteTypes;
