import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { getCandidateSkills } from './queries';
import {
  addMultipleCandidateSkills,
  addMultipleCandidateSkillsInternal,
  deleteCandidateSkill,
} from './mutations';

export const candidateSkillApi = createApi({
  reducerPath: ReducerPaths.CANDIDATE_SKILL,
  baseQuery,
  endpoints: (builder) => ({
    getCandidateSkills: getCandidateSkills(builder),
    deleteCandidateSkills: deleteCandidateSkill(builder),
    addMultipleCandidateSkills: addMultipleCandidateSkills(builder),
    addMultipleCandidateSkillsInternal:
      addMultipleCandidateSkillsInternal(builder),
  }),
});

export const {
  useGetCandidateSkillsQuery,
  useLazyGetCandidateSkillsQuery,
  useDeleteCandidateSkillsMutation,
  useAddMultipleCandidateSkillsInternalMutation,
  useAddMultipleCandidateSkillsMutation,
} = candidateSkillApi;
