import { useEffect, useState } from 'react';
import useWindowSize from './useWindowSize';
import { useAppSelector } from './use-app-selector.hook';

const useRegisterForm = () => {
  const authState = useAppSelector((state) => state.auth);
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);

  const [passStrength, setPassStrength] = useState(0);

  useEffect(() => {
    if (windowSize && windowSize.width) {
      setIsMobile(windowSize.width < 768);
    }
  }, [windowSize, setIsMobile]);

  const passwordValidator = (pass: string) => {
    let strength = 0;
    if (!pass || pass.length === 0) {
      strength = 0;
      setPassStrength(0);
    }

    if (pass.length >= 6) {
      strength = 1;
    }
    if (pass.match(/^(?=.*\d)(?=.*[a-zA-Z]).{6,}/)) {
      // has at least 6 characters, and contains a number and letters
      strength = 2;
    }
    if (pass.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      // contains at least 8 letters, a number, a lowercase and uppercase letter
      strength = 3;
    }
    if (
      pass.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      )
    ) {
      // contains at least 8 letters, a number, a lowercase, uppercase and special character
      strength = 4;
    }

    setPassStrength(strength);
    return strength;
  };

  return {
    isMobile,
    authState,
    passwordValidator,
    passStrength,
  };
};

export default useRegisterForm;
