import { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link } from 'react-router-dom';
import { ReactComponent as Copy } from '$icons/copy.svg';
import {
  DAYJS_SHORTDATE_AND_TIME,
  DAYJS_SHORTDATE_FORMAT,
} from '$constants/datetimes';
import { errorHandler, successHandler } from '$store/utils';
import {
  GetAllInterviewsOutput,
  GetInterviewsSlotsAsInternalOutput,
  useLazyGetInterviewSlotsAsInternalQuery,
} from '$store/modules/interview';
import { segmentTrack } from '$utils/handleSegment';
import ModalInTailwind from '$components/ModalInTailwind/ModalInTailwind';
import InternalJobInterviewStatusSelect from './InternalJobInterviewStatusSelect';
import InternalScheduleInterviewCalendar from './InternalScheduleInterviewCalendar';

const InternalJobInterviewsTable = ({
  interviewsData,
}: {
  interviewsData: GetAllInterviewsOutput['data'] | undefined;
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const cellClassnames =
    'table-cell p-2 first:pl-6 last:pr-6 bg-white group-hover/row:bg-green-50';

  const [isOpenScheduleInterview, setIsOpenScheduleInterview] = useState(false);
  const [candidateInterviewSlots, setCandidateInterviewSlots] = useState<
    GetInterviewsSlotsAsInternalOutput[number]['UserSchedules'] | undefined
  >();
  const [takenSlots, setTakenSlots] = useState<
    | GetInterviewsSlotsAsInternalOutput[number]['ReservedUserSchedules']
    | undefined
  >();
  const [candidateInterviewId, setCandidateInterviewId] = useState<
    number | undefined
  >();

  const [getInterviewSlotsAsInternal] =
    useLazyGetInterviewSlotsAsInternalQuery();

  const copyToClipboardHandler = async (email: string, type: string) => {
    try {
      await navigator.clipboard.writeText(email);
      successHandler(`${type} copied`, true);
      segmentTrack(`Interviews - Copy ${type}`);
    } catch (err) {
      errorHandler(
        '[JobInterviewCopy]',
        'Something went wrong, try again later',
        err,
        true,
        true
      );
    }
  };

  const onScheduleHandler = async (
    interviewData: GetAllInterviewsOutput['data'][number]
  ) => {
    setIsOpenScheduleInterview(true);
    const candidateInterviews = await getInterviewSlotsAsInternal({
      candidateId: interviewData.Candidate.Id,
      candidatePositionStatus: interviewData.CandidatePositionStatus.Id,
    });
    setCandidateInterviewSlots(
      candidateInterviews && candidateInterviews?.data?.[0]
        ? candidateInterviews.data[0].UserSchedules
        : []
    );
    setTakenSlots(
      candidateInterviews && candidateInterviews?.data?.[0]
        ? candidateInterviews.data[0].ReservedUserSchedules
        : []
    );
    setCandidateInterviewId(interviewData.Id);
  };

  const onCloseModalHandler = () => {
    setIsOpenScheduleInterview(false);
    setCandidateInterviewSlots(undefined);
  };

  return (
    <div className="w-full">
      <ModalInTailwind
        isOpen={isOpenScheduleInterview}
        closeModal={onCloseModalHandler}
      >
        <InternalScheduleInterviewCalendar
          schedules={candidateInterviewSlots}
          onClose={onCloseModalHandler}
          interviewId={candidateInterviewId}
          takenSlots={takenSlots || []}
        />
      </ModalInTailwind>
      <div className="overflow-x-auto w-full pb-8">
        <div className="table border-collapse w-full">
          <div className="table-header-group font-semibold border-b border-internal-main border-opacity-75">
            <div className={`sticky left-0 min-w-40 ${cellClassnames}`}>
              Candidate Name
            </div>
            <div className={cellClassnames}>ID</div>
            <div className={cellClassnames}>Copy Email</div>
            <div className={cellClassnames}>Phone</div>
            <div className={cellClassnames}>Company Name</div>
            <div className={cellClassnames}>Position Name</div>
            <div className={cellClassnames}>Request Date</div>
            <div className={cellClassnames}>Interview Date</div>
            <div className={cellClassnames}>Status</div>
          </div>
          {interviewsData && (
            <div className="table-row-group">
              {interviewsData.map((singleInterview) => {
                return (
                  <div
                    className="table-row border-b border-internal-main border-opacity-75 m-4 cursor-default group/row"
                    key={`candidate_interview_${singleInterview.Id}`}
                    style={{
                      backgroundColor: 'red',
                    }}
                  >
                    {/* Name */}
                    <div className={`sticky left-0 z-10 ${cellClassnames}`}>
                      <Link
                        to={`/simera-dashboard/candidates/${singleInterview.Candidate.Id}`}
                        className="hover:underline hover:text-internal-link"
                        onClick={() =>
                          segmentTrack('Interviews - Go To Candidate Profile')
                        }
                      >
                        {`${singleInterview.Candidate.User.FirstName} ${singleInterview.Candidate.User.LastName}`}
                      </Link>
                    </div>
                    {/* Id */}
                    <div className={cellClassnames}>
                      {singleInterview.Candidate.Id}
                    </div>
                    {/* Email */}
                    <div className={`${cellClassnames}`}>
                      <div className="flex flex-row gap-2 group relative">
                        <div className="max-w-20 truncate ">
                          {singleInterview.Candidate.User.Email}
                        </div>
                        {singleInterview?.Candidate?.User?.Email && (
                          <>
                            <div className="absolute hidden group-hover:flex bg-white shadow border top-8 py-1 px-2 rounded z-10">
                              {singleInterview.Candidate.User.Email}
                            </div>
                            <button
                              type="button"
                              className="items-center flex justify-center"
                              aria-label="Copy Email"
                              onClick={() => {
                                copyToClipboardHandler(
                                  singleInterview.Candidate.User.Email,
                                  'Email'
                                );
                              }}
                            >
                              <Copy />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    {/* Phone */}
                    <div className={cellClassnames}>
                      <div className="flex flex-row gap-2">
                        {singleInterview?.Candidate?.User?.Phone}
                        {singleInterview?.Candidate?.User?.Phone && (
                          <button
                            type="button"
                            className="items-center flex justify-center"
                            aria-label="Copy Email"
                            onClick={() => {
                              copyToClipboardHandler(
                                singleInterview.Candidate.User.Phone,
                                'Phone'
                              );
                            }}
                          >
                            <Copy />
                          </button>
                        )}
                      </div>
                    </div>
                    {/* Company */}
                    <div className={cellClassnames}>
                      {singleInterview.User.Employer.Company.Name}
                    </div>
                    {/* Position */}
                    <div className={cellClassnames}>
                      {singleInterview?.CandidatePositionStatus?.Position
                        ?.Name ||
                        singleInterview?.CandidatePositionStatus?.Position
                          ?.JobPosition?.title}
                    </div>
                    {/* Request Date */}
                    <div className={cellClassnames}>
                      {dayjs(singleInterview.CreateTime).format(
                        DAYJS_SHORTDATE_FORMAT
                      )}
                    </div>
                    {/* Interview Date */}
                    <div className={cellClassnames}>
                      {singleInterview.InterviewDate &&
                        `${dayjs
                          .tz(
                            singleInterview.InterviewDate,
                            singleInterview.Timezone
                          )
                          .tz('America/New_York')
                          .format(DAYJS_SHORTDATE_AND_TIME)} EST`}
                    </div>
                    {/* Status */}
                    <div className={cellClassnames}>
                      <InternalJobInterviewStatusSelect
                        currentStatus={singleInterview.Status}
                        Id={singleInterview.Id}
                        onSchedule={() => onScheduleHandler(singleInterview)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InternalJobInterviewsTable;
