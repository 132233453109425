import Modal from 'react-modal';

import { useDispatch } from 'react-redux';
import classes from './ActiveVideoModal.module.css';
import { useAppSelector } from '../../../../../hooks';
import { VideoPlayer } from '../VideoPlayer';
import { useAdditionalVideo } from '../../../../../store/modules/additional-videos/hooks';
import { ReactComponent as CloseX } from '../../../../../icons/CloseX.svg';
import { ReactComponent as Arrow } from '../../../../../icons/ArrowBackInternal.svg';
import { triggerSegmentOnCloseModalTrack } from '../../../../../store/globals/LastPlayedVideo';

interface Props {
  internal?: boolean;
}
export const ActiveVideoModal = ({ internal }: Props) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const {
    closeAdditionalVideoModal,
    setVideo,
    removeActiveVideo,
    removeActiveVideoType,
  } = useAdditionalVideo();
  const { isModalOpen } = useAppSelector((state) => state.additionalVideoSlice);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(triggerSegmentOnCloseModalTrack());
    removeActiveVideo();
    removeActiveVideoType();
    closeAdditionalVideoModal();
  };

  const { activeVideo, videos } = useAppSelector(
    (state) => state.additionalVideoSlice
  );

  const color = internal ? 'var(--brand-green-10)' : 'var(--brand-blue-100)';
  const handlePrev = () => {
    const index = videos.findIndex((video) => video.Id === activeVideo?.Id);
    if (index === 0) {
      setVideo(videos[videos.length - 1]);
    } else {
      setVideo(videos[index - 1]);
    }
  };

  const handleNext = () => {
    const index = videos.findIndex((video) => video.Id === activeVideo?.Id);
    if (index === videos.length - 1) {
      setVideo(videos[0]);
    } else {
      setVideo(videos[index + 1]);
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      className={classes.Modal}
      overlayClassName={{
        base: classes.Overlay,
        afterOpen: classes.OverlayAfterOpen,
        beforeClose: classes.OverlayBeforeClose,
      }}
      contentLabel="Upload video"
      ariaHideApp={false}
      closeTimeoutMS={200}
      bodyOpenClassName={classes.bodyOpen}
    >
      <header>
        <div className={classes.header}>
          <button
            onClick={handleClose}
            type="button"
            aria-label="Close"
            className={classes.closeButton}
          >
            <CloseX width={15} height={15} fill={color} />
          </button>
        </div>
      </header>
      <main className={classes.body}>
        <button
          type="button"
          onClick={handlePrev}
          disabled={videos?.length === 0}
          className={classes.button}
          aria-label="Previous"
        >
          <Arrow fill={color} />
        </button>

        {activeVideo && <VideoPlayer video={activeVideo} internal={internal} />}

        <button
          disabled={videos?.length === 0}
          type="button"
          onClick={handleNext}
          className={classes.button}
          aria-label="Next"
        >
          <Arrow
            fill={color}
            style={{
              transform: 'rotate(180deg)',
            }}
          />
        </button>
      </main>
    </Modal>
  );
};

ActiveVideoModal.defaultProps = {
  internal: false,
};
