import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { ReactComponent as EmptyIcon } from '../../../icons/EmptyCandidates.svg';
import { CandidateData } from '../../../types/candidates';
import CandidatesSearchFilters from '../../../types/candidatesPaginatedSearch';
import SearchFilter from '../utils/SearchFilter';
import CandidateSummaryCardGraph from './CandidateSummaryCard/CandidateSummaryCardGraph';
import classes from './CandidateSummaryCardList.module.css';
import CardSkeleton from './CardSkeleton/CardSkeleton';

interface CandidateSummaryCardListProps {
  jobPositionStatus: string;
  candidatesData: CandidateData[];
  positionId: number;
  currentFilter: string;
  loading: boolean;
  setSwitchingId: Dispatch<SetStateAction<number | null>>;
  onNextPage: () => void;
  filters: CandidatesSearchFilters;
}
const CandidateSummaryCardListGraph: FC<CandidateSummaryCardListProps> = ({
  jobPositionStatus,
  candidatesData,
  positionId,
  currentFilter,
  loading,
  setSwitchingId,
  onNextPage,
  filters,
}) => {
  const [t] = useTranslation('global');
  const [filteredData, setFilteredData] = useState(candidatesData);
  const lastSearchTotalItems = useRef(0);

  const applyFilters = useMemo(() => {
    // reset lastSearchTotalItems, 25 its the default return value for the first page, if its less it should be a new search
    if (candidatesData.length <= 25) lastSearchTotalItems.current = 0;
    return SearchFilter(filters, candidatesData);
  }, [filters, candidatesData]);

  useEffect(() => {
    setFilteredData(applyFilters);
  }, [applyFilters]);

  useEffect(() => {
    if (!loading) {
      if (filteredData.length < 3) {
        // 3 items to make virtuoso have scroll bar and enable atBottomStateChange trigger
        onNextPage();
      } else if (
        // save last filtered result
        lastSearchTotalItems.current === 0 ||
        lastSearchTotalItems.current !== filteredData.length
      ) {
        lastSearchTotalItems.current = filteredData.length;
      } else if (lastSearchTotalItems.current === filteredData.length) {
        // if parent component sent new data and the new whole set didnt aggregate items, call again next page
        onNextPage();
      }
    }
  }, [filteredData?.length, loading, onNextPage]);

  if (filteredData.length === 0)
    return (
      <div className={classes.NoData}>
        <EmptyIcon fill="var(--primary-green)" />
        <p className={classes.NoDataText}>{t('SimeraInternal.EmptyState')}</p>
      </div>
    );

  if (currentFilter === 'new') {
    filteredData.sort((a, b) => {
      if (a.CandidatesPositionStatus && b.CandidatesPositionStatus) {
        if (
          a.CandidatesPositionStatus?.[0]?.CreateTime >
          b.CandidatesPositionStatus?.[0]?.CreateTime
        )
          return -1;
        if (
          a.CandidatesPositionStatus?.[0]?.CreateTime <
          b.CandidatesPositionStatus?.[0]?.CreateTime
        )
          return 1;
      }
      return 0;
    });
  }

  if (
    (filters.skills && filters.skills?.length > 0) ||
    (filters.languages && filters.languages?.length > 0) ||
    (filters.education && filters.education?.length > 0) ||
    (filters.englishLevel && filters.englishLevel?.length > 0) ||
    (filters.fileCategories && filters.fileCategories?.length > 0) ||
    (filters.workExperience && filters.workExperience?.length > 0) ||
    filters.name
  ) {
    filteredData.sort((a, b) => {
      if (a.CandidatesPositionStatus && b.CandidatesPositionStatus) {
        if (
          a.CandidatesPositionStatus?.[0]?.CreateTime >
          b.CandidatesPositionStatus?.[0]?.CreateTime
        )
          return -1;
        if (
          a.CandidatesPositionStatus?.[0]?.CreateTime <
          b.CandidatesPositionStatus?.[0]?.CreateTime
        )
          return 1;
      }
      return 0;
    });
  }

  return (
    <div className={classes.Container} data-testid="card-list-container">
      {filteredData && (
        <Virtuoso
          atBottomStateChange={onNextPage}
          style={{
            marginBottom: '10px',
            height: '100%',
          }}
          totalCount={filteredData.length}
          itemContent={(index) => {
            const candidate = filteredData[index];
            return (
              <CandidateSummaryCardGraph
                jobPositionStatus={jobPositionStatus}
                candidate={candidate}
                candidateIndex={index}
                positionId={positionId}
                setSwitchingId={setSwitchingId}
              />
            );
          }}
        />
      )}
      {loading && <CardSkeleton />}
    </div>
  );
};

export default CandidateSummaryCardListGraph;
