import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  DuplicatePositionCutomRoasterInput,
  DuplicatePositionCutomRoasterOutput,
} from '../companies.types';

export const duplicatePositionWithCustomRoster = (builder: CompanyBuilder) =>
  builder.mutation<
    DuplicatePositionCutomRoasterOutput,
    DuplicatePositionCutomRoasterInput
  >({
    query: ({ position, candidateId }) => ({
      url: '/positions/duplicate/custom',
      method: 'POST',
      body: { ...position, Candidates: candidateId },
    }),
    transformResponse: (
      queryResponse: DuplicatePositionCutomRoasterOutput,
      _,
      arg
    ) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEs.POSITION_DUPLICATED
          : SuccessMessageEn.POSITION_DUPLICATED,
        true
      );
      return queryResponse;
    },
    transformErrorResponse: (error, _, arg) => {
      errorHandler(
        '[duplicatePositionInternal]',
        arg.lang === 'en'
          ? ErrorMessageEn.PositionDuplicateError
          : ErrorMessageEs.PositionDuplicateError,
        error,
        true
      );
    },
  });
