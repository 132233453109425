import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './SkillsEditableContainer.module.css';
import EditableContainer from '../EditableContainer/EditableContainer';
import useWindowWidth from '../../hooks/useWindowWidth';
import SkillsReadOnly from './SkillsReadOnly';
import SkillsContainer from '../SkillsFormContainer/SkillsContainer';
import NewSkillsContainer from '../SkillsFormContainer/NewSkillsContainer';
import { useGetCandidateSkillsQuery } from '../../store/modules/candidate-skill';
import CardSkeleton from '../Positions/CandidateSummaryCardList/CardSkeleton/CardSkeleton';

interface Props {
  isActive: boolean;
}

const SkillsEditableContainer: FC<Props> = ({ isActive }) => {
  const [isEdit, setIsEdit] = useState(true);
  const [t, i18next] = useTranslation('global');
  const [show, setShow] = useState(false);
  const [newSkills, setNewSkills] = useState(false);
  const candidateId: number = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;

  const { data, isLoading } = useGetCandidateSkillsQuery({
    candidateId: +candidateId!,
    lang: i18next.language as 'en' | 'es',
  });

  const isMobile = useWindowWidth() <= 650;

  const onClickPencil = () => {
    if (isMobile && window) window.scrollTo(0, 0);
    setIsEdit(false);
  };

  const handleCancel = () => {
    setShow(true);
  };

  const handleCancelModal = (isCancel: boolean, continueEditing: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    if (newSkills && continueEditing) {
      setNewSkills(false);
    }
    setShow(false);
  };

  const onClickSave = () => {
    setNewSkills(false);
  };
  const onClickPlus = () => {
    setNewSkills(!newSkills);
  };

  const newSkillsOrField = () => {
    if (isLoading)
      return <CardSkeleton hasProfilePicture={false} rows={2} padding={0} />;

    if (newSkills) {
      return (
        <NewSkillsContainer
          onClickCancel={handleCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={handleCancelModal}
          show={show}
          onClickSave={onClickSave}
          newSkills
          countForms={data?.length || 1}
        />
      );
    }
    return (
      <div className={classes.SkillsEditableContainer}>
        {data &&
          data?.map((skill, idx) => (
            <SkillsReadOnly
              skillsLength={data?.length ?? 0}
              key={skill?.Id}
              skill={skill}
              idx={idx}
            />
          ))}
      </div>
    );
  };

  return (
    <EditableContainer
      title={t('formNames.skills')}
      isEditable={isEdit}
      data-testid="skills-editableForm"
      onClickPencil={onClickPencil}
      onClickPlus={onClickPlus}
      hasAddMore
      addMore={newSkills ? t('skills.skill') : false}
      isActive={isActive}
    >
      {!isEdit ? (
        <SkillsContainer
          isEdit
          setEdit={setIsEdit}
          onClickCancel={handleCancel}
          onClickCancelModal={handleCancelModal}
          show={show}
        />
      ) : (
        newSkillsOrField()
      )}
    </EditableContainer>
  );
};

export default SkillsEditableContainer;
