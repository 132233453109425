import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import CandidatesBackButton from '../../../CandidateDetails/BackButton/CandidatesBackButton';
import classes from './AdditionalVideosEmployer.module.css';
import {
  VideoSection,
  VideoGalleryFull,
  ActiveVideoModal,
} from '../components';
import EmployerLayout from '../../../EmployerDashboard/EmployerLayout';

export const AdditionalVideosEmployerScreen: FC = () => {
  const navigate = useNavigate();

  const [t] = useTranslation('global');

  return (
    <EmployerLayout isPositions>
      <section className={classes.layout}>
        <CandidatesBackButton
          text={`${t('AdditionalVideos.backButtonEmployer')}`}
          onClick={() => navigate(-1)}
          color="#05668D"
        />
        <br />
        <VideoSection>
          <VideoGalleryFull employer />
        </VideoSection>
      </section>
      <ActiveVideoModal />
    </EmployerLayout>
  );
};
