import { ErrorMessageEn } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  BackgroundTasksBuilder,
  GetTasksInput,
  GetTasksOutput,
} from '../background-tasks.types';

export const getTasks = (builder: BackgroundTasksBuilder) =>
  builder.query<GetTasksOutput, GetTasksInput>({
    query: (options) => ({
      url: '/background-tasks',
      method: 'GET',
      params: {
        take: 4,
        page: options.page,
        order: 'DESC',
      },
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.data.map(({ Id }) => ({ type: 'Task' as const, Id })),
            { type: 'Task', Id: 'LIST' },
          ]
        : [{ type: 'Task', Id: 'LIST' }],
    transformErrorResponse: (err) => {
      errorHandler('[getTasksAction]', ErrorMessageEn.GetTasksFail, err, true);
      return err.data;
    },
  });
