import useOnClickOutside from '$hooks/useOnClickOutside';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as DownArrow } from '$icons/OpenArrow.svg';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { DAYJS_JOB_INTERVIEW_DATE_FORMAT } from '$constants/datetimes';
import dayjs from 'dayjs';
import { segmentTrack } from '$utils/handleSegment';

export enum JobInterviewsViewModeTypes {
  todayInterviews = 'Today Interviews',
  upcomingInterviews = 'Upcoming Interviews',
  openRequests = 'Open Requests',
  custom = 'Custom',
}

export type DefaultViewModesType =
  | 'Today Interviews'
  | 'Upcoming Interviews'
  | 'Open Requests'
  | undefined;

const JobInterviewViewsFilterButton = ({
  formRegisterHook,
  formSetValueHook,
  currentViewMode,
}: {
  formRegisterHook: UseFormRegister<FieldValues>;
  formSetValueHook: UseFormSetValue<FieldValues>;
  currentViewMode: JobInterviewsViewModeTypes | undefined;
}) => {
  const DEFAULT_VIEW_MODES: JobInterviewsViewModeTypes[] = [
    JobInterviewsViewModeTypes.todayInterviews,
    JobInterviewsViewModeTypes.upcomingInterviews,
    JobInterviewsViewModeTypes.openRequests,
    JobInterviewsViewModeTypes.custom,
  ];
  const standardViewsButtonRef = useRef(null);
  const [showViewOptions, setShowViewOptions] = useState(false);

  useOnClickOutside(standardViewsButtonRef, () => setShowViewOptions(false));

  const clearPreviousFilters = useCallback(() => {
    formSetValueHook('jobInterviewInterviewDate', undefined);
    formSetValueHook('status', undefined);
  }, [formSetValueHook]);

  useEffect(() => {
    segmentTrack('Interviews - Standard View Changed');
    switch (currentViewMode) {
      case JobInterviewsViewModeTypes.openRequests:
        clearPreviousFilters();
        formSetValueHook('status', ['NeedToSchedule', 'Rescheduled']);
        setShowViewOptions(false);
        break;
      case JobInterviewsViewModeTypes.upcomingInterviews:
        clearPreviousFilters();
        formSetValueHook('jobInterviewInterviewDate', {
          from: dayjs(new Date()).format(DAYJS_JOB_INTERVIEW_DATE_FORMAT),

          to: dayjs(new Date())
            .add(1, 'year')
            .format(DAYJS_JOB_INTERVIEW_DATE_FORMAT),
        });
        setShowViewOptions(false);
        break;
      case JobInterviewsViewModeTypes.todayInterviews:
        clearPreviousFilters();
        formSetValueHook('jobInterviewInterviewDate', {
          to: dayjs(new Date()).format(DAYJS_JOB_INTERVIEW_DATE_FORMAT),
          from: dayjs(new Date()).format(DAYJS_JOB_INTERVIEW_DATE_FORMAT),
        });
        setShowViewOptions(false);
        break;
      case JobInterviewsViewModeTypes.custom:
        formSetValueHook('jobInterviewRequestDate', undefined);
        setShowViewOptions(false);
        break;
      default:
        formSetValueHook(
          'viewMode',
          JobInterviewsViewModeTypes.todayInterviews
        );
        setShowViewOptions(false);
        break;
    }
  }, [currentViewMode, formSetValueHook, clearPreviousFilters]);

  return (
    <div className="relative" ref={standardViewsButtonRef}>
      <button
        type="button"
        className="bg-internal-main rounded text-white p-2 flex flex-row items-center gap-2"
        onClick={() => setShowViewOptions((prev) => !prev)}
      >
        Standard Views
        <DownArrow
          className={`w-3 transition-transform ${
            showViewOptions && 'rotate-180'
          }`}
        />
      </button>
      {showViewOptions && (
        <div className="absolute min-w-fit mt-2 bg-white rounded overflow-hidden p-4 flex flex-col border shadow z-20">
          {DEFAULT_VIEW_MODES.map((name) => {
            return (
              <label
                htmlFor={name}
                key={name}
                className="flex min-w-fit whitespace-nowrap border-b last:border-b-0 py-2 first:pt-0 last:pb-0"
              >
                <input
                  type="radio"
                  id={name}
                  {...formRegisterHook('viewModes')}
                  value={name}
                  className="mr-2"
                  defaultChecked={
                    currentViewMode === undefined &&
                    name === JobInterviewsViewModeTypes.todayInterviews
                  }
                />
                {name}
              </label>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default JobInterviewViewsFilterButton;
