import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
import classes from './EmployerLayout.module.css';
import { useAppSelector } from '../../hooks';

export type AddNewPositionButtonProps = {
  onClick: () => void;
};

const AddNewPositionButton: FC<AddNewPositionButtonProps> = ({ onClick }) => {
  const [t] = useTranslation('global');
  const { serverInternal } = useAppSelector((state) => state.auth);
  const backgroundColor = serverInternal
    ? 'var(--primary-green)'
    : 'var(--brand-blue-100)';

  return (
    <div
      className={classes.NewJob}
      role="button"
      style={{
        backgroundColor,
      }}
      data-testid="add-new-position-button"
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
    >
      <p className={classes.NewJobText}>{t('EmployerDashboard.AddNewJob')}</p>
      <AddIcon />
    </div>
  );
};

export default AddNewPositionButton;
