import { Auth0DecodedHash, Auth0Result, Auth0UserProfile } from 'auth0-js';
import ICombinedResponse from '../types/combinedResponse.d';
import CandidateEntity from '../types/entities/candidate.entity.d';
import EmployerDto from '../types/serverTypes/employerDto';
import { UserDto } from '../types/serverTypes/userDto';
import { InternalEntity } from '../types/entities/internal.entity';

export const combineResponsesCandidate = (
  auth0Response: Auth0DecodedHash | Auth0Result | Auth0UserProfile,
  serverUserResponse: UserDto,
  serverCandidateResponse?: CandidateEntity
): ICombinedResponse => ({
  auth0Response,
  serverUserResponse: {
    ...serverUserResponse,
    Candidate: serverCandidateResponse,
  },
  serverCandidateResponse: serverCandidateResponse ?? null,
  serverEmployerResponse: null,
  serverInternalResponse: null,
});

export const combineResponsesEmployer = (
  auth0Response: Auth0DecodedHash | Auth0Result | Auth0UserProfile,
  serverUserResponse: UserDto,
  serverEmployerResponse: EmployerDto
): ICombinedResponse => ({
  auth0Response,
  serverUserResponse: {
    ...serverUserResponse,
    Employer: serverEmployerResponse,
  },
  serverEmployerResponse,
  serverCandidateResponse: null,
  serverInternalResponse: null,
});

export const combineResponsesInternal = (
  auth0Response: Auth0DecodedHash | Auth0Result | Auth0UserProfile,
  serverUserResponse: UserDto,
  serverInternalResponse: InternalEntity
): ICombinedResponse => ({
  auth0Response,
  serverUserResponse: {
    ...serverUserResponse,
    Internal: serverInternalResponse,
  },
  serverEmployerResponse: null,
  serverCandidateResponse: null,
  serverInternalResponse,
});

export default combineResponsesCandidate;
