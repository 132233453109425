import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  SaveWorkHistoryInput,
  SaveWorkHistoryOutput,
  WorkExperienceBuilder,
} from '../work-experience.types';

export const saveWorkExperiences = (builder: WorkExperienceBuilder) =>
  builder.mutation<SaveWorkHistoryOutput, SaveWorkHistoryInput>({
    query: ({ data }) => ({
      url: `/candidate-experience/add-range`,
      method: 'POST',
      body: data.map((o) => ({
        Id: o?.Id!,
        JobPosition: o?.JobPosition!,
        JobTitle: o?.JobTitle.val!,
        Company: o?.Company.val!,
        Country: o?.Country.val,
        Admission: o?.Admission.val!,
        Description: o?.Description.val!,
        Egress: o?.Egress.val === '' ? null : o?.Egress.val,
      })),
    }),
    invalidatesTags: [{ type: 'workExperience', id: 'LIST' }],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setWorkHistoryAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
