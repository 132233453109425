import { errorHandler } from '../../../utils';
import {
  FormInfoBuilder,
  GetFileCategoriesInput,
  GetFileCategoriesOutput,
} from '../form-info.types';

export const getFileCategories = (builder: FormInfoBuilder) =>
  builder.query<GetFileCategoriesOutput, GetFileCategoriesInput>({
    query: () => ({
      url: '/file-categories',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      },
    }),
    keepUnusedDataFor: 240,
    transformErrorResponse: (error, meta, args) => {
      const { t } = args;
      errorHandler(
        '[fetchFileCategoriesAction]',
        t('errorMessages.getInfoFail'),
        error,
        true
      );
      return error.data;
    },
  });
