// WARN: THIS COMPONENT IS DISPLAYED FOR GUESTS MODE

import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { PositionDTOasGuest } from '$types/serverTypes/positionDto';
import classes from './PositionDetailsCard.module.css';
import PositionInternalDto from '../../../../types/serverTypes/positionInternalDto';

interface Props {
  position: PositionInternalDto | PositionDTOasGuest | undefined;
  candidatesCount: number;
  isGuest?: boolean;
}

const PositionDetailsCard: FC<Props> = ({
  position,
  candidatesCount,
  isGuest,
}) => {
  const [t] = useTranslation('global');

  return (
    <div
      className={classes.PositionDetailsCardContainer}
      data-testid="positionDetails"
    >
      <div className={classes.PositionDetailsCardHeader}>
        {!isGuest && position && 'Company' in position && (
          <div>{position?.Company?.Name}</div>
        )}
        <div className={classes.PositionDetailsCompanyPosition}>
          <div className={classes.PositionDetailsTitle}>
            {position?.JobPosition?.title || position?.JobPosition?.Title}
          </div>
          {!isGuest && (
            <div className={classes.PositionDetailsCandidatesContainer}>
              {`${candidatesCount ?? 0} ${t('SimeraInternal.Candidates')}`}
            </div>
          )}
        </div>
      </div>
      {!isGuest && (
        <div className={classes.PositionDetailsSkillsContainer}>
          <p className={classes.TextSkill}>Skills</p>
          <div className={classes.PositionDetailsSkillsList}>
            {position &&
            'Skills_position' in position &&
            position.Skills_position
              ? position.Skills_position.map((skill) => (
                  <div className={classes.PositionDetailsSkill} key={skill.Id}>
                    {skill.Title}
                  </div>
                ))
              : undefined}
          </div>
        </div>
      )}
      {!isGuest &&
        position &&
        'ExperienceYears' in position &&
        'SalaryLowerBound' in position &&
        'SalaryUpperBound' in position && (
          <div className={classes.PositionDetailsInfoContainer}>
            <div className={classes.PositionDetailsInfoItem}>
              <p
                className={classes.PositionDetailsInfoItemText}
              >{`${position?.ExperienceYears} years+`}</p>
              &nbsp;
              <p>{t('EmployerPositions.Experience')}</p>
            </div>
            <div className={classes.PositionDetailsInfoItem}>
              <p
                className={classes.PositionDetailsInfoItemText}
              >{`$${position?.SalaryLowerBound} to $${position?.SalaryUpperBound}`}</p>
            </div>
            {position && position.DegreeName ? (
              <div className={classes.PositionDetailsInfoItem}>
                <p
                  className={classes.PositionDetailsInfoItemText}
                >{`${position.DegreeName}`}</p>
              </div>
            ) : undefined}
          </div>
        )}
    </div>
  );
};

PositionDetailsCard.defaultProps = {
  isGuest: false,
};

export default PositionDetailsCard;
