import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { segmentTrack } from '$utils/handleSegment';
import getRouteTypeFromLocalStorage from '$utils/getRouteTypeFromLocalStorage';
import { useGetShare } from '../../hooks/useShare';
import RouteTypes from '../../types/routeTypes';
import Spinner from '../Spinner/Spinner';

const secondsToMinutes = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const leftOverSecs = Math.floor(seconds % 60);
  const formattedSeconds =
    leftOverSecs < 10 ? `0${leftOverSecs}` : leftOverSecs;
  return `${minutes}:${formattedSeconds}`;
};

const percentageViewedBeforePause = (pausedTime: number, duration: number) => {
  return ((pausedTime * 100) / duration).toFixed(2);
};

const ShareVideo = () => {
  const { shareReferenceId } = useParams();
  const videoRef = useRef(null);
  const { data, loadingShare } = useGetShare(shareReferenceId || '');
  const type = getRouteTypeFromLocalStorage();

  const handlePlayWithSegmentTrack = (
    event: React.SyntheticEvent<HTMLVideoElement>
  ) => {
    const videoElement = event.target as HTMLVideoElement;
    if (Math.trunc(videoElement.currentTime) === 0) {
      segmentTrack('Share Video Content Started', {
        duration: secondsToMinutes(videoElement.duration),
        title: data?.Name,
        video_id: data?.AdditionalVideoId,
        video_type: 'AdditionalVideo',
      });
    } else if (
      videoElement.currentTime > 0 &&
      videoElement.currentTime < videoElement.duration
    ) {
      segmentTrack('Share Video Content Unpaused', {
        duration: secondsToMinutes(videoElement.duration),
        title: data?.Name,
        video_id: data?.AdditionalVideoId,
        video_type: 'AdditionalVideo',
      });
    }
  };

  const handlePauseWithSegmentTrack = (
    event: React.SyntheticEvent<HTMLVideoElement>
  ) => {
    const videoElement = event.target as HTMLVideoElement;
    if (!videoElement.ended) {
      segmentTrack('Share Video Content Paused', {
        duration: secondsToMinutes(videoElement.duration),
        playback_progress: percentageViewedBeforePause(
          videoElement.currentTime,
          videoElement.duration
        ),
        title: data?.Name,
        video_id: data?.AdditionalVideoId,
        video_type: 'AdditionalVideo',
      });
    }
  };

  const handleEndedWithSegmentTrack = (
    event: React.SyntheticEvent<HTMLVideoElement>
  ) => {
    const videoElement = event.target as HTMLVideoElement;
    segmentTrack('Share Video Content Completed', {
      duration: secondsToMinutes(videoElement.duration),
      title: data?.Name,
      video_id: data?.AdditionalVideoId,
      video_type: 'AdditionalVideo',
    });
  };

  if (loadingShare) {
    return <Spinner />;
  }

  if (!data || data?.ContentType !== 'video') {
    return null;
  }

  return (
    <div className="sm:bg-white flex flex-1 sm:mx-[30px] lg:mx-[60px] sm:my-[30px] z-0">
      <div className="w-full px-5 sm:px-[30px] lg:px-0 lg:w-[800px] py-5 sm:py-[30px] flex flex-col mx-auto">
        <p className="text-[14px]">
          by{' '}
          <span
            className={`font-semibold ${
              type === RouteTypes.INTERNAL
                ? 'text-brand-green10'
                : 'text-brand-blue100'
            }`}
          >
            {data.Candidate?.split(' ')?.[0] || ''}{' '}
            {data.Candidate?.split(' ')?.[1]?.[0] || ''}
          </span>
        </p>
        <p
          className={`text-[22px] sm:text-[26px] pb-1 font-bold ${
            type === RouteTypes.INTERNAL
              ? 'text-brand-green10'
              : 'text-brand-blue100'
          }`}
        >
          {data.Name}
        </p>
        <video
          ref={videoRef}
          onPlay={handlePlayWithSegmentTrack}
          onPause={handlePauseWithSegmentTrack}
          onEnded={handleEndedWithSegmentTrack}
          controls
          className="w-full lg:h-[560px] pb-3"
        >
          <track kind="captions" />
          <source src={data.Url} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default ShareVideo;
