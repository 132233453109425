import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditableContainerInternal from '../EditableContainer/EditableContainerInternal';
import WorkSummaryReadOnly from '../ReadOnly/WorkSummaryReadOnly';
import WorkSummaryFormContainer from '../WorkSummaryForm/WorkSummaryFormContainer';
import { Candidate } from '../../types/candidates';

interface Props {
  isActive: boolean;
  secondary: boolean;
  candidate: Candidate;
}

const WorkSummaryEditableContainer: FC<Props> = ({
  isActive,
  secondary,
  candidate,
}) => {
  const [t] = useTranslation('global');
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);

  const onClickPencil = () => {
    setIsEdit(false);
  };
  const handleCancel = () => {
    setShow(true);
  };

  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    setShow(false);
  };

  return (
    <EditableContainerInternal
      title={t('workHistory.aboutMe')}
      isEditable={secondary && isEdit}
      onClickPencil={onClickPencil}
      addMore={false}
      isActive={isActive}
    >
      {!isEdit ? (
        <WorkSummaryFormContainer
          setIsEdit={setIsEdit}
          onClickCancel={handleCancel}
          onClickCancelModal={handleCancelModal}
          show={show}
          candidate={candidate}
        />
      ) : (
        candidate.WorkSummary && (
          <WorkSummaryReadOnly
            workExperienceDescription={candidate?.WorkSummary}
          />
        )
      )}
    </EditableContainerInternal>
  );
};

export default WorkSummaryEditableContainer;
