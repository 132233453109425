import React, { CSSProperties, FC, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import useMeasure from 'react-use-measure';
import { ReactComponent as ArrowDown } from '../../../../../icons/ArrowDown.svg';
import classes from './NotesCollapsibleComponent.module.css';

interface Props {
  children: React.ReactNode;
  title: string;
}

const NotesCollapsibleComponent: FC<Props> = ({ children, title }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [ref, bounds] = useMeasure();

  const togglePanel = () => {
    setIsCollapsed((prev) => !prev);
  };
  const panelContentAnimatedStyle = useSpring<CSSProperties>({
    height: isCollapsed ? '0px' : `${bounds.height}px`,
  });

  const iconAnimatedStyle = useSpring({
    transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
  });

  return (
    <div className={classes.Panel}>
      <div
        className={classes.PanelHeading}
        onClick={togglePanel}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            togglePanel();
          }
        }}
        tabIndex={0}
        role="button"
      >
        {title}
        <animated.div style={iconAnimatedStyle}>
          <ArrowDown />
        </animated.div>
      </div>
      <animated.div
        className={classes.PanelContent}
        style={panelContentAnimatedStyle}
      >
        <div className={classes.PanelContentInner} ref={ref}>
          {children}
        </div>
      </animated.div>
    </div>
  );
};

export default NotesCollapsibleComponent;
