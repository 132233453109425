import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FilterType, {
  parseFilterType,
} from '../../../../constants/InternalFIlterTypes';
import ChipFilter from '../../CandidatesPositionPage/ChipGroup/ChipFilter';
import classes from './InternalChipGroup.module.css';

interface InternalChipGroupProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const InternalChipGroup: FC<InternalChipGroupProps> = ({ setPage }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [term, setTerm] = useState(FilterType.All);

  useEffect(() => {
    const searchTerm = searchParams.get('term');
    const parsedSearchTerm = parseFilterType(searchTerm);

    if (searchTerm !== parsedSearchTerm) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('term', parsedSearchTerm);
      setSearchParams(newSearchParams);
    }
    if (parsedSearchTerm !== term) {
      setTerm(parsedSearchTerm);
    }
  }, [searchParams, setSearchParams, term]);

  const handleOnChange = (activeValue: FilterType) => {
    setPage(1);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('term', activeValue);
    setSearchParams(newSearchParams);
  };

  return (
    <div className={classes.wrapper} data-testid="internal-chipGroup">
      <ChipFilter
        active={term}
        onChange={handleOnChange}
        label="All"
        activeValue={FilterType.All}
        secondary
      />
      <ChipFilter
        active={term}
        onChange={handleOnChange}
        label="Open"
        activeValue={FilterType.Open}
        secondary
      />
      <ChipFilter
        active={term}
        onChange={handleOnChange}
        label="Closed"
        activeValue={FilterType.Closed}
        secondary
      />
      <ChipFilter
        active={term}
        onChange={handleOnChange}
        label="Deactivated"
        activeValue={FilterType.Deactivated}
        secondary
      />
    </div>
  );
};

export default InternalChipGroup;
