// WARN: THIS COMPONENT IS DISPLAYED FOR GUESTS MODE

import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalInTailwind from '$components/ModalInTailwind/ModalInTailwind';
import {
  StatusCandidatePositionOptions,
  candidatesApi,
  useUpdateCandidatePositionStatusMutation,
} from '$store/modules/candidates';
import { segmentTrack } from '$utils/handleSegment';
import { useGetCandidateJobInterestQuery } from '$store/modules/candidate-job-interest';
import { userLanguage } from '$translations/index';
import { JobInterest } from '$types/user_info';
import MakeOfferToCandidateDto from '$types/serverTypes/makeOfferToCandidateDto';
import classes from './CandidateSummmaryCardEmployers.module.css';
import SummaryCardHeader from './SummaryCardHeader/SummaryCardHeader';
import ActiveValues from '../../../../../constants/ActiveValues';
import CandidateFeedBackModal from '../../CandidateFeedBackModal/CandidateFeedBackModal';
import UpdateToOfferSentConfirmModal from '../UpdateToOfferSentConfirmModal/UpdateToOfferSentConfirmModal';
import { companiesApi } from '../../../../../store/modules/companies';
import { useAppSelector } from '../../../../../hooks';
import {
  CandidateData,
  CandidatesPositionStatusOnGuestMode,
} from '../../../../../types/candidates';
import computeLastJob from '../../../../../utils/computeLastJob';
import { CalendarModal } from '../../../../Employers/CalendarModal/CalendarModal';
import EmployerTakeActionsBtn from '../../EmployerActionsBtn/EmployerTakeActionsBtn';
import ModalCandidateActions from './ModalCandidateActions/ModalCandidateActions';
import AttachmentSummary from '../../../AttachmentsSummary/AttachmentsSummary';
import ReactionBox from '../../ReactionBox/ReactionBox';

interface Props {
  candidate: CandidateData | CandidatesPositionStatusOnGuestMode;
  statusId: number;
  status: string | undefined;
  createTime: Date;
  activeValue: ActiveValues;
  candidatesPositionStatusArrayIndex: number;
  existingOffer: boolean;
  setSwitchingId: Dispatch<SetStateAction<number | null>>;
  isGuest?: boolean;
  position: any;
}

const CandidateSummaryCardEmployers: FC<Props> = ({
  candidate,
  statusId,
  status,
  createTime,
  activeValue,
  candidatesPositionStatusArrayIndex,
  existingOffer,
  setSwitchingId,
  isGuest,
  position,
}) => {
  const { positionId: positionIdFromParam } = useParams();
  const positionId = (
    positionIdFromParam ? Number(positionIdFromParam) : position.Id
  ) as number;
  const [showMakeOfferModal, setMakeOfferModal] = useState<boolean>(false);
  const [modalShortlist, setModalShortlist] = useState(false);
  const [modalRejectCandidate, setModalRejectCandidate] = useState(false);
  const [showModalConfirmationInterview, setShowModalConfirmationInterview] =
    useState<boolean>(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const dispatch = useDispatch();

  const [updateCandidatePositionStatus] =
    useUpdateCandidatePositionStatusMutation();

  const { data: jobInterest } = useGetCandidateJobInterestQuery(
    {
      candidateId: candidate.Id,
      lang: userLanguage,
    },
    { skip: isGuest }
  );
  const { serverUser } = useAppSelector((state) => state.auth);
  const [lastJob, setLastJob] = useState<{
    title: string;
    company: null | string;
  }>({
    title: '',
    company: null,
  });
  const [t, i18n] = useTranslation('global');

  useEffect(() => {
    setMakeOfferModal(false);
  }, [candidate]);

  const handleMakeOfferModal = (show: boolean) => setMakeOfferModal(show);

  const interviewAgainHandler = () => {
    setShowModalConfirmationInterview(true);
  };

  const changeCandidatePositionStatusHandler = (
    newStatus: StatusCandidatePositionOptions
  ) => {
    if ('CandidatesPositionStatus' in candidate) {
      if (!candidate.CandidatesPositionStatus) return;
      updateCandidatePositionStatus({
        data: {
          CandidatePositionStatusId: candidate.CandidatesPositionStatus[0].Id,
          Status: newStatus,
        },
        lang: i18n.language as 'en' | 'es',
      }).then(() => {
        setSwitchingId(candidate.Id);
        dispatch(candidatesApi.util.invalidateTags(['Candidates']));
        dispatch(companiesApi.util.invalidateTags(['PositionsList']));
        if (newStatus === 'new') {
          segmentTrack(`Candidate Removed from Shortlist`, {
            candidate_id: candidate.Id,
            position_id: positionId,
          });
        } else if (newStatus === 'shortlisted') {
          segmentTrack(`Candidate Added to Shortlist`, {
            candidate_id: candidate.Id,
            position_id: positionId,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (candidate?.CandidateWorkExperience?.length) {
      const lj = computeLastJob(candidate.CandidateWorkExperience);
      setLastJob(lj);
    } else {
      setLastJob({
        title: 'N/A',
        company: null,
      });
    }
  }, [candidate.CandidateWorkExperience]);

  const mappedSkills = position?.Skills_position?.map(
    (skill: any) => skill.Title
  );

  return (
    <li
      className={`flex ${isGuest ? 'flex-col sm:flex-row' : 'gap-4'} ${
        classes.CandidateCard
      }`}
    >
      <UpdateToOfferSentConfirmModal
        modalIsOpen={showMakeOfferModal}
        handleClose={handleMakeOfferModal}
        makeOfferToCandidateDto={
          {
            candidatePositionStatusId: statusId,
            candidatesPositionStatusArrayIndex,
            currentCandidateFilter: activeValue,
            user: {
              name: `${serverUser?.FirstName} ${serverUser?.LastName}`,
              email: serverUser?.Email!,
            },
          } as MakeOfferToCandidateDto
        }
      />
      <div className={classes.leftContentEmployee}>
        {/* TODO: refactor this component */}
        <SummaryCardHeader
          isGuest={isGuest}
          jobInterest={
            // eslint-disable-next-line no-nested-ternary
            isGuest
              ? {
                  Salary: [
                    candidate.CandidateJobInterest?.SalaryLowerBound,
                    candidate.CandidateJobInterest?.SalaryUpperBound,
                  ],
                }
              : jobInterest
              ? (jobInterest as JobInterest)
              : { Salary: [] }
          }
          firstName={candidate.User?.FirstName}
          lastName={candidate.User?.LastName}
          createTime={createTime}
          candidateId={candidate.Id}
          positionId={positionId}
          status={status}
          score={'Score' in candidate ? candidate?.Score : 0}
          activeValue={activeValue}
          statusId={statusId}
          imgUrl={candidate.User?.ProfilePicture?.Url}
          lastJob={lastJob}
          yearsOfExperience={candidate.YearsOfExperience}
          candidateInterviewStatus={
            'CandidateInterviewStatus' in candidate
              ? candidate?.CandidateInterviewStatus
              : undefined
          }
          actionButtons={
            <div className={classes.Action}>
              {'CandidatesPositionStatus' in candidate &&
                candidate.CandidatesPositionStatus &&
                candidate.CandidatesPositionStatus.length > 0 && (
                  <EmployerTakeActionsBtn
                    positionId={positionId}
                    activeValue={activeValue}
                    status={status}
                    interviewAgainHandler={interviewAgainHandler}
                    candidatePositionStatusId={
                      candidate.CandidatesPositionStatus[0].Id
                    }
                    existingOffer={existingOffer}
                    setSwitchingId={() => {
                      setSwitchingId(candidate.Id);
                    }}
                    candidateId={candidate.Id}
                  />
                )}
              <button
                data-testid="reject-button"
                type="button"
                onClick={() => setIsFeedbackModalOpen(true)}
                className={classes.Reject}
              >
                {t('CandidatePositionSummaryCard.Reject')}
              </button>
            </div>
          }
        />
        <hr className={classes.divider} />
        <div className={classes.ContentContainer}>
          <div className={classes.ContentSkills}>
            {candidate.CandidateSkills &&
              candidate.CandidateSkills.length > 0 && (
                <>
                  <p className={classes.TopSkills}>
                    {t('CandidatePositionSummaryCard.Topskills')}:
                  </p>
                  <div className={classes.ListSkills}>
                    {candidate.CandidateSkills?.slice(0, 6).map((skill) => (
                      <div
                        key={skill.Id}
                        className={`${classes.Chip} ${
                          mappedSkills?.includes(skill.Skill?.Title)
                            ? classes.ChipsSkillsEmployeeActive
                            : classes.ChipsSkillsEmployee
                        }`}
                      >
                        <p className={classes.ChipText}>
                          {skill?.Skill?.Title}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              )}
          </div>
          {!isGuest && (
            <ReactionBox
              candidateId={candidate.Id}
              positionId={positionId}
              status={status}
              activeValue={activeValue}
              setModalShortlist={setModalShortlist}
              setModalRejectCandidate={setModalRejectCandidate}
            />
          )}
        </div>
        <AttachmentSummary
          candidate={candidate}
          positionId={positionId}
          isGuest={isGuest}
        />
        {!isGuest && (
          <div className={classes.ActionMobile}>
            {'CandidatesPositionStatus' in candidate &&
              candidate?.CandidatesPositionStatus &&
              candidate?.CandidatesPositionStatus?.length > 0 && (
                <EmployerTakeActionsBtn
                  positionId={positionId}
                  activeValue={activeValue}
                  status={status}
                  interviewAgainHandler={interviewAgainHandler}
                  candidatePositionStatusId={
                    candidate.CandidatesPositionStatus[0].Id
                  }
                  existingOffer={existingOffer}
                  setSwitchingId={() => {
                    setSwitchingId(candidate.Id);
                  }}
                  candidateId={candidate.Id}
                />
              )}
            <button
              data-testid="reject-button"
              type="button"
              onClick={() => setIsFeedbackModalOpen(true)}
              className={classes.Reject}
            >
              {t('CandidatePositionSummaryCard.Reject')}
            </button>
          </div>
        )}
      </div>
      <ModalInTailwind
        isOpen={modalShortlist}
        closeModal={() => setModalShortlist(false)}
      >
        <ModalCandidateActions
          closeModal={() => setModalShortlist(false)}
          img={candidate.User?.ProfilePicture?.Url}
          candidateFirstName={candidate.User?.FirstName}
          candidateLastName={candidate.User?.LastName}
          lastJob={lastJob}
          score={'Score' in candidate ? candidate.Score : 0}
          handleOk={() => {
            changeCandidatePositionStatusHandler('shortlisted');
            setModalShortlist(false);
          }}
          type="shortlist"
        />
      </ModalInTailwind>
      <ModalInTailwind
        isOpen={modalRejectCandidate}
        closeModal={() => setModalRejectCandidate(false)}
      >
        <ModalCandidateActions
          closeModal={() => setModalRejectCandidate(false)}
          img={candidate.User?.ProfilePicture?.Url}
          candidateFirstName={candidate.User?.FirstName}
          candidateLastName={candidate.User?.LastName}
          lastJob={lastJob}
          score={'Score' in candidate ? candidate.Score : 0}
          handleOk={() => {
            setIsFeedbackModalOpen(true);
            setModalRejectCandidate(false);
          }}
          type="reject"
        />
      </ModalInTailwind>
      <ModalInTailwind
        isOpen={showModalConfirmationInterview}
        closeModal={() => setShowModalConfirmationInterview(false)}
      >
        <ModalCandidateActions
          closeModal={() => setShowModalConfirmationInterview(false)}
          img={candidate.User?.ProfilePicture?.Url}
          candidateFirstName={candidate.User?.FirstName}
          candidateLastName={candidate.User?.LastName}
          lastJob={lastJob}
          score={'Score' in candidate ? candidate.Score : 0}
          handleOk={() => {
            setIsCalendarModalOpen(true);
            setShowModalConfirmationInterview(false);
          }}
          type="interview"
        />
      </ModalInTailwind>
      {isCalendarModalOpen && 'CandidatesPositionStatus' in candidate && (
        <CalendarModal
          candidateId={candidate.Id}
          setIsOpen={setIsCalendarModalOpen}
          isOpen={isCalendarModalOpen}
          setSwitchingId={setSwitchingId}
          candidatePositionStatusId={
            candidate?.CandidatesPositionStatus?.[0]?.Id
          }
        />
      )}
      <CandidateFeedBackModal
        candidateId={candidate.Id}
        isOpen={isFeedbackModalOpen}
        setIsOpen={setIsFeedbackModalOpen}
        setSwitchingId={setSwitchingId}
      />
    </li>
  );
};

CandidateSummaryCardEmployers.defaultProps = {
  isGuest: false,
};

export default CandidateSummaryCardEmployers;
