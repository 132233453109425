import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm, UseFormSetValue } from 'react-hook-form';
import classes from './LanguageEditableContainer.module.css';
import { SaveState } from '../../types/ForrmType';
import {
  InitialFormStateTypes,
  FormTypes,
  FieldTypes,
} from '../../types/user_info';
import FormFooter from '../FormFooter/FormFooter';
import CancelModal from '../CancelModal/CancelModal';
import SaveButton from '../Buttons/SaveButton';
import LanguagesForm from '../SkillsFormContainer/LanguagesForm';
import {
  useAddCandidateLanguageInternalMutation,
  useAddCandidateLanguagesMutation,
} from '../../store/modules/candidate-language';
import { errorHandler, successHandler } from '../../store/utils';
import { useAppDispatch } from '../../hooks';
import { candidatesApi } from '../../store/modules/candidates';

const MAX_LANGUAGES = 5;
interface LanguageProps {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean, continueEditing: boolean) => void;
  onClickSave: () => void;
  show?: boolean;
  newLanguage?: boolean;
  countForms: number;
}

const NewLanguage: FC<LanguageProps> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  onClickSave,
  show,
  newLanguage,
  countForms,
}) => {
  const [t, i18next] = useTranslation('global');
  const [Modal, setModal] = useState<boolean>(false);
  const [delId, setDelId] = useState();
  const { candidateId } = useParams();
  const dispatch = useAppDispatch();

  const [
    saveLanguageInternal,
    { isLoading: isLoadingInternal, isSuccess: isSucessInternal },
  ] = useAddCandidateLanguageInternalMutation();
  const [saveLanguage, { isLoading, isSuccess }] =
    useAddCandidateLanguagesMutation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      LanguageName: { val: '', mult: 0 },
      LanguageProficiency: { val: '', mult: 0 },
    },
  });

  const removeFromLanguages = () => {};

  const saveInfo = async (data: { [x: string]: any }) => {
    if (countForms > MAX_LANGUAGES) {
      errorHandler(
        '[EducationalInformationForm]',
        'Educational information limit reached',
        null,
        true
      );
      return;
    }
    if (data) {
      try {
        if (Number(candidateId)) {
          await saveLanguageInternal({
            candidateId: Number(candidateId),
            languages: [data as FormTypes['languages']],
            lang: i18next.language as 'en' | 'es',
          });
          dispatch(candidatesApi.util.invalidateTags(['Score']));
        } else {
          saveLanguage({
            languages: [data as FormTypes['languages']],
            lang: i18next.language as 'en' | 'es',
          });
          dispatch(candidatesApi.util.invalidateTags(['Score']));
        }
        if (setEdit) {
          setEdit(true);
        }
        if (newLanguage) {
          onClickSave();
        }
        successHandler(t('common.saved'), true);
        window.analytics.track('Languages form submitted');
      } catch (error) {
        // Error
      }
    }
  };

  const language = register(`LanguageName.val`, { required: true });
  const languageProf = register(`LanguageProficiency.val`, { required: true });
  const buttonState = () => {
    if (isDirty) {
      return SaveState.IDLE;
    }
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }

    if (isSuccess || isSucessInternal) {
      return SaveState.FULFILLED;
    }

    return SaveState.IDLE;
  };
  return (
    <div className={classes.content} data-testid="language-content">
      <form data-testid="language-form" onSubmit={handleSubmit(saveInfo)}>
        <LanguagesForm
          errors={errors}
          index={0}
          removeLanguage={removeFromLanguages}
          t={t}
          setValue={
            setValue as UseFormSetValue<
              | InitialFormStateTypes
              | {
                  Id?: number;
                  LanguageName: FieldTypes;
                  LanguageProficiency: FieldTypes;
                  Score?: FieldTypes;
                }
            >
          }
          headerText={{ 0: t('education.first') }}
          language={language}
          languageProf={languageProf}
          row={{ LanguageName: { val: '' } }}
          Id={0}
          delId={delId}
          setDelId={setDelId}
          modal={Modal}
          setModal={setModal}
          removeUnsaved={() => {}}
          newLanguage
        />
        <div className={isEdit ? classes.ButtonContainer : ''}>
          {isEdit ? (
            <FormFooter
              onClickCancel={onClickCancel}
              disabled={buttonState() === SaveState.PENDING || !isDirty}
              saveState={buttonState()}
              translate={t}
              submit
            />
          ) : (
            <SaveButton
              disabled={buttonState() === SaveState.PENDING || !isDirty}
              state={buttonState()}
              t={t}
              submit
            />
          )}
        </div>
      </form>
      {show && onClickCancelModal && (
        <CancelModal onClickCancelModal={onClickCancelModal} />
      )}
    </div>
  );
};

NewLanguage.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  newLanguage: false,
};
export default NewLanguage;
