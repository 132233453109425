import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCandidateId } from '../../hooks/useCandidateId';
import useWindowWidth from '../../hooks/useWindowWidth';
import { ReactComponent as CloseX } from '../../icons/CloseX.svg';
import { ReactComponent as DownloadIcon } from '../../icons/Download.svg';
import { ReactComponent as EditIcon } from '../../icons/EditIcon.svg';
import { ReactComponent as PDFIcon } from '../../icons/PDFIcon.svg';
import { ReactComponent as TrashIcon } from '../../icons/TrashIcon.svg';
import { ReactComponent as ZapIcon } from '../../icons/zap.svg';
import { useGetCandidateEducationQuery } from '../../store/modules/candidate-education';
import { useGetCandidateExtracurricularQuery } from '../../store/modules/candidate-extracurricular';
import { useGetCandidateJobInterestQuery } from '../../store/modules/candidate-job-interest';
import { useGetCandidateLanguagesQuery } from '../../store/modules/candidate-language';
import { useGetCandidateSkillsQuery } from '../../store/modules/candidate-skill';
import { useGetCandidateTechnicalInfoQuery } from '../../store/modules/candidate-technical-info';
import {
  useDeleteCVMutation,
  useGetCvQuery,
  useParseCVMutation,
  useSaveParsedCVMutation,
  useUploadCVMutation,
} from '../../store/modules/uploads';
import { useGetWorkHistoryByCandidateIdQuery } from '../../store/modules/work-experience';
import { errorHandler, successHandler } from '../../store/utils';
import { ResumeParserStatus } from '../../types/resumeParseStatus';
import Button from '../Buttons/Button';
import { ConfirmDeletionResumeModal } from '../Candidates/ConfirmDeletionResumeModal';
import DragDrop from '../DragDrop/DragDrop';
import ProgressBar from '../ProgressBar/ProgressBar';
import Spinner from '../Spinner/Spinner';
import classes from './CVUploadMainContainer.module.css';
import { candidatesApi } from '../../store/modules/candidates';
import { useAppDispatch } from '../../hooks';
import { segmentTrack } from '../../utils/handleSegment';

const CVUploadMainContainer = () => {
  const [Modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isMobile: boolean = useWindowWidth() < 768;
  const [validFile, setAcceptedFile] = useState<File | undefined>(undefined);
  const [status, setStatus] = useState(ResumeParserStatus.UNINITIALIZED);
  const navigate = useNavigate();
  const [t, i18next] = useTranslation('global');
  const [uploadCV, { error, data, isLoading }] = useUploadCVMutation();
  const [parseCV] = useParseCVMutation();
  const [saveParsedCV, { isLoading: saveParsedCVLoading }] =
    useSaveParsedCVMutation();
  const [deleteCV] = useDeleteCVMutation();
  const candidateId = useCandidateId();
  const { data: cv } = useGetCvQuery({ candidateId });
  const dispatch = useAppDispatch();

  useEffect(() => {
    // if there is an error on upload, clear the drop zone
    if (error) setAcceptedFile(undefined);
  }, [error, navigate]);

  const onDeleteCV = async () => {
    setAcceptedFile(undefined);
    await deleteCV({ language: i18next.language, candidateId });
    dispatch(candidatesApi.util.invalidateTags(['Score']));
    segmentTrack(
      'Resume Deleted',
      {
        candidate_id: candidateId,
      },
      ['role']
    );
  };

  const { refetch: refetchWork } = useGetWorkHistoryByCandidateIdQuery(
    {
      lang: i18next.language as 'es' | 'en',
      candidateId,
    },
    { skip: candidateId === 0 }
  );
  const { refetch: refetchTechnicalInfo } = useGetCandidateTechnicalInfoQuery(
    {
      lang: i18next.language as 'es' | 'en',
      candidateId,
    },
    { skip: candidateId === 0 }
  );
  const { refetch: refetchJobInterest } = useGetCandidateJobInterestQuery(
    {
      lang: i18next.language as 'es' | 'en',
      candidateId,
    },
    { skip: candidateId === 0 }
  );

  const { refetch: refetchExtracurricular } =
    useGetCandidateExtracurricularQuery(
      {
        lang: i18next.language as 'es' | 'en',
        candidateId,
      },
      { skip: candidateId === 0 }
    );
  const { refetch: refetchEducation } = useGetCandidateEducationQuery(
    {
      lang: i18next.language as 'es' | 'en',
      candidateId,
    },
    { skip: candidateId === 0 }
  );
  const { refetch: refetchLanguages } = useGetCandidateLanguagesQuery(
    {
      lang: i18next.language as 'es' | 'en',
      candidateId: candidateId!,
    },
    { skip: candidateId === 0 }
  );
  const { refetch: refetchSkills } = useGetCandidateSkillsQuery(
    {
      lang: i18next.language as 'es' | 'en',
      candidateId,
    },
    { skip: candidateId === 0 }
  );
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const acceptedFile = acceptedFiles[0];
      if (acceptedFile) {
        setAcceptedFile(acceptedFile);
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        uploadCV({
          data: formData,
          language: i18next.language,
        }).then((resumeResponse) => {
          if ('data' in resumeResponse)
            segmentTrack('Resume Uploaded', {
              candidate_id: candidateId,
              resume_id: resumeResponse.data.Key,
            });
        });
        setStatus(ResumeParserStatus.PENDING);
        formData.append('candidateId', candidateId.toString());
        parseCV({
          data: formData,
          language: i18next.language,
        })
          .unwrap()
          .then(async (payload) => {
            await saveParsedCV({
              // manually add candidate_id to payload
              data: { ...payload, candidate_id: candidateId },
              language: i18next.language,
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
            refetchWork();
            refetchTechnicalInfo();
            refetchJobInterest();
            refetchExtracurricular();
            refetchEducation();
            refetchLanguages();
            refetchSkills();
            successHandler(t('ParsedResume.parsed'), true, true);
            setStatus(ResumeParserStatus.FINISHED);
          })
          .catch((err) => {
            errorHandler(
              'useParsedResumeEvent',
              t('ParsedResume.failed'),
              new Error(err),
              true,
              true
            );
            setStatus(ResumeParserStatus.FINISHED);
          });
      }
    },
    [
      uploadCV,
      i18next.language,
      candidateId,
      parseCV,
      saveParsedCV,
      dispatch,
      refetchWork,
      refetchTechnicalInfo,
      refetchJobInterest,
      refetchExtracurricular,
      refetchEducation,
      refetchLanguages,
      refetchSkills,
      t,
    ]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    disabled: validFile !== undefined,
    accept: '.pdf',
    onDrop,
  });

  const splitAtStartOfUUID = (inputString: string) => {
    // Regular expression to match UUID pattern at the beginning of the string
    const uuidPattern =
      /^(?:[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;

    // Find the index of the first occurrence of UUID pattern in the string
    const match = inputString.match(uuidPattern);
    const lastIndex = match ? match[0].length : 0;

    // Split the string into two parts based on the UUID pattern index
    const secondPart = inputString.slice(lastIndex);
    const updatedSecondPart = secondPart.substring(1);

    return updatedSecondPart;
  };

  const onDownloadHandler = () => {
    segmentTrack('CV Downloaded', {
      file_id: Number(cv?.Id),
      candidate_id: Number(candidateId),
    });
  };

  return (
    <div data-testid="main-container">
      {cv?.Key ? (
        <>
          {Modal && (
            <ConfirmDeletionResumeModal
              setModal={setModal}
              Delete={onDeleteCV}
            />
          )}
          <div className={classes.ContentContainer}>
            <div className={classes.ContentHeader}>
              <p className={classes.Tittle}>Resume</p>
              {!isEdit ? (
                <EditIcon
                  className={classes.EditIcon}
                  onClick={() => setIsEdit(true)}
                  fill="#5A5B5E"
                />
              ) : (
                <Button
                  icon={CloseX}
                  iconStyles={{
                    width: '14',
                    height: '14',
                    fill: '#5A5B5E',
                  }}
                  bgColor="transparent"
                  showText={false}
                  onClick={() => setIsEdit(false)}
                  customStyles={{
                    paddingLeft: '1rem',
                    width: 'auto',
                    flexGrow: '0',
                    marginBottom: '0',
                    height: 'auto',
                  }}
                />
              )}
            </div>
            {!isEdit ? (
              <a
                className={classes.link}
                href={cv.Url ?? data?.Url}
                download
                onClick={onDownloadHandler}
                target="_blank"
                rel="noreferrer"
              >
                <PDFIcon className={classes.PdfIcon} />
                <p>{splitAtStartOfUUID(cv?.Key ?? '')}</p>
                <DownloadIcon className={classes.CheckIcon} />
              </a>
            ) : (
              <div className={classes.link}>
                <PDFIcon className={classes.PdfIcon} />
                <p>{splitAtStartOfUUID(cv?.Key ?? '')}</p>
                <TrashIcon
                  className={classes.Trash}
                  onClick={() => setModal(true)}
                  aria-hidden
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={classes.CardContainer}>
          <div className={classes.AutofillContainer}>
            <div className={classes.AutofillInfo}>
              <ZapIcon />
              <p>{t('Resume.autofill')}</p>
            </div>
            <div>{t('Resume.autofillInfo')}</div>
          </div>
          {validFile ? (
            <div className={classes.DragUploadedContainer}>
              <ProgressBar fileName={validFile.name} fileUploadProgress={100} />
              <p className={classes.Info}>{t('Resume.uploaded')}</p>
            </div>
          ) : (
            <div className={classes.DragMainContainer}>
              <div className={`${isMobile && classes.DragContainer}`}>
                <DragDrop
                  isMobile={isMobile}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  isDragActive={isDragActive}
                  supportedFilesText={t('cv.supported')}
                />

                {isMobile && (
                  <div className={classes.CenterDiv}>
                    <Button
                      bgColor="#05668D"
                      text={t('Resume.uploadMobile')}
                      showText
                      size="80%"
                      onClick={open}
                      customStyles={{
                        height: '36px',
                        width: 'auto',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                )}
              </div>

              {isMobile ? (
                <p className={classes.MobileInfo}>{t('Resume.footer')}</p>
              ) : (
                <p className={classes.Info}>{t('Resume.footer')}</p>
              )}
            </div>
          )}
        </div>
      )}
      {isLoading ||
        saveParsedCVLoading ||
        (status === ResumeParserStatus.PENDING && (
          <div className={classes.Spinner}>
            <div>
              <Spinner height="100%" width="100%" />
            </div>
            <div className={classes.SpinnerText}>
              <p>{t('Resume.spinnerText1')}</p>
              <p>{t('Resume.spinnerText2')}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CVUploadMainContainer;
