import { useState, useEffect } from 'react';
import HandleHomePage from './HandleHomePage';
import NewSplashPageModal from './NewSplashPageModal/NewSplashPageModal';
// import useSplashPage from '../../../hooks/useSplashPage';
import { useCandidateId } from '../../../hooks/useCandidateId';
import { useGetCandidateInfoQuery } from '../../../store/modules/candidates';

interface HandleHomePageProps {
  forceProfile?: boolean;
}
const HandleHomePagePrevius = ({ forceProfile }: HandleHomePageProps) => {
  const candidateId = useCandidateId();
  const { data } = useGetCandidateInfoQuery(candidateId);
  const [isOpenSplash, setIsOpenSplash] = useState(false);
  // Session storage to open the modal just once in the session
  const isModalDisplayed =
    sessionStorage.getItem('isModalDisplayed') === 'true';
  useEffect(() => {
    if (data && !isModalDisplayed) {
      setIsOpenSplash(!data.Completed);
    }
  }, [data, isModalDisplayed]);

  return (
    <>
      <HandleHomePage
        forceProfile={forceProfile}
        setIsOpenSplash={setIsOpenSplash}
      />
      <NewSplashPageModal
        modalIsOpen={isOpenSplash}
        setNewSplashPageModal={setIsOpenSplash}
        candidateId={candidateId}
      />
    </>
  );
};

HandleHomePagePrevius.defaultProps = {
  forceProfile: false,
};

export default HandleHomePagePrevius;
