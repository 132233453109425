import React from 'react';
import classes from './VideoSection.module.css';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}
export const VideoSection = ({ children }: Props) => {
  return (
    <div className={`${classes.container} items-center`}>
      <div className="container">{children}</div>
    </div>
  );
};
