import ICombinedResponse from '../types/combinedResponse.d';

const saveLocalStorage = (CombinedResponse: ICombinedResponse) => {
  localStorage.setItem(
    'Auth0User',
    JSON.stringify(CombinedResponse.auth0Response)
  );
  localStorage.setItem(
    'ServerUserResponse',
    JSON.stringify(CombinedResponse.serverUserResponse)
  );
  if (CombinedResponse.serverCandidateResponse) {
    localStorage.setItem(
      'ServerCandidateResponse',
      JSON.stringify(CombinedResponse.serverCandidateResponse)
    );
  }

  if (CombinedResponse.serverEmployerResponse) {
    localStorage.setItem(
      'ServerEmployerResponse',
      JSON.stringify(CombinedResponse.serverEmployerResponse)
    );
  }
};

export default saveLocalStorage;
