import dayjs from 'dayjs';
import { TIMEZONES } from '../constants/datetimes';

export const diffOffset = (curr: string, prev: string = TIMEZONES.EASTERN) => {
  const prevOffset = dayjs()
    .tz(prev)
    .format('Z')
    .match(/^([-+]\d{2})/);
  const currOffset = dayjs()
    .tz(curr)
    .format('Z')
    .match(/^([-+]\d{2})/);
  if (prevOffset && currOffset) {
    return +currOffset[0] - +prevOffset[0];
  }
  return 0;
};
