import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AdditionalDocumentsFormCandidate from '../CandidateDetails/AdditionalDocumentsFormCandidate/AdditionalDocumentsFormCandidate';
import { useGetAdditionalDocumentsByCandidateIdQuery } from '../../store/modules/additional-documents';
import AdditionalDocuments from '../CandidateDetails/AdditionalDocuments/AdditionalDocuments';
import { useGetFileCategoriesQuery } from '../../store/modules/form-info';
import classes from './AdditionalDocumentsFormContainer.module.css';
import { useCandidateId } from '../../hooks/useCandidateId';
import { useAppSelector } from '../../hooks';
import { SectionContainer } from '../SectionContainer';

interface Props {
  isActive: boolean;
}

const AdditionalDocumentsFormContainer: FC<Props> = ({ isActive }) => {
  const [t, i18n] = useTranslation('global');
  const { data: fileCategories, isLoading } = useGetFileCategoriesQuery({ t });
  const candidateId = useCandidateId();
  const { data: additionalDocuments } =
    useGetAdditionalDocumentsByCandidateIdQuery({
      lang: i18n.language as 'en' | 'es',
      candidateId: +candidateId!,
    });
  const { Id } = useAppSelector(
    (state: any) => state.auth.serverCandidate ?? {}
  );
  return (
    <>
      <SectionContainer isActive={isActive} title={t('AdditionalDocs.Title')}>
        {fileCategories && (
          <AdditionalDocumentsFormCandidate
            candidateId={Id}
            fileCategories={fileCategories}
          />
        )}
      </SectionContainer>
      <br />
      <SectionContainer isActive={isActive} title="Files">
        {additionalDocuments &&
          fileCategories &&
          additionalDocuments?.length !== 0 &&
          !isLoading && (
            <div className={classes.ReadOnlyContainer}>
              <AdditionalDocuments
                secondary={false}
                fileCategories={fileCategories!}
                data={additionalDocuments}
              />
            </div>
          )}
      </SectionContainer>
    </>
  );
};

export default AdditionalDocumentsFormContainer;
