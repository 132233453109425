import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  SaveParsedCvInput,
  SaveParsedCvOutput,
  UploadBuilder,
} from '../uploads.types';

export const saveParsedCV = (builder: UploadBuilder) =>
  builder.mutation<SaveParsedCvOutput, SaveParsedCvInput>({
    query: (args) => ({
      url: 'parsed-resume',
      method: 'POST',
      body: args.data,
    }),
    invalidatesTags: () => [{ type: 'Upload', id: 'List' }, 'Score'],
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[uploadCVAction]',
        args.language === 'es'
          ? ErrorMessageEs.UploadCV
          : ErrorMessageEn.UploadCV,
        err,
        true
      );
      return err.data;
    },
  });
