import { createApi } from '@reduxjs/toolkit/query/react';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import {
  getAdditionalVideoById,
  getAdditionalVideosByCandidateId,
  getEnglishAssessment,
} from './queries';
import {
  deleteAdditionalVideo,
  sortAdditionalVideos,
  updateAdditionalVideo,
  updateVisibilityAdditionalVideos,
} from './mutations';
import { AdditionalVideoState } from './additional-videos.types';
import { AdditionalVideo } from '../../../types/additional-video/additional-video';
import { Video } from '../uploads';
import { initiateChunkUploadAdditionalVideo } from './mutations/initiate-chunk-upload-additional-video.mutation';
import { getPresignedAdditionalVideoChunkUrl } from './queries/get-additional-video-presigned-url.query';
import { completeChunkUploadAdditionalVideo } from './mutations/complete-chunk-upload-additional-video.mutation';

const initialState: AdditionalVideoState = {
  activeVideo: null,
  activeVideoType: null,
  videos: [],
  videosType: [],
  isModalOpen: false,
};
export const additionalVideosSlice = createSlice({
  name: ReducerPaths.ADDITIONAL_VIDEO_SLICE,
  reducers: {
    setActiveVideo: (state, action: PayloadAction<AdditionalVideo | null>) => {
      state.activeVideo = action.payload;
    },
    setActiveVideoType: (state, action: PayloadAction<Video | null>) => {
      state.activeVideoType = action.payload;
    },
    resetActiveVideo: (state) => {
      state.activeVideo = null;
    },
    resetActiveVideoType: (state) => {
      state.activeVideoType = null;
    },
    updateActiveVideo: (state, action: PayloadAction<string>) => {
      state.activeVideo = {
        ...state.activeVideo!,
        Name: action.payload,
      };
    },
    setVideos: (state, action: PayloadAction<AdditionalVideo[]>) => {
      state.videos = action.payload;
    },
    setVideosType: (state, action: PayloadAction<Video[]>) => {
      state.videosType = action.payload;
    },
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
  },
  initialState,
});

export const additionalVideosApi = createApi({
  reducerPath: ReducerPaths.ADDITIONAL_VIDEO,
  baseQuery,
  tagTypes: ['additionalVideos'],
  endpoints: (builder) => ({
    getAdditionalVideosByCandidateId: getAdditionalVideosByCandidateId(builder),
    getAdditionalVideoById: getAdditionalVideoById(builder),
    deleteAdditionalVideo: deleteAdditionalVideo(builder),
    updateAdditionalVideo: updateAdditionalVideo(builder),
    updateVisibilityAdditionalVideos: updateVisibilityAdditionalVideos(builder),
    initiateChunkUploadAdditionalVideo:
      initiateChunkUploadAdditionalVideo(builder),
    getPresignedAdditionalVideoChunkUrl:
      getPresignedAdditionalVideoChunkUrl(builder),
    completeChunkUploadAdditionalVideo:
      completeChunkUploadAdditionalVideo(builder),
    sortAdditionalVideos: sortAdditionalVideos(builder),
    getEnglishAssessment: getEnglishAssessment(builder),
  }),
});

export const {
  useLazyGetEnglishAssessmentQuery,
  useGetAdditionalVideosByCandidateIdQuery,
  useLazyGetAdditionalVideosByCandidateIdQuery,
  useGetAdditionalVideoByIdQuery,
  useDeleteAdditionalVideoMutation,
  useUpdateAdditionalVideoMutation,
  useUpdateVisibilityAdditionalVideosMutation,
  useInitiateChunkUploadAdditionalVideoMutation,
  useLazyGetPresignedAdditionalVideoChunkUrlQuery,
  useCompleteChunkUploadAdditionalVideoMutation,
  useSortAdditionalVideosMutation,
} = additionalVideosApi;

export const {
  setActiveVideo,
  setActiveVideoType,
  updateActiveVideo,
  resetActiveVideo,
  resetActiveVideoType,
  setVideos,
  setVideosType,
  openModal,
  closeModal,
} = additionalVideosSlice.actions;
