import { CandidateData } from '../../../../types/candidates';

const filterByFiles = (
  singleCandidate: CandidateData,
  searchFileCategory: string[]
) => {
  return searchFileCategory.every((singleSearchedFileCategory: string) => {
    // Filter for videos
    if (singleSearchedFileCategory.toLowerCase() === 'video') {
      return !!singleCandidate?.Videos && singleCandidate?.Videos.length > 0;
    }

    // Apply filter for curriculum
    if (singleSearchedFileCategory.toLowerCase() === 'curriculum') {
      return singleCandidate.Curriculum && singleCandidate.Curriculum.Url;
    }

    // Apply filter for Files categories  (resumen, other assesments, etc)
    return (
      singleCandidate?.Files &&
      singleCandidate?.Files?.some((singleFile) => {
        return (
          singleFile?.FileCategory &&
          singleFile.FileCategory.CategoryName === singleSearchedFileCategory
        );
      })
    );
  });
};

export default filterByFiles;
