import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  RatingBuilder,
  UpdateRatingInput,
  UpdateRatingOutput,
} from '../ratings.types';

export const updateRating = (builder: RatingBuilder) =>
  builder.mutation<UpdateRatingOutput, UpdateRatingInput>({
    query: (options) => ({
      url: `/ratings`,
      method: 'POST',
      body: { ...options.data },
    }),
    invalidatesTags: (result, err, { data }) => [{ type: 'Rating', data }],
    transformResponse: (queryResponse: { data: any }, _, arg) => {
      successHandler(
        arg.lang === 'en'
          ? SuccessMessageEs.VideoRated
          : SuccessMessageEn.VideoRated,
        true
      );
      return queryResponse.data;
    },
    transformErrorResponse: (error, _, arg) => {
      errorHandler(
        '[updateRating]',
        arg.lang === 'en'
          ? ErrorMessageEn.VideoRated
          : ErrorMessageEs.VideoRated,
        error.data,
        true
      );
      return error.data;
    },
  });
