import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CandidateTechnicalInfoBuilder,
  GetCandidateTechnicalInfoInput,
  GetCandidateTechnicalInfoOutput,
} from '../candidate-technical-info.types';

export const getCandidateTechnicalInfo = (
  builder: CandidateTechnicalInfoBuilder
) =>
  builder.query<
    GetCandidateTechnicalInfoOutput,
    GetCandidateTechnicalInfoInput
  >({
    query: ({ candidateId }) => ({
      url: `/candidate-technical-information/${candidateId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            { type: 'CandidateTechnicalInfo', id: result.Id },
            { type: 'CandidateTechnicalInfo', id: 'LIST' },
          ]
        : [{ type: 'CandidateTechnicalInfo', id: 'LIST' }],

    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setTechnicalInformation]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
