import { useRef, useState } from 'react';
import { countriesCodes } from '../../../constants/countriesCodes';
import { ReactComponent as ArrowDown } from '../../../icons/ArrowDown.svg';
import { CountryType } from '../../../types/CountryType';
import classes from './PhoneNumberInput.module.css';

interface Props {
  setDialCode: (args: string) => void;
}

const DEFAULT_COUNTRY = {
  emoji: '🇦🇷',
  name: 'Argentina',
  dialCode: '+54',
};
const COUNTRY_DIVIDER = 'Venezuela';

const PhoneNumberInput = ({ setDialCode }: Props) => {
  const [countryCode, setCountryCode] = useState<CountryType>(
    countriesCodes[0]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [indexCountry, setIndexCountry] = useState(0);
  const countryRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<any>(null);
  const handleCountry = (element: CountryType) => {
    setCountryCode(element);
    setIndexCountry(0);
    setDialCode(element.dial_code);
  };

  return (
    <div className={classes.container}>
      <button
        className={classes.input}
        ref={inputRef}
        type="button"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (countryRef.current) {
              const emoji =
                countryRef.current.children[indexCountry].children[0]
                  .textContent ?? DEFAULT_COUNTRY.emoji;

              const name =
                countryRef.current.children[indexCountry].children[1]
                  .textContent ?? DEFAULT_COUNTRY.name;
              const dialCode =
                countryRef.current.children[indexCountry].children[2]
                  .textContent ?? DEFAULT_COUNTRY.dialCode;
              handleCountry({
                name,
                emoji,
                dial_code: dialCode,
              });
            }
          }
          if (e.key === 'ArrowDown') {
            if (indexCountry < [...countriesCodes].length - 1) {
              setIndexCountry((index) => index + 1);
              if (
                countryRef.current &&
                countryRef.current.children[indexCountry]
              ) {
                const selectedElement = countryRef.current.children[
                  indexCountry
                ] as HTMLElement;
                countryRef.current.scrollTop =
                  selectedElement.offsetTop - countryRef.current.offsetTop;
              }
            }
          }
          if (e.key === 'ArrowUp') {
            if (indexCountry > 0) {
              setIndexCountry((index) => index - 1);
            }
            if (
              countryRef.current &&
              countryRef.current.children[indexCountry]
            ) {
              const selectedElement = countryRef.current.children[
                indexCountry
              ] as HTMLElement;
              countryRef.current.scrollTop =
                selectedElement.offsetTop - countryRef.current.offsetTop;
            }
          }
        }}
      >
        <span>{countryCode.emoji}</span>
        <ArrowDown />
      </button>
      {isOpen && (
        <div className={classes.countriesContainer} ref={countryRef}>
          {countriesCodes.map((country, idx) => (
            <div
              className={`${
                country.name === COUNTRY_DIVIDER && classes.divider
              } ${classes.country} ${idx === indexCountry && classes.active}`}
              key={`${Math.random()}-${country.name}`}
              onClick={() => {
                handleCountry(country);
                setIsOpen(false);
              }}
              aria-hidden
            >
              <span>{country.emoji}</span>
              <span>{country.name}</span>
              <span className={classes.dialCode}>{country.dial_code}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PhoneNumberInput;
