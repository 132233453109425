import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { AdditionalTagResponse } from '../../../../types/candidates';
import { errorHandler } from '../../../utils';
import {
  FormInfoBuilder,
  GetAdditionalTagsInput,
  GetAdditionalTagsOutput,
} from '../form-info.types';

export const getAdditionalTags = (builder: FormInfoBuilder) =>
  builder.query<GetAdditionalTagsOutput, GetAdditionalTagsInput>({
    query: () => ({
      url: '/candidate-additional-tag/',
      method: 'GET',
    }),
    transformResponse: (response: AdditionalTagResponse[]) => {
      return response
        .filter((tag) => !tag.Deprecated)
        .map((tag) => ({
          Id: tag.Id,
          title: tag.Name,
        }));
    },
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[loadAllAdditionalTags]',
        args.lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        err,
        true
      );
    },
  });
