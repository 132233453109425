import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import NotesCollapsibleComponent from '../NotesCollapsibleComponent/NotesCollapsibleComponent';
import PositionNotes from '../PositionNotes/PositionNotes';
import { Note } from '../../../../../types/candidates';

interface Props {
  notes?: Note[];
  positionId: number;
}

const SinglePositionNotes: FC<Props> = ({ notes, positionId }) => {
  const [t] = useTranslation('global');
  return (
    <div>
      <NotesCollapsibleComponent title={t('Notes.GeneralNotes')}>
        <PositionNotes
          title=""
          notes={notes?.filter((note) => !note.Position)}
          positionId={0}
          isGeneralNotes
        />
      </NotesCollapsibleComponent>
      <PositionNotes
        title={t('Notes.Notes')}
        notes={notes?.filter((note) => note.Position?.Id === positionId!)}
        positionId={positionId}
      />
    </div>
  );
};

SinglePositionNotes.defaultProps = {
  notes: [],
};

export default SinglePositionNotes;
