import { useState, useRef, useEffect } from 'react';
import { AdditionalVideo } from '../../../../../../types/additional-video/additional-video';

export const useVideoPlayer = (video: AdditionalVideo) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [video]);

  const handleUpdate = () => {
    setShowUpdateModal(true);
  };
  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  return {
    showUpdateModal,
    showDeleteModal,
    videoRef,
    handleUpdate,
    handleDelete,
    setShowUpdateModal,
    setShowDeleteModal,
  };
};
