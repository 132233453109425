import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import classes from './UpdateCompanyModal.module.css';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: JSX.Element | JSX.Element[];
}

const UpdateCompanyModal: FC<Props> = ({ isOpen, setIsOpen, children }) => {
  const [t] = useTranslation('global');
  Modal.setAppElement(document.getElementById('root') as HTMLElement);

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
      overlayClassName={classes.Overlay}
      className={classes.Modal}
    >
      <header data-testid="update-company-modal">
        <h2 className={classes.title}>{t('CompaniesTable.UpdateCompany')}</h2>
      </header>
      {children}
    </Modal>
  );
};

export default UpdateCompanyModal;
