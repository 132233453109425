import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { candidateExtracurricularApi } from './modules/candidate-extracurricular';
import { candidateTechnicalInfoApi } from './modules/candidate-technical-info';
import { candidateVideoAnalyzerApi } from './modules/candidate-video-analyzer';
import { candidateJobInterestApi } from './modules/candidate-job-interest';
import { additionalDocumentApi } from './modules/additional-documents';
import { candidateEducationApi } from './modules/candidate-education';
import { candidateLanguageApi } from './modules/candidate-language';
import { companiesApi, companySlice } from './modules/companies';
import { backgroundTasksApi } from './modules/background-tasks';
import { candidateSkillApi } from './modules/candidate-skill';
import { workExperienceApi } from './modules/work-experience';
import { additionalTagApi } from './modules/additional-tags';
import { uploadsApi, uploadsSlice } from './modules/uploads';
import { candidatesApi } from './modules/candidates';
import { employersApi } from './modules/employers';
import { referralsApi } from './modules/referrals';
import { userInfoApi } from './modules/user-info';
import { formInfoApi } from './modules/form-info';
import {
  additionalVideosApi,
  additionalVideosSlice,
} from './modules/additional-videos';
// Legacy reducers
import { passwordResetReducer } from './legacy/password-reset';
import { authReducer } from './legacy/auth';
import { candidateScheduleApi } from './modules/candidate-schedule';
import { ratingsApi } from './modules/ratings';
import { setTraceIdMiddleware } from './middleware/setTraceIdMIddleware';
import { lastPlayedVideo } from './globals/LastPlayedVideo';
import { unauthorizedRedirectMiddleware } from './middleware/unauthorizedRedirectMiddleware';
import { candidateReactionsApi } from './modules/candidate-reactions';
import { videoFeedbacksApi } from './modules/video-feedback';
import { interviewApi } from './modules/interview';

export const store = configureStore({
  reducer: {
    [uploadsSlice.name]: uploadsSlice.reducer,
    [companySlice.name]: companySlice.reducer,
    [uploadsApi.reducerPath]: uploadsApi.reducer,
    [formInfoApi.reducerPath]: formInfoApi.reducer,
    [userInfoApi.reducerPath]: userInfoApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [referralsApi.reducerPath]: referralsApi.reducer,
    [employersApi.reducerPath]: employersApi.reducer,
    [candidatesApi.reducerPath]: candidatesApi.reducer,
    [additionalTagApi.reducerPath]: additionalTagApi.reducer,
    [candidateSkillApi.reducerPath]: candidateSkillApi.reducer,
    [workExperienceApi.reducerPath]: workExperienceApi.reducer,
    [backgroundTasksApi.reducerPath]: backgroundTasksApi.reducer,
    [candidateScheduleApi.reducerPath]: candidateScheduleApi.reducer,
    [candidateLanguageApi.reducerPath]: candidateLanguageApi.reducer,
    [additionalDocumentApi.reducerPath]: additionalDocumentApi.reducer,
    [candidateEducationApi.reducerPath]: candidateEducationApi.reducer,
    [candidateJobInterestApi.reducerPath]: candidateJobInterestApi.reducer,
    [candidateTechnicalInfoApi.reducerPath]: candidateTechnicalInfoApi.reducer,
    [candidateExtracurricularApi.reducerPath]:
      candidateExtracurricularApi.reducer,
    [candidateVideoAnalyzerApi.reducerPath]: candidateVideoAnalyzerApi.reducer,
    [additionalVideosApi.reducerPath]: additionalVideosApi.reducer,
    [ratingsApi.reducerPath]: ratingsApi.reducer,
    [videoFeedbacksApi.reducerPath]: videoFeedbacksApi.reducer,
    [candidateReactionsApi.reducerPath]: candidateReactionsApi.reducer,
    [additionalVideosSlice.name]: additionalVideosSlice.reducer,
    [interviewApi.reducerPath]: interviewApi.reducer,
    [lastPlayedVideo.name]: lastPlayedVideo.reducer,
    // Legacy reducers
    passwordReset: passwordResetReducer,
    auth: authReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      unauthorizedRedirectMiddleware,
      formInfoApi.middleware,
      uploadsApi.middleware,
      userInfoApi.middleware,
      referralsApi.middleware,
      companiesApi.middleware,
      employersApi.middleware,
      candidatesApi.middleware,
      additionalTagApi.middleware,
      workExperienceApi.middleware,
      candidateSkillApi.middleware,
      backgroundTasksApi.middleware,
      additionalVideosApi.middleware,
      candidateLanguageApi.middleware,
      candidateScheduleApi.middleware,
      additionalDocumentApi.middleware,
      candidateEducationApi.middleware,
      candidateJobInterestApi.middleware,
      candidateTechnicalInfoApi.middleware,
      candidateVideoAnalyzerApi.middleware,
      candidateExtracurricularApi.middleware,
      ratingsApi.middleware,
      videoFeedbacksApi.middleware,
      candidateReactionsApi.middleware,
      interviewApi.middleware,
      setTraceIdMiddleware
    ),
});

export type IRootState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
