/* eslint-disable no-useless-escape */
export const emailRegex: RegExp =
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[a-zA-Z]{2,15})+$/;

export const passwordRegex: RegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const dateRegex: RegExp =
  /^(0[1-9]|1[012])([^$%&|<>#]*$)[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;

export const NameRegex: RegExp =
  /^([a-zA-ZZáéíóúÁÉÍÓÚÑñÇçüÜ ]*$)([^$%&|<>@#0-9]*$)/;

export const simeraEmailRegex = /^\w+([-+.']\w+)*@?(simera.me)$/;

export const phoneRegex = /^\d+$/;

export const stringRegex = /^[A-Za-z]+$/;
