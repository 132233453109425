import { FC } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import classes from './TechnicalInformation.module.css';
import { ReactComponent as WindowsIcon } from '../../icons/WindowsIcon.svg';
import { ReactComponent as MacIcon } from '../../icons/MacIcon.svg';
import { ReactComponent as LinuxIcon } from '../../icons/LinuxIcon.svg';
import { ReactComponent as OtherIcon } from '../../icons/SuspensivePoints.svg';
import Radio from '../Radio/Radio';
import BasicInput from '../Inputs/BaseInput';
import SelectInput from '../SelectInput/SelectInput';
import HelpInfoTable from './HelpInfoTable';
import FormsCard from '../FormsCard/FormsCard';

interface TechnicalInformationFormProps {
  CPUModel: UseFormRegisterReturn;
  RAM: UseFormRegisterReturn;
  Storage: UseFormRegisterReturn;
  OperatingSystem: UseFormRegisterReturn;
  AvailableSpace: UseFormRegisterReturn;
  os: string;
  t: TFunction<'global', undefined>;
  errors: {
    CPUModel?: FieldError | undefined;
    RAM?: FieldError | undefined;
    AvailableSpace?: FieldError | undefined;
    OperatingSystem?: FieldError | undefined;
    Storage?: FieldError | undefined;
    Id?: FieldError | undefined;
  };
  secondary?: boolean;
}

const TechnicalInformationForm: FC<TechnicalInformationFormProps> = ({
  CPUModel,
  RAM,
  AvailableSpace,
  OperatingSystem,
  Storage,
  os,
  t,
  errors,
  secondary,
}) => {
  const radios = [
    { id: 'Windows', label: 'Windows', img: WindowsIcon },
    { id: 'Mac', label: 'Mac', img: MacIcon },
    { id: 'Linux', label: 'Linux', img: LinuxIcon },
    { id: 'Others', label: t('common.others'), img: OtherIcon },
  ];

  return (
    <FormsCard>
      <div className={classes.header}>
        <span className={classes.headerText}>
          {t('technical.os')}
          <small className={classes.headerSmall}>
            ({t('common.chooseOne')})
          </small>
        </span>
      </div>
      <div className={classes.radioContainer}>
        {radios.map((radio) => (
          <Radio
            key={radio.id}
            name={OperatingSystem.name}
            onChange={OperatingSystem.onChange}
            refProp={OperatingSystem.ref}
            shape="square"
            label={radio.label}
            img={radio.img}
            value={radio.id}
            checked={os === radio.id}
            secondary={secondary}
          />
        ))}
      </div>
      <div className={classes.gridContainer}>
        <BasicInput
          name={CPUModel.name}
          onBlur={CPUModel.onBlur}
          onChange={CPUModel.onChange}
          refProp={CPUModel.ref}
          type="text"
          label={t('technical.cpu')}
          hasToolTip={os !== 'Others'}
          content={
            <HelpInfoTable secondary={secondary} t={t} os={os} spec="cpu" />
          }
          errorText={errors?.CPUModel?.message?.toString()}
        />
        <SelectInput
          className="select-tooltip"
          name={RAM.name}
          onBlur={RAM.onBlur}
          onChange={RAM.onChange}
          refProp={RAM.ref}
          label={t('technical.ram')}
          hasToolTip={os !== 'Others'}
          tooltipContent={
            <HelpInfoTable secondary={secondary} t={t} os={os} spec="ram" />
          }
          options={[
            { id: 0, text: '>1 GB' },
            { id: 2, text: '2 GB' },
            { id: 4, text: '4 GB' },
            { id: 8, text: '8 GB' },
            { id: 16, text: '16 GB' },
            { id: 32, text: '32 GB' },
            { id: 64, text: '64+ GB' },
          ]}
          errorText={errors?.RAM?.message?.toString()}
        />

        <BasicInput
          name={Storage.name}
          onBlur={Storage.onBlur}
          onChange={Storage.onChange}
          refProp={Storage.ref}
          type="text"
          label={t('technical.storage')}
          hasToolTip={os !== 'Others'}
          content={
            <HelpInfoTable secondary={secondary} t={t} os={os} spec="storage" />
          }
          errorText={errors?.Storage?.message?.toString()}
        />
        <BasicInput
          name={AvailableSpace.name}
          onBlur={AvailableSpace.onBlur}
          onChange={AvailableSpace.onChange}
          refProp={AvailableSpace.ref}
          type="text"
          label={t('technical.space')}
          hasToolTip={os !== 'Others'}
          errorText={errors?.AvailableSpace?.message?.toString()}
          content={
            <HelpInfoTable secondary={secondary} t={t} os={os} spec="space" />
          }
        />
      </div>
    </FormsCard>
  );
};

TechnicalInformationForm.defaultProps = {
  secondary: false,
};
export default TechnicalInformationForm;
