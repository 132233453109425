import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EditableContainer from '../EditableContainer/EditableContainer';
import classes from './CurriculumEditableContainer.module.css';
import useWindowWidth from '../../hooks/useWindowWidth';
import CVUploadMainContainer from '../CVUploadMainContainer/CVUploadMainContainer';

interface CurriculumEditableContainerProps {
  isActive: boolean;
}

const CurriculumEditableContainer: FC<CurriculumEditableContainerProps> = ({
  isActive,
}) => {
  const [t] = useTranslation('global');
  const isMobile = useWindowWidth() <= 650;

  return (
    <EditableContainer
      title={t('Resume.title')}
      isEditable
      onClickPencil={() => {}}
      data-testid="curriculum-editableForm"
      isActive={isActive}
      showHeader={false}
    >
      <div className={isMobile ? '' : classes.ExtraEditModeContainer}>
        <CVUploadMainContainer />
      </div>
    </EditableContainer>
  );
};

export default CurriculumEditableContainer;
