import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoVideoIcon } from '../../../../../icons/NoVideoIconBold.svg';
import { ReactComponent as NoVideoInternalIcon } from '../../../../../icons/NoVideoInternalIcon.svg';
import classes from './NoVideos.module.css';

interface NoVideosProps {
  internal?: boolean;
}
export const NoVideos = ({ internal }: NoVideosProps) => {
  const [t] = useTranslation('global');
  return (
    <div className={classes.noVideoContainer}>
      {internal ? (
        <NoVideoInternalIcon />
      ) : (
        <NoVideoIcon fill="var(--brand-blue-100)" />
      )}
      <h3>{t('AdditionalVideos.noVideoTitle')}</h3>
      <p className={classes.noVideoText}>{t('AdditionalVideos.noVideoText')}</p>
      <p className={classes.noVideoText}>
        {t('AdditionalVideos.noVideoSubtext')}
      </p>
    </div>
  );
};

NoVideos.defaultProps = {
  internal: false,
};
