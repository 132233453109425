import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { FormTypes } from '../../../../types/user_info';
import { errorHandler } from '../../../utils';
import {
  CandidateEducationBuilder,
  SaveCandidateEducationInput,
  SaveCandidateEducationOutput,
} from '../candidate-education.types';

export const addCandidateEducation = (builder: CandidateEducationBuilder) =>
  builder.mutation<SaveCandidateEducationOutput, SaveCandidateEducationInput>({
    query: ({ data }) => ({
      url: `/candidate-education/add-range`,
      method: 'POST',
      body: data.map((education) => {
        const yearValidation =
          education?.YearGraduated.val === '' ||
          education?.YearGraduated.val === undefined;
        const finishDate = yearValidation ? null : education?.YearGraduated.val;
        return {
          Id: education?.Id,
          DegreeType: education?.DegreeType.val,
          DegreeName: education?.DegreeName.val,
          EducationalInstitutionName: education?.SchoolName.val,
          FinishDate: finishDate,
          CurrentlyEnrolled: yearValidation,
        };
      }),
    }),
    invalidatesTags: [{ type: 'candidatesEducation', id: 'LIST' }, 'Score'],
    transformResponse: (response: FormTypes['educationBackendPayload'][]) => {
      return response.map((edu) => ({
        Id: edu?.Id,
        DegreeType: { val: edu?.DegreeType },
        SchoolName: { val: edu?.EducationalInstitutionName },
        DegreeName: { val: edu?.DegreeName },
        YearGraduated: {
          val: edu?.FinishDate
            ? `${new Date(edu?.FinishDate).getFullYear()}`
            : undefined,
        },
        Enrolled: edu?.CurrentlyEnrolled,
      }));
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setEducationalInfoAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
