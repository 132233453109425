import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HoverTooltip from '../../Tooltip/HoverTooltip';
import classes from './SimeraUsersScreen.module.css';
import { ReactComponent as ClearIcon } from '../../../icons/CloseRoundIcon.svg';
import EmployersTable from './EmployersTable/EmployersTable';
import isNumber from '../../../utils/isNumber';
import TableFooter from '../../TableFooter/TableFooter';
import { useLoadEmployersPaginatedQuery } from '../../../store/modules/employers';
import { EmployersPaginatedOptions } from '../../../constants/EmployersPaginatedOptions';
import { parseOrder } from '../../../constants/CandidatesPaginatedOptions';

interface FormData {
  search: string;
}

const SimeraUsersScreen = () => {
  const [t] = useTranslation('global');
  const [searchParams, setSearchParams] = useSearchParams();

  const employersQuery = useMemo<EmployersPaginatedOptions>(() => {
    const name = searchParams.get('name');
    const id = searchParams.get('id');

    return {
      ...(name && { Name: name }),
      ...(id && { Id: parseInt(id, 10) }),
      order: parseOrder(searchParams.get('order')),
      page: parseInt(searchParams.get('page') ?? '1', 10),
      take: parseInt(searchParams.get('take') ?? '10', 10),
    };
  }, [searchParams]);

  const { data, isLoading } = useLoadEmployersPaginatedQuery({
    data: employersQuery,
  });
  const searchDefaultValue =
    employersQuery.Name ?? employersQuery.Id?.toString() ?? '';

  const {
    handleSubmit,
    register,
    formState: { isDirty },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      search: searchDefaultValue,
    },
  });

  const search = register('search', {
    setValueAs: (value) => value.trim().replace(/\s\s+/g, ' '),
    required: true,
  });

  const setSingleSearchParam = (key: string, value: string | number) => {
    const params = new URLSearchParams(searchParams);
    params.set(key, value.toString());
    setSearchParams(params);
  };

  const resetSearch = () => {
    const params = new URLSearchParams(searchParams);
    params.delete('name');
    params.delete('id');
    setSearchParams(params);
    reset({
      search: undefined,
    });
  };

  const handleSearch = (values: FormData) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', '1');
    if (isNumber(+values.search)) {
      params.set('id', values.search);
      params.delete('name');
    } else {
      params.set('name', values.search.toLowerCase().trim());
      params.delete('id');
    }
    setSearchParams(params);
  };

  return (
    <div className={classes.content}>
      <header className={classes.header}>
        <div className={classes.headerText}>
          <h1 className={classes.title}> {t('SimeraInternal.UsersTitle')} </h1>
        </div>
        <form onSubmit={handleSubmit(handleSearch)}>
          <div className={classes.formContainer}>
            <div className={classes.searchBarContent}>
              <input
                type="text"
                className={classes.searchBar}
                name={search.name}
                ref={search.ref}
                placeholder={t('CandidatesTable.SearchLabelEmployer')}
                onChange={search.onChange}
                onBlur={search.onBlur}
              />
            </div>

            <button
              className={classes.calendarButton}
              disabled={!isDirty}
              type="submit"
              data-testid="apply-filter-button"
            >
              {t('CandidatesTable.ApplyFilter')}
            </button>
            <HoverTooltip text={t('CompaniesTable.ClearFilters')}>
              <div>
                <button
                  aria-label="clear filters"
                  className={classes.calendarClearButton}
                  type="button"
                  data-testid="clear-filter-button"
                  onClick={resetSearch}
                >
                  <ClearIcon fill="#716f6f8b" />
                </button>
              </div>
            </HoverTooltip>
          </div>
        </form>
      </header>
      <EmployersTable data={data?.data ?? []} loading={isLoading}>
        <TableFooter
          pageState={data?.meta.page ?? 1}
          pageSize={data?.meta.take ?? 10}
          pageCount={data?.meta.pageCount ?? 0}
          hasPreviousPage={data?.meta.hasPreviousPage ?? false}
          hasNextPage={data?.meta.hasNextPage ?? false}
          nextPage={() =>
            setSingleSearchParam('page', (data?.meta.page ?? 1) + 1)
          }
          previousPage={
            (data?.meta.page ?? 1) > 1
              ? () => setSingleSearchParam('page', (data?.meta.page ?? 1) - 1)
              : () => null
          }
          handleSelectChange={(e) =>
            setSingleSearchParam('take', e.target.value)
          }
        />
      </EmployersTable>
    </div>
  );
};

export default SimeraUsersScreen;
