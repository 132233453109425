import { CandidateData } from '../../../../types/candidates';

const filterBySalaryRange = (
  singleCandidate: CandidateData,
  salaryRange: number[]
) => {
  return (
    singleCandidate?.CandidateJobInterest &&
    typeof singleCandidate?.CandidateJobInterest?.SalaryLowerBound ===
      'number' &&
    typeof singleCandidate?.CandidateJobInterest?.SalaryUpperBound ===
      'number' &&
    salaryRange[0] <= singleCandidate.CandidateJobInterest.SalaryLowerBound &&
    salaryRange[1] >= singleCandidate.CandidateJobInterest.SalaryUpperBound
  );
};

export default filterBySalaryRange;
