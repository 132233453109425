import { FC, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InitialFormStateTypes } from '../../types/user_info';
import classes from './EducationalInformationForm.module.css';
import AddButton from '../Buttons/AddButton';
import { SaveState } from '../../types/ForrmType';
import SaveButton from '../Buttons/SaveButton';
import EducationForm from './EducationForm';
import FormFooter from '../FormFooter/FormFooter';
import CancelModal from '../CancelModal/CancelModal';
import AddMoreFooter from '../Buttons/AddMoreFooter';
import {
  useAddCandidateEducationInternalMutation,
  useAddCandidateEducationMutation,
  useDeleteCandidateEducationMutation,
  useGetCandidateEducationQuery,
} from '../../store/modules/candidate-education';
import { errorHandler, successHandler } from '../../store/utils';
import { useAppDispatch } from '../../hooks';
import { candidatesApi } from '../../store/modules/candidates';

const currentDate = new Date();

interface EducationalInfoProps {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean, continueEditing: boolean) => void;
  show?: boolean;
  secondary?: boolean;
}

const EducationalInformationForm: FC<EducationalInfoProps> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  secondary,
  show,
}) => {
  const [t, i18next] = useTranslation('global');
  const [countForms, setCountForms] = useState(1);
  const { candidateId } = useParams();
  const candidateIdFromLocalStorage = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;

  const dispatch = useAppDispatch();

  const id = candidateId || candidateIdFromLocalStorage;

  const { data: educationData } = useGetCandidateEducationQuery({
    lang: i18next.language as 'es' | 'en',
    candidateId: +id,
  });

  const [
    addCandidateEducationInternal,
    {
      isLoading: isLoadingInternal,
      isSuccess: isSuccessInternal,
      isError: isErrorInternal,
    },
  ] = useAddCandidateEducationInternalMutation();
  const [addCandidateEducation, { isLoading, isSuccess, isError }] =
    useAddCandidateEducationMutation();
  const [deleteCandidateEducation, { isSuccess: deleteSuccess }] =
    useDeleteCandidateEducationMutation();

  const loadingEducation = useCallback(() => {
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }
    if (isSuccess || isSuccessInternal) {
      return SaveState.FULFILLED;
    }
    if (isError || isErrorInternal) {
      return SaveState.FAILED;
    }
    return SaveState.IDLE;
  }, [
    isError,
    isErrorInternal,
    isSuccess,
    isSuccessInternal,
    isLoading,
    isLoadingInternal,
  ]);
  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { educationalInfo: educationData } });

  useEffect(() => {
    setValue('educationalInfo', educationData);
  }, [setValue, educationData]);

  const { dirtyFields, isDirty } = useFormState({ control });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'educationalInfo' as never,
  });

  const appendField = () => {
    if (countForms >= 4) {
      return errorHandler(
        '[EducationalInformationForm]',
        'Educational information limit reached',
        null,
        true
      );
    }

    append({
      DegreeType: { val: '', mult: 1 },
      DegreeName: { val: '', mult: 1 },
      SchoolName: { val: '', mult: 1 },
      YearGraduated: { val: '', mult: 1 },
    });

    setCountForms((count) => count + 1);
    return null;
  };

  useEffect(() => {
    if (educationData?.length) {
      setCountForms(educationData.length);
    }
  }, [educationData, setCountForms]);

  useEffect(() => {
    if (
      (loadingEducation() === SaveState.FULFILLED || deleteSuccess) &&
      educationData
    ) {
      reset({ educationalInfo: educationData });
    }
  }, [deleteSuccess, educationData, loadingEducation, reset]);

  const removeCard = async (index: number, deleteId: number) => {
    await deleteCandidateEducation({
      id: deleteId,
      lang: i18next.language as 'es' | 'en',
    });
    dispatch(candidatesApi.util.invalidateTags(['Score']));

    if (deleteSuccess) {
      setCountForms((count) => count - 1);
    }
    remove(index);
  };

  const removeUnsavedCard = (index: number) => {
    remove(index);
    setCountForms((count) => count - 1);
  };

  const saveInfo = async (data: InitialFormStateTypes) => {
    if (dirtyFields.educationalInfo) {
      const modifiedCardIndexes = Object.keys(dirtyFields?.educationalInfo);
      if (data.educationalInfo) {
        const modifiedCards = data.educationalInfo?.filter((_, ind) =>
          modifiedCardIndexes.includes(ind.toString())
        );

        try {
          if (Number(candidateId)) {
            await addCandidateEducationInternal({
              candidateId: Number(candidateId),
              data: modifiedCards,
              lang: i18next.language as 'es' | 'en',
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
          } else {
            await addCandidateEducation({
              data: modifiedCards,
              lang: i18next.language as 'es' | 'en',
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
          }
          if (setEdit) {
            setEdit(true);
          }
          successHandler(t('common.saved'), true);
          window.analytics.track('Education form submitted');
        } catch (error) {
          // error
        }
      }
    }
  };

  const renderFields = () => {
    return fields.map((row: any, index: number) => {
      const { Id } = row;
      const degreeType = register(`educationalInfo.${index}.DegreeType.val`, {
        required: t('education.requiredDegreeType').toString(),
      });
      const degreeName = register(`educationalInfo.${index}.DegreeName.val`, {
        required: t('education.requiredDegreeName').toString(),
      });
      const schoolName = register(`educationalInfo.${index}.SchoolName.val`);
      const yearGraduated = register(
        `educationalInfo.${index}.YearGraduated.val`,
        {
          validate: {
            lessThanToday: (value) =>
              (value &&
                parseInt(value, 10) <= currentDate.getFullYear() &&
                parseInt(value, 10) >= 1950) ||
              value === '' ||
              value === undefined ||
              'Enter a valid year',
          },
        }
      );
      const enrolled = register(`educationalInfo.${index}.Enrolled`);
      return (
        <div key={row.id}>
          <EducationForm
            key={row.id}
            idx={index}
            remove={removeCard}
            control={control}
            t={t}
            formId={row.id}
            formIndex={index}
            degreeType={degreeType}
            degreeName={degreeName}
            schoolName={schoolName}
            yearGraduated={yearGraduated}
            setValue={setValue}
            errors={errors}
            enrolled={enrolled}
            deleteId={Id}
            removeUnsaved={removeUnsavedCard}
            secondary={secondary}
          />
        </div>
      );
    });
  };
  return (
    <div style={{ height: '100%' }}>
      <form
        data-testid="form-container"
        onSubmit={handleSubmit(saveInfo)}
        className={classes.Container}
      >
        {!isEdit && (
          <div className={classes.buttonContainer}>
            <AddButton onClick={appendField} />
          </div>
        )}
        <div className={classes.CardContainer}>{renderFields()}</div>
        {fields.length > 1 && !isEdit && (
          <AddMoreFooter onClick={appendField} label={t('education.add')} />
        )}
        <div className={classes.ButtonContainer}>
          {isEdit ? (
            <FormFooter
              onClickCancel={onClickCancel}
              disabled={loadingEducation() === SaveState.PENDING || !isDirty}
              saveState={isDirty ? SaveState.IDLE : loadingEducation()}
              translate={t}
              submit
            />
          ) : (
            <SaveButton
              disabled={loadingEducation() === SaveState.PENDING || !isDirty}
              state={isDirty ? SaveState.IDLE : loadingEducation()}
              t={t}
              submit
            />
          )}
        </div>
      </form>
      {show && onClickCancelModal && (
        <CancelModal onClickCancelModal={onClickCancelModal} />
      )}
    </div>
  );
};
EducationalInformationForm.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  secondary: false,
};
export default EducationalInformationForm;
