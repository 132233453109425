import { SaveState } from '../../../types/ForrmType';

export enum PasswordResetActionTypes {
  PASSWORD_RECOVERY_REQUEST_START = 'PASSWORD_RECOVERY_REQUEST_START',
  PASSWORD_RECOVERY_REQUEST_SUCCESS = 'PASSWORD_RECOVERY_REQUEST_SUCCESS',
  PASSWORD_RECOVERY_REQUEST_FAIL = 'PASSWORD_RECOVERY_REQUEST_FAIL',
  VALIDATE_TOKEN_START = 'VALIDATE_TOKEN_START',
  VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS',
  VALIDATE_TOKEN_FAIL = 'VALIDATE_TOKEN_FAIL',
  RESET_PASSWORD_AUTH_START = 'RESET_PASSWORD_AUTH_START',
  RESET_PASSWORD_AUTH_SUCCESS = 'RESET_PASSWORD_AUTH_SUCCESS',
  RESET_PASSWORD_AUTH_FAIL = 'RESET_PASSWORD_AUTH_FAIL',
  DEFAULT_TYPE = 'DEFAULT_TYPE',
}

type BaseAction = {
  type: PasswordResetActionTypes;
  error?: Error | string;
};

export type PasswordResetState = {
  emailSentStatus: SaveState;
  validateTokenStatus: SaveState;
  passwordChangeStatus: SaveState;
  error?: Error | string | null;
  token?: string | null;
};

export type PasswordResetAction = BaseAction & {
  token?: string;
};

export interface ValidateTokenAction extends BaseAction {
  token: string;
}
