import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  AddCandidateLanguageInternalInput,
  AddCandidateLanguageInternalOutput,
  CandidateLanguageBuilder,
} from '../candidate-language.types';

export const addCandidateLanguageInternal = (
  builder: CandidateLanguageBuilder
) =>
  builder.mutation<
    AddCandidateLanguageInternalOutput,
    AddCandidateLanguageInternalInput
  >({
    query: ({ languages, candidateId }) => ({
      url: `/candidate-languages/save-range/internal/${candidateId}`,
      method: 'POST',
      body: {
        fileID: -1,
        languages: languages.map((language) => ({
          Id: language?.Id,
          LanguageName: language?.LanguageName?.val,
          LanguageProficiency: language?.LanguageProficiency?.val,
        })),
      },
    }),
    invalidatesTags: [{ type: 'candidateLanguages', id: 'LIST' }, 'Score'],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[addCandidateLanguage]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
