import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { ReducerPaths } from '../../constants/reducerPaths';
import { updateRating } from './mutations';
import { getRatingEmployer, getRatingInternal } from './queries';

export const ratingsApi = createApi({
  reducerPath: ReducerPaths.RATINGS,
  baseQuery,
  endpoints: (builder) => ({
    updateRating: updateRating(builder),
    getRatingInternal: getRatingInternal(builder),
    getRatingEmployer: getRatingEmployer(builder),
  }),
});

export const {
  useUpdateRatingMutation,
  useGetRatingInternalQuery,
  useGetRatingEmployerQuery,
} = ratingsApi;
