import { FC, useEffect, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import classes from './MultipleTagSelector.module.css';
import Chip from './Chip';
import generateUUID from '../../utils/generateUUID';
import { AutocompleteOption } from '../../types/user_info';

interface MultipleTagSelectorProps {
  selectedTags: AutocompleteOption[];
  options: AutocompleteOption[];
  label?: string;
  arrayName: string;
  setValue: UseFormSetValue<any>;
  placeholder?: string;
  secondary?: boolean;
  labelWeight?: 'normal' | 'bold';
  isSearch?: boolean;
  handleNewOption?: (value: string) => void;
}
const MultipleTagSelector: FC<MultipleTagSelectorProps> = ({
  arrayName,
  options,
  label,
  placeholder,
  setValue,
  secondary,
  selectedTags,
  labelWeight,
  isSearch,
  handleNewOption,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const autocompleteRef = useRef<any>(null);
  const [focused, setFocused] = useState(false);

  const clickOutsideHandler = (evt: Event) => {
    if (
      autocompleteRef &&
      autocompleteRef.current &&
      !autocompleteRef.current.contains(evt.target) &&
      !(evt.target instanceof HTMLLIElement)
    ) {
      setShowOptions(false);
    }
  };

  const unselectOption = (option: AutocompleteOption) => {
    const filteredOptions = selectedTags.filter((o) => o.Id !== option.Id);
    setValue(arrayName, filteredOptions, { shouldDirty: true });

    setShowOptions(false);
  };

  const renderChips = () =>
    selectedTags.map((tag) => (
      <Chip
        key={`${generateUUID()}-${tag.Id}`}
        option={tag}
        secondary={secondary}
        onClickChip={() => unselectOption(tag)}
      />
    ));

  useEffect(() => {
    window.addEventListener('mousedown', clickOutsideHandler);
    return () => {
      window.removeEventListener('mousedown', clickOutsideHandler);
    };
  }, []);

  const focusThis = () => {
    if (autocompleteRef) autocompleteRef.current.focus();
    setFocused(true);
    setShowOptions(true);
  };

  const isInList = (id: number) => selectedTags.find((o) => o.Id === id);

  const selectOption = (option: AutocompleteOption) => {
    setSearchVal('');
    if (autocompleteRef) autocompleteRef.current.value = '';
    if (!isInList(option.Id)) {
      setValue(arrayName, [...selectedTags, option], {
        shouldDirty: true,
        shouldValidate: true,
      });
      focusThis();
    }
  };
  const onChangeInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(evt.target.value);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  const handleInputKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const optionsUl: HTMLElement | null = document.querySelector(
      '#autocompleteOptions'
    ) as HTMLElement;

    const firstLi: HTMLLIElement | null = optionsUl?.querySelector(
      'li:first-child'
    ) as HTMLLIElement;

    if (
      evt.key === 'ArrowDown' &&
      showOptions &&
      optionsUl &&
      optionsUl.childNodes.length > 0 &&
      firstLi
    ) {
      setShowOptions(true);
      firstLi.focus();
    } else if (evt.key === 'Enter') {
      setShowOptions((s) => !s);

      if (
        evt.currentTarget.value !== '' &&
        !options.find((o) => o.title === evt.currentTarget.value) &&
        options.filter(
          (opt) => opt.title.toLowerCase().indexOf(searchVal.toLowerCase()) >= 0
        ).length === 0
      ) {
        if (handleNewOption) handleNewOption(evt.currentTarget.value);
        setSearchVal('');
        autocompleteRef.current.value = '';
      }
    }
  };

  const handleOptionsKeyDown = (evt: React.KeyboardEvent<HTMLElement>) => {
    evt.stopPropagation();
    const focusedEl = evt.currentTarget;
    const prevEl: HTMLLIElement =
      focusedEl.previousElementSibling as HTMLLIElement;
    const nextEl: HTMLLIElement = focusedEl.nextElementSibling as HTMLLIElement;

    if (evt.key === 'ArrowDown' && showOptions && nextEl) {
      nextEl.focus();
    } else if (evt.key === 'ArrowUp' && showOptions && prevEl) {
      prevEl.focus();
    } else if (evt.key === 'Enter') {
      const option: HTMLLIElement = evt.currentTarget as HTMLLIElement;
      setSearchVal('');
      if (!isInList(option.value)) {
        setValue(
          arrayName,
          [...selectedTags, { Id: Number(option.value), title: option.title }],
          { shouldDirty: true }
        );

        focusThis();
        if (autocompleteRef) autocompleteRef.current.value = '';
      }
    }
  };

  const autoCompleteWrapper = isSearch
    ? classes.AutocompleteInputWrapperSearch
    : classes[
        `${
          secondary
            ? 'AutocompleteInputWrapperInternal'
            : 'AutocompleteInputWrapper'
        }`
      ];

  const inputContainer = isSearch
    ? classes.InputContainerSearch
    : classes.InputContainer;
  return (
    <div
      data-testid="multi-chip-selector"
      style={{ position: 'relative', width: '100%' }}
      className={inputContainer}
    >
      {label !== '' && (
        <p
          className={classes.Label}
          style={{ fontWeight: labelWeight === 'normal' ? 400 : '' }}
          data-testid="input-placeholder"
        >
          {label}
        </p>
      )}
      <div
        data-testid="input"
        className={`${autoCompleteWrapper} ${
          focused && classes.AutocompleteInputFocus
        }`}
        aria-hidden="true"
        onClick={focusThis}
      >
        {renderChips()}
        <input
          onChange={(evt) => {
            onChangeInput(evt);
          }}
          placeholder={selectedTags.length ? '' : placeholder}
          onKeyDown={handleInputKeyDown}
          onBlur={() => {
            setFocused(false);
          }}
          className={classes.AutocompleteInput}
          ref={autocompleteRef}
        />
      </div>
      {showOptions && (
        <ul
          data-testid="list-container"
          id="autocompleteOptions"
          className={classes.options}
          defaultValue={1}
          aria-hidden="true"
        >
          {options
            .filter(
              (opt) =>
                opt.title.toLowerCase().indexOf(searchVal.toLowerCase()) >= 0
            )
            .map((opt) => (
              <li
                id={`option-${opt.title}`}
                tabIndex={opt.Id}
                title={opt.title}
                value={opt.Id}
                key={`${generateUUID()} ${opt.Id}`}
                onMouseDown={() => selectOption(opt)}
                onKeyDown={handleOptionsKeyDown}
                role="presentation"
              >
                {opt.title}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

MultipleTagSelector.defaultProps = {
  placeholder: '',
  secondary: false,
  labelWeight: 'bold',
  isSearch: false,
  label: '',
  handleNewOption: () => {},
};

export default MultipleTagSelector;
