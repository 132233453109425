import { Dispatch, FC, SetStateAction } from 'react';
import {
  UseFormGetValues,
  UseFormRegisterReturn,
  UseFormSetValue,
} from 'react-hook-form';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import AutocompleteRHF from '../Autocomplete/AutocompleteRHF';
import { ReactComponent as TrashIcon } from '../../icons/TrashIcon.svg';
import classes from './FormContainer.module.css';
import { FieldTypes, InitialFormStateTypes } from '../../types/user_info';
import BasicInput from '../Inputs/BaseInput';
import SelectInput from '../SelectInput/SelectInput';
import FormsCard from '../FormsCard/FormsCard';
import ConfirmDeletionModal from '../ConfirmDeletionModal/ConfirmDeletionModal';
import { SkillLevel as SkillEnum } from '../../types/skills/skills';
import { Skill } from '../../types/candidates';

interface SkillsFormProps {
  index: number;
  removeSkill: (index: number, deleteId: number) => void;
  t: TFunction<'global', undefined>;
  setValue: UseFormSetValue<
    | InitialFormStateTypes
    | {
        Id?: number;
        CertificationId: FieldTypes;
        Skill: {
          val: string;
          mult: number;
          Id: number;
          title?: string;
        };
        SkillLevel: FieldTypes;
        certDate: FieldTypes;
        CertificationUrl: FieldTypes;
      }
  >;
  SkillLevel: UseFormRegisterReturn;
  SkillName: UseFormRegisterReturn;
  CertificationId: UseFormRegisterReturn;
  CertificationUrl: UseFormRegisterReturn;
  certDate: UseFormRegisterReturn;
  skills: Skill[] | undefined;
  row: any;
  Id: number;
  removeUnsaved: (index: number) => void;
  Modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  delId: any;
  setDelId: any;
  newSkill?: boolean;
  errors?: any;
  secondary?: boolean;
  getValues?: UseFormGetValues<
    | InitialFormStateTypes
    | {
        Id?: number;
        CertificationId: FieldTypes;
        Skill: { val: string; mult: number; Id: number; title?: string };
        SkillLevel: FieldTypes;
        certDate: FieldTypes;
        CertificationUrl: FieldTypes;
      }
  >;
}
const SkillsForm: FC<SkillsFormProps> = ({
  index,
  removeSkill,
  t,
  setValue,
  SkillLevel,
  SkillName,
  CertificationId,
  CertificationUrl,
  certDate,
  skills,
  row,
  Id,
  removeUnsaved,
  Modal,
  setModal,
  delId,
  setDelId,
  newSkill,
  errors,
  getValues,
  secondary,
}) => {
  const SkillLevelOptions = [
    { id: SkillEnum.Novice, text: t('skills.novice') },
    { id: SkillEnum.Beginner, text: t('skills.advancedBeginner') },
    { id: SkillEnum.Competent, text: t('skills.competent') },
    { id: SkillEnum.Proficient, text: t('skills.proficient') },
    { id: SkillEnum.Expert, text: t('skills.expert') },
  ];
  const skillNumber = [
    t('skills.first'),
    t('skills.second'),
    t('skills.third'),
    t('skills.fourth'),
    t('skills.fifth'),
    t('skills.sixth'),
    t('skills.seventh'),
    t('skills.eighth'),
    t('skills.nineth'),
    t('skills.tenth'),
    t('skills.eleventh'),
    t('skills.twelveth'),
    t('skills.thirteenth'),
    t('skills.fourteenth'),
    t('skills.fifteenth'),
    t('skills.sixteenth'),
    t('skills.seventeenth'),
    t('skills.eighteenth'),
    t('skills.nineteenth'),
    t('skills.twentieth'),
    t('skills.twentyfirst'),
    t('skills.twentysecond'),
    t('skills.twentythird'),
    t('skills.twentyfourth'),
    t('skills.twentyfifth'),
  ];

  return (
    <FormsCard key={row.id}>
      {Modal && delId === Id && (
        <ConfirmDeletionModal
          SetModal={setModal}
          Delete={removeSkill}
          Index={index}
          DeleteId={Id}
          secondary={secondary}
        />
      )}
      {!newSkill && (
        <p className={classes.cardTitle}>{`${skillNumber[index]} ${t(
          'skills.skill'
        )}`}</p>
      )}

      <div className={classes.formHeaderIconContainer}>
        {index > 0 && Id !== undefined ? (
          <TrashIcon
            className={classes.Remove}
            onClick={() => {
              setModal(true);
              setDelId(Id);
            }}
          />
        ) : (
          index > 0 && (
            <TrashIcon
              className={classes.Remove}
              onClick={() => {
                removeUnsaved(index);
              }}
            />
          )
        )}
      </div>
      <div className={classes.row}>
        <div className={classes.skillName}>
          <AutocompleteRHF
            label={t('skills.name')}
            name={SkillName.name}
            onBlur={SkillName.onBlur}
            onChange={SkillName.onChange}
            refProp={SkillName.ref}
            options={skills ?? []}
            setValue={setValue}
            currSearchVal={(row.Skill && row.Skill.Title) || ''}
            errorText={
              errors?.Skill?.title?.type === 'skillListed' ||
              errors?.Skill?.title?.type === 'required'
                ? t('skills.notListed')
                : ''
            }
          />
        </div>

        <div className={classes.skillLevel}>
          <SelectInput
            name={SkillLevel.name}
            onBlur={SkillLevel.onBlur}
            onChange={SkillLevel.onChange}
            refProp={SkillLevel.ref}
            label={t('skills.level')}
            placeholder={t('skills.SelectLevel')}
            options={SkillLevelOptions}
            errorText={errors?.SkillLevel ? t('workHistory.requiredField') : ''}
          />
        </div>
      </div>
      <p className={classes.certTitle}>{t('skills.idTitle')}</p>

      <BasicInput
        name={CertificationId.name}
        onBlur={CertificationId.onBlur}
        onChange={CertificationId.onChange}
        refProp={CertificationId.ref}
        type="text"
        placeholder={t('skills.id')}
      />
      <BasicInput
        name={CertificationUrl.name}
        onBlur={CertificationUrl.onBlur}
        onChange={CertificationUrl.onChange}
        refProp={CertificationUrl.ref}
        label={t('skills.url')}
        type="text"
        placeholder={t('skills.url')}
      />
      <div className={classes.fiftyPerc}>
        <BasicInput
          name={certDate.name}
          onBlur={certDate.onBlur}
          onChange={certDate.onChange}
          refProp={certDate.ref}
          value={getValues && getValues(`skills.${index}.certDate.val`)}
          type="month"
          minDate="1950-01"
          label={t('skills.date')}
          maxDate={dayjs().format('YYYY/MM').replace('/', '-')}
          placeholder="YYYY-MM"
          errorText={
            errors &&
            errors.certDate &&
            errors.certDate.val.type === 'isValidDate'
              ? t('skills.notYear')
              : ''
          }
          errorDate={
            errors &&
            errors.certDate &&
            errors.certDate.val.type === 'isValidYear'
              ? t('workHistory.invalidDate')
              : ''
          }
        />
      </div>
    </FormsCard>
  );
};

SkillsForm.defaultProps = {
  newSkill: false,
  errors: undefined,
  getValues: undefined,
  secondary: false,
};

export default SkillsForm;
