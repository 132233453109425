import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  AdditionalDocumentBuilder,
  GetAdditionalDocumentsByCandidateIdInput,
  GetAdditionalDocumentsByCandidateIdOutput,
} from '../additional-document.types';

export const getAdditionalDocumentsByCandidateId = (
  builder: AdditionalDocumentBuilder
) =>
  builder.query<
    GetAdditionalDocumentsByCandidateIdOutput,
    GetAdditionalDocumentsByCandidateIdInput
  >({
    query: ({ candidateId }) => ({
      url: `/upload/file/${candidateId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ Id }) => ({
              type: 'additionalDocuments' as const,
              id: Id,
            })),
            { type: 'additionalDocuments', id: 'LIST' },
          ]
        : [{ type: 'additionalDocuments', id: 'LIST' }],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[loadAdditionalDocumentAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
