import { FC, useState } from 'react';
import { UseFormRegisterReturn, useWatch } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { ReactComponent as TrashIcon } from '../../icons/TrashIcon.svg';
import Radio from '../Radio/Radio';
import BaseTextArea from '../TextArea/BaseTextArea';
import classes from './ExtracurricularForm.module.css';
import { ReactComponent as Others } from '../../icons/SuspensivePoints.svg';
import { ReactComponent as Charity } from '../../icons/Charity.svg';
import { ReactComponent as Volunteering } from '../../icons/Volunteering.svg';
import FormsCard from '../FormsCard/FormsCard';
import ConfirmDeletionModal from '../ConfirmDeletionModal/ConfirmDeletionModal';

interface ICounters {
  [key: string]: number;
}

interface FormInputsProps {
  t: TFunction<'global', undefined>;
  index: number;
  extracurricularType: UseFormRegisterReturn;
  ExtracurricularDetail: UseFormRegisterReturn;
  counter: ICounters;
  setCounter: (counter: ICounters) => void;
  control: any;
  remove: (index: number, deleteId: number) => void;
  errors: any;
  formIndex: number;
  deleteId: number;
  removeUnsaved: (index: number) => void;
  newExtra?: boolean;
  secondary?: boolean;
}

const ExtracurricularForm: FC<FormInputsProps> = ({
  t,
  index,
  extracurricularType,
  ExtracurricularDetail,
  counter,
  setCounter,
  control,
  remove,
  errors,
  formIndex,
  deleteId,
  removeUnsaved,
  newExtra,
  secondary,
}) => {
  const [Modal, setModal] = useState<boolean>(false);
  const labels = [
    {
      id: 0,
      label: t('extracurricular.vol'),
      img: Volunteering,
    },
    {
      id: 1,
      label: t('extracurricular.charity'),
      img: Charity,
    },
    {
      id: 2,
      label: t('extracurricular.other'),
      img: Others,
    },
  ];

  const extraType = useWatch({
    control,
    name: extracurricularType.name,
  });

  const headerText: { [key: number]: string } = {
    0: t('education.first'),
    1: t('education.second'),
    2: t('education.third'),
    3: t('education.fourth'),
    4: t('education.fifth'),
  };

  return (
    <FormsCard>
      <div data-testid="extracurricular-form">
        {Modal && (
          <ConfirmDeletionModal
            SetModal={setModal}
            Delete={remove}
            Index={formIndex}
            DeleteId={deleteId}
            secondary={secondary}
          />
        )}
        <div>
          {!newExtra && (
            <div className={classes.title}>{`${headerText[index]} ${t(
              'extracurricular.nonacademic'
            )}`}</div>
          )}
          <div className={classes.formHeaderIconContainer}>
            {index !== 0 && deleteId !== undefined ? (
              <TrashIcon
                className={classes.formHeaderIcon}
                onClick={() => {
                  setModal(true);
                }}
              />
            ) : (
              index !== 0 && (
                <TrashIcon
                  className={classes.formHeaderIcon}
                  onClick={() => {
                    removeUnsaved(index);
                  }}
                />
              )
            )}
          </div>
          <label
            data-testid="degree-type-input"
            className={classes.DropdownLabel}
            htmlFor="type-dropdown"
          >
            {t('extracurricular.activity')}
            <div className={classes.Radios}>
              {labels.map((label) => (
                <Radio
                  key={label.id}
                  shape="square"
                  name={extracurricularType.name}
                  onChange={extracurricularType.onChange}
                  refProp={extracurricularType.ref}
                  label={label.label}
                  value={label.id}
                  img={label.img}
                  checked={
                    extraType && extraType.toString() === label.id.toString()
                  }
                  secondary={secondary}
                />
              ))}
            </div>
            <div className={classes.errorRadio}>
              {errors?.extracurricular?.[formIndex]?.ExtracurricularType?.val
                ?.message || errors?.ExtracurricularType?.val?.message}
            </div>
          </label>
        </div>
      </div>
      <BaseTextArea
        counter={counter[index]}
        name={ExtracurricularDetail.name}
        onChange={(e) => {
          setCounter({ ...counter, [index]: e.target.value.length });
          ExtracurricularDetail.onChange(e);
        }}
        refProp={ExtracurricularDetail.ref}
        label={t('extracurricular.experience')}
        onBlur={ExtracurricularDetail.onBlur}
        secondary={secondary}
        errorText={
          errors?.extracurricular?.[formIndex]?.ExtracurricularDetail?.val
            ?.message || errors?.ExtracurricularDetail?.val?.message
        }
      />
    </FormsCard>
  );
};

ExtracurricularForm.defaultProps = {
  newExtra: false,
  secondary: false,
};

export default ExtracurricularForm;
