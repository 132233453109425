import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  ActivatePositionInput,
  ActivatePositionOutput,
} from '../companies.types';

export const activatePosition = (builder: CompanyBuilder) =>
  builder.mutation<ActivatePositionOutput, ActivatePositionInput>({
    query: (option) => ({
      url: `/positions/activate/${option.positionId}`,
      method: 'PATCH',
    }),
    transformResponse: (queryResponse: ActivatePositionOutput, _, arg) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEs.ActivatePosition
          : SuccessMessageEn.ActivatePosition,
        true
      );
      return queryResponse;
    },
    transformErrorResponse: (error, _, arg) => {
      errorHandler(
        '[ActivatePosition]',
        arg.lang === 'es'
          ? ErrorMessageEs.ActivatePosition
          : ErrorMessageEn.ActivatePosition,
        error,
        true
      );
    },
    invalidatesTags: () => [{ type: 'Company', Id: 'LIST' }],
  });
