import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './HideVideoModal.module.css';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import { ReactComponent as Close } from '../../../../../icons/CloseGreen.svg';
import { ReactComponent as CloseBlue } from '../../../../../icons/CloseBlue.svg';
import { ReactComponent as ArrowRight } from '../../../../../icons/ArrowRight.svg';
import { useUpdateAdditionalVideoMutation } from '../../../../../store/modules/additional-videos';
import { AdditionalVideo } from '../../../../../types/additional-video/additional-video';
import {
  Video,
  useUpdateVideoMutation,
} from '../../../../../store/modules/uploads';

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  video?: AdditionalVideo | null;
  videoType?: Video | null;
  candidate?: boolean;
}
export const HideVideoModal: FC<Props> = ({
  setModal,
  video,
  videoType,
  candidate,
}) => {
  const [t, i18next] = useTranslation('global');
  const parentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(parentRef, () => setModal(false));
  const [updateAdditionalVideo, { isLoading }] =
    useUpdateAdditionalVideoMutation();
  const [updateVideos] = useUpdateVideoMutation();

  const handleUpdate = async () => {
    if (videoType) {
      await updateVideos({
        data: [{ ...videoType, IsVisible: false }],
        lang: i18next.language as 'en' | 'es',
      });
    }
    if (video) {
      await updateAdditionalVideo({
        id: video.Id,
        data: {
          Name: video.Name,
          IsVisible: false,
        },
        lang: i18next.language as 'en' | 'es',
      });
    }
    setModal(false);
  };

  return (
    <div
      className={classes.Container}
      onClick={() => setModal(false)}
      aria-hidden
      ref={parentRef}
    >
      <div
        className={classes.Centered}
        onClick={(e) => e.stopPropagation()}
        aria-hidden
      >
        <div className={classes.Content}>
          <div className={classes.HeaderContent}>
            <div className={classes.Header}>
              <p>{t('AdditionalVideos.modals.hide')}</p>
              {candidate ? (
                <CloseBlue
                  className={classes.Close}
                  onClick={() => setModal(false)}
                />
              ) : (
                <Close
                  className={classes.Close}
                  onClick={() => setModal(false)}
                />
              )}
            </div>
            <div className={classes.divider} />
          </div>

          <div className={classes.HideContainer}>
            <p className={classes.Text}>
              {t('AdditionalVideos.modals.hideQuestion1')}
            </p>
            <div className={classes.ButtonContainer}>
              <button
                type="button"
                className={`${classes.Cancel} ${
                  candidate && classes.CandidateText
                }`}
                onClick={() => setModal(false)}
              >
                {t('AdditionalVideos.modals.cancel')}
              </button>
              <button
                type="button"
                className={`${classes.Yes} ${candidate && classes.Candidate}`}
                onClick={handleUpdate}
                disabled={isLoading}
              >
                {t('AdditionalVideos.modals.hide')}
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HideVideoModal.defaultProps = {
  videoType: null,
  video: null,
  candidate: false,
};
