import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './DragDropContainer.module.css';
import { ReactComponent as UploadIcon } from '../../icons/UploadIcon.svg';
import { ReactComponent as UploadIconInternal } from '../../icons/UploadIconInternal.svg';

interface DragDropTextContainerProps {
  active: boolean;
  isMobile: boolean;
  supportedFilesText: string;
  disabled?: boolean;
  secondary?: boolean;
}
const DragDropTextContainer: FC<DragDropTextContainerProps> = ({
  active,
  isMobile,
  supportedFilesText,
  disabled,
  secondary,
}) => {
  const activeContainer = active
    ? classes.DragActiveContainer
    : classes.DragContainer;
  const containerStyle = isMobile ? classes.MobileContainer : activeContainer;
  const [t] = useTranslation('global');
  const Icon = secondary ? UploadIconInternal : UploadIcon;
  return (
    <div
      data-testid="drop-container"
      className={`${containerStyle} ${disabled && classes.disabled}`}
    >
      {active ? (
        <Icon className={classes.UploadIcon} fill="#EEEEEE" />
      ) : (
        <Icon
          className={classes.UploadIcon}
          fill={secondary ? '#079d866a' : '#CDE0E8'}
        />
      )}
      <div className={classes.CenterColumns}>
        <p className={classes.ContainerTitle}>
          {t('cv.dragDrop')}&nbsp;
          <span className={classes.Normal}>{t('cv.or')}</span>
        </p>
      </div>
      {!isMobile && (
        <div className={classes.SupportedFiles} data-testid="cv-desktop">
          {supportedFilesText}
        </div>
      )}
    </div>
  );
};

DragDropTextContainer.defaultProps = {
  disabled: false,
  secondary: false,
};
export default DragDropTextContainer;
