import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CompanyBuilder,
  GetEmployeeByCompanyInput,
  GetEmployeeByCompanyOutput,
} from '../companies.types';

export const getEmployeesByCompany = (builder: CompanyBuilder) =>
  builder.query<GetEmployeeByCompanyOutput, GetEmployeeByCompanyInput>({
    query: (position) => ({
      url: `/candidate-position-status/employees/company/${position.companyId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ Id }) => ({ type: 'Company' as const, Id })),
            { type: 'Company', Id: 'LIST' },
          ]
        : [{ type: 'Company', Id: 'LIST' }],
    transformErrorResponse: (error, meta, args) => {
      errorHandler(
        '[getEmployeesByCompany]',
        args.lang === 'es'
          ? ErrorMessageEs.ErrorGettingEmployees
          : ErrorMessageEn.ErrorGettingEmployees,
        error,
        true
      );
      return error.data;
    },
  });
