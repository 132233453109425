import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  // useCallback,
  // useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import classes from './SimeraCandidatesScreen.module.css';
import { emailRegex } from '../../../constants/regex';
import { ReactComponent as ClearIcon } from '../../../icons/CloseRoundIcon.svg';
import { ReactComponent as FilterIcon } from '../../../icons/Filter.svg';
import compareObjects from '../../../utils/compareObjects';
import isNumber from '../../../utils/isNumber';
import AddNewImportCandidateButton from '../../EmployerDashboard/AddnewImportCandidateButton';
import CandidateInternalForm from '../../EmployerDashboard/CandidateInternalModal/CandidateInternalForm';
import ImportCandidatesModal from '../../EmployerDashboard/ImportCandidatesModal/ImportCandidatesModal';
import HoverTooltip from '../../Tooltip/HoverTooltip';
import CandidatesTable from './CandidatesTable/CandidatesTable';
// import { SIMERA_SSE_ROUTE } from '../../../constants/routes';
// import SseEventTypes from '../../../constants/SseEventTypes';
import {
  // candidatesApi,
  useGetInternalCandidatesQuery,
} from '../../../store/modules/candidates';
import { parseOrder } from '../../../constants/CandidatesPaginatedOptions';
// import { useAppDispatch } from '../../../hooks';
import { CandidatesPaginationQuery } from '../../../types/candidates';

const SimeraCandidatesScreen = () => {
  dayjs.extend(customParseFormat);
  const [t, i18Next] = useTranslation('global');
  const [searchParams, setSearchParams] = useSearchParams();

  const candidatesQuery = useMemo<CandidatesPaginationQuery>(() => {
    const id = searchParams.get('Id');
    return {
      order: parseOrder(searchParams.get('order')),
      page: parseInt(searchParams.get('page') ?? '1', 10),
      take: parseInt(searchParams.get('take') ?? '10', 10),
      Id: id ? parseInt(id, 10) : undefined,
      Name: searchParams.get('Name') ?? undefined,
      Email: searchParams.get('Email') ?? undefined,
      StartDate: searchParams.get('StartDate') ?? undefined,
      EndDate: searchParams.get('EndDate') ?? undefined,
    };
  }, [searchParams]);
  // const dispatch = useAppDispatch();

  const { data, isLoading } = useGetInternalCandidatesQuery({
    query: candidatesQuery,
    lang: i18Next.language as 'en' | 'es',
  });
  const nameDefaultValue =
    candidatesQuery.Name ?? candidatesQuery.Id?.toString() ?? '';

  const {
    register,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      search: nameDefaultValue,
      initialDate: candidatesQuery.StartDate,
      endDate: candidatesQuery.EndDate,
    },
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);

  const search = register('search', {
    setValueAs: (value) => value.trim().replace(/\s\s+/g, ' '),
  });

  const searchValue = watch('search');
  const endDateValue = watch('endDate');
  const initialDateValue = watch('initialDate');

  const initialDate = register('initialDate', {
    validate: {
      isValidFormat: (value) =>
        value ? dayjs(value, 'YYYY-MM', true).isValid() : true,
    },
  });
  const endDate = register('endDate', {
    validate: {
      isValidFormat: (value) =>
        value ? dayjs(value, 'YYYY-MM', true).isValid() : true,
    },
  });
  const searchData = watch();

  const refSearchData = useRef(searchData);

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!compareObjects(refSearchData.current, searchData)) {
      refSearchData.current = searchData;

      const newSearchParams = new URLSearchParams(searchParams);

      if (searchValue !== '') {
        if (isNumber(+searchValue)) {
          newSearchParams.set('Id', refSearchData.current.search);
          newSearchParams.delete('Name');
          newSearchParams.delete('Email');
        } else if (emailRegex.test(searchValue)) {
          newSearchParams.set('Email', refSearchData.current.search);
          newSearchParams.delete('Name');
          newSearchParams.delete('Id');
        } else {
          newSearchParams.set('Name', refSearchData.current.search);
          newSearchParams.delete('Id');
          newSearchParams.delete('Email');
        }
      } else {
        newSearchParams.delete('Name');
        newSearchParams.delete('Id');
        newSearchParams.delete('Email');
      }

      if (initialDateValue === '' && endDateValue !== '') {
        setError('initialDate', {
          type: 'required',
          message: t('CandidatesTable.InputCalendarError2'),
        });
        return;
      }
      if (initialDateValue !== '' && endDateValue === '') {
        setError('endDate', {
          type: 'required',
          message: t('CandidatesTable.InputCalendarError'),
        });
        return;
      }
      if (refSearchData.current.initialDate !== '' && endDateValue !== '') {
        const validateInitialDate = new Date(
          refSearchData.current.initialDate!
        );
        const validateEndDate = new Date(refSearchData.current.endDate!);
        if (validateInitialDate > validateEndDate) {
          setError('endDate', {
            type: 'required',
            message: t('CandidatesTable.InputCalendarError3'),
          });
          return;
        }

        if (refSearchData.current.initialDate) {
          newSearchParams.set('StartDate', refSearchData.current.initialDate);
        }
        if (refSearchData.current.endDate) {
          newSearchParams.set('EndDate', refSearchData.current.endDate);
        }
      }
      clearErrors();
      setSearchParams(newSearchParams);
    }
  };

  const clearSearchParams = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('Name');
    newSearchParams.delete('Id');
    newSearchParams.delete('Email');
    newSearchParams.delete('StartDate');
    newSearchParams.delete('EndDate');
    setSearchParams(newSearchParams);
  };

  const setSingleSearchParam = (key: string, value: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  // const handleScore = useCallback(() => {
  //   dispatch(candidatesApi.util.invalidateTags(['Candidates']));
  // }, [dispatch]);

  // useEffect(() => {
  //   const liveScore = new EventSource(SIMERA_SSE_ROUTE);

  //   liveScore.addEventListener(SseEventTypes.score, handleScore);

  //   return () => liveScore.close();
  // }, [handleScore]);

  return (
    <div className={classes.content} data-testid="table-container">
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>{t('CandidatesTable.Candidates')}</h1>
        <div className={classes.modalButtonsContainer}>
          <AddNewImportCandidateButton
            setImportModalIsOpen={setImportModalIsOpen}
            importCandidate
          />
          <AddNewImportCandidateButton setModalIsOpen={setModalIsOpen} />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className={classes.Modal}
        overlayClassName={classes.Overlay}
        contentLabel="Import Candidates"
        ariaHideApp={false}
      >
        <CandidateInternalForm setModalIsOpen={setModalIsOpen} />
      </Modal>
      <Modal
        isOpen={importModalIsOpen}
        onRequestClose={() => setImportModalIsOpen(false)}
        className={classes.Modal}
        overlayClassName={classes.Overlay}
        contentLabel="Add New Candidate"
        ariaHideApp={false}
      >
        <ImportCandidatesModal />
      </Modal>
      <header className={classes.header}>
        <form onSubmit={handleSearch}>
          <div className={classes.formContainer}>
            <div className={classes.gridItem}>
              <div className={classes.calendarInput}>
                <p className={classes.InputLabel}>
                  {t('CandidatesTable.SearchLabel')}
                </p>
                <div className={classes.filterInputContainer}>
                  <input
                    type="text"
                    data-testid="search-input"
                    className={classes.searchBar}
                    name={search.name}
                    ref={search.ref}
                    onChange={search.onChange}
                    onBlur={search.onBlur}
                    placeholder={t('common.search')}
                  />
                  <div className={classes.filterIcon}>
                    <FilterIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.gridItem}>
              <div className={classes.calendarInput}>
                <p className={classes.calendarLabel}>
                  {t('CandidatesTable.InitialDateLabel')}
                </p>
                <input
                  type="month"
                  data-testid="initial-date-input"
                  className={classes.InputMonth}
                  placeholder="YYYY-MM"
                  name={initialDate.name}
                  ref={initialDate.ref}
                  onChange={initialDate.onChange}
                  onBlur={initialDate.onBlur}
                />
              </div>
            </div>
            <div className={classes.gridItem}>
              <div className={classes.calendarInput}>
                <p className={classes.calendarLabel}>
                  {t('CandidatesTable.EndDateLabel')}
                </p>
                <input
                  type="month"
                  data-testid="end-date-input"
                  className={classes.InputMonth}
                  placeholder="YYYY-MM"
                  name={endDate.name}
                  ref={endDate.ref}
                  onChange={endDate.onChange}
                  onBlur={endDate.onBlur}
                />
              </div>
            </div>

            <div className={`${classes.gridItem} ${classes.actions} `}>
              <button
                className={classes.calendarButton}
                disabled={
                  !isDirty ||
                  (searchValue === '' &&
                    endDateValue === '' &&
                    initialDateValue === '')
                }
                type="submit"
                data-testid="apply-filter-button"
              >
                {t('CandidatesTable.ApplyFilter')}
              </button>
            </div>
            <div
              className={`${classes.gridItem} ${classes.actions} `}
              style={{
                fontSize: '1rem',
              }}
            >
              <HoverTooltip text="Clear filters">
                <div>
                  <button
                    aria-label="Clear filters"
                    className={classes.calendarClearButton}
                    type="button"
                    data-testid="clear-filter-button"
                    onClick={() => {
                      clearSearchParams();
                      reset({
                        search: undefined,
                      });
                    }}
                  >
                    <ClearIcon fill="#716f6f8b" />
                  </button>
                </div>
              </HoverTooltip>
            </div>
            <div className={classes.gridItem} style={{ height: '2.5rem' }} />
            <div className={classes.gridItem}>
              {errors.initialDate && (
                <p className={classes.ErrorText}>
                  {errors.initialDate.message ||
                    t('CandidatesTable.InputCalendarError4')}
                </p>
              )}
            </div>
            <div className={classes.gridItem}>
              {errors.endDate && (
                <p className={classes.ErrorText}>
                  {errors.endDate.message ||
                    t('CandidatesTable.InputCalendarError4')}
                </p>
              )}
            </div>
            <div className={classes.gridItem} />
            <div className={classes.gridItem} />
          </div>
        </form>
      </header>
      <CandidatesTable
        candidatesData={data?.data ?? []}
        loading={isLoading}
        pageCount={data?.meta.pageCount ?? 0}
        pageSize={candidatesQuery.take}
        pageState={candidatesQuery.page}
        hasPreviousPage={!!data?.meta.hasPreviousPage}
        hasNextPage={!!data?.meta.hasNextPage}
        setPageSize={(pageSize) =>
          setSingleSearchParam('take', pageSize.toString())
        }
        setPage={(pageVal) => setSingleSearchParam('page', pageVal.toString())}
      />
    </div>
  );
};

export default SimeraCandidatesScreen;
