import { FC } from 'react';
import PositionsPageInternal from '../Positions/PositionsPageInternal/PositionsPageInternal';
import SimeraDashboardLayout from './SimeraDashboardLayout';
import InternalDashboardPages from '../../constants/InternalDashboardPages';
import SinglePosition from '../Positions/SinglePositionPage';
import SimeraCandidatesScreen from '../Screens/SimeraCandidatesScreen/SimeraCandidatesScreen';
import SimeraCompaniesScreen from '../Screens/SimeraCompaniesScreen/SimeraEmployersScreen';
import NotesScreen from '../Screens/CandidateNotesScreen/NotesScreen/NotesScreen';
import SimeraCompanyScreen from '../Screens/SimeraCompanyScreen/SimeraCompanyScreen';
import SimeraEmployersScreen from '../Screens/SimeraEmployersScreen/SimeraUsersScreen';

interface SimeraDashboardProps {
  page: InternalDashboardPages;
}
const SimeraDashboard: FC<SimeraDashboardProps> = ({ page }) => {
  const router: {
    [key: number]: JSX.Element;
  } = {
    [InternalDashboardPages.POSITIONS]: <PositionsPageInternal />,
    [InternalDashboardPages.POSITION]: <SinglePosition />,
    [InternalDashboardPages.CANDIDATES]: <SimeraCandidatesScreen />,
    [InternalDashboardPages.COMPANIES]: <SimeraCompaniesScreen />,
    [InternalDashboardPages.COMPANY]: <SimeraCompanyScreen />,
    [InternalDashboardPages.EMPLOYERS]: <SimeraEmployersScreen />,
    [InternalDashboardPages.NOTES]: <NotesScreen />,
  };

  return (
    <SimeraDashboardLayout activeTab={page}>
      {router[page]}
    </SimeraDashboardLayout>
  );
};

export default SimeraDashboard;
