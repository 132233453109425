import { FC } from 'react';
import Modal from 'react-modal';
import { CandidateAdditionalVideoType } from '$types/candidates';
import classes from './CandidateVideosModal.module.css';
import CandidateVideosModalContent from './CandidateVideosModalContent';

interface Props {
  modalIsOpen: boolean;
  handleClose: () => void;
  candidateId: number;
  primary?: boolean;
  positionId: number;
  additionalVideos: CandidateAdditionalVideoType[];
}

const CandidateVideosModal: FC<Props> = ({
  modalIsOpen,
  handleClose,
  candidateId,
  primary,
  positionId,
  additionalVideos,
}) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);

  return (
    <Modal
      isOpen={
        modalIsOpen &&
        additionalVideos !== undefined &&
        additionalVideos?.length > 0
      }
      onRequestClose={handleClose}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      contentLabel="Candidate videos"
      ariaHideApp={false}
    >
      <CandidateVideosModalContent
        primary={primary}
        candidateId={candidateId}
        handleClose={handleClose}
        positionId={positionId}
        additionalVideos={additionalVideos}
      />
    </Modal>
  );
};

CandidateVideosModal.defaultProps = {
  primary: false,
};
export default CandidateVideosModal;
