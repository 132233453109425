import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  DeleteCVInput,
  DeleteCVOutput,
  UploadBuilder,
  UploadCVOutput,
} from '../uploads.types';

export const deleteCV = (builder: UploadBuilder) =>
  builder.mutation<DeleteCVOutput, DeleteCVInput>({
    query: (args) => ({
      url: `/upload/cv/${args.candidateId}`,
      method: 'DELETE',
      body: args,
    }),
    invalidatesTags: () => [{ type: 'Upload', id: 'List' }],
    transformResponse: (res: UploadCVOutput, meta, args) => {
      successHandler(
        (args.language ?? 'en') === 'en'
          ? SuccessMessageEn.DeleteSuccess
          : SuccessMessageEs.DeleteSuccess,
        true
      );
      return res;
    },
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[uploadCVAction]',
        args.language === 'es'
          ? ErrorMessageEs.DeleteError
          : ErrorMessageEn.DeleteError,
        err,
        true
      );
      return err.data;
    },
  });
