import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { AdditionalVideo } from '../../../../types/additional-video/additional-video';
import { errorHandler, successHandler } from '../../../utils';
import {
  AdditionalVideoBuilder,
  UpdateAdditionalVideoInput,
  UpdateAdditionalVideoOutput,
} from '../additional-videos.types';

export const updateAdditionalVideo = (builder: AdditionalVideoBuilder) =>
  builder.mutation<UpdateAdditionalVideoOutput, UpdateAdditionalVideoInput>({
    query: ({ id, data }) => ({
      url: `additional-video/${id}`,
      method: 'PATCH',
      body: data,
    }),
    invalidatesTags: [{ type: 'additionalVideos', id: 'LIST' }],
    transformResponse: (response: AdditionalVideo, meta, { lang }) => {
      successHandler(
        lang === 'es'
          ? SuccessMessageEs.UpdateAdditionalVideo
          : SuccessMessageEn.UpdateAdditionalVideo,
        true
      );
      return response;
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        'updateAdditionalVideo',
        lang === 'es'
          ? ErrorMessageEs.UpdateAdditionalVideoError
          : ErrorMessageEn.UpdateAdditionalVideoError,
        error,
        true
      );
      return error;
    },
  });
