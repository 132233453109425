import {
  GetCandidateInterviewsOutput,
  InterviewBuilder,
} from '../interview.types';

export const getCandidateInterviews = (builder: InterviewBuilder) =>
  builder.query<GetCandidateInterviewsOutput[], void>({
    query: () => ({
      url: '/interview/candidate',
      method: 'GET',
    }),
    providesTags: () => [{ type: 'Slots' }],
  });
