import { createApi } from '@reduxjs/toolkit/query/react';
import { updateEmployer } from './mutations';
import { loadEmployersPaginated } from './queries';
import { baseQuery } from '../../utils/baseQuery';
import { ReducerPaths } from '../../constants/reducerPaths';

export const employersApi = createApi({
  reducerPath: ReducerPaths.EMPLOYERS,
  baseQuery,
  endpoints: (builder) => ({
    updateEmployer: updateEmployer(builder),
    loadEmployersPaginated: loadEmployersPaginated(builder),
  }),
});

export const { useLoadEmployersPaginatedQuery, useUpdateEmployerMutation } =
  employersApi;
