import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { getAdditionalDocumentsByCandidateId } from './queries';
import {
  deleteAdditionalDocument,
  saveAdditionalDocument,
  updateVisibility,
} from './mutations';

export const additionalDocumentApi = createApi({
  reducerPath: ReducerPaths.ADDITIONAL_DOCUMENT,
  baseQuery,
  endpoints: (builder) => ({
    getAdditionalDocumentsByCandidateId:
      getAdditionalDocumentsByCandidateId(builder),

    saveAdditionalDocument: saveAdditionalDocument(builder),
    updateVisibility: updateVisibility(builder),
    deleteAdditionalDocument: deleteAdditionalDocument(builder),
  }),
});

export const {
  useGetAdditionalDocumentsByCandidateIdQuery,
  useLazyGetAdditionalDocumentsByCandidateIdQuery,
  useSaveAdditionalDocumentMutation,
  useUpdateVisibilityMutation,
  useDeleteAdditionalDocumentMutation,
} = additionalDocumentApi;
