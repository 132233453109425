import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '$constants/alerts';
import { errorHandler, successHandler } from '$store/utils';
import { ValidLanguages, userLanguage } from '$translations/index';
import {
  InterviewBuilder,
  SetInterviewSlotInput,
  SetInterviewSlotOutput,
} from '../interview.types';

export const setInterviewSlots = (builder: InterviewBuilder) =>
  builder.mutation<SetInterviewSlotOutput[], SetInterviewSlotInput>({
    query: ({ schedules, candidateId, candidatePositionStatusId }) => ({
      url: '/interview/slots',
      method: 'POST',
      body: {
        Schedules: schedules,
        CandidateId: candidateId,
        CandidatePositionStatusId: candidatePositionStatusId,
      },
    }),
    invalidatesTags: [{ type: 'Slots' }],
    transformResponse: (response: SetInterviewSlotOutput[]) => {
      successHandler(
        userLanguage === 'es'
          ? SuccessMessageEs.ScheduleSaved
          : SuccessMessageEn.ScheduleSaved,
        true
      );
      return response;
    },
    transformErrorResponse: (err) => {
      errorHandler(
        '[SetInterviewSlots]',
        userLanguage === ValidLanguages.es
          ? ErrorMessageEs.ScheduleSaveError
          : ErrorMessageEn.ScheduleSaveError,
        err,
        true
      );
      return err;
    },
  });
