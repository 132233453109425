import {
  ErrorMessageEn,
  ErrorMessageEs,
  // SuccessMessageEn,
  // SuccessMessageEs,
} from '$constants/alerts';
import { errorHandler /* , successHandler */ } from '$store/utils';
import { ValidLanguages, userLanguage } from '$translations/index';
import {
  InterviewBuilder,
  InternalCreateAndSetInterviewInput,
} from '../interview.types';

export const internalCreateAndSetInterview = (builder: InterviewBuilder) =>
  builder.mutation<any, InternalCreateAndSetInterviewInput>({
    query: ({ interviewId, startDate, endDate, timezone }) => ({
      url: `/interview/${interviewId}`,
      method: 'POST',
      body: {
        StartDate: startDate,
        EndDate: endDate,
        Timezone: timezone,
      },
    }),
    invalidatesTags: [{ type: 'Slots' }],
    // transformResponse: (response) => {
    //   successHandler(
    //     userLanguage === 'es'
    //       ? SuccessMessageEs.SuccessfullyDeleted
    //       : SuccessMessageEn.SuccessfullyDeleted,
    //     true
    //   );
    //   return response;
    // },
    transformErrorResponse: (err) => {
      errorHandler(
        '[DeleteInterviewSlot]',
        userLanguage === ValidLanguages.es
          ? ErrorMessageEs.DeleteInterviewSlot
          : ErrorMessageEn.DeleteInterviewSlot,
        err,
        true
      );

      return err;
    },
  });
