import { Auth0DecodedHash, Auth0UserProfile } from 'auth0-js';
import CandidateProfileDto from '../../../types/serverTypes/candidateProfileDto';
import ProfilePictureDto from '../../../types/serverTypes/profilePictureDto';
import CurriculumDto from '../../../types/serverTypes/curriculumDto';
import ICombinedResponse from '../../../types/combinedResponse';
import { SaveState } from '../../../types/ForrmType';
import CandidateEntity from '../../../types/entities/candidate.entity';
import { InternalEntity } from '../../../types/entities/internal.entity';
import EmployerDto from '../../../types/serverTypes/employerDto';
import { UserDto } from '../../../types/serverTypes/userDto';
import { Auth0ResultExt } from '../../../types/candidates';

export enum AuthActionTypes {
  RESET_PASSWORD_AUTH_START = 'RESET_PASSWORD_AUTH_START',
  RESET_PASSWORD_AUTH_SUCCESS = 'RESET_PASSWORD_AUTH_SUCCESS',
  RESET_PASSWORD_AUTH_FAIL = 'RESET_PASSWORD_AUTH_FAIL',
  EMAIL_AUTH_START = 'EMAIL_AUTH_START',
  EMAIL_AUTH_SUCCESS = 'EMAIL_AUTH_SUCCESS',
  EMAIL_AUTH_FAIL = 'EMAIL_AUTH_FAIL',
  EMAIL_LOGIN_START = 'EMAIL_LOGIN_START',
  EMAIL_LOGIN_SUCCESS = 'EMAIL_LOGIN_SUCCESS',
  EMAIL_LOGIN_FAIL = 'EMAIL_LOGIN_FAIL',
  INIT_AUTH_START = 'INIT_AUTH_START',
  INIT_AUTH_SUCCESS = 'INIT_AUTH_SUCCESS',
  INIT_AUTH_FAIL = 'INIT_AUTH_FAIL',
  LOGOUT_START = 'LOGOUT_START',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAIL = 'LOGOUT_FAIL',
  EMPLOYER_EMAIL_AUTH_START = 'EMPLOYER_EMAIL_AUTH_START',
  EMPLOYER_EMAIL_AUTH_SUCCESS = 'EMPLOYER_EMAIL_AUTH_SUCCESS',
  EMPLOYER_EMAIL_AUTH_FAIL = 'EMPLOYER_EMAIL_AUTH_FAIL',
  GOOGLE_AUTH_START = 'GOOGLE_AUTH_START',
  GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS',
  GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL',
  LINKEDIN_AUTH_START = 'LINKEDIN_AUTH_START',
  LINKEDIN_AUTH_SUCCESS = 'LINKEDIN_AUTH_SUCCESS',
  LINKEDIN_AUTH_FAIL = 'LINKEDIN_AUTH_FAIL',
  INTERNAL_EMAIL_AUTH_START = 'INTERNAL_EMAIL_AUTH_START',
  INTERNAL_EMAIL_AUTH_SUCCESS = 'INTERNAL_EMAIL_AUTH_SUCCESS',
  INTERNAL_EMAIL_AUTH_FAIL = 'INTERNAL_EMAIL_AUTH_FAIL',
  UPDATE_VERSION = 'UPDATE_VERSION',
  UPDATE_BASIC_INFO = 'UPDATE_BASIC_INFO',
  CONFIRM_EMAIL_START = 'CONFIRM_EMAIL_START',
  CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_FAIL = 'CONFIRM_EMAIL_FAIL',
  RESEND_CONFIRMATION_EMAIL_START = 'RESEND_CONFIRMATION_EMAIL_START',
  RESEND_CONFIRMATION_EMAIL_SUCCESS = 'RESEND_CONFIRMATION_EMAIL_SUCCESS',
  RESEND_CONFIRMATION_EMAIL_FAIL = 'RESEND_CONFIRMATION_EMAIL_FAIL',
  UPDATE_CANDIDATE_SCORE = 'UPDATE_CANDIDATE_SCORE',
  SESSION_EXPIRED_IN_BACKEND = 'SESSION_EXPIRED_IN_BACKEND',
  SESSION_EXPIRED_IN_BACKEND_REDIRECT = 'SESSION_EXPIRED_IN_BACKEND_REDIRECT',
}

interface BaseAction {
  type: AuthActionTypes | string;
  error?: Error | string;
}

export interface AuthAction extends BaseAction {
  combinedResponses?: ICombinedResponse;
  auth0User?: Auth0UserProfile | null;
  candidateProfileDto?: CandidateProfileDto;
  auth0Response?: Auth0ResultExt;
  isLoggedIn?: boolean;
  resetPasswordMessage?: string;
  score?: number;
  version?: string;
  Image?: ProfilePictureDto;
  employerEmail?: string;
  internalEmail?: string;
  confirmToken?: string;
  Curriculum?: CurriculumDto;
}

export interface AuthState {
  loading: boolean;
  isLoggedIn: boolean;
  error?: boolean;
  errorMessage?: string;
  auth0Response?: Auth0DecodedHash | Auth0ResultExt | Auth0UserProfile | null;
  auth0User?: Auth0UserProfile | null;
  serverUser?: UserDto | null;
  serverCandidate?: CandidateEntity | null;
  serverEmployer?: EmployerDto | null;
  serverInternal?: InternalEntity | null;
  version?: string;
  emailConfirmError?: boolean;
  loadingConfirmEmail?: SaveState;
  employer?: {
    employerRegisterSuccess: boolean | undefined;
    employerEmail?: string | undefined;
  };
  internal: {
    internalRegisterSuccess: boolean | undefined;
    internalEmail?: string | undefined;
  };
  resendConfirmationEmail: boolean | null;
  sessionExpiredInBackend: boolean;
}
