import {
  InterviewBuilder,
  ModifySingleInterviewStatusInput,
  ModifySingleInterviewStatusOutput,
} from '../interview.types';

export const modifySingleInterviewStatus = (builder: InterviewBuilder) =>
  builder.mutation<
    ModifySingleInterviewStatusOutput,
    ModifySingleInterviewStatusInput
  >({
    query: ({ interviewId, status }) => ({
      url: `/interview/${interviewId}`,
      method: 'PATCH',
      body: { Status: status },
    }),
    // Temporaly disabled to keep the candidate on the page so the user can change again the status
    // invalidatesTags: (result, meta, { interviewId }) =>
    //   interviewId
    //     ? [{ type: 'JobInterview', id: interviewId }]
    //     : [{ type: 'JobInterview' }],
  });
