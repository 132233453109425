import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import App from './App';
import { i18nextInstance } from './translations';

const Auth0Domain: string = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
const Auth0ClientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID ?? '';
// if (process.env.NODE_ENV !== 'development') // temporaly enabled for all environments
Sentry.init({
  dsn:
    process.env.REACT_APP_SENTRY_DSN ??
    'https://04a44d0c1668d12c6ea20ba01d93d8ff@o4506072464293888.ingest.us.sentry.io/4506072466653184',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.reportingObserverIntegration(),
  ],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE) ?? 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [process.env.REACT_APP_API_URL as string],
});

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={Auth0Domain}
      clientId={Auth0ClientId}
      redirectUri={window.location.origin}
      useRefreshTokens
    >
      <I18nextProvider i18n={i18nextInstance}>
        <Provider store={store}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </Provider>
      </I18nextProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
