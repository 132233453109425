import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetCandidateJobInterestQuery } from '../../store/modules/candidate-job-interest';
import { useGetCandidateInfoQuery } from '../../store/modules/candidates';
import Spinner from '../Spinner/Spinner';
import CandidateDetails from './CandidateDetails';
import { useGetCandidateExtracurricularQuery } from '../../store/modules/candidate-extracurricular';

interface Props {
  secondary?: boolean;
  isEmployee?: boolean;
}

const ContainerCandidateDetails: FC<Props> = ({ secondary, isEmployee }) => {
  const { candidateId } = useParams();
  const [, i18n] = useTranslation('global');

  const { isLoading, data } = useGetCandidateInfoQuery(candidateId!);

  const { isLoading: loadingJobInterest } = useGetCandidateJobInterestQuery({
    candidateId: +candidateId!,
    lang: i18n.language as 'en' | 'es',
  });

  const { isLoading: loadingExtracurriculars } =
    useGetCandidateExtracurricularQuery({
      candidateId: +candidateId!,
      lang: i18n.language as 'en' | 'es',
    });

  if (isLoading || !data || loadingJobInterest || loadingExtracurriculars) {
    return <Spinner secondary={secondary} />;
  }
  return (
    <CandidateDetails
      secondary={secondary}
      isEmployee={isEmployee}
      candidate={data}
    />
  );
};

ContainerCandidateDetails.defaultProps = {
  secondary: false,
  isEmployee: false,
};

export default ContainerCandidateDetails;
