import { ChangeEventHandler, FC, Ref } from 'react';
import { ChangeHandler } from 'react-hook-form';
import classes from './SelectInput.module.css';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import Tooltip from '../Tooltip/Tooltip';

interface SelectInputProps {
  label?: string;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLSelectElement> | undefined;
  onBlur: ChangeHandler;
  refProp: Ref<any>;
  name: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > | null;
  options: { id: string | number; text: string }[];
  hasToolTip?: boolean;
  tooltipContent?: React.ReactNode;
  className?: string;
  errorText?: string;
  height?: string;
  weight?: 'normal' | 'bold';
  isRequired?: boolean;
  secondary?: boolean;
}

const SelectInput: FC<SelectInputProps> = ({
  label,
  placeholder,
  name,
  onChange,
  onBlur,
  refProp,
  icon: Icon,
  options,
  hasToolTip,
  className,
  errorText,
  tooltipContent: Content,
  height,
  weight,
  isRequired,
  secondary,
}) => {
  return (
    <div data-testid="select-input" className={`${className}`}>
      <label
        data-testid="degree-type-input"
        className={classes.DropdownLabel}
        htmlFor="select-dropdown"
      >
        {label ? (
          <div className={`${classes.header} select-tt`}>
            <p
              className={`${classes.labelText} ${
                isRequired && classes.required
              } `}
              style={{
                fontWeight: weight === 'bold' ? 600 : '',
              }}
            >
              {label}
            </p>
            {hasToolTip && <Tooltip>{Content}</Tooltip>}
          </div>
        ) : (
          <div style={{ marginTop: '1rem' }} />
        )}
        <div className={classes.center}>
          <select
            className={`${Icon ? classes.Dropdown : classes.Dropdown__NoIcon} ${
              secondary
                ? classes.backgroundColorInternal
                : classes.backgroundColor
            }`}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            ref={refProp}
            defaultValue=""
            style={{ height }}
          >
            {placeholder && (
              <option disabled hidden value="">
                {placeholder}
              </option>
            )}
            {options.map((option) => (
              <option
                key={option.id}
                className={classes.option}
                value={option.id}
              >
                {option.text}
              </option>
            ))}
          </select>
          {Icon && (
            <div className={classes.Icon}>
              <Icon />
            </div>
          )}

          <div className={classes.Arrow}>
            <ArrowDown />
          </div>
        </div>
      </label>
      {errorText !== '' && (
        <p className={classes.ErrorText} data-testid="input-error">
          {errorText}
        </p>
      )}
    </div>
  );
};

SelectInput.defaultProps = {
  icon: null,
  placeholder: undefined,
  label: undefined,
  hasToolTip: false,
  tooltipContent: null,
  className: '',
  errorText: '',
  height: '',
  weight: 'normal',
  isRequired: false,
  secondary: false,
};
export default SelectInput;
