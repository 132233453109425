import { useCallback, useEffect, useState } from 'react';
import { useLazyGetAdditionalDocumentsByCandidateIdQuery } from '$store/modules/additional-documents';
import { useLazyGetAdditionalTagsByCandidateIdQuery } from '$store/modules/additional-tags';
import {
  useLazyGetAdditionalVideosByCandidateIdQuery,
  useLazyGetEnglishAssessmentQuery,
} from '$store/modules/additional-videos';
import { useLazyGetCandidateEducationQuery } from '$store/modules/candidate-education';
import { useLazyGetCandidateJobInterestQuery } from '$store/modules/candidate-job-interest';
import { useLazyGetCandidateLanguagesQuery } from '$store/modules/candidate-language';
import { useLazyGetCandidateSkillsQuery } from '$store/modules/candidate-skill';
import { useLazyGetCvQuery } from '$store/modules/uploads';
import { useLazyGetWorkHistoryByCandidateIdQuery } from '$store/modules/work-experience';
import { userLanguage } from '$translations/index';
import computeYearsOfExperience from '$utils/computeYearsOfExperience';
import PositionInternalDto from '$types/serverTypes/positionInternalDto';
import { useLazyGetCandidateInterviewByCPSQuery } from '$store/modules/interview';

const GetCandidateDataByMultipleFetch = (
  position: PositionInternalDto | undefined
) => {
  const [data, setData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  /** Queries */
  const [getAdditionalVideoByCandidateId] =
    useLazyGetAdditionalVideosByCandidateIdQuery();
  const [getCandidateEducation] = useLazyGetCandidateEducationQuery();
  const [getCandidatesJobInterest] = useLazyGetCandidateJobInterestQuery();
  const [getCandidateLanguages] = useLazyGetCandidateLanguagesQuery();
  const [getCandidateSkills] = useLazyGetCandidateSkillsQuery();
  const [getCV] = useLazyGetCvQuery();
  const [getCandidateWorkEHistory] = useLazyGetWorkHistoryByCandidateIdQuery();
  const [getAddtionalDocuments] =
    useLazyGetAdditionalDocumentsByCandidateIdQuery();
  const [getAdditionalTags] = useLazyGetAdditionalTagsByCandidateIdQuery();
  const [getEnglishAssessment] = useLazyGetEnglishAssessmentQuery();
  const [getCandidateInterviewByCPS] = useLazyGetCandidateInterviewByCPSQuery();

  const getData = useCallback(() => {
    if (position?.CandidatesPositionStatus) {
      setIsLoading(true);

      const candidateExtraData = position.CandidatesPositionStatus.map(
        async (item) => {
          const tempData = { ...item.Candidate };
          tempData.CandidatesPositionStatus = [
            {
              Status: item.Status,
              CreateTime: item.CreateTime,
              Id: item.Id,
            },
          ];

          /** Parent props */
          delete tempData.AddresLine1;
          delete tempData.AddresLine2;
          delete tempData.AgreedTermsOfServiceDate;
          delete tempData.Completed;
          delete tempData.IsFirstLogin;
          delete tempData.LinkedinUrl;
          delete tempData.ResidenceCountry;
          delete tempData.WhatsAppNumber;

          /** User props */
          tempData.User = { ...item.Candidate.User };
          delete tempData.User.EmailVerified;
          delete tempData.User.ConfirmToken;
          delete tempData.User.Id;
          delete tempData.User.Phone;
          delete tempData.User.SignUpType;

          /** User ProfilePicture */
          tempData.User.ProfilePicture = { ...tempData.User.ProfilePicture };
          delete tempData.User.ProfilePicture.Key;

          await Promise.all([
            getAdditionalVideoByCandidateId({
              candidateId: tempData.Id,
              lang: userLanguage,
            }).then((response) => {
              const tempVideoData =
                response?.data?.map((singleVideo) => {
                  const tempSingleVideo: any = { ...singleVideo };
                  delete tempSingleVideo.CreateTime;
                  delete tempSingleVideo.IsVisible;
                  delete tempSingleVideo.ShareReferenceId;
                  return tempSingleVideo;
                }) || [];
              tempData.AdditionalVideos = tempVideoData;
            }),
            getCandidateEducation({
              candidateId: tempData.Id,
              lang: userLanguage,
            }).then((response) => {
              tempData.CandidateEducation = [
                ...(response?.data ? response.data : []),
              ].map((educationData) => {
                return {
                  Id: educationData?.Id,
                  DegreeName: educationData?.DegreeName?.val,
                  DegreeType: educationData?.DegreeType?.val,
                };
              });
            }),
            getCandidatesJobInterest({
              candidateId: tempData.Id,
              lang: userLanguage,
            }).then((response: any) => {
              tempData.CandidateJobInterest = response.data
                ? {
                    Id: response?.data?.Id,
                    SalaryLowerBound: response.data?.Salary[0],
                    SalaryUpperBound: response.data?.Salary[1],
                  }
                : null;
            }),
            getCandidateLanguages({
              candidateId: tempData.Id,
              lang: userLanguage,
            }).then((response) => {
              tempData.CandidateLanguages =
                response?.data?.map((singleLanguage) => {
                  return {
                    Id: singleLanguage?.Id,
                    LanguageName: singleLanguage?.LanguageName?.val,
                  };
                }) || [];
            }),
            getCandidateSkills({
              candidateId: tempData.Id,
              lang: userLanguage,
            }).then((response) => {
              tempData.CandidateSkills = [
                ...(response?.data ? response.data : []),
              ].map((singleSkill) => {
                return {
                  Id: singleSkill?.Id,
                  Skill: singleSkill?.Skill,
                };
              });
            }),
            getCV({ candidateId: tempData.Id }).then((response) => {
              tempData.Curriculum = {
                Url: response?.data?.Url,
                Id: response?.data?.Id,
              };
            }),
            getCandidateWorkEHistory({
              candidateId: tempData.Id,
              lang: userLanguage,
            }).then((response) => {
              tempData.CandidateWorkExperience = (
                response?.data ? [...response.data] : []
              ).map((singleWorkHistory) => {
                return {
                  Admission: singleWorkHistory?.Admission?.val
                    ? new Date(singleWorkHistory?.Admission?.val).toISOString()
                    : null,
                  Company: singleWorkHistory?.Company?.val || null,
                  Egress: singleWorkHistory?.Egress?.val
                    ? new Date(singleWorkHistory?.Egress?.val).toISOString()
                    : null,
                  Id: singleWorkHistory?.Id,
                  JobTitle: singleWorkHistory?.JobTitle.val || null,
                };
              });
              tempData.YearsOfExperience = computeYearsOfExperience(
                tempData.CandidateWorkExperience
              );
            }),
            getAddtionalDocuments({
              candidateId: tempData.Id,
              lang: userLanguage,
            }).then((response) => {
              tempData.Files = (response?.data ? response.data : []).map(
                (singleFile) => {
                  const tempSingleFile: any = { ...singleFile };
                  delete tempSingleFile.Key;
                  delete tempSingleFile.IsCandidatesVisible;
                  delete tempSingleFile.IsEmployersVisible;
                  return tempSingleFile;
                }
              );
            }),
            getAdditionalTags({
              candidateId: tempData.Id,
              lang: userLanguage,
            }).then((response) => {
              tempData.AdditionalTags = (
                response?.data ? response.data : []
              ).map((singleAdditionalTag) => {
                return {
                  Id: singleAdditionalTag.Id,
                  Name: singleAdditionalTag.title,
                };
              });
            }),
            getEnglishAssessment({ candidateId: tempData.Id }).then((resp) => {
              tempData.Videos = (resp?.data ? resp.data : []).map(
                (singleEnglishAssesment: any) => {
                  return {
                    EnglishAssessment: singleEnglishAssesment?.Score
                      ? {
                          Score: singleEnglishAssesment.Score,
                        }
                      : null,
                    Id: singleEnglishAssesment?.AdditionalVideo?.Id || null,
                    Key: singleEnglishAssesment?.AdditionalVideo?.Key || null,
                    Type: 0, // its unnecessary but just in case somewhere uses it
                    Url: singleEnglishAssesment?.AdditionalVideo?.Url || null,
                  };
                }
              );
            }),
            getCandidateInterviewByCPS({
              candidateId: tempData.Id,
              candidatePositionStatus: item.Id,
            }).then((resp) => {
              tempData.CandidateInterviewStatus = resp?.data;
            }),
          ]);

          return tempData;
        }
      );

      Promise.all(candidateExtraData)
        .then((resp: any) => {
          setData(resp);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    getAdditionalTags,
    getAdditionalVideoByCandidateId,
    getAddtionalDocuments,
    getCV,
    getCandidateEducation,
    getCandidateInterviewByCPS,
    getCandidateLanguages,
    getCandidateSkills,
    getCandidateWorkEHistory,
    getCandidatesJobInterest,
    getEnglishAssessment,
    position?.CandidatesPositionStatus,
  ]);

  const refetch = () => {
    getData();
  };

  useEffect(() => {
    if (position?.CandidatesPositionStatus && !isLoading) {
      if (!data) {
        getData();
      } else if (data) {
        let hasChanges = false;
        setData((previous: any) => {
          position?.CandidatesPositionStatus?.forEach((singleCandidate) => {
            previous.forEach((previousCandidate: any) => {
              if (
                singleCandidate.Candidate.Id === previousCandidate.Id &&
                previousCandidate.CandidatesPositionStatus[0].Status !==
                  singleCandidate.Status
              ) {
                hasChanges = true;
                previousCandidate.CandidatesPositionStatus[0].Status =
                  singleCandidate.Status;
              }
            });
          });
          if (hasChanges) return [...previous];
          return previous;
        });
      }
    }
  }, [position?.CandidatesPositionStatus, getData, isLoading, data]);

  return {
    data,
    isLoading,
    refetch,
  };
};

export default GetCandidateDataByMultipleFetch;
