import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  RequestInterviewInput,
  RequestInterviewOutput,
} from '../companies.types';

export const requestInterviews = (builder: CompanyBuilder) =>
  builder.mutation<RequestInterviewOutput, RequestInterviewInput>({
    query: ({ requestInterview }) => ({
      url: '/candidate-position-status/request-interview',
      method: 'PATCH',
      body: requestInterview,
    }),
    transformResponse: (queryResponse: RequestInterviewOutput, _, arg) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEs.REQUEST_INTERVIEW
          : SuccessMessageEn.REQUEST_INTERVIEW,
        true
      );
      return queryResponse;
    },
    transformErrorResponse: (error, meta, arg) => {
      errorHandler(
        '[requestInterview]',
        arg.lang === 'en'
          ? ErrorMessageEn.requestInterview
          : ErrorMessageEs.requestInterview,
        error,
        true
      );
      return error.data;
    },
    invalidatesTags: () => [{ type: 'Company' }],
  });
