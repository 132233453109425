import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import useAdditionalVideoChunksUploader from '$hooks/useAdditionalVideoChunksUploader';
import classes from './ImportVideoModal.module.css';
import { ReactComponent as CloseIcon } from '../../../../../icons/CloseGreen.svg';
import { TopicInput } from '../TopicInput/TopicInput';
import { DropzoneAdditionalVideo } from '../DropzoneAdditionalVideo';
import { ActionButtons } from '../ActionButtons';
import { useCandidateId } from '../../../../../hooks/useCandidateId';
import { AdditionalVideoFormValues } from '../../../../../types/additional-video/additional-video';
import { VisibilityVideo } from '../VisibilityVideo';

interface Props {
  isModalOpen: boolean;
  setIsOpen: (show: boolean) => void;
}

export const ImportVideoModal = ({ isModalOpen, setIsOpen }: Props) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const [t] = useTranslation('global');
  const candidateId = useCandidateId();
  const { uploadAdditionalVideoByChunks, isLoading } =
    useAdditionalVideoChunksUploader();
  const { control, setValue, handleSubmit, watch, reset, getValues } =
    useForm<AdditionalVideoFormValues>({
      defaultValues: {
        file: null,
        name: '',
        isVisible: true,
      },
    });
  const fileValue = watch('file');
  const visibilityValue = watch('isVisible');

  const handleClose = () => {
    setIsOpen(false);
    reset({
      file: null,
      name: '',
    });
  };
  const saveAdditionalVideo = async (data: AdditionalVideoFormValues) => {
    await uploadAdditionalVideoByChunks({
      videoLabel: data.name,
      videoBlob: data.file as Blob,
      candidateId,
      isVisible: data.isVisible,
    }).finally(() => {
      handleClose();
    });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      className={classes.Modal}
      overlayClassName={{
        base: classes.Overlay,
        afterOpen: classes.OverlayAfterOpen,
        beforeClose: classes.OverlayBeforeClose,
      }}
      contentLabel="Upload video"
      ariaHideApp={false}
      closeTimeoutMS={200}
      bodyOpenClassName={classes.bodyOpen}
    >
      <header className={classes.header}>
        <p className={classes.title}>
          {t('AdditionalVideos.modals.uploadTitle')}
        </p>
        <button
          aria-label="Button Close Icon"
          type="button"
          onClick={() => setIsOpen(false)}
        >
          <CloseIcon fill="var(--primary-green)" />
        </button>
      </header>
      <main>
        <p className={classes.description}>
          {t('AdditionalVideos.modals.uploadDescription')}
        </p>
        <form
          onSubmit={handleSubmit(saveAdditionalVideo)}
          className={classes.formContainer}
        >
          <TopicInput
            setValue={setValue}
            control={control}
            file={fileValue}
            getValues={getValues}
          />
          {fileValue === null ? (
            <DropzoneAdditionalVideo control={control} setValue={setValue} />
          ) : (
            <VisibilityVideo
              file={fileValue}
              setValue={setValue}
              isVisible={visibilityValue}
            />
          )}
          <ActionButtons
            setIsOpen={handleClose}
            disabled={isLoading || fileValue === null}
            isLoading={isLoading}
          />
        </form>
      </main>
    </Modal>
  );
};
