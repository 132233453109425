import { ErrorMessageEn, ErrorMessageEs } from '$constants/alerts';
import { userLanguage } from '$translations/index';
import { errorHandler } from '../../../utils';
import {
  GetPresignedAdditionalVideoChunkUrlInput,
  AdditionalVideoBuilder,
  GetPresignedAdditionalVideoChunkUrlOutput,
} from '../additional-videos.types';

export const getPresignedAdditionalVideoChunkUrl = (
  builder: AdditionalVideoBuilder
) =>
  builder.query<
    GetPresignedAdditionalVideoChunkUrlOutput,
    GetPresignedAdditionalVideoChunkUrlInput
  >({
    query: ({ key, uploadId, partNumber }) => ({
      url: `/additional-video/presigned-url/part-upload-url`,
      method: 'GET',
      params: {
        Key: key,
        UploadId: uploadId,
        PartNumber: partNumber,
      },
    }),
    transformErrorResponse: (error) => {
      errorHandler(
        '[GET PRESIGNED URL ADDITIONAL VIDEO]',
        userLanguage === 'es'
          ? ErrorMessageEs.videoUpload
          : ErrorMessageEn.videoUpload,
        error,
        true
      );
    },
  });
