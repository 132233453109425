import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './TableFooter.module.css';

interface Props {
  pageSize: number;
  pageState: number;
  handleSelectChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  pageCount: number;
  nextPage: () => void;
  previousPage: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  migration?: boolean;
}

const defaultProps = {
  handleSelectChange: () => {},
  migration: false,
};

const TableFooter: FC<Props> = ({
  pageSize,
  handleSelectChange,
  pageState,
  pageCount,
  nextPage,
  previousPage,
  hasNextPage,
  hasPreviousPage,
  migration,
}) => {
  const [t] = useTranslation('global');
  return (
    <div className={classes.tableFooter}>
      <p className={classes.tableShow}>
        {`${t('CandidatesTable.Page')}: ${pageState} ${t(
          'CandidatesTable.Of'
        )} ${pageCount}`}
      </p>

      {!migration && (
        <>
          <p className={classes.tableShow}>{t('CandidatesTable.Show')}</p>
          <select
            value={pageSize}
            onChange={handleSelectChange}
            className={classes.tableSelect}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
        </>
      )}
      <button
        className={classes.nextButton}
        type="button"
        onClick={previousPage}
        disabled={!hasPreviousPage}
      >
        {t('CandidatesTable.Previous')}
      </button>
      <button
        className={classes.previousButton}
        style={{
          background: '#f5f5f5',
          padding: '1rem 2rem',
          margin: '1rem',
        }}
        type="button"
        onClick={nextPage}
        disabled={!hasNextPage}
      >
        {t('CandidatesTable.Next')}
      </button>
    </div>
  );
};

TableFooter.defaultProps = defaultProps;

export default TableFooter;
