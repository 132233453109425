import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import classes from './Application.module.css';
import PositionDto from '../../../../../types/serverTypes/positionDto';
import { useAppSelector } from '../../../../../hooks';

interface Props {
  jobPosition: PositionDto;
}

dayjs.extend(relativeTime);

const Application = ({ jobPosition }: Props) => {
  const { serverCandidate } = useAppSelector((state) => state.auth);
  const [t] = useTranslation('global');

  const status = jobPosition.CandidatesPositionStatus?.find(
    (item) => item.Candidate.Id === serverCandidate?.Id
  );
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <span>
          <span>{t('JobDashboard.JobSuggestions.Status')}</span>
          {status?.Status ?? 'unknown'}
        </span>
        <h3 className={classes.title}>{jobPosition.JobPosition.title}</h3>
      </div>
      <div className={classes.infoContainer}>
        <p className={classes.info}>
          <span>{t('JobDashboard.JobSuggestions.Experience')}</span>
          {`${jobPosition.ExperienceYears} ${
            jobPosition.ExperienceYears === 1
              ? t('JobDashboard.JobSuggestions.Year')
              : t('JobDashboard.JobSuggestions.Years')
          }`}
        </p>
        <p className={classes.info}>
          <span>{t('JobDashboard.JobSuggestions.SalaryRange')}</span>
          {`${jobPosition.SalaryLowerBound} - ${jobPosition.SalaryUpperBound}`}
        </p>
      </div>
      <span className={classes.time}>{`${dayjs(
        jobPosition.CreateTime
      ).fromNow()}`}</span>
    </div>
  );
};

export default Application;
