import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import classes from './LanguageFormContainer.module.css';
import Languages from '../SkillsFormContainer/Languages';
import AddButton from '../Buttons/AddButton';
import { SaveState } from '../../types/ForrmType';
import { FormTypes } from '../../types/user_info';
import FormFooter from '../FormFooter/FormFooter';
import CancelModal from '../CancelModal/CancelModal';
import SaveButton from '../Buttons/SaveButton';
import AddMoreFooter from '../Buttons/AddMoreFooter';
import {
  useAddCandidateLanguageInternalMutation,
  useAddCandidateLanguagesMutation,
  useDeleteCandidateLanguageMutation,
  useGetCandidateLanguagesQuery,
} from '../../store/modules/candidate-language';
import { errorHandler, successHandler } from '../../store/utils';
import { useAppDispatch } from '../../hooks';
import { candidatesApi } from '../../store/modules/candidates';

interface LanguageProps {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean, continueEditing: boolean) => void;
  show?: boolean;
  secondary?: boolean;
}

const LanguageFormContainer: FC<LanguageProps> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  secondary,
}) => {
  const [t, i18next] = useTranslation('global');
  const { candidateId } = useParams();

  const candidateIdFromLocalStorage = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;

  const dispatch = useAppDispatch();

  const id = candidateId || candidateIdFromLocalStorage;

  const { data: languages } = useGetCandidateLanguagesQuery({
    candidateId: +id,
    lang: i18next.language as 'en' | 'es',
  });

  const [
    saveLanguageInternal,
    { isLoading: isLoadingInternal, isSuccess: isSuccessInternal },
  ] = useAddCandidateLanguageInternalMutation();
  const [saveLanguage, { isLoading, isSuccess }] =
    useAddCandidateLanguagesMutation();
  const [deleteLanguage] = useDeleteCandidateLanguageMutation();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { dirtyFields, isDirty, errors },
  } = useForm({
    defaultValues: { languages },
  });

  const {
    fields: fieldsLanguages,
    append: appendLanguage,
    remove: removeLanguage,
  } = useFieldArray({
    control,
    name: 'languages' as never,
  });

  const [numberOfLanguages, setNumberOfLanguages] = useState(
    fieldsLanguages.length || 1
  );

  const appendFields = () => {
    if (numberOfLanguages >= 5) {
      return errorHandler(
        '[LanguageFormContainer]',
        'Language information limit reached',
        null,
        true
      );
    }
    appendLanguage({
      LanguageName: { val: '', mult: 1 },
      LanguageProficiency: { val: '', mult: 2 },
      Score: { val: '1', mult: 3 },
    });
    setNumberOfLanguages((s) => s + 1);
    return null;
  };

  const removeFromLanguages = async (index: number, deleteId: number) => {
    await deleteLanguage({
      id: deleteId,
      lang: i18next.language as 'en' | 'es',
    });
    dispatch(candidatesApi.util.invalidateTags(['Score']));
    removeLanguage(index);
    setNumberOfLanguages((count) => count - 1);
  };

  const removeUnsavedCard = (index: number) => {
    removeLanguage(index);
    setNumberOfLanguages((count) => count - 1);
  };

  const saveInfo = async (data: { languages?: FormTypes['languages'][] }) => {
    if (dirtyFields?.languages) {
      const modifiedCardIndexes = Object.keys(dirtyFields?.languages);
      if (data.languages) {
        const modifiedCards = data.languages.filter((_, ind) =>
          modifiedCardIndexes.includes(ind.toString())
        );
        try {
          if (Number(candidateId)) {
            await saveLanguageInternal({
              candidateId: Number(candidateId),
              languages: modifiedCards,
              lang: i18next.language as 'en' | 'es',
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
          } else {
            await saveLanguage({
              languages: modifiedCards,
              lang: i18next.language as 'en' | 'es',
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
          }
          if (setEdit) {
            setEdit(true);
          }
          successHandler(t('common.saved'), true);
          window.analytics.track('Languages form submitted');
          reset(
            {},
            {
              keepValues: true,
            }
          );
        } catch (error) {
          // error
        }
      }
    }
  };
  const buttonState = () => {
    if (isDirty) {
      return SaveState.IDLE;
    }
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }

    if (isSuccess || isSuccessInternal) {
      return SaveState.FULFILLED;
    }

    return SaveState.IDLE;
  };
  return (
    <div className={classes.content} data-testid="language-content">
      <form data-testid="language-form" onSubmit={handleSubmit(saveInfo)}>
        {!isEdit && (
          <div className={classes.ButtonContainer}>
            <AddButton onClick={appendFields} />
          </div>
        )}

        <Languages
          t={t}
          removeLanguage={removeFromLanguages}
          register={register}
          fields={fieldsLanguages}
          setValue={setValue}
          removeUnsaved={removeUnsavedCard}
          errors={errors?.languages}
          secondary={secondary}
        />
        {fieldsLanguages.length > 1 && !isEdit && (
          <AddMoreFooter onClick={appendFields} label={t('languages.add')} />
        )}
        <div className={classes.ButtonContainer}>
          {isEdit ? (
            <FormFooter
              onClickCancel={onClickCancel}
              disabled={buttonState() === SaveState.PENDING || !isDirty}
              saveState={buttonState()}
              translate={t}
              submit
            />
          ) : (
            <SaveButton
              disabled={buttonState() === SaveState.PENDING || !isDirty}
              state={buttonState()}
              t={t}
              submit
            />
          )}
        </div>
        {show && onClickCancelModal && (
          <CancelModal onClickCancelModal={onClickCancelModal} />
        )}
      </form>
    </div>
  );
};

LanguageFormContainer.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  secondary: false,
};
export default LanguageFormContainer;
