import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import classes from './Terms.module.css';

const terms = `
Last updated April 25, 2024

Please read these Terms of Service (“Agreement”, “Terms of Service”, “Terms”) carefully because this 
Agreement constitutes a legally binding contract between Simera, LLC (“Simera”, “us”, “we”, or “our”) 
and you (“You”, “Your, “User”, “Users”, “Candidate”, or “Candidates”). The Terms described in this 
Agreement govern your access to and use of the websites, applications, media, platforms, social media 
and any other online services offered by Simera including without limitation the simera.io website and 
any other website, application, media, software, program, services, tools, features, databases, materials, 
social media, content, products, platforms, forums, or information those offered through our website, 
events, and communications (collectively, the “Service”, or the “Services”). Communications governed by 
this Agreement include communications related to the Services made outside of Simera’s Services, including 
without limitation phone calls, text messages, emails, WhatsApp, video submission platforms, and other 
electronic communications. 

**Acceptance of Terms:** You must agree to and accept all of these Terms or you will not be permitted to use the 
Service. By accessing or using the Service in any way means that you have read, understood, and agree to be 
legally bound by all of these Terms. If you do not agree with any part of these terms, you should discontinue 
using or access the Service immediately.

**Changes to these Terms or the Site Services:** We reserve the right, at our sole discretion, to amend, change, 
modify, revise, or replace these Terms at any time by posting revised Terms of Service. You agree to be responsible 
for reviewing these Terms on a regular basis. If you use any of the Services after a change to the Terms is effective, 
that means you agree to all changes.

## Simera Services

Simera is not a recruiter, headhunter, business process outsourcing firm, staffing firm, or employment agency of any kind. 

Simera is a technology company that provides a marketplace platform (the “Platform”) that allows Users to find and pursue 
contract labor opportunities offered by businesses registered with Simera (a “Client” or “Clients”). The platform allows 
Users to register accounts, create profiles, submit content, search for potential contractor opportunities, get discovered 
by Clients, get contacted by Simera Clients about contractor opportunities, have their profiles and content distributed to 
Clients and businesses not registered with Simera, and interact with Clients.

## Relationship of Parties

Simera does not at any time employ Candidates. Clients of Simera do not at any time employ Candidates. Simera, via its Services, 
provides the means by which Clients discover and communicate with Candidates. In the event that a Client wishes to enter into a 
contractual relationship with a Candidate, Simera facilitates that process with both parties. After the Client and Candidate 
have entered into contractual relationships with Simera, Simera’s responsibilities are to collect funds from the Client on behalf 
of the Candidate, process payments to the Candidate, and perform other administrative and support services to both parties as 
needed. The terms of a Candidate - Client relationship are separate and not included in this Agreement.

Nothing in this Agreement is intended or should be construed to create an employer-employee relationship, partnership, or joint 
venture relationship between Simera and a Candidate or a Simera Client and a Candidate. Any information, including company name, 
role, responsibilities, requirements, or payment amounts, about any Client or any work opportunity offered by a Client that is 
made available to a Candidate by Simera through any means is offered solely for the purpose of informing Candidates about 
potential opportunities for contract work. The information should never be considered an offer of employment, partnership, or 
joint venture.

## Account

**Access to Services:** You must register an account (“Account”) with Simera in order to have full access to our Service. Your 
registration is subject to our approval. We reserve the right to decline a registration for any lawful reason, including without 
limitation cost to maintain data, supply and demand factors, or other business considerations. 

**Eligibility:** When registering a Simera Account you represent and warrant that you are above the age of 18, that you are of 
legal age to form a binding contract, and that the information you provide us is accurate, complete, and current at all times. 
Inaccurate, incomplete, or outdated information may result in the immediate termination of your Account on the Service. 

**Usernames and Passwords:** You are solely responsible for maintaining the confidentiality of your Account and password and for 
restricting access to your Account. Your username and password may not be used by any other person. You agree to accept all 
responsibility for any and all activities or actions that occur under your account, whether your password is with us or a third-party 
service. You agree to notify us upon becoming aware of any breach of security or unauthorized use of your account. Simera will not be 
liable for any acts or omissions by you, including damages of any kind incurred as a result of such acts or omissions. 

**One Account Per User:** You may only register one (1) account with Simera. Registering multiple accounts may result in immediate 
termination of all your registered Accounts on the Service and a lifetime ban of using the Service. 

**Account Access:** Simera may access your Account in order to respond to your requests for technical support, to verify compliance 
with your obligations to Simera, to comply with laws, or to maintain and improve our systems. 

**Use of the Services:** You agree to use the Services only for lawful purposes and in a way that does not infringe the rights of, 
restrict, or inhibit anyone else's use of the Services.

## User Content

**Collection of Personal Data:** We collect personal data such as your name, email address, and telephone number. 

**Submissions of User Content:** The Simera Service allows Users to submit, post, display, provide, upload, and otherwise make 
available (hereinafter referred to as “Submitting”) content including without limitation video files, audio files, written materials, 
skills, assessments, scores, ratings, photos, links to third party content, personal information, contact information, CVs / resumes, 
work history, certificates, education information, references, social media, comments, technology and equipment information, and other 
content or information (“User Content”). 

**Files and Attachments:** You are responsible for scanning any files or attachments sent to you by Simera or Simera Clients for viruses 
and malware. Simera will not be liable for any damages which might occur due to using our Service. 

## User Content Publishing and Distribution

**Your Responsibility for User Content:** You are solely responsible for all your User Content. You represent and warrant that, to the best 
of your knowledge, all your User Content and information you submit to us is truthful and accurate. You shall be solely responsible for your 
User Content and the consequences of posting, publishing it, sharing it, or otherwise making it available via the Services, and you agree 
that we are only acting as a passive conduit for your online distribution and publication of your User Content. You understand and agree that 
you may be exposed to User Content that is inaccurate, objectionable, inappropriate for children, or otherwise unsuited to your purpose, and 
you agree that we shall not be liable for any damages you allege to incur as a result of or relating to any User Content.

**Right to Use:** By submitting any User Content or information on or through the Service, you expressly grant Simera a worldwide, non-exclusive, 
perpetual, irrevocable, royalty-free, fully paid, sublicensable, and transferable license to use, modify, reproduce, modify, publish, list 
information regarding, edit, translate, transcribe, rate, score, distribute, syndicate, publicly display, publicly perform, and make derivative 
works of all User Content and your name, voice, and/or likeness as contained in your User Content, in whole or in part, and in any form, media or 
technology, whether now known or hereafter developed, for use in connection with the Services and our (and our successors' and affiliates') 
business, including without limitation for promoting and redistributing part or all of the Services (and derivative works thereof) in any media 
formats and through any media channels, without prior notice or request for approval. You waive all moral rights in your User Content, and you 
warrant that moral rights have not otherwise been asserted in your User Content. You will not be entitled to any compensation from us if we use 
your User Content or if your name, likeness, or voice is used or conveyed in connection with the Services. Simera has no obligation to provide 
data or analytics about the consumption of User data. 

**Modification of User Content:** Simera has the right, in our sole and absolute discretion, (i) to edit, redact, or modify any User Content; 
(ii) to choose to re-categorize any User Content; (iii) to publish or remove any User Content; and (iv) to request that you submit updated 
versions of User Content.

**Rating and Scoring of User Content:** You agree that Simera may rate and score your User Content for quality control, marketing, machine learning, 
AI, and other purposes. Simera is under no obligation to provide a record of the scores or ratings to you and is under no obligation to provide 
information about how the scores or ratings are generated. You agree that Simera may use the Ratings and Scores for business purposes at its sole 
discretion. 

**Removal of User Content:** We have the right, in our sole and absolute discretion, to screen or delete any User Content at any time and for any 
reason, without notice. You have the right to delete or request the deletion of any User Content you submitted. Some of your User Content may not be 
completely removed and copies of your User Content may continue to exist on servers owned, licensed, or controlled by Simera. To the maximum extent 
permitted by law, we are not responsible or liable for the removal or deletion, or the failure to remove or delete) any of your User Content.

**Prohibited Content:** You agree to not do any of the following: Submit any User Content that i) infringes, misappropriates or violates a third party’s 
patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy; (ii) violates, or 
encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading 
or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment or harm 
against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity; or (vii) 
promotes illegal or harmful activities or substances; post or send unsolicited or unauthorized advertising, promotional materials, email, spam, or other 
form of solicitation. 

## Unsecured Transmission of User Content

You understand that the operation of the Services, including submitting User Content, may be unencrypted and involve (a) transmissions over various networks; 
(b) changes to conform and adapt to technical requirements of connecting networks or devices; (c) transmission to Simera’s third party vendors and hosting 
partners to provide the necessary hardware, software, networking, storage, and related technology required to operate and maintain the Services. You acknowledge 
that you bear sole responsibility for adequate security, protection, and backup of User Content. Simera will have no liability to you for any unauthorized access 
or use of any of your User Content, or any corruption, deletion, destruction, or loss of any User Content. 

## Enforcement & Responsibility

Simera is not obligated to monitor access to or use of the Services or to review or edit User Content. We have the right to investigate violations of these Terms 
or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute Users who violate the law. 

## Third-Party Links and Resources

Simera may allow you to access third-party websites, applications, or other resources (collectively, “Third-Party Services''). We provide access only as a 
convenience and are not responsible for the content, functionality, security, products, content, or services on or available from Third-Party Services. You 
acknowledge sole responsibility for and assume all risk arising from your use of any Third-Party Services.  You are solely responsible for any fees or costs 
or other charges associated with accessing Third-Party Services. 

## Termination

If your Account is suspended or terminated you will be ineligible to use the Services except for administrative purposes. Your User Content will not be visible 
to Clients accessing the Service. Some User Content may remain available to Clients or third parties that have downloaded or saved a copy or copies, or on 
properties not directly controlled by Simera, or on our Services at our sole discretion.  

**Account Suspension or Termination:** We may suspend or terminate your Account and bar access to the Services immediately, without prior notice or liability, 
under our sole discretion, for any reason and without limitation, if we, in our sole discretion, determine that you are violating these Terms or the terms of 
any third-party service provider. Such suspension or termination shall not constitute our breach of these Terms. 

**Account Termination by You:** You may terminate your Account at any time by requesting termination by email or other electronic means. 

**Prohibited Actions While Using the Services**

You agree to adhere to the highest standards of conduct and integrity while using our Services. Failure to conduct yourself with professionalism may result in 
account suspension or termination as well as a lifetime ban on using the services and being included in the Internal Responsibility Release Database.  

**Acting in a misleading or fraudulent way:** You agree to not misrepresent yourself, your experience, skills, professional qualifications. This includes (i) 
lying or misrepresenting your experience, including without limitation the businesses, individuals, or organizations you worked or performed services for; lying 
or misrepresenting your skills, previous job titles, or professional qualifications; (ii) using generative AI or other tools to bolster your descriptions of 
your experience or qualifications; (iii) passing off any part of someone’s work product as your own; (iv) submitting User Content that were not created by you; 
(v) falsely attributing statements to any person or entity, including without limitation references, endorsements, or verifications; (vi) falsely claiming you 
are or were connected to a person or organization.

**Being Unresponsive:** You agree to respond to requests for communication or information in a timely manner, including (i) requests for information, (ii) 
requests to provide User Content, (iii) requests for meetings, (iv) requests for clarifications or updates to previously submitted User Content, from Simera 
personnel, Simera Clients, Simera partners, prospective Simera clients; 

**Treating Others Unfairly:** You agree to respect the rights and dignity of others by (i) refraining from using offensive or vulgar language; (ii) arriving late 
and/or missing scheduled meetings with Simera, Simera clients, Simera partners, or prospective Simera clients; (iii) inciting or encouraging violence; (iv) posting 
personal identifying information or other sensitive, private data about another person. 

**Unauthorized Access or Usage:** You agree to not engage in any of the following activities in connection with your use of the Services: (i) copying, distributing, 
or disclosing any part of the Services in any medium; (ii) automated or non-automated content scraping or downloading; (iii) using any automated system, including 
without limitation robots, spiders, or offline readers to access the Services for any purpose; (iv) transmitting spam or other unsolicited communications; (v) 
attempting to interfere with, compromise the system integrity or security or decipher any transmissions to or from the services running the Services; (vi) taking 
any action that imposes, or may impose, at our sole discretion an unreasonably large load on our infrastructure; (vii) submitting invalid data, viruses, worms, 
trojan horses, or other software agents through the Services; (viii) collecting or harvesting any data, including without limitation information submitted by 
Clients, User Content from other Users, any personally identifiable information, including Client names, from the services; (ix) impersonating another person or 
otherwise misrepresenting your affiliation with a person or entity, conducting fraud, hiding or attempting to hide your identity; (x) interfering with the proper 
functioning of the Service; (xi) accessing any content on the Services through any means other than those provided or authorized by the Services; or (xii) 
bypassing the measures we use to prevent or restrict access to the Services.

## Non Circumvention; Non Solicitation

You agree that during the period over which you have an active Account and for twelve (12) months from the date of last login you will not contact a Simera Client 
or other business you discovered via Simera with the purpose of entering into an employment, contractor, consulting, or partnership relationship without first 
notifying Simera in writing and obtaining Simera’s prior written consent. You agree to notify Simera if a Client contacts you with the purpose of entering into an 
employment, contractor, consulting, or partnership relationship directly. 

## Internal Responsibility Release Database

In the event of an Account termination, we may record your identity and the nature of the cause of the account termination in an Internal Responsibility Release 
Database (“IRRD”). The IRRD is intended solely for business use and may be used to inform third-party stakeholders of the factual circumstances that caused your 
Account Termination. The IRRD is maintained to protect the integrity and reputation value of the Platform and is not intended to degrade, defame, or harm your 
reputation. You agree that the information provided to the IRRD may be used internally and shared with third-party stakeholders, including without limitation 
Simera Clients and other businesses, without consent from you, provided such use is consistent with the intended business purposes stated herein. You release 
Simera from any claims, lawsuits, or damages that arise or are related to the inclusion of your information, content, or data in the IRRD, provided this 
information is used in a manner consistent with these terms. Simera disclaims all liability for any indirect, incidental, consequential, or punitive damages 
arising from the use of the IRRD. 

## Electronic Communications, Transactions, and Signatures

Using Simera’s Platform, sending us emails, text messages, WhatsApp messages, or any other digital communications, and completing online forms constitute 
electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications 
we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE 
OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR 
COMPLETED BY US OR VIA THE SERVICE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any 
jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means 
other than electronic means.

## Errors and Corrections

There may be information presented by Simera, Clients, Simera partners, or other third-parties with access to the Service or transmitted electronically that 
contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. Simera reserves the 
right to correct any errors, inaccuracies, or omissions and to change or update information at any time and without notice. 

## Indemnification

You agree, to the fullest extent permitted by applicable law, to defend, indemnify and hold harmless Simera LLC and its licensee and licensors, and their 
employees, contractors, agents, officers and directors, from and against any and all claims, actions, settlements, damages, obligations, losses, liabilities, 
costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of or relating to (i) your use and access of the 
Service, by you or any person using your account and password, (ii) your User Content, (iii) your violation of the rights of a third party, including but not 
limited to intellectual property rights; (iv) any overt harmful act toward any other user of the Services with whom you connected via the Services or (v) a 
breach of these Terms. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for 
which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify 
you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.

## Limitation of Liability

EXCEPT TO THE EXTENT REQUIRED BY APPLICABLE LAW, IN NO EVENT SHALL SIMERA, LLC, NOR ITS DIRECTORS, EMPLOYEES, PARTNERS, AGENTS, SUPPLIERS, OR AFFILIATES, 
BE LIABLE FOR ANY (A) INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, 
GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE; (II) ANY CONDUCT OR CONTENT OF 
ANY OTHER USERS OR THIRD PARTY ON OR THROUGH THE SERVICE; (III) ANY CONTENT OBTAINED FROM THE SERVICE; AND (IV) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR 
TRANSMISSIONS OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF 
THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.

TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, OUR MAXIMUM LIABILITY TO YOU RELATING TO THE SERVICES OR ARISING OUT OF OR IN ANY WAY CONNECTED TO THESE 
TERMS SHALL NOT EXCEED ONE HUNDRED DOLLARS. THE EXISTENCE OF ONE OR MORE CLAIMS BY YOU WILL NOT INCREASE OUR LIABILITY.

## Disclaimer

YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE SERVICES ARE PROVIDED WITHOUT WARRANTIES 
OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT 
OR COURSE OF PERFORMANCE.

SIMERA LLC, ITS SUBSIDIARIES, AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT (A) THE SERVICES WILL FUNCTION UNINTERRUPTED, SECURE OR AVAILABLE AT ANY PARTICULAR 
TIME OR LOCATION; (B) ANY ERRORS OR DEFECTS WILL BE CORRECTED; (C) THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE RESULTS OF USING THE 
SERVICES WILL MEET YOUR REQUIREMENTS.
`;

const Terms: FC = () => {
  return (
    <div data-testid="terms" className={classes.Container}>
      <h1 className={classes.Title}>Terms of Service</h1>
      <div className={classes.Card}>
        <ReactMarkdown className={classes.Markdown}>{terms}</ReactMarkdown>
      </div>
    </div>
  );
};

export default Terms;
