export const SIMERA_CANDIDATES_ROUTE = '/internal-dashboard/candidates';
export const SIMERA_CANDIDATES_BY_ID_ROUTE = (id: number) =>
  `/simera-dashboard/candidates/${id}`;
export const SIMERA_POSITIONS_ROUTE = '/internal-dashboard';
export const SIMERA_COMPANIES_ROUTE = '/internal-dashboard/companies';
export const SIMERA_EMPLOYERS_ROUTE = '/simera-dashboard/employers';
export const SIMERA_JOB_INTERVIEWS_ROUTE = '/internal-dashboard/interviews';
export const SIMERA_COMPANY_ROUTE = (id: number) =>
  `/internal-dashboard/company/${id}`;
export const SIMERA_SSE_ROUTE = `${process.env.REACT_APP_API_URL}/candidates/sse`;
export const SIMERA_ENGLISH_ASSESSMENT_SSE_ROUTE = `${process.env.REACT_APP_API_URL}/english-assessment/sse`;
export const SIMERA_PARSED_RESUME_SSE_ROUTE = `${process.env.REACT_APP_API_URL}/parsed-resume/sse`;
