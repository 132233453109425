import auth0 from 'auth0-js';

const auth0Client = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
});

auth0Client.popup.callback({
  hash: window.location.hash,
});

export default auth0Client;
