import { ErrorMessageEn, ErrorMessageEs } from '$constants/alerts';
import { errorHandler } from '$store/utils';
import {
  CandidateReactionsBuilder,
  SendReactionInput,
} from '../candidate-reactions.types';

export const sendReaction = (builder: CandidateReactionsBuilder) =>
  builder.mutation<undefined, SendReactionInput>({
    query: ({ candidateId, reactionType }) => ({
      url: `/candidate-reactions/${candidateId}`,
      method: 'POST',
      body: { ReactionType: reactionType },
    }),
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[SendReactionAction]',
        lang === 'es'
          ? ErrorMessageEs.ErrorSendingReaction
          : ErrorMessageEn.ErrorSendingReaction,
        error,
        true
      );
    },
    invalidatesTags: (baseQuery, meta, { candidateId }) => [
      { type: 'candidateReactions', id: candidateId },
    ],
  });
