import { ErrorMessageEn, ErrorMessageEs } from '$constants/alerts';
import { errorHandler } from '$store/utils';
import {
  GetVideoFeedbackTypeInput,
  GetVideoFeedbackTypeOutput,
  VideoFeedbackBuilder,
} from '../video-feedback.types';

export const getAllVideoFeedbacks = (builder: VideoFeedbackBuilder) =>
  builder.query<GetVideoFeedbackTypeOutput, GetVideoFeedbackTypeInput>({
    query: ({ videoId, videoType }) => ({
      url: `/feedbacks?EntityId=${videoId}&EntityName=${videoType}`,
      method: 'GET',
    }),
    providesTags: (result, meta, { videoId }) =>
      result
        ? [{ type: 'videoFeedback', id: videoId }, { type: 'videoFeedback' }]
        : [{ type: 'videoFeedback' }],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[getAllVideosFeedbackEndpoint]',
        lang === 'es'
          ? ErrorMessageEs.ErrorRetrievingFeedback
          : ErrorMessageEn.ErrorRetrievingFeedback,
        error.data,
        true
      );
      return error.data;
    },
  });
