import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { getWorkHistoryByCandidateId } from './queries';
import { saveWorkExperiences, saveWorkExperiencesInternal } from './mutations';
import { deleteWorkExperience } from './mutations/delete-work-experience.mutation';

export const workExperienceApi = createApi({
  reducerPath: ReducerPaths.WORK_EXPERIENCE,
  baseQuery,
  endpoints: (builder) => ({
    getWorkHistoryByCandidateId: getWorkHistoryByCandidateId(builder),
    saveWorkExperiences: saveWorkExperiences(builder),
    saveWorkExperiencesInternal: saveWorkExperiencesInternal(builder),
    deleteWorkExperience: deleteWorkExperience(builder),
  }),
});

export const {
  useGetWorkHistoryByCandidateIdQuery,
  useLazyGetWorkHistoryByCandidateIdQuery,
  useSaveWorkExperiencesMutation,
  useSaveWorkExperiencesInternalMutation,
  useDeleteWorkExperienceMutation,
} = workExperienceApi;
