import { useEffect, useState } from 'react';
import { SaveState } from '../types/ForrmType';
import PositionDto from '../types/serverTypes/positionDto';
import PositionInternalDto from '../types/serverTypes/positionInternalDto';

const useDuplicatePosition = (
  position: PositionDto | PositionInternalDto,
  setModal: (show: boolean) => void
) => {
  const [showCancel, setShowCancel] = useState(false);

  const [showContent, setShowContent] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [counterObjective, setCounterObjective] = useState<number>(0);
  useEffect(() => {
    if (
      [SaveState.FAILED, SaveState.FULFILLED].includes(position?.actionStatus!)
    ) {
      setModal(false);
    }
  }, [position?.actionStatus, setModal]);

  const handleCancel = () => {
    setShowCancel(true);
  };

  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setModal(false);
    }

    setShowCancel(false);
  };

  return {
    handleCancel,
    handleCancelModal,
    showCancel,
    showContent,
    setShowContent,
    counter,
    setCounter,
    counterObjective,
    setCounterObjective,
  };
};

export default useDuplicatePosition;
