import computeYearsOfExperience from '../../../../utils/computeYearsOfExperience';
import {
  CandidateBuilder,
  FindCandidatesInput,
  FindCandidatesOutput,
} from '../candidates.types';
// NOTE: uncommment this to remove candidate sync workaround
// const transforQuerystringSearchParams = (
//   queryStringsParams: FindCandidatesInput
// ) => {
//   return Object.keys(queryStringsParams).reduce((prev: string, act: string) => {
//     const paramKey = act.toLowerCase();
//     if (paramKey !== 'cps') {
//       if (prev !== '') {
//         prev += '&';
//       }
//       let value = queryStringsParams[
//         act as keyof FindCandidatesInput
//       ] as string;

//       if (typeof value === 'string') value = value?.replace(/\[|\]/g, '');

//       switch (paramKey) {
//         case 'minsalary':
//           prev += `SalaryRange[0]=${value}`;
//           break;
//         case 'maxsalary':
//           prev += `SalaryRange[1]=${value}`;
//           break;
//         case 'skills':
//         case 'languages':
//         case 'educationlevel':
//         case 'yearsofexperience':
//         case 'files':
//         case 'previousexperience':
//         case 'additionaltags':
//           if (paramKey === 'yearsofexperience') value = value.replace(/ /g, '');
//           prev += value
//             ? value
//                 .split(',')
//                 .map((singleValue: string, index: number) => {
//                   return `${act}[${index}]=${singleValue}`;
//                 })
//                 .join('&')
//             : '';
//           break;
//         default:
//           prev += `${act}=${value}`;
//           break;
//       }
//     }
//     return prev;
//   }, '');
// };

export const findCandidates = (builder: CandidateBuilder) =>
  builder.query<FindCandidatesOutput, FindCandidatesInput>({
    query: (arg) => {
      return {
        url: `candidates/v2/search?Criteria=${
          arg.Query || arg.Id || arg.Email
        }`,
        // NOTE: use this endpoint instead to remove candidate sync workaround
        // url: `candidates/search?${transforQuerystringSearchParams(arg)}`,
        method: 'GET',
      };
    },
    transformResponse: (resp: any) => {
      if (resp && resp.data) {
        resp.data = resp?.data?.map((singleCandidate: any) => {
          singleCandidate.YearsOfExperience = computeYearsOfExperience(
            singleCandidate.CandidateWorkExperience
          );
          return singleCandidate;
        });
      }

      // NOTE: remove this if block to remove candidate sync workaround
      if (resp && resp.meta) {
        resp.page = resp.meta.page;
        resp.total = resp.meta.itemCount;
        resp.limit = resp.meta.take;
        resp.prev = resp.meta.hasPreviousPage;
        resp.next = resp.meta.hasNextPage;
        delete resp.meta;
      }
      return resp;
    },
    providesTags: (result) =>
      result
        ? [
            ...result.data.map(({ Id }) => ({
              type: 'Candidate' as const,
              Id,
            })),
            { type: 'Candidates' },
          ]
        : [{ type: 'Candidates' }],
  });
