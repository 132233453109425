export enum FileCategoryType {
  RESUMES = 1,
  OTHER_ASSESSMENTS = 2,
  EMAIL_SAMPLES = 3,
  WRITING_SAMPLES = 4,
  REFERENCE = 5,
  BACKGROUND_CHECK_FORM = 6,
  WRITING_SAMPLE = 7,
  BUSINESS_EMAIL_SAMPLE = 8,
  ENGLISH_ASSESSMENT = 9,
  SALES_ASSESSMENT = 10,
  ADDITIONAL_ASSESSMENT = 11,
  ADDITIONAL_DOCUMENTATION = 12,
  INTERNAL_INTERVIEW_FORMS = 13,
}
