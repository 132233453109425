import { ChangeEvent, FC } from 'react';
import { Link } from 'react-router-dom';

import Button from '../Buttons/Button';
import BaseInput from '../Inputs/BaseInput';
import { ReactComponent as GoogleIcon } from '../../icons/GoogleColorIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../icons/LinkedInWhiteIcon.svg';
import { ReactComponent as EyeIcon } from '../../icons/PasswordEye.svg';
import classes from './LoginForm.module.css';
import RoundedButton from '../Buttons/RoundedButton';
import useLoginForm from './hooks/useLoginForm';

const LoginForm: FC = () => {
  const {
    submitForm,
    handleSubmit,
    isValid,
    errors,
    email,
    password,
    onGoogleSignIn,
    onLinkedInSignIn,
    t,
  } = useLoginForm();

  return (
    <form
      data-testid="login-form"
      className={classes.Form}
      onSubmit={handleSubmit(submitForm)}
    >
      <div>
        <BaseInput
          onChange={(e: ChangeEvent) => email.onChange(e)}
          name={email.name}
          onBlur={email.onBlur}
          refProp={email.ref}
          label={t('register.emailInput')}
          type="text"
          errorText={errors.email && t('login.emailFormat')}
          dataTestId="login_email"
        />
      </div>

      <div>
        <BaseInput
          onChange={(e: ChangeEvent) => password.onChange(e)}
          name={password.name}
          onBlur={password.onBlur}
          refProp={password.ref}
          label={t('register.passwordInput')}
          type="password"
          errorText={errors.password && 'Required'}
          icon={EyeIcon}
          isPassword
          dataTestId="login_password"
        />
      </div>

      <div className={classes.JustifyRight}>
        <a href="recover_password" className={classes.Password}>
          {t('login.forgotPass')}
        </a>
      </div>

      <div className={classes.LoginButton}>
        <Button
          disabled={!isValid}
          customStyles={{ boderRadius: '2px' }}
          onClick={handleSubmit(submitForm)}
          text={t('login.login')}
          bgColor="var(--brand-blue-100)"
          showText
          submit
        />
      </div>
      <div className={classes.OrFlex}>
        <div className={classes.OrTags}>
          <hr className={classes.ORLine} />
          <p className={classes.Or}>{t('common.or')}</p>
          <hr className={classes.ORLine} />
        </div>
      </div>
      <div data-testid="login-buttons" className={classes.ButtonFlex}>
        <RoundedButton icon={GoogleIcon} onClick={onGoogleSignIn} />
        <RoundedButton icon={LinkedInIcon} onClick={onLinkedInSignIn} />
      </div>
      <div className={classes.accounts}>
        <div>
          <p className={classes.Account}>
            {t('login.areYouEmployer')}
            <Link to="/employer_login" className={classes.SignUp}>
              &nbsp;{t('login.loginHere')}
            </Link>
          </p>
          <p className={classes.Account}>
            {t('login.noAccount')}
            <Link to="/register" className={classes.SignUp}>
              &nbsp;{t('login.signUp')}
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
