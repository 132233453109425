import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import AddNoteForm from '../AddNoteForm/AddNoteForm';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '600px',
  },
};
interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  positionId?: number;
}
const AddNoteModal: FC<Props> = ({ isOpen, setIsOpen, positionId }) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);

  const [t] = useTranslation('global');
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen(false);
      }}
      ariaHideApp={false}
      style={customStyles}
    >
      <div data-testid="add-note-modal">
        <h3
          style={{
            color: 'var(--brand-dark-grey)',
          }}
        >
          {t('Notes.CreateNote')}
        </h3>
        <AddNoteForm setIsOpen={setIsOpen} positionId={positionId} />
      </div>
    </Modal>
  );
};
AddNoteModal.defaultProps = {
  positionId: 0,
};

export default AddNoteModal;
