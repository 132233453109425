import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils';
import { getCandidateReferralId } from './queries/get-candidate-referral-id.query';
import { getCandidatesReferrals } from './queries/get-candidates-referrals.query';
import { isValidReferral } from './queries/is-valid-referral.query';

export const referralsApi = createApi({
  reducerPath: 'referrals',
  baseQuery,
  keepUnusedDataFor: 240,
  tagTypes: ['Referrals', 'Referral'],
  endpoints: (builder) => ({
    getCandidateReferralId: getCandidateReferralId(builder),
    getCandidatesReferrals: getCandidatesReferrals(builder),
    isValidReferral: isValidReferral(builder),
  }),
});

export const {
  useGetCandidateReferralIdQuery,
  useGetCandidatesReferralsQuery,
  useIsValidReferralQuery,
} = referralsApi;
