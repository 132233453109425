import { resetActiveVideo } from '..';
import { useAppDispatch } from '../../../../hooks';
import { AdditionalVideo } from '../../../../types/additional-video/additional-video';
import { Video } from '../../uploads';
import {
  setActiveVideo,
  updateActiveVideo,
  setVideos,
  openModal,
  closeModal,
  setActiveVideoType,
  resetActiveVideoType,
  setVideosType,
} from '../additional-videos.reducer';

export const useAdditionalVideo = () => {
  const dispatch = useAppDispatch();

  const setVideo = (video: AdditionalVideo | null) => {
    dispatch(setActiveVideo(video));
  };

  const setVideoType = (video: Video | null) => {
    dispatch(setActiveVideoType(video));
  };

  const removeActiveVideo = () => {
    dispatch(resetActiveVideo());
  };

  const removeActiveVideoType = () => {
    dispatch(resetActiveVideoType());
  };

  const renameVideo = (name: string) => {
    dispatch(updateActiveVideo(name));
  };

  const setAdditionalVideos = (videos: AdditionalVideo[]) => {
    dispatch(setVideos(videos));
  };
  const setSignedVideosType = (videos: Video[]) => {
    dispatch(setVideosType(videos));
  };
  const openAdditionalVideoModal = () => {
    dispatch(openModal());
  };
  const closeAdditionalVideoModal = () => {
    dispatch(closeModal());
  };

  return {
    setVideo,
    setVideoType,
    setSignedVideosType,
    renameVideo,
    removeActiveVideo,
    removeActiveVideoType,
    setAdditionalVideos,
    openAdditionalVideoModal,
    closeAdditionalVideoModal,
  };
};
