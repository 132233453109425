import axios from 'axios';
import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { successHandler, errorHandler } from '../../../utils';
import {
  AddCandidateToPositionInput,
  AddCandidateToPositionOutput,
  CandidateBuilder,
} from '../candidates.types';
import { INTERESTED_EMAIL } from '../../../../api/endpoints';

export const addCandidateToPosition = (builder: CandidateBuilder) =>
  builder.mutation<AddCandidateToPositionOutput, AddCandidateToPositionInput>({
    query: (args) => ({
      url: '/candidate-position-status/add-candidate',
      method: 'POST',
      body: args.data,
    }),
    transformResponse: async (res, meta, args) => {
      successHandler(
        (args.lang ?? 'en') === 'en'
          ? SuccessMessageEn.ADD_CANDIDATE_TO_POSITION
          : SuccessMessageEs.ADD_CANDIDATE_TO_POSITION,
        true
      );
      await axios.post(
        INTERESTED_EMAIL,
        {
          candidateId: args.data.candidateId,
          positionId: args.data.positionId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          },
        }
      );
      await axios.post(
        INTERESTED_EMAIL,
        {
          candidateId: args.data.candidateId,
          positionId: args.data.positionId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('id_token')}`,
          },
        }
      );
      return args.data;
    },
    transformErrorResponse: (err, meta, args) => {
      if (err.data && err.status === 409) {
        errorHandler(
          '[addCandidateToPositionEndpoint]',
          (args.lang ?? 'en') === 'es'
            ? ErrorMessageEs.CandidateAlreadyAddedError
            : ErrorMessageEn.CandidateAlreadyAddedError,
          err,
          true
        );
      } else {
        errorHandler(
          '[addCandidateToPositionEndpoint]',
          (args.lang ?? 'en') === 'es'
            ? ErrorMessageEs.AddCandidateToPositionError
            : ErrorMessageEn.AddCandidateToPositionError,
          err,
          true
        );
      }
      return err.data;
    },
    invalidatesTags: () => [{ type: 'Candidates' }],
  });
