import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as PDFIcon } from '../../../icons/PDFIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../icons/Download.svg';
import { ReactComponent as DocxIcon } from '../../../icons/DocxIcon.svg';
import { ReactComponent as TrashIcon } from '../../../icons/TrashIcon.svg';

import classes from './AdditionalFile.module.css';
import ConfirmDeleteFileModal from '../ConfirmDeleteFileModal/ConfirmDeleteFileModal';
import VisibilityForm from './VisibilityForm';
import { useDeleteAdditionalDocumentMutation } from '../../../store/modules/additional-documents';
import { segmentTrack } from '../../../utils/handleSegment';
import { AdditionalDocument } from '../../../types/candidates';

interface Props {
  data: AdditionalDocument;
  secondary?: boolean;
  isUpdate: boolean;
}

const AdditionalFile: FC<Props> = ({
  data: {
    Description: label,
    Url: url,
    Id: fileId,
    IsCandidatesVisible: isCandidateVisible,
    IsEmployersVisible: isEmployersVisible,
    Key: key,
  },
  secondary,
  isUpdate,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, i18n] = useTranslation('global');
  const handleDelete = () => {
    setIsModalOpen(true);
  };
  const [deleteAdditionalDocument] = useDeleteAdditionalDocumentMutation();
  const { candidateId } = useParams();
  const handleConfirm = () => {
    deleteAdditionalDocument({
      fileId,
      lang: i18n.language as 'en' | 'es',
    });
    setIsModalOpen(false);
  };
  const extension = key?.split('.')?.pop() ?? 'pdf';
  const onDownloadHandler = () => {
    segmentTrack('Documents downloaded', {
      file_id: Number(fileId),
      candidate_id: Number(candidateId),
    });
  };

  const fill = secondary ? '#079e88' : '#05668d';
  const Icon =
    extension === 'pdf' ? <PDFIcon fill={fill} /> : <DocxIcon fill={fill} />;
  const titleContainer = !secondary ? classes.titleContainer : '';
  return (
    <div className={classes.fileContainer} data-testid="file-container">
      <div className={classes.link}>
        <div className={titleContainer}>
          <a
            href={url}
            download
            className={classes.anchor}
            onClick={onDownloadHandler}
            target="_blank"
            rel="noreferrer"
          >
            {Icon}
            <p>{label}</p>
          </a>
        </div>
        <div className={classes.actionsContainer}>
          {secondary && (
            <VisibilityForm
              fileId={fileId}
              isCandidateVisibleDefaultValue={!!isCandidateVisible}
              isEmployersVisibleDefaultValue={!!isEmployersVisible!}
            />
          )}
          <div className={classes.actions}>
            {!isUpdate ? (
              <a
                aria-label="download icon"
                href={url}
                download
                className={classes.deleteIcon}
                onClick={onDownloadHandler}
                target="_blank"
                rel="noreferrer"
              >
                <DownloadIcon />
              </a>
            ) : (
              <button
                aria-label="delete icon"
                type="button"
                onClick={handleDelete}
                className={classes.deleteIcon}
              >
                <TrashIcon className={classes.TrashIcon} />
              </button>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ConfirmDeleteFileModal
          setShowModal={setIsModalOpen}
          handleConfirm={handleConfirm}
          secondary={secondary}
        />
      )}
    </div>
  );
};

AdditionalFile.defaultProps = {
  secondary: false,
};
export default AdditionalFile;
