import type { FC } from 'react';
import {
  InterviewSlotStatusTypes,
  useDeleteInterviewSlotMutation,
} from '$store/modules/interview';
import { ReactComponent as CheckMark } from '$icons/calendar-check.svg';
import { ReactComponent as CloseIcon } from '../../../../../icons/CloseBlue.svg';
import { ReactComponent as CalendarIcon } from '../../../../../icons/CalendarIcon.svg';
import classes from './Schedule.module.css';
import { SetSchedulesType } from '../../CalendarModalTypes';

interface ScheduleProps {
  schedule: SetSchedulesType;
  setSchedules: React.Dispatch<React.SetStateAction<SetSchedulesType[]>>;
}

export const Schedule: FC<ScheduleProps> = ({ schedule, setSchedules }) => {
  const [deleteInterview] = useDeleteInterviewSlotMutation();

  const deleteScheduleHandler = async () => {
    if (schedule?.id) await deleteInterview({ scheduleId: schedule.id });
    setSchedules((prev) => {
      const newSchedules = prev.filter(
        (el) =>
          el.date.format('D') !== schedule.date.format('D') ||
          el.date.format('H') !== schedule.date.format('H')
      );
      return newSchedules;
    });
  };

  return (
    <div className={classes.container}>
      <div>
        {schedule.status === InterviewSlotStatusTypes.Scheduled ? (
          <CheckMark />
        ) : (
          <CalendarIcon className="stroke-employer-main" />
        )}
      </div>
      <div className={classes.schedule}>
        <p>
          {`${schedule.date.format('ddd, MMM DD h:mma')} - ${schedule.date
            .add(1, 'hour')
            .format('h:mma')}`}
        </p>
        {schedule.status === InterviewSlotStatusTypes.Scheduled && (
          <div className="flex-none font-semibold text-sm text-orange-400 w-full">
            Schedule has been confirmed
          </div>
        )}
      </div>
      {schedule.status !== InterviewSlotStatusTypes.Scheduled && (
        <div
          aria-label="Schedule Close"
          data-testid="schedule-close"
          className={classes.cancelButton}
          role="button"
          tabIndex={0}
          onClick={deleteScheduleHandler}
          onKeyDown={deleteScheduleHandler}
        >
          <CloseIcon />
        </div>
      )}
    </div>
  );
};
