import RouteTypes from '$types/routeTypes';

const getRouteTypeFromLocalStorage = (): RouteTypes => {
  const ServerUserFromLocalStorage = JSON.parse(
    localStorage.getItem('ServerUserResponse') || '{}'
  );

  if (ServerUserFromLocalStorage.Candidate) {
    return RouteTypes.CANDIDATE;
  }
  if (ServerUserFromLocalStorage.Employer) {
    return RouteTypes.EMPLOYER;
  }
  if (ServerUserFromLocalStorage.Internal) {
    return RouteTypes.INTERNAL;
  }
  return RouteTypes.DEFAULT;
};

export default getRouteTypeFromLocalStorage;
