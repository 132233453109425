import { ErrorMessageEn, ErrorMessageEs } from '$constants/alerts';
import { errorHandler } from '$store/utils';
import { ValidLanguages, userLanguage } from '$translations/index';
import {
  InterviewBuilder,
  CreateEmptyInterviewOutput,
  CreateEmptyInterviewInput,
} from '../interview.types';

export const createEmptyInterview = (builder: InterviewBuilder) =>
  builder.mutation<CreateEmptyInterviewOutput, CreateEmptyInterviewInput>({
    query: ({ candidatePositionStatusId }) => ({
      url: `/interview/admin/${candidatePositionStatusId}`,
      method: 'POST',
    }),
    transformErrorResponse: (err) => {
      errorHandler(
        '[CreateEmptyInterview]',
        userLanguage === ValidLanguages.es
          ? ErrorMessageEs.InterviewWithoutScheduleError
          : ErrorMessageEn.InterviewWithoutScheduleError,
        err,
        true
      );
      return err;
    },
  });
