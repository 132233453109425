import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CompanyBuilder,
  LoadRoasterInput,
  LoadRoasterOutput,
} from '../companies.types';

export const loadRoster = (builder: CompanyBuilder) =>
  builder.query<LoadRoasterOutput, LoadRoasterInput>({
    query: ({ positionId }) => ({
      url: `/candidate-position-status/custom-roster/${positionId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ id }) => ({ type: 'Company' as const, id })),
            { type: 'Company', Id: 'LIST' },
          ]
        : [{ type: 'Company', Id: 'LIST' }],
    transformErrorResponse(error, meta, arg) {
      errorHandler(
        '[loadCustomRoster]',
        arg.lang === 'en'
          ? ErrorMessageEn.loadingCustomRoster
          : ErrorMessageEs.loadingCustomRoster,
        error,
        true
      );
    },
  });
