import { AxiosError } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import type { IRootState } from '../../index';
import { resetPassword, validateToken } from '../../../api/auth.service';
import { ErrorMessageEs, ErrorMessageEn } from '../../../constants/alerts';
import PasswordRecoveryRequestDto from '../../../types/serverTypes/passwordRecoveryRequestDto';
import TokenValidationDto from '../../../types/serverTypes/tokenValidationDto';
import {
  PasswordResetActionTypes,
  PasswordResetAction,
  ValidateTokenAction,
} from './password-reset.types';
import { errorHandler } from '../../utils';

export const resetPasswordAuthAction = Object.assign(
  (passwordRecoveryRequestDto: PasswordRecoveryRequestDto) =>
    async (
      dispatch: ThunkDispatch<IRootState, unknown, PasswordResetAction>
    ) => {
      const { lang } = passwordRecoveryRequestDto;
      try {
        dispatch(resetPasswordAuthAction.start());
        await resetPassword(passwordRecoveryRequestDto);
        dispatch(resetPasswordAuthAction.success());
      } catch (error) {
        dispatch(resetPasswordAuthAction.fail(error as Error));
        const err = error as AxiosError;
        if (err.response && err.response.status === 404) {
          errorHandler(
            '[resetPasswordAuthAction]',
            lang === 'es'
              ? ErrorMessageEs.ResetPassword
              : ErrorMessageEn.ResetPassword,
            error,
            true
          );
        }
      }
    },
  {
    start: () => ({
      type: PasswordResetActionTypes.PASSWORD_RECOVERY_REQUEST_START,
    }),
    success: (): PasswordResetAction => ({
      type: PasswordResetActionTypes.PASSWORD_RECOVERY_REQUEST_SUCCESS,
    }),
    fail: (error: Error) => ({
      type: PasswordResetActionTypes.PASSWORD_RECOVERY_REQUEST_FAIL,
      error,
    }),
  }
);

export const validateTokenAuthAction = Object.assign(
  (tokenValidationDto: TokenValidationDto) =>
    async (
      dispatch: ThunkDispatch<IRootState, unknown, PasswordResetAction>
    ) => {
      const { lang } = tokenValidationDto;
      try {
        dispatch(validateTokenAuthAction.start());
        await validateToken(tokenValidationDto);
        dispatch(validateTokenAuthAction.success(tokenValidationDto.token));
      } catch (error) {
        dispatch(validateTokenAuthAction.fail(error as Error));
        errorHandler(
          '[validateTokenAuthAction]',
          lang === 'es'
            ? ErrorMessageEs.InvalidToken
            : ErrorMessageEn.InvalidToken,
          error,
          true
        );
      }
    },
  {
    start: () => ({ type: PasswordResetActionTypes.VALIDATE_TOKEN_START }),
    success: (token: string): ValidateTokenAction => ({
      type: PasswordResetActionTypes.VALIDATE_TOKEN_SUCCESS,
      token,
    }),
    fail: (error: Error) => ({
      type: PasswordResetActionTypes.VALIDATE_TOKEN_FAIL,
      error,
    }),
  }
);
