import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SwitchTypes from '../../constants/CandidatesSwitchTypes';
import MatchTypes from '../../constants/MatchValues';
import { useAppDispatch } from '../../hooks';
import { ReactComponent as BackArrow } from '../../icons/ArrowBackInternal.svg';
import { ReactComponent as RefreshIcon } from '../../icons/RefreshIcon.svg';
import {
  candidatesApi,
  // useGetCandidatesByPositionIdQuery,
  useLazyFindCandidateQuery,
} from '../../store/modules/candidates';
import { useLoadOnePositionQuery } from '../../store/modules/companies';
import { CandidateData } from '../../types/candidates';
import CandidatesSearchFilters from '../../types/candidatesPaginatedSearch';
import { handleSalaryRange } from '../../utils/handleSalaryRange';
import Button from '../Buttons/Button';
import CandidatesSwitch from '../CandidatesSwitch/CandidatesSwitch';
import { Filters } from '../Internal/Positions/Filters';
import CandidateSummaryCardListGraph from './CandidateSummaryCardList/CandidateSummaryCardListGraph';
import CardSkeleton from './CandidateSummaryCardList/CardSkeleton/CardSkeleton';
import SearchBar from './SearchBar/SearchBar';
import classes from './SinglePosition.module.css';
import SinglePositionCard from './SinglePositionCard';
import GetCandidateDataByMultipleFetch from './utils/GetCandidateDataByMultipleFetch';

const PositionsPage: FC = () => {
  // const [data, setData] = useState<any>();
  const { id: positionId } = useParams();
  const [candidates, setCandidates] = useState<CandidateData[]>([]);
  const [positionCandidates, setPositionCandidates] = useState<CandidateData[]>(
    []
  );
  const [findCandidatesResult, setFindCandidatesResult] = useState<
    CandidateData[]
  >([]);
  const [t] = useTranslation('global');
  const [searchParams] = useSearchParams();
  const [candidatesPositionCount, setCandidatesPositionCount] = useState({
    new: 0,
    shortlisted: 0,
    declined: 0,
    requestInterview: 0,
    hired: 0,
    offerSent: 0,
  });
  const [switchingCandidateId, setSwitchingCandidateId] = useState<
    number | null
  >(null);

  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const navigate = useNavigate();
  const cps = (searchParams.get('cps') ?? SwitchTypes.ACTIVE) as SwitchTypes;

  const { data: position } = useLoadOnePositionQuery({
    id: positionId,
  });

  // const {
  //   // data: candidatesApi,
  //   isLoading,
  //   isFetching,
  //   refetch,
  // } = useGetCandidatesByPositionIdQuery(
  //   {
  //     positionId: parseInt(positionId!, 10),
  //   },
  //   { refetchOnMountOrArgChange: true }
  // );

  const { data, isLoading, refetch } =
    GetCandidateDataByMultipleFetch(position);

  const [findCandidates] = useLazyFindCandidateQuery();

  const dispatch = useAppDispatch();

  const filters = useMemo((): CandidatesSearchFilters => {
    const yearsOfExperience = JSON.parse(
      searchParams.get('YearsOfExperience')?.trim() ?? 'null'
    );

    setPage(1);
    setHasNextPage(false);
    return {
      cps,
      matchSkill: searchParams.get('SkillsMatch') ?? MatchTypes.ALL,
      matchAdditionalTags:
        searchParams.get('AdditionalTagsMatch') ?? MatchTypes.ALL,
      additionalTags: searchParams.get('AdditionalTags')?.split(','),
      education: searchParams.get('EducationLevel')?.split(','),
      fileCategories: searchParams.get('Files')?.split(','),
      languages: searchParams.get('Languages')?.split(','),
      skills: searchParams.get('Skills')?.split(','),
      englishLevel: searchParams.get('EnglishLevel')?.split(','),
      positionId: parseInt(positionId ?? '0', 10),
      id:
        parseInt(searchParams.get('Id') ?? '0', 10) === 0
          ? null
          : parseInt(searchParams.get('Id') ?? '0', 10),
      workExperience: searchParams.get('PreviousExperience')?.split(','),
      salaryRange: handleSalaryRange(
        searchParams.get('MinSalary'),
        searchParams.get('MaxSalary')
      ),
      query: searchParams.get('Query') ?? '',
      yearsOfExperience,
      name: searchParams.get('Name') ?? null,
      email: searchParams.get('Email') ?? null,
    };
  }, [cps, positionId, searchParams]);

  const reloadCandidates = useCallback(() => {
    dispatch(candidatesApi.util.resetApiState());
    setCandidates([]);
    setPage(1);
    refetch();
  }, [dispatch, refetch]);

  const searchCandidate = useCallback(
    async (findParams) => {
      setIsLoadingSearch(true);
      findCandidates(findParams)
        .then((findCandidateData) => {
          setIsLoadingSearch(false);
          setHasNextPage(!!findCandidateData?.data?.next ?? false);
          setFindCandidatesResult((prev) => {
            const resultData = findCandidateData?.data?.data || [];
            return [...prev, ...resultData].reduce(
              (previous: CandidateData[], actual: CandidateData) => {
                const isNewCandidate = !previous.some(
                  (singleCandidate: CandidateData) => {
                    return singleCandidate.Id === actual.Id;
                  }
                );

                if (isNewCandidate) previous.push(actual);
                return previous;
              },
              []
            );
          });
        })
        .catch(() => {
          setIsLoadingSearch(false);
        });
    },
    [findCandidates]
  );

  const onNextPage = useCallback(() => {
    if (hasNextPage && !isLoadingSearch) {
      setPage((prev) => prev + 1);
    }
  }, [hasNextPage, isLoadingSearch]);

  useEffect(() => {
    if (data && !isLoading) {
      setPositionCandidates((prev) => {
        const newCandidates = data?.filter(
          (item: CandidateData) =>
            !prev.some((prevItem) => prevItem.Id === item.Id)
        );
        const parsePrev = prev.filter(
          (item) => item.Id !== switchingCandidateId
        );
        return [...parsePrev, ...newCandidates];
      });
    }
  }, [data, isLoading, switchingCandidateId, cps]);

  useEffect(() => {
    if (cps === 'find') setCandidates(findCandidatesResult);
    else setCandidates(positionCandidates);
  }, [cps, positionCandidates, findCandidatesResult]);

  useEffect(() => {
    const findParamsLength = Array.from(searchParams.keys()).length;
    if (cps.toLowerCase() === 'find') {
      if (findParamsLength > 1) {
        searchCandidate({
          ...Object.fromEntries(searchParams.entries()),
          page,
        });
      } else {
        setCandidates([]);
      }
    } else {
      setCandidates(positionCandidates);
    }
  }, [cps, searchParams, positionCandidates, searchCandidate, page]);

  useEffect(() => {
    if (position?.CandidatesPositionStatus) {
      const candidatesCount = position?.CandidatesPositionStatus?.reduce(
        (acc, curr) => {
          acc[curr.Status] += 1;

          return acc;
        },
        {
          new: 0,
          shortlisted: 0,
          declined: 0,
          requestInterview: 0,
          hired: 0,
          offerSent: 0,
        }
      );
      setCandidatesPositionCount(candidatesCount);
    }
  }, [position?.CandidatesPositionStatus]);

  return (
    <div className={classes.PositionWrapper} data-testid="positionWrapper">
      <Button
        text={t('CandidatesTable.Previous')}
        showText
        customStyles={{
          color: '#079E88',
          width: '190px',
          alignSelf: 'flex-start',
          border: '1px solid #079E88',
          borderRadius: '4px',
          backgroundColor: 'transparent',
          padding: '0.5rem 1rem',
          marginBottom: '1rem',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
        textStyles={{
          marginLeft: '30px',
        }}
        icon={BackArrow}
        iconStyles={{ fill: '#079E88' }}
        onClick={() => navigate('/internal-dashboard', { replace: true })}
      />
      <div className={classes.Container}>
        <Filters />
        <div className={classes.PositionMainContainer}>
          <div className={classes.PositionContainer}>
            <SinglePositionCard
              candidatesCount={position?.CandidatesPositionStatus?.length ?? 0}
            />
          </div>
          <div className={classes.PositionCandidatesContainer}>
            <div className={classes.PositionCandidatesFilter}>
              <CandidatesSwitch
                value={(cps as SwitchTypes) ?? SwitchTypes.ACTIVE}
                setSwitchingId={setSwitchingCandidateId}
                candidatesCount={candidatesPositionCount}
              />
            </div>
            <div className={classes.PositionCandidatesSearch}>
              <SearchBar />
            </div>
            <div
              className={classes.refresh}
              aria-hidden="true"
              onClick={reloadCandidates}
            >
              <RefreshIcon />
            </div>
          </div>

          {isLoading ||
          (cps === 'find' && isLoadingSearch && candidates.length === 0) ? (
            <div data-testid="loading-page1">
              <CardSkeleton />
            </div>
          ) : (
            <CandidateSummaryCardListGraph
              loading={isLoading || isLoadingSearch}
              jobPositionStatus={cps}
              candidatesData={candidates}
              positionId={parseInt(positionId ?? '0', 10)}
              currentFilter={cps}
              setSwitchingId={setSwitchingCandidateId}
              onNextPage={onNextPage}
              filters={filters}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PositionsPage;
