import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CompanyBuilder,
  GetPositionFilteredInput,
  GetPositionFilteredOutput,
} from '../companies.types';

const TAKE_POSITIONS = 12;

export const getPositionFiltered = (builder: CompanyBuilder) =>
  builder.query<GetPositionFilteredOutput, GetPositionFilteredInput>({
    query: ({ query, page }) => {
      const parsedQuery = { ...query };
      // @ts-ignore
      if (parsedQuery.companies?.length === 0) {
        delete parsedQuery.companies;
      }
      if (parsedQuery.jobPositions?.length === 0) {
        delete parsedQuery.jobPositions;
      }

      return {
        url: '/positions/filter',
        method: 'GET',
        params: {
          ...parsedQuery,
          page,
          take: TAKE_POSITIONS,
        },
      };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.data.map(({ Id }) => ({ type: 'Company' as const, Id })),
            { type: 'Company', Id: 'LIST' },
          ]
        : [{ type: 'Company', Id: 'LIST' }],
    transformErrorResponse: (error, meta, args) => {
      errorHandler(
        '[FilterCandidate]',
        args.lang === 'es'
          ? ErrorMessageEs.CandidatePositionLoadPositions
          : ErrorMessageEn.CandidatePositionLoadPositions,
        error,
        true
      );
      return error.data;
    },
  });
