export enum FormType {
  WorkFormScore = 'WorkFormScore',
  EducationFormScore = 'EducationFormScore',
  SkillsFormScore = 'SkillsFormScore',
  LanguagesFormScore = 'LanguagesFormScore',
  JobInterestsFormScore = 'JobInterestsFormScore',
  TechnicalInformationFormScore = 'TechnicalInformationFormScore',
  ExtracurricularFormScore = 'ExtracurricularFormScore',
}

export enum SaveState {
  IDLE,
  PENDING,
  FULFILLED,
  FAILED,
}

export default FormType;
