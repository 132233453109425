import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Steps.module.css';
import NumberedStep from '../../NumberedStep/NumberedStep';
import { ReactComponent as Circle } from '../../../icons/Circle.svg';

const Steps: FC = () => {
  const [t] = useTranslation('global');

  return (
    <div className={classes.StepsContainer}>
      <NumberedStep
        icon={Circle}
        title={t('referrals.inviteFriendsTitle')}
        subtitle={t('referrals.inviteFriendsSubtitle')}
        char="1"
        charStyles={{
          color: 'var(--neutral-10)',
        }}
      />
      <NumberedStep
        icon={Circle}
        title={t('referrals.friendsGetBonusTitle')}
        subtitle={t('referrals.friendsGetBonusSubtitle')}
        char="2"
        charStyles={{
          color: 'var(--neutral-10)',
        }}
      />
      <NumberedStep
        icon={Circle}
        title={t('referrals.getPriceTitle')}
        subtitle={t('referrals.getPriceSubtitle')}
        char="3"
        charStyles={{
          color: 'var(--neutral-10)',
        }}
      />
    </div>
  );
};

export default Steps;
