import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ShareReferenceLocationState } from '$types/LocationState';
import {
  employerEmailLoginAuthAction,
  employerGoogleLoginAuthAction,
  employerLinkedInLoginAuthAction,
} from '../../../../store/legacy/auth/auth.actions';
import { LoginFormTypes } from '../../../../constants/FormTypes';
import { emailRegex } from '../../../../constants/regex';
import { useAppDispatch, useAppSelector } from '../../../../hooks';

const useLoginEmployerForm = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const [t, i18next] = useTranslation('global');
  const navigate = useNavigate();
  const location = useLocation() as ShareReferenceLocationState;

  useEffect(() => {
    if (authState.isLoggedIn) {
      navigate(
        '/employer-dashboard/positions',
        location?.state && { state: { ...location.state } }
      );
    }
  }, [authState, navigate, location?.state]);

  const onGoogleSignIn = () => {
    dispatch(employerGoogleLoginAuthAction(i18next.language));
  };
  const onLinkedInSignIn = () => {
    dispatch(employerLinkedInLoginAuthAction(i18next.language));
  };

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<LoginFormTypes>({ mode: 'onChange' });
  const email = register('email', {
    required: true,
    pattern: emailRegex,
    maxLength: {
      value: 100,
      message: t('register.invalidEmailInput').toString(),
    },
  });
  const password = register('password', { required: true });

  const submitForm = (values: LoginFormTypes) => {
    dispatch(
      employerEmailLoginAuthAction(
        values.email.toLowerCase(),
        values.password,
        i18next.language
      )
    );
  };

  return {
    onGoogleSignIn,
    onLinkedInSignIn,
    handleSubmit,
    submitForm,
    email,
    password,
    errors,
    isValid,
    t,
  };
};

export default useLoginEmployerForm;
