import { TFunction } from 'i18next';
import { InitialFormStateTypes } from '../types/user_info';

interface ISteps {
  name: string;
  atLeastOne: boolean;
}

export const CalculateStepState = (
  info: InitialFormStateTypes,
  t: TFunction
): ISteps[] => {
  const score = [
    {
      ...((info.workHistory?.length ?? 0) > 0 &&
      info.workHistory?.[0]?.JobPosition?.title !== ''
        ? {
            name: t('profileCardCTA.AddWorkHistory'),

            atLeastOne: true,
          }
        : { name: t('profileCardCTA.NoWorkHistory'), atLeastOne: false }),
    },
    {
      ...((info?.educationalInfo?.[0]?.SchoolName.val ?? '') !== ''
        ? {
            name: t('profileCardCTA.AddEducation'),
            atLeastOne: true,
          }
        : { name: t('profileCardCTA.NoEducation'), atLeastOne: false }),
    },
    {
      ...((info.skills?.[0]?.Skill?.Title ?? '') !== ''
        ? { name: t('profileCardCTA.AddSkills'), atLeastOne: true }
        : { name: t('profileCardCTA.NoSkills'), atLeastOne: false }),
    },
    {
      ...((info.languages?.[0]?.LanguageName?.val ?? '') !== ''
        ? { name: t('profileCardCTA.AddLanguages'), atLeastOne: true }
        : { name: t('profileCardCTA.NoLanguages'), atLeastOne: false }),
    },
    {
      name:
        (info?.jobInterest?.JobInterest?.length ?? 0) > 0
          ? t('profileCardCTA.AddJobInterests')
          : t('profileCardCTA.NoJobInterests'),
      atLeastOne: info?.jobInterest?.JobInterest?.length! > 0,
    },
    {
      name: info?.technicalInfo
        ? t('profileCardCTA.AddTechnical')
        : t('profileCardCTA.NoTechnical'),
      atLeastOne: info?.technicalInfo
        ? Object.entries(info?.technicalInfo).filter((item) => {
            return item[1] !== '';
          }).length > 0
        : false,
    },
    {
      ...((info.extracurricular?.[0]?.ExtracurricularType?.val ?? '') !== ''
        ? { name: t('profileCardCTA.AddExtracurricular'), atLeastOne: true }
        : { name: t('profileCardCTA.NoExtracurricular'), atLeastOne: false }),
    },
  ];

  return score;
};

export const computeScore = (
  steps: ISteps[],
  cv: boolean,
  videos: number
): number => {
  const pointsCv = 10;
  const pointsPerVideo = 5;

  let total = Math.round(
    (steps.filter((step) => step.atLeastOne === true).length / steps.length) *
      100 *
      0.75
  );

  if (cv) {
    total += pointsCv;
  }

  if (videos > 0) {
    total += videos * pointsPerVideo;
  }

  return total;
};
