import { FC } from 'react';
import classes from './NotesReadOnly.module.css';

interface Props {
  title: string;
  content: string;
  hasDivider?: boolean;
  date: string;
  name: string;
}

const NotesReadOnly: FC<Props> = ({
  title,
  content,
  hasDivider,
  date,
  name,
}) => {
  return (
    <section
      data-testid="notes-readonly"
      className={classes.notesEditContainer}
    >
      <div className={classes.notesHeader}>
        <p data-testid="notes-readonly-title" className={classes.notesTitle}>
          {title}
        </p>

        <p className={classes.notesDate}>{date}</p>
      </div>
      <p data-testid="notes-readonly-subtitle" className={classes.extraDetail}>
        {content}
      </p>

      <div className={classes.notesFooter}>
        <p className={classes.notesAuthorName}>{name}</p>
      </div>

      {hasDivider && (
        <div
          data-testid="notes-divider"
          className={classes.editContainerDivider}
        />
      )}
    </section>
  );
};

NotesReadOnly.defaultProps = {
  hasDivider: true,
};

export default NotesReadOnly;
