import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  EmployerBuilder,
  LoadEmployersPaginatedInput,
  LoadEmplyersPaginatedOutput,
} from '../employers.types';

export const loadEmployersPaginated = (builder: EmployerBuilder) =>
  builder.query<LoadEmplyersPaginatedOutput, LoadEmployersPaginatedInput>({
    query: (options) => ({
      url: '/employers',
      method: 'GET',
      params: options.data,
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.data.map(({ Id }) => ({ type: 'Employer' as const, Id })),
            { type: 'Employer', Id: 'LIST' },
          ]
        : [{ type: 'Employer', Id: 'LIST' }],
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[EmployerData]',
        args.lang === 'es'
          ? ErrorMessageEs.loadPaginatedCompaniesError
          : ErrorMessageEn.loadPaginatedCompaniesError,
        err,
        true
      );

      return err.data;
    },
  });
