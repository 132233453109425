import type { FC, ReactNode } from 'react';
import Modal from 'react-modal';

interface ModalProps {
  header: ReactNode;
  isOpen: boolean;
  customStyles: { content: {} };
  customClass: {
    readonly [key: string]: string;
  };
  closeModal: () => void;
  bg: boolean;
  isMobile: boolean | undefined;
}
const CustomModal: FC<ModalProps> = ({
  children,
  header,
  isOpen,
  closeModal,
  customStyles,
  customClass,
  bg,
  isMobile,
}) => {
  return (
    <div
      className={`${isOpen && bg && isMobile ? customClass.background : ''}`}
    >
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        className={customClass.Modal}
        contentLabel="Example Modal"
      >
        <div data-testid="modal-header" className={customClass.ModalHeader}>
          {header}
        </div>
        <div data-testid="modal-content" className={customClass.ModalContent}>
          {children}
        </div>
      </Modal>
    </div>
  );
};

export default CustomModal;
