import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { getTraceId } from './traceId';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('id_token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    const segmentTraceId = getTraceId();
    if (segmentTraceId) {
      headers.set('x-trace-id', segmentTraceId);
    }

    return headers;
  },
});
