import { ReactElement, useCallback, useEffect, useState } from 'react';
import DragAndDropSortableContainer from '$components/DragAndDropSortable/DragAndDropSortableContainer';
import { useSortAdditionalVideosMutation } from '$store/modules/additional-videos';
import { AdditionalVideo } from '../../../../../../types/additional-video/additional-video';
import VideoCardFull from './VideoCardFull/VideoCardFull';

interface Props {
  candidateId: number;
  additionalVideos: AdditionalVideo[];
  showCheckbox: boolean;
  setSelectedVideos: React.Dispatch<React.SetStateAction<number[]>>;
  selectedVideos: number[];
  internal?: boolean;
  setSelectedShowVideos: React.Dispatch<React.SetStateAction<number[]>>;
  selectedShowVideos: number[];
  selectedVideosType: number[];
  selectedShowVideosType: number[];
}
const VideoListFull = ({
  candidateId,
  additionalVideos,
  showCheckbox,
  setSelectedVideos,
  selectedVideos,
  internal,
  setSelectedShowVideos,
  selectedShowVideos,
  selectedVideosType,
  selectedShowVideosType,
}: Props) => {
  const [allVideos, setAllVideos] = useState<
    { id: number; component: ReactElement }[]
  >([]);

  const [sortAdditionalVideos, { data: sortedVideosResult }] =
    useSortAdditionalVideosMutation();

  const onSortChange = (ids: number[]) => {
    sortAdditionalVideos({
      newAdditionalVideosOrder: ids,
      candidateId,
    });
  };

  const generateSorteableData = useCallback(
    (video) => {
      return {
        id: video.Id,
        component: (
          <VideoCardFull
            candidateId={candidateId}
            key={video.Id}
            video={video}
            showCheckbox={showCheckbox}
            selectedVideos={selectedVideos}
            setSelectedVideos={setSelectedVideos}
            internal={internal}
            setSelectedShowVideos={setSelectedShowVideos}
            selectedShowVideos={selectedShowVideos}
            selectedVideosType={selectedVideosType}
            selectedShowVideosType={selectedShowVideosType}
          />
        ),
      };
    },
    [
      candidateId,
      internal,
      selectedShowVideos,
      selectedShowVideosType,
      selectedVideos,
      selectedVideosType,
      setSelectedShowVideos,
      setSelectedVideos,
      showCheckbox,
    ]
  );

  // first display
  useEffect(() => {
    const tempArr: { id: number; component: ReactElement }[] = [];

    additionalVideos.forEach((video) => {
      tempArr.push(generateSorteableData(video));
    });

    setAllVideos(tempArr);
  }, [
    additionalVideos,
    candidateId,
    internal,
    selectedShowVideos,
    selectedShowVideosType,
    selectedVideos,
    selectedVideosType,
    setSelectedShowVideos,
    setSelectedVideos,
    showCheckbox,
    generateSorteableData,
  ]);

  // after the first readjustment
  useEffect(() => {
    if (!sortedVideosResult) return;
    const tempArr: { id: number; component: ReactElement }[] = [];
    sortedVideosResult?.forEach((video) => {
      tempArr.push(generateSorteableData(video));
    });

    setAllVideos(tempArr);
  }, [generateSorteableData, sortedVideosResult]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      <DragAndDropSortableContainer data={allVideos} onChange={onSortChange} />
    </div>
  );
};

VideoListFull.defaultProps = {
  internal: false,
};

export default VideoListFull;
