import EducationDegreeTypes from '../types/DegreeTypes';

const optionsSelect = [
  {
    id: EducationDegreeTypes.ASSOCIATE_DEGREE,
    text: EducationDegreeTypes.ASSOCIATE_DEGREE,
  },
  {
    id: EducationDegreeTypes.BACHELORS_DEGREE,
    text: EducationDegreeTypes.BACHELORS_DEGREE,
  },
  {
    id: EducationDegreeTypes.HIGH_SCHOOL,
    text: EducationDegreeTypes.HIGH_SCHOOL,
  },
  {
    id: EducationDegreeTypes.MASTERS_DEGREE,
    text: EducationDegreeTypes.MASTERS_DEGREE,
  },
  { id: EducationDegreeTypes.PHD, text: EducationDegreeTypes.PHD },
  {
    id: EducationDegreeTypes.VOCATIONAL,
    text: EducationDegreeTypes.VOCATIONAL,
  },
];

export default optionsSelect;
