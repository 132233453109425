import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import AddNewJobForm from './AddNewJobForm';
import classes from './AddNewJobModal.module.css';

interface Props {
  modalIsOpen: boolean;
  onClose: () => void;
}

const AddNewJobModal: FC<Props> = ({ modalIsOpen, onClose }) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const [t] = useTranslation('global');

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={onClose}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      contentLabel="Add New Position"
      ariaHideApp={false}
    >
      <h1 className={classes.modalTitle} data-testid="add-new-job-title">
        {t('EmployerAddPosition.AddTitle')}
      </h1>
      <p>{t('EmployerAddPosition.AddText')}</p>

      <p className={classes.modalSubtitle} data-testid="add-new-job-subtitle">
        {t('EmployerAddPosition.Position')}
      </p>
      <AddNewJobForm onClose={onClose} />
    </Modal>
  );
};

export default AddNewJobModal;
