import { FC } from 'react';

import classes from './NumberedStep.module.css';

interface NumberedStepProps {
  icon: FC;
  title: string;
  subtitle: string;
  char: string;
  charStyles?: {};
  titleFontStyles?: {};
  subTitleFontStyles?: {};
  containerStyles?: {};
}

const defaultProps = {
  charStyles: {},
  titleFontStyles: {},
  subTitleFontStyles: {},
  containerStyles: {},
};

const NumberedStep: FC<NumberedStepProps> = ({
  icon: SVG,
  title,
  subtitle,
  char,
  charStyles,
  titleFontStyles,
  subTitleFontStyles,
  containerStyles,
}) => {
  return (
    <div
      className={classes.StepContainer}
      data-testid="numbered-step"
      style={containerStyles}
    >
      <div className={classes.IconContainer}>
        <div className={classes.SVGContainer}>
          <SVG />
        </div>
        <p className={classes.CharContainer} style={charStyles}>
          {char}
        </p>
      </div>
      <div className={classes.TextContainer}>
        <p
          // data-testid="progress-indicator-title"
          className={classes.Title}
          style={titleFontStyles}
        >
          {title}
        </p>
        <p
          // data-testid="progress-indicator-subtitle"
          className={classes.Subtitle}
          style={subTitleFontStyles}
          // subtitleColor: #ffffffbf
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
};

NumberedStep.defaultProps = defaultProps;

export default NumberedStep;
