import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  UseFormResetField,
  useWatch,
} from 'react-hook-form';
import { FileRejection, useDropzone } from 'react-dropzone';
import classes from './DropZoneFile.module.css';
import type { FormData as AdditionalDocumentsFormData } from '../AdditionalDocumentsForm/AdditionalDocumentsForm';
import { warningHandlerInvalidFiles } from '../../../store/utils';
import DragDrop from '../../DragDrop/DragDrop';
import { useSaveAdditionalDocumentMutation } from '../../../store/modules/additional-documents';

interface Props {
  control: Control<AdditionalDocumentsFormData, object>;
  reset: UseFormResetField<AdditionalDocumentsFormData>;
  error?: string;
  setAcceptedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  secondary?: boolean;
  disabled?: boolean;
  candidateId: number;
}
const MAX_SIZE = 52_428_800;

const DropZoneFile: FC<Props> = ({
  control,
  reset,
  error,
  setAcceptedFile,
  secondary,
  disabled,
  candidateId,
}) => {
  const [t, i18n] = useTranslation('global');
  const [saveAdditionalDocumentApi, { isLoading }] =
    useSaveAdditionalDocumentMutation();
  const watch = useWatch({
    control,
  });
  const { FileCategory, IsCandidatesVisible, IsEmployersVisible } = watch;

  const onDrop = useCallback(
    // submit

    async (acceptedFiles: File[], fileRejection: FileRejection[]) => {
      warningHandlerInvalidFiles(true, fileRejection);

      if (acceptedFiles.length > 0) {
        setAcceptedFile(undefined);
        reset('file');
        const formData = new FormData();

        if (!acceptedFiles[0] || !FileCategory) {
          return;
        }

        formData.append('CandidateId', `${candidateId}`);
        formData.append(
          'Description',
          acceptedFiles[0].name.split('.')[0] ?? ''
        );
        formData.append('FileCategory', FileCategory);
        formData.append('file', acceptedFiles[0]);
        if (IsCandidatesVisible) {
          formData.append('IsCandidatesVisible', `${IsCandidatesVisible}`);
        }
        if (IsEmployersVisible) {
          formData.append('IsEmployersVisible', `${IsEmployersVisible}`);
        }

        saveAdditionalDocumentApi({
          lang: i18n.language as 'en' | 'es',
          data: formData,
        });
      }
    },
    [
      FileCategory,
      IsCandidatesVisible,
      IsEmployersVisible,
      candidateId,
      i18n.language,
      reset,
      saveAdditionalDocumentApi,
      setAcceptedFile,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept:
      'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    maxSize: MAX_SIZE,
    maxFiles: 1,
    onDrop,
    disabled,
  });
  return (
    <div>
      <Controller
        control={control}
        name="file"
        rules={{
          required: `${t('AdditionalDocs.FileRequired')}`,
        }}
        render={() => (
          <div className={classes.DropZoneContainer}>
            <div className={classes.Center}>
              <DragDrop
                isMobile={false}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
                supportedFilesText="Allowed file types: PDF, DOC, DOCX"
                disabled={disabled || isLoading}
                secondary={secondary}
              />
            </div>
          </div>
        )}
      />
      {error && (
        <div className={classes.errorContainer}>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

DropZoneFile.defaultProps = {
  error: undefined,
  secondary: false,
  disabled: false,
};

export default DropZoneFile;
