import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountButton from '../AccountButton/AccountButton';
import { ReactComponent as Refer } from '../../icons/Refer.svg';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as LogoBlue } from '../../images/logoBlue.svg';
import { ReactComponent as SimeraLogoNoTexto } from '../../icons/SimeraLogoNoTexto.svg';
import classes from './Home.module.css';
import Footer from '../Footer/Footer';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import useWindowSize from '../../hooks/useWindowSize';

interface HomeLayoutProps {
  children?: React.ReactNode;
  isProfile?: boolean;
  hideContent?: boolean;
  secondary?: boolean;
}

const HomeLayout: FC<HomeLayoutProps> = ({
  children,
  isProfile,
  hideContent,
  secondary,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [step, setStep] = useState(-1);
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  const [t] = useTranslation('global');
  const secondaryStyle = isProfile || secondary;

  useEffect(() => {
    if (windowSize && windowSize.width) {
      setIsMobile(windowSize.width < 768);
    }
  }, [windowSize, setIsMobile]);

  const LogoDisplay = secondaryStyle ? LogoBlue : Logo;
  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const navigate = useNavigate();
  const onClickRefer = () => navigate('/referrals');

  const goToStep = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    n: number
  ) => {
    e.preventDefault();

    if (step === -1) {
      window.location.replace(`/home#step-0`);
      setStep(0);
    }
    if (step <= 6 && n === -1) {
      const newStep = step === 0 ? step : step - 1;
      window.location.replace(`/home#step-${newStep}`);
      setStep(newStep);
    } else if (step >= 0 && n === 1) {
      const newStep = step === 6 ? step : step + 1;
      window.location.replace(`/home#step-${newStep}`);
      setStep(newStep);
    }
    if (typeof window !== 'undefined') {
      const hashId = window.location.hash;

      if (hashId) {
        const element = document.querySelector(hashId);

        if (element) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }, 350);
        }
      }
    }
  };

  return (
    <div
      data-testid="home-container"
      className={`${classes.homeContainer} ${
        hideContent && secondaryStyle && isMobile && classes.displayNone
      }`}
    >
      {openMenu && (
        <DropdownMenu className={classes.dropdownMenu} setModal={setOpenMenu} />
      )}
      <div
        data-testid="home-header"
        className={secondaryStyle ? classes.headerProfile : classes.header}
      >
        <div
          className={
            secondaryStyle ? classes.headerTopProfile : classes.headerTop
          }
        >
          <div className={classes.headerTop__Left}>
            <div className={classes.headerTop__CompanyName}>
              {isMobile ? <SimeraLogoNoTexto /> : <LogoDisplay />}
            </div>
            <p
              className={classes.jobDashboard}
              onClick={() => {
                navigate('/home');
              }}
              aria-hidden
            >
              {t('home.profile')}
            </p>
            <p
              className={classes.jobDashboard}
              onClick={() => {
                navigate('/job-interview');
              }}
              aria-hidden
            >
              {t('home.interviews')}
            </p>
            {/* <p
              className={classes.jobDashboard}
              onClick={() => {
                navigate('/job-dashboard');
              }}
              aria-hidden
            >
              {t('home.jobDashboard')}
            </p> */}
          </div>

          <div className={classes.header__CompanyActions}>
            <div
              onClick={onClickRefer}
              aria-hidden="true"
              className={classes.referralButton}
              data-testid="referal-button"
            >
              {t('home.refer')}
              <Refer className={classes.referIcon} />
            </div>
            <AccountButton
              isProfile={secondaryStyle}
              t={t}
              action={handleMenu}
            />
          </div>
        </div>
        {!secondaryStyle && (
          <div className={classes.headerText}>
            <p className={classes.welcome}>{t('home.welcome_1')}</p>
            <div className={classes.divider} />
            <p className={classes.subtitle}>{t('home.instructions')}</p>
            <p className={classes.subtitle}>
              <span className={classes.subtitleBold}>
                {t('home.instructions_2')}
              </span>
              {t('home.instructions_3')}
            </p>
          </div>
        )}
      </div>
      {children && (
        <div
          className={`${classes.content} ${
            secondaryStyle && classes.profileContent
          }`}
        >
          {children}
        </div>
      )}
      {!isProfile && <Footer changeStep={goToStep} />}
    </div>
  );
};

HomeLayout.defaultProps = {
  isProfile: false,
  hideContent: false,
  secondary: false,
  children: null,
};

export default HomeLayout;
