import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ReactComponent as LocationIcon } from '../../icons/Location.svg';
import Button from '../Buttons/Button';
import classes from './ContactInformationModal.module.css';
import { ReactComponent as CloseX } from '../../icons/CloseX.svg';
import BasicInput from '../Inputs/BaseInput';
import CancelModal from '../CancelModal/CancelModal';
import { phoneRegex } from '../../constants/regex';
import AutocompleteRHF from '../Autocomplete/AutocompleteRHF';
import countries from '../../constants/countries';
import FormFooter from '../FormFooter/FormFooter';
import { SaveState } from '../../types/ForrmType';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import UpdateCandidateDto from '../../types/serverTypes/UpdateCandidateDto';
import { useUploadCandidateInfoMutation } from '../../store/modules/candidates';
import { Candidate } from '../../types/candidates';

interface ContactInformationModalProps {
  setShowModal: (value: React.SetStateAction<boolean>) => void;
  candidate: Candidate;
}

const ContactInformationModal: FC<ContactInformationModalProps> = ({
  setShowModal,
  candidate,
}) => {
  const [uploadCandidate, { isLoading }] = useUploadCandidateInfoMutation();
  const serverUser = candidate?.User;

  const [showClickCancel, setClickCancelModal] = useState(false);
  const modalRef = useRef<any>(null);
  const [t] = useTranslation('global');
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      ResidenceCountry: candidate?.ResidenceCountry,
      Phone: serverUser?.Phone,
      AddresLine1: candidate?.AddresLine1,
      AddresLine2: candidate?.AddresLine2,
      WhatsAppNumber: candidate?.WhatsAppNumber,
    },
  });

  useOnClickOutside(modalRef, () => {
    if (!showClickCancel) {
      setShowModal(false);
    }
  });

  const phone = register('Phone', {
    pattern: phoneRegex,
    setValueAs: (value: string | undefined) => {
      return value?.replace(/\s/g, '');
    },
  });

  const address1 = register('AddresLine1');
  const address2 = register('AddresLine2');
  const residence = register('ResidenceCountry');
  const whatsappNumber = register('WhatsAppNumber');

  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setShowModal(false);
    }

    setClickCancelModal(false);
  };
  const handleCancel = () => {
    if (!isDirty) {
      setShowModal(false);
    }
    setClickCancelModal(isDirty);
  };
  const saveInfo = async (data: UpdateCandidateDto) => {
    await uploadCandidate({
      candidateId: candidate!.Id,
      data,
    });
    setShowModal(false);
  };

  return (
    <div className={classes.modal}>
      <div className={classes.modalContent} ref={modalRef}>
        <nav className={classes.modal__nav}>
          <h2 className={classes.modal__nav__title}>
            {t('infoContainer.basicInformation')}
          </h2>
          <Button
            icon={CloseX}
            iconStyles={{
              width: '14',
              height: '14',
              fill: '#232428',
            }}
            bgColor="transparent"
            showText={false}
            onClick={() => setShowModal(false)}
            customStyles={{
              width: '0.875rem',
              height: '0.875rem',
              flexGrow: '0',
              marginBottom: '0',
            }}
          />
        </nav>
        <main className={classes.modal__main}>
          <form
            className={classes.modal__form}
            onSubmit={handleSubmit(saveInfo)}
          >
            <div className={classes.modal__form__fields}>
              <BasicInput
                label={t('profile.phone')}
                type="text"
                onChange={phone.onChange}
                refProp={phone.ref}
                onBlur={phone.onBlur}
                name={phone.name}
                placeholder="N/A"
                errorText={errors.Phone && t('register.phoneInputValid')}
              />
              <div className={classes.AutocompleteWrapper}>
                <AutocompleteRHF
                  label={t('infoContainer.ResidenceCountry')}
                  name={residence.name}
                  onBlur={residence.onBlur}
                  onChange={residence.onChange}
                  refProp={residence.ref}
                  options={countries}
                  setValue={setValue}
                  placeholder="N/A"
                  icon={LocationIcon}
                  currSearchVal={
                    (serverUser && candidate?.ResidenceCountry) ?? ''
                  }
                />
              </div>
              <BasicInput
                label={t('profile.Address1')}
                type="text"
                onChange={address1.onChange}
                refProp={address1.ref}
                onBlur={address1.onBlur}
                name={address1.name}
                placeholder="N/A"
              />
              <BasicInput
                label={t('profile.Address2')}
                type="text"
                onChange={address2.onChange}
                refProp={address2.ref}
                onBlur={address2.onBlur}
                name={address2.name}
                placeholder="N/A"
              />
              <BasicInput
                label={t('profile.Email')}
                type="text"
                onChange={address2.onChange}
                refProp={address2.ref}
                onBlur={address2.onBlur}
                name={address2.name}
                placeholder="N/A"
              />
              <BasicInput
                label={t('profile.WhatsappNumber')}
                type="text"
                onChange={whatsappNumber.onChange}
                refProp={whatsappNumber.ref}
                onBlur={whatsappNumber.onBlur}
                name={whatsappNumber.name}
                placeholder="N/A"
              />
            </div>
            <div style={{ marginTop: '1rem' }}>
              <FormFooter
                translate={t}
                saveState={isLoading ? SaveState.PENDING : SaveState.IDLE}
                disabled={isLoading || !isDirty}
                submit
                onClickCancel={handleCancel}
                useMobile={false}
              />
            </div>

            {showClickCancel && handleCancelModal && (
              <CancelModal onClickCancelModal={handleCancelModal} />
            )}
          </form>
        </main>
      </div>
    </div>
  );
};

export default ContactInformationModal;
