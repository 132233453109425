import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MAX_RELOAD_TIMES,
  RELOAD_INTERVAL_TIME,
} from '$constants/VideoChunksUpload';
import classes from './VideoGallery.module.css';
import { NoVideos } from '../NoVideos';
import { useCandidateId } from '../../../../../hooks/useCandidateId';
import { useGetAdditionalVideosByCandidateIdQuery } from '../../../../../store/modules/additional-videos';
import VideoListFull from './VideoListFull/VideoListFull';
import Spinner from '../../../../Spinner/Spinner';
import { ReactComponent as AddIcon } from '../../../../../icons/Add.svg';
import { ImportVideoModal } from '../ImportVideoModal';
import { useAppSelector } from '../../../../../hooks';
import { HideVideosModal } from '../HideVideosModal';
import { ShowVideosModal } from '../ShowVideosModal';
import { ShowButtons } from '../ShowButtons';
import { useAdditionalVideo } from '../../../../../store/modules/additional-videos/hooks';

interface VideoGalleryProps {
  internal?: boolean;
  employer?: boolean;
}
export const VideoGalleryFull = ({ internal, employer }: VideoGalleryProps) => {
  const id = useCandidateId();
  const [t, i18next] = useTranslation('global');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [showVideosModal, setShowVideosModal] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectedShowVideos, setSelectedShowVideos] = useState<number[]>([]);
  const [selectedVideos, setSelectedVideos] = useState<number[]>([]);
  const [selectedShowVideosType, setSelectedShowVideosType] = useState<
    number[]
  >([]);
  const [selectedVideosType, setSelectedVideosType] = useState<number[]>([]);
  const reloadInterval = useRef<NodeJS.Timeout | null>();
  const reloadCounter = useRef(0);
  const { activeVideo } = useAppSelector((state) => state.additionalVideoSlice);
  const {
    data: additionalVideos,
    isLoading,
    refetch,
  } = useGetAdditionalVideosByCandidateIdQuery({
    candidateId: id,
    lang: i18next.language as 'en' | 'es',
  });

  const { setAdditionalVideos } = useAdditionalVideo();

  useEffect(() => {
    if (additionalVideos) {
      setAdditionalVideos(additionalVideos);
    }
  }, [additionalVideos, setAdditionalVideos]);

  useEffect(() => {
    const hasProcessingVideos = () => {
      return (
        additionalVideos?.find((item) => item.Url === '' || item.Key === '') !==
        undefined
      );
    };
    if (
      additionalVideos &&
      additionalVideos.length > 0 &&
      hasProcessingVideos()
    ) {
      reloadInterval.current = setInterval(async () => {
        if (hasProcessingVideos() && reloadCounter.current < MAX_RELOAD_TIMES) {
          reloadCounter.current += 1;
          await refetch();
        } else {
          if (reloadInterval.current) clearTimeout(reloadInterval.current);
          reloadCounter.current = 0;
        }
      }, RELOAD_INTERVAL_TIME);
    }
    return () => {
      if (reloadInterval.current) clearInterval(reloadInterval.current);
    };
  }, [additionalVideos, refetch]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', width: '100%', alignContent: 'center' }}>
        <Spinner height="200px" />
      </div>
    );
  }

  return (
    <section className={classes.container}>
      <header className={classes.header}>
        <p className={classes.title}>
          {internal || employer
            ? t('AdditionalVideos.galleryTitleInternal')
            : t('AdditionalVideos.galleryTitle')}
        </p>
        <section className={classes.headerAction}>
          {internal && !activeVideo && (
            <ShowButtons
              selectedShowVideos={selectedShowVideos}
              selectedVideos={selectedVideos}
              showCheckbox={showCheckbox}
              setSelectedVideos={setSelectedVideos}
              setSelectedShowVideos={setSelectedShowVideos}
              setShowCheckbox={setShowCheckbox}
              setShowVisibilityModal={setShowVisibilityModal}
              setShowVideosModal={setShowVideosModal}
              setSelectedVideosType={setSelectedVideosType}
              setSelectedShowVideosType={setSelectedShowVideosType}
              selectedShowVideosType={selectedShowVideosType}
              selectedVideosType={selectedVideosType}
            />
          )}
          {internal && !employer && (
            <button
              className={classes.importButton}
              onClick={() => setIsModalOpen(true)}
              type="button"
            >
              {t('AdditionalVideos.buttons.upload')}
              <AddIcon className={classes.addIcon} />
            </button>
          )}
        </section>
      </header>
      <br />
      {internal && (
        <ImportVideoModal
          isModalOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        />
      )}
      {!additionalVideos || additionalVideos?.length === 0 ? (
        <NoVideos internal={internal} />
      ) : (
        <VideoListFull
          candidateId={id}
          additionalVideos={additionalVideos || []}
          showCheckbox={showCheckbox}
          setSelectedVideos={setSelectedVideos}
          selectedVideos={selectedVideos}
          internal={internal}
          setSelectedShowVideos={setSelectedShowVideos}
          selectedShowVideos={selectedShowVideos}
          selectedVideosType={selectedVideosType}
          selectedShowVideosType={selectedShowVideosType}
        />
      )}
      {showVisibilityModal && (
        <HideVideosModal
          setModal={setShowVisibilityModal}
          setShowCheckbox={setShowCheckbox}
          selectedVideos={selectedVideos}
          setSelectedVideos={setSelectedVideos}
          setSelectedVideosType={setSelectedVideosType}
          selectedVideosType={selectedVideosType}
        />
      )}
      {showVideosModal && (
        <ShowVideosModal
          setModal={setShowVideosModal}
          setShowCheckbox={setShowCheckbox}
          selectedVideos={selectedShowVideos}
          setSelectedVideos={setSelectedShowVideos}
          setSelectedVideosType={setSelectedShowVideosType}
          selectedVideosType={selectedShowVideosType}
        />
      )}
    </section>
  );
};

VideoGalleryFull.defaultProps = {
  internal: false,
  employer: false,
};
