import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  FieldValues,
  useForm,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useFormState,
} from 'react-hook-form';
import classes from './FormContainer.module.css';
import { FieldTypes, InitialFormStateTypes } from '../../types/user_info';
import { SaveState } from '../../types/ForrmType';
import SaveButton from '../Buttons/SaveButton';
import FormFooter from '../FormFooter/FormFooter';
import CancelModal from '../CancelModal/CancelModal';
import NewSkills from './NewSkills';
import {
  useAddMultipleCandidateSkillsInternalMutation,
  useAddMultipleCandidateSkillsMutation,
} from '../../store/modules/candidate-skill';
import { errorHandler } from '../../store/utils';
import { candidatesApi } from '../../store/modules/candidates';
import { useAppDispatch } from '../../hooks';

export type FormValues = {
  CertificationId: {
    val: string;
    mult: number;
  };
  Skill: {
    val: string;
    mult: number;
    Id: number;
    title: string;
  };
  SkillLevel: {
    val: string;
    mult: number;
  };
  certDate: {
    val: string;
    mult: number;
  };
  CertificationUrl: {
    val: string;
    mult: number;
  };
};

const MAX_SKILLS = 25;
interface ContainerProps {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean, continueEditing: boolean) => void;
  show?: boolean;
  onClickSave: () => void;
  newSkills?: boolean;
  countForms: number;
}

const NewSkillsContainer: FC<ContainerProps> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  onClickSave,
  newSkills,
  countForms,
}) => {
  const { candidateId } = useParams();
  const dispatch = useAppDispatch();

  const [
    saveSkillInternal,
    {
      isLoading: isLoadingInternal,
      isSuccess: isSuccessInternal,
      isError: isErrorInternal,
    },
  ] = useAddMultipleCandidateSkillsInternalMutation();
  const [saveSkill, { isLoading, isSuccess, isError }] =
    useAddMultipleCandidateSkillsMutation();
  const loadingSkills = useCallback(() => {
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }
    if (isSuccess || isSuccessInternal) {
      return SaveState.FULFILLED;
    }
    if (isError || isErrorInternal) {
      return SaveState.FAILED;
    }
    return SaveState.IDLE;
  }, [
    isError,
    isErrorInternal,
    isSuccess,
    isSuccessInternal,
    isLoading,
    isLoadingInternal,
  ]);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      CertificationId: { val: '', mult: 0 },
      Skill: { val: '', mult: 0, Id: 0, title: '' },
      SkillLevel: { val: '', mult: 0 },
      certDate: { val: '', mult: 0 },
      CertificationUrl: { val: '', mult: 0 },
    },
  });

  const { isDirty } = useFormState({ control });

  const [t, i18next] = useTranslation('global');

  const saveInfo = async (data: FormValues) => {
    if (countForms >= MAX_SKILLS) {
      errorHandler(
        'skillsScreen',
        'Number of skills limit reached',
        null,
        true
      );
      return;
    }
    if (data) {
      try {
        if (Number(candidateId)) {
          await saveSkillInternal({
            candidateId: Number(candidateId),
            skills: [data],
            lang: i18next.language as 'en' | 'es',
          });
        } else {
          saveSkill({
            skills: [data],
            lang: i18next.language as 'en' | 'es',
          });
          dispatch(candidatesApi.util.invalidateTags(['Score']));
        }
        if (setEdit) {
          setEdit(true);
        }
        if (newSkills) {
          onClickSave();
        }
        window.analytics.track('Skills form submitted');
      } catch (error) {
        // error
      }
    }
  };
  return (
    <div data-testid="skills-form" className={classes.MainContainer}>
      <div className={classes.CardContainer}>
        <form
          data-testid="form-container"
          onSubmit={handleSubmit(saveInfo)}
          className={classes.Container}
        >
          <NewSkills
            t={t}
            errors={errors}
            register={
              register as UseFormRegister<
                | FieldValues
                | {
                    Id?: number;
                    CertificationId: FieldTypes;
                    Skill: {
                      val: string;
                      mult: number;
                      Id: number;
                      title?: string;
                    };
                    SkillLevel: FieldTypes;
                    certDate: FieldTypes;
                    CertificationUrl: FieldTypes;
                  }
              >
            }
            removeSkill={() => {}}
            setValue={
              setValue as UseFormSetValue<
                | InitialFormStateTypes
                | {
                    Id?: number;
                    CertificationId: FieldTypes;
                    Skill: {
                      val: string;
                      mult: number;
                      Id: number;
                      title?: string;
                    };
                    SkillLevel: FieldTypes;
                    certDate: FieldTypes;
                    CertificationUrl: FieldTypes;
                  }
              >
            }
            getValues={
              getValues as UseFormGetValues<
                | InitialFormStateTypes
                | {
                    Id?: number;
                    CertificationId: FieldTypes;
                    Skill: {
                      val: string;
                      mult: number;
                      Id: number;
                      title?: string;
                    };
                    SkillLevel: FieldTypes;
                    certDate: FieldTypes;
                    CertificationUrl: FieldTypes;
                  }
              >
            }
            removeUnsaved={() => {}}
          />

          <div className={classes.ButtonContainer}>
            {isEdit ? (
              <FormFooter
                onClickCancel={onClickCancel}
                disabled={loadingSkills() === SaveState.PENDING || !isDirty}
                saveState={isDirty ? SaveState.IDLE : loadingSkills()}
                translate={t}
                submit
              />
            ) : (
              <SaveButton
                disabled={loadingSkills() === SaveState.PENDING || !isDirty}
                state={isDirty ? SaveState.IDLE : loadingSkills()}
                t={t}
                submit
              />
            )}
          </div>
        </form>
        {show && onClickCancelModal && (
          <CancelModal onClickCancelModal={onClickCancelModal} />
        )}
      </div>
    </div>
  );
};

NewSkillsContainer.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  newSkills: false,
};

export default NewSkillsContainer;
