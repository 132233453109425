import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  FindHiredCandidatePositionStatusByCompanyInput,
  FindHiredCandidatePositionStatusByCompanyOutput,
} from '../candidates.types';

export const findHiredCandidatePositionStatusByCompany = (
  builder: CandidateBuilder
) =>
  builder.query<
    FindHiredCandidatePositionStatusByCompanyOutput,
    FindHiredCandidatePositionStatusByCompanyInput
  >({
    query: () => '/candidate-position-status/employees',
    providesTags: () => ['CandidatePositionStatus'],
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[findHiredCandidatePositionStatusByCompany]',
        (args.lang ?? 'en') === 'en'
          ? ErrorMessageEn.ErrorGettingEmployees
          : ErrorMessageEs.ErrorGettingEmployees,
        err,
        true
      );

      return err.data;
    },
  });
