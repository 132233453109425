import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import BaseInput from '../../../../Inputs/BaseInput';
import { SaveState } from '../../../../../types/ForrmType';
import CancelModal from '../../../../CancelModal/CancelModal';
import FormFooter from '../../../../FormFooter/FormFooter';
import { UpdateEmployerDto } from '../../../../../types/serverTypes/updateEmployerDto';
import { useUpdateEmployerMutation } from '../../../../../store/modules/employers';
import { EmployerData } from '../../../../../types/candidates';

interface Props {
  employer: EmployerData;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const UpdateEmployerForm: FC<Props> = ({ employer, setIsOpen }) => {
  const [t, i18next] = useTranslation('global');
  const [saveState, setSaveState] = useState(SaveState.IDLE);
  const [loading, setLoading] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [updateEmployer] = useUpdateEmployerMutation();
  const handleCancel = () => {
    setShowCancel(true);
  };
  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setIsOpen(false);
    }

    setShowCancel(false);
  };

  const {
    register,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      FirstName: employer.User.FirstName,
      LastName: employer.User.LastName,
    },
  });

  const firstName = register('FirstName', {
    required: `${t('Employers.FirstNameError')}`,
  });

  const lastName = register('LastName', {
    required: `${t('Employers.LastNameError')}`,
  });

  const saveData = async (data: UpdateEmployerDto) => {
    setSaveState(SaveState.PENDING);
    setLoading(true);
    await updateEmployer({
      data,
      employerId: employer.Id,
      lang: i18next.language as 'en' | 'es',
    });
    setLoading(false);
    setSaveState(SaveState.IDLE);
    setIsOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(saveData)}>
      <BaseInput
        label={t('register.nameInput')}
        type="text"
        name={firstName.name}
        refProp={firstName.ref}
        onChange={firstName.onChange}
        onBlur={firstName.onBlur}
        errorText={errors.FirstName?.message}
      />

      <BaseInput
        label={t('register.lastNameInput')}
        type="text"
        name={lastName.name}
        refProp={lastName.ref}
        onChange={lastName.onChange}
        onBlur={lastName.onBlur}
        errorText={errors.LastName?.message}
      />
      <div style={{ marginTop: '1rem' }}>
        <FormFooter
          translate={t}
          saveState={saveState}
          submit
          onClickCancel={handleCancel}
          disabled={loading || !isDirty}
        />
      </div>
      {showCancel && handleCancelModal && (
        <CancelModal onClickCancelModal={handleCancelModal} />
      )}
    </form>
  );
};

export default UpdateEmployerForm;
