import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { EmployerData } from '../../../../types/candidates';
import { errorHandler, successHandler } from '../../../utils';
import {
  EmployerBuilder,
  UpdateEmployerInput,
  UpdateEmployerOutput,
} from '../employers.types';

export const updateEmployer = (builder: EmployerBuilder) =>
  builder.mutation<UpdateEmployerOutput, UpdateEmployerInput>({
    query: (options) => ({
      url: `/employers/${options.employerId}`,
      method: 'PATCH',
      body: { ...options.data },
    }),
    invalidatesTags: (result, err, { employerId }) => [
      { type: 'Employer', Id: employerId },
    ],
    transformResponse: (queryResponse: { data: EmployerData }, _, arg) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEn.UpdateEmployer
          : SuccessMessageEs.UpdateEmployer,
        true
      );
      return queryResponse.data;
    },
    transformErrorResponse: (error, _, arg) => {
      errorHandler(
        '[updateEmployer]',
        arg.lang === 'es'
          ? ErrorMessageEs.updatePaginatedCompaniesError
          : ErrorMessageEn.updatePaginatedCompaniesError,
        error.data,
        true
      );
      return error.data;
    },
  });
