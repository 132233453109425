import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import Modal from 'react-modal';
import { updateTermOfServiceEmployer } from '../../../api/auth.service';
import { logoutAuthAction } from '../../../store/legacy/auth';
import classes from './EmployerTermOfService.module.css';
import { useAppDispatch } from '../../../hooks';

interface Props {
  modalIsOpen: boolean;
  employerId: number | undefined;
  setOpenTermOfService: any;
}

const EmployerTermOfServiceModal: FC<Props> = ({
  modalIsOpen,
  employerId,
  setOpenTermOfService,
}) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const dispatch = useAppDispatch();
  const [t, i18next] = useTranslation('global');

  const acceptTermOfService = async () => {
    if (employerId) {
      const resp = await updateTermOfServiceEmployer(employerId);
      if (resp.AgreedTermsOfServiceDate) {
        setOpenTermOfService(false);
      }
    }
  };

  const declineTermOfService = async () => {
    dispatch(logoutAuthAction(i18next.language));
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      contentLabel="Terms Of Service"
      ariaHideApp={false}
    >
      <h1 className={classes.modalTitle}>
        {t('employersModal.termOfService')}
      </h1>
      <p className={classes.content}>
        By accessing or using our platform, users acknowledge and agree that any
        individuals found through our platform or services shall not be hired,
        contacted, or solicited for any reason except through Simera. Users
        further acknowledge and agree that any attempt to circumvent this
        restriction may result in immediate termination of their account and
        legal action.
      </p>
      <div className={classes.buttonsContainer}>
        <button
          onClick={declineTermOfService}
          className={classes.rejectButton}
          type="button"
        >
          {t('employersModal.decline')}
        </button>

        <button
          onClick={acceptTermOfService}
          className={classes.acceptButton}
          type="button"
        >
          {t('employersModal.accept')}
        </button>
      </div>
    </Modal>
  );
};

export default EmployerTermOfServiceModal;
