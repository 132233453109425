import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classes from './ActionEmployees.module.css';
import { ReactComponent as EyeIcon } from '../../../../../icons/PasswordEye.svg';
import { ReactComponent as EditIcon } from '../../../../../icons/EditIcon.svg';
import HoverTooltip from '../../../../Tooltip/HoverTooltip';
import { SIMERA_COMPANY_ROUTE } from '../../../../../constants/routes';
import UpdateCompanyModal from '../UpdateCompanyModal/UpdateCompanyModal';
import UpdateCompanyForm from '../UpdateCompanyForm/UpdateCompanyForm';

interface Props {
  companyId: number;
  companyName: string;
}
const ActionEmployees: FC<Props> = ({ companyId, companyName }) => {
  const navigate = useNavigate();
  const [t] = useTranslation('global');
  const [isOpen, setIsOpen] = useState(false);

  const handleClickEye = () => {
    navigate(SIMERA_COMPANY_ROUTE(companyId));
  };

  return (
    <section className={classes.actions}>
      <HoverTooltip text={t('CompaniesTable.UpdateCompany')}>
        <button
          aria-label="Update company"
          type="button"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <EditIcon fill="#5d5e61" />
        </button>
      </HoverTooltip>
      <HoverTooltip text={t('CompaniesTable.Details')}>
        <button
          aria-label="Hover Button Icon"
          type="button"
          onClick={handleClickEye}
        >
          <EyeIcon />
        </button>
      </HoverTooltip>
      <UpdateCompanyModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <UpdateCompanyForm
          companyId={companyId}
          setModal={setIsOpen}
          companyName={companyName}
        />
      </UpdateCompanyModal>
    </section>
  );
};

export default ActionEmployees;
