import { ChangeEventHandler, FC, Ref } from 'react';
import { ChangeHandler } from 'react-hook-form';
import classes from './BaseTestArea.module.css';

interface LineTextAreaProps {
  onBlur: ChangeHandler;
  name: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement> | undefined;
  refProp: Ref<any>;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  errorText?: string;
  maxLength?: number;
}

const defaultProps = {
  defaultValue: '',
  disabled: false,
  placeholder: '',
  errorText: '',
  maxLength: 600,
};

const FeedBackTextArea: FC<LineTextAreaProps> = ({
  onBlur,
  name,
  defaultValue,
  disabled,
  placeholder,
  errorText,
  refProp,
  onChange,
  maxLength,
}) => {
  return (
    <div data-testid="textarea-container" className={classes.TextAreaContainer}>
      <div>
        <div className={classes.TextAreaFlex}>
          <textarea
            data-testid="base-textarea"
            ref={refProp}
            name={name}
            className={classes.FeedbackLineStyle}
            onBlur={onBlur}
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={disabled}
            maxLength={maxLength}
            onChange={onChange}
          />
        </div>
      </div>
      {errorText !== '' && (
        <p className={classes.ErrorText} data-testid="textarea-error">
          {errorText}
        </p>
      )}
    </div>
  );
};
FeedBackTextArea.defaultProps = defaultProps;
export default FeedBackTextArea;
