import { CandidateData } from '../types/candidates';

export default function computeYearsOfExperience(
  workExperience: CandidateData['CandidateWorkExperience']
): number {
  let YearsOfExperience = 0;
  if (workExperience && workExperience.length > 0) {
    const sortedExperience = [...workExperience].sort((a, b) => {
      return (a?.Admission || '').localeCompare(b?.Admission || '');
    });

    let totalExperience: number = 0;
    let latestEgress: Date | null = null;

    sortedExperience.forEach((experience) => {
      let admissionDate: Date = experience.Admission
        ? new Date(experience.Admission)
        : new Date();
      const egressDate: Date = experience.Egress
        ? new Date(experience.Egress)
        : new Date();

      if (latestEgress && admissionDate < latestEgress) {
        admissionDate = latestEgress;
      }

      if (egressDate > admissionDate) {
        const experienceDuration: number = Math.abs(
          egressDate.getTime() - admissionDate.getTime()
        );
        totalExperience += experienceDuration / (1000 * 60 * 60 * 24); // converting milliseconds to days
        latestEgress = egressDate;
      }
    });

    const totalExperienceYears: number = Math.round(totalExperience / 365);
    YearsOfExperience = totalExperienceYears;
  }
  return YearsOfExperience;
}
