import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import classes from './SimeraEmployersScreen.module.css';
import CompaniesTable from './CompaniesTable/CompaniesTable';
import TableFooter from '../../TableFooter/TableFooter';
import isNumber from '../../../utils/isNumber';
import HoverTooltip from '../../Tooltip/HoverTooltip';
import { ReactComponent as ClearIcon } from '../../../icons/CloseRoundIcon.svg';
import { useGetCompaniesPaginatedQuery } from '../../../store/modules/companies';
import { CompaniesPaginatedOptions } from '../../../constants/CompaniesPaginatedOptions';
import { parseOrder } from '../../../constants/CandidatesPaginatedOptions';

interface FormData {
  search: string;
}
const SimeraEmployersScreen = () => {
  const [t] = useTranslation('global');
  const [searchParams, setSearchParams] = useSearchParams();
  const companiesQuery = useMemo<CompaniesPaginatedOptions>(() => {
    const id = searchParams.get('id');
    const name = searchParams.get('name');

    return {
      ...(id && { Id: parseInt(id, 10) }),
      ...(name && { Name: name }),
      take: parseInt(searchParams.get('take') ?? '10', 10),
      order: parseOrder(searchParams.get('order')),
      page: parseInt(searchParams.get('page') ?? '1', 10),
    };
  }, [searchParams]);
  const searchDefaultValue =
    companiesQuery.Name ?? companiesQuery.Id?.toString() ?? '';
  const { data } = useGetCompaniesPaginatedQuery({
    options: companiesQuery,
  });
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      search: searchDefaultValue,
    },
  });

  const setSingleSearchParam = (key: string, value: string | number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value.toString());
    setSearchParams(newSearchParams);
  };

  const search = register('search', {
    setValueAs: (value) => value.trim().replace(/\s\s+/g, ' '),
    required: true,
  });

  const handleSearch = (formData: FormData) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('page', '1');
    if (isNumber(+formData.search)) {
      newSearchParams.set('id', formData.search);
      newSearchParams.delete('name');
    } else {
      newSearchParams.set('name', formData.search);
      newSearchParams.delete('id');
    }
    setSearchParams(newSearchParams);
  };

  const clearSearch = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('id');
    newSearchParams.delete('name');
    setSearchParams(newSearchParams);
    reset({
      search: '',
    });
  };

  return (
    <div className={classes.content}>
      <header className={classes.header}>
        <div className={classes.headerText}>
          <h1 className={classes.title}>
            {t('SimeraInternal.EmployersTitle')}
          </h1>
        </div>
        <form onSubmit={handleSubmit(handleSearch)}>
          <div className={classes.formContainer}>
            <div className={classes.searchBarContent}>
              <input
                type="text"
                className={classes.searchBar}
                name={search.name}
                ref={search.ref}
                placeholder={t('CandidatesTable.SearchLabel')}
                onChange={search.onChange}
                onBlur={search.onBlur}
              />
            </div>

            <button
              className={classes.calendarButton}
              disabled={!isDirty}
              type="submit"
              data-testid="apply-filter-button"
            >
              {t('CandidatesTable.ApplyFilter')}
            </button>

            {getValues().search !== '' && (
              <HoverTooltip text={t('CompaniesTable.ClearFilters')}>
                <div>
                  <button
                    aria-label="Clear filters"
                    className={classes.calendarClearButton}
                    type="button"
                    data-testid="clear-filter-button"
                    onClick={clearSearch}
                  >
                    <ClearIcon fill="#716f6f8b" />
                  </button>
                </div>
              </HoverTooltip>
            )}
          </div>
        </form>
      </header>
      <CompaniesTable data={data?.data ?? []}>
        <TableFooter
          pageState={data?.meta.page ?? 1}
          pageSize={companiesQuery.take ?? 10}
          pageCount={data?.meta.pageCount ?? 0}
          hasPreviousPage={data?.meta.hasPreviousPage ?? false}
          hasNextPage={data?.meta.hasNextPage ?? false}
          nextPage={() =>
            setSingleSearchParam('page', (companiesQuery.page ?? 1) + 1)
          }
          previousPage={() =>
            setSingleSearchParam('page', (companiesQuery.page ?? 2) - 1)
          }
          handleSelectChange={(e) =>
            setSingleSearchParam('take', e.target.value)
          }
        />
      </CompaniesTable>
    </div>
  );
};

export default SimeraEmployersScreen;
