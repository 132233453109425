import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CandidateAdditionalVideoType } from '$types/candidates';
import { useOriginPath } from '../../../hooks/use-origin-path';
import { ReactComponent as PlayIconBig } from '../../../icons/PlayIconBig.svg';
import { useGetCandidateInfoQuery } from '../../../store/modules/candidates';
import Button from '../../Buttons/Button';
import VideoErrorScreen from '../../VideoErrorScreen/VideoErrorScreen';
import classes from './CandidateVideosModalContent.module.css';
import CandidateVideosModalHeader from './CandidateVideosModalHeader';
import VideoGenericElement from '../../GenericElements/VideoGenericElement';

interface Props {
  candidateId: number;
  primary?: boolean;
  handleClose: () => void;
  positionId: number;
  additionalVideos: CandidateAdditionalVideoType[];
}

const CandidateVideosModalContent: FC<Props> = ({
  candidateId,
  primary,
  handleClose,
  positionId,
  additionalVideos,
}) => {
  const [t] = useTranslation('global');
  const { data: candidate } = useGetCandidateInfoQuery(candidateId);
  const [controlsEnabled, setControlsEnabled] = useState(false);
  const [currentWatchingVideoIndex, setCurrentWatchingVideoIndex] = useState(0);
  const [errorLoading, setErrorLoading] = useState(false);
  const [textButton, setTextButton] = useState<string>(
    t('VideoModal.GotoVideos')
  );
  const navigate = useNavigate();
  const { setOriginPath } = useOriginPath();

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handlePreviousVideo = () => {
    setCurrentWatchingVideoIndex((currentIndex) => {
      if (additionalVideos && currentIndex === additionalVideos.length - 1)
        setTextButton(t('VideoModal.NextVideo'));

      return currentIndex === 0 ? 0 : currentIndex - 1;
    });
    setControlsEnabled(false);

    const video = videoRef.current;

    if (video) {
      video.load();
    }
  };

  const handleNextVideo = () => {
    setCurrentWatchingVideoIndex((currentIndex) => {
      const nextIndex = currentIndex + 1;
      if (additionalVideos && nextIndex === additionalVideos.length - 1) {
        setTextButton(t('VideoModal.GotoVideos'));
      }

      if (additionalVideos && nextIndex === additionalVideos.length) {
        if (primary) {
          setOriginPath(`/employer-dashboard/positions/${positionId}`);
          navigate(`/employer/video/${candidateId}`, { replace: true });
        } else {
          setOriginPath(`/simera-dashboard/positions/${positionId}`);
          navigate(`/internal/video/${candidateId}`, { replace: true });
        }

        return currentIndex;
      }
      return nextIndex;
    });
    setControlsEnabled(false);

    const video = videoRef.current;

    if (video) {
      video.load();
    }
  };

  const handlePlayVideo = () => {
    setControlsEnabled(true);
    const video = videoRef.current;

    if (video) {
      video.play();
    }
  };

  useEffect(() => {
    if (additionalVideos && additionalVideos.length > 1) {
      setTextButton(t('VideoModal.NextVideo'));
    }
  }, [additionalVideos, t]);

  return additionalVideos ? (
    <div
      className={
        classes.Container && (primary ? classes.Employer : classes.Internal)
      }
    >
      <div className={classes.Wrapper}>
        <CandidateVideosModalHeader
          primary={primary}
          handleClose={handleClose}
          candidate={candidate}
          videoId={additionalVideos[currentWatchingVideoIndex].Id}
        />
        <div className={classes.divider} />
        <div className={classes.Question}>
          {additionalVideos[currentWatchingVideoIndex].Name}
        </div>
        <div className={classes.VideoContainer}>
          {errorLoading ? (
            <VideoErrorScreen />
          ) : (
            <VideoGenericElement
              videoRef={videoRef}
              controls={controlsEnabled}
              className={classes.video}
              autoPlay={false}
              onError={() => {
                setErrorLoading(true);
                if (videoRef.current) {
                  videoRef.current.style.visibility = 'hidden';
                }
              }}
              onLoadedData={() => {
                if (videoRef.current) {
                  videoRef.current.style.visibility = 'visible';
                }
                setErrorLoading(false);
              }}
              videoData={additionalVideos[currentWatchingVideoIndex]}
            />
          )}
          {!controlsEnabled ? (
            <PlayIconBig
              fill="#ffffff"
              width="3rem"
              height="3rem"
              className={classes.playIconContainer}
              onClick={handlePlayVideo}
            />
          ) : undefined}
        </div>
      </div>
      <div className={classes.ButtonsContainer}>
        <Button
          text={t('VideoModal.GoBack')}
          disabled={currentWatchingVideoIndex === 0}
          showText
          size="auto"
          styling
          customStyles={{
            color: primary ? 'var(--brand-blue-100)' : 'var(--primary-green)',
            fontWeight: '600',
            fontSize: '16px',
            flexGrow: '0',
            padding: '16px',
          }}
          onClick={handlePreviousVideo}
        />
        <Button
          text={textButton}
          disabled={additionalVideos?.length === 0}
          customStyles={{
            color: 'var(--white)',
            fontWeight: '600',
            fontSize: '16px',
            flexGrow: '0',
            padding: '16px',
          }}
          bgColor={primary ? 'var(--brand-blue-100)' : 'var(--primary-green)'}
          showText
          size="auto"
          height="36px"
          onClick={handleNextVideo}
        />
      </div>
    </div>
  ) : null;
};
CandidateVideosModalContent.defaultProps = {
  primary: false,
};

export default CandidateVideosModalContent;
