import { errorHandler } from '../../../utils';
import {
  FormInfoBuilder,
  GetSkillsInput,
  GetSkillsOutput,
} from '../form-info.types';

export const getSkillsQuery = (builder: FormInfoBuilder) =>
  builder.query<GetSkillsOutput, GetSkillsInput>({
    query: () => ({
      url: '/form-info/skills',
      method: 'GET',
    }),
    keepUnusedDataFor: 240,
    transformErrorResponse: (err, meta, args) => {
      const { t } = args;
      errorHandler(
        '[fetchSkillsAction]',
        t('errorMessages.getInfoFail'),
        err,
        true
      );
      return err.data;
    },
  });
