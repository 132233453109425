import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from '../../ProfileMenu/ProfileMenu.module.css';

interface Props {
  link: React.RefObject<HTMLDivElement>;
  highlightedSectionIndex: number;
  setHighlightedSection: React.Dispatch<React.SetStateAction<number>>;
  secondary?: boolean;
}

const ProfileMenu: FC<Props> = ({
  link,
  highlightedSectionIndex,
  setHighlightedSection,
  secondary,
}) => {
  const [t] = useTranslation('global');

  const goToSection = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    n: number
  ) => {
    setHighlightedSection((prev) => (prev !== n ? n : prev));
    e.preventDefault();

    if (typeof window !== 'undefined') {
      const element = document.querySelector(`#section-${n}`);
      if (n === 1) {
        window.scrollTo({ top: 0 });
        element!.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (element) {
        element.scrollIntoView(true);
      }
    }
  };

  const menuOptions = [
    { id: 1, title: t('workHistory.menuTitle') },
    { id: 2, title: t('jobs.title') },
    { id: 3, title: t('skills.skills') },
    { id: 4, title: t('education.title') },
    { id: 5, title: t('languages.langs') },
    { id: 6, title: t('extracurricular.menuTitle') },
    { id: 7, title: t('technical.title') },
  ];

  const menuOption = !secondary
    ? classes.menuOption
    : classes.menuOptionSecondary;

  const selectedMenuOption = !secondary
    ? classes.selectedMenuOption
    : classes.selectedMenuOptionSecondary;

  return (
    <div
      id="menu"
      className={classes.MenuContainer}
      data-testid="profile-menu-container"
      ref={link}
    >
      {menuOptions.map((option) => (
        <div
          aria-hidden="true"
          onClick={(e) => {
            goToSection(e, option.id);
          }}
          key={option.id}
          className={
            option.id === highlightedSectionIndex
              ? `${menuOption} ${selectedMenuOption}`
              : menuOption
          }
        >
          {option.title}
        </div>
      ))}
    </div>
  );
};

ProfileMenu.defaultProps = {
  secondary: false,
};
export default ProfileMenu;
