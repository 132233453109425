import {
  CompanyBuilder,
  TrackingHiredCandidatesInput,
  TrackingHiredCandidatesOutput,
} from '../companies.types';
import { errorHandler, successHandler } from '../../../utils';
import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';

export const trackingHiredCandidates = (builder: CompanyBuilder) =>
  builder.mutation<TrackingHiredCandidatesOutput, TrackingHiredCandidatesInput>(
    {
      query: ({ candidatePositionStatusId, trackingCandidateData }) => ({
        url: `/candidate-position-status/trackingCandidates/${candidatePositionStatusId}`,
        method: 'PATCH',
        body: trackingCandidateData,
      }),
      transformResponse: (
        queryResponse: TrackingHiredCandidatesOutput,
        _,
        arg
      ) => {
        successHandler(
          arg.lang === 'es'
            ? SuccessMessageEn.UpdateEmployer
            : SuccessMessageEs.UpdateEmployer,
          true
        );
        return queryResponse;
      },
      transformErrorResponse: (error, meta, args) => {
        errorHandler(
          '[trackingHiredCandidates]',
          args.lang === 'es'
            ? ErrorMessageEs.trackingHiredInfoError
            : ErrorMessageEn.trackingHiredInfoError,
          error,
          true
        );
        return error.data;
      },
      invalidatesTags: () => [{ type: 'Company' }],
    }
  );
