import { FC, KeyboardEventHandler, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './EditableContainer.module.css';
import { ReactComponent as Pencil } from '../../icons/Pencil.svg';
import { ReactComponent as PlusIcon } from '../../icons/PlusIcon.svg';
import CardSkeleton from '../Positions/CandidateSummaryCardList/CardSkeleton/CardSkeleton';

interface Props {
  title: string;
  children: React.ReactNode;
  isEditable: boolean;
  newEdit?: boolean;
  onClickPlus?:
    | MouseEventHandler<HTMLDivElement>
    | KeyboardEventHandler<HTMLDivElement>;
  onClickPencil: () => void;
  hasAddMore?: boolean;
  addMore?: boolean | string | null | object;
  isActive?: boolean;
  isLoading?: boolean;
}

const EditableContainerInternal: FC<Props> = ({
  title,
  children,
  isEditable,
  onClickPencil,
  onClickPlus,
  hasAddMore,
  newEdit,
  addMore,
  isActive,
  isLoading,
}) => {
  const [t] = useTranslation('global');
  return (
    <section
      className={`${classes.editableContainer} ${
        isActive ? classes.activeSection : ''
      }`}
      data-testid="editable-container"
    >
      <div className={classes.editableContainerHeader}>
        {isEditable ? (
          <p className={classes.editableContainerTitle}>
            {addMore ? `${t('common.new')} ${addMore}` : title}
          </p>
        ) : (
          <p className={classes.editableContainerTitle}>{`${title}`}</p>
        )}

        {isEditable && (
          <div
            data-testid="editable-actions"
            className={classes.editableContainerActions}
          >
            {hasAddMore && !newEdit && (
              <div
                aria-label="Add more"
                className={classes.editableContainerActionItem}
                onClick={onClickPlus as MouseEventHandler}
                onKeyDown={onClickPlus as KeyboardEventHandler}
                role="button"
                tabIndex={0}
              >
                <PlusIcon />
              </div>
            )}
            <div
              aria-label="Edit"
              className={classes.editableContainerActionItem}
              onClick={onClickPencil}
              onKeyDown={onClickPencil}
              role="button"
              tabIndex={0}
              data-testid="edit-button"
            >
              <Pencil />
            </div>
          </div>
        )}
      </div>
      {addMore && <div className={classes.editableContainerDivider} />}
      <div className={classes.editableContainerContent}>
        {isLoading ? <CardSkeleton hasProfilePicture={false} /> : children}
      </div>
    </section>
  );
};

EditableContainerInternal.defaultProps = {
  onClickPlus: undefined,
  hasAddMore: false,
  newEdit: false,
  addMore: false,
  isActive: false,
  isLoading: false,
};
export default EditableContainerInternal;
