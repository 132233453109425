import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useWindowWidth from '../../hooks/useWindowWidth';
import { ReactComponent as LeftArrow } from '../../icons/LeftArrow.svg';
import { ReactComponent as SimeraLogoDark } from '../../icons/SimeraLogoDark.svg';
import { ReactComponent as SimeraLogoLight } from '../../images/logo.svg';
import LoginButton from '../AccountButton/LoginButton';
import BackButton from '../BackButton/BackButton';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import SimeraScore from '../SimeraScore/SimeraScore';
import classes from './InfoContainer.module.css';

interface InfoContainerProps {
  hideSimeraScore?: boolean;
  hideHomeButton?: boolean;
  hideInfoOnMobile?: boolean;
  secondaryColor?: boolean;
  containerStyles?: {};
  referral?: boolean;
}

const InfoContainer: FC<InfoContainerProps> = ({
  hideSimeraScore,
  hideHomeButton,
  hideInfoOnMobile,
  secondaryColor,
  containerStyles,
  children,
  referral,
}) => {
  const isMobile = useWindowWidth() < 768;
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const [t] = useTranslation('global');

  const handleMenu = () => {
    if (window.location.pathname === '/') {
      navigate('/login', { replace: true });
    } else {
      setOpenMenu(!openMenu);
    }
  };
  const onBackButton = () => navigate('/home');

  return (
    <div
      data-testid="info-container"
      className={`${classes.InfoContainer} ${
        secondaryColor ? classes.SecondaryColor : classes.PrimaryColor
      } ${
        isMobile && hideInfoOnMobile && classes.HiddenInfoContainer
      } ${containerStyles}`}
    >
      <div
        className={`${classes.HeaderContainer} ${
          isMobile && hideInfoOnMobile && classes.HiddenInfoHeader
        }`}
      >
        {isMobile && hideInfoOnMobile ? (
          <SimeraLogoDark
            data-testid="simera-logo"
            className={classes.SimeraLogo}
          />
        ) : (
          <SimeraLogoLight
            data-testid="simera-logo"
            className={classes.SimeraLogo}
          />
        )}
        {isMobile && <LoginButton t={t} action={handleMenu} />}
      </div>
      {openMenu && (
        <DropdownMenu className={classes.dropdownMenu} setModal={setOpenMenu} />
      )}
      {(!isMobile || (isMobile && !hideInfoOnMobile)) && (
        <>
          <div className={classes.MobileBackButtonContainer}>
            {isMobile && referral && (
              <button
                type="button"
                className={classes.BackButton}
                onClick={onBackButton}
              >
                <LeftArrow data-testid="left-arrow" />
                {t('referrals.ReturnToProfileBtn')}
              </button>
            )}
          </div>
          <div className={classes.Info}>
            {!isMobile && !hideHomeButton && (
              <div className={classes.BackButtonContainer}>
                <BackButton />
              </div>
            )}
            {children}
            {!hideSimeraScore && (
              <SimeraScore stepState={[{ name: '', percentage: 0 }]} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

InfoContainer.defaultProps = {
  hideSimeraScore: false,
  hideHomeButton: false,
  hideInfoOnMobile: false,
  secondaryColor: false,
  containerStyles: {},
  referral: false,
};

export default InfoContainer;
