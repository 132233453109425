import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { emailRegex } from '../../constants/regex';
import Button from '../Buttons/Button';
import BaseInput from '../Inputs/BaseInput';
import classes from './ForgotPasswordForm.module.css';
import { ReactComponent as BackArrow } from '../../icons/BackArrow.svg';
import ForgotPasswordModal from './ForgotPasswordModal';
import { SaveState } from '../../types/ForrmType';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { resetPasswordAuthAction } from '../../store/legacy/password-reset';

interface IFormTypes {
  email: string;
}

const ForgotPasswordForm = () => {
  const { emailSentStatus } = useAppSelector((state) => state.passwordReset);

  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormTypes>();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (emailSentStatus === SaveState.FULFILLED) {
      setModalIsOpen(true);
    }
    return () => {
      setModalIsOpen(false);
    };
  }, [emailSentStatus]);

  const [t, i18n] = useTranslation('global');
  const email = register('email', {
    required: true,
    pattern: emailRegex,
  });

  const onSubmit = async (values: IFormTypes) => {
    await dispatch(
      resetPasswordAuthAction({
        email: values.email.toLowerCase(),
        timezoneOffset: new Date().getTimezoneOffset(),
        lang: i18n.language,
      })
    );
  };

  return (
    <form
      data-testid="forgot-password-form"
      className={classes.ForgotPasswordForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <BaseInput
        onChange={(e) => email.onChange(e)}
        name={email.name}
        onBlur={email.onBlur}
        refProp={email.ref}
        label={t('forgotPass.email')}
        type="email"
        errorText={errors.email && t('forgotPass.error')}
      />
      <div className={classes.ButtonContainer}>
        <Button
          submit
          onClick={() => {}}
          text={t('forgotPass.btn')}
          bgColor="#05668D"
          customStyles={{
            width: 'auto',
            flexGrow: '0',
            padding: '0 1rem',
          }}
          styling={{
            boxShadow:
              ' 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)',
          }}
          disabled={emailSentStatus === SaveState.PENDING}
          showText
        />
      </div>
      <div className={classes.back}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => navigate('/login')}
          onKeyDown={() => navigate('/login')}
        >
          <span>
            <BackArrow style={{ marginRight: '10px' }} />{' '}
            {t('forgotPass.backToLogin')}
          </span>
        </div>
      </div>
      {modalIsOpen && <ForgotPasswordModal setModalIsOpen={setModalIsOpen} />}
    </form>
  );
};

export default ForgotPasswordForm;
