import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { AdditionalDocument } from '../../../../types/candidates';
import { successHandler, errorHandler } from '../../../utils';
import {
  AdditionalDocumentBuilder,
  UpdateVisibilityOutput,
  UpdatevisibilityInput,
} from '../additional-document.types';

export const updateVisibility = (builder: AdditionalDocumentBuilder) =>
  builder.mutation<UpdateVisibilityOutput, UpdatevisibilityInput>({
    query: ({ fileId, data }) => ({
      url: `/upload/file/visibility/${fileId}`,
      method: 'PATCH',
      body: data,
    }),

    transformResponse: (response: AdditionalDocument, meta, { lang }) => {
      if (lang === 'en') {
        successHandler(SuccessMessageEn.AdditionalDocumentUpdatedSuccess, true);
      } else {
        successHandler(SuccessMessageEs.AdditionalDocumentUpdatedSuccess, true);
      }
      return response;
    },
    invalidatesTags: [{ type: 'additionalDocuments', id: 'LIST' }],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[AdditionalDocumentSave]',
        lang === 'es'
          ? ErrorMessageEs.AdditionalDocumentError
          : ErrorMessageEn.AdditionalDocumentError,
        error,
        true
      );
    },
  });
