import { userLanguage } from '$translations/index';
import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import {
  AdditionalVideo,
  SortAdditionalVideosInput,
} from '../../../../types/additional-video/additional-video';
import { errorHandler, successHandler } from '../../../utils';
import {
  AdditionalVideoBuilder,
  GetAdditionalVideoByCandidateIdOutput,
} from '../additional-videos.types';

export const sortAdditionalVideos = (builder: AdditionalVideoBuilder) =>
  builder.mutation<
    GetAdditionalVideoByCandidateIdOutput,
    SortAdditionalVideosInput
  >({
    query: ({ candidateId, newAdditionalVideosOrder }) => ({
      url: `/additional-video/candidate/${candidateId}/sort`,
      method: 'PATCH',
      body: { AdditionalVideoIds: newAdditionalVideosOrder },
    }),
    // invalidatesTags: [{ type: 'additionalVideos' }], // currently is overwriting with the response instead of invalidating tag
    transformResponse: (response: AdditionalVideo[]) => {
      successHandler(
        userLanguage === 'es'
          ? SuccessMessageEs.UpdateAdditionalVideos
          : SuccessMessageEn.UpdateAdditionalVideos,
        true
      );
      return response;
    },
    transformErrorResponse: (error) => {
      errorHandler(
        'updateVisibilityAdditionalVideos',
        userLanguage === 'es'
          ? ErrorMessageEs.UpdateAdditionalVideosError
          : ErrorMessageEn.UpdateAdditionalVideosError,
        error,
        true
      );
      return error;
    },
  });
