import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ModalInTailwind from '$components/ModalInTailwind/ModalInTailwind';
import SwitchTypes from '../../../../constants/CandidatesSwitchTypes';
import Button from '../../../Buttons/Button';
import ConfirmAddToPositionModal from '../../../ConfirmAddToPositionModal/ConfirmAddToPositionModal';
import classes from './CandidateActionBtn.module.css';
import {
  candidatesApi,
  useAddCandidateToPositionAgainMutation,
  useAddCandidateToPositionMutation,
  useRemoveCandidateFromPositionMutation,
} from '../../../../store/modules/candidates';
import type { CandidateData } from '../../../../types/candidates';
import { useAppDispatch } from '../../../../hooks';
import { companiesApi } from '../../../../store/modules/companies';

const validStatus = ['new', 'offerSent', 'requestInterview', 'shortlisted'];

interface CandidateActionBtnRightProps {
  candidate: CandidateData;
  positionId: number;
  salaryUpperBound: number;
  candidateArrayIndex: number;
  candidatePositionStatus: string;
  setSwitchingId: Dispatch<SetStateAction<number | null>>;
}

interface InitialState {
  text: string;
  bgColor: string;
  customStyles: {
    padding: string;
    height: string;
    borderRadius: string;
    color?: string;
    border: string;
    flexGrow: string;
  };
  bgOtherStates: string;
  disabledText: string;
}

const CandidateActionBtnRightGraph: FC<CandidateActionBtnRightProps> = ({
  candidate,
  positionId,
  salaryUpperBound,
  candidateArrayIndex,
  candidatePositionStatus,
  setSwitchingId,
}) => {
  const [t, i18n] = useTranslation('global');
  const [addCandidateToPosition] = useAddCandidateToPositionMutation();
  const [removeCandidateFromPosition] =
    useRemoveCandidateFromPositionMutation();
  const [addCandidateToPositionAgain] =
    useAddCandidateToPositionAgainMutation();

  const dispatch = useAppDispatch();
  const initialState: InitialState = useMemo(
    () => ({
      text: '',
      bgColor: 'var(--primary-green)',
      customStyles: {
        padding: '0.75rem 1.5rem',
        height: 'auto',
        borderRadius: '0.25rem',
        flexGrow: '0',
        border: 'var(--white) 1px solid',
        fontWeight: 600,
        fontSize: '0.875rem',
      },
      bgOtherStates: `${classes.ButtonDefault}`,
      disabledText: t('CandidatePositionSummaryCard.AddingCandidate'),
    }),
    [t]
  );

  const [saving, setSaving] = useState<boolean>(false);
  const [buttonStateStyles, setButtonStateStyles] =
    useState<InitialState>(initialState);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const showReject = validStatus.includes(
      (candidatePositionStatus ?? '') as string
    );
    if (candidatePositionStatus === 'find') {
      initialState.text = t('CandidatePositionSummaryCard.AddToPosition');
    }
    if (showReject) {
      initialState.text = t('CandidatePositionSummaryCard.RemoveCandidate');
      initialState.bgColor = '#fff';
      initialState.customStyles = {
        ...initialState.customStyles,
        color: 'var(--internal-alert)',
        border: 'var(--internal-alert) 1px solid',
      };
      initialState.bgOtherStates = `${classes.ButtonRemove}`;
      initialState.disabledText = t(
        'CandidatePositionSummaryCard.RemovingCandidate'
      );
    }
    if (candidatePositionStatus === SwitchTypes.INACTIVE) {
      initialState.text = t('CandidatePositionSummaryCard.AddAgain');
    }
    setButtonStateStyles(initialState);
    setSaving(false);
  }, [
    candidate.CandidatesPositionStatus,
    candidatePositionStatus,
    initialState,
    t,
  ]); // watching for changes in the candidate prop seems to work, but not sure why

  const handleAddToPosition = () => {
    setModalIsOpen(true);
  };

  const confirmAddToPosition = () => {
    setSaving(true);
    setModalIsOpen(false);
    addCandidateToPosition({
      data: {
        candidateId: candidate.Id,
        positionId,
        candidateArrayIndex,
      },
      lang: i18n.language as 'en' | 'es',
    }).then(() => {
      dispatch(companiesApi.util.invalidateTags(['PositionsList']));
      dispatch(candidatesApi.util.invalidateTags(['Candidates']));
    });
    setTimeout(() => {
      setSwitchingId(candidate.Id);
    }, 1000);
    setSaving(false);
  };

  const handleRemoveFromPosition = async (candidateId: number) => {
    setSaving(true);

    removeCandidateFromPosition({
      data: {
        candidateId,
        positionId,
      },
      lang: i18n.language as 'es' | 'en',
    }).then(() => {
      dispatch(companiesApi.util.invalidateTags(['PositionsList']));
    });
    setTimeout(() => {
      setSwitchingId(candidate.Id);
    }, 1000);
    setSaving(false);
  };

  const handleAddAgainToPosition = async (candidateId: number) => {
    setSaving(true);

    addCandidateToPositionAgain({
      data: {
        candidateId,
        positionId,
      },
      lang: i18n.language as 'es' | 'en',
    }).then(() => {
      dispatch(companiesApi.util.invalidateTags(['PositionsList']));
    });
    setTimeout(() => {
      setSwitchingId(candidate.Id);
    }, 1000);
    setSaving(false);
  };

  const handleActions = () => {
    const map: any = {
      find: () => handleAddToPosition(),
      new: () => handleRemoveFromPosition(candidate.Id),
      declined: () => handleAddAgainToPosition(candidate.Id),
      hired: () => {},
      offerSent: () => handleRemoveFromPosition(candidate.Id),
      requestInterview: () => handleRemoveFromPosition(candidate.Id),
      shortlisted: () => handleRemoveFromPosition(candidate.Id),
    };

    map[candidatePositionStatus]();
  };

  return (
    <>
      <Button
        size="auto"
        showText
        text={saving ? buttonStateStyles.disabledText : buttonStateStyles.text}
        styling={buttonStateStyles.bgOtherStates}
        bgColor={buttonStateStyles.bgColor}
        customStyles={buttonStateStyles.customStyles}
        disabled={saving}
        onClick={() => handleActions()}
      />

      <ModalInTailwind
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
      >
        <ConfirmAddToPositionModal
          setIsOpen={setModalIsOpen}
          handleConfirm={confirmAddToPosition}
          salaryExceeds={
            (candidate &&
              candidate.CandidateJobInterest &&
              candidate.CandidateJobInterest?.SalaryUpperBound >
                salaryUpperBound) ||
            false
          }
        />
      </ModalInTailwind>
    </>
  );
};

export default CandidateActionBtnRightGraph;
