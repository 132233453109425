import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CandidateExtracurricularBuilder,
  DeleteCandidateExtracurricularInput,
} from '../candidate-extracurricular.types';

export const deleteCandidateExtracurricular = (
  builder: CandidateExtracurricularBuilder
) =>
  builder.mutation<void, DeleteCandidateExtracurricularInput>({
    query: ({ id }) => ({
      url: `/candidate-extracurricular/delete/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: [
      { type: 'candidateExtracurriculars', id: 'LIST' },
      'Score',
    ],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setExtracurricularsAction]',
        lang === 'es' ? ErrorMessageEs.DeleteError : ErrorMessageEn.DeleteError,
        error,
        true
      );
    },
    transformResponse(_data, meta, { lang }) {
      successHandler(
        lang === 'es'
          ? SuccessMessageEs.SuccessfullyDeleted
          : SuccessMessageEn.SuccessfullyDeleted,
        true
      );
    },
  });
