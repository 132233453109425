import { FC, memo } from 'react';
import classes from './ChipList.module.css';
import { AutocompleteOption } from '../../types/user_info';
import AddIcon from './AddIcon';

interface ChipListProps {
  defaultTags: AutocompleteOption[];
  onClickAction: (option: AutocompleteOption) => void;
  selectedTags: AutocompleteOption[];
  secondary?: boolean;
}

const ChipList: FC<ChipListProps> = ({
  defaultTags,
  onClickAction,
  selectedTags,
  secondary,
}) => {
  const chipClass = secondary ? classes.chipSecondary : classes.chip;
  const selectedClass = secondary
    ? classes.selectedSecondary
    : classes.selected;
  const fill = secondary ? '#079E88 ' : '#05668D';
  const textClass = secondary ? classes.textSecondary : classes.text;
  return (
    <div data-testid="container" className={classes.container}>
      {defaultTags.map((chip) => {
        const isSelected = selectedTags.find((o) => o.Id === chip.Id);
        return (
          <div
            data-testid="chip"
            key={chip.Id}
            className={isSelected ? `${chipClass} ${selectedClass}` : chipClass}
            aria-hidden="true"
            onClick={() => onClickAction(chip)}
          >
            <p
              className={
                isSelected ? `${textClass} ${classes.textSelected}` : textClass
              }
            >
              {chip.title}
            </p>
            <AddIcon
              data-testid="icon"
              color={isSelected ? 'white' : fill}
              selected={!!isSelected}
            />
          </div>
        );
      })}
    </div>
  );
};

ChipList.defaultProps = {
  secondary: false,
};

export default memo(ChipList);
