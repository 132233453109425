import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  UpdateVideoInput,
  UpdateVideoOutput,
  UploadBuilder,
} from '../uploads.types';

export const updateVideo = (builder: UploadBuilder) =>
  builder.mutation<UpdateVideoOutput, UpdateVideoInput>({
    query: ({ data }) => ({
      url: 'videos/update',
      method: 'PATCH',
      body: data,
    }),
    invalidatesTags: [{ type: 'Upload', id: 'List' }],
    transformResponse: (response: UpdateVideoOutput, meta, { lang }) => {
      successHandler(
        lang === 'es'
          ? SuccessMessageEs.UpdateAdditionalVideo
          : SuccessMessageEn.UpdateAdditionalVideo,
        true
      );
      return response;
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        'updateVideo',
        lang === 'es'
          ? ErrorMessageEs.UpdateAdditionalVideoError
          : ErrorMessageEn.UpdateAdditionalVideoError,
        error,
        true
      );
      return error;
    },
  });
