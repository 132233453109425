// WARN: THIS COMPONENT IS DISPLAYED FOR GUESTS MODE

import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import CandidateSummaryCardEmployers from '../CandidateSummaryCardList/CandidateSummaryCard/CandidateSummaryCardEmployers/CandidateSummaryCardEmployers';
import classes from './CandidatesPositionPage.module.css';
import ActiveValues from '../../../constants/ActiveValues';
import EmptyState from '../EmptyState/EmptyState';
import { CandidateData } from '../../../types/candidates';

interface Props {
  activeValue: ActiveValues;
  candidates: CandidateData[];
  setSwitchingId: Dispatch<SetStateAction<number | null>>;
  existingOffer: boolean;
  isGuest?: boolean;
  position: any;
}
const CandidatesList: FC<Props> = ({
  activeValue,
  candidates,
  setSwitchingId,
  existingOffer,
  isGuest,
  position,
}) => {
  const [t] = useTranslation('global');

  const dictionary: {
    [key: string]: { title: string; description: string };
  } = {
    all: {
      title: t('EmployerPositions.NoCandidatesAsigned'),
      description: t('EmployerPositions.NoCandidatesSubtext'),
    },
    new: {
      title: t('EmployerPositions.NoCandidatesAsigned'),
      description: t('EmployerPositions.NoCandidatesSubtext'),
    },
    shortlisted: {
      title: t('EmployerPositions.NoShortListText'),
      description: t('EmployerPositions.NoShortListDescription'),
    },
    offerSent: {
      title: t('EmployerPositions.NoOfferSentText'),
      description: t('EmployerPositions.NoOfferSentDescription'),
    },
    requestInterview: {
      title: t('EmployerPositions.NoInterviewRequestedText'),
      description: t('EmployerPositions.NoInterviewRequestedDescription'),
    },
  };
  if (candidates?.length === 0) {
    return (
      <EmptyState
        text={dictionary[activeValue].title}
        description={dictionary[activeValue].description}
        height="100%"
      />
    );
  }
  return (
    <div className={classes.CardsContainer}>
      {candidates?.map((item, candidatesPositionStatusArrayIndex) => (
        <CandidateSummaryCardEmployers
          key={`PositionCandidate-${item.Id}`}
          candidate={item}
          status={item.CandidatesPositionStatus?.[0]?.Status}
          existingOffer={existingOffer}
          statusId={item.Id}
          createTime={item.CreateTime}
          activeValue={activeValue}
          candidatesPositionStatusArrayIndex={
            candidatesPositionStatusArrayIndex
          }
          setSwitchingId={setSwitchingId}
          position={position}
          isGuest={isGuest}
        />
      ))}
    </div>
  );
};

CandidatesList.defaultProps = {
  isGuest: false,
};

export default CandidatesList;
