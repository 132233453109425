import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './CandidateScore.module.css';
import getRangeOfColor from '../../../../utils/rangeofColors';

interface Props {
  score?: number;
}

const CandidateScore: FC<Props> = ({ score }) => {
  const [t] = useTranslation('global');
  return (
    <div
      className={classes.Score}
      style={{
        backgroundColor: getRangeOfColor(score!),
      }}
    >
      {`${t('employersModal.score')}: ${score}`}
    </div>
  );
};

CandidateScore.defaultProps = {
  score: 0,
};

export default CandidateScore;
