import { useTranslation } from 'react-i18next';
import { ReactComponent as ThumbInactive } from '$icons/thumb_inactive.svg';
import { ReactComponent as ThumbActive } from '$icons/thumb_active.svg';
import {
  useDeleteReactionMutation,
  useGetSingleCandidateReactoionsQuery,
  useSendReactionMutation,
} from '$store/modules/candidate-reactions';
import { Reactions } from '$constants/ReactionTypes';
import { segmentTrack } from '$utils/handleSegment';
import ActiveValues from '$constants/ActiveValues';
import classes from './ReactionBox.module.css';

const ReactionBox = ({
  candidateId,
  positionId,
  status,
  activeValue,
  setModalShortlist,
  setModalRejectCandidate,
}: {
  candidateId: number;
  positionId: number;
  status: string | undefined;
  activeValue: ActiveValues;
  setModalShortlist: (value: boolean) => void;
  setModalRejectCandidate: (value: boolean) => void;
}) => {
  const [, i18n] = useTranslation('global');

  const { data: singleCandidateReaction } =
    useGetSingleCandidateReactoionsQuery({
      candidateId,
      lang: i18n.language,
    });
  const [sendReaction] = useSendReactionMutation();
  const [deleteReaction] = useDeleteReactionMutation();

  const handleLike = () => {
    sendReaction({
      candidateId,
      reactionType: Reactions.Like,
      lang: i18n.language,
    }).then(() => {
      segmentTrack('Liked Candidate', {
        candidate_id: candidateId,
        position_id: positionId,
      });
    });
    if (status === 'new' && activeValue === ActiveValues.New) {
      setModalShortlist(true);
    }
  };

  const handleDislike = () => {
    sendReaction({
      candidateId,
      reactionType: Reactions.Dislike,
      lang: i18n.language,
    }).then(() => {
      segmentTrack('Unliked Candidate', {
        candidate_id: candidateId,
        position_id: positionId,
      });
    });
    setModalRejectCandidate(true);
  };

  const handleResetReaction = () => {
    deleteReaction({ candidateId, lang: i18n.language });
  };

  return (
    <div className={classes.Qualification}>
      <p className={classes.QualificationText} data-testid="like">
        {singleCandidateReaction?.CurrentUserReaction === Reactions.Like ? (
          <ThumbActive
            className={`${classes.ReactionIcon} ${classes.IconLike}`}
            onClick={handleResetReaction}
            data-testid="thumb_icon"
          />
        ) : (
          <ThumbInactive
            className={`${classes.ReactionIcon} ${classes.IconLike}`}
            onClick={handleLike}
            data-testid="thumb_icon"
          />
        )}
        {singleCandidateReaction?.Likes || 0}
      </p>
      <p className={classes.QualificationText} data-testid="dislike">
        {singleCandidateReaction?.CurrentUserReaction === Reactions.Dislike ? (
          <ThumbActive
            className={`${classes.ReactionIcon} ${classes.IconDislike}`}
            onClick={handleResetReaction}
            data-testid="thumb_icon"
          />
        ) : (
          <ThumbInactive
            className={`${classes.ReactionIcon} ${classes.IconDislike}`}
            onClick={handleDislike}
            data-testid="thumb_icon"
          />
        )}
        {singleCandidateReaction?.Dislikes || 0}
      </p>
    </div>
  );
};

export default ReactionBox;
