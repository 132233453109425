import { useCallback, FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { WORKING_HOURS } from '$constants/datetimes';
import { InternalTimeOption } from './InternalTimeOption';

export type TimePickerProps = {
  setSelectedSchedule: React.Dispatch<
    React.SetStateAction<SetSelectedScheduleType | undefined>
  >;
  selectedSchedule: SetSelectedScheduleType | undefined;
  day: Dayjs;
  zone: 'eastern' | 'central' | 'pacific' | 'mountain';
  timezone: string;
  takenSlots: any[];
};

type SetSelectedScheduleType = {
  date: Dayjs;
  timezone: string;
};

const InternalTimePicker: FC<TimePickerProps> = ({
  setSelectedSchedule,
  selectedSchedule,
  day,
  zone,
  timezone,
  takenSlots,
}) => {
  const setSchedule = useCallback(
    (hour: Dayjs) => {
      const parseDay = {
        date: day.format('D'),
        month: day.format('M'),
        year: day.format('YYYY'),
      };
      const schedule = dayjs()
        .set('date', +parseDay.date)
        .set('month', +parseDay.month - 1)
        .set('year', +parseDay.year)
        .set('hour', +hour.format('H'))
        .set('minute', 0)
        .set('second', 0)
        .tz(timezone, true);

      setSelectedSchedule(() => ({
        date: schedule,
        timezone,
      }));
    },
    [setSelectedSchedule, day, timezone]
  );

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        {WORKING_HOURS[zone].map((el: any) => {
          const isAlreadyTaken = takenSlots.some((item) => {
            const tempSlot = dayjs.tz(item.StartDate, item.Timezone);
            const slotHour = tempSlot.utc().hour();
            const timeHour = el.utc().hour();
            return tempSlot.isSame(day, 'day') && slotHour === timeHour;
          });

          return (
            <InternalTimeOption
              key={`${el.format()}`}
              time={el}
              setSchedule={setSchedule}
              isSelected={
                !!selectedSchedule &&
                selectedSchedule.date.format('D') === day.format('D') &&
                selectedSchedule.date.format('H') === el.format('H')
              }
              disable={isAlreadyTaken}
            />
          );
        })}
      </div>
    </div>
  );
};

export { InternalTimePicker };
