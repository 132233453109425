import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LinkedInIcon } from '../../../../icons/LinkedInSmall.svg';
import { ReactComponent as BehanceIcon } from '../../../../icons/BehanceIcon.svg';
import { ReactComponent as WhatsappIcon } from '../../../../icons/Whatsapp.svg';
import { ReactComponent as GithubIcon } from '../../../../icons/GithubIcon.svg';
import { ReactComponent as Website } from '../../../../icons/WebsiteIcon.svg';
import SocialMediaLink from '../../../CandidateInformation/SocialMediaLink';

import classes from './CandidatesSocialmedia.module.css';
import addHttpFormat from '../../../../utils/addHttpFormat';

interface Props {
  linkedIn: string | null;
  custom: string | null;
  github: string | null;
  behance: string | null;
  whatsappNumber: string | null;
  secondary: boolean;
}

const CandidateSocialMedia: FC<Props> = ({
  linkedIn,
  custom,
  github,
  behance,
  whatsappNumber,
  secondary,
}) => {
  const [t] = useTranslation('global');
  const socialMedias = [
    {
      icon: LinkedInIcon,
      label: 'LinkedIn',
      link: linkedIn,
      isDisplayed: linkedIn !== '',
    },
    {
      icon: Website,
      label: t('profileCardCTA.Website'),
      link: custom,
      isDisplayed: custom !== '',
    },
    {
      icon: GithubIcon,
      label: 'Github',
      link: github,
      isDisplayed: github !== '',
    },
    {
      icon: BehanceIcon,
      label: 'Behance',
      link: behance,
      isDisplayed: behance !== '',
    },
    {
      icon: WhatsappIcon,
      label: 'Whatsapp',
      link: `api.whatsapp.com/send?phone=${whatsappNumber?.replace('+', '')}`,
      isDisplayed: whatsappNumber !== '' && secondary,
    },
  ];

  const hasSocialMedia = socialMedias.some(
    (socialMedia) => socialMedia.isDisplayed
  );

  return (
    <div className={classes.container} data-testid="candidate-social-media">
      <p className={classes.title}>{t('profileCardCTA.OnWeb')}</p>
      {hasSocialMedia ? (
        socialMedias
          .filter((social) => social.isDisplayed)
          .map((socialMedia) => (
            <SocialMediaLink
              key={`candidate-social-${socialMedia.label}`}
              label={socialMedia.label}
              icon={socialMedia.icon}
              onClick={() => window.open(addHttpFormat(socialMedia.link!))}
              secondary={secondary}
            />
          ))
      ) : (
        <p className={classes.subTitle}>{t('profileCardCTA.noSocialMedia')}</p>
      )}
    </div>
  );
};

export default CandidateSocialMedia;
