import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  SavePositionInput,
  SavePositionOutput,
} from '../companies.types';

export const savePosition = (builder: CompanyBuilder) =>
  builder.mutation<SavePositionOutput, SavePositionInput>({
    query: ({ position }) => ({
      url: '/positions',
      method: 'POST',
      body: position,
    }),
    transformResponse: (response: SavePositionOutput, meta, { lang }) => {
      successHandler(
        lang === 'en'
          ? SuccessMessageEn.SavePosition
          : SuccessMessageEs.SavePosition,
        true
      );
      return response;
    },
    transformErrorResponse: (error, meta, arg) => {
      errorHandler(
        '[savePosition]',
        arg.lang === 'en'
          ? ErrorMessageEn.PositionSaveError
          : ErrorMessageEs.PositionSaveError,
        error,
        true
      );
      return error.data;
    },
    invalidatesTags: () => [{ type: 'Company' }],
  });
