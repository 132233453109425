import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';
import ProfileScore from '../ProfileScore/ProfileScore';
import classes from './CandidateInformationCard.module.css';
import { ReactComponent as Edit } from '../../icons/EditDark.svg';
import { ReactComponent as LinkedInIcon } from '../../icons/LinkedInSmall.svg';
import { ReactComponent as BehanceIcon } from '../../icons/BehanceIcon.svg';
import { ReactComponent as GithubIcon } from '../../icons/GithubIcon.svg';
import { ReactComponent as Website } from '../../icons/WebsiteIcon.svg';
import useWindowWidth from '../../hooks/useWindowWidth';
import SocialMediaLink from './SocialMediaLink';
import addHttpFormat from '../../utils/addHttpFormat';
import { CalculateStepState } from '../../utils/CalculateScore';
import { useGetProfilePictureQuery } from '../../store/modules/uploads';
import { useAppSelector } from '../../hooks';
import { useCandidateInfo } from '../../hooks/use-candidate-info.hook';
import VideoSection from '../CandidateProfile/VideoSection';

interface Props {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPortfolioModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CandidateInformationCard: FC<Props> = ({
  setShowModal,
  setShowPortfolioModal,
}) => {
  const isMobile = useWindowWidth() < 834;
  const { serverUser } = useAppSelector((state) => state.auth);
  const [t] = useTranslation('global');
  const { data: profilePicture } = useGetProfilePictureQuery({});
  const navigate = useNavigate();

  const navigateToVideoRecording = () => {
    navigate('/interview');
  };

  const info = useCandidateInfo(serverUser?.Candidate?.Id);

  const stepState = CalculateStepState(info, t);

  return isMobile ? (
    <>
      <div
        className={`p-4 justify-center align-middle items-center gap-2 ${classes.Header}`}
        data-testid="candidate-information-card"
      >
        <div className="w-10 md:w-40">
          <DefaultIcon2
            picture={profilePicture?.Url}
            firstName={serverUser?.FirstName}
            lastName={serverUser?.LastName}
          />
        </div>
        <h4 className="text-md font-semibold">
          {serverUser &&
            `${serverUser.FirstName.split(' ')[0]} ${
              serverUser.LastName.split(' ')[0]
            }`}
        </h4>
        <Edit
          fill="#5A5B5E"
          className={classes.Icon}
          onClick={() => setShowModal(true)}
        />
      </div>
      <div className={classes.Body}>
        <div style={{ width: '100%', padding: '1rem', marginTop: '10px' }}>
          <button
            tabIndex={0}
            type="button"
            className={`w-full ${classes.webLinkAdd}`}
            onClick={() => setShowPortfolioModal(true)}
          >
            {t('profileCardCTA.PortfolioAndWebsites')}
          </button>
          {(serverUser?.Candidate?.CustomUrl ||
            serverUser?.Candidate?.GithubUrl ||
            serverUser?.Candidate?.BehanceUrl ||
            serverUser?.Candidate?.LinkedinUrl) && (
            <p className={classes.webTitle}>{t('profileCardCTA.OnWeb')}</p>
          )}
          {serverUser?.Candidate?.CustomUrl && (
            <SocialMediaLink
              label={t('profileCardCTA.Website')}
              icon={Website}
              onClick={() =>
                window.open(addHttpFormat(serverUser?.Candidate?.CustomUrl!))
              }
            />
          )}
          {serverUser?.Candidate?.LinkedinUrl && (
            <SocialMediaLink
              label="LinkedIn"
              icon={LinkedInIcon}
              onClick={() =>
                window.open(addHttpFormat(serverUser?.Candidate?.LinkedinUrl!))
              }
            />
          )}
          {serverUser?.Candidate?.BehanceUrl && (
            <SocialMediaLink
              label="Behance"
              icon={BehanceIcon}
              onClick={() =>
                window.open(addHttpFormat(serverUser?.Candidate?.BehanceUrl!))
              }
            />
          )}
          {serverUser?.Candidate?.GithubUrl && (
            <SocialMediaLink
              label="Github"
              icon={GithubIcon}
              onClick={() =>
                window.open(addHttpFormat(serverUser?.Candidate?.GithubUrl!))
              }
            />
          )}
          <VideoSection onClick={navigateToVideoRecording} />
        </div>
        <ProfileScore stepState={stepState} />
      </div>
    </>
  ) : (
    <div className={classes.CandidateInfoContainer}>
      <div className={classes.Header}>
        <div className={classes.titleEdit}>
          {!isMobile && (
            <h2
              data-testid="candidate-profile-screen-title"
              className={classes.title}
            >
              {t('profile.title')}
            </h2>
          )}
          <Edit
            fill="#5A5B5E"
            className={classes.Icon}
            onClick={() => setShowModal(true)}
          />
        </div>
        <div className="w-44">
          <DefaultIcon2
            firstName={serverUser?.FirstName}
            lastName={serverUser?.LastName}
            picture={profilePicture?.Url}
            addPadding
            fontSize="text-5xl"
          />
        </div>
        <h4 className={classes.Title}>
          {serverUser &&
            `${serverUser.FirstName.split(' ')[0]} ${
              serverUser.LastName.split(' ')[0]
            }`}
        </h4>
        <div className="w-full">
          <button
            tabIndex={0}
            type="button"
            className={`w-full ${classes.webLinkAdd}`}
            onClick={() => setShowPortfolioModal(true)}
          >
            {t('profileCardCTA.PortfolioAndWebsites')}
          </button>
          {(serverUser?.Candidate?.CustomUrl ||
            serverUser?.Candidate?.GithubUrl ||
            serverUser?.Candidate?.BehanceUrl ||
            serverUser?.Candidate?.LinkedinUrl) && (
            <p className={classes.webTitle}>{t('profileCardCTA.OnWeb')}</p>
          )}

          {serverUser?.Candidate?.CustomUrl && (
            <SocialMediaLink
              label={t('profileCardCTA.Website')}
              icon={Website}
              onClick={() =>
                window.open(addHttpFormat(serverUser?.Candidate?.CustomUrl!))
              }
            />
          )}
          {serverUser?.Candidate?.LinkedinUrl && (
            <SocialMediaLink
              label="LinkedIn"
              icon={LinkedInIcon}
              onClick={() =>
                window.open(addHttpFormat(serverUser?.Candidate?.LinkedinUrl!))
              }
            />
          )}
          {serverUser?.Candidate?.BehanceUrl && (
            <SocialMediaLink
              label="Behance"
              icon={BehanceIcon}
              onClick={() =>
                window.open(addHttpFormat(serverUser?.Candidate?.BehanceUrl!))
              }
            />
          )}
          {serverUser?.Candidate?.GithubUrl && (
            <SocialMediaLink
              label="Github"
              icon={GithubIcon}
              onClick={() =>
                window.open(addHttpFormat(serverUser?.Candidate?.GithubUrl!))
              }
            />
          )}
        </div>
      </div>
      <VideoSection onClick={navigateToVideoRecording} />
      <div className={classes.Body}>
        <ProfileScore stepState={stepState} />
      </div>
    </div>
  );
};

export default CandidateInformationCard;
