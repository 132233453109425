import { useCallback, FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { InterviewSlotStatusTypes } from '$store/modules/interview';
import classes from './TimePicker.module.css';
import { TimeOption } from './TimeOption';
import { WORKING_HOURS } from '../../../../../constants/datetimes';
import { SetSchedulesType } from '../../CalendarModalTypes';

export type TimePickerProps = {
  setSchedules: React.Dispatch<React.SetStateAction<SetSchedulesType[]>>;
  schedules: SetSchedulesType[];
  day: Dayjs;
  zone: 'eastern' | 'central' | 'pacific' | 'mountain';
  timezone: string;
};

export const TimePicker: FC<TimePickerProps> = ({
  setSchedules,
  schedules,
  day,
  zone,
  timezone,
}) => {
  const setSchedule = useCallback(
    (hour: Dayjs) => {
      const parseDay = {
        date: day.format('D'),
        month: day.format('M'),
        year: day.format('YYYY'),
      };
      const schedule = dayjs()
        .set('date', +parseDay.date)
        .set('month', +parseDay.month - 1)
        .set('year', +parseDay.year)
        .set('hour', +hour.format('H'))
        .set('minute', 0)
        .set('second', 0)
        .tz(timezone, true);

      setSchedules((prev) => [
        ...prev,
        {
          date: schedule,
          status: InterviewSlotStatusTypes.Draft,
          id: undefined,
        },
      ]);
    },
    [setSchedules, day, timezone]
  );

  return (
    <div className={classes.container}>
      <h2 className={classes.timeTitle}>{day.format('ddd, MMM D')}</h2>
      <div className={classes.gridContainer}>
        {WORKING_HOURS[zone].map((el: any) => {
          // isSame day but hour is before current hour
          const isSameDay =
            day.isSame(dayjs(), 'day') &&
            el.isBefore(dayjs().add(4, 'hour'), 'hour');
          return (
            <TimeOption
              key={`${el.format()}`}
              time={el}
              setSchedule={setSchedule}
              isSelected={schedules.some(
                (ele) =>
                  ele.date.format('D') === day.format('D') &&
                  ele.date.format('H') === el.format('H') &&
                  (ele.status === InterviewSlotStatusTypes.Scheduled ||
                    ele.status === InterviewSlotStatusTypes.Draft ||
                    ele.status === undefined)
              )}
              disable={/* schedules.length > 4 || */ isSameDay}
            />
          );
        })}
      </div>
    </div>
  );
};
