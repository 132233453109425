import { ErrorMessageEn } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CandidateScheduleBuilder,
  GetSchedulesByCpsInput,
  GetSchedulesByCpsOutput,
} from '../candidate-schedule.types';

export const getSchedulesByCps = (builder: CandidateScheduleBuilder) =>
  builder.query<GetSchedulesByCpsOutput, GetSchedulesByCpsInput>({
    query: ({ candidateId, positionId }) => ({
      url: '/schedule/all-cps',
      params: {
        candidateId,
        positionId,
      },
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ Id }) => ({
              type: 'candidatesSchedule' as const,
              Id,
            })),
            { type: 'candidatesSchedule' },
          ]
        : [{ type: 'candidatesSchedule' }],
    transformErrorResponse: (error) => {
      errorHandler(
        '[getCandidatesEndpoint]',
        ErrorMessageEn.GetCandidatesFail,
        error.data,
        true
      );
      return error.data;
    },
  });
