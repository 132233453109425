const enum FilterType {
  All = 'All',
  Open = 'Open',
  Closed = 'Closed',
  Deactivated = 'Deactivated',
}

export const parseFilterType = (
  val: string | null,
  defaultValue = FilterType.Open
): FilterType => {
  if (!val) {
    return defaultValue;
  }

  const isFilterType = [
    FilterType.All,
    FilterType.Open,
    FilterType.Closed,
    FilterType.Deactivated,
  ].includes(val as FilterType);

  return isFilterType ? (val as FilterType) : defaultValue;
};

export default FilterType;
