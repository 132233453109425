import { FC } from 'react';
import { ReactComponent as PDFIcon } from '../../icons/UploadingPDFIcon.svg';
import classes from './ProgressBar.module.css';
import { ReactComponent as CheckIcon } from '../../icons/CheckIcon.svg';

interface ProgressBarProps {
  fileName: string;
  fileUploadProgress: number;
}
const ProgressBar: FC<ProgressBarProps> = ({
  fileName,
  fileUploadProgress,
}) => {
  return (
    <div data-testid="progress-bar" className={classes.ProgressBar}>
      <PDFIcon />
      <div className={classes.Column}>
        <div className={classes.PercentageFlex}>
          <p className={classes.FileName}>{fileName}</p>
          <p className={classes.Percentage}>{fileUploadProgress}%</p>
          {fileUploadProgress === 100 ? (
            <CheckIcon className={classes.CheckIcon} />
          ) : null}
        </div>
        <div
          data-testid="progress-line"
          className={classes.ProgressLine}
          style={{ width: `${fileUploadProgress}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
