import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classes from './SkillsEditableContainer.module.css';
import SkillsReadOnly from './SkillsReadOnly';
import SkillsContainer from '../SkillsFormContainer/SkillsContainer';
import NewSkillsContainer from '../SkillsFormContainer/NewSkillsContainer';
import EditableContainerInternal from '../EditableContainer/EditableContainerInternal';
import useInternalEditableContainer from '../../hooks/useInternalEditableContainer';
import { useGetCandidateSkillsQuery } from '../../store/modules/candidate-skill';
import CardSkeleton from '../Positions/CandidateSummaryCardList/CardSkeleton/CardSkeleton';

interface Props {
  isActive: boolean;
  secondary: boolean;
}

const SkillsEditableContainerInternal: FC<Props> = ({
  isActive,
  secondary,
}) => {
  const [t, i18next] = useTranslation('global');
  const { candidateId } = useParams();
  const { data, isLoading } = useGetCandidateSkillsQuery({
    candidateId: +candidateId!,
    lang: i18next.language as 'en' | 'es',
  });
  const {
    newFormStep: newSkills,
    isEdit,
    setIsEdit,
    handleCancel,
    handleCancelModal,
    show,
    onClickSave,
    onClickPencil,
    onClickPlus,
  } = useInternalEditableContainer();

  const newSkillsOrField = () => {
    if (isLoading)
      return <CardSkeleton hasProfilePicture={false} rows={2} padding={0} />;

    if (newSkills) {
      return (
        <NewSkillsContainer
          onClickCancel={handleCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={handleCancelModal}
          show={show}
          onClickSave={onClickSave}
          newSkills
          countForms={data?.length || 1}
        />
      );
    }
    return (
      <div
        className={`grid grid-cols-1 md:grid-cols-2 gap-2 ${classes.SkillsEditableContainer}`}
      >
        {data &&
          data?.map((skill, idx) => (
            <SkillsReadOnly
              skillsLength={data?.length ?? 0}
              key={skill?.Id}
              skill={skill}
              idx={idx}
              secondary={secondary}
            />
          ))}
      </div>
    );
  };

  return (
    <EditableContainerInternal
      title={t('formNames.skills')}
      isEditable={secondary && isEdit}
      data-testid="skills-editableForm"
      onClickPencil={onClickPencil}
      onClickPlus={onClickPlus}
      hasAddMore
      addMore={newSkills ? 'Skill' : false}
      isActive={isActive}
    >
      {!isEdit ? (
        <SkillsContainer
          isEdit
          setEdit={setIsEdit}
          onClickCancel={handleCancel}
          onClickCancelModal={handleCancelModal}
          show={show}
          secondary
        />
      ) : (
        newSkillsOrField()
      )}
    </EditableContainerInternal>
  );
};

export default SkillsEditableContainerInternal;
