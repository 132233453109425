import React, { FC } from 'react';
import { FieldError, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import SelectInput from '../../../../../SelectInput/SelectInput';
import BaseInput from '../../../../../Inputs/BaseInput';
import classes from './TrackHiredCandidateForm.module.css';
import BaseTextArea from '../../../../../TextArea/BaseTextArea';
import {
  EndReason,
  TrackingCandidatesData,
} from '../../../../../../constants/TrackingCandidates';

interface Props {
  register: UseFormRegister<TrackingCandidatesData>;
  watch: UseFormWatch<TrackingCandidatesData>;
  errors: {
    Salary?: FieldError | undefined;
    StartDate?: FieldError | undefined;
    EndDate?: FieldError | undefined;
    EndReason?: FieldError | undefined;
    SimeraFee?: FieldError | undefined;
    AdditionalNotesEndDate?: FieldError | undefined;
    Benefits?: FieldError | undefined;
  };
}

const TerminateFields: FC<Props> = ({ register, watch, errors }) => {
  const [t] = useTranslation('global');
  const endDate = register('EndDate', {
    required: {
      value: true,
      message: 'End Date is required',
    },
    validate: {
      isDate: (value) => {
        const date = dayjs(value);
        return date.isValid();
      },
      isValidDate: (value) => {
        const date = dayjs(value);
        const startDate = dayjs(watch('StartDate'));
        return date.isAfter(startDate);
      },
    },
  });

  const endDateReason = register('EndReason', {
    shouldUnregister: true,
    required: {
      value: true,
      message: 'End Date Reason is required',
    },
  });

  const additionalNotes = register('AdditionalNotesEndDate', {
    shouldUnregister: true,
  });
  const additionalNotesValue = watch('AdditionalNotesEndDate');

  const endDateErrorMessage = (() => {
    switch (errors.EndDate?.type) {
      case 'required':
        return t('Tracking.Required');
      case 'isDate':
        return t('Tracking.InvalidDate');
      case 'isValidDate':
        return t('Tracking.InvalidEndDate');
      default:
        return undefined;
    }
  })();
  return (
    <div data-testid="terminated-fields">
      <div className={classes.twoColumns}>
        <div
          style={{
            width: '50%',
          }}
        >
          <SelectInput
            onChange={endDateReason.onChange}
            onBlur={endDateReason.onBlur}
            refProp={endDateReason.ref}
            name={endDateReason.name}
            label={t('Tracking.EnDateReason')}
            placeholder=" "
            isRequired
            options={[
              { id: EndReason.QUIT, text: t('Tracking.Quit') },
              { id: EndReason.FIRED, text: t('Tracking.Fired') },
              {
                id: EndReason.COMPANY_LAYOFF,
                text: t('Tracking.Layoff'),
              },
              { id: EndReason.OTHER, text: t('Tracking.Other') },
            ]}
            errorText={errors.EndReason?.message}
          />
        </div>
        <div
          style={{
            width: '50%',
            marginTop: '0.2rem',
          }}
        >
          <BaseInput
            label={t('Tracking.EndDate')}
            isRequired
            type="date"
            name={endDate.name}
            onChange={endDate.onChange}
            onBlur={endDate.onBlur}
            refProp={endDate.ref}
            errorText={endDateErrorMessage}
          />
        </div>
      </div>
      <BaseTextArea
        label={t('Tracking.AdditionalNotes')}
        onBlur={additionalNotes.onBlur}
        name={additionalNotes.name}
        onChange={additionalNotes.onChange}
        refProp={additionalNotes.ref}
        counter={additionalNotesValue?.length || 0}
        maxLength={4096}
        secondary
      />
    </div>
  );
};

export default TerminateFields;
