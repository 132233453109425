import { FC } from 'react';
import { ReactComponent as SimeraLogoLight } from '../../icons/SimeraSpinnerLogo.svg';
import classes from './Spinner.module.css';

interface Props {
  secondary?: boolean;
  height?: string;
  width?: string;
  centerHeight?: string;
  centerWidth?: string;
}

const Spinner: FC<Props> = ({
  secondary,
  height,
  width,
  centerHeight,
  centerWidth,
}) => {
  const fill = secondary ? '#079e88' : '#05668d';
  return (
    <div
      className={classes.Container}
      style={{
        height,
        width,
      }}
      data-testid="spinner"
    >
      <div
        style={{ width: centerWidth, height: centerHeight }}
        className={classes.Center}
      >
        <SimeraLogoLight fill={fill} />
      </div>
    </div>
  );
};

Spinner.defaultProps = {
  secondary: false,
  height: '100vh',
  width: '100vw',
  centerHeight: '120px',
  centerWidth: '120px',
};

export default Spinner;
