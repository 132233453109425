import { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Person } from '$icons/MenuAvatar.svg';
import { ReactComponent as Positions } from '$icons/search-list.svg';
import { ReactComponent as DoorOpen } from '$icons/DoorOpen.svg';
import { ReactComponent as Home } from '$icons/HomeIcon.svg';
import { ReactComponent as Support } from '$icons/Support.svg';
import { ReactComponent as TeamMembers } from '$icons/team-members.svg';
import { ReactComponent as AddIcon } from '$icons/Add-Position.svg';
import { ReactComponent as Payroll } from '$icons/money-bag-svgrepo-com.svg';
import DropdownMenuItem from '../CandidateHome/DropdownMenuItem';
import { logoutAuthAction } from '../../store/legacy/auth';
import classes from './DropdownMenu.module.css';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { setFirstLoginStatusFalse } from '../../api/auth.service';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface Props {
  className?: string;
  isEmployer?: boolean;
  isInternal?: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  onAddPosition?: () => void;
}

const DropdownMenu: FC<Props> = ({
  className,
  isEmployer,
  isInternal,
  setModal,
  onAddPosition,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { serverCandidate } = useAppSelector((state) => state.auth);
  const [t, i18next] = useTranslation('global');
  const parentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(parentRef, () => setModal(false));

  const logOut = async () => {
    if (serverCandidate?.IsFirstLogin) {
      await setFirstLoginStatusFalse();
    }
    dispatch(logoutAuthAction(i18next.language));
  };

  const linkToSupport = () => {};
  const redirectRoute = '/profile';
  const profile = () => navigate(redirectRoute);
  const home = () => navigate('/home');
  const employerDashboard = () => navigate('/employer-dashboard');
  const gotToTeamMembers = () => navigate('/employer-dashboard/team-members');

  const text = serverCandidate?.IsFirstLogin
    ? t('authButton.profile')
    : t('authButton.onBoarding');
  return (
    <div
      data-testid="dropdown-menu"
      className={`${classes.dropdownMenu} ${className}`}
      ref={parentRef}
    >
      {!isInternal && (
        <DropdownMenuItem
          icon={Home}
          text={t('authButton.home')}
          onClick={isEmployer ? employerDashboard : home}
        />
      )}
      {isEmployer && (
        <>
          <div className={classes.EmployerMobileItem}>
            <DropdownMenuItem
              icon={Positions}
              text={t('EmployerDashboard.Positions')}
              onClick={employerDashboard}
            />
          </div>
          <div className={classes.EmployerMobileItem}>
            <DropdownMenuItem
              icon={TeamMembers}
              text={t('EmployerDashboard.TeamMembers')}
              onClick={gotToTeamMembers}
            />
          </div>
          <div className={classes.EmployerMobileItem}>
            <DropdownMenuItem
              icon={AddIcon}
              text={t('EmployerDashboard.AddNewJob')}
              onClick={() => {
                if (onAddPosition) {
                  onAddPosition();
                  setModal(false);
                }
              }}
            />
          </div>
          <div className={classes.EmployerMobileItem}>
            <DropdownMenuItem
              icon={Payroll}
              text="Make Payroll Request"
              onClick={() => {
                if (onAddPosition) {
                  onAddPosition();
                }
              }}
            />
          </div>
        </>
      )}
      {!isEmployer && !isInternal && (
        <>
          <DropdownMenuItem icon={Person} text={text} onClick={profile} />
          <DropdownMenuItem
            icon={Support}
            text={t('authButton.support')}
            onClick={linkToSupport}
            support
          />
        </>
      )}

      <DropdownMenuItem
        icon={DoorOpen}
        color="#D00000"
        text={t('authButton.logout')}
        onClick={logOut}
      />
    </div>
  );
};

DropdownMenu.defaultProps = {
  className: '',
  isEmployer: false,
  isInternal: false,
  onAddPosition: () => {},
};

export default DropdownMenu;
