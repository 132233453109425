import { FC } from 'react';
import classes from './ReadOnly.module.css';

interface ReadOnlyProps {
  workExperienceDescription?: string | null;
}
const WorkSummaryReadOnly: FC<ReadOnlyProps> = ({
  workExperienceDescription,
}) => {
  const formattedWorkExperienceDescription =
    workExperienceDescription?.split(/\r?\n|\r|\n/g);
  return (
    <div className={classes.Fragment}>
      <div className={classes.DescriptionContainer}>
        <ul className={classes.Description}>
          {formattedWorkExperienceDescription?.map((item) => (
            <li key={`${item} ${Math.random()}`}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

WorkSummaryReadOnly.defaultProps = {
  workExperienceDescription: '',
};
export default WorkSummaryReadOnly;
