import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import classes from './FilterInput.module.css';
import Button from '../../../../Buttons/Button';
import { ReactComponent as CloseX } from '../../../../../icons/CloseX.svg';
import { stringRegex } from '../../../../../constants/regex';
import { toPascalCase } from '../../../../../utils/toPascalCase';
import { ReactComponent as ArrowDown } from '../../../../../icons/ArrowDown.svg';
import MatchTypes from '../../../../../constants/MatchValues';

interface WordItem {
  id: number;
  word: string;
}

interface Props {
  title: string;
  isText?: boolean;
  nameField?: boolean;
  matchInput?: boolean;
}

export const FilterInput: FC<Props> = ({
  title,
  isText,
  nameField,
  matchInput,
}) => {
  const [t] = useTranslation('global');
  const [wordList, setWordList] = useState<WordItem[]>([]);
  const [show, setShow] = useState(false);
  const [counter, setCounter] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [match, setMatch] = useState(
    searchParams.get(`${toPascalCase(title)}Match`) ?? MatchTypes.ALL
  );

  const inputRequired = t('forms.errors.required');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ inputValue: string }>();
  const inputValue = register('inputValue', {
    required: nameField ? false : inputRequired,
    pattern: {
      value: isText ? stringRegex : /.*/,
      message: isText ? t('forms.errors.letters') : '',
    },
  });

  useEffect(() => {
    const list = searchParams.get(toPascalCase(title))?.split(',');
    if (list) {
      const newList = list?.map((item, index) => ({
        id: index,
        word: item.trim(),
      }));
      setWordList([...newList]);
    }
  }, [searchParams, title]);

  const onSubmit = (data: { inputValue: string }) => {
    if (
      !wordList.some(
        (item) => item.word.toLowerCase() === data.inputValue.toLowerCase()
      )
    ) {
      const newParams = new URLSearchParams(searchParams);
      const newSearch = newParams.get(toPascalCase(title))
        ? `${newParams.get(
            toPascalCase(title)
          )},${data.inputValue.toLowerCase()}`
        : data.inputValue.toLowerCase();
      newParams.set(toPascalCase(title), newSearch);
      setSearchParams(newParams);
      const newWordItem: WordItem = {
        id: counter,
        word: data.inputValue,
      };
      setWordList([...wordList, newWordItem]);
      setCounter((prevCounter) => prevCounter + 1);
      reset();
    }
  };

  const onSubmitNameField = (data: { inputValue: string }) => {
    const newParams = new URLSearchParams(searchParams);
    if (data.inputValue === '') {
      newParams.delete(toPascalCase(title));
      setSearchParams(newParams);
    } else {
      newParams.set(toPascalCase(title), data.inputValue.toLowerCase());
      setSearchParams(newParams);
    }
  };

  const handleDeleteClick = (id: number, name: string) => {
    const newParams = new URLSearchParams(searchParams);
    const newSearch = newParams
      .get(toPascalCase(title))
      ?.split(',')
      .filter((item) => item.toLowerCase() !== name.toLowerCase())
      ?.join(',');

    if (newSearch && newSearch !== '') {
      newParams.set(toPascalCase(title), newSearch);
      setSearchParams(newParams);
    } else {
      newParams.delete(toPascalCase(title));
      setSearchParams(newParams);
    }

    const updatedList = wordList.filter((item) => item.id !== id);
    setWordList(updatedList);
  };

  const truncateText = (text: string) => {
    const maxLength = 11;
    if (text.length <= maxLength) {
      return text;
    }

    return `${text.slice(0, maxLength)}...`;
  };

  const handleMatch = (matchQuery: MatchTypes) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(`${toPascalCase(title)}Match`, matchQuery);
    setSearchParams(newParams);
    setMatch(matchQuery);
  };

  return (
    <div className={classes.FilterContainer}>
      <div
        className={classes.TitleContainer}
        onClick={() => setShow((prev) => !prev)}
        aria-hidden
      >
        {matchInput && (
          <>
            <p className={`${classes.Title} ${classes.CursorPointer}`}>
              {title}
            </p>
            <ArrowDown />
            {show && (
              <div className={classes.MenuContainer}>
                <div
                  onClick={() => handleMatch(MatchTypes.ALL)}
                  className={`${classes.MenuItem} ${
                    match === MatchTypes.ALL && classes.selected
                  }`}
                  aria-hidden
                >
                  {t('Filters.All')}
                </div>
                <div
                  onClick={() => handleMatch(MatchTypes.ANY)}
                  className={`${classes.MenuItem} ${
                    match === MatchTypes.ANY && classes.selected
                  }`}
                  aria-hidden
                >
                  {t('Filters.Any')}
                </div>
              </div>
            )}
          </>
        )}
        {!matchInput && <p className={classes.Title}>{title}</p>}
      </div>
      <form
        onSubmit={handleSubmit(nameField ? onSubmitNameField : onSubmit)}
        className={classes.Form}
      >
        <input
          type="text"
          placeholder={t('forms.type')}
          className={classes.FilterInput}
          ref={inputValue.ref}
          name={inputValue.name}
          onChange={inputValue.onChange}
          onBlur={inputValue.onBlur}
          autoComplete="off"
          data-testid="input"
        />
        <p className={classes.ErrorMessage}>{errors.inputValue?.message}</p>
        {wordList.length !== 0 && !nameField ? (
          <ul className={classes.List}>
            {wordList.map((wordItem, index) => (
              <li
                key={wordItem.id}
                className={classes.ListItem}
                data-testid={`item${index}`}
              >
                {truncateText(wordItem.word)}
                <Button
                  icon={CloseX}
                  iconStyles={{
                    width: '16',
                    height: '16',
                    fill: '#FFFFFF',
                  }}
                  bgColor="transparent"
                  showText={false}
                  onClick={() => handleDeleteClick(wordItem.id, wordItem.word)}
                  customStyles={{
                    paddingLeft: '1rem',
                    width: 'auto',
                    flexGrow: '0',
                    marginBottom: '0',
                    height: 'auto',
                  }}
                />
              </li>
            ))}
          </ul>
        ) : null}
      </form>
    </div>
  );
};

FilterInput.defaultProps = {
  isText: false,
  nameField: false,
  matchInput: false,
};
