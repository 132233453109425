import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  DuplicatePositionInternalInput,
  DuplicatePositionInternalOutput,
} from '../companies.types';

export const duplicatePositionInternal = (builder: CompanyBuilder) =>
  builder.mutation<
    DuplicatePositionInternalOutput,
    DuplicatePositionInternalInput
  >({
    query: ({ position }) => ({
      url: '/positions/duplicate',
      method: 'POST',
      body: position,
    }),
    transformResponse: (
      queryResponse: DuplicatePositionInternalOutput,
      _,
      arg
    ) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEs.POSITION_DUPLICATED
          : SuccessMessageEn.POSITION_DUPLICATED,
        true
      );
      return queryResponse;
    },
    transformErrorResponse: (error, _, arg) => {
      errorHandler(
        '[duplicatePositionInternal]',
        arg.lang === 'en'
          ? ErrorMessageEn.PositionDuplicateError
          : ErrorMessageEs.PositionDuplicateError,
        error,
        true
      );
    },
  });
