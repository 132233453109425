import { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PositionInternalDto from '../../../types/serverTypes/positionInternalDto';
import classes from '../PositionPage.module.css';
import PositionStatus from '../../../constants/PositionStatus';
import PositionCardMenu from '../PositionCardMenu/PositionCardMenu';
import EditJobModal from '../../AddNewJobModal/EditJobModal';
import DuplicateCustomRoster from '../../DuplicateCustomRoster/DuplicateCustomRoster';
import DuplicatePositionModal from '../../DuplicateJobModal/DuplicatePositionModal';
import PositionDto from '../../../types/serverTypes/positionDto';
import { useOriginPath } from '../../../hooks/use-origin-path';

interface PositionCardInternalProps {
  position: PositionDto | PositionInternalDto;
}

const PositionCardInternal: FC<PositionCardInternalProps> = ({ position }) => {
  const [t] = useTranslation('global');
  const { setOriginPath } = useOriginPath();
  const [openModal, setOpenModal] = useState(false);
  const [openCustomRosterModal, setOpenCustomRosterModal] = useState(false);
  const [openDuplicateModal, setDuplicateModal] = useState(false);
  const card = useRef<HTMLDivElement>(null);
  const MAX_SKILLS = 5;

  const handleOpenDuplicateModal = (show: boolean) => setDuplicateModal(show);
  const dictionary: {
    [key: string]: string;
  } = {
    Open: t('PositionCard.Open'),
    Closed: t('PositionCard.Filled'),
    Deactivated: t('PositionCard.Deactivated'),
  };

  const chipClass =
    position.Status !== PositionStatus.Closed &&
    position.Status !== PositionStatus.Deactivated
      ? classes.ChipStatus
      : classes.ChipStatusClose;

  return (
    <>
      <div
        ref={card}
        className={classes.card}
        data-testid="positionCard"
        tabIndex={-1}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardJob}>
            <p className={classes.cardJobCategory}>
              {`${position?.Company?.Name}  ID:${position?.Id ?? ''}`}
            </p>
            <p className={classes.cardJobTitle}>
              {position?.JobPosition?.title}
            </p>
          </div>
          <div className={classes.RightContent}>
            <div className={classes.PositionStatus}>
              <div className={chipClass}>
                {(position.Status !== undefined &&
                  dictionary[position.Status]) ||
                  t('PositionCard.Open')}
              </div>
              <p className={classes.tooltipCandidateText}>
                {`${position?.CandidatesPositionStatus?.length} ${t(
                  'SimeraInternal.Candidates'
                )}`}
              </p>
            </div>
            <PositionCardMenu
              isInternal
              position={position}
              setOpenEditModal={setOpenModal}
              handleOpenDuplicateModal={handleOpenDuplicateModal}
            />
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.cardBody}>
          <div className={classes.experience}>
            <p className={classes.cardBodySubtitle}>{`${t(
              'EmployerPositions.Experience'
            )}:`}</p>
            <p
              className={classes.cardBodyText}
            >{`${position.ExperienceYears} years+`}</p>
          </div>
          <div className={classes.salary}>
            <p className={classes.cardBodySubtitle}>{`${t(
              'EmployerAddPosition.PositionSalary'
            )}:`}</p>
            <p
              className={classes.cardBodyText}
            >{`$${position.SalaryLowerBound} - $${position.SalaryUpperBound}`}</p>
          </div>
        </div>
        <div className={classes.cardSkills}>
          <p className={classes.cardBodySubtitle}>{`${t(
            'EmployerAddPosition.PositionSkills'
          )}:`}</p>
          <div className={classes.chipContainer}>
            {position.Skills_position?.slice(0, MAX_SKILLS).map((skill) => (
              <div
                className={classes.Chip}
                key={`${skill.Title}-${position.Id}`}
              >
                <p className={classes.ChipText}> {skill.Title} </p>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.cardCandidates}>
          <button
            type="button"
            tabIndex={0}
            className={classes.cardLink}
            aria-hidden
          >
            <Link
              to={`/simera-dashboard/positions/${position.Id}`}
              className={classes.Link}
              onClick={() => {
                setOriginPath(window.location.pathname);
              }}
            >
              {t('SimeraInternal.PositionDetails')}
            </Link>
          </button>
        </div>
      </div>
      <EditJobModal
        modalIsOpen={openModal}
        setModal={setOpenModal}
        position={position}
      />
      <DuplicatePositionModal
        modalIsOpen={openDuplicateModal}
        setModal={handleOpenDuplicateModal}
        position={position}
        setOpenCustomRosterModal={setOpenCustomRosterModal}
      />
      {openCustomRosterModal && (
        <DuplicateCustomRoster
          isOpen={openCustomRosterModal}
          setIsOpen={setOpenCustomRosterModal}
          positionId={position.Id}
        />
      )}
    </>
  );
};

export default PositionCardInternal;
