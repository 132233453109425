import {
  CandidateData,
  Candidate,
  CandidatesPositionStatusOnGuestMode,
} from '../types/candidates';

type WorkExpArrType =
  | CandidateData['CandidateWorkExperience']
  | Candidate['CandidateWorkExperience']
  | CandidatesPositionStatusOnGuestMode['CandidateWorkExperience'];

const computeLastJob = (workExpArr: WorkExpArrType) => {
  const getLastJob = workExpArr!
    .map((c) =>
      c.Egress
        ? c
        : {
            ...c,
            Egress: new Date(Date.now()).toISOString().slice(0, 10),
          }
    )
    .reduce((accum, curr) =>
      new Date(curr.Egress!) >= new Date(accum.Egress!) ? curr : accum
    );

  if ('JobPosition' in getLastJob && getLastJob?.JobPosition?.title) {
    return {
      title: getLastJob?.JobPosition?.title! || '',
      company: getLastJob.Company,
    };
  }

  return {
    title:
      'JobTitle' in getLastJob && getLastJob?.JobTitle
        ? getLastJob?.JobTitle
        : '',
    company:
      'Company' in getLastJob && getLastJob.Company ? getLastJob.Company : '',
  };
};

export default computeLastJob;
