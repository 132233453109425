import { FC } from 'react';
import classes from './ScoreStep.module.css';

interface Props {
  title: string;
  bindTo: (e: any) => void;
}

const ScoreStep: FC<Props> = ({ title, bindTo }) => {
  return (
    <div className={classes.ContainerStyles} onClick={bindTo} aria-hidden>
      <p className={classes.StepName}>{title}</p>
      <p className={classes.LabelStyles}>+</p>
    </div>
  );
};

export default ScoreStep;
