import { userLanguage } from '$translations/index';
import CandidateEntity from '$types/entities/candidate.entity';
import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CandidateTosAgreeInput,
  CandidateTosBuilder,
} from '../candidate-tos.types';

export const agreeCandidateTos = (builder: CandidateTosBuilder) =>
  builder.mutation<CandidateEntity, CandidateTosAgreeInput>({
    query: ({ candidateId }) => ({
      url: `candidates/terms-of-service/${candidateId}`,
      method: 'PATCH',
    }),
    invalidatesTags: ['CandidateTos'],
    transformErrorResponse: (error) => {
      errorHandler(
        '[AgreeCandidateTos]',
        userLanguage === 'es'
          ? ErrorMessageEs.InfoFail
          : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
