import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '$store/constants/reducerPaths';
import { baseQuery } from '$store/utils/baseQuery';
import { getAllVideoFeedbacks } from './queries/get-all-video-feedbacks';
import { deleteVideoComment, sendVideoComment } from './mutations';

export const videoFeedbacksApi = createApi({
  reducerPath: ReducerPaths.VIDEO_FEEDBACKS,
  tagTypes: ['videoFeedback'],
  baseQuery,
  endpoints: (builder) => ({
    getAllVideoFeedbacks: getAllVideoFeedbacks(builder),
    sendVideoComment: sendVideoComment(builder),
    deleteVideoComment: deleteVideoComment(builder),
  }),
});

export const {
  useGetAllVideoFeedbacksQuery,
  useSendVideoCommentMutation,
  useDeleteVideoCommentMutation,
} = videoFeedbacksApi;
