import { useCallback, useRef, Dispatch, SetStateAction } from 'react';

const useInfiniteScroll = (
  isLoading: boolean,
  hasNextPage: boolean,
  setPage: Dispatch<SetStateAction<number>>
) => {
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            setPage((prevPage) => prevPage + 1);
          }
        },
        { threshold: 0.6 }
      );
      if (node) observer.current.observe(node);
    },
    [isLoading, hasNextPage, setPage]
  );

  return { lastElementRef };
};

export default useInfiniteScroll;
