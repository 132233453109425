import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  PositionInput,
  PositionOutput,
} from '../companies.types';

export const deletePosition = (Builder: CompanyBuilder) =>
  Builder.mutation<PositionOutput, PositionInput>({
    query: (option) => ({
      url: `/positions/${option.positionId}`,
      method: 'DELETE',
    }),
    transformResponse: (queryResponse: PositionOutput, _, arg) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEs.DeletePosition
          : SuccessMessageEn.DeletePosition,
        true
      );
      return queryResponse;
    },
    transformErrorResponse(error, meta, arg) {
      errorHandler(
        '[DeletePosition]',
        arg.lang === 'es'
          ? ErrorMessageEs.DeletePosition
          : ErrorMessageEn.DeletePosition,
        error,
        true
      );
    },
    invalidatesTags: () => [{ type: 'Company' }],
  });
