import { useTranslation } from 'react-i18next';
import Home from '../../Home/Home';
import ForgotPasswordForm from '../../ForgotPasswordForm/ForgotPasswordForm';
import classes from './ForgotPasswordScreen.module.css';
import logo from '../../../images/logoBlue.svg';

const ForgotPasswordScreen = () => {
  const [t] = useTranslation('global');
  return (
    <Home>
      <section className={classes.container}>
        <h1 className={classes.header}>{t('forgotPass.title')} </h1>
        <div className={classes.divider} />
        <p className={classes.subHeader}>{t('forgotPass.instructions')}</p>
        <div className={classes.card}>
          <div>
            <img src={logo} alt="logo" />
          </div>
          <ForgotPasswordForm />
        </div>
      </section>
    </Home>
  );
};

export default ForgotPasswordScreen;
