import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as ArrowDown } from '../../../icons/ChevronDownBlack.svg';
import { ReactComponent as ArrowUp } from '../../../icons/ChevronUpBlack.svg';
import {
  useGetApplicationsQuery,
  useGetPositionsBySkillQuery,
  useGetPreviousApplicationsQuery,
} from '../../../store/modules/companies';
import HomeLayout from '../../CandidateHome/HomeLayout';
import Spinner from '../../Spinner/Spinner';
import ApplicationsList from './ApplicationsList/ApplicationsList';
import classes from './JobDashboard.module.css';
import { JobSuggestions } from './JobSuggestions/JobSuggestions';
import ModalJobPosition from './ModalJobPosition/ModalJobPosition';
// import ModalAccessRestricted from './ModalAccessRestricted/ModalAccessRestricted';

const JobDashboardScreen = () => {
  const [t, i18n] = useTranslation('global');
  const [isOpen, setIsOpen] = useState(false);
  const { positionId } = useParams();

  // const [isModalAccess, setIsModalAccess] = useState(false);
  const [arrow, setArrow] =
    useState<React.FunctionComponent<React.SVGProps<SVGSVGElement>>>(ArrowDown);
  const { data: suggestions, isLoading } = useGetPositionsBySkillQuery({
    lang: i18n.language as 'en' | 'es',
  });
  const { data: applications } = useGetApplicationsQuery({
    lang: i18n.language as 'en' | 'es',
  });
  const { data: previousApplications } = useGetPreviousApplicationsQuery({
    lang: i18n.language as 'en' | 'es',
  });

  const toogleApplication = () => {
    if (!isOpen) {
      setArrow(ArrowUp);
    } else {
      setArrow(ArrowDown);
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <HomeLayout isProfile>
      <div className={classes.container}>
        {isLoading ? <Spinner /> : <JobSuggestions suggestions={suggestions} />}
        <ApplicationsList
          applications={applications}
          title={t('JobDashboard.JobSuggestions.Title2')}
        />
        <ApplicationsList
          applications={previousApplications}
          title={t('JobDashboard.JobSuggestions.Title3')}
          Arrow={arrow}
          isOpen={isOpen}
          toogleOpen={toogleApplication}
        />
      </div>
      {/* <ModalAccessRestricted
        isModalAccess={isModalAccess}
        setIsModalAccess={setIsModalAccess}
      /> */}
      {positionId && <ModalJobPosition positionId={+positionId!} />}
    </HomeLayout>
  );
};

export default JobDashboardScreen;
