import { ChangeEvent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { simeraEmailRegex } from '../../constants/regex';
import {
  internalEmailLoginAuthAction,
  internalGoogleLoginAuthAction,
  internalLinkedInLoginAuthAction,
} from '../../store/legacy/auth';
import Button from '../Buttons/Button';
import BaseInput from '../Inputs/BaseInput';
import { ReactComponent as GoogleIcon } from '../../icons/GoogleColorIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../icons/LinkedInBlueIcon.svg';
import { ReactComponent as EyeIcon } from '../../icons/PasswordEye.svg';
import classes from './LoginForm.module.css';
import RoundedButton from '../Buttons/RoundedButton';
import { useAppDispatch, useAppSelector } from '../../hooks';

interface FormTypes {
  email: string;
  password: string;
}

const SimeraLoginForm = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const [t, i18next] = useTranslation('global');

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormTypes>({ mode: 'onChange' });

  useEffect(() => {
    if (authState.isLoggedIn) {
      navigate('/internal-dashboard');
    }
  }, [authState, navigate]);

  const email = register('email', {
    required: true,
    pattern: simeraEmailRegex,
    maxLength: {
      value: 100,
      message: t('register.invalidEmailInput').toString(),
    },
  });
  const password = register('password', { required: true });

  const submitForm = (values: FormTypes) => {
    dispatch(
      internalEmailLoginAuthAction(
        values.email.toLowerCase(),
        values.password,
        i18next.language
      )
    );
  };

  const onGoogleSignIn = () => {
    dispatch(internalGoogleLoginAuthAction(i18next.language));
  };

  const onLinkedInSignIn = () => {
    dispatch(internalLinkedInLoginAuthAction(i18next.language));
  };

  return (
    <form
      data-testid="login-form"
      className={classes.Form}
      onSubmit={handleSubmit(submitForm)}
    >
      <div>
        <BaseInput
          onChange={(e: ChangeEvent) => email.onChange(e)}
          name={email.name}
          onBlur={email.onBlur}
          refProp={email.ref}
          label={t('register.emailInput')}
          type="text"
          errorText={errors.email && t('login.emailFormat')}
          dataTestId="login_email"
        />
      </div>

      <div>
        <BaseInput
          onChange={(e: ChangeEvent) => password.onChange(e)}
          name={password.name}
          onBlur={password.onBlur}
          refProp={password.ref}
          label={t('register.passwordInput')}
          type="password"
          errorText={errors.password && 'Required'}
          icon={EyeIcon}
          isPassword
          dataTestId="login_password"
        />
      </div>

      <div className={classes.JustifyRight}>
        <a href="recover_password" className={classes.Password}>
          {t('login.forgotPass')}
        </a>
      </div>

      <div className={classes.LoginButton}>
        <Button
          disabled={!isValid}
          customStyles={{ borderRadius: '10px' }}
          onClick={handleSubmit(submitForm)}
          text={t('login.login')}
          bgColor="#079E88"
          showText
          submit
        />
      </div>
      <div className={classes.OrFlex}>
        <div className={classes.OrTags}>
          <hr className={classes.ORLine} />
          <p className={classes.Or}>{t('common.or')}</p>
          <hr className={classes.ORLine} />
        </div>
      </div>
      <div data-testid="login-buttons" className={classes.ButtonFlex}>
        <RoundedButton icon={GoogleIcon} onClick={onGoogleSignIn} />
        <RoundedButton icon={LinkedInIcon} onClick={onLinkedInSignIn} />
      </div>
      <div className={classes.accounts}>
        <div>
          <p className={classes.AccountSimera}>
            {t('login.noAccount')}
            <Link to="/internal_register" className={classes.SignUp}>
              &nbsp;{t('login.signUp')}
            </Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default SimeraLoginForm;
