import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../Buttons/Button';
import classes from './ConfirmDeleteFileModal.module.css';
import { ReactComponent as CloseX } from '../../../icons/CloseX.svg';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

interface Props {
  setShowModal: (value: React.SetStateAction<boolean>) => void;
  handleConfirm: () => void;
  secondary?: boolean;
}

const ConfirmDeleteFileModal: FC<Props> = ({
  setShowModal,
  handleConfirm,
  secondary,
}) => {
  const modalRef = useRef<any>(null);
  const [t] = useTranslation('global');

  useOnClickOutside(modalRef, () => setShowModal(false));

  const confirmButton = secondary
    ? classes.ConfirmBtn
    : classes.ConfirmBtnSecondary;

  return (
    <div className={classes.modal}>
      <div className={classes.modalContent} ref={modalRef}>
        <nav className={classes.modal__nav}>
          <h2 className={classes.modal__nav__title}>
            {t('AdditionalDocs.ConfirmDeleteFile')}
          </h2>
          <Button
            icon={CloseX}
            iconStyles={{
              width: '14',
              height: '14',
              fill: '#232428',
            }}
            bgColor="transparent"
            showText={false}
            onClick={() => setShowModal(false)}
            customStyles={{
              width: '0.875rem',
              height: '0.875rem',
              flexGrow: '0',
              marginBottom: '0',
            }}
          />
        </nav>
        <main className={classes.modal__main}>
          <div className={classes.modal__form__fields}>
            <strong>{t('AdditionalDocs.ConfirmText')}</strong>
            <p className={classes.modalText}>
              {t('MakeOfferModal.ConfirmSubText')}
            </p>
            <div className={classes.actions}>
              <button
                type="button"
                className={classes.normalButton}
                onClick={() => setShowModal(false)}
              >
                {t('ConfirmAddToPositionModal.goBack')}
              </button>
              <button
                type="button"
                onClick={handleConfirm}
                className={confirmButton}
              >
                {t('ConfirmAddToPositionModal.accept')}
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

ConfirmDeleteFileModal.defaultProps = {
  secondary: false,
};

export default ConfirmDeleteFileModal;
