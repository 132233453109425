import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  GetRatingInternalInput,
  GetRatingInternalOutput,
  RatingBuilder,
} from '../ratings.types';

export const getRatingInternal = (builder: RatingBuilder) =>
  builder.query<GetRatingInternalOutput, GetRatingInternalInput>({
    query: (options) => ({
      url: '/ratings/internal',
      method: 'GET',
      params: options.data,
    }),
    providesTags: (result) =>
      result
        ? [
            {
              type: 'Rating',
              id: result.Id,
            },
            { type: 'Rating', id: 'List' },
          ]
        : [{ type: 'Rating', id: 'List' }],
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[Rating]',
        args.lang === 'en' ? ErrorMessageEn.InfoFail : ErrorMessageEs.InfoFail,
        err,
        true
      );
      return err.data;
    },
  });
