import { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PositionDto from '../../types/serverTypes/positionDto';
import StatusCandidatePosition from '../../types/serverTypes/StatusCandidatePosition';
import classes from './PositionPage.module.css';
import EditJobModal from '../AddNewJobModal/EditJobModal';
import PositionCardMenu from './PositionCardMenu/PositionCardMenu';
import DuplicatePositionModal from '../DuplicateJobModal/DuplicatePositionModal';
import { SaveState } from '../../types/ForrmType';
import PositionInternalDto from '../../types/serverTypes/positionInternalDto';

interface Props {
  position: PositionInternalDto | PositionDto;
}

const PositionCard: FC<Props> = ({ position }) => {
  const [t] = useTranslation('global');
  const [openModal, setOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [openDuplicateModal, setDuplicateModal] = useState(
    position.actionStatus === SaveState.IDLE
  );
  const card = useRef<HTMLDivElement>(null);

  const handleOpenDuplicateModal = (show: boolean) => setDuplicateModal(show);

  const newCandidates =
    position?.CandidatesPositionStatus?.filter(
      (s) => s.Status === StatusCandidatePosition.new
    ).length || 0;

  const interviewed =
    position?.CandidatesPositionStatus?.filter(
      (s) => s.Status === StatusCandidatePosition.requestInterview
    ).length || 0;

  const offerSent =
    position?.CandidatesPositionStatus?.filter(
      (s) => s.Status === StatusCandidatePosition.offerSent
    ).length || 0;

  const handleSkillsDisplay = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        ref={card}
        className={classes.card}
        data-testid="positionCard"
        tabIndex={-1}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardJob}>
            <p className={classes.cardJobCategory}>
              {position.JobPosition.Category?.Name ?? ''}
            </p>
            <p className={classes.cardJobTitle}>{position.JobPosition.title}</p>
          </div>
          <div className={classes.tooltip}>
            <div className={classes.tooltiptext}>
              <div className={classes.tooltipContent}>
                {t('EmployerPositions.NewAplications')}:
                <p className={classes.tooltiptextNumber}>{newCandidates}</p>
              </div>
              <div className={classes.tooltipContent}>
                {`${t('EmployerPositions.BeingInterviewed')}:`}
                <p className={classes.tooltiptextNumber}>{interviewed}</p>
              </div>
              <div className={classes.tooltipContent}>
                {`${t('EmployerPositions.OfferSent')}:`}
                <p className={classes.tooltiptextNumber}>{offerSent}</p>
              </div>
            </div>
            <div className={classes.popover} data-testid="candidates-popover">
              <div
                className={classes.popoverItem}
                data-testid="candidates-popoverItem"
              >
                {newCandidates}
              </div>
              <div className={classes.popoverItem}>{interviewed}</div>
              <div className={classes.popoverItem}>{offerSent}</div>
            </div>
          </div>
          <PositionCardMenu
            isInternal={false}
            setOpenEditModal={setOpenModal}
            position={position}
            handleOpenDuplicateModal={handleOpenDuplicateModal}
          />
        </div>
        <div className={classes.divider} />
        <div className={classes.cardBody}>
          <div className={classes.experience}>
            <p className={classes.cardBodySubtitle}>{`${t(
              'EmployerPositions.Experience'
            )}:`}</p>
            <p
              className={classes.cardBodyText}
            >{`${position.ExperienceYears} years+`}</p>
          </div>
          <div className={classes.salary}>
            <p className={classes.cardBodySubtitle}>{`${t(
              'EmployerAddPosition.PositionSalary'
            )}:`}</p>
            <p
              className={classes.cardBodyText}
            >{`$${position.SalaryLowerBound} - $${position.SalaryUpperBound}`}</p>
          </div>
        </div>
        <div className={classes.cardSkills}>
          <p className={classes.cardBodySubtitle}>{`${t(
            'EmployerAddPosition.PositionSkills'
          )}:`}</p>
          <div
            className={
              isOpen ? classes.chipContainerTall : classes.chipContainer
            }
            onClick={handleSkillsDisplay}
            title={t('EmployerPositions.ViewSkills')}
            onKeyDown={() => {}}
            tabIndex={0}
            role="button"
          >
            {position.Skills_position.map((skill) => (
              <div
                className={classes.Chip}
                key={`${skill.Title}-${position.Id}`}
              >
                <p className={classes.ChipText}> {skill.Title} </p>
              </div>
            ))}
          </div>
        </div>
        <Link
          data-testid="viewCandidatesButton"
          role="button"
          tabIndex={0}
          className={classes.cardLink}
          onKeyDown={() => {}}
          to={`/employer-dashboard/positions/${position.Id}`}
        >
          {t('EmployerPositions.ViewCandidates')}
        </Link>
      </div>
      <EditJobModal
        modalIsOpen={openModal}
        setModal={setOpenModal}
        position={position}
      />
      <DuplicatePositionModal
        modalIsOpen={openDuplicateModal}
        setModal={handleOpenDuplicateModal}
        position={position}
      />
    </>
  );
};

export default PositionCard;
