import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './PasswordResetSuccess.module.css';
import ResetSuccessCard from './ResetSuccessCard';

const PasswordResetSuccess: FC = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/login');
  };

  return (
    <div className={classes.container} data-testid="container">
      <ResetSuccessCard onClickButton={goToLogin} />
    </div>
  );
};

export default PasswordResetSuccess;
