import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackgroundTaskStatus from '../../../types/backgroundTaskStatus';
import classes from './MigrationTasks.module.css';
import { ReactComponent as StartDate } from '../../../icons/StartDate.svg';
import { ReactComponent as EndDate } from '../../../icons/EndDate.svg';
import { ReactComponent as Status } from '../../../icons/Status.svg';
import { ReactComponent as ArrowDown } from '../../../icons/ArrowDown.svg';
import HoverTooltip from '../../Tooltip/HoverTooltip';
import { Task } from '../../../types/candidates';

interface MigrationTasksProps {
  task: Task;
}

const MigrationTasks: FC<MigrationTasksProps> = ({ task }) => {
  const [t] = useTranslation('global');
  const [isOpenLog, setIsOpenLog] = useState<boolean>(false);

  const Childdiv = {
    height: '100%',
    width: `${Number.isNaN(task.Percentage) ? 0 : task.Percentage}%`,
    backgroundColor: 'var(--primary-green)',
    textAlign: 'right' as 'right',
  };
  return (
    <>
      <div
        className={`${isOpenLog && classes.containerLog} ${classes.container}`}
      >
        <div
          aria-label="Arrow down"
          style={{ width: '2%' }}
          role="button"
          tabIndex={0}
          onKeyDown={() => setIsOpenLog((prev) => !prev)}
          onClick={() => setIsOpenLog((prev) => !prev)}
        >
          <ArrowDown />
        </div>
        <div
          style={{ flexDirection: 'column', minWidth: '90px', width: '15%' }}
          className={`${classes.tagsContainer} ${classes.startebyContainer}`}
        >
          <span className={classes.name}>{t('MigrationTasks.StartedBy')}:</span>
          <span className={classes.taskName}>{`${
            task.User.FirstName
          } ${task.User.LastName.slice(0, 3)}.`}</span>
        </div>
        <div style={{ width: '18%' }} className={classes.progressBarContainer}>
          <span className={classes.progressText}>{`${task.Percentage}%`}</span>
          <div className={classes.progress} style={Childdiv} />
        </div>
        <div style={{ width: '17%' }} className={classes.tagsContainer}>
          <HoverTooltip text={t('MigrationTasks.TaskStart')}>
            <StartDate />
          </HoverTooltip>
          <span className={classes.taskName}>
            {dayjs(task.CreateTime).format('MM/DD/YY H:mm')}
          </span>
        </div>
        <div style={{ width: '17%' }} className={classes.tagsContainer}>
          <HoverTooltip text={t('MigrationTasks.TaskEnd')}>
            <EndDate />
          </HoverTooltip>
          <span className={classes.taskName}>
            {task.Status === 2
              ? dayjs(task.UpdateTime).format('MM/DD/YY H:mm')
              : BackgroundTaskStatus[task.Status]}
          </span>
        </div>
        <div style={{ width: '25%' }} className={classes.tagsContainer}>
          <HoverTooltip text={t('MigrationTasks.Status')}>
            <div style={{ width: '20px' }}>
              <Status />
            </div>
          </HoverTooltip>
          <span className={classes.taskName}>
            {BackgroundTaskStatus[task.Status]}
          </span>
        </div>
      </div>
      {isOpenLog && (
        <div className={classes.logs}>
          <span className={classes.taskName}>{task.Log && task.Log}</span>
        </div>
      )}
    </>
  );
};

export default MigrationTasks;
