import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './CancelModal.module.css';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface CancelModalProps {
  onClickCancelModal: (isCancel: boolean, continueEditing: boolean) => void;
}

const CancelModal: FC<CancelModalProps> = ({ onClickCancelModal }) => {
  const [t] = useTranslation('global');
  const cancelModalRef = useRef<any>(null);

  useOnClickOutside(cancelModalRef, () => onClickCancelModal(false, false));
  return (
    <div className={classes.CancelModal}>
      <div className={classes.CancelModalContent} ref={cancelModalRef}>
        <h3 className={classes.Title}>{t('CancelModal.discard')}</h3>
        <button
          className={classes.DiscardButton}
          type="button"
          data-testid="cancel-button"
          onClick={() => {
            if (onClickCancelModal) {
              onClickCancelModal(true, true);
            }
          }}
        >
          {t('CancelModal.Cancel')}
        </button>
        <button
          className={classes.Continue}
          type="button"
          onClick={() => {
            if (onClickCancelModal) {
              onClickCancelModal(false, false);
            }
          }}
        >
          {t('CancelModal.continue')}
        </button>
      </div>
    </div>
  );
};

export default CancelModal;
