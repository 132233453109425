import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { AdditionalTagResponse } from '../../../../types/candidates';
import { errorHandler, successHandler } from '../../../utils';
import {
  AdditionalTagBuilder,
  SaveAdditionalTagInput,
  SaveAdditionalTagOutput,
} from '../additional-tag.types';

export const saveAdditionalTag = (builder: AdditionalTagBuilder) =>
  builder.mutation<SaveAdditionalTagOutput, SaveAdditionalTagInput>({
    query: ({ Name, candidateId }) => ({
      url: '/candidate-additional-tag/',
      method: 'POST',
      body: {
        Name,
        CandidateId: candidateId,
      },
    }),
    invalidatesTags: [{ type: 'additionalTags', id: 'LIST' }],
    transformResponse(response: AdditionalTagResponse, meta, { lang }) {
      successHandler(
        lang === 'es'
          ? SuccessMessageEs.AdditionalTagAdded
          : SuccessMessageEn.AdditionalTagAdded,
        true
      );
      return {
        Id: response.Id,
        title: response.Name,
      };
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[SAVE_ADDITIONAL_TAGS]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
