import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { successHandler, errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  RemoveCandidateFromPositionInput,
  RemoveCandidateFromPositionOuput,
} from '../candidates.types';

export const removeCandidateFromPosition = (builder: CandidateBuilder) =>
  builder.mutation<
    RemoveCandidateFromPositionOuput,
    RemoveCandidateFromPositionInput
  >({
    query: (args) => ({
      url: '/candidate-position-status/remove-candidate',
      method: 'DELETE',
      body: {
        removeCandidate: args.data,
      },
    }),
    transformResponse: (res, meta, args) => {
      successHandler(
        (args.lang ?? 'en') === 'en'
          ? SuccessMessageEn.REMOVED_CANDIDATE_FROM_POSITION
          : SuccessMessageEs.REMOVED_CANDIDATE_FROM_POSITION,
        true
      );
      return args.data;
    },
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[addCandidateToPositionAction]',
        (args.lang ?? 'en') === 'es'
          ? ErrorMessageEs.CandidateDeletionError
          : ErrorMessageEn.CandidateDeletionError,
        err,
        true
      );
      return err.data;
    },
    invalidatesTags: () => [{ type: 'Candidates' }],
  });
