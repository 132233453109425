import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './ContentContainer.module.css';
import useWindowWidth from '../../hooks/useWindowWidth';
import AccountButton from '../AccountButton/AccountButton';
import { ReactComponent as LeftArrow } from '../../icons/LeftArrow.svg';
import Button from '../Buttons/Button';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { useAppSelector } from '../../hooks';

interface ContentContainerProps {
  leftFooter?: ReactNode;
  rightFooter: ReactNode;
  headerStyling?: {};
  goBackTo?: () => void;
}

const defaultProps = {
  leftFooter: <div />,
  headerStyling: {},
  goBackTo: undefined,
};

const ContentContainer: FC<ContentContainerProps> = ({
  leftFooter,
  rightFooter,
  children,
  headerStyling,
  goBackTo,
}) => {
  const isMobile: boolean = useWindowWidth() < 768;
  const mediumWidth: boolean = useWindowWidth() < 992;
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const [openMenu, setOpenMenu] = useState(false);
  const [t] = useTranslation('global');
  const navigate = useNavigate();

  const handleMenu = () => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname.includes('landing') ||
      window.location.pathname === '/es' ||
      window.location.pathname === '/en'
    ) {
      navigate('/login', { replace: true });
    } else {
      setOpenMenu(!openMenu);
    }
  };

  return (
    <div data-testid="content-container" className={classes.ContentContainer}>
      <div className={`${classes.HeaderContainer} ${headerStyling}`}>
        {!isMobile && isLoggedIn && (
          <>
            {goBackTo && (
              <Button
                text={t('referrals.ReturnToProfileBtn')}
                icon={LeftArrow}
                showText
                size={mediumWidth ? '15rem' : 'auto'}
                bgColor="var(--brand-blue-100)"
                styling={classes.GoToProfileBtn}
                onClick={() => navigate('/home', { replace: false })}
              />
            )}
            <AccountButton t={t} action={handleMenu} blue />
          </>
        )}
        {(window.location.pathname === '/' ||
          window.location.pathname.includes('landing') ||
          window.location.pathname === '/es' ||
          window.location.pathname === '/en') &&
          !isLoggedIn &&
          !isMobile && <AccountButton t={t} blue action={handleMenu} />}
        {openMenu && <DropdownMenu setModal={setOpenMenu} />}
      </div>
      <div
        style={{
          alignItems:
            window.location.pathname === '/referrals' && !isMobile
              ? 'flex-start'
              : 'center',
        }}
        className={classes.Content}
      >
        {children}
      </div>
      {!isMobile && (
        <div className={classes.Footer}>
          {leftFooter}
          {rightFooter}
        </div>
      )}
    </div>
  );
};

ContentContainer.defaultProps = defaultProps;

export default ContentContainer;
