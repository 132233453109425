import { useTranslation } from 'react-i18next';
import { FC, useMemo, useState } from 'react';
import { userLanguage } from '$translations/index';
import classes from './CandidateProgressBar.module.css';
import { useGetCvQuery } from '../../store/modules/uploads';
import { useGetCandidateSkillsQuery } from '../../store/modules/candidate-skill';
import { useGetAdditionalVideosByCandidateIdQuery } from '../../store/modules/additional-videos';
import { useGetAdditionalDocumentsByCandidateIdQuery } from '../../store/modules/additional-documents';
import { useCandidateId } from '../../hooks/useCandidateId';
import ItemProgressBar from './ItemProgressBar/ItemProgressBar';
import CV from '../../icons/CV.svg';
import IconVideo from '../../icons/IconVideo.svg';
import MSInterview from '../../icons/MSInterview.svg';
import Reference from '../../icons/Reference.svg';
import UpdateSkills from '../../icons/UpdateSkills.svg';
import ProgressModal from './ProgressModal/ProgressModals';
import ProgressState from '../../types/ProgressState';
import { FileCategoryType } from '../../types/categoryType';

interface CandidateProgressBarProps {
  setHighlightedSection: React.Dispatch<React.SetStateAction<number>>;
  updateLinkClicked: (status: boolean) => void;
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenSplash: React.Dispatch<React.SetStateAction<boolean>>;
}

const CandidateProgressBar: FC<CandidateProgressBarProps> = ({
  setHighlightedSection,
  updateLinkClicked,
  setReady,
  setIsOpenSplash,
}) => {
  const [t, i18next] = useTranslation('global');
  const id = useCandidateId();
  const { data: cv } = useGetCvQuery({ candidateId: id });
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
  const [progressState, setProgressState] = useState<ProgressState>({});

  const candidateId: number = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;

  const { data: skills } = useGetCandidateSkillsQuery({
    candidateId: +candidateId!,
    lang: i18next.language as 'en' | 'es',
  });
  const { data: videos } = useGetAdditionalVideosByCandidateIdQuery({
    candidateId: id,
    lang: i18next.language as 'en' | 'es',
  });
  const { data: videosSigned } = useGetAdditionalVideosByCandidateIdQuery({
    candidateId,
    lang: userLanguage,
  });
  const { data: additionalDocuments } =
    useGetAdditionalDocumentsByCandidateIdQuery({
      lang: i18next.language as 'en' | 'es',
      candidateId: +candidateId!,
    });

  const checkInterview = useMemo(
    () =>
      cv?.Key !== undefined &&
      skills?.length !== 0 &&
      videos !== undefined &&
      videos?.length >= 3 &&
      additionalDocuments !== undefined &&
      additionalDocuments.filter(
        (file) => file.FileCategory.Id === FileCategoryType.REFERENCE
      ).length > 0,
    [cv, skills, videos, additionalDocuments]
  );
  return (
    <div
      className={`${classes.Container} ${
        checkInterview && classes.displayNone
      }`}
    >
      <ItemProgressBar
        title={t('candidateProgressBar.Upload')}
        section={1}
        icon={CV}
        check={cv?.Key !== undefined}
        handleSection={setHighlightedSection}
        updateLinkClicked={updateLinkClicked}
        setIsOpen={setIsProgressModalOpen}
        setProgressState={setProgressState}
        property="CVUpload"
      />
      <ItemProgressBar
        title={t('candidateProgressBar.updateSkills')}
        section={4}
        icon={UpdateSkills}
        check={skills?.length !== 0}
        handleSection={setHighlightedSection}
        updateLinkClicked={updateLinkClicked}
        setIsOpen={setIsProgressModalOpen}
        setProgressState={setProgressState}
        property="UpdateSkills"
      />

      <ItemProgressBar
        title={t('candidateProgressBar.recordVideo')}
        section={0}
        icon={IconVideo}
        check={
          videos !== undefined &&
          videosSigned !== undefined &&
          (videos?.length >= 1 || videosSigned?.length >= 1)
        }
        to="/video"
        setIsOpen={setIsProgressModalOpen}
        setProgressState={setProgressState}
        property="RecordVideo"
      />
      <ItemProgressBar
        title={t('candidateProgressBar.getReference')}
        section={0}
        icon={Reference}
        check={
          additionalDocuments !== undefined &&
          additionalDocuments.filter(
            (file) => file.FileCategory.Id === FileCategoryType.REFERENCE
          ).length > 0
        }
        setIsOpen={setIsProgressModalOpen}
        setProgressState={setProgressState}
        property="GetaReference"
        copy={
          process.env.REACT_APP_LINK_REFERENCE ??
          'https://docs.google.com/forms/d/e/1FAIpQLSc4FOS4TMegSz6F5Bei9zgPMOmreYKj1iRnAzxymG56McqNDg/viewform?usp=pp_url&entry.1874067299=*first_name*&entry.1798843663=*last_name*&entry.1393829874=*email*'
        }
      />
      <ItemProgressBar
        title={t('candidateProgressBar.completeAssessments')}
        icon={MSInterview}
        check={checkInterview}
        setIsOpen={setIsProgressModalOpen}
        setProgressState={setProgressState}
        section={10}
        property="CompleteAssessments"
      />
      {isProgressModalOpen && (
        <ProgressModal
          setIsOpen={setIsProgressModalOpen}
          isOpen={isProgressModalOpen}
          progressState={progressState}
          check={checkInterview}
          setReady={setReady}
          candidateId={id}
          setIsOpenSplash={setIsOpenSplash}
        />
      )}
    </div>
  );
};

export default CandidateProgressBar;
