import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import classes from './NewSplashPageModal.module.css';
import { ReactComponent as LogoBlue } from '../../../../images/logoBlue.svg';
import { segmentTrack } from '../../../../utils/handleSegment';

interface Props {
  modalIsOpen: boolean;
  setNewSplashPageModal: (arg: boolean) => void;
  candidateId: number;
}

const NewSplashPageModal = ({
  modalIsOpen,
  setNewSplashPageModal,
  candidateId,
}: Props) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const [t] = useTranslation('global');
  const list = [
    {
      step: 1,
      title: t('onboardingModalCandidate.Upload.title'),
      description: t('onboardingModalCandidate.Upload.description'),
    },
    {
      step: 2,
      title: t('onboardingModalCandidate.updateSkills.title'),
      description: t('onboardingModalCandidate.updateSkills.description'),
    },
    {
      step: 3,
      title: t('onboardingModalCandidate.recordVideo.title'),
      description: t('onboardingModalCandidate.recordVideo.description'),
    },
    {
      step: 4,
      title: t('onboardingModalCandidate.getReference.title'),
      description: t('onboardingModalCandidate.getReference.description'),
    },
    {
      step: 5,
      title: t('onboardingModalCandidate.completeAssessments.title'),
      description: t(
        'onboardingModalCandidate.completeAssessments.description'
      ),
    },
  ];
  const closeModal = () => {
    sessionStorage.setItem('isModalDisplayed', 'true');
    setNewSplashPageModal(false);
    segmentTrack(
      'Onboarding Modal Candidate Skipped',
      {
        id: candidateId,
      },
      ['role']
    );
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      ariaHideApp={false}
    >
      <div className={classes.Container}>
        <div className={classes.ContainerLogoMobile}>
          <LogoBlue className={classes.LogoMobile} />
          <p className={classes.DescriptionMobile}>
            {t('onboardingModalCandidate.mainDescription')}
          </p>
        </div>
        <div className={classes.List}>
          {list.map((item) => {
            return (
              <div className={classes.Item} key={item.step}>
                <div className={classes.ContainerNumber}>
                  <p className={classes.Number}>{item.step}</p>
                  <div className={classes.LineNumber} />
                </div>
                <div className={classes.ContainerText}>
                  <p className={classes.ContainerTextTitle}>{item.title}</p>
                  <p className={classes.ContainerTextDescription}>
                    {item.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes.Line} />
        <div className={classes.ContainerVideo}>
          <LogoBlue className={classes.Logo} />
          <p className={classes.Description}>
            {t('onboardingModalCandidate.mainDescription')}
          </p>
          <iframe
            id="video"
            className={classes.Video}
            title="YouTube video player"
            frameBorder="0"
            src="https://www.youtube.com/embed/sFMFRqSydtM?si=8oRYcv2yo_8eRlk1?autoplay=1"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          <button onClick={closeModal} className={classes.Skip} type="button">
            {t('onboardingModalCandidate.buttonSkip')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NewSplashPageModal;
