import { CandidateData } from '../../../../types/candidates';

const filterByPreviousExperience = (
  singleCandidate: CandidateData,
  searchedPreviousExperience: string[]
) => {
  return searchedPreviousExperience.every((searchedWorkExp) => {
    return singleCandidate.CandidateWorkExperience?.some((singleExperience) => {
      return (
        singleExperience.JobTitle &&
        singleExperience.JobTitle.toLowerCase().includes(
          searchedWorkExp.toLowerCase()
        )
      );
    });
  });
};

export default filterByPreviousExperience;
