import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { RedirectAfterLoginStateType } from '$types/LocationState';
import Spinner from '../Spinner/Spinner';
import RouteTypes from '../../types/routeTypes';
import TitleTypes from '../../types/TitleTypes';
import { useAppSelector } from '../../hooks';

interface AuthRouteProps {
  children: JSX.Element;
  type: RouteTypes;
}

// Route that requires authentication
const AuthRoute: FC<AuthRouteProps> = ({ children, type }) => {
  const {
    isLoggedIn,
    serverCandidate,
    serverEmployer,
    loading,
    serverInternal,
  } = useAppSelector((state) => state.auth);

  const location = useLocation();
  const state = location.state as RedirectAfterLoginStateType['state'];

  if (type === RouteTypes.CANDIDATE) {
    document.title = TitleTypes.Candidate;
  } else {
    document.title = TitleTypes.Default;
  }

  // if user needs to be logged in, return to login
  if (loading)
    return (
      <Spinner secondary={type === RouteTypes.INTERNAL || !!serverInternal} />
    );

  if (type === RouteTypes.PROTECTED) {
    return isLoggedIn ? children : <Navigate to="/login" />;
  }

  // Redirects back to /job-interview if candidate is not logged in
  if (
    !isLoggedIn &&
    !serverCandidate &&
    location.pathname === '/job-interview'
  ) {
    return <Navigate to="/" state={{ redirectTo: '/job-interview' }} />;
  }
  if (state?.redirectTo && isLoggedIn && serverCandidate)
    return <Navigate to={state.redirectTo} />;

  // We should redirect to forbidden page
  if (type === RouteTypes.CANDIDATE) {
    return isLoggedIn && serverCandidate ? children : <Navigate to="/" />;
  }

  if (type === RouteTypes.EMPLOYER) {
    return isLoggedIn && serverEmployer ? (
      children
    ) : (
      <Navigate to="/employer_login" />
    );
  }
  if (type === RouteTypes.INTERNAL) {
    return isLoggedIn && serverInternal ? (
      children
    ) : (
      <Navigate to="/internal_login" />
    );
  }

  // if user goes to login or register route but is already logged in go to home
  if (type === RouteTypes.AUTH && serverCandidate) {
    return isLoggedIn ? <Navigate to="/home" /> : children;
  }

  if (type === RouteTypes.AUTH && serverEmployer) {
    return isLoggedIn ? (
      <Navigate
        to="/employer-dashboard/positions"
        state={location?.state && { ...location?.state }}
      />
    ) : (
      children
    );
  }

  if (type === RouteTypes.AUTH && serverInternal) {
    return isLoggedIn ? <Navigate to="/internal-dashboard" /> : children;
  }
  // else go to home

  return isLoggedIn ? <Navigate to="/home" /> : children;
};

export default AuthRoute;
