export enum ReducerPaths {
  USERS = 'users',
  UPLOAD = 'upload',
  UPLOADS = 'uploads',
  COMPANY = 'company',
  POSITIONS = 'positions',
  COMPANIES = 'companies',
  EMPLOYERS = 'employers',
  CANDIDATES = 'candidates',
  FORM_INFO = 'formInfoApi',
  USER_INFO = 'userInfoApi',
  CANDIDATES_ES = 'candidatesEs',
  ADDITIONAL_TAG = 'additionalTag',
  WORK_EXPERIENCE = 'workExperience',
  CANDIDATE_SKILL = 'candidateSkill',
  CANDIDATE_LANGUAGE = 'candidateLanguage',
  CANDIDATE_EDUCATION = 'candidateEducation',
  ADDITIONAL_DOCUMENT = 'additionalDocument',
  CANDIDATE_JOB_INTEREST = 'candidateJobInterest',
  CANDIDATE_TECHNICAL_INFO = 'candidateTechnicalInfo',
  CANDIDATE_EXTRACURRICULAR = 'candidateExtracurricular',
  CANDIDATE_VIDEO_ANALYZER = 'candidateVideoAnalyzer',
  CANDIDATE_SCHEDULE = 'candidateSchedule',
  CANDIDATE_REACTIONS = 'candidateReactions',
  ADDITIONAL_VIDEO = 'additionalVideo',
  ADDITIONAL_VIDEO_SLICE = 'additionalVideoSlice',
  RATINGS = 'ratingsApi',
  VIDEO_FEEDBACKS = 'videoFeedbacksApi',
  POSITION_AS_GUEST = 'positionAsGuest',
  INTERVIEW = 'interviewApi',
}
