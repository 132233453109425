import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  candidatesApi,
  useHireCandidateMutation,
} from '../../../../../../store/modules/candidates';
import classes from './HireConfirmModalContent.module.css';
import { useAppDispatch } from '../../../../../../hooks';
import { companiesApi } from '../../../../../../store/modules/companies';
import { segmentTrack } from '../../../../../../utils/handleSegment';

type Props = {
  handleClose: (show: boolean) => void;
  candidatePositionStatusId: number;
  positionId: number;
  candidateArrayIndex: number;
  candidateId: number;
  setSwitchingId: (candidateId: number) => void;
};

const HireConfirmModalContent: FC<Props> = ({
  handleClose,
  candidatePositionStatusId,
  positionId,
  candidateArrayIndex,
  candidateId,
  setSwitchingId,
}) => {
  const [t, i18n] = useTranslation('global');
  const [hireCandidate] = useHireCandidateMutation();
  const dispatch = useAppDispatch();

  const handleConfirm = async () => {
    await hireCandidate({
      data: {
        candidatePositionStatusId,
        candidateArrayIndex,
        positionId,
        candidateId,
      },
      lang: i18n.language as 'en' | 'es',
    }).then(() => {
      segmentTrack('Candidate Hired', {
        candidate_id: candidateId,
        position_id: positionId,
      });
      dispatch(candidatesApi.util.invalidateTags(['Candidates']));
      dispatch(companiesApi.util.invalidateTags(['PositionsList']));
    });
    handleClose(false);
    setTimeout(() => {
      setSwitchingId(candidateId);
    }, 1000);
  };

  return (
    <>
      <strong>{t('HireCandidateModal.ConfirmTitle')}</strong>
      <p>{t('HireCandidateModal.ComfirmText')}</p>
      <p>{t('HireCandidateModal.ConfirmSubText')}</p>
      <div className={classes.actions}>
        <button
          type="button"
          className={classes.normalButton}
          onClick={() => handleClose(false)}
        >
          {t('HireCandidateModal.ConfirmBack')}
        </button>
        <button
          type="button"
          onClick={handleConfirm}
          className={classes.ConfirmBtn}
        >
          {t('HireCandidateModal.ConfirmButton')}
        </button>
      </div>
    </>
  );
};

export default HireConfirmModalContent;
