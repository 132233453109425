import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SimeraBlueLogo } from '../../../../icons/SimeraLogoBlue.svg';
import { ReactComponent as CloseX } from '../../../../icons/CloseX.svg';
import { ReactComponent as MailboxSvg } from '../../../../icons/MailboxBigIconInternal.svg';
import { resendConfirmationEmailAction } from '../../../../store/legacy/auth';
import classes from './InternalRegistrationModalContent.module.css';
import Button from '../../../Buttons/Button';
import { useAppDispatch } from '../../../../hooks';

type Props = {
  handleClose: (show: boolean) => void;
  internalEmail: string;
  resendConfirmationEmail: boolean | null;
};

const InternalRegistrationModalContent: FC<Props> = ({
  handleClose,
  internalEmail,
  resendConfirmationEmail,
}) => {
  const dispatch = useAppDispatch();

  const [t] = useTranslation('global');
  const resendEmailBtn = useRef<HTMLSpanElement>(null);
  const [disableEmailResendBtn, setDisableEmailResendBtn] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();
  const countdown = useRef<NodeJS.Timeout>();
  const [currentSecond, setCurrentSecond] = useState(60);

  const handleResendEmailConfirmation = () => {
    dispatch(resendConfirmationEmailAction(internalEmail));
  };

  useEffect(() => {
    if (resendConfirmationEmail) {
      setDisableEmailResendBtn(true);
      timeout.current = setTimeout(() => {
        setDisableEmailResendBtn(false);
      }, 60000);
    }
  }, [resendConfirmationEmail]);

  useEffect(() => {
    if (disableEmailResendBtn) {
      countdown.current = setInterval(() => {
        setCurrentSecond((prev) => prev - 1);
      }, 1000);
    }
    if (!disableEmailResendBtn && countdown.current) {
      clearInterval(countdown.current);
      setCurrentSecond(60);
    }
  }, [disableEmailResendBtn]);

  useEffect(() => {
    return () => {
      const timeoutRef = timeout.current;
      const c = countdown.current;
      if (timeoutRef) clearTimeout(timeoutRef);
      if (c) clearInterval(c);
    };
  }, []);

  return (
    <div className={classes.Container}>
      <header className={classes.ModalHeader}>
        <nav className={classes.ModalNav}>
          <SimeraBlueLogo width="143" height="35" />
          <Button
            icon={CloseX}
            iconStyles={{
              width: '14',
              height: '14',
              fill: '#909193',
            }}
            bgColor="transparent"
            showText={false}
            onClick={() => handleClose(false)}
            customStyles={{
              paddingLeft: '1rem',
              width: 'auto',
              flexGrow: '0',
              marginBottom: '0',
              height: 'auto',
            }}
          />
        </nav>
      </header>
      <div className={classes.ContentContainer}>
        <div>
          <div className={classes.MailboxSvgContainer}>
            <MailboxSvg />
          </div>
          <h2 className={classes.ModalTitle}>
            {t('employerRegistrationSuccess.title')}
          </h2>
          <p className={classes.Body}>
            {t('employerRegistrationSuccess.bodyPara1')} <br />
            <strong>{`${internalEmail}`}</strong>
          </p>
          <p className={classes.Body}>
            {t('employerRegistrationSuccess.bodyPara2')}
          </p>
        </div>
        <footer className={classes.Footer}>
          <p>{t('employerRegistrationSuccess.footerPara1')}</p>
          <p>
            {t('employerRegistrationSuccess.footerPara2')}{' '}
            <span
              className={`${classes.ResentEmailLink} ${
                disableEmailResendBtn ? classes.ResentEmailLinkDisabled : ''
              }`}
              role="button"
              onClick={handleResendEmailConfirmation}
              onKeyDown={handleResendEmailConfirmation}
              tabIndex={0}
              ref={resendEmailBtn}
            >
              {t('employerRegistrationSuccess.resendBtn')}
            </span>
            {disableEmailResendBtn && (
              <>
                {' '}
                <span>{currentSecond}</span>
              </>
            )}
          </p>
        </footer>
      </div>
    </div>
  );
};

export default InternalRegistrationModalContent;
