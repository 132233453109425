import {
  GetAllInterviewsInput,
  GetAllInterviewsOutput,
  InterviewBuilder,
} from '../interview.types';

export const getAllInterviews = (builder: InterviewBuilder) =>
  builder.query<GetAllInterviewsOutput, GetAllInterviewsInput>({
    query: (params) => {
      const { status, ...searchParams } = params;
      const searchQueryString = new URLSearchParams();
      if (status && status.length)
        status?.forEach((item: string) =>
          searchQueryString.append('status', item)
        );
      return {
        url: `/interview/all?${searchQueryString.toString()}`,
        method: 'GET',
        params: searchParams,
      };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.data.map((item) => ({
              type: 'JobInterview' as const,
              id: item.Id,
            })),
            { type: 'JobInterview' },
          ]
        : [{ type: 'JobInterview' }],
  });
