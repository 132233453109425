import { FC } from 'react';
import classes from './Button.module.css';

interface RoundedButtonProps {
  icon: FC;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const RoundedButton: FC<RoundedButtonProps> = ({ icon: Icon, onClick }) => {
  return (
    <button
      aria-label="rounded-button"
      data-testid="rounded-button"
      onClick={onClick}
      type="button"
      className={classes.btnRounded}
    >
      <Icon />
    </button>
  );
};

export default RoundedButton;
