import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import classes from './InternalSearchBar.module.css';
import { ReactComponent as SearchIcon } from '../../../../icons/SearchIcon.svg';
import MultipleTagSelector from '../../../MultipleTagSelector/MultipleTagSelector';
import FilterTypeSearchBar from '../FilterContent/FilterTypeSearchBar';
import { useGetJobPositionsQuery } from '../../../../store/modules/form-info';
import { useLoadCompaniesQuery } from '../../../../store/modules/companies';
import { AutocompleteOption } from '../../../../types/user_info';
import PositionInternalDto from '../../../../types/serverTypes/positionInternalDto';

type FieldValues = {
  terms: AutocompleteOption[];
};

interface Props {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  barType: FilterTypeSearchBar;
  allPositions: PositionInternalDto[];
}

const InternalSearchBar: FC<Props> = ({ setPage, barType, allPositions }) => {
  const [t] = useTranslation('global');
  const { data: jobPositions = [] } = useGetJobPositionsQuery({ t });
  const { data: companies } = useLoadCompaniesQuery({});
  const [searchParams, setSearchParmas] = useSearchParams();
  const query = useMemo(() => {
    const searchQuery = searchParams.get('query');

    return (
      (searchQuery
        ?.split(',')
        .map((Id) => {
          const company = companies?.find(
            (companyItem) => companyItem.Id === parseInt(Id, 10)
          );
          if (company) {
            return {
              Id: company.Id,
              title: company.Name,
            };
          }

          const jobPosition = jobPositions.find(
            (jobPositionItem) => jobPositionItem.Id === parseInt(Id, 10)
          );
          if (jobPosition) {
            return jobPosition;
          }

          return null;
        })
        .filter((item) => item !== null) as AutocompleteOption[]) ?? []
    );
  }, [companies, jobPositions, searchParams]);
  const { control, setValue } = useForm<FieldValues>({});

  const terms = useWatch({
    control,
    name: 'terms',
  });
  const filterRef = useRef(terms);
  useEffect(() => {
    if (filterRef.current !== terms) {
      filterRef.current = terms;

      setPage(1);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('query', terms.map(({ Id }) => Id).join(','));
      setSearchParmas(newSearchParams);
    }
  }, [searchParams, setPage, setSearchParmas, terms]);
  const options = () => {
    if (barType === FilterTypeSearchBar.JOB_ID) {
      return allPositions
        ? allPositions.reduce<any>((acc, item) => {
            if (item.Status === 'Closed') {
              return acc;
            }
            acc.push({ Id: item.Id, title: item.Id.toString() });
            return acc;
          }, [])
        : [];
    }
    return barType === FilterTypeSearchBar.COMPANY
      ? companies?.map(({ Id, Name }) => ({
          Id,
          title: Name,
        })) ?? []
      : (jobPositions &&
          jobPositions.map(({ Id, title }) => ({
            Id,
            title,
          }))) ??
          [];
  };
  const placeholder = () => {
    if (barType === FilterTypeSearchBar.COMPANY) {
      return t('SimeraInternal.CompanySearchBarPlaceholder');
    }
    if (barType === FilterTypeSearchBar.JOB_POSITION) {
      return t('SimeraInternal.SearchByJobPositionPlaceholder');
    }
    return t('SimeraInternal.SearchByJobIdPlaceholder');
  };
  return (
    <div className={classes.searchBar}>
      <SearchIcon />
      <Controller
        control={control}
        name="terms"
        defaultValue={query}
        render={({ field }) => (
          <MultipleTagSelector
            placeholder={placeholder()}
            arrayName="terms"
            label=""
            options={options()}
            setValue={setValue}
            selectedTags={field.value ?? []}
            secondary
            isSearch
          />
        )}
      />
    </div>
  );
};

export default InternalSearchBar;
