import { FC, HTMLProps, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { Column, useTable } from 'react-table';
import { SaveState } from '../../types/ForrmType';
import capitalizedName from '../../utils/capitalizedName';
import CancelModal from '../CancelModal/CancelModal';
import FormFooter from '../FormFooter/FormFooter';
import classes from './DuplicateCustomRoster.module.css';
import {
  LoadRoasterOutput,
  useDuplicatePositionWithCustomRosterMutation,
} from '../../store/modules/companies';
import { useAppSelector } from '../../hooks';
import { RosterData } from '../../types/candidates';
import { usePositions } from '../../store/modules/companies/hooks/use-positions';

interface CustomRosterTableProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: LoadRoasterOutput;
}

const CustomRosterTable: FC<CustomRosterTableProps> = ({ setIsOpen, data }) => {
  const { activePosition } = useAppSelector((state) => state.company);

  const [t] = useTranslation('global');
  const [saveState, setSaveState] = useState(SaveState.IDLE);
  const [showCancel, setShowCancel] = useState(false);
  const [
    duplicatePositionWithCustomRoster,
    { isLoading, status, data: duplicatedPosition, isSuccess },
  ] = useDuplicatePositionWithCustomRosterMutation();

  const { addPositionAction } = usePositions();

  useEffect(() => {
    if (status === QueryStatus.fulfilled && duplicatedPosition && isSuccess) {
      addPositionAction(duplicatedPosition);
      setIsOpen(false);
    }
  }, [addPositionAction, duplicatedPosition, isSuccess, setIsOpen, status]);

  const candidatedCheckedInitialState = data?.map((candidate) => ({
    id: candidate.id,
    checked: false,
  }));

  const [candidatesChecked, setCandidatesChecked] = useState(
    candidatedCheckedInitialState
  );

  const columns: Column<RosterData>[] = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('DuplicateJob.Name')}`,
        accessor: 'name',
      },
      {
        Header: `${t('DuplicateJob.ActiveRosters')}`,
        accessor: 'activerosters',
      },
      {
        Header: `${t('DuplicateJob.ActiveInterviews')}`,
        accessor: 'activeinterviews',
      },
    ],
    [t]
  );
  const handleCancel = () => {
    setShowCancel(true);
  };
  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> & HTMLProps<HTMLFormElement>
  ) => {
    e.preventDefault();
    setSaveState(SaveState.PENDING);
    const selectedCandidates = candidatesChecked
      ?.filter((candidate) => candidate.checked)
      .map((candidate) => candidate.id);

    await duplicatePositionWithCustomRoster({
      position: activePosition!,
      candidateId: selectedCandidates ?? [],
    });
    setSaveState(SaveState.IDLE);
    setIsOpen(false);
  };

  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setIsOpen(false);
    }

    setShowCancel(false);
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data: data ?? [],
    });

  return (
    <form onSubmit={handleSubmit}>
      <table {...getTableProps()} className={classes.Table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th
                aria-label="select all candidates"
                style={{
                  textAlign: 'center',
                }}
              >
                <input
                  type="checkBox"
                  className={classes.checkBox}
                  onChange={(e) => {
                    setCandidatesChecked((prev) => {
                      const newCandidatesChecked = prev?.map((candidate) => {
                        return {
                          ...candidate,
                          checked: e.target.checked,
                        };
                      });
                      return newCandidatesChecked;
                    });
                  }}
                />
              </th>
              {headerGroup.headers.map((column) => (
                <th
                  style={{
                    textAlign: 'center',
                  }}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                aria-label="clickable row"
                {...row.getRowProps()}
                className={classes.clickableRow}
              >
                <td
                  aria-label="select candidate"
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <input
                    type="checkBox"
                    className={classes.checkBox}
                    checked={(candidatesChecked ?? [])[row.index]?.checked}
                    onChange={() => {
                      setCandidatesChecked((prev) => {
                        const newCandidatesChecked = prev?.map((candidate) => {
                          if (candidate.id === row.original.id) {
                            return {
                              ...candidate,
                              checked: !candidate.checked,
                            };
                          }
                          return candidate;
                        });
                        return newCandidatesChecked;
                      });
                    }}
                  />
                </td>
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{
                        textAlign: 'center',
                      }}
                      {...cell.getCellProps()}
                    >
                      {typeof cell.value === 'string'
                        ? capitalizedName(cell.value)
                        : cell.value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ marginTop: '1rem' }}>
        <FormFooter
          translate={t}
          saveState={saveState}
          submit
          onClickCancel={handleCancel}
          disabled={isLoading}
          useMobile={false}
        />
      </div>
      {showCancel && handleCancelModal && (
        <CancelModal onClickCancelModal={handleCancelModal} />
      )}
    </form>
  );
};

export default CustomRosterTable;
