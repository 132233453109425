import { useEffect } from 'react';
import { ReactComponent as Close } from '$icons/CloseBlue.svg';

const PandaDocForm = ({ onClose }: { onClose: () => void }) => {
  useEffect(() => {
    const config = {
      nodeId: 'form-container-a54353f8-a557-4fe9-916a-28819e17b6f0',
      width: '100%',
      height: '700px',
      url: 'https://eform.pandadoc.com/?eform=d8ebc497-808c-4a42-b7eb-26b5797cc344',
      events: {
        loaded: () => {},
        started: () => {},
        completed: () => {},
        exception: () => {},
      },
      data: {},
    };

    // const dataQueryString = config.data
    //   ? Object.keys(config.data)
    //       .map(
    //         (key) =>
    //           `&${key}=${encodeURIComponent(JSON.stringify(config.data[key]))}`
    //       )
    //       .join('')
    //   : '';

    const iframe = document.createElement('iframe');
    iframe.src = config.url;

    const node = document.getElementById(config.nodeId);
    if (node) {
      node.style.height = config.height;
      node.style.width = config.width;
      iframe.height = '100%';
      iframe.width = '100%';
      node.appendChild(iframe);
    } else {
      iframe.height = config.height;
      iframe.width = config.width;
      document.body.appendChild(iframe);
    }

    const eventHandler = (e: any) => {
      if (
        e &&
        e.data &&
        config &&
        iframe &&
        e.source === iframe.contentWindow
      ) {
        try {
          const message = JSON.parse(e.data);
          if (message && message.event) {
            const event = message.event.replace('embed.form.', '');
            // @ts-ignore
            const callback = config.events ? config.events[event] : null;
            if (callback) {
              callback(message.data);
            }
          }
        } catch (error) {
          //   console.error(error);
        }
      }
    };

    window.addEventListener('message', eventHandler, false);

    return () => {
      window.removeEventListener('message', eventHandler, false);
    };
  }, []);

  return (
    <div className="container flex p-4 bg-white rounded overflow-hidden relative">
      <button
        type="button"
        className="z-10 absolute right-4"
        onClick={onClose}
        aria-label="Close"
      >
        <Close className="w-10 h-10" />
      </button>
      <div id="form-container-a54353f8-a557-4fe9-916a-28819e17b6f0" />
    </div>
  );
};

export default PandaDocForm;
