import { FC } from 'react';
import classes from './Tooltip.module.css';
import { ReactComponent as HelpIcon } from '../../icons/HelpIcon.svg';

interface IProps {
  children: React.ReactNode;
}

const Tooltip: FC<IProps> = ({ children }) => {
  return (
    <div
      className={`${classes.tooltip} icon-tt`}
      data-testid="tooltip-container"
    >
      <HelpIcon />
      <span className={classes.tooltiptext} data-testid="tooltip-children">
        {children}
      </span>
    </div>
  );
};

export default Tooltip;
