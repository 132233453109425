const capitalizedName = (name: string): string => {
  if (name.includes('/')) {
    return name?.toUpperCase();
  }
  const nameArr = name.split(' ');
  const reformed = nameArr.map((element) => {
    return `${element?.charAt(0)?.toUpperCase()}${element
      ?.slice(1)
      ?.toLowerCase()}`;
  });
  return reformed.join(' ');
};

export default capitalizedName;
