import { useContext, useEffect, useState } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ReactComponent as ArrowRight } from '../../../../../icons/ArrowRight.svg';
import classes from './Arrows.module.css';

const Arrow = ({
  children,
  disabled,
  onClick,
  left,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  left: boolean;
}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={`${classes.arrowContainer} ${
        left ? classes.leftArrow : classes.rightArrow
      }`}
      style={{
        opacity: disabled ? '0' : '1',
      }}
    >
      {children}
    </button>
  );
};

export const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()} left>
      <ArrowRight />
    </Arrow>
  );
};

export const RightArrow = () => {
  const {
    isLastItemVisible,
    scrollNext,
    visibleElements,
    scrollToItem,
    getItemElementById,
    items,
  } = useContext(VisibilityContext);

  const [, setDisabled] = useState(
    !visibleElements.length && isLastItemVisible
  );
  useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow
      disabled={false}
      onClick={() => {
        if (isLastItemVisible) {
          scrollToItem(getItemElementById(items.toArr()?.[0]?.[0])!);
        } else {
          scrollNext();
        }
      }}
      left={false}
    >
      <ArrowRight />
    </Arrow>
  );
};
