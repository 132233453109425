import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../utils/baseQuery';
import { getAdditionalTags } from './queries/get-additional-tags.query';
import { getFileCategories, getJobPositions, getSkillsQuery } from './queries';
import { ReducerPaths } from '../../constants/reducerPaths';

export const formInfoApi = createApi({
  reducerPath: ReducerPaths.FORM_INFO,
  baseQuery,
  endpoints: (builder) => ({
    getJobPositions: getJobPositions(builder),
    getSkills: getSkillsQuery(builder),
    getFileCategories: getFileCategories(builder),
    getAdditionalTags: getAdditionalTags(builder),
  }),
});

export const {
  useGetJobPositionsQuery,
  useGetSkillsQuery,
  useGetFileCategoriesQuery,
  useGetAdditionalTagsQuery,
} = formInfoApi;
