import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoBlue } from '$icons/SimeraLogoBlue.svg';
import Spinner from '$components/Spinner/Spinner';

interface ModalDocumentPDFProps {
  filePDF: string | null;
  error: boolean;
  loading: boolean;
  closeModal: () => void;
}

const ModalDocumentPDF = ({
  filePDF,
  error,
  loading,
  closeModal,
}: ModalDocumentPDFProps) => {
  const [t] = useTranslation('global');
  return (
    <div className="flex flex-col w-full h-full bg-white">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex justify-between items-center bg-white h-14 px-4 md:px-8">
            <LogoBlue className=" h-10" />
            <button
              type="button"
              className="h-12 px-4 font-semibold text-brand-blue100"
              onClick={closeModal}
            >
              {t('modalDocumentPdf.close')}
            </button>
          </div>

          <div className="flex flex-1">
            {error && (
              <div className="flex flex-1 justify-center items-center">
                {t('modalDocumentPdf.error')}
              </div>
            )}
            {filePDF && (
              <object
                data={filePDF}
                type="application/pdf"
                className="flex flex-1"
              >
                <embed
                  src={filePDF}
                  type="application/pdf"
                  className="flex flex-1"
                />
              </object>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ModalDocumentPDF;
