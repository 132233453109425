import { ChangeEvent, FC, useEffect, useState } from 'react';
import { UseFormRegisterReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ShareReferenceLocationState } from '$types/LocationState';
import { NameRegex, emailRegex, phoneRegex } from '../../constants/regex';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useRegisterForm from '../../hooks/useRegisterForm';
import { ReactComponent as GoogleIcon } from '../../icons/GoogleColorIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../icons/LinkedInBlueIcon.svg';
import { ReactComponent as EyeIcon } from '../../icons/PasswordEye.svg';
import { ReactComponent as SimeraBlueLogo } from '../../icons/SimeraLogoBlue.svg';
import {
  emailAuthAction,
  employerEmailAuthAction,
  employerGoogleAuthAction,
  employerLinkedInAuthAction,
  googleAuthAction,
  linkedInAuthAction,
} from '../../store/legacy/auth';
import Button from '../Buttons/Button';
import RoundedButton from '../Buttons/RoundedButton';
import BaseInput from '../Inputs/BaseInput';
import classes from './RegisterForm.module.css';
import ResendEmailModal from './ResendEmailModal/ResendEmailModal';
import SelectCountry from './SelectCountry/SelectCountry';
import SelectPlatformReferral from './SelectPlatformReferral/SelectPlatformReferral';

interface RegisterFormProps {
  employerRegister?: boolean;
}

const defaultProps = {
  employerRegister: false,
};

interface FormTypes {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  companyName?: string;
  companyURL?: string;
  phone: string;
  residenceCountry?: string;
  platformReferral?: string;
}

const RegisterForm: FC<RegisterFormProps> = ({ employerRegister }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormTypes>({ mode: 'onChange' });
  const [t, i18next] = useTranslation('global');
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { version } = useAppSelector((state) => state.auth);
  const [dialCode, setDialCode] = useState('+54');
  const location = useLocation() as ShareReferenceLocationState;
  const passwordWatch = watch('password', '');
  const { isMobile, authState, passStrength, passwordValidator } =
    useRegisterForm();

  const passColors = [
    'white',
    '#f44336',
    'purple',
    'blueviolet',
    'cyan',
    '#05668D',
  ];

  useEffect(() => {
    if (authState.isLoggedIn) {
      if (employerRegister) navigate('/employer-dashboard');
      else navigate('/home');
    }
  }, [authState.isLoggedIn, navigate, employerRegister]);

  useEffect(() => {
    if (!authState.loading && authState.employer?.employerRegisterSuccess) {
      setShowModal(true);
    }
  }, [authState.loading, authState.employer?.employerRegisterSuccess]);

  // Form declaration
  const firstName = register('firstName', {
    required: t('register.nameInputValid').toString(),
    maxLength: {
      value: 100,
      message: t('register.invalidFirstNameInput').toString(),
    },
    pattern: {
      value: NameRegex,
      message: t('register.invalidFirstNameInput').toString(),
    },
  });
  const lastName = register('lastName', {
    required: t('register.lastNameInputValid').toString(),
    maxLength: {
      value: 100,
      message: t('register.invalidLastNameInput').toString(),
    },
    pattern: {
      value: NameRegex,
      message: t('register.invalidLastNameInput').toString(),
    },
  });
  const email = register('email', {
    required: t('register.emailInputValid').toString(),
    maxLength: {
      value: 100,
      message: t('register.invalidEmailInput').toString(),
    },
    pattern: {
      value: emailRegex,
      message: 'Invalid email',
    },
  });
  const phone = register('phone', {
    pattern: {
      value: phoneRegex,
      message: t('register.phoneInputValid'),
    },
    setValueAs: (value) => {
      if (value) {
        return value.replace(/\s/g, '');
      }
      return value;
    },
  });

  const password = register('password', {
    validate: {
      validPass: (val) =>
        passwordValidator(val) >= 1 || 'Must contain at least 6 characters',
    },
  });
  const confirmPassword = register('confirmPassword', {
    required: t('register.confirmPasswordValid').toString(),
    validate: {
      passwordEqual: (value) =>
        value === getValues().password || t('register.matchPass').toString(),
    },
  });

  let companyName: UseFormRegisterReturn | undefined;
  let companyURL: UseFormRegisterReturn | undefined;
  if (employerRegister) {
    companyName = register('companyName', {
      required: t('register.companyRequired').toString(),
    });
    companyURL = register('companyURL');
  }
  if (!employerRegister) {
    register('residenceCountry', {
      required: t('register.countryRequired').toString(),
    });
    register('platformReferral');
  }

  const submitForm = (values: FormTypes) => {
    if (employerRegister) {
      const { companyName: companyNameField } = values;
      const { companyURL: companyURLField } = values;
      if (companyNameField) {
        dispatch(
          employerEmailAuthAction(
            {
              firstName: values.firstName.trim().replace(/\s\s+/g, ' '),
              lastName: values.lastName.trim().replace(/\s\s+/g, ' '),
              email: values.email.toLowerCase().trim(),
              password: values.password,
              shareReferenceId: location?.state?.shareReferenceId,
            },
            {
              CompanyName: companyNameField.trim(),
              CompanyURL: companyURLField?.trim(),
            },
            i18next.language
          )
        );
      }
    } else {
      dispatch(
        emailAuthAction(
          {
            firstName: values.firstName.trim().replace(/\s\s+/g, ' '),
            lastName: values.lastName.trim().replace(/\s\s+/g, ' '),
            email: values.email.toLowerCase().trim(),
            password: values.password,
            phone: `${dialCode} ${values.phone}`,
            residenceCountry: values?.residenceCountry,
            platformReferral: values?.platformReferral,
          },
          i18next.language,
          version ?? 'v1'
        )
      );
    }
  };

  const onGoogleSignIn = () => {
    if (employerRegister) {
      dispatch(
        employerGoogleAuthAction(
          {
            CompanyName: 'Google Sign in (No company name)',
            ShareReferenceId: location?.state?.shareReferenceId,
          },
          i18next.language
        )
      );
    } else {
      dispatch(googleAuthAction(undefined, i18next.language, version ?? 'v1'));
    }
  };

  const onLinkedInSignIn = () => {
    if (employerRegister) {
      dispatch(
        employerLinkedInAuthAction(
          {
            CompanyName: 'LinkedIn Sign in (No company name)',
            ShareReferenceId: location?.state?.shareReferenceId,
          },
          i18next.language
        )
      );
    } else {
      dispatch(
        linkedInAuthAction(undefined, i18next.language, version ?? 'v1')
      );
    }
  };

  const closeModal = (): void => {
    setShowModal(false);
  };
  const buttonColor = employerRegister
    ? 'var(--brand-blue-4)'
    : 'var(--brand-blue-100)';

  return (
    <>
      <ResendEmailModal
        showModal={showModal}
        closeModal={closeModal}
        isMobile={!!isMobile}
        authState={authState}
      />
      <form
        id="register-form"
        data-testid="register-form"
        className={classes.Form}
        onSubmit={handleSubmit(submitForm)}
        style={{
          WebkitFilter:
            !isMobile && showModal ? 'blur(1.5px)' : '' /* Safari 6.0 - 9.0 */,
          filter: !isMobile && showModal ? 'blur(1.5px)' : '',
          zIndex: showModal && isMobile ? '-10' : '1',
        }}
      >
        <div className={classes.AlignCenter}>
          <SimeraBlueLogo />
        </div>
        <div className={classes.InputsContainer}>
          <div className={classes.NameInputs}>
            <BaseInput
              onChange={(e: ChangeEvent) => firstName.onChange(e)}
              name={firstName.name}
              onBlur={firstName.onBlur}
              refProp={firstName.ref}
              label={t('register.nameInput')}
              type="text"
              errorText={errors.firstName?.message}
              placeholder={t('common.typeHere')}
              isRequired
            />
            <BaseInput
              onChange={(e: ChangeEvent) => lastName.onChange(e)}
              name={lastName.name}
              onBlur={lastName.onBlur}
              refProp={lastName.ref}
              label={t('register.lastNameInput')}
              type="text"
              errorText={errors.lastName?.message}
              placeholder={t('common.typeHere')}
              isRequired
            />
          </div>
          {!employerRegister && (
            <>
              <div className={classes.companyContainer}>
                <BaseInput
                  onChange={(e: ChangeEvent) => email.onChange(e)}
                  name={email.name}
                  onBlur={email.onBlur}
                  refProp={email.ref}
                  label={t('register.emailInput')}
                  type="text"
                  errorText={errors.email?.message}
                  placeholder={t('common.typeHere')}
                  isRequired
                />
                <BaseInput
                  onChange={(e: ChangeEvent) => email.onChange(e)}
                  name={phone.name}
                  onBlur={phone.onBlur}
                  refProp={phone.ref}
                  label={t('register.phone')}
                  type="text"
                  errorText={errors.phone?.message}
                  placeholder="1234567890"
                  phone
                  setDialCode={setDialCode}
                />
              </div>
              <SelectCountry
                label={t('infoContainer.ResidenceCountry')}
                placeholder={t('infoContainer.ResidenceCountryPlaceholder')}
                setValue={setValue}
                errorText={errors.residenceCountry?.message}
              />
            </>
          )}
          {employerRegister && companyName && (
            <div className={classes.companyContainer}>
              <BaseInput
                onChange={(e: ChangeEvent) => email.onChange(e)}
                name={email.name}
                onBlur={email.onBlur}
                refProp={email.ref}
                label={t('register.emailInput')}
                type="text"
                errorText={errors.email?.message}
                placeholder={t('common.typeHere')}
                isRequired
              />
              <BaseInput
                onChange={(e: ChangeEvent) => {
                  if (companyName) return companyName.onChange(e);
                  return null;
                }}
                name={companyName.name}
                onBlur={companyName.onBlur}
                refProp={companyName.ref}
                label={t('register.companyName')}
                type="text"
                errorText={errors.companyName?.message}
                placeholder={t('common.typeHere')}
                isRequired
              />
            </div>
          )}
          {employerRegister && companyURL && (
            <BaseInput
              onChange={(e: ChangeEvent) => {
                if (companyURL) return companyURL.onChange(e);
                return null;
              }}
              name={companyURL.name}
              onBlur={companyURL.onBlur}
              refProp={companyURL.ref}
              label={t('register.companyURL')}
              type="text"
              errorText={errors.companyURL?.message}
              placeholder={t('common.typeHere')}
            />
          )}
          <BaseInput
            onChange={(e: ChangeEvent) => password.onChange(e)}
            name={password.name}
            onBlur={password.onBlur}
            refProp={password.ref}
            label={t('register.passwordInput')}
            type="password"
            errorText={errors.password?.message}
            placeholder={t('common.typeHere')}
            icon={EyeIcon}
            isPassword
            isRequired
          />
          <div className={classes.passContainer}>
            <div className={classes.ProgressBarContainer}>
              <div
                style={{
                  width: `${(passStrength / 4) * 100}%`,
                  background: passColors[passStrength],
                }}
                className={classes.ProgressBar}
              />
            </div>
            {passwordWatch.length >= 1 && (
              <p className={classes.passStrength}>
                {t('register.passStrength')}
                {t(`register.strength${passStrength}`)}
              </p>
            )}
          </div>
          <BaseInput
            onChange={(e: ChangeEvent) => confirmPassword.onChange(e)}
            name={confirmPassword.name}
            onBlur={confirmPassword.onBlur}
            refProp={confirmPassword.ref}
            label={t('register.confirmPassword')}
            type="password"
            errorText={errors.confirmPassword?.message}
            placeholder={t('common.typeHere')}
            icon={EyeIcon}
            isPassword
            isRequired
          />
          {!employerRegister && (
            <SelectPlatformReferral
              label={t('register.platformReferral.label')}
              placeholder={t('register.platformReferral.placeholder')}
              setValue={setValue}
            />
          )}
        </div>
        <div className={classes.CreateAccountButton}>
          <Button
            submit
            onClick={handleSubmit(submitForm)}
            text={t('register.createAccBtn')}
            bgColor={buttonColor}
            showText
          />
        </div>
        <div className={classes.OrFlex}>
          <div className={classes.OrTags}>
            <hr className={classes.ORLine} />
            <p className={classes.Or}>{t('common.or')}</p>
            <hr className={classes.ORLine} />
          </div>
        </div>
        <div data-testid="login-buttons" className={classes.ButtonFlex}>
          <RoundedButton icon={GoogleIcon} onClick={onGoogleSignIn} />
          <RoundedButton icon={LinkedInIcon} onClick={onLinkedInSignIn} />
        </div>
        <p className={classes.Account}>
          {t('register.alreadyHave')}
          <Link
            to={employerRegister ? '/employer_login' : '/login'}
            className={classes.SignUp}
          >
            &nbsp;{t('register.login')}
          </Link>
        </p>
      </form>
    </>
  );
};

RegisterForm.defaultProps = defaultProps;

export default RegisterForm;
