import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  DuplicatePositionEmployerInput,
  DuplicatePositionEmployerOutput,
} from '../companies.types';

export const duplicatePositionEmployer = (builder: CompanyBuilder) =>
  builder.mutation<
    DuplicatePositionEmployerOutput,
    DuplicatePositionEmployerInput
  >({
    query: ({ position }) => ({
      url: '/positions/duplicate',
      method: 'POST',
      body: position,
    }),
    transformResponse: (
      queryResponse: DuplicatePositionEmployerOutput,
      _,
      arg
    ) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEs.POSITION_DUPLICATED
          : SuccessMessageEn.POSITION_DUPLICATED,
        true
      );
      return queryResponse;
    },
    transformErrorResponse: (error, _, arg) => {
      errorHandler(
        '[duplicatePositionEmployer]',
        arg.lang === 'en'
          ? ErrorMessageEn.PositionDuplicateError
          : ErrorMessageEs.PositionDuplicateError,
        error,
        true
      );
    },
    invalidatesTags: () => [{ type: 'Company' }],
  });
