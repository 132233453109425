import React, { FC } from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RadioValues from '../../types/DuplicateRadioValue';
import { EditPositionDto } from '../../types/serverTypes/editPositionDto';
import classes from './DuplicatePosition.module.css';

interface Props {
  register: UseFormRegister<EditPositionDto>;
  errors: FieldErrors<EditPositionDto>;
}

const DuplicateRosterActions: FC<Props> = ({ register, errors }) => {
  const [t] = useTranslation('global');
  const duplicateRoster = register('RosterAction', {
    shouldUnregister: true,
    required: 'You must select an option',
  });

  return (
    <>
      <h2>Roster</h2>
      <div className={classes.duplicateSection}>
        <label className={classes.checkBox} htmlFor="duplicate-roster">
          <input
            className={classes.checkBoxInput}
            type="radio"
            name={duplicateRoster.name}
            ref={duplicateRoster.ref}
            onChange={(e) => {
              duplicateRoster.onChange(e);
            }}
            value={RadioValues.All}
            onBlur={duplicateRoster.onBlur}
            id="duplicate-roster"
          />
          {t('DuplicateJob.DuplicateRoster')}
        </label>
        <label className={classes.checkBox} htmlFor="duplicate-custom-roster">
          <input
            className={classes.checkBoxInput}
            type="radio"
            name={duplicateRoster.name}
            ref={duplicateRoster.ref}
            onChange={duplicateRoster.onChange}
            value={RadioValues.Custom}
            onBlur={duplicateRoster.onBlur}
            id="duplicate-custom-roster"
          />
          {t('DuplicateJob.SelectRoster')}
        </label>
        <label className={classes.checkBox} htmlFor="no-duplicate-roster">
          <input
            className={classes.checkBoxInput}
            type="radio"
            name={duplicateRoster.name}
            ref={duplicateRoster.ref}
            onChange={duplicateRoster.onChange}
            value={RadioValues.NoDuplicate}
            onBlur={duplicateRoster.onBlur}
            id="no-duplicate-roster"
          />
          {t('DuplicateJob.NoDuplicate')}
        </label>
      </div>
      <br />
      <p className={classes.errorText}>{errors.RosterAction?.message}</p>
    </>
  );
};

export default DuplicateRosterActions;
