import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useGetCandidateJobInterestQuery } from '../../store/modules/candidate-job-interest';
import EditableContainer from '../EditableContainer/EditableContainer';
import JobInterestsFormContainer from '../JobInterestsForm/JobInterestsFormContainer';
import JobInterestReadOnly from './JobInterestReadOnly';
import { useAppSelector } from '../../hooks';

interface Props {
  isActive: boolean;
}

const JobInterestsEditableContainer: FC<Props> = ({ isActive }) => {
  const [isEdit, setIsEdit] = useState(true);
  const [t, i18next] = useTranslation('global');
  const [show, setShow] = useState(false);
  const { serverUser } = useAppSelector((state) => state.auth);
  const { candidateId } = useParams();
  const isMobile = useWindowWidth() <= 650;

  const { isLoading, data: jobInterest } = useGetCandidateJobInterestQuery({
    candidateId: candidateId ?? serverUser?.Candidate?.Id!,
    lang: i18next.language as 'en' | 'es',
  });

  const onClickPencil = () => {
    if (isMobile && window) {
      window.scrollTo(0, 0);
    }
    setIsEdit(false);
  };

  const handleCancel = () => {
    setShow(true);
  };

  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    setShow(false);
  };

  return (
    <EditableContainer
      title={t('formNames.jobs')}
      isEditable={isEdit}
      data-testid="jobs-editableForm"
      onClickPencil={onClickPencil}
      isActive={isActive}
    >
      {!isEdit ? (
        <JobInterestsFormContainer
          isEdit
          setEdit={setIsEdit}
          onClickCancel={handleCancel}
          onClickCancelModal={handleCancelModal}
          show={show}
        />
      ) : (
        !isLoading &&
        jobInterest &&
        jobInterest?.JobInterest &&
        jobInterest.JobInterest.length > 0 && (
          <JobInterestReadOnly jobInterest={jobInterest} />
        )
      )}
    </EditableContainer>
  );
};

export default JobInterestsEditableContainer;
