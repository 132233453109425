import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Video } from '$store/modules/uploads';
import { segmentTrack } from '$utils/handleSegment';
import {
  CandidateAdditionalVideoType,
  CandidateDataVideo,
  ICandidateVideos,
} from '$types/candidates';
import { AdditionalVideo } from '$types/additional-video/additional-video';
import { setPlayedVideoData } from '$store/globals/LastPlayedVideo';
import { VideoTypes } from '$constants/VideoTypes';

interface VideoGenericElementProps
  extends React.VideoHTMLAttributes<HTMLVideoElement> {
  type?: string;
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>;
  videoData:
    | ICandidateVideos
    | CandidateDataVideo
    | Video
    | AdditionalVideo
    | CandidateAdditionalVideoType;
}

/** Transforms the duration of the video into minutes and seconds */
const secondsToMinutes = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const leftOverSecs = Math.floor(seconds % 60);
  const formattedSeconds =
    leftOverSecs < 10 ? `0${leftOverSecs}` : leftOverSecs;
  return `${minutes}:${formattedSeconds}`;
};

/** Transforms the currentTime into the total percentage watched */
const percentageViewedBeforePause = (pausedTime: number, duration: number) => {
  return ((pausedTime * 100) / duration).toFixed(2);
};

/**
 * Generic <video> element with segment tracks
 */
const VideoGenericElement = ({
  type,
  videoRef,
  videoData,
  ...defaultVideoProps
}: VideoGenericElementProps) => {
  const [isSeeking, setIsSeeking] = useState(false);
  const [pauseTime, setPauseTime] = useState(0);
  const defaultTrackingVideoData: {
    video_id?: number;
    videoId?: number;
    video_type?: string;
    title?: string;
  } = {};
  const dispatch = useDispatch();
  let normalizedUrl = '';
  if (videoData) {
    if ('url' in videoData && videoData.url) {
      normalizedUrl = videoData.url;
    } else if ('Url' in videoData && videoData.Url) {
      normalizedUrl = videoData.Url as string;
    }

    if ('Id' in videoData && videoData?.Id !== undefined) {
      defaultTrackingVideoData.video_id = videoData.Id;
    }

    if ('type' in videoData && typeof videoData.type === 'number') {
      defaultTrackingVideoData.video_type = 'Video';
      defaultTrackingVideoData.title = VideoTypes[videoData!.type];
    } else if (
      videoData &&
      'Type' in videoData &&
      typeof videoData.Type === 'number'
    ) {
      defaultTrackingVideoData.video_type = 'Video';
      defaultTrackingVideoData.title = VideoTypes[videoData!.Type];
    } else if ('Name' in videoData) {
      defaultTrackingVideoData.video_type = 'AdditionalVideo';
      defaultTrackingVideoData.title = videoData.Name;
    }
  }

  const handlePlayWithSegmentTrack = (
    event: React.SyntheticEvent<HTMLVideoElement>
  ) => {
    const videoElement = event.target as HTMLVideoElement;
    if (Math.trunc(videoElement.currentTime) === 0) {
      segmentTrack('Video Content Started', {
        ...defaultTrackingVideoData,
        duration: secondsToMinutes(videoElement.duration),
      });
    } else if (
      (!isSeeking &&
        Math.trunc(pauseTime) === Math.trunc(videoElement.currentTime)) ||
      Math.trunc(pauseTime) === Math.trunc(videoElement.currentTime) + 1 // sometimes the play time started in the next second, example: pause time 7.9954 and playtime 8.0141
    ) {
      segmentTrack('Video Content Unpaused', {
        ...defaultTrackingVideoData,
        duration: secondsToMinutes(videoElement.duration),
      });
    }
    dispatch(
      setPlayedVideoData({
        data: defaultTrackingVideoData,
        element: videoRef?.current,
      })
    );
    if (defaultVideoProps.onPlay) defaultVideoProps.onPlay(event);
  };

  const handlePauseWithSegmentTrack = (
    event: React.SyntheticEvent<HTMLVideoElement>
  ) => {
    const videoElement = event.target as HTMLVideoElement;
    if (!videoElement.ended && !isSeeking) {
      segmentTrack('Video Content Paused', {
        ...defaultTrackingVideoData,
        duration: secondsToMinutes(videoElement.duration),
        playback_progress: percentageViewedBeforePause(
          videoElement.currentTime,
          videoElement.duration
        ),
      });
      setPauseTime(videoElement.currentTime);
    }

    if (defaultVideoProps.onPause) defaultVideoProps.onPause(event);
  };

  const handleEndedWithSegmentTrack = (
    event: React.SyntheticEvent<HTMLVideoElement>
  ) => {
    const videoElement = event.target as HTMLVideoElement;

    segmentTrack('Video Content Completed', {
      ...defaultTrackingVideoData,
      duration: secondsToMinutes(videoElement.duration),
    });

    if (defaultVideoProps.onEnded) defaultVideoProps.onEnded(event);
  };

  const handleOnSeeking = () => {
    setIsSeeking(true);
  };

  const handleOnSeeked = () => {
    setIsSeeking(false);
  };

  return (
    <video
      disablePictureInPicture
      {...defaultVideoProps}
      ref={videoRef}
      onPlay={handlePlayWithSegmentTrack}
      onPause={handlePauseWithSegmentTrack}
      onEnded={handleEndedWithSegmentTrack}
      onSeeked={handleOnSeeked}
      onSeeking={handleOnSeeking}
    >
      <track kind="captions" />
      <source id="videoInternal" src={normalizedUrl} type={type} />
    </video>
  );
};

VideoGenericElement.defaultProps = {
  type: 'video/mp4',
  videoRef: null,
};

export default VideoGenericElement;
