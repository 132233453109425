import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  UpdateToOfferSentConfirmationInput,
  UpdateToOfferSentConfirmationOutput,
} from '../companies.types';

export const updateToOfferSentConfirmation = (builder: CompanyBuilder) =>
  builder.mutation<
    UpdateToOfferSentConfirmationOutput,
    UpdateToOfferSentConfirmationInput
  >({
    query: ({ options }) => ({
      url: '/candidate-position-status/make-offer',
      method: 'PATCH',
      body: {
        candidatePositionStatusId: options.candidatePositionStatusId,
        user: options.user,
      },
    }),
    transformResponse: (
      queryResponse: UpdateToOfferSentConfirmationOutput,
      _,
      arg
    ) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEs.MAKE_OFFER_EMAIL_SUCCESS
          : SuccessMessageEn.MAKE_OFFER_EMAIL_SUCCESS,
        true
      );
      return queryResponse;
    },
    transformErrorResponse: (error, meta, args) => {
      errorHandler(
        '[updateCandidatePositionStatusToOfferSentAction]',
        args.lang === 'es'
          ? ErrorMessageEs.CandidatePositionStatusError
          : ErrorMessageEn.CandidatePositionStatusError,
        error,
        true
      );
      return error.data;
    },
    invalidatesTags: () => [{ type: 'Company' }],
  });
