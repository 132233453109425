import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { savePosition } from './mutations/save-position.mutation';
import {
  getAllPositions,
  getEmployeesByCompany,
  getPaginatedCompanies,
  getPApplications,
  getPositionFiltered,
  getPositionsBySkill,
  getPreviousApplications,
  loadCompanies,
  loadOnePosition,
  loadPositions,
  loadRoster,
} from './queries';
import {
  activatePosition,
  deactivatePosition,
  deletePosition,
  duplicatePositionEmployer,
  duplicatePositionInternal,
  duplicatePositionWithCustomRoster,
  editPosition,
  requestInterviews,
  trackingHiredCandidates,
  updateCompany,
} from './mutations';
import { editPositionInternal } from './mutations/edit-position-internal.mutation';
import { ReducerPaths } from '../../constants/reducerPaths';
import { updateToOfferSentConfirmation } from './mutations/update-to-offer-sent-confirmation.mutation';
import { rejectCandidateFromPositionEmployee } from './mutations/reject-candidate-from-position-employee.mutation';
import { CompaniesState } from './companies.types';
import PositionDto from '../../../types/serverTypes/positionDto';
import PositionStatus from '../../../constants/PositionStatus';
import { getTraceId } from '../../utils/traceId';

const customParamsSerializer = (params: Record<string, any>) => {
  const serializedParams = Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map((v) => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`)
          .join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return serializedParams;
};

export const companiesApi = createApi({
  reducerPath: ReducerPaths.COMPANIES,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('id_token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      const segmentTraceId = getTraceId();
      if (segmentTraceId) {
        headers.set('x-trace-id', segmentTraceId);
      }

      return headers;
    },
    paramsSerializer: customParamsSerializer,
  }),
  keepUnusedDataFor: 240,
  refetchOnMountOrArgChange: 30,
  refetchOnReconnect: true,
  tagTypes: ['Company', 'PositionsList'],
  endpoints: (builder) => ({
    savePosition: savePosition(builder),
    editPosition: editPosition(builder),
    editPositionInternal: editPositionInternal(builder),
    loadPositions: loadPositions(builder),
    requestInterviews: requestInterviews(builder),
    loadCompanies: loadCompanies(builder),
    updateCompanies: updateCompany(builder),
    loadOnePosition: loadOnePosition(builder),
    getEmployeesByCompany: getEmployeesByCompany(builder),
    trackingHiredCandidates: trackingHiredCandidates(builder),
    getCompaniesPaginated: getPaginatedCompanies(builder),
    activatePosition: activatePosition(builder),
    deactivatePosition: deactivatePosition(builder),
    deletePosition: deletePosition(builder),
    loadRoaster: loadRoster(builder),
    duplicatePositionWithCustomRoster:
      duplicatePositionWithCustomRoster(builder),
    duplicatePositionInternal: duplicatePositionInternal(builder),
    duplicatePositionEmployer: duplicatePositionEmployer(builder),
    internalFilterPositions: getPositionFiltered(builder),
    updateToOfferSentConfirmation: updateToOfferSentConfirmation(builder),
    rejectCandidateFromPositionEmployee:
      rejectCandidateFromPositionEmployee(builder),
    getAllPositions: getAllPositions(builder),
    getPositionsBySkill: getPositionsBySkill(builder),
    getApplications: getPApplications(builder),
    getPreviousApplications: getPreviousApplications(builder),
  }),
});

const initialState: CompaniesState = {
  activePosition: null,
  positions: [],
};

export const companySlice = createSlice({
  name: ReducerPaths.COMPANY,
  initialState,
  reducers: {
    setActivePosition: (state, action) => {
      state.activePosition = action.payload;
    },
    concatPositions: (state, action: PayloadAction<PositionDto[]>) => {
      state.positions = state.positions.concat(action.payload);
    },
    setPositions: (state, action: PayloadAction<PositionDto[]>) => {
      state.positions = [...action.payload];
    },
    removePosition: (state, action: PayloadAction<number>) => {
      state.positions = state.positions.filter(
        (position) => position.Id !== action.payload
      );
    },
    deactivatePositionSlice: (state, action: PayloadAction<number>) => {
      state.positions = state.positions.map((position) =>
        position.Id === action.payload
          ? { ...position, Status: PositionStatus.Deactivated }
          : position
      );
    },
    activatePositionSlice: (state, action: PayloadAction<number>) => {
      state.positions = state.positions.map((position) =>
        position.Id === action.payload
          ? { ...position, Status: PositionStatus.Open }
          : position
      );
    },

    addPosition: (state, action: PayloadAction<PositionDto>) => {
      state.positions = [action.payload, ...state.positions];
    },
    updateStatePosition: (state, action: PayloadAction<PositionDto>) => {
      state.positions = state.positions.map((position) =>
        position.Id === action.payload.Id ? action.payload : position
      );
    },
  },
});

export const {
  useSavePositionMutation,
  useEditPositionMutation,
  useEditPositionInternalMutation,
  useLoadPositionsQuery,
  useRequestInterviewsMutation,
  useLoadCompaniesQuery,
  useUpdateCompaniesMutation,
  useLoadOnePositionQuery,
  useGetEmployeesByCompanyQuery,
  useGetCompaniesPaginatedQuery,
  useActivatePositionMutation,
  useDeactivatePositionMutation,
  useDeletePositionMutation,
  useLoadRoasterQuery,
  useDuplicatePositionWithCustomRosterMutation,
  useDuplicatePositionInternalMutation,
  useDuplicatePositionEmployerMutation,
  useInternalFilterPositionsQuery,
  useLazyInternalFilterPositionsQuery,
  useUpdateToOfferSentConfirmationMutation,
  useRejectCandidateFromPositionEmployeeMutation,
  useTrackingHiredCandidatesMutation,
  useGetAllPositionsQuery,
  useGetPositionsBySkillQuery,
  useGetApplicationsQuery,
  useGetPreviousApplicationsQuery,
} = companiesApi;

export const {
  setActivePosition,
  addPosition,
  concatPositions,
  removePosition,
  updateStatePosition,
  setPositions,
  deactivatePositionSlice,
  activatePositionSlice,
} = companySlice.actions;
