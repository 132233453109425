export const filterNames = {
  skillsMatchs: 'SkillsMatch',
  additionalTagsMatch: 'AdditionalTagsMatch',
  additionalTags: 'AdditionalTags',
  educationLevel: 'EducationLevel',
  files: 'Files',
  languages: 'Languages',
  skills: 'Skills',
  englishLevel: 'EnglishLevel',
  id: 'Id',
  previousExperience: 'PreviousExperience',
  minSalary: 'MinSalary',
  maxSalary: 'MaxSalary',
  query: 'Query',
  name: 'Name',
  email: 'Email',
  yearsOfExperience: 'YearsOfExperience',
};
