import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '$store/constants/reducerPaths';
import { baseQuery } from '$store/utils/baseQuery';
import {
  getAllCandidatesReactions,
  getSingleCandidateReactions,
} from './queries';
import { deleteReaction, sendReaction } from './mutations';

export const candidateReactionsApi = createApi({
  reducerPath: ReducerPaths.CANDIDATE_REACTIONS,
  tagTypes: ['candidateReactions'],
  baseQuery,
  endpoints: (builder) => ({
    getAllCandidatesReactions: getAllCandidatesReactions(builder),
    getSingleCandidateReactoions: getSingleCandidateReactions(builder),
    sendReaction: sendReaction(builder),
    deleteReaction: deleteReaction(builder),
  }),
});

export const {
  useGetAllCandidatesReactionsQuery,
  useGetSingleCandidateReactoionsQuery,
  useSendReactionMutation,
  useDeleteReactionMutation,
} = candidateReactionsApi;
