import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from '../../../../../icons/ArrowRight.svg';
import classes from './JobPosition.module.css';
import PositionDto from '../../../../../types/serverTypes/positionDto';

interface Props {
  job: PositionDto;
}

dayjs.extend(relativeTime);

const JobPosition = ({ job }: Props) => {
  const navigate = useNavigate();
  const [t] = useTranslation('global');

  return (
    <div className={classes.container}>
      <h3 className={classes.title}>{job.JobPosition.title}</h3>
      <div className={classes.infoContainer}>
        <p className={classes.info}>
          <span>{t('JobDashboard.JobSuggestions.Experience')}</span>
          {`${job.ExperienceYears} ${
            job.ExperienceYears === 1
              ? t('JobDashboard.JobSuggestions.Year')
              : t('JobDashboard.JobSuggestions.Years')
          }`}
        </p>
        <p className={classes.info}>
          <span>{t('JobDashboard.JobSuggestions.SalaryRange')}</span>
          {`${job.SalaryLowerBound} - ${job.SalaryUpperBound}`}
        </p>
      </div>
      <span className={classes.time}>{`${dayjs(
        job.CreateTime
      ).fromNow()}`}</span>
      <button
        className={classes.button}
        type="button"
        onClick={() => navigate(`/job-dashboard/${job.Id}`)}
      >
        {t('JobDashboard.JobSuggestions.ViewJob')} <Arrow />
      </button>
    </div>
  );
};

export default JobPosition;
