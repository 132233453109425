export const useOriginPath = () => {
  const originPathKey = 'originPath';

  const getOriginPath = () => {
    return localStorage.getItem(originPathKey);
  };

  const setOriginPath = (path: string): void => {
    localStorage.setItem(originPathKey, path);
  };

  return {
    getOriginPath,
    setOriginPath,
  };
};
