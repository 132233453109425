import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './FilterContent.module.css';
import FilterTypeSearchBar from './FilterTypeSearchBar';

interface Props {
  setOption: (option: FilterTypeSearchBar) => void;
}

const FilterContent: FC<Props> = ({ setOption }) => {
  const [t] = useTranslation('global');
  return (
    <ul className={classes.listOptions}>
      <li
        className={classes.option}
        onClick={() => setOption(FilterTypeSearchBar.COMPANY)}
        aria-hidden
      >
        <span>{t('SimeraInternal.Company')}</span>
      </li>
      <li
        className={classes.option}
        onClick={() => setOption(FilterTypeSearchBar.JOB_POSITION)}
        aria-hidden
      >
        <span>{t('SimeraInternal.JobPosition')}</span>
      </li>
      <li
        className={classes.option}
        onClick={() => setOption(FilterTypeSearchBar.JOB_ID)}
        aria-hidden
      >
        <span>{t('SimeraInternal.JobId')}</span>
      </li>
    </ul>
  );
};

export default FilterContent;
