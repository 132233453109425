import { ErrorMessageEn, ErrorMessageEs } from '$constants/alerts';
import { errorHandler } from '$store/utils';
import {
  DeleteVideoCommentInput,
  VideoFeedbackBuilder,
} from '../video-feedback.types';

export const deleteVideoComment = (builder: VideoFeedbackBuilder) =>
  builder.mutation<undefined, DeleteVideoCommentInput>({
    query: ({ commentId }) => ({
      url: `/feedbacks/${commentId}`,
      method: 'DELETE',
    }),
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[DeleteVideoFeedbackAction]',
        lang === 'es'
          ? ErrorMessageEs.ErrorRemovingFeedback
          : ErrorMessageEn.ErrorRemovingFeedback,
        error,
        true
      );
    },
    invalidatesTags: (baseQuery, meta, { videoId }) => [
      { type: 'videoFeedback', id: videoId },
    ],
  });
