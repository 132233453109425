import type { FC } from 'react';
import Modal from 'react-modal';

import classes from './PositionCardModal.module.css';

interface Props {
  modalIsOpen: boolean;
  handleClose: () => void;
  contentLabel: string;
}

const PositionCardModal: FC<Props> = ({
  modalIsOpen,
  handleClose,
  contentLabel,
  children,
}) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleClose}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      contentLabel={contentLabel}
      ariaHideApp={false}
    >
      {children}
    </Modal>
  );
};

export default PositionCardModal;
