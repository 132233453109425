import { FC, useCallback } from 'react';
import { useForm, UseFormSetValue, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  FieldTypes,
  FormTypes,
  InitialFormStateTypes,
} from '../../types/user_info';
import classes from './EducationalInformationForm.module.css';
import { SaveState } from '../../types/ForrmType';
import SaveButton from '../Buttons/SaveButton';
import EducationForm from './EducationForm';
import FormFooter from '../FormFooter/FormFooter';
import CancelModal from '../CancelModal/CancelModal';
import {
  useAddCandidateEducationInternalMutation,
  useAddCandidateEducationMutation,
} from '../../store/modules/candidate-education';
import { errorHandler, successHandler } from '../../store/utils';
import { candidatesApi } from '../../store/modules/candidates';
import { useAppDispatch } from '../../hooks';

interface EducationProps {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean, continueEditing: boolean) => void;
  show?: boolean;
  onClickSave: () => void;
  newEducation?: boolean;
  secondary?: boolean;
  countForms: number;
}

const formFields = {
  DegreeType: { val: '', mult: 1 },
  DegreeName: { val: '', mult: 1 },
  SchoolName: { val: '', mult: 1 },
  YearGraduated: { val: '', mult: 1 },
  Enrolled: false,
};

const currentDate = new Date();

const NewEducation: FC<EducationProps> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  onClickSave,
  newEducation,
  secondary,
  countForms,
}) => {
  const [t, i18next] = useTranslation('global');
  const { candidateId } = useParams();
  const dispatch = useAppDispatch();

  const [
    addCandidateEducationInternal,
    {
      isLoading: isLoadingInternal,
      isSuccess: isSuccessInternal,
      isError: isErrorInternal,
    },
  ] = useAddCandidateEducationInternalMutation();
  const [addCandidateEducation, { isLoading, isSuccess, isError }] =
    useAddCandidateEducationMutation();

  const loadingEducation = useCallback(() => {
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }
    if (isSuccess || isSuccessInternal) {
      return SaveState.FULFILLED;
    }
    if (isError || isErrorInternal) {
      return SaveState.FAILED;
    }
    return SaveState.IDLE;
  }, [
    isError,
    isErrorInternal,
    isSuccess,
    isSuccessInternal,
    isLoading,
    isLoadingInternal,
  ]);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: formFields });

  const { isDirty } = useFormState({ control });

  const saveInfo = async (data: { [x: string]: any }) => {
    if (countForms >= 4) {
      errorHandler(
        '[EducationalInformationForm]',
        'Educational information limit reached',
        null,
        true
      );
      return;
    }
    if (data) {
      try {
        if (Number(candidateId)) {
          addCandidateEducationInternal({
            candidateId: Number(candidateId),
            lang: i18next.language as 'en' | 'es',
            data: [data as FormTypes['education']],
          });
          dispatch(candidatesApi.util.invalidateTags(['Score']));
        } else {
          addCandidateEducation({
            lang: i18next.language as 'en' | 'es',
            data: [data as FormTypes['education']],
          });
          dispatch(candidatesApi.util.invalidateTags(['Score']));
        }
        if (setEdit) {
          setEdit(true);
        }
        if (newEducation) {
          onClickSave();
        }
        successHandler(t('common.saved'), true);
        window.analytics.track('Education form submitted');
      } catch (error) {
        // error
      }
    }
    // }
  };

  const renderFields = () => {
    const degreeType = register(`DegreeType.val`, {
      required: t('education.requiredDegreeType').toString(),
    });
    const degreeName = register(`DegreeName.val`, {
      required: t('education.requiredDegreeName').toString(),
    });
    const schoolName = register(`SchoolName.val`);
    const yearGraduated = register(`YearGraduated.val`, {
      validate: {
        lessThanToday: (value) =>
          (value &&
            parseInt(value, 10) <= currentDate.getFullYear() &&
            parseInt(value, 10) >= 1950) ||
          value === '' ||
          'Enter a valid year',
      },
    });
    const enrolled = register(`Enrolled`);

    return (
      <EducationForm
        idx={0}
        remove={() => {}}
        control={control}
        t={t}
        formId="1"
        formIndex={0}
        degreeType={degreeType}
        degreeName={degreeName}
        schoolName={schoolName}
        yearGraduated={yearGraduated}
        setValue={
          setValue as UseFormSetValue<
            | InitialFormStateTypes
            | {
                Id?: number;
                DegreeType: FieldTypes;
                DegreeName: FieldTypes;
                SchoolName: FieldTypes;
                YearGraduated: FieldTypes;
                Enrolled: boolean;
              }
          >
        }
        errors={errors}
        enrolled={enrolled}
        deleteId={0}
        removeUnsaved={() => {}}
        newEducation
        secondary={secondary}
      />
    );
  };

  return (
    <div style={{ height: '100%' }}>
      <form
        data-testid="form-container"
        onSubmit={handleSubmit(saveInfo)}
        className={classes.Container}
      >
        <div className={classes.CardContainer}>{renderFields()}</div>
        <div className={isEdit ? classes.ButtonContainer : ''}>
          {isEdit ? (
            <FormFooter
              onClickCancel={onClickCancel}
              disabled={loadingEducation() === SaveState.PENDING || !isDirty}
              saveState={isDirty ? SaveState.IDLE : loadingEducation()}
              translate={t}
              submit
            />
          ) : (
            <SaveButton
              disabled={loadingEducation() === SaveState.PENDING || !isDirty}
              state={isDirty ? SaveState.IDLE : loadingEducation()}
              t={t}
              submit
            />
          )}
        </div>
      </form>
      {show && onClickCancelModal && (
        <CancelModal onClickCancelModal={onClickCancelModal} />
      )}
    </div>
  );
};

NewEducation.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  newEducation: false,
  secondary: false,
};

export default NewEducation;
