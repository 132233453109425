import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { segmentTrack } from '$utils/handleSegment';
import { ReactComponent as MakeOffer } from '$icons/makeOffer.svg';
import { ReactComponent as Reschedule } from '$icons/reschedule.svg';
import { ReactComponent as Start } from '$icons/star.svg';
import useOnClickOutside from '$hooks/useOnClickOutside';
import ActiveValues from '$constants/ActiveValues';
import classes from './EmployerTakeActionsBtn.module.css';
import Button from '../../../Buttons/Button';
import {
  candidatesApi,
  useUpdateCandidatePositionStatusToOfferSentMutation,
  useUpdateCandidatePositionStatusMutation,
  StatusCandidatePositionOptions,
} from '../../../../store/modules/candidates';
import { useAppSelector, useAppDispatch } from '../../../../hooks';
import { companiesApi } from '../../../../store/modules/companies';

type EmployerActionsProps = {
  positionId: number;
  interviewAgainHandler: () => void;
  status: string | undefined;
  activeValue: ActiveValues;
  candidatePositionStatusId: number;
  existingOffer: boolean;
  setSwitchingId: () => void;
  candidateId: number;
};

const EmployerTakeActionsBtn = ({
  positionId,
  interviewAgainHandler,
  status,
  activeValue,
  candidatePositionStatusId,
  existingOffer,
  setSwitchingId,
  candidateId,
}: EmployerActionsProps) => {
  const EmployerActionsRef = useRef<HTMLDivElement>(null);
  const [t, i18n] = useTranslation('global');
  const { serverUser } = useAppSelector((state: any) => state.auth);
  const dispatch = useAppDispatch();
  const [updateCandidatePositionStatusToOfferSentAsCandidate] =
    useUpdateCandidatePositionStatusToOfferSentMutation();
  const [updateCandidatePositionStatus] =
    useUpdateCandidatePositionStatusMutation();
  const [openTakeActions, setOpenTakeActions] = useState(false);

  useOnClickOutside(EmployerActionsRef, () => {
    setOpenTakeActions(false);
  });

  const onMakeOfferHandler = () => {
    if (existingOffer) {
      toast(t(t('CandidatePositionSummaryCard.ExistingOffer')), {
        type: 'error',
        hideProgressBar: false,
      });
      setOpenTakeActions(false);
      return;
    }
    updateCandidatePositionStatusToOfferSentAsCandidate({
      data: {
        candidatePositionStatusId,
        user: {
          name: `${serverUser?.FirstName} ${serverUser?.LastName}`,
          email: serverUser?.Email!,
        },
      },
      lang: i18n.language as 'en' | 'es',
    }).then(() => {
      setSwitchingId();
      dispatch(candidatesApi.util.invalidateTags(['Candidates']));
      dispatch(companiesApi.util.invalidateTags(['PositionsList']));
      setOpenTakeActions(false);
      segmentTrack('Make Offer', {
        candidate_id: candidateId,
      });
    });
  };

  const changeCandidatePositionStatusHandler = (
    newStatus: StatusCandidatePositionOptions
  ) => {
    updateCandidatePositionStatus({
      data: {
        CandidatePositionStatusId: candidatePositionStatusId,
        Status: newStatus,
      },
      lang: i18n.language as 'en' | 'es',
    }).then(() => {
      setSwitchingId();
      dispatch(candidatesApi.util.invalidateTags(['Candidates']));
      dispatch(companiesApi.util.invalidateTags(['PositionsList']));
      setOpenTakeActions(false);
      if (newStatus === 'new') {
        segmentTrack(`Candidate Removed from Shortlist`, {
          candidate_id: candidateId,
          position_id: positionId,
        });
      } else if (newStatus === 'shortlisted') {
        segmentTrack(`Candidate Added to Shortlist`, {
          candidate_id: candidateId,
          position_id: positionId,
        });
      }
    });
  };

  const iconStyles = {
    width: '18',
    height: '18',
    fill: 'var(--black-75)',
  };

  const customStyles = {
    color: 'var(--brand-dark-grey)',
    height: 'auto',
    justifyContent: 'flex-start',
    textAlign: 'left',
    width: '100%',
    whiteSpace: 'nowrap',
    gap: '0.8rem',
  };

  return (
    <>
      <button
        data-testid="schedule-button"
        type="button"
        onClick={() => setOpenTakeActions(true)}
        className={classes.employerButton}
      >
        {t('CandidatePositionSummaryCard.TakeActions')}
      </button>
      {openTakeActions && typeof candidatePositionStatusId === 'number' && (
        <div
          ref={EmployerActionsRef}
          className={classes.employerActionsBtnContainer}
        >
          <ul>
            {activeValue === ActiveValues.New && (
              <li aria-hidden>
                <Button
                  text={t('EmployerPositions.ShortList')}
                  size="100%"
                  bgColor="none"
                  showText
                  icon={Start}
                  iconStyles={iconStyles}
                  customStyles={customStyles}
                  onClick={() =>
                    changeCandidatePositionStatusHandler('shortlisted')
                  }
                />
              </li>
            )}
            {activeValue === ActiveValues.ShortList &&
              status === 'shortlisted' && (
                <li aria-hidden>
                  <Button
                    text={t('EmployerPositions.RemoveShortList')}
                    size="100%"
                    bgColor="none"
                    showText
                    icon={Start}
                    iconStyles={iconStyles}
                    customStyles={customStyles}
                    onClick={() => changeCandidatePositionStatusHandler('new')}
                  />
                </li>
              )}
            <li aria-hidden>
              <Button
                text={t('EmployerPositions.MakeOffer')}
                size="100%"
                bgColor="none"
                showText
                icon={MakeOffer}
                iconStyles={iconStyles}
                customStyles={customStyles}
                onClick={onMakeOfferHandler}
              />
            </li>
            <li aria-hidden>
              <Button
                text={t('EmployerPositions.RequestInterview')}
                size="100%"
                bgColor="none"
                showText
                icon={Reschedule}
                iconStyles={iconStyles}
                customStyles={customStyles}
                onClick={() => {
                  interviewAgainHandler();
                  setOpenTakeActions(false);
                }}
              />
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default EmployerTakeActionsBtn;
