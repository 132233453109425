import { SaveState } from '../../../types/ForrmType';
import { AuthAction, AuthActionTypes, AuthState } from './auth.types';

const initialState: AuthState = {
  loading: true,
  error: false,
  errorMessage: '',
  isLoggedIn: false,
  version: 'v1',
  auth0Response: {},
  emailConfirmError: false,
  loadingConfirmEmail: SaveState.IDLE,
  employer: {
    employerRegisterSuccess: undefined,
    employerEmail: undefined,
  },
  internal: {
    internalRegisterSuccess: undefined,
    internalEmail: undefined,
  },
  resendConfirmationEmail: null,
  sessionExpiredInBackend: false,
};

export const authReducer = (
  state = initialState,
  action: AuthAction = { type: 'default' }
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.UPDATE_BASIC_INFO:
      return {
        ...state,
        serverUser: {
          ...state.serverUser!,
          FirstName: action?.candidateProfileDto?.FirstName!,
          LastName: action?.candidateProfileDto?.LastName!,
          Candidate: {
            ...state.serverUser?.Candidate!,
            ...action?.candidateProfileDto!,
          },
        },
      };
    case AuthActionTypes.EMAIL_AUTH_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case AuthActionTypes.EMAIL_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        isLoggedIn: true,
        auth0Response: action.combinedResponses?.auth0Response,
        serverUser: action.combinedResponses?.serverUserResponse,
        serverCandidate: action.combinedResponses?.serverCandidateResponse,
        serverEmployer: action.combinedResponses?.serverEmployerResponse,
        serverInternal: action.combinedResponses?.serverInternalResponse,
        sessionExpiredInBackend: false,
      };
    case AuthActionTypes.EMAIL_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.error?.toString(),
      };
    case AuthActionTypes.EMPLOYER_EMAIL_AUTH_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case AuthActionTypes.EMPLOYER_EMAIL_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        isLoggedIn: false,
        employer: {
          employerRegisterSuccess: true,
          employerEmail: action.employerEmail,
        },
      };
    case AuthActionTypes.EMPLOYER_EMAIL_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.error?.toString(),
        employer: {
          employerRegisterSuccess: false,
        },
      };
    case AuthActionTypes.INTERNAL_EMAIL_AUTH_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case AuthActionTypes.INTERNAL_EMAIL_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        isLoggedIn: false,
        internal: {
          internalRegisterSuccess: true,
          internalEmail: action.internalEmail,
        },
      };
    case AuthActionTypes.INTERNAL_EMAIL_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.error?.toString(),
        internal: {
          internalRegisterSuccess: false,
        },
      };
    case AuthActionTypes.EMAIL_LOGIN_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case AuthActionTypes.EMAIL_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        isLoggedIn: true,
        auth0User: action.auth0User,
        serverUser: action.combinedResponses?.serverUserResponse,
        serverCandidate: action.combinedResponses?.serverCandidateResponse,
        serverInternal: action.combinedResponses?.serverInternalResponse,
        serverEmployer: action.combinedResponses?.serverEmployerResponse,
        sessionExpiredInBackend: false,
      };
    case AuthActionTypes.EMAIL_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.error?.toString(),
      };
    case AuthActionTypes.INIT_AUTH_START:
      return {
        ...state,
        loading: true,
        error: false,
        isLoggedIn: false,
      };
    case AuthActionTypes.INIT_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        isLoggedIn: true,
        auth0Response: action.auth0Response,
        serverUser: action.combinedResponses?.serverUserResponse,
        serverCandidate: action.combinedResponses?.serverCandidateResponse,
        serverEmployer: action.combinedResponses?.serverEmployerResponse,
        serverInternal: action.combinedResponses?.serverInternalResponse,
        sessionExpiredInBackend: false,
      };
    case AuthActionTypes.INIT_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        isLoggedIn: false,
        errorMessage: action.error?.toString(),
      };
    case AuthActionTypes.GOOGLE_AUTH_START:
      return {
        ...state,
        isLoggedIn: false,
        loading: true,
        error: false,
      };
    case AuthActionTypes.GOOGLE_AUTH_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        error: false,
        errorMessage: '',
        auth0Response: action.combinedResponses?.auth0Response,
        auth0User: action.auth0User,
        serverUser: action.combinedResponses?.serverUserResponse,
        serverCandidate: action.combinedResponses?.serverCandidateResponse,
        serverInternal: action.combinedResponses?.serverInternalResponse,
        serverEmployer: action.combinedResponses?.serverEmployerResponse,
        sessionExpiredInBackend: false,
      };

    case AuthActionTypes.GOOGLE_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AuthActionTypes.LINKEDIN_AUTH_START:
      return {
        ...state,
        isLoggedIn: false,
        loading: true,
        error: false,
      };
    case AuthActionTypes.LINKEDIN_AUTH_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        error: false,
        errorMessage: '',
        auth0Response: action.combinedResponses?.auth0Response,
        auth0User: action.auth0User,
        serverUser: action.combinedResponses?.serverUserResponse,
        serverCandidate: action.combinedResponses?.serverCandidateResponse,
        serverInternal: action.combinedResponses?.serverInternalResponse,
        serverEmployer: action.combinedResponses?.serverEmployerResponse,
        sessionExpiredInBackend: false,
      };
    case AuthActionTypes.LINKEDIN_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AuthActionTypes.LOGOUT_START:
      return { ...state, loading: true, error: false, isLoggedIn: false };
    case AuthActionTypes.LOGOUT_SUCCESS:
      return { ...state, loading: false, error: false, auth0User: null };
    case AuthActionTypes.LOGOUT_FAIL:
      return { ...state, loading: false, error: true, auth0User: null };
    case AuthActionTypes.RESET_PASSWORD_AUTH_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case AuthActionTypes.RESET_PASSWORD_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
      };
    case AuthActionTypes.RESET_PASSWORD_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AuthActionTypes.UPDATE_VERSION:
      return {
        ...state,
        version: action.version,
      };
    case AuthActionTypes.CONFIRM_EMAIL_START:
      return {
        ...state,
        loading: true,
        emailConfirmError: false,
        loadingConfirmEmail: SaveState.PENDING,
      };
    case AuthActionTypes.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        emailConfirmError: false,
        loadingConfirmEmail: SaveState.FULFILLED,
        errorMessage: '',
      };
    case AuthActionTypes.CONFIRM_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        emailConfirmError: true,
        loadingConfirmEmail: SaveState.FAILED,
        errorMessage: action.error?.toString(),
      };
    case AuthActionTypes.RESEND_CONFIRMATION_EMAIL_START:
      return {
        ...state,
        loading: true,
      };
    case AuthActionTypes.RESEND_CONFIRMATION_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        resendConfirmationEmail: true,
      };
    case AuthActionTypes.RESEND_CONFIRMATION_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        resendConfirmationEmail: false,
        errorMessage: action.error?.toString(),
      };
    case AuthActionTypes.SESSION_EXPIRED_IN_BACKEND:
      return {
        ...state,
        isLoggedIn: false,
        error: false,
        auth0User: null,
        sessionExpiredInBackend: true,
      };
    case AuthActionTypes.UPDATE_CANDIDATE_SCORE:
      return {
        ...state,
        serverCandidate: state.serverCandidate && {
          ...state.serverCandidate,
          Score: action.score ?? 0,
        },
      };

    default:
      return state;
  }
};
