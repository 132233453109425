import { CandidateData } from '../../../../types/candidates';

const filterByYearsOfExperience = (
  singleCandidate: CandidateData,
  searchedYears: number[]
) => {
  return (
    singleCandidate.YearsOfExperience &&
    singleCandidate.YearsOfExperience >= searchedYears[0] &&
    singleCandidate.YearsOfExperience <= searchedYears[1]
  );
};

export default filterByYearsOfExperience;
