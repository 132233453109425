import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  ReferralBuilder,
  GetCandidatesReferralsInput,
  GetCandidatesReferralsOutput,
} from '../referrals.types';

export const getCandidatesReferrals = (builder: ReferralBuilder) =>
  builder.query<GetCandidatesReferralsOutput, GetCandidatesReferralsInput>({
    query: () => '/candidates/referrals',
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ Id }) => ({ type: 'Referral', Id } as const)),
            { type: 'Referral', Id: 'LIST' },
          ]
        : [{ type: 'Referral', Id: 'LIST' }],
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[getCandidatesReferrals]',
        (args.lang ?? 'en') === 'en'
          ? ErrorMessageEn.InfoFail
          : ErrorMessageEs.InfoFail,
        err,
        false
      );

      return err.data;
    },
  });
