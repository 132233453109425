import {
  GetInterviewsSlotsAsInternalInput,
  GetInterviewsSlotsAsInternalOutput,
  InterviewBuilder,
} from '../interview.types';

export const getInterviewSlotsAsInternal = (builder: InterviewBuilder) =>
  builder.query<
    GetInterviewsSlotsAsInternalOutput,
    GetInterviewsSlotsAsInternalInput
  >({
    query: ({ candidateId, candidatePositionStatus }) => ({
      url: `/interview/candidate/slots/${candidateId}?candidatePositionStatusId=${candidatePositionStatus}`,
      method: 'GET',
    }),
    providesTags: (result, meta, { candidateId }) => [
      { type: 'Slots', id: candidateId },
    ],
  });
