import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { successHandler, errorHandler } from '../../../utils';
import {
  AdditionalDocumentBuilder,
  DeleteAdditionalDocumentInput,
} from '../additional-document.types';

export const deleteAdditionalDocument = (builder: AdditionalDocumentBuilder) =>
  builder.mutation<void, DeleteAdditionalDocumentInput>({
    query: ({ fileId }) => ({
      url: `/upload/file/${fileId}`,
      method: 'DELETE',
    }),
    invalidatesTags: [{ type: 'additionalDocuments', id: 'LIST' }],
    transformResponse: (res, meta, { lang }) => {
      if (lang === 'en') {
        successHandler(SuccessMessageEn.AdditionalDocumentDeletedSuccess, true);
      } else {
        successHandler(SuccessMessageEs.AdditionalDocumentDeletedSuccess, true);
      }
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[AdditionalDocumentDeleteError]',
        lang === 'es'
          ? ErrorMessageEs.AdditionalDocumentDeleteError
          : ErrorMessageEn.AdditionalDocumentDeleteError,
        error,
        true
      );
    },
  });
