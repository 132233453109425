import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { successHandler, errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  UpdateCandidatePositionStatusToOfferSentInput,
  UpdateCandidatePositionStatusToOfferSentOutput,
} from '../candidates.types';

export const updateCandidatePositionStatusToOfferSent = (
  builder: CandidateBuilder
) =>
  builder.mutation<
    UpdateCandidatePositionStatusToOfferSentOutput,
    UpdateCandidatePositionStatusToOfferSentInput
  >({
    query: (args) => ({
      url: '/candidate-position-status/make-offer',
      method: 'PATCH',
      body: args.data,
    }),
    transformResponse: (res, meta, args) => {
      successHandler(
        (args.lang ?? 'en') === 'en'
          ? SuccessMessageEn.MAKE_OFFER
          : SuccessMessageEs.MAKE_OFFER,
        true
      );
      return args.data;
    },
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[updateCandidatePositionStatusToOfferSentAction]',
        (args.lang ?? 'en') === 'es'
          ? ErrorMessageEs.CandidatePositionStatusError
          : ErrorMessageEn.CandidatePositionStatusError,
        err,
        true
      );
      return err.data;
    },
    invalidatesTags: () => [{ type: 'CandidatePositionStatus' }],
  });
