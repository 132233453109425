import { CandidateData } from '../../../../types/candidates';

const filterByAdditionalTags = (
  singleCandidate: CandidateData,
  searchedTags: string[]
) => {
  return searchedTags.every((singleTag) => {
    return (
      (singleCandidate?.AdditionalTags &&
        singleCandidate.AdditionalTags.some((candidateAdditionalTag) => {
          return (
            candidateAdditionalTag?.Name &&
            candidateAdditionalTag?.Name.toLowerCase() ===
              singleTag.toLowerCase()
          );
        })) ??
      false
    );
  });
};

export default filterByAdditionalTags;
