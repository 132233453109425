import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageReadOnly from './LanguageReadOnly';
import LanguageFormContainer from '../LanguageForm/LanguageFormContainer';
import NewLanguage from './NewLanguage';
import EditableContainerInternal from '../EditableContainer/EditableContainerInternal';
import useInternalEditableContainer from '../../hooks/useInternalEditableContainer';
import { useGetCandidateLanguagesQuery } from '../../store/modules/candidate-language';
import CardSkeleton from '../Positions/CandidateSummaryCardList/CardSkeleton/CardSkeleton';

interface LanguageEditableContainerProps {
  isActive: boolean;
  secondary: boolean;
}

const LanguageEditableContainerInternal: FC<LanguageEditableContainerProps> = ({
  isActive,
  secondary,
}) => {
  const [t, i18next] = useTranslation('global');
  const { candidateId } = useParams();
  const { data, isLoading } = useGetCandidateLanguagesQuery({
    candidateId: +candidateId!,
    lang: i18next.language as 'en' | 'es',
  });

  const {
    newFormStep: newLanguage,
    handleCancel,
    handleCancelModal,
    show,
    onClickSave,
    onClickPencil,
    onClickPlus,
    isEdit,
    setIsEdit,
  } = useInternalEditableContainer();

  const newLangOrField = () => {
    if (isLoading)
      return <CardSkeleton hasProfilePicture={false} rows={2} padding={0} />;

    if (newLanguage) {
      return (
        <NewLanguage
          onClickCancel={handleCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={handleCancelModal}
          show={show}
          onClickSave={onClickSave}
          newLanguage
          countForms={data?.length || 1}
        />
      );
    }
    return (
      data &&
      data?.map((lang, idx) => (
        <LanguageReadOnly
          key={`languageEditable-${lang?.Id}`}
          language={lang}
          isLastOne={idx === data.length - 1}
        />
      ))
    );
  };

  return (
    <EditableContainerInternal
      title={t('formNames.languages')}
      isEditable={secondary && isEdit}
      onClickPencil={onClickPencil}
      onClickPlus={onClickPlus}
      data-testid="language-editableForm"
      newEdit={newLanguage}
      hasAddMore
      addMore={newLanguage ? t('formNames.language') : false}
      isActive={isActive}
    >
      {!isEdit ? (
        <LanguageFormContainer
          onClickCancel={handleCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={handleCancelModal}
          show={show}
          secondary={secondary}
        />
      ) : (
        newLangOrField()
      )}
    </EditableContainerInternal>
  );
};

export default LanguageEditableContainerInternal;
