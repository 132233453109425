import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CandidateSkillBuilder,
  DeleteCandidateSkillsInput,
} from '../candidate-skill.types';

export const deleteCandidateSkill = (builder: CandidateSkillBuilder) =>
  builder.mutation<void, DeleteCandidateSkillsInput>({
    query: ({ candidateId }) => ({
      url: `/candidate-skills/delete/${candidateId}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, { candidateId }) => [
      { type: 'candidateSkills', id: candidateId },
      { type: 'candidateSkills', id: 'LIST' },
      'Score',
    ],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[deleteSkill]',
        lang === 'es' ? ErrorMessageEs.DeleteError : ErrorMessageEn.DeleteError,
        error,
        true
      );
    },
    transformResponse(_data, meta, { lang }) {
      successHandler(
        lang === 'es'
          ? SuccessMessageEs.SuccessfullyDeleted
          : SuccessMessageEn.SuccessfullyDeleted,
        true
      );
    },
  });
