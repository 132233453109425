import { memo, useCallback } from 'react';
import classes from './HideVideo.module.css';
import { BaseCheckbox } from '../../../../UI';
import { ReactComponent as Eye } from '../../../../../icons/Eye.svg';
import { AdditionalVideo } from '../../../../../types/additional-video/additional-video';
import { Video } from '../../../../../store/modules/uploads';

interface HideVideoProps {
  showCheckbox: boolean;
  video: AdditionalVideo | Video;
  selectedVideos: number[];
  setShowVisibilityModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedVideos: React.Dispatch<React.SetStateAction<number[]>>;
}

export const HideVideo = memo(
  ({
    showCheckbox,
    video,
    selectedVideos,
    setShowVisibilityModal,
    setSelectedVideos,
  }: HideVideoProps) => {
    const handleVisibility = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setShowVisibilityModal(true);
      },
      [setShowVisibilityModal]
    );

    const handleCheckboxChange = useCallback(
      (isChecked: boolean) => {
        const videoId = video.Id;
        if (videoId) {
          if (!isChecked) {
            setSelectedVideos(selectedVideos.filter((id) => id !== videoId));
          } else {
            setSelectedVideos([...selectedVideos, videoId]);
          }
        }
      },
      [selectedVideos, setSelectedVideos, video.Id]
    );

    return !showCheckbox ? (
      <button
        aria-label="Show video"
        type="button"
        className={classes.visibleButton}
        onClick={(e) => handleVisibility(e)}
      >
        <Eye />
      </button>
    ) : (
      <div className={classes.checkbox}>
        {video.Id && (
          <BaseCheckbox
            id={video.Id}
            isChecked={selectedVideos.includes(video.Id)}
            onChange={handleCheckboxChange}
          />
        )}
      </div>
    );
  }
);
