export enum SkillLevel {
  Novice = 'Novice',
  Beginner = 'Advanced Beginner',
  Competent = 'Competent',
  Proficient = 'Proficient',
  Expert = 'Expert',
}

export const SkillLevelOptions = [
  { id: SkillLevel.Novice, text: SkillLevel.Novice },
  { id: SkillLevel.Beginner, text: SkillLevel.Beginner },
  { id: SkillLevel.Competent, text: SkillLevel.Competent },
  { id: SkillLevel.Proficient, text: SkillLevel.Proficient },
  { id: SkillLevel.Expert, text: SkillLevel.Expert },
];
