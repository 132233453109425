import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../Buttons/Button';
import BaseInput from '../Inputs/BaseInput';
import classes from './PasswordReset.module.css';
import { submitPasswordChangeAction } from '../../store/legacy/auth';
import { ReactComponent as BackArrow } from '../../icons/BackArrow.svg';
import { SaveState } from '../../types/ForrmType';
import { ReactComponent as EyeIcon } from '../../icons/PasswordEye.svg';
import Spinner from '../Spinner/Spinner';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { validateTokenAuthAction } from '../../store/legacy/password-reset/password-reset.actions';

interface IFormTypes {
  password: string;
  confirmPassword: string;
}

const PasswordResetForm = () => {
  const {
    register,
    formState: { errors },
    getValues,
    watch,
    handleSubmit,
  } = useForm<IFormTypes>({
    mode: 'onChange',
    defaultValues: { password: '', confirmPassword: '' },
  });
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    validateTokenStatus,
    passwordChangeStatus,
    token: passwordResetToken,
  } = useAppSelector((state) => state.passwordReset);
  const [t, i18n] = useTranslation('global');
  const [passStrength, setPassStrength] = useState(0);

  const passwordWatch = watch('password', '');

  const passColors = [
    'white',
    '#f44336',
    'purple',
    'blueviolet',
    'cyan',
    '#05668D',
  ];

  const passwordValidator = (pass: string) => {
    let strength = 0;
    if (!pass || pass.length === 0) {
      strength = 0;
      setPassStrength(0);
    }

    if (pass.length >= 6) {
      strength = 1;
    }
    if (pass.match(/^(?=.*\d)(?=.*[a-zA-Z]).{6,}/)) {
      // has at least 6 characters, and contains a number and letters
      strength = 2;
    }
    if (pass.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      // contains at least 8 letters, a number, a lowercase and uppercase letter
      strength = 3;
    }
    if (
      pass.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      )
    ) {
      // contains at least 8 letters, a number, a lowercase, uppercase and special character
      strength = 4;
    }

    setPassStrength(strength);
    return strength;
  };

  // Form declaration
  const password = register('password', {
    validate: {
      validPass: (val) =>
        passwordValidator(val) >= 1 || 'Must contain at least 6 characters',
    },
  });
  const confirmPassword = register('confirmPassword', {
    required: t('register.confirmPasswordValid').toString(),
    validate: {
      passwordEqual: (value) =>
        value === getValues().password || t('register.matchPass').toString(),
    },
  });

  useEffect(() => {
    const tokenQuery = new URLSearchParams(location.search).get('token');
    if (tokenQuery) {
      dispatch(
        validateTokenAuthAction({
          token: tokenQuery,
          timezoneOffset: new Date().getTimezoneOffset(),
          lang: i18n.language,
        })
      );
    }
  }, [location.search, dispatch, i18n.language]);

  const onSubmit = (values: IFormTypes) => {
    if (passwordResetToken) {
      dispatch(
        submitPasswordChangeAction({
          token: passwordResetToken,
          password: values.password,
          timezoneOffset: new Date().getTimezoneOffset(),
          lang: i18n.language,
        })
      );
    }
  };

  useEffect(() => {
    if (validateTokenStatus === SaveState.FAILED) {
      navigate('/login');
    }
  }, [validateTokenStatus, navigate]);

  useEffect(() => {
    if (passwordChangeStatus === SaveState.FULFILLED) {
      navigate('/reset_success');
    }
  }, [navigate, passwordChangeStatus]);

  return validateTokenStatus !== SaveState.FULFILLED ? (
    <Spinner />
  ) : (
    <form
      data-testid="password-reset-form"
      className={classes.PasswordResetForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={classes.InputsContainer}>
        <BaseInput
          onChange={(e: ChangeEvent) => password.onChange(e)}
          name={password.name}
          onBlur={password.onBlur}
          refProp={password.ref}
          label={t('resetPassword.label1')}
          type="password"
          errorText={errors.password?.message}
          placeholder={t('resetPassword.passwordPlaceholder1')}
          icon={EyeIcon}
          isPassword
        />
        <div className={classes.passContainer}>
          <div className={classes.ProgressBarContainer}>
            <div
              style={{
                width: `${(passStrength / 4) * 100}%`,
                background: passColors[passStrength],
              }}
              className={classes.ProgressBar}
            />
          </div>
          {passwordWatch.length >= 1 && (
            <p className={classes.passStrength}>
              {t('register.passStrength')}
              {t(`register.strength${passStrength}`)}
            </p>
          )}
        </div>
        <BaseInput
          onChange={(e: ChangeEvent) => confirmPassword.onChange(e)}
          name={confirmPassword.name}
          onBlur={confirmPassword.onBlur}
          refProp={confirmPassword.ref}
          label={t('resetPassword.label2')}
          type="password"
          errorText={errors.confirmPassword?.message}
          placeholder={t('resetPassword.passwordPlaceholder2')}
          icon={EyeIcon}
          isPassword
        />
      </div>

      <div className={classes.ButtonContainer}>
        <Button
          submit
          onClick={() => {}}
          text={t('resetPassword.submitBtn')}
          bgColor="#05668D"
          customStyles={{
            width: 'auto',
            flexGrow: '0',
            padding: '0 1rem',
          }}
          styling={{
            boxShadow:
              ' 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)',
          }}
          disabled={passwordChangeStatus === SaveState.PENDING}
          showText
        />
      </div>
      <div className={classes.back}>
        <div
          tabIndex={0}
          role="button"
          onClick={() => navigate('/login')}
          onKeyDown={() => navigate('/login')}
        >
          <span>
            <BackArrow style={{ marginRight: '10px' }} />
            {t('forgotPass.backToLogin')}
          </span>
        </div>
      </div>
    </form>
  );
};

export default PasswordResetForm;
