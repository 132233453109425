import {
  ErrorMessageEn,
  ErrorMessageEs,
} from '../../../../../constants/alerts';
import { errorHandler } from '../../../../utils';
import {
  CandidateVideoAnalyzerBuilder,
  GetCandidateVideoInfoInput,
  GetCandidateVideoInfoOutput,
} from '../../candidate-video-analyzer.types';

export const getCandidateVideoAnalyzer = (
  builder: CandidateVideoAnalyzerBuilder
) =>
  builder.query<GetCandidateVideoInfoOutput, GetCandidateVideoInfoInput>({
    query: ({ candidateId }) => {
      const url = new URLSearchParams();
      url.append('candidateId', candidateId.toString());
      return {
        url: `/upload/analyzed-videos`,
        method: 'GET',
        params: url,
      };
    },
    providesTags: () => ['candidateVideoAnalyzer'],
    transformErrorResponse: (error, meta, arg) => {
      errorHandler(
        '[candidateVideoAnalyzer]',
        arg.lang === 'es'
          ? ErrorMessageEs.loadPaginatedCompaniesError
          : ErrorMessageEn.loadPaginatedCompaniesError,
        error,
        true
      );
    },
  });
