import { ReducerPaths } from '$store/constants/reducerPaths';
import StatusCandidatePosition from '$types/serverTypes/StatusCandidatePosition';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export type InterviewBuilder = EndpointBuilder<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
  >,
  'InterviewStatus' | 'Slots' | 'JobInterview',
  ReducerPaths.INTERVIEW
>;

export type GetInterviewOutput = {
  Id: number;
  CreatedBy: string;
  UpdatedBy: string | null;
  CreateTime: string;
  UpdateTime: string;
  DeleteTime: string | null;
  StartDate: string;
  EndDate: string;
  Timezone: string;
  Interview: null | GetInterviewCandidateResponse;
};

export type GetInterviewCandidateResponse = {
  Id: number;
  CreateTime: string;
  UpdateTime: string;
  DeleteTime: null | string;
  Feedback: null | string;
  VideoInterviewUrl: null | string;
  InterviewTranscriptionUrl: null | string;
  InterviewScheduleUrl: null | string;
  InterviewDate: string;
  Timezone: string;
  CandidateAccepted: boolean;
  CandidateAcceptedTime: string;
  Status: InterviewSlotStatusTypes;
  CreatedBy: string;
  UpdatedBy: string;
};

export enum InterviewSlotStatusTypes {
  Draft = 'Draft',
  Requested = 'Requested',
  Scheduled = 'Scheduled',
  CancelledCandidate = 'Cancelled - Candidate',
  CancelledClient = 'Cancelled - Client',
  CompleteCandidateLate = 'Complete - Candidate late',
  CompleteClientLate = 'Complete - Client late',
  CompleteOnTime = 'Complete - On time',
  Rescheduled = 'Rescheduled',
  NeedToSchedule = 'Need to Schedule',
}

export type SetInterviewSlotInput = {
  schedules: {
    EndDate: string;
    StartDate: string;
    Timezone: string;
  }[];
  candidateId: number;
  candidatePositionStatusId: number;
};

export type SetInterviewSlotOutput = {
  Id: number;
  CreatedBy: string;
  UpdatedBy: null | string;
  CreateTime: string;
  UpdateTime: string;
  DeleteTime: null | string;
  StartDate: string;
  EndDate: string;
  Timezone: string;
};

export type DeleteInterviewSlotInput = {
  scheduleId: number;
};

export type ApplyInterviewSlotInput = {
  scheduleId: number;
  candidateId?: number;
};

export type ApplyInterviewSlotOutput = {
  Id: number;
  StartDate: string;
  EndDate: string;
  Timezone: string;
};

export type GetCandidateInterviewsOutput = {
  Id: number;
  InterviewDate: string | null;
  Timezone: string | null;
  Status: InterviewSlotStatusTypes;
  User: {
    Id: number;
    Employer: {
      Id: number;
      Company: {
        Id: number;
        Name: string;
      };
    };
  };
  CandidatePositionStatus: {
    Id: number;
    Status: string;
    Position: {
      Id: number;
      Name: string;
      Status: string;
      JobDescription: string;
      JobPosition: {
        Id: number;
        title: string;
      };
      ExperienceYears: number;
      Responsibilities: string;
      SalaryLowerBound: number;
      SalaryUpperBound: number;
    };
  };
  UserSchedules: {
    Id: number;
    StartDate: string;
    EndDate: string;
    Timezone: string;
    CreateTime: string;
  }[];
};

export type GetCandidateInterviewByCPSInput = {
  candidateId: number;
  candidatePositionStatus: number;
};

export type GetCandidateInterviewByCPSOutput = {
  Id: number;
  InterviewDate: string;
  CandidateAccepted: boolean;
  CandidateAcceptedTime: string;
  Status: InterviewSlotStatusTypes;
  UserSchedule: {
    Id: number;
    StartDate: string;
    EndDate: string;
    Timezone: string;
  };
};

export type GetAllInterviewsOutput = {
  data: {
    Id: number;
    CreateTime: string;
    InterviewDate: string;
    Timezone: string;
    Status: InterviewSlotStatusTypes;
    Candidate: {
      Id: number;
      User: {
        Id: number;
        FirstName: string;
        LastName: string;
        Email: string;
        Phone: string;
      };
    };
    User: {
      Id: number;
      Employer: {
        Id: number;
        Company: {
          Id: number;
          Name: string;
        };
      };
    };
    CandidatePositionStatus: {
      Id: number;
      Position: {
        Id: number;
        Name: string | '';
        JobPosition: {
          Id: number;
          title: string;
        };
      };
    };
  }[];
  meta: {
    page: number;
    take: number;
    itemCount: number;
    pageCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
  };
};

export type GetAllInterviewsInput = {
  search?: string;
  interviewDateMin?: string;
  interviewDateMax?: string;
  requestDateMin?: string;
  requestDateMax?: string;
  status?: string[];
  page: number;
  take: number;
};

export type ModifySingleInterviewStatusInput = {
  interviewId: number;
  status: string;
};

export type ModifySingleInterviewStatusOutput = {
  Id: number;
  Status: InterviewSlotStatusTypes;
  Candidate: {};
  CandidatePositionStatus: {};
  UpdatedBy: string;
  UpdateTime: string;
  CreatedBy: null;
};

export type GetInterviewsSlotsAsInternalInput = {
  candidateId: number;
  candidatePositionStatus: number;
};

export type GetInterviewsSlotsAsInternalOutput = {
  Id: number;
  InterviewDate: null | string;
  Timezone: null | string;
  Status: InterviewSlotStatusTypes;
  User: {
    Id: number;
    Employer: {
      Id: number;
      Company: {
        Id: number;
        Name: string;
      };
    };
  };
  CandidatePositionStatus: {
    Id: number;
    Status: StatusCandidatePosition;
    Position: {
      Id: number;
      Name: string;
      Status: StatusCandidatePosition;
      JobDescription: string;
      JobPosition: {
        Id: number;
        title: string;
      };
      ExperienceYears: number;
      Responsibilities: string;
      SalaryLowerBound: number;
      SalaryUpperBound: number;
    };
  };
  UserSchedules: {
    Id: number;
    StartDate: string;
    EndDate: string;
    Timezone: string;
    CreateTime: string;
  }[];
  ReservedUserSchedules: {
    Id: number;
    StartDate: string;
    EndDate: string;
    Timezone: string;
    CreateTime: string;
  }[];
}[];

export type InternalCreateAndSetInterviewInput = {
  interviewId: number;
  startDate: string;
  endDate: string;
  timezone: string;
};

export type CreateEmptyInterviewInput = {
  candidatePositionStatusId: number;
};
export type CreateEmptyInterviewOutput = {
  CandidateId: number;
  CandidatePositionStatusId: number;
  Status: InterviewSlotStatusTypes;
  Candidate: {
    Id: number;
  };
  User: {
    Id: number;
  };
  CandidatePositionStatus: {
    Id: number;
  };
  CreatedBy: string;
  UpdateTime: string;
  UpdatedBy: string | null;
  Id: number;
  CreateTime: string;
  DeleteTime: null | string;
}[];
