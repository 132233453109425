import { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ShowVideosModal.module.css';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import { ReactComponent as Close } from '../../../../../icons/CloseGreen.svg';
import { ReactComponent as ArrowRight } from '../../../../../icons/ArrowRight.svg';
import { useUpdateVisibilityAdditionalVideosMutation } from '../../../../../store/modules/additional-videos';
import { useUpdateVideoMutation } from '../../../../../store/modules/uploads';

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCheckbox: React.Dispatch<React.SetStateAction<boolean>>;
  selectedVideos: number[];
  setSelectedVideos: React.Dispatch<React.SetStateAction<number[]>>;
  selectedVideosType?: number[] | null;
  setSelectedVideosType?: React.Dispatch<React.SetStateAction<number[]>> | null;
}
export const ShowVideosModal: FC<Props> = ({
  setModal,
  setShowCheckbox,
  selectedVideos,
  setSelectedVideos,
  selectedVideosType,
  setSelectedVideosType,
}) => {
  const [t, i18next] = useTranslation('global');
  const parentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(parentRef, () => setModal(false));
  const [updateVisibilityAdditionalVideos, { isLoading }] =
    useUpdateVisibilityAdditionalVideosMutation();

  const [updateVideos] = useUpdateVideoMutation();

  const videos = useMemo(() => {
    return selectedVideos.map((id) => ({ Id: id, IsVisible: true }));
  }, [selectedVideos]);

  const videosType = useMemo(() => {
    if (selectedVideosType) {
      return selectedVideosType.map((id) => ({ Id: id, IsVisible: true }));
    }
    return [];
  }, [selectedVideosType]);

  const handleUpdate = async () => {
    await updateVisibilityAdditionalVideos({
      data: videos,
      lang: i18next.language as 'en' | 'es',
    });
    await updateVideos({
      data: videosType,
      lang: i18next.language as 'en' | 'es',
    });
    setShowCheckbox(false);
    setModal(false);
    const newArray = [...selectedVideos];
    newArray.splice(0, newArray.length);
    setSelectedVideos(newArray);
    if (selectedVideosType && setSelectedVideosType) {
      const newArrayType = [...selectedVideosType];
      newArrayType.splice(0, newArrayType.length);
      setSelectedVideos(newArrayType);
    }
  };

  return (
    <div
      className={classes.Container}
      onClick={() => setModal(false)}
      aria-hidden
      ref={parentRef}
    >
      <div
        className={classes.Centered}
        onClick={(e) => e.stopPropagation()}
        aria-hidden
      >
        <div className={classes.Content}>
          <div className={classes.HeaderContent}>
            <div className={classes.Header}>
              <p>{t('AdditionalVideos.modals.showVideos')}</p>
              <Close
                className={classes.Close}
                onClick={() => setModal(false)}
              />
            </div>
            <div className={classes.divider} />
          </div>

          <div className={classes.HideContainer}>
            <p className={classes.Text}>
              {t('AdditionalVideos.modals.showQuestion1')}
            </p>
            <div className={classes.ButtonContainer}>
              <button
                type="button"
                className={classes.Cancel}
                onClick={() => setModal(false)}
              >
                {t('AdditionalVideos.modals.cancel')}
              </button>
              <button
                type="button"
                className={classes.Yes}
                onClick={handleUpdate}
                disabled={isLoading}
              >
                {t('AdditionalVideos.modals.visible')}
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ShowVideosModal.defaultProps = {
  selectedVideosType: null,
  setSelectedVideosType: null,
};
