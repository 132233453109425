import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { successHandler } from '$store/utils';
import { ReactComponent as ShareIcon } from '$icons/share_link.svg';
// import { toast } from 'react-toastify';
import { ReactComponent as DeactivateIcon } from '../../../../icons/CloseRoundIcon.svg';
// import { ReactComponent as CopyLinkIcon } from '../../../../icons/CopyLink.svg';
import { ReactComponent as DuplicateIcon } from '../../../../icons/DuplicateIcon.svg';
import { ReactComponent as EditDark } from '../../../../icons/EditDark.svg';
import { ReactComponent as TrashIcon } from '../../../../icons/TrashIcon.svg';
import { ReactComponent as ActivateIcon } from '../../../../icons/verified.svg';
import PositionDto from '../../../../types/serverTypes/positionDto';
import PositionInternalDto from '../../../../types/serverTypes/positionInternalDto';
import Button from '../../../Buttons/Button';
import classes from './PositionCardMenuOptions.module.css';

type Props = {
  isInternal: boolean;
  position: PositionInternalDto | PositionDto;
  showMenuOptions: boolean;
  setOpenEditModal?: React.Dispatch<React.SetStateAction<boolean>>;
  handleShowModal: (selected: string) => void;
  handleOpenDuplicateModal: (show: boolean) => void;
  setShowMenuOptions: (showMenu: boolean) => void;
};

const PositionCardMenuOptions: FC<Props> = ({
  isInternal,
  position,
  showMenuOptions,
  setOpenEditModal,
  handleShowModal,
  handleOpenDuplicateModal,
  setShowMenuOptions,
}) => {
  const [t] = useTranslation('global');
  const isDeactivated = position?.Status === 'Deactivated';
  const isFilled = position?.Status === 'Closed';
  const baseUrl = window.location.origin;

  // const copyUrl = async () => {
  //   try {
  //     await navigator.clipboard.writeText(
  //       `${baseUrl}/job-dashboard/${position?.Id}`
  //     );
  //     toast(t('JobDashboard.ModalViewJob.alertCopySuccess'), {
  //       type: 'success',
  //       hideProgressBar: false,
  //     });
  //   } catch (error) {
  //     toast(t('JobDashboard.ModalViewJob.alertCopyError'), {
  //       type: 'error',
  //       hideProgressBar: false,
  //     });
  //   }
  // };

  const copySharePosition = async () => {
    if ('ShareReferenceId' in position) {
      await navigator.clipboard.writeText(
        `${baseUrl}/share/position/${position?.ShareReferenceId}`
      );
      successHandler('Share url successfully copied to your clipboard', true);
    }
  };

  return isInternal ? (
    <ul
      data-testid="menuOptions"
      className={`${classes.MenuOptions} ${
        showMenuOptions && classes.ExpandMenu
      } 
      }`}
    >
      {!isFilled &&
        (!isDeactivated ? (
          <li
            aria-hidden
            onClick={(e) => {
              e.stopPropagation();
              handleShowModal('Deactivate');
              setShowMenuOptions(false);
            }}
            className={`${classes.Option} ${
              Boolean(showMenuOptions) && classes.ShowOption
            }`}
          >
            <Button
              text={t('PositionCard.Deactivate')}
              size="100%"
              bgColor="none"
              showText
              icon={DeactivateIcon}
              iconStyles={{
                width: '18',
                height: '18',
                fill: 'var(--black-75)',
              }}
              customStyles={{
                color: 'var(--brand-dark-grey)',
                height: 'auto',
                justifyContent: 'flex-start',
                textAlign: 'left',
                width: '100%',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
                gap: '0.8rem',
              }}
            />
          </li>
        ) : (
          <li
            aria-hidden
            onClick={(e) => {
              e.stopPropagation();
              handleShowModal('Activate');
              setShowMenuOptions(false);
            }}
            className={`${classes.Option} ${
              Boolean(showMenuOptions) && classes.ShowOption
            }`}
          >
            <Button
              text={t('PositionCard.Activate')}
              size="100%"
              bgColor="none"
              showText
              icon={ActivateIcon}
              iconStyles={{
                width: '18',
                height: '18',
                fill: 'var(--black-75)',
              }}
              customStyles={{
                color: 'var(--brand-dark-grey)',
                height: 'auto',
                justifyContent: 'flex-start',
                textAlign: 'left',
                width: '100%',
                whiteSpace: 'nowrap',
                fontSize: '0.8rem',
                gap: '0.8rem',
              }}
            />
          </li>
        ))}
      <li
        aria-hidden
        onClick={(e) => {
          e.stopPropagation();
          if (setOpenEditModal) {
            setOpenEditModal(true);
            setShowMenuOptions(false);
          }
        }}
        className={`${classes.Option} ${
          showMenuOptions ? classes.ShowOption : undefined
        }`}
      >
        <Button
          text={t('PositionCard.EditOption')}
          size="100%"
          bgColor="none"
          showText
          icon={EditDark}
          iconStyles={{
            width: '18',
            height: '18',
            fill: 'var(--black-75)',
          }}
          customStyles={{
            color: 'var(--brand-dark-grey)',
            height: 'auto',
            justifyContent: 'flex-start',
            fontSize: '0.8rem',
            gap: '0.8rem',
          }}
        />
      </li>
      <li
        aria-hidden
        onClick={(e) => {
          e.stopPropagation();
          handleShowModal('Delete');
          setShowMenuOptions(false);
        }}
        className={`${classes.Option} ${
          showMenuOptions ? classes.ShowOption : undefined
        }`}
      >
        <Button
          text={t('PositionCard.DeleteOption')}
          size="100%"
          bgColor="none"
          showText
          icon={TrashIcon}
          iconStyles={{
            width: '18',
            height: '18',
            fill: 'var(--black-75)',
          }}
          customStyles={{
            color: 'var(--brand-dark-grey)',
            height: 'auto',
            justifyContent: 'flex-start',
            fontSize: '0.8rem',
            gap: '0.8rem',
          }}
        />
      </li>
      <li
        aria-hidden
        onClick={(e) => {
          e.stopPropagation();
          handleOpenDuplicateModal(true);
          setShowMenuOptions(false);
        }}
        className={`${classes.Option} ${
          showMenuOptions ? classes.ShowOption : undefined
        }`}
      >
        <Button
          text={t('PositionCard.DuplicatePosition')}
          size="100%"
          bgColor="none"
          showText
          icon={DuplicateIcon}
          iconStyles={{
            width: '24',
            height: '24',
            fill: 'var(--black-75)',
          }}
          customStyles={{
            color: 'var(--brand-dark-grey)',
            height: 'auto',
            justifyContent: 'flex-start',
            fontSize: '0.8rem',
            gap: '0.8rem',
          }}
        />
      </li>
      {/* <li
        aria-hidden
        onClick={(e) => {
          e.stopPropagation();
          copyUrl();
        }}
        className={`${classes.Option} ${
          showMenuOptions ? classes.ShowOption : undefined
        }`}
      >
        <Button
          text={t('PositionCard.CopyJobLink')}
          size="100%"
          bgColor="none"
          showText
          icon={CopyLinkIcon}
          iconStyles={{
            width: '24',
            height: '24',
            fill: 'var(--black-75)',
          }}
          customStyles={{
            color: 'var(--brand-dark-grey)',
            height: 'auto',
            justifyContent: 'flex-start',
            fontSize: '0.8rem',
            gap: '0.8rem',
          }}
        />
      </li> */}
      <li
        aria-hidden
        onClick={(e) => {
          e.stopPropagation();
          copySharePosition();
          setShowMenuOptions(false);
        }}
        className={`${classes.Option} ${
          showMenuOptions ? classes.ShowOption : undefined
        }`}
      >
        <Button
          text={t('PositionCard.SharePosition')}
          size="100%"
          bgColor="none"
          showText
          icon={ShareIcon}
          iconStyles={{
            width: '18',
            height: '18',
            fill: 'var(--black-75)',
          }}
          customStyles={{
            color: 'var(--brand-dark-grey)',
            height: 'auto',
            justifyContent: 'flex-start',
            fontSize: '0.8rem',
            gap: '0.8rem',
          }}
        />
      </li>
    </ul>
  ) : (
    <ul
      className={`${classes.MenuOptions} ${
        showMenuOptions && classes.ExpandMenu
      } 
      }`}
    >
      <li
        aria-hidden
        onClick={() => {
          if (setOpenEditModal) setOpenEditModal(true);
        }}
        className={`${classes.Option} ${
          showMenuOptions ? classes.ShowOption : undefined
        }`}
      >
        <Button
          text={t('PositionCard.EditOption')}
          size="100%"
          bgColor="none"
          showText
          icon={EditDark}
          iconStyles={{
            width: '18',
            height: '18',
            fill: 'var(--black-75)',
          }}
          customStyles={{
            color: 'var(--brand-dark-grey)',
            height: 'auto',
            justifyContent: 'flex-start',
            fontSize: '0.8rem',
            gap: '0.8rem',
          }}
        />
      </li>
      <li
        aria-hidden
        onClick={(e) => {
          e.stopPropagation();
          handleOpenDuplicateModal(true);
        }}
        className={`${classes.Option} ${
          showMenuOptions ? classes.ShowOption : undefined
        }`}
      >
        <Button
          text={t('PositionCard.DuplicatePosition')}
          size="100%"
          bgColor="none"
          showText
          icon={DuplicateIcon}
          iconStyles={{
            width: '24',
            height: '24',
            fill: 'var(--black-75)',
          }}
          customStyles={{
            color: 'var(--brand-dark-grey)',
            height: 'auto',
            justifyContent: 'flex-start',
            fontSize: '0.8rem',
            gap: '0.8rem',
          }}
        />
      </li>
    </ul>
  );
};

PositionCardMenuOptions.defaultProps = {
  setOpenEditModal: undefined,
};

export default PositionCardMenuOptions;
