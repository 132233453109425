import { ErrorMessageEs, ErrorMessageEn } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import { DeleteProfilePictureInput, UploadBuilder } from '../uploads.types';

export const deleteProfilePicture = (builder: UploadBuilder) =>
  builder.mutation<void, DeleteProfilePictureInput>({
    query: () => ({
      url: '/upload/profile-picture',
      method: 'DELETE',
    }),
    transformErrorResponse: (err, meta, args) => {
      if (err.status === 404) {
        return err.data;
      }
      errorHandler(
        '[deleteProfilePicture]',
        args.lang === 'es'
          ? ErrorMessageEs.DeleteError
          : ErrorMessageEn.DeleteError,
        err,
        true
      );
      return err.data;
    },
  });
