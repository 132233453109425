export interface TrackingCandidatesData {
  Salary?: number | null;
  StartDate?: string | null;
  EndDate?: string | null;
  EndReason?: EndReason | null;
  SimeraFee?: number | null;
  AdditionalNotesEndDate?: string | null;
  Benefits?: number | null;
}

export enum EndReason {
  QUIT,
  FIRED,
  COMPANY_LAYOFF,
  OTHER,
}
