import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import Spinner from '$components/Spinner/Spinner';
import useOnClickOutside from '$hooks/useOnClickOutside';
import { ReactComponent as Clear } from '$icons/CloseXGreen.svg';
import { ReactComponent as Calendar } from '$icons/CalendarIcon.svg';
import { ReactComponent as Reload } from '$icons/RefreshIcon.svg';
import {
  GetAllInterviewsInput,
  GetAllInterviewsOutput,
  InterviewSlotStatusTypes,
  useGetAllInterviewsQuery,
} from '$store/modules/interview';
import { DAYJS_JOB_INTERVIEW_DATE_FORMAT } from '$constants/datetimes';
import { segmentTrack } from '$utils/handleSegment';
import InternalJobInterviewsTable from './InternalJobInterviewsTable';
import JobInterviewStatusFilterButton from './JobInterviewStatusFilterButton';
import JobInterviewViewsFilterButton, {
  JobInterviewsViewModeTypes,
} from './JobInterviewViewsFilterButton';
import 'react-day-picker/dist/style.css';
import './InternalJobInterviewLayout.css';

type FilterValuesType = {
  jobInterviewInterviewDate?: {
    from: Date;
    to: Date;
  };
  jobInterviewRequestDate?: {
    from: Date;
    to: Date;
  };
  status?: (keyof typeof InterviewSlotStatusTypes)[];
  viewModes?: JobInterviewsViewModeTypes | undefined;
};

const InternalJobInterviewLayout = () => {
  const NUMBER_OF_RESULTS = [10, 25, 50];

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(NUMBER_OF_RESULTS[2]);

  const [searchQueries, setSearchQueries] = useState<any>({
    status: undefined,
    interviewDateMin: dayjs(new Date()).format(DAYJS_JOB_INTERVIEW_DATE_FORMAT),
    interviewDateMax: dayjs(new Date()).format(DAYJS_JOB_INTERVIEW_DATE_FORMAT),
    requestDateMin: undefined,
    requestDateMax: undefined,
  });
  const applySearchTimer = useRef<NodeJS.Timeout>();

  const { register, watch, setValue } = useForm();
  const filtersValues: FilterValuesType = watch();
  /* Single Date Picker */
  const datePickerRef = useRef(null);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  /* Range Date Picker */
  const dateRangePickerRef = useRef(null);
  const [isOpenDateRangePicker, setIsOpenDateRangePicker] = useState(false);

  // using useMemo because with useEffect for some reason makes an infinite loop
  const getAllInterviewsSearchParams = useMemo(() => {
    let statusFilters: undefined | InterviewSlotStatusTypes[];
    if (filtersValues.status !== undefined) {
      statusFilters = filtersValues.status.map((statusKeyName) => {
        const key = statusKeyName as keyof typeof InterviewSlotStatusTypes;

        // On backend, Need to Schedule is named as Requested
        if (
          InterviewSlotStatusTypes[key] ===
          InterviewSlotStatusTypes.NeedToSchedule
        )
          return InterviewSlotStatusTypes.Requested;

        return InterviewSlotStatusTypes[key];
      });
    }

    let interviewDateMin;
    let interviewDateMax;

    // default todays interviews
    if (
      filtersValues.viewModes === undefined &&
      !filtersValues.jobInterviewInterviewDate?.from
    ) {
      interviewDateMin = dayjs(new Date()).format(
        DAYJS_JOB_INTERVIEW_DATE_FORMAT
      );
    } else if (filtersValues.jobInterviewInterviewDate?.from) {
      interviewDateMin = dayjs(
        filtersValues.jobInterviewInterviewDate?.from
      ).format(DAYJS_JOB_INTERVIEW_DATE_FORMAT);
    }

    if (
      filtersValues.viewModes === undefined &&
      !filtersValues.jobInterviewInterviewDate?.to
    ) {
      interviewDateMax = dayjs(new Date()).format(
        DAYJS_JOB_INTERVIEW_DATE_FORMAT
      );
    } else if (filtersValues.jobInterviewInterviewDate?.to) {
      interviewDateMax = dayjs(
        filtersValues.jobInterviewInterviewDate?.to
      ).format(DAYJS_JOB_INTERVIEW_DATE_FORMAT);
    }

    return {
      status: statusFilters && statusFilters.length > 0 && statusFilters,
      interviewDateMin,
      interviewDateMax,
      requestDateMin:
        filtersValues?.jobInterviewRequestDate &&
        dayjs(filtersValues.jobInterviewRequestDate?.from).format(
          DAYJS_JOB_INTERVIEW_DATE_FORMAT
        ),
      requestDateMax:
        filtersValues?.jobInterviewRequestDate &&
        filtersValues?.jobInterviewRequestDate?.to &&
        dayjs(filtersValues.jobInterviewRequestDate?.to).format(
          DAYJS_JOB_INTERVIEW_DATE_FORMAT
        ),
    };
  }, [filtersValues]);

  const {
    data: jobInterviewsData,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllInterviewsQuery({
    search,
    page,
    take,
    ...searchQueries,
  } as GetAllInterviewsInput);

  const clearRequestDateRangeHandler = () => {
    setPage(1);
    setValue('jobInterviewRequestDate', undefined);
  };

  const clickDatePickerHandler = () => {
    setIsOpenDatePicker((prev) => !prev);
  };

  const clickDateRangerPickerHandler = () => {
    setIsOpenDateRangePicker((prev) => !prev);
  };

  const onChangeRangeDateHandler = (rangeDates: DateRange | undefined) => {
    setPage(1);
    segmentTrack('Interviews - Change Request Date');
    if (rangeDates) {
      // if previously the user has already selected two days
      if (
        filtersValues?.jobInterviewRequestDate?.from &&
        filtersValues?.jobInterviewRequestDate?.to
      ) {
        // if the new selected day is lower than the previous selected day
        // (example: selected 5 but previous was 10)
        // use the 'from' prop since that is the value that has changed and remove the 'to' prop
        if (
          rangeDates &&
          rangeDates.from &&
          rangeDates.from < filtersValues.jobInterviewRequestDate.from
        ) {
          setValue('jobInterviewRequestDate', {
            from: rangeDates.from,
            to: undefined,
          });
        } else {
          // if the new selected day is higher, use 'to' prop as this is the value that has changed from react-day-picker
          setValue('jobInterviewRequestDate', {
            from: rangeDates.to,
            to: undefined,
          });
        }
      } else {
        // if missing 'to' prop
        setValue('jobInterviewRequestDate', rangeDates);
      }
    } else {
      // if returns undefined, set 'from'
      setValue('jobInterviewRequestDate', {
        from: filtersValues?.jobInterviewRequestDate?.from,
        to: undefined,
      });
    }
  };

  const onChangeInterviewDateHandler = (rangeDates: DateRange | undefined) => {
    setPage(1);
    setValue('viewModes', JobInterviewsViewModeTypes.custom);
    segmentTrack('Interviews - Select Interview Date');
    // clickDatePickerHandler();
    if (rangeDates) {
      // if previously the user has already selected two days
      if (
        filtersValues?.jobInterviewInterviewDate?.from &&
        filtersValues?.jobInterviewInterviewDate?.to
      ) {
        // if the new selected day is lower than the previous selected day
        // (example: selected 5 but previous was 10)
        // use the 'from' prop since that is the value that has changed and remove the 'to' prop
        if (
          rangeDates &&
          rangeDates.from &&
          rangeDates.from < filtersValues.jobInterviewInterviewDate.from
        ) {
          setValue('jobInterviewInterviewDate', {
            from: rangeDates.from,
            to: undefined,
          });
        } else {
          // if the new selected day is higher, use 'to' prop as this is the value that has changed from react-day-picker
          setValue('jobInterviewInterviewDate', {
            from: rangeDates.to,
            to: undefined,
          });
        }
      } else {
        // if missing 'to' prop
        setValue('jobInterviewInterviewDate', rangeDates);
      }
    } else {
      // if returns undefined, set 'from'
      setValue('jobInterviewInterviewDate', {
        from: filtersValues?.jobInterviewInterviewDate?.from,
        to: undefined,
      });
    }
  };

  /** Waits half second to send the search param */
  const searchInputDebounce = useRef<ReturnType<typeof setTimeout>>();
  const searchInputOnChangeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
    if (searchInputDebounce.current) clearTimeout(searchInputDebounce.current);
    searchInputDebounce.current = setTimeout(() => {
      setPage(1);
      setSearch(ev.target.value);
      if (ev?.target?.value?.length > 0)
        segmentTrack('Interviews - Search Filter');
    }, 500);
  };

  const numberOfResultsHandler = (ev: ChangeEvent<HTMLSelectElement>) => {
    setTake(Number(ev.target.value));
    segmentTrack('Interviews - Change Max Results Ammount');
  };

  const previousPageHandler = () => {
    if (jobInterviewsData && jobInterviewsData?.meta)
      setPage(jobInterviewsData.meta.page - 1);
    segmentTrack('Interviews - Previous Page');
  };

  const nextPageHandler = () => {
    if (jobInterviewsData && jobInterviewsData?.meta)
      setPage(jobInterviewsData.meta.page + 1);
    segmentTrack('Interviews - Next Page');
  };

  const dateRangeText = useMemo(() => {
    if (!filtersValues.jobInterviewRequestDate) return '';
    const { from, to } = filtersValues.jobInterviewRequestDate;
    if (from && to)
      return `${dayjs(from).toDate().toLocaleDateString()} - ${dayjs(to)
        .toDate()
        .toLocaleDateString()}`;
    if (from && !to) return `${dayjs(from).toDate().toLocaleDateString()} - `;
    if (!from && !to) return '';
    return '';
  }, [filtersValues?.jobInterviewRequestDate]);

  const interviewDateText = useMemo(() => {
    if (
      !filtersValues.jobInterviewInterviewDate ||
      filtersValues.viewModes !== JobInterviewsViewModeTypes.custom
    )
      return '';
    const { from, to } = filtersValues.jobInterviewInterviewDate;
    if (from && to)
      return `${dayjs(from).toDate().toLocaleDateString()} - ${dayjs(to)
        .toDate()
        .toLocaleDateString()}`;
    if (from && !to) return `${dayjs(from).toDate().toLocaleDateString()} - `;
    if (!from && !to) return '';
    return '';
  }, [filtersValues.jobInterviewInterviewDate, filtersValues.viewModes]);

  useOnClickOutside(dateRangePickerRef, () => setIsOpenDateRangePicker(false));
  useOnClickOutside(datePickerRef, () => setIsOpenDatePicker(false));

  useEffect(() => {
    if (applySearchTimer.current) clearTimeout(applySearchTimer.current);
    applySearchTimer.current = setTimeout(() => {
      if (
        JSON.stringify(searchQueries) !==
        JSON.stringify(getAllInterviewsSearchParams)
      )
        setSearchQueries(getAllInterviewsSearchParams);
    }, 500);
  }, [getAllInterviewsSearchParams, searchQueries]);

  return (
    <div className="flex flex-col gap-y-4 w-full h-full bg-white">
      <div className="flex flex-row items-center justify-between pt-10 px-20">
        <div className="text-4xl">Interviews</div>
        {/* <div>
          <button
            className="bg-internal-main text-white p-4 rounded"
            type="button"
          >
            Add Interview
          </button>
        </div> */}
      </div>
      <div className="px-10">
        <form className="flex flex-row gap-4 items-end flex-wrap">
          <label
            htmlFor="jobInterviewSearch"
            className="flex flex-col max-w-64 text-gray-400 font-semibold text-sm"
          >
            Search by Company Name or ID
            <input
              id="jobInterviewSearch"
              type="text"
              placeholder="Search"
              className="p-2 rounded placeholder-internal-main border border-internal-main text-base outline-none placeholder:opacity-60"
              onChange={searchInputOnChangeHandler}
            />
          </label>
          <label
            htmlFor="jobInterviewRequestDate"
            className="flex flex-col max-w-64 text-gray-400 font-semibold text-sm relative"
            ref={dateRangePickerRef}
          >
            Request Date
            <div className="flex relative flex-row items-center">
              <input
                id="jobInterviewRequestDate"
                type="text"
                placeholder="Initial - End"
                className="p-2 pr-10 rounded placeholder-internal-main border border-internal-main text-base outline-none placeholder:opacity-60"
                readOnly
                onClick={clickDateRangerPickerHandler}
                value={dateRangeText}
              />
              {filtersValues?.jobInterviewRequestDate?.from === undefined ? (
                <div className="absolute right-0 h-full items-center flex">
                  <Calendar className="self-center items-center justify-end mx-1 stroke-internal-main" />
                </div>
              ) : (
                <button
                  type="button"
                  className="absolute right-0 h-full items-center flex"
                  onClick={clearRequestDateRangeHandler}
                  aria-label="Clear Request Date"
                >
                  <Clear className="self-center items-center justify-end mx-1" />
                </button>
              )}
            </div>
            {isOpenDateRangePicker && (
              <div className="border rounded shadow w-fit mt-2 z-10 top-16 absolute bg-white">
                <DayPicker
                  mode="range"
                  selected={filtersValues?.jobInterviewRequestDate}
                  onSelect={onChangeRangeDateHandler}
                />
              </div>
            )}
          </label>
          <label
            htmlFor="jobInterviewInterviewDate"
            ref={datePickerRef}
            className="flex flex-col max-w-64 text-gray-400 font-semibold text-sm relative"
          >
            Interview Date
            <div className="flex relative flex-row items-center">
              <input
                id="jobInterviewInterviewDate"
                type="text"
                placeholder="Initial - End"
                className="p-2 rounded placeholder-internal-main border border-internal-main text-base outline-none placeholder:opacity-60"
                readOnly
                onClick={clickDatePickerHandler}
                value={interviewDateText}
              />
              {filtersValues.jobInterviewInterviewDate?.from === undefined ||
              filtersValues.viewModes !== JobInterviewsViewModeTypes.custom ? (
                <div className="absolute right-0 h-full items-center flex">
                  <Calendar className="self-center items-center justify-end mx-1 stroke-internal-main" />
                </div>
              ) : (
                <button
                  type="button"
                  className="absolute right-0 h-full items-center flex"
                  onClick={() => {
                    setPage(1);
                    setValue('jobInterviewInterviewDate', undefined);
                    setValue('viewModes', JobInterviewsViewModeTypes.custom);
                  }}
                  aria-label="Clear Request Date"
                >
                  <Clear className="self-center items-center justify-end mx-1" />
                </button>
              )}
            </div>
            {isOpenDatePicker && (
              <div className="border rounded shadow w-fit mt-2 z-10 top-16 absolute bg-white">
                <DayPicker
                  mode="range"
                  selected={filtersValues.jobInterviewInterviewDate}
                  onSelect={onChangeInterviewDateHandler}
                />
              </div>
            )}
          </label>

          <JobInterviewStatusFilterButton
            formSetValueHook={setValue}
            statusFilters={filtersValues.status}
          />
          <JobInterviewViewsFilterButton
            formRegisterHook={register}
            formSetValueHook={setValue}
            currentViewMode={filtersValues.viewModes}
          />
          <button
            type="button"
            aria-label="Reload"
            className="bg-internal-main rounded text-white p-3 pb-2 flex flex-row items-center"
            onClick={() => refetch()}
          >
            <Reload className="w-5" />
          </button>
        </form>
      </div>
      <div className="w-full">
        {isLoading || isFetching ? (
          <Spinner secondary />
        ) : (
          <>
            <InternalJobInterviewsTable
              interviewsData={
                jobInterviewsData?.data as GetAllInterviewsOutput['data']
              }
            />
            <div className="flex justify-end gap-8 px-8 mt-4 items-center">
              <div>
                Page {jobInterviewsData?.meta?.page} of{' '}
                {jobInterviewsData?.meta?.pageCount}
              </div>
              <div className="flex gap-2 items-center">
                Show
                <select
                  className="p-2 rounded"
                  onChange={numberOfResultsHandler}
                  defaultValue={take}
                >
                  {NUMBER_OF_RESULTS.map((num) => {
                    return (
                      <option key={`numberOfResult_${num}`} value={num}>
                        {num}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="flex gap-2">
                <button
                  type="button"
                  className="py-2 px-4 rounded enabled:bg-internal-main enabled:text-white enabled:shadow disabled:bg-gray-400 disabled:opacity-40 disabled:cursor-not-allowed"
                  disabled={
                    !jobInterviewsData?.meta?.hasPreviousPage || isFetching
                  }
                  onClick={previousPageHandler}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="py-2 px-4 rounded enabled:bg-internal-main enabled:text-white enabled:shadow disabled:bg-gray-400 disabled:opacity-40 disabled:cursor-not-allowed"
                  disabled={!jobInterviewsData?.meta?.hasNextPage || isFetching}
                  onClick={nextPageHandler}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InternalJobInterviewLayout;
