import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { successHandler, errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  UpdateCandidatePositionStatusInput,
  UpdateCandidatePositionStatusOutput,
} from '../candidates.types';

export const updateCandidatePositionStatus = (builder: CandidateBuilder) =>
  builder.mutation<
    UpdateCandidatePositionStatusInput,
    UpdateCandidatePositionStatusOutput
  >({
    query: (args) => ({
      url: `/candidate-position-status/${args.data.CandidatePositionStatusId}`,
      method: 'PATCH',
      body: args.data,
      responseHandler: (response) => response.text(),
    }),
    transformResponse: (res, meta, args) => {
      if (meta?.response?.status === 200 && meta?.response?.ok) {
        if (args.data.Status === 'shortlisted') {
          successHandler(
            (args.lang ?? 'en') === 'en'
              ? SuccessMessageEn.UPDATE_CANDIDATE_SHORTLISTED
              : SuccessMessageEs.UPDATE_CANDIDATE_SHORTLISTED,
            true
          );
        } else if (args.data.Status === 'new') {
          successHandler(
            (args.lang ?? 'en') === 'en'
              ? SuccessMessageEn.UPDATE_CANDIDATE_NEW
              : SuccessMessageEs.UPDATE_CANDIDATE_NEW,
            true
          );
        } else {
          successHandler(
            (args.lang ?? 'en') === 'en'
              ? SuccessMessageEn.UPDATE_STATUS
              : SuccessMessageEs.UPDATE_STATUS,
            true
          );
        }
      } else {
        throw new Error();
      }
    },
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[updateCandidatePositionStatus]',
        (args.lang ?? 'en') === 'es'
          ? ErrorMessageEs.CanditatePositionStatusError
          : ErrorMessageEn.CanditatePositionStatusError,
        err,
        true
      );
      return err.data;
    },
  });
