const compareObjects = (obj1: any, obj2: any) => {
  // Check if both objects are null or undefined
  if (!obj1 && !obj2) {
    return true;
  }
  // Check if one of the objects is null or undefined
  if (!obj1 || !obj2) {
    return false;
  }
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }
  return obj1Keys.every((k) => obj1[k] === obj2[k]);
};

export default compareObjects;
