import { userLanguage } from '../../translations';
import {
  AuthActionTypes,
  authReducer,
  sessionExpiredAction,
} from '../legacy/auth';

export const unauthorizedRedirectMiddleware: any =
  (storeApi: any) => (next: any) => (action: any) => {
    if (action?.meta?.baseQueryMeta?.response?.status === 401) {
      if (!storeApi.getState()?.auth?.sessionExpiredInBackend) {
        storeApi.dispatch(
          authReducer(
            {
              ...storeApi.getState()?.auth,
              type: AuthActionTypes.SESSION_EXPIRED_IN_BACKEND,
            },
            { type: AuthActionTypes.SESSION_EXPIRED_IN_BACKEND }
          )
        );
        storeApi.dispatch(sessionExpiredAction(userLanguage));
      }
      return false;
    }
    return next(action);
  };
