import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useGetCandidateJobInterestQuery } from '../../store/modules/candidate-job-interest';
import EditableContainerInternal from '../EditableContainer/EditableContainerInternal';
import JobInterestsFormContainer from '../JobInterestsForm/JobInterestsFormContainer';
import JobInterestReadOnly from './JobInterestReadOnly';

interface Props {
  isActive: boolean;
  secondary: boolean;
  isEmployee?: boolean;
}

const JobInterestsEditableContainerInternal: FC<Props> = ({
  isActive,
  secondary,
  isEmployee,
}) => {
  const [isEdit, setIsEdit] = useState(true);
  const [t, i18next] = useTranslation('global');
  const [show, setShow] = useState(false);
  const isMobile = useWindowWidth() <= 650;
  const { candidateId } = useParams();

  const { isLoading, data: jobInterest } = useGetCandidateJobInterestQuery({
    candidateId: candidateId!,
    lang: i18next.language as 'en' | 'es',
  });

  const onClickPencil = () => {
    if (isMobile && window) window.scrollTo(0, 0);
    setIsEdit(false);
  };

  const handleCancel = () => {
    setShow(true);
  };

  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    setShow(false);
  };

  return (
    <EditableContainerInternal
      title={t('formNames.jobs')}
      isEditable={secondary && isEdit}
      data-testid="jobs-editableForm"
      onClickPencil={onClickPencil}
      isActive={isActive}
    >
      {!isEdit ? (
        <JobInterestsFormContainer
          isEdit
          setEdit={setIsEdit}
          onClickCancel={handleCancel}
          onClickCancelModal={handleCancelModal}
          show={show}
          secondary={secondary}
        />
      ) : (
        !isLoading &&
        jobInterest &&
        jobInterest.JobInterest &&
        jobInterest.JobInterest.length > 0 && (
          <JobInterestReadOnly
            secondary={secondary}
            jobInterest={jobInterest}
            isEmployee={isEmployee}
          />
        )
      )}
    </EditableContainerInternal>
  );
};

JobInterestsEditableContainerInternal.defaultProps = {
  isEmployee: false,
};

export default JobInterestsEditableContainerInternal;
