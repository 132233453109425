import type { FC } from 'react';
import useWindowWidth from '../../../hooks/useWindowWidth';
import classes from './CollapseModule.module.css';

interface CollapseModuleProps {
  hide: boolean;
}

const CollapseModule: FC<CollapseModuleProps> = ({ hide, children }) => {
  const isMobile = useWindowWidth() < 768;

  return (
    <div
      className={`${
        isMobile ? classes.ContainerMobile : classes.ContainerDesktop
      } ${hide && classes.Collapse}`}
    >
      {children}
    </div>
  );
};

export default CollapseModule;
