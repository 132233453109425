import { useState } from 'react';
import { ReactComponent as AddIcon } from '$icons/Add.svg';
import ModalInTailwind from '$components/ModalInTailwind/ModalInTailwind';
import PandaDocForm from '$components/PandaDocForm/PandaDocForm';

const MakePayrollRequestButton = () => {
  const [showPandaDoc, setShowPandaDoc] = useState(false);

  const onclickHandler = () => {
    setShowPandaDoc(true);
  };
  return (
    <>
      <ModalInTailwind
        isOpen={showPandaDoc}
        closeModal={() => {
          setShowPandaDoc(false);
        }}
      >
        <PandaDocForm
          onClose={() => {
            setShowPandaDoc(false);
          }}
        />
      </ModalInTailwind>
      <button
        type="button"
        onClick={onclickHandler}
        className="flex justify-center items-center gap-2 rounded bg-employer-button-mainBg text-employer-button-mainText p-3 font-semibold"
      >
        Make Payroll Request <AddIcon />
      </button>
    </>
  );
};

export default MakePayrollRequestButton;
