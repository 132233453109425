import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './FriendsList.module.css';
import NumberedStep from '../../NumberedStep/NumberedStep';
import { ReactComponent as CircleFriendsList } from '../../../icons/CircleFriendsList.svg';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { Referral } from '../../../types/candidates';

interface FriendsListProps {
  referralsList: Referral[];
}

const FriendsList: FC<FriendsListProps> = ({ referralsList }) => {
  const isMobile = useWindowWidth() < 768;
  const [t] = useTranslation('global');

  return (
    <div className={classes.FriendsListContainer}>
      <h2 className={classes.Title}>{t('referrals.friendList')}</h2>
      {referralsList.map((referral: Referral) => (
        <NumberedStep
          key={referral.Id}
          icon={CircleFriendsList}
          title={`
            ${referral.Referree.FirstName.split(' ')[0]} ${
            referral.Referree.LastName.split(' ')[0]
          }`}
          subtitle={`Joined ${new Date(referral.CreateTime).toLocaleDateString(
            'en-US'
          )}`}
          char={referral.Referree.FirstName?.[0]?.toUpperCase()}
          charStyles={{
            color: '#ffffff',
          }}
          titleFontStyles={{
            fontWeight: 'normal',
            color: 'var(--brand-grey)',
          }}
          subTitleFontStyles={{
            color: 'var(--bg-50)',
          }}
          containerStyles={{
            backgroundColor: 'var(--bg-extra-light)',
            borderRadius: '40px',
            flexBasis: !isMobile && 'calc(50% - 0.5rem)',
            minWidth: !isMobile ? '15rem' : '0',
            justifyContent: 'flex-start',
          }}
        />
      ))}
    </div>
  );
};

export default FriendsList;
