import { setTraceId } from '../utils/traceId';

/**
 * Stores the X-Trace-Id value upcoming from the server on the localstorage
 */
export const setTraceIdMiddleware: any = () => (next: any) => (action: any) => {
  if (action?.meta?.requestStatus === 'fulfilled') {
    setTraceId(
      action?.meta?.baseQueryMeta?.response?.headers?.get('X-Trace-Id')
    );
  }
  return next(action);
};
