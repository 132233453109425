import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classes from './EmailSubscription.module.css';
import { ReactComponent as UnsubscribeIcon } from '../../icons/unSubscribe.svg';
import Spinner from '../Spinner/Spinner';
import { ReactComponent as SubscribeIcon } from '../../icons/Resubscribe.svg';
import {
  useResubscribeUserToEmailMutation,
  useUnsubscribeUserFromEmailMutation,
} from '../../store/modules/user-info';

const EmailSubscription: FC = () => {
  const [subscribe, setSubscribe] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const { token } = useParams();
  const [t] = useTranslation('global');
  const [unsubscribeUserFromEmail, { isLoading, isError }] =
    useUnsubscribeUserFromEmailMutation();
  const [
    resubscribeUserToEmail,
    { isLoading: isLoadingResuscribe, isError: isErrorResuscribe },
  ] = useResubscribeUserToEmailMutation();

  const loading = isLoading || isLoadingResuscribe;
  const error = isError || isErrorResuscribe;
  const handleResubscribe = () => {
    if (token) {
      resubscribeUserToEmail({ token });
    }
    setSubscribe(true);
  };

  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (token) {
      unsubscribeUserFromEmail({ token });
    }
  }, [token, unsubscribeUserFromEmail]);

  return !loading && !error ? (
    <div className={classes.Layout}>
      {!subscribe && !firstRender ? (
        <div className={classes.Container}>
          <h1 className={classes.Heading}>
            {t('SubscriptionPage.UnsubscribeSuccessful')}
          </h1>
          <UnsubscribeIcon className={classes.Icon} />
          <p className={classes.MainParagraph}>
            {t('SubscriptionPage.UnsubscribeSuccessfulText')}
          </p>
          <div className={classes.Footer}>
            <p className={classes.FooterText}>
              {t('SubscriptionPage.unsubscribeAccident')}
            </p>
            <button
              className={classes.FooterButton}
              type="button"
              onClick={() => handleResubscribe()}
            >
              {t('SubscriptionPage.Resubscribe')}
            </button>
          </div>
        </div>
      ) : (
        <div className={classes.Container}>
          <h1 className={classes.Heading}>
            {t('SubscriptionPage.ResubscribeSuccessful')}
          </h1>
          <SubscribeIcon className={classes.Icon} />
          <p className={classes.MainParagraph}>
            {t('SubscriptionPage.ResubscribeSuccessfulText')}
          </p>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default EmailSubscription;
