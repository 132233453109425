import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ExtracurricularEditableContainer.module.css';
import ExtracurricularReadOnly from './ExtracurricularReadOnly';
import ExtracurricularFormContainer from '../ExtracurricularForm/ExtracurricularFormContainer';
import NewExtracurricular from '../ExtracurricularForm/NewExtracurricular';
import EditableContainerInternal from '../EditableContainer/EditableContainerInternal';
import useInternalEditableContainer from '../../hooks/useInternalEditableContainer';
import { useGetCandidateExtracurricularQuery } from '../../store/modules/candidate-extracurricular';
import { Candidate } from '../../types/candidates';

interface ExtracurricularEditableContainerProps {
  isActive: boolean;
  secondary: boolean;
  candidate: Candidate;
}

const ExtracurricularEditableContainerInternal: FC<
  ExtracurricularEditableContainerProps
> = ({ isActive, secondary, candidate }) => {
  const [t, i18n] = useTranslation('global');

  const { data, isLoading } = useGetCandidateExtracurricularQuery({
    candidateId: candidate.Id,
    lang: i18n.language as 'en' | 'es',
  });

  const labels = [
    {
      id: 0,
      label: t('extracurricular.vol'),
    },
    {
      id: 1,
      label: t('extracurricular.charity'),
    },
    {
      id: 2,
      label: t('extracurricular.other'),
    },
  ];

  const {
    newFormStep: newExtra,
    handleCancel,
    setIsEdit,
    handleCancelModal,
    show,
    isEdit,
    onClickSave,
    onClickPencil,
    onClickPlus,
    isMobile,
  } = useInternalEditableContainer();

  const newExtraOrField = () => {
    if (newExtra) {
      return (
        <NewExtracurricular
          onClickCancel={handleCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={handleCancelModal}
          show={show}
          onClickSave={onClickSave}
          newExtra
          secondary={secondary}
          countForms={data?.length || 1}
        />
      );
    }
    return (
      !isLoading &&
      data?.map((extra, idx) => (
        <ExtracurricularReadOnly
          key={`extraEditable-${extra?.Id}`}
          extracurricular={extra}
          idx={idx}
          labels={labels}
          extracurricularsLength={data?.length ?? -1}
        />
      ))
    );
  };
  return (
    <EditableContainerInternal
      title={t('extracurricular.title')}
      isEditable={secondary && isEdit}
      onClickPencil={onClickPencil}
      onClickPlus={onClickPlus}
      data-testid="extracurricular-editableForm"
      hasAddMore
      addMore={newExtra ? t('extracurricular.nonacademic') : false}
      isActive={isActive}
    >
      {!isEdit ? (
        <div className={isMobile ? '' : classes.ExtraEditModeContainer}>
          <ExtracurricularFormContainer
            onClickCancel={handleCancel}
            isEdit
            setEdit={setIsEdit}
            onClickCancelModal={handleCancelModal}
            show={show}
            secondary={secondary}
          />
        </div>
      ) : (
        newExtraOrField()
      )}
    </EditableContainerInternal>
  );
};

export default ExtracurricularEditableContainerInternal;
