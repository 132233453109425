import {
  GetProfilePictureInput,
  GetProfilePictureOutput,
  UploadBuilder,
} from '../uploads.types';

export const getProfilePicture = (builder: UploadBuilder) =>
  builder.query<GetProfilePictureOutput, GetProfilePictureInput>({
    query: () => ({
      url: '/upload/profile-picture',
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            {
              type: 'Upload',
              id: result.Id,
            },
            { type: 'Upload', id: 'List' },
          ]
        : [{ type: 'Upload', id: 'List' }],
    transformResponse: (response: GetProfilePictureOutput) => {
      return response;
    },
  });
