import {
  SuccessMessageEn,
  SuccessMessageEs,
  ErrorMessageEn,
  ErrorMessageEs,
} from '../../../../constants/alerts';
import { successHandler, errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  UpdateCandidateProfileInput,
  UpdateCandidateProfileOutput,
} from '../candidates.types';

export const updateCandidateProfile = (builder: CandidateBuilder) =>
  builder.mutation<UpdateCandidateProfileOutput, UpdateCandidateProfileInput>({
    query: (options) => ({
      url: '/candidates/update-profile',
      method: 'PATCH',
      body: {
        ...options.data,
        UserId: options.userId,
      },
    }),
    transformResponse: (res, meta, arg) => {
      successHandler(
        (arg.lang ?? 'en') === 'en'
          ? SuccessMessageEn.PROFILE_UPDATE
          : SuccessMessageEs.PROFILE_UPDATE,
        true
      );

      return arg.data;
    },
    transformErrorResponse: (error, _, arg) => {
      errorHandler(
        '[updateCandidateProfileEndpoint]',
        (arg.lang ?? 'en') === 'es'
          ? ErrorMessageEs.UpdateCandidateFail
          : ErrorMessageEn.UpdateCandidateFail,
        error,
        true
      );
      return error.data;
    },
    invalidatesTags: (res, meta, arg) => [
      { type: 'Candidate', Id: arg.candidateId },
    ],
  });
