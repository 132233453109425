import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { workHistoryDefaultValue } from '../../../../constants/formDefaultValues';
import { errorHandler } from '../../../utils';
import { FormTypes, WorkHistoryResponse } from '../../../../types/user_info';
import {
  GetWorkHistoryByCandidateIdInput,
  GetWorkHistoryByCandidateIdOutput,
  WorkExperienceBuilder,
} from '../work-experience.types';

export const getWorkHistoryByCandidateId = (builder: WorkExperienceBuilder) =>
  builder.query<
    GetWorkHistoryByCandidateIdOutput,
    GetWorkHistoryByCandidateIdInput
  >({
    query: ({ candidateId }) => ({
      url: `/candidate-experience/${candidateId}`,
      method: 'GET',
    }),
    transformResponse(response: WorkHistoryResponse[]) {
      if (response.length === 0) {
        return workHistoryDefaultValue;
      }
      const mappedResponse: FormTypes['work'][] = response.map((work) => {
        const admissionVal = work?.Admission;
        const egressVal = work?.Egress;
        return {
          Id: work.Id,
          Company: { val: work?.Company },
          Country: { val: work?.Country },
          Description: { val: work.Description },
          Admission: {
            val: work?.Admission
              ? `${admissionVal.split('-')[0]}-${admissionVal.split('-')[1]}`
              : '',
          },
          Egress: {
            val: egressVal
              ? `${egressVal.split('-')[0]}-${egressVal.split('-')[1]}`
              : '',
          },
          JobTitle: { val: work?.JobTitle },
          JobPosition: {
            Id: work?.JobPosition?.Id,
            title: work?.JobPosition?.title,
          },
        };
      });
      return mappedResponse;
    },
    providesTags: (result) =>
      result
        ? [
            ...result.map((work) => ({
              type: 'workExperience' as const,
              id: work?.Id,
            })),
            { type: 'workExperience', id: 'LIST' },
          ]
        : [{ type: 'workExperience', id: 'LIST' }],

    transformErrorResponse: (err, meta, { lang }) => {
      errorHandler(
        '[setWorkHistoryAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        err,
        true
      );
    },
  });
