import { FC } from 'react';
import {
  UseFormRegisterReturn,
  useWatch,
  FieldError,
  Control,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FeedbackType from '../../../../constants/FeedbackType';
import { FeedbackFormValues } from '../../../../types/candidates';
import Button from '../../../Buttons/Button';
import RadioFeedback from '../../../Radio/RadioFeedback';
import FeedBackTextArea from '../../../TextArea/FeedbackTextArea';
import classes from './FeedbackForm.module.css';

interface Props {
  feedback: UseFormRegisterReturn;
  feedbackType: UseFormRegisterReturn;
  control: Control<FeedbackFormValues, object>;
  errors: {
    FeedbackType?: any;
    Feedback?: FieldError | undefined;
  };
  handleClose?: () => void;
  setSelectedFeedbackText: (text: string) => void;
}
const FeedbackForm: FC<Props> = ({
  feedback,
  feedbackType,
  control,
  errors,
  handleClose,
  setSelectedFeedbackText,
}) => {
  const currentType = useWatch({ control, name: 'FeedbackType' });
  const [t] = useTranslation('global');
  const labels = [
    {
      label: t('FeedbackReject.CandidateNotRequirement'),
      value: FeedbackType.CANDIDATE_NOT_MEET_REQUIREMENTS,
    },
    {
      label: t('FeedbackReject.CandidateCommunicationNotStrong'),
      value: FeedbackType.CANDIDATE_COMMUNICATION_NOT_STRONG_ENOUGH,
    },
    {
      label: t('FeedbackReject.CandidatePersonalityDoesntMatch'),
      value: FeedbackType.CANDIDATE_PERSONALITY_DOESNT_MATCH_OUR_NEEDS,
    },
    {
      label: t('FeedbackReject.Other'),
      value: FeedbackType.OTHER,
    },
  ];

  return (
    <>
      {labels.map((label) => {
        return (
          <RadioFeedback
            key={`feedback-radio-${label.value}`}
            name={feedbackType.name}
            label={label.label}
            value={label.value}
            onChange={(ev) => {
              feedbackType.onChange(ev);
              setSelectedFeedbackText(label.label);
            }}
            refProp={feedbackType.ref}
            checked={parseInt(currentType, 10) === label.value}
          />
        );
      })}
      {errors.FeedbackType?.message && (
        <div className={classes.ErrorText}>{errors.FeedbackType?.message}</div>
      )}
      <h4 style={{ marginTop: '4px', marginBottom: '4px' }}>
        {t('FeedbackReject.FeedbackText')}
      </h4>
      <FeedBackTextArea
        onBlur={feedback.onBlur}
        name={feedback.name}
        onChange={feedback.onChange}
        refProp={feedback.ref}
        placeholder="Write here"
        errorText={errors.Feedback?.message}
      />

      <div className={classes.buttonContainer}>
        <div className={classes.button}>
          {handleClose && (
            <Button
              bgColor="var(--brand-light-grey-70)"
              showText
              text="Cancel"
              customStyles={{
                borderRadius: '4px',
                boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.1)',
                color: 'black',
                marginRight: '10px',
                padding: '0px 10px',
              }}
              onClick={handleClose}
            />
          )}
          <Button
            submit
            bgColor="var(--brand-blue-100)"
            showText
            text={t('CandidatePositionSummaryCard.RemoveCandidate')}
            customStyles={{
              borderRadius: '4px',
              boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.1)',
              padding: '0px 10px',
              flex: '0 0 auto',
            }}
            size="auto"
          />
        </div>
      </div>
    </>
  );
};

FeedbackForm.defaultProps = {
  handleClose: () => {},
};

export default FeedbackForm;
