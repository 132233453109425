import { errorHandler } from '../../../utils';
import {
  FormInfoBuilder,
  GetJobPositionsInput,
  GetJobPositionsOutput,
} from '../form-info.types';

export const getJobPositions = (builder: FormInfoBuilder) =>
  builder.query<GetJobPositionsOutput, GetJobPositionsInput>({
    query: () => ({
      url: '/form-info/job-positions',
      method: 'GET',
    }),
    keepUnusedDataFor: 240,
    transformErrorResponse: (err, meta, args) => {
      const { t } = args;
      errorHandler(
        '[fetchJobPositionsAction]',
        t('errorMessages.getInfoFail'),
        err,
        true
      );
      return err.data;
    },
  });
