import {
  ErrorMessageEs,
  ErrorMessageEn,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { Candidate } from '../../../../types/candidates';
import { successHandler, errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  UpdateCandidateScoreInput,
  UpdateCandidateScoreOutput,
} from '../candidates.types';

export const updateCandidateScore = (builder: CandidateBuilder) =>
  builder.mutation<UpdateCandidateScoreOutput, UpdateCandidateScoreInput>({
    query: (options) => ({
      url: '/candidates/update-score',
      method: 'PATCH',
      body: {
        candidateId: options.candidateId,
        Score: options.score,
      },
    }),
    transformResponse: (queryResponse: { data: Candidate }, _, arg) => {
      successHandler(
        (arg.lang ?? 'en') === 'en'
          ? SuccessMessageEn.UpdateCandidate
          : SuccessMessageEs.UpdateCandidate,
        true
      );
      return queryResponse.data;
    },
    transformErrorResponse: (error, _, arg) => {
      errorHandler(
        '[updateCandidateScoreEndpoint]',
        (arg.lang ?? 'en') === 'es'
          ? ErrorMessageEs.UpdateCandidateFail
          : ErrorMessageEn.UpdateCandidateFail,
        error,
        true
      );
      return error.data;
    },
    invalidatesTags: (result, error, arg) => [
      { type: 'Candidate', Id: arg.candidateId },
    ],
  });
