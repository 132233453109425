import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  ApplyCandidateInput,
  ApplyCandidateOutput,
  CandidateBuilder,
} from '../candidates.types';

export const applyCandidate = (builder: CandidateBuilder) =>
  builder.mutation<ApplyCandidateOutput, ApplyCandidateInput>({
    query: (args) => ({
      url: '/candidate-position-status/apply-candidate',
      method: 'POST',
      body: { positionId: args.data.positionId },
    }),
    transformResponse: async (res, meta, args) => {
      successHandler(
        (args.lang ?? 'en') === 'en'
          ? SuccessMessageEn.ADD_CANDIDATE_TO_POSITION
          : SuccessMessageEs.ADD_CANDIDATE_TO_POSITION,
        true
      );
      return args.data;
    },
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[applyToCandidateToPosition]',
        (args.lang ?? 'en') === 'es'
          ? ErrorMessageEs.ApplyCandidate
          : ErrorMessageEn.ApplyCandidate,
        err,
        true
      );

      return err.data;
    },
    invalidatesTags: () => [{ type: 'Candidates' }],
  });
