import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './GeneralNotes.module.css';
import PositionNotes from '../PositionNotes/PositionNotes';
import isNumber from '../../../../../utils/isNumber';
import { Note } from '../../../../../types/candidates';

interface Props {
  notes?: Note[];
}

const GeneralNotes: FC<Props> = ({ notes }) => {
  const [t] = useTranslation('global');
  const notesByPosition = notes?.reduce(
    (acc: { [key: string]: Partial<Note>[] }, note) => {
      const { Position, ...rest } = note;
      const generalPosition = `${t('Notes.GeneralNotes')}`;
      const specificPosition = Position?.JobPosition?.title
        ? `${Position.Id} - ${Position.JobPosition.title}`
        : generalPosition;

      if (!acc[generalPosition]) {
        acc[generalPosition] = [];
      }

      if (!acc[specificPosition]) {
        acc[specificPosition] = [];
      }

      if (specificPosition === generalPosition) {
        acc[generalPosition].push({ ...rest });
      } else {
        acc[specificPosition].push({ ...rest });
      }

      return acc;
    },
    {}
  );

  if (notes?.length === 0) {
    return (
      <PositionNotes
        notes={notes}
        title={t('Notes.GeneralNotes')}
        positionId={0}
      />
    );
  }

  return (
    <div className={classes.generalNotesContainer}>
      {Object.keys(notesByPosition!).map((position, idx) => {
        const positionId = isNumber(+position.split('-')[0].trim())
          ? Number(position.split('-')[0].trim())
          : 0;
        return (
          <div key={position}>
            <PositionNotes
              notes={notesByPosition![position]}
              title={position}
              positionId={positionId}
            />
            {Object.keys(notesByPosition!).length > 1 && idx === 0 && (
              <div className={classes.dividerContainer}>
                <div className={classes.divider}>
                  <span className={classes.dividerText}>
                    {t('Notes.NotesPosition')}
                  </span>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

GeneralNotes.defaultProps = {
  notes: [],
};

export default GeneralNotes;
