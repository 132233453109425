import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { ReactComponent as CopyText } from '../../../icons/CopyText.svg';
import { ReactComponent as CopyTextMobile } from '../../../icons/CopyTextMobile.svg';

import Button from '../../Buttons/Button';
import SocialMediaShareButtons from '../SocialMediaShareButtons/SocialMediaShareButtons';

import classes from './ReferModule.module.css';

interface ReferModuleProps {
  link: string;
}

const ReferModule: FC<ReferModuleProps> = ({ link }) => {
  const isMobile = useWindowWidth() < 768;

  const handleCopy = () => {
    navigator.clipboard.writeText(link);

    // add tooltip when url is copied
  };

  const [t] = useTranslation('global');

  return (
    <div className={classes.ModuleContainer}>
      <h3 className={classes.Title}>{t('common.share')}</h3>
      <SocialMediaShareButtons link={link} />
      <div className={classes.CopyReferralURLContainer}>
        <div className={classes.CopyReferralURLWrapper}>
          <input
            readOnly
            className={classes.CopyInput}
            type="text"
            name="referralUrl"
            id="referralUrl"
            value={link}
          />
          {isMobile && (
            <button
              aria-label="Copy referral URL"
              type="button"
              className={classes.CopyButton}
              onClick={handleCopy}
            >
              <CopyTextMobile />
            </button>
          )}
        </div>
        <Button
          icon={CopyText}
          size="fit-content"
          text={t('referrals.CopyButtonBig')}
          showText
          onClick={handleCopy}
          invertTextContent
          styling={classes.CopyButtonBig}
        />
      </div>
    </div>
  );
};

export default ReferModule;
