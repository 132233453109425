import { useEffect, useRef, useState } from 'react';
import { AuthState } from '../store/legacy/auth/auth.types';

const useResendEmailModal = (authState: AuthState) => {
  const [disableEmailResendBtn, setDisableEmailResendBtn] = useState(false);
  const [currentSecond, setCurrentSecond] = useState(60);
  const timeout = useRef<NodeJS.Timeout>();
  const countdown = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (authState.resendConfirmationEmail) {
      setDisableEmailResendBtn(true);
      timeout.current = setTimeout(() => {
        setDisableEmailResendBtn(false);
      }, 60000);
    }
  }, [authState.resendConfirmationEmail]);

  useEffect(() => {
    if (disableEmailResendBtn) {
      countdown.current = setInterval(() => {
        setCurrentSecond((prev) => prev - 1);
      }, 1000);
    }
    if (!disableEmailResendBtn && countdown.current) {
      clearInterval(countdown.current);
      setCurrentSecond(60);
    }
  }, [disableEmailResendBtn]);
  useEffect(() => {
    return () => {
      const timeoutRef = timeout.current;
      const c = countdown.current;
      if (timeoutRef) clearTimeout(timeoutRef);
      if (c) clearInterval(c);
    };
  }, []);

  return {
    disableEmailResendBtn,
    currentSecond,
  };
};

export default useResendEmailModal;
