import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  ReferralBuilder,
  IsValidReferralInput,
  IsValidReferralOutput,
} from '../referrals.types';

export const isValidReferral = (builder: ReferralBuilder) =>
  builder.query<IsValidReferralOutput, IsValidReferralInput>({
    query: (args) => ({
      url: `/referral-unique-id/is-referral/${args.referralId}`,
      method: 'GET',
    }),
    transformResponse: (response: { data: boolean }, meta, args) => {
      if (response) {
        localStorage.setItem('referralId', args.referralId);
      }
      return response.data;
    },
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[isValidReferral]',
        (args.lang ?? 'en') === 'en'
          ? ErrorMessageEn.ReferralError
          : ErrorMessageEs.ReferralError,
        err,
        false
      );
      return err.data;
    },
  });
