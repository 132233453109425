import { useTranslation } from 'react-i18next';
import { AdditionalVideo } from '../../../../../types/additional-video/additional-video';
import classes from './VideoPlayer.module.css';
import capitalizedName from '../../../../../utils/capitalizedName';
import { ReactComponent as EditIcon } from '../../../../../icons/EditIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../icons/TrashIcon.svg';
import { VideoUpdateModal } from '../VideoUpdateModal';
import { UpdateVideoForm } from '../UpdateVideoForm';
import { VideoDeleteModal } from '../VideoDeleteModal';
import { useVideoPlayer } from './hooks/useVideoPlayer';
import CandidateVideosRate from '../../../../Positions/CandidateVideosModal/CandidateVideosRate';
import VideoGenericElement from '../../../../GenericElements/VideoGenericElement';

interface Props {
  internal?: boolean;
  video: AdditionalVideo;
}
const defaultProps = {
  internal: false,
  employer: false,
};
export const VideoPlayer = ({ video, internal }: Props) => {
  const [t] = useTranslation('global');
  const {
    showDeleteModal,
    showUpdateModal,
    videoRef,
    handleDelete,
    handleUpdate,
    setShowDeleteModal,
    setShowUpdateModal,
  } = useVideoPlayer(video);

  return (
    <article className={classes.videoPlayerContent}>
      {showUpdateModal && (
        <VideoUpdateModal setModal={setShowUpdateModal} isInternal={internal}>
          <UpdateVideoForm
            setModal={setShowUpdateModal}
            videoName={video.Name}
            videoId={video.Id}
            isInternal={internal}
          />
        </VideoUpdateModal>
      )}
      {showDeleteModal && (
        <VideoDeleteModal
          setModal={setShowDeleteModal}
          videoId={video.Id}
          isInternal={internal}
        />
      )}

      <div className={classes.intructionsContainer}>
        <div
          className={`${classes.title} ${internal && classes.internalTitle}`}
        >
          <p>{capitalizedName(video.Name)}</p>
          <CandidateVideosRate
            additionalVideoId={video.Id}
            primary={!internal}
          />
        </div>
        {video.Url && (
          <VideoGenericElement
            videoRef={videoRef}
            className={classes.video}
            width={750}
            controls
            videoData={video}
          />
        )}
        {internal && (
          <div className={classes.actionContainer}>
            <button
              type="button"
              className={`${classes.actionButton} ${
                internal && classes.internal
              }`}
              onClick={handleUpdate}
            >
              {t('AdditionalVideos.buttons.rename')}
              <EditIcon fill="#fff" />
            </button>
            <button
              type="button"
              className={`${classes.actionButton} ${
                internal && classes.internal
              }`}
              onClick={handleDelete}
            >
              {t('AdditionalVideos.buttons.delete')}
              <DeleteIcon fill="#fff" />
            </button>
          </div>
        )}
      </div>
    </article>
  );
};

VideoPlayer.defaultProps = defaultProps;
