import { useTranslation } from 'react-i18next';
import SimeraRegisterForm from '../../RegisterForm/SimeraRegisterForm';
import classes from './EmployerRegisterScreen.module.css';

const SimeraRegisterScreen = () => {
  const [t] = useTranslation('global');
  return (
    <div className={classes.SimeraContainer}>
      <div data-testid="login-header" className={classes.header}>
        <p className={classes.header}>{t('register.title')}</p>
        <div className={classes.headerDivider} />
      </div>

      <SimeraRegisterForm />
    </div>
  );
};

export default SimeraRegisterScreen;
