import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SIMERA_CANDIDATES_BY_ID_ROUTE } from '../../../../../constants/routes';
import { ReactComponent as EditIcon } from '../../../../../icons/EditIcon.svg';
import { ReactComponent as EyeIcon } from '../../../../../icons/PasswordEye.svg';
import HoverTooltip from '../../../../Tooltip/HoverTooltip';
import classes from './ActionButtons.module.css';
import TrackHiredCandidatesModal from '../TrackHiredCandidatesModal/TrackHiredCandidatesModal';
import TrackHiredCandidateForm from '../TrackHiredCandidatesModal/TrackHiredCandidateForm/TrackHiredCandidateForm';
import { EmployeesData } from '../../../../../types/candidates';
import { EmployeeColumn } from '../../../../../constants/CompanyColumn';

interface Props {
  candidateId: number;
  employee: EmployeesData | undefined;
  cps: EmployeeColumn;
}

const ActionButtons: FC<Props> = ({ candidateId, employee, cps }) => {
  const navigate = useNavigate();
  const [t] = useTranslation('global');

  const handleCandidateProfile = (id: number) => {
    navigate(SIMERA_CANDIDATES_BY_ID_ROUTE(id));
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classes.actions} data-testid="action-buttons">
      <HoverTooltip text={t('Tracking.AdditionalInfo')}>
        <button
          aria-label="Edit"
          type="button"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <EditIcon fill="#5d5e61" />
        </button>
      </HoverTooltip>
      <HoverTooltip text={t('CompaniesTable.CandidateProfile')}>
        <button
          aria-label="Candidate profile"
          className={classes.actionButton}
          onClick={() => handleCandidateProfile(candidateId)}
          type="button"
        >
          <EyeIcon />
        </button>
      </HoverTooltip>
      <TrackHiredCandidatesModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <TrackHiredCandidateForm
          employee={employee}
          setIsOpen={setIsOpen}
          cps={cps}
        />
      </TrackHiredCandidatesModal>
    </div>
  );
};

export default ActionButtons;
