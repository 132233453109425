import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormSetValue } from 'react-hook-form';
import classes from './VisibilityVideo.module.css';
import { ReactComponent as VideoIcon } from '../../../../../icons/VideoIcon.svg';
import { ReactComponent as GreenEye } from '../../../../../icons/GreenEye.svg';
import { ReactComponent as GreenEyeOff } from '../../../../../icons/GreenEyeOff.svg';
import { ReactComponent as CloseX } from '../../../../../icons/CloseX.svg';
import { AdditionalVideoFormValues } from '../../../../../types/additional-video/additional-video';

interface Props {
  isVisible: boolean;
  setValue: UseFormSetValue<AdditionalVideoFormValues>;
  file: File;
}
export const VisibilityVideo = ({ isVisible, setValue, file }: Props) => {
  const [toggleVisibility, setToggleVisibility] = useState(isVisible);
  const [t] = useTranslation('global');

  const handleVisibility = () => {
    setToggleVisibility((prev) => {
      setValue('isVisible', !prev);
      return !prev;
    });
  };
  useEffect(() => {
    setToggleVisibility(isVisible);
  }, [isVisible, toggleVisibility]);
  return (
    <section className={classes.acceptedVideoContainer}>
      <section className={classes.videoName}>
        <VideoIcon />
        <p>{file.name}</p>
      </section>
      <section className={classes.videoActions}>
        <button
          type="button"
          onClick={handleVisibility}
          className={classes.visibility}
        >
          {toggleVisibility ? (
            <span className={classes.visibility}>
              <GreenEye /> {t('AdditionalVideos.buttons.hideVideo')}
            </span>
          ) : (
            <span className={classes.visibility}>
              <GreenEyeOff /> {t('AdditionalVideos.buttons.hidden')}
            </span>
          )}
        </button>
        <button
          aria-label="Remove video"
          type="button"
          onClick={() => {
            setValue('file', null);
          }}
          className={classes.removeVideo}
        >
          <CloseX width="16px" height="16px" fill="#5A5B5E" />
        </button>
      </section>
    </section>
  );
};
