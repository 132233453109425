import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActiveValues from '$constants/ActiveValues';
import { ReactComponent as Star } from '$icons/star.svg';
import { ReactComponent as Hire } from '$icons/PersonAdd.svg';
import useOnClickOutside from '$hooks/useOnClickOutside';
import { ReactComponent as Interview } from '$icons/reschedule.svg';
import { StatusCandidatePositionOptions } from '$store/modules/candidates';
import { segmentTrack } from '$utils/handleSegment';
import Button from '../../../Buttons/Button';
import classes from './CandidateActionBtn.module.css';

interface CandidateActionBtnLeftProps {
  activeValue: ActiveValues;
  handleHireModal: (value: boolean) => void;
  handleRequestInterview: () => void;
  handleChangePositionStatus: (status: StatusCandidatePositionOptions) => void;
  candidateId: number;
}

const CandidateActionBtnLeft: FC<CandidateActionBtnLeftProps> = ({
  activeValue,
  handleHireModal,
  handleRequestInterview,
  handleChangePositionStatus,
  candidateId,
}) => {
  const InternalActionsRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation('global');
  const [openTakeActions, setOpenTakeActions] = useState(false);

  const customStyles = {
    padding: '0.75rem 1.5rem',
    height: 'auto',
    borderRadius: '0.25rem',
    flexGrow: '0',
    border: 'var(--primary-green) 1px solid',
    color: 'var(--primary-green)',
    fontWeight: 600,
    fontSize: '0.875rem',
  };

  const iconStyles = {
    width: '18',
    height: '18',
    fill: 'var(--black-75)',
  };

  const takeActionStyles = {
    color: 'var(--brand-dark-grey)',
    height: 'auto',
    justifyContent: 'flex-start',
    textAlign: 'left',
    width: '100%',
    whiteSpace: 'nowrap',
    gap: '0.8rem',
  };

  useOnClickOutside(InternalActionsRef, () => {
    setOpenTakeActions(false);
  });

  return (
    <>
      <Button
        size="auto"
        showText
        text={t('CandidatePositionSummaryCard.TakeActions')}
        styling={classes.ButtonHire}
        customStyles={customStyles}
        onClick={() => setOpenTakeActions(true)}
      />
      {openTakeActions && (
        <div
          ref={InternalActionsRef}
          className={classes.internalActionsBtnContainer}
        >
          <ul>
            {activeValue === ActiveValues.New && (
              <li aria-hidden>
                <Button
                  text={t('EmployerPositions.ShortList')}
                  size="100%"
                  bgColor="none"
                  showText
                  icon={Star}
                  iconStyles={iconStyles}
                  customStyles={takeActionStyles}
                  onClick={async () => {
                    await handleChangePositionStatus('shortlisted');
                    segmentTrack('Candidate Added to Shortlist', {
                      candidate_id: candidateId,
                    });
                    setOpenTakeActions(false);
                  }}
                />
              </li>
            )}
            {activeValue === ActiveValues.ShortList && (
              <li aria-hidden>
                <Button
                  text={t('EmployerPositions.RemoveShortList')}
                  size="100%"
                  bgColor="none"
                  showText
                  icon={Star}
                  iconStyles={iconStyles}
                  customStyles={takeActionStyles}
                  onClick={async () => {
                    await handleChangePositionStatus('new');
                    segmentTrack('Candidate Removed from Shortlist', {
                      candidate_id: candidateId,
                    });
                    setOpenTakeActions(false);
                  }}
                />
              </li>
            )}
            {activeValue !== ActiveValues.requestInterview && (
              <li aria-hidden>
                <Button
                  size="auto"
                  bgColor="none"
                  showText
                  text={t('EmployerPositions.RequestInterview')}
                  customStyles={takeActionStyles}
                  icon={Interview}
                  iconStyles={iconStyles}
                  onClick={() => {
                    handleRequestInterview();
                    setOpenTakeActions(false);
                  }}
                />
              </li>
            )}
            <li aria-hidden>
              <Button
                size="auto"
                bgColor="none"
                showText
                text={t('CandidatePositionSummaryCard.HireCandidate')}
                customStyles={takeActionStyles}
                icon={Hire}
                iconStyles={iconStyles}
                onClick={() => {
                  handleHireModal(true);
                  setOpenTakeActions(false);
                }}
              />
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default CandidateActionBtnLeft;
