import { ErrorMessageEn, ErrorMessageEs } from '$constants/alerts';
import { errorHandler } from '$store/utils';
import {
  CandidateReactionsBuilder,
  GetSingleCandidateInput,
  GetSingleCandidateOutput,
} from '../candidate-reactions.types';

export const getSingleCandidateReactions = (
  builder: CandidateReactionsBuilder
) =>
  builder.query<GetSingleCandidateOutput, GetSingleCandidateInput>({
    query: ({ candidateId }) => ({
      url: `/candidate-reactions/${candidateId}/grouped`,
      method: 'GET',
    }),
    providesTags: (result, meta, { candidateId }) => [
      {
        type: 'candidateReactions' as const,
        id: candidateId,
      },
      { type: 'candidateReactions' },
    ],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[getCandidateReactionsEndpoint]',
        lang === 'es'
          ? ErrorMessageEs.ErrorRetrievingReactions
          : ErrorMessageEn.ErrorRetrievingReactions,
        error.data,
        true
      );
      return error.data;
    },
  });
