import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { getCandidateTechnicalInfo } from './queries';
import {
  saveCandidateTechnicalInfo,
  saveCandidateTechnicalInfoInternal,
} from './mutations';

export const candidateTechnicalInfoApi = createApi({
  reducerPath: ReducerPaths.CANDIDATE_TECHNICAL_INFO,
  baseQuery,
  endpoints: (builder) => ({
    getCandidateTechnicalInfo: getCandidateTechnicalInfo(builder),
    saveCandidateTechnicalInfoInternal:
      saveCandidateTechnicalInfoInternal(builder),
    saveCandidateTechnicalInfo: saveCandidateTechnicalInfo(builder),
  }),
});

export const {
  useGetCandidateTechnicalInfoQuery,
  useSaveCandidateTechnicalInfoInternalMutation,
  useSaveCandidateTechnicalInfoMutation,
} = candidateTechnicalInfoApi;
