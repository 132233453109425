import axios, { Method } from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { GET_SHARE } from '../api/endpoints';

export interface Data {
  Url: string;
  ContentType: string;
  Name: string;
  Candidate: string;
  CandidateId: number;
  AdditionalVideoId: number;
}

interface RequestParam {
  url: string;
  method: Method;
}

const getShare = async (id: string) => {
  const options: RequestParam = {
    method: 'GET',
    url: `${GET_SHARE(id)}`,
  };
  try {
    const response = await axios.request(options);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const useGetShare = (id: string) => {
  const [loadingShare, setLoadingShare] = useState<boolean>(true);
  const [data, setData] = useState<Data | null>(null);

  const getSpeedTest = useCallback(async () => {
    const shareResp = await getShare(id);
    setLoadingShare(false);
    setData(shareResp);
  }, [id]);

  useEffect(() => {
    getSpeedTest();
  }, [getSpeedTest]);

  return { loadingShare, data };
};
