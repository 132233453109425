import React, { FC } from 'react';
import { ReactComponent as BackArrow } from '../../../icons/BackArrow.svg';
import classes from './BackButton.module.css';

interface Props {
  color?: string;
  text: string;
  onClick: () => void;
}

const CandidatesBackButton: FC<Props> = ({ color, text, onClick }) => {
  return (
    <button
      className={classes.topBarBackButton}
      data-testid="back-button"
      onClick={onClick}
      type="button"
      style={{
        color,
        border: `1px solid ${color}`,
      }}
    >
      <div>
        <BackArrow fill={color} />
      </div>
      <span>{text}</span>
    </button>
  );
};

CandidatesBackButton.defaultProps = {
  color: 'var(--brand-blue-100)',
};

export default CandidatesBackButton;
