import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CandidateWebPortfoliosModal from '$components/CandidateWebPortfoliosModal/CandidateWebPortfoliosModal';
import { useTranslation, Trans } from 'react-i18next';
import ModalInTailwind from '$components/ModalInTailwind/ModalInTailwind';
import { useAppDispatch } from '$hooks/use-app-dispatch.hook';
import { logoutAuthAction } from '$store/legacy/auth';
import { userLanguage } from '$translations/index';
import { segmentTrack } from '$utils/handleSegment';
import { useAgreeCandidateTosMutation } from '$store/modules/candidate-tos';
import { useAppSelector } from '$hooks/use-app-selector.hook';
import useWindowSize from '../../hooks/useWindowSize';
import AdditionalDocumentsFormContainer from '../AdditionalDocumentsFormContainer/AdditionalDocumentsFormContainer';
import BasicInformationFormModal from '../BasicInformationFormModal/BasicInformationFormModal';
import HomeLayout from '../CandidateHome/HomeLayout';
import CandidateInformationCard from '../CandidateInformation/CandidateInformationCard';
import CurriculumEditableContainer from '../CurriculumEditableContainer/CurriculumEditableContainer';
import EducationalInfoEditableContainer from '../EducationalInfoEditableContainer/EducationalInfoEditableContainer';
import ExtracurricularEditableContainer from '../ExtracurricularEditableContainer/ExtracurricularEditableContainer';
import JobInterestsEditableContainer from '../JobInterestsEditableContainer/JobInterestsEditableContainer';
import LanguageEditableContainer from '../LanguageEditableContainer/LanguageEditableContainer';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import CandidateProgressBar from '../CandidateProgressBar/CandidateProgressBar';
import SkillsEditableContainer from '../SkillsEditableContainer/SkillsEditableContainer';
import TechnicalInfoEditableContainer from '../TechnicalInfoEditableContainer/TechnicalInfoEditableContainer';
import WorkHistoryEditableContainer from '../WorkHistoryEditableContainer/WorkHistoryEditableContainer';
import classes from './Profile.module.css';
import { WorkSummaryEditableContainer } from '../Candidates/WorkSummaryEditableContainer';
import { useCandidateId } from '../../hooks/useCandidateId';
import ReadyModal from '../ReadyModal/ReadyModal';

export type ProfileProps = {
  setIsOpenSplash: React.Dispatch<React.SetStateAction<boolean>>;
};

const Profile = ({ setIsOpenSplash }: ProfileProps) => {
  const { serverCandidate } = useAppSelector((state) => state.auth);
  const [t] = useTranslation('global');
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tocomplete = searchParams.get('tocomplete');
  const [showModal, setShowModal] = useState(false);
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);
  const [showTOSModal, setShowTOSModal] = useState(
    !serverCandidate?.AgreedTermsOfServiceDate
  );
  const navRef = useRef<HTMLDivElement>(null);
  const formSectionRef = useRef<HTMLDivElement>(null);
  const [highlightedSection, setHighlightedSection] = useState(0);
  const linkClicked = useRef({ status: false });
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  const [readyForInterview, setReadyForInterview] = useState(false);
  const candidateId = useCandidateId();
  const { serverUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [agreeCandidateTos] = useAgreeCandidateTosMutation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--scroll-padding',
      `${navRef?.current?.offsetHeight}px`
    );
  }, [navRef]);

  useEffect(() => {
    if (windowSize && windowSize.width) {
      setIsMobile(windowSize.width < 768);
    }
  }, [windowSize, setIsMobile]);

  const updateLinkClicked = (status: boolean) => {
    linkClicked.current.status = status;
  };
  const highlightSection = (
    sectionNumber: number,
    el: HTMLDivElement,
    navHeight: number
  ) => {
    const elDistFromTop: number = el.getBoundingClientRect().top;
    const elHeight: number = el.offsetHeight;
    if (sectionNumber === 1 && !(elDistFromTop < navHeight)) {
      setHighlightedSection((prev) =>
        sectionNumber !== prev ? sectionNumber : prev
      );
    }
    if (elDistFromTop <= navHeight && elDistFromTop + elHeight >= navHeight) {
      setHighlightedSection((prev) =>
        sectionNumber !== prev ? sectionNumber : prev
      );
    }
  };

  const handleScroll = useCallback(() => {
    if (formSectionRef.current && window !== undefined && navRef.current) {
      const navHeight = navRef?.current?.offsetHeight;
      const sections = formSectionRef.current?.childNodes;
      sections.forEach((el, ind) => {
        if (ind === 0 || linkClicked.current.status) {
          return;
        }

        const section = el as HTMLDivElement;
        highlightSection(ind, section, navHeight);
      });
    }
  }, []);

  const acceptTosHandler = () => {
    setShowTOSModal(false);
    agreeCandidateTos({ candidateId }).then(() => {
      segmentTrack('Accepted Terms and Conditions');
    });
  };
  const declineTosHandler = () => {
    segmentTrack('Declined Terms and Conditions');
    dispatch(logoutAuthAction(userLanguage));
  };

  useEffect(() => {
    if (window !== undefined && isMobile !== undefined && !isMobile) {
      window.addEventListener('scroll', handleScroll as EventListener);
    }
    if (window !== undefined && isMobile !== undefined && isMobile) {
      window.removeEventListener('scroll', handleScroll as EventListener);
      setHighlightedSection(0);
    }
  }, [handleScroll, isMobile]);

  useEffect(() => {
    if (tocomplete) {
      if (tocomplete === 'cv') {
        const element = document.querySelector(`#section-1`);
        if (element) {
          element.scrollIntoView(true);
        }
        navigate('/home');
      } else if (tocomplete === 'skills') {
        const element = document.querySelector(`#section-4`);
        if (element) {
          element.scrollIntoView(true);
        }
        navigate('/home');
      }
    }
  }, [tocomplete, navigate]);

  return (
    <>
      <HomeLayout isProfile hideContent={showModal}>
        <div
          data-testid="profile-info-container"
          className={classes.infoContainer}
        >
          <div className={classes.infoContainer}>
            <CandidateInformationCard
              setShowModal={setShowModal}
              setShowPortfolioModal={setShowPortfolioModal}
            />
          </div>
        </div>
        <div
          ref={formSectionRef}
          data-testid="profile-forms-container"
          className={classes.formsContainer}
        >
          <div id="section-0">
            <CandidateProgressBar
              setHighlightedSection={setHighlightedSection}
              updateLinkClicked={updateLinkClicked}
              setReady={setReadyForInterview}
              setIsOpenSplash={setIsOpenSplash}
            />
          </div>
          <ProfileMenu
            link={navRef}
            highlightedSectionIndex={highlightedSection}
            updateLinkClicked={updateLinkClicked}
            setHighlightedSection={setHighlightedSection}
          />
          <div id="section-1">
            <CurriculumEditableContainer isActive={highlightedSection === 1} />
          </div>
          <div>
            <WorkSummaryEditableContainer isActive={false} />
          </div>
          <div id="section-2">
            <WorkHistoryEditableContainer isActive={highlightedSection === 2} />
          </div>
          <div id="section-3">
            <JobInterestsEditableContainer
              isActive={highlightedSection === 3}
            />
          </div>
          <div id="section-4">
            <SkillsEditableContainer isActive={highlightedSection === 4} />
          </div>
          <div id="section-5">
            <EducationalInfoEditableContainer
              isActive={highlightedSection === 5}
            />
          </div>
          <div id="section-6">
            <LanguageEditableContainer isActive={highlightedSection === 6} />
          </div>
          <div id="section-7">
            <ExtracurricularEditableContainer
              isActive={highlightedSection === 7}
            />
          </div>
          <div id="section-8">
            <TechnicalInfoEditableContainer
              isActive={highlightedSection === 8}
            />
          </div>
          <div id="section-9">
            <AdditionalDocumentsFormContainer
              isActive={highlightedSection === 9}
            />
          </div>
        </div>
      </HomeLayout>
      {showModal && <BasicInformationFormModal setShowModal={setShowModal} />}
      {readyForInterview && (
        <ReadyModal
          isOpen={readyForInterview}
          setIsOpen={setReadyForInterview}
        />
      )}
      <CandidateWebPortfoliosModal
        isOpen={showPortfolioModal}
        onClose={() => {
          setShowPortfolioModal(false);
        }}
        candidateId={candidateId}
        userId={serverUser?.Id as number}
      />
      <ModalInTailwind
        isOpen={showTOSModal}
        closeModal={() => {
          // dont do anything
        }}
      >
        <div className="rounded bg-white p-4 flex justify-center flex-col gap-4">
          <h1 className="text-brand-blue100 text-2xl font-bold">
            {t('candidateTosAndPrivacyPolicy.modalTitle')}
          </h1>
          <div>
            <Trans
              i18nKey="candidateTosAndPrivacyPolicy.modalContent"
              ns="global"
              components={{
                TosLink: (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  <a
                    href="https://info.simera.io/terms-of-service"
                    target="_blank"
                    rel="noreferrer"
                    className="underline text-brand-blue100"
                    onClick={() => {
                      segmentTrack('Click On Terms of Service');
                    }}
                  />
                ),
                PrivacyPolicy: (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  <a
                    href="https://info.simera.io/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                    className="underline text-brand-blue100"
                    onClick={() => {
                      segmentTrack('Click On Privacy Policy');
                    }}
                  />
                ),
                AgreeBtnText: t('candidateTosAndPrivacyPolicy.buttonAgree'),
              }}
            />
          </div>
          <div className="flex justify-between">
            <button
              type="button"
              className="text-brand-blue100 w-fit self-center py-2 rounded hover:underline"
              onClick={declineTosHandler}
              data-testid="declineTos"
            >
              {t('candidateTosAndPrivacyPolicy.buttonDecline')}
            </button>
            <button
              type="button"
              className="bg-brand-blue100 w-fit self-center px-4 py-2 text-white rounded"
              onClick={acceptTosHandler}
              data-testid="agreeTos"
            >
              {t('candidateTosAndPrivacyPolicy.buttonAgree')}
            </button>
          </div>
        </div>
      </ModalInTailwind>
    </>
  );
};

export default Profile;
