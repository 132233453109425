import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import classes from './EmployersTable.module.css';
import Spinner from '../../../Spinner/Spinner';
import { EmployerColumn } from '../../../../constants/CompanyColumn';
import EmployerActionButtons from './EmployerActionButtons/EmployerActionButtons';
import { EmployerData } from '../../../../types/candidates';

interface Props {
  data: EmployerData[];
  children: React.ReactNode;
  loading: boolean;
}

const EmployersTable: FC<Props> = ({ data, children, loading }) => {
  const [t] = useTranslation('global');

  const mappedData = data.map((employer) => ({
    Id: employer.Id,
    Name: `${employer.User.FirstName} ${employer.User.LastName}`,
    Email: employer.User.Email,
    Company: employer.Company.Name,
  }));
  const columns = useMemo<ColumnDef<EmployerColumn>[]>(
    () => [
      {
        header: `${t('Employers.Id')}`,
        accessorKey: 'Id',
      },
      {
        header: `${t('Employers.Name')}`,
        accessorKey: 'Name',
      },
      {
        header: `${t('Employers.Email')}`,
        accessorKey: 'Email',
      },
      {
        header: `${t('Employers.Company')}`,
        accessorKey: 'Company',
      },
    ],
    [t]
  );
  const { getHeaderGroups, getRowModel } = useReactTable({
    data: mappedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <div className={classes.tableContainer} data-testid="candidate-table">
      <div className={classes.tableScroll}>
        {loading ? (
          <div className={classes.spinnerContainer}>
            <Spinner secondary height="100%" width="100%" />
          </div>
        ) : (
          <table>
            <thead>
              {getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className={classes.tableHead}>
                  {headerGroup.headers.map((header) => (
                    <th
                      className={classes.tableHead}
                      key={header.id}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                  <th
                    className={classes.tableHead}
                    style={{
                      textAlign: 'center',
                      zIndex: 1,
                    }}
                  >
                    {t('CompaniesTable.Actions')}
                  </th>
                </tr>
              ))}
            </thead>
            <tbody>
              {getRowModel().rows.map((row) => (
                <tr key={row.id} className={classes.clickableRow}>
                  {row.getVisibleCells().map((cell) => (
                    <td className={classes.cell} key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                  <td
                    aria-label="action buttons column"
                    className={classes.cell}
                  >
                    <EmployerActionButtons
                      employer={
                        data.find(
                          (employer) => employer.Id === row.original.Id
                        )!
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {children}
    </div>
  );
};

export default EmployersTable;
