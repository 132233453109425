enum FeedbackType {
  CANDIDATE_NOT_MEET_REQUIREMENTS,
  CANDIDATE_NOT_CULTURAL_FIT,
  PREFERRED_ANOTHER_CANDIDATE,
  OTHER,
  CANDIDATE_COMMUNICATION_NOT_STRONG_ENOUGH,
  CANDIDATE_PERSONALITY_DOESNT_MATCH_OUR_NEEDS,
}

export default FeedbackType;
