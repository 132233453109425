import { ErrorMessageEn } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  LoadCandidateNotesByCPSInput,
  LoadCandidateNotesByCPSOutput,
} from '../candidates.types';

export const loadCandidateNotesByCPS = (builder: CandidateBuilder) =>
  builder.query<LoadCandidateNotesByCPSOutput, LoadCandidateNotesByCPSInput>({
    query: (options) => ({
      url: `/candidate-notes/candidate/${options.candidateId}/position/${
        options.positionId ?? '0'
      }`,
      method: 'GET',
    }),
    transformErrorResponse: (error) => {
      errorHandler(
        '[loadCandidateNotesByCPSEndpoint]',
        ErrorMessageEn.GetCandidatesFail,
        error.data,
        true
      );
      return error.data;
    },
    providesTags: () => [{ type: 'CandidateNotes' }],
  });
