import PositionDto from '../../../../types/serverTypes/positionDto';
import Application from './Application/Application';
import classes from './ApplicationsList.module.css';

interface Props {
  applications: PositionDto[] | undefined;
  title: string;
  Arrow?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isOpen?: boolean;
  toogleOpen?: () => void;
}

const ApplicationsList = ({
  applications,
  title,
  Arrow,
  isOpen,
  toogleOpen,
}: Props) => {
  if (!applications) return null;

  return (
    <div className={classes.container}>
      <div
        onClick={toogleOpen}
        className={`${classes.titleContainer} ${Arrow && classes.cursor}`}
        aria-hidden
      >
        <h2 className={classes.title}>{title}</h2>
        {Arrow && <Arrow fill="black" />}
      </div>
      {isOpen && (
        <div className={classes.applications}>
          {applications.map((item: any) => (
            <Application jobPosition={item} key={item.Id} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ApplicationsList;

ApplicationsList.defaultProps = {
  Arrow: undefined,
  isOpen: true,
  toogleOpen: undefined,
};
