import { FC, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HamburgerMenu } from '$icons/hamburgerMenu.svg';
import { ReactComponent as LogoBlue } from '../../images/logoBlue.svg';
import AccountButton from '../AccountButton/AccountButton';
import classes from './EmployerLayout.module.css';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import AddNewJobModal from '../AddNewJobModal/AddNewJobModal';
import AddNewPositionButton from './AddNewPositionButton';
import EmployerTermOfServiceModal from './EmployerTermOfService/EmployerTermOfServiceModal';
import MakePayrollRequestButton from './MakePayrollRequestButton/MakePayrollRequestButton';

interface HomeLayoutProps {
  children?: React.ReactNode;
  isProfile?: boolean;
  isEmployees?: boolean;
  isPositions?: boolean;
  externalIsModalPositionOpen?: boolean | undefined;
}

const EmployerLayout: FC<HomeLayoutProps> = ({
  children,
  isProfile,
  isEmployees,
  isPositions,
  externalIsModalPositionOpen,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openTermOfService, setOpenTermOfService] = useState(false);
  const { t } = useTranslation('global');
  const [isModalPositionOpen, setIsModalPositionOpen] = useState(false);

  const toggleIsModalPositionOpen = useCallback(
    () => setIsModalPositionOpen((prev) => !prev),
    []
  );

  useEffect(() => {
    if (externalIsModalPositionOpen) {
      setIsModalPositionOpen(true);
    }
  }, [externalIsModalPositionOpen]);

  const serverEmployer = JSON.parse(
    localStorage.getItem('ServerEmployerResponse') || '{}'
  );

  useEffect(() => {
    if (serverEmployer && serverEmployer.AgreedTermsOfServiceDate === null) {
      setOpenTermOfService(true);
    }
  }, [serverEmployer]);

  return (
    <div data-testid="employer-container" className={classes.Container}>
      {openMenu && (
        <DropdownMenu
          className={classes.DropdownMenu}
          isEmployer
          setModal={setOpenMenu}
          onAddPosition={toggleIsModalPositionOpen}
        />
      )}
      <nav className={classes.Nav}>
        <div className={classes.Logo} data-testid="employer-logo">
          <LogoBlue />
        </div>
        {!isProfile && (
          <>
            <div
              aria-hidden
              className={`${classes.Positions} ${
                isPositions && classes.ActiveNavItem
              }`}
              data-testid="positions-tab"
            >
              <Link className={classes.Link} to="/employer-dashboard/positions">
                {t('EmployerDashboard.Positions')}
              </Link>
            </div>
            <div
              aria-hidden
              className={`${classes.Employees} ${
                isEmployees && classes.ActiveNavItem
              }`}
            >
              <Link
                className={classes.Link}
                to="/employer-dashboard/team-members"
              >
                {t('EmployerDashboard.TeamMembers')}
              </Link>
            </div>
            <div className={classes.PositionButton}>
              {isEmployees ? (
                <MakePayrollRequestButton />
              ) : (
                <AddNewPositionButton onClick={toggleIsModalPositionOpen} />
              )}
            </div>
          </>
        )}

        <div
          className={classes.Account}
          style={{ marginLeft: isProfile ? 'auto' : '0' }}
        >
          <AccountButton
            isProfile
            t={t}
            action={() => setOpenMenu(!openMenu)}
          />
        </div>

        <button
          className={`${classes.MobileHamburgerMenu}`}
          onClick={() => setOpenMenu(!openMenu)}
          type="button"
          aria-label="Menu"
        >
          <HamburgerMenu />
        </button>
      </nav>
      {children && <div className={classes.Content}>{children}</div>}
      <AddNewJobModal
        onClose={toggleIsModalPositionOpen}
        modalIsOpen={isModalPositionOpen}
      />
      <EmployerTermOfServiceModal
        modalIsOpen={openTermOfService}
        employerId={serverEmployer?.Id}
        setOpenTermOfService={setOpenTermOfService}
      />
    </div>
  );
};

EmployerLayout.defaultProps = {
  children: null,
  isProfile: false,
  isEmployees: false,
  isPositions: false,
  externalIsModalPositionOpen: undefined,
};

export default EmployerLayout;
