import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import en from 'dayjs/locale/en';
import es from 'dayjs/locale/es';
import {
  GetCandidateInterviewsOutput,
  InterviewSlotStatusTypes,
  useGetCandidateInterviewsQuery,
} from '$store/modules/interview';
import { DAYJS_DATE_FORMAT, DAYJS_TIME_FORMAT } from '$constants/datetimes';
import { ValidLanguages, userLanguage } from '$translations/index';
import InterviewPositionCard from './InterviewPositionCard';

const CandidateInterviewLayout = () => {
  dayjs.extend(relativeTime);
  dayjs.locale(userLanguage === ValidLanguages.es ? es : en);

  const { data, isLoading } = useGetCandidateInterviewsQuery();
  const [t] = useTranslation('global');

  const [requestedInterviews, setRequestedInterviews] = useState<
    GetCandidateInterviewsOutput[]
  >([]);
  const [confirmedInterviews, setConfirmedInterviews] = useState<
    GetCandidateInterviewsOutput[]
  >([]);

  useEffect(() => {
    if (!isLoading) {
      const tempRequested: any = [];
      const tempConfirmed: any = [];
      data?.forEach((singleInterviewData) => {
        if (singleInterviewData.Status === InterviewSlotStatusTypes.Requested) {
          tempRequested.push(singleInterviewData);
        } else {
          tempConfirmed.push(singleInterviewData);
        }
      });

      setRequestedInterviews(tempRequested);
      setConfirmedInterviews(tempConfirmed);
    }
  }, [isLoading, data]);

  return (
    <div className="container">
      {requestedInterviews.length > 0 &&
        requestedInterviews.map((singleInterview) => {
          return (
            <InterviewPositionCard
              key={`request_interview_position_card_${singleInterview.Id}`}
              interviewData={singleInterview}
            />
          );
        })}

      {confirmedInterviews.length > 0 && (
        <div className="bg-white p-8">
          <h1 className="font-semibold text-2xl mb-4">
            {t('candidateJobInterview.upcomingInterviews')}
          </h1>
          <div className="flex flex-row gap-4 overflow-x-auto">
            {confirmedInterviews.map((singleConfirmed) => {
              const dayjsDate = dayjs.tz(
                singleConfirmed.InterviewDate,
                singleConfirmed.Timezone ?? ''
              );
              const date = dayjsDate.local().format(DAYJS_DATE_FORMAT);
              const time = dayjsDate.local().format(DAYJS_TIME_FORMAT);
              return (
                <div
                  className="bg-gray-100 min-w-fit p-4 border border-gray-200 rounded"
                  key={`confirmed_interview_${singleConfirmed.Id}`}
                >
                  <div className="font-semibold mb-4">
                    <div className="capitalize">{date}</div>
                    <div>{`${time} ${t(
                      'candidateJobInterview.localTime'
                    )}`}</div>
                  </div>
                  <div className="mb-4 text-gray-400">
                    <div data-testid="interviewer_company">
                      <span className="font-semibold">
                        {t('candidateJobInterview.company')}:
                      </span>{' '}
                      {singleConfirmed.User.Employer.Company.Name}
                    </div>
                    <div data-testid="interviewer_position">
                      <span className="font-semibold">
                        {t('candidateJobInterview.position')}:
                      </span>{' '}
                      {
                        singleConfirmed.CandidatePositionStatus.Position
                          .JobPosition.title
                      }
                    </div>
                  </div>
                  <div className="font-semibold text-sm text-gray-400 first-letter:capitalize">
                    {dayjsDate.local().fromNow()}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default CandidateInterviewLayout;
