import type { FC } from 'react';
import classes from './Button.module.css';

interface ButtonProps {
  text?: string | null;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  iconStyles?: {};
  secondIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  secondIconStyles?: {};
  bgColor?: string;
  size?: string;
  showText?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  submit?: boolean;
  transparentBordered?: boolean;
  disabled?: boolean;
  value?: string | undefined;
  invertTextContent?: boolean;
  customStyles?: {};
  styling?: {};
  height?: string;
  textStyles?: {};
}

const defaultProps: ButtonProps = {
  icon: null,
  iconStyles: {},
  secondIcon: null,
  secondIconStyles: {},
  bgColor: 'white',
  showText: false,
  size: '100%',
  text: null,
  onClick: undefined,
  submit: false,
  transparentBordered: false,
  disabled: false,
  value: undefined,
  invertTextContent: false,
  customStyles: {},
  styling: {},
  height: undefined,
  textStyles: {},
};

const Button: FC<ButtonProps> = ({
  icon: Icon,
  iconStyles,
  secondIcon: SecondIcon,
  secondIconStyles,
  text,
  bgColor,
  size,
  showText,
  onClick,
  submit,
  transparentBordered,
  disabled,
  value,
  invertTextContent,
  customStyles,
  styling,
  height,
  textStyles,
}) => {
  let disabledColor;
  if (disabled) {
    disabledColor = 'gray';
  } else {
    disabledColor = bgColor;
  }

  return (
    <button
      data-testid="test-button"
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      style={{
        width: size,
        height,
        backgroundColor: transparentBordered ? 'transparent' : disabledColor,
        border: transparentBordered
          ? '2px solid rgba(84, 134, 192, 0.5)'
          : 'none',
        ...customStyles,
      }}
      value={value}
      className={`${classes.Btn} ${styling}`}
      onClick={onClick}
    >
      {invertTextContent && Icon && showText ? (
        <>
          {text}
          {Icon && <Icon {...iconStyles} />}
        </>
      ) : (
        <>
          {Icon && <Icon {...iconStyles} />}
          {showText && <span style={{ ...textStyles }}>{text}</span>}
          {SecondIcon && <SecondIcon {...secondIconStyles} />}
        </>
      )}
    </button>
  );
};

Button.defaultProps = defaultProps;

export default Button;
