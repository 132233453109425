import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PositionCardInternal from './PositionCardInternal';
import Spinner from '../../Spinner/Spinner';
import PositionHeader from '../PositionHeader/PositionHeader';
import classes from '../PositionPage.module.css';
import useInfiniteScroll from '../hooks/useInfiniteScroll';
import EmptyState from '../EmptyState/EmptyState';
import PositionDto from '../../../types/serverTypes/positionDto';
import { useFilteredPositions } from '../../../hooks/use-filtered-positions';
import { useGetAllPositionsQuery } from '../../../store/modules/companies';

const PositionsPage = () => {
  const [page, setPage] = useState<number>(1);

  const [t] = useTranslation('global');
  const { data: allPositions } = useGetAllPositionsQuery({});

  const { positions, pageInfo, isLoading, isFetching } =
    useFilteredPositions(page);

  const { lastElementRef } = useInfiniteScroll(
    isLoading,
    pageInfo.hasNextPage,
    setPage
  );

  if (isLoading)
    return (
      <div style={{ display: 'flex' }}>
        <Spinner secondary />
      </div>
    );

  const renderPositions = (rawData: PositionDto[]) => {
    const data: PositionDto[] = [];
    rawData.forEach((obj) => {
      if (!data.some((uniqueObj) => uniqueObj.Id === obj.Id)) {
        data.push(obj);
      }
    });

    if (data?.length === 0) {
      return (
        <div className={classes.emptyPositions}>
          <EmptyState
            text={t('SimeraInternal.EmptyPositions')}
            description=""
            secondary
          />
        </div>
      );
    }
    return data.map((position, idx) => {
      if (positions.length === idx + 1) {
        return (
          <div ref={lastElementRef} key={`position-${position.Id}`}>
            <PositionCardInternal
              position={position}
              key={`position-${position.Id}`}
            />
          </div>
        );
      }
      if (idx === 0) {
        return (
          <div key={`position-${position.Id}`}>
            <PositionCardInternal
              position={position}
              key={`position-${position.Id}`}
            />
          </div>
        );
      }
      return (
        <PositionCardInternal
          key={`position-${position.Id}`}
          position={position}
        />
      );
    });
  };

  return (
    <div className={classes.wrapper} data-testid="container">
      <PositionHeader setPage={setPage} allPositions={allPositions ?? []} />
      <div className={classes.content}>
        {isFetching && page === 1 ? (
          <div style={{ display: 'flex' }}>
            <Spinner secondary />
          </div>
        ) : (
          renderPositions(positions)
        )}
      </div>
    </div>
  );
};

export default PositionsPage;
