import { useTranslation } from 'react-i18next';
import { FC, useEffect, useRef } from 'react';
import { ReactComponent as Close } from '../../icons/CloseX.svg';
import classes from './ConfirmDeletionModal.module.css';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface ConfirmDeletionModalProps {
  SetModal: React.Dispatch<React.SetStateAction<boolean>>;
  Delete: (index: number, deleteId: number) => void;
  DeleteId?: number;
  Index?: number;
  secondary?: boolean;
}

const ConfirmDeletionModal: FC<ConfirmDeletionModalProps> = ({
  SetModal,
  Delete,
  DeleteId,
  Index,
  secondary,
}) => {
  const [t] = useTranslation('global');
  const parentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(parentRef, () => SetModal(false));

  const handleDelete = () => {
    Delete(Index!, DeleteId!);
    SetModal(false);
  };

  useEffect(() => {
    // add listener for when user presses "enter" key
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleDelete();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // removing rule as handle delete depend on prop functions that will change to often
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const container = secondary ? classes.Container_secondary : classes.Container;
  const yes = secondary ? classes.Yes_secondary : classes.Yes;
  const cancel = secondary ? classes.Cancel_secondary : classes.Cancel;
  return (
    <div className={container} onClick={() => SetModal(false)} aria-hidden>
      <div
        className={classes.Centered}
        onClick={(e) => e.stopPropagation()}
        aria-hidden
        ref={parentRef}
      >
        <div className={classes.Content}>
          <Close
            width="10"
            height="10"
            fill="#232428"
            className={classes.Close}
            onClick={() => SetModal(false)}
          />

          <p className={classes.Text}>{t('delete.question1')}</p>
          <div className={classes.ButtonContainer}>
            <button type="button" className={yes} onClick={handleDelete}>
              {t('delete.yes')}
            </button>
            <button
              type="button"
              className={cancel}
              onClick={() => SetModal(false)}
            >
              {t('delete.cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
ConfirmDeletionModal.defaultProps = {
  DeleteId: 0,
  Index: 0,
  secondary: false,
};

export default ConfirmDeletionModal;
