import { useTranslation } from 'react-i18next';
import { useGetCandidateEducationQuery } from '../store/modules/candidate-education';
import { useGetAdditionalDocumentsByCandidateIdQuery } from '../store/modules/additional-documents';
import { useGetCandidateExtracurricularQuery } from '../store/modules/candidate-extracurricular';
import { useGetCandidateTechnicalInfoQuery } from '../store/modules/candidate-technical-info';
import { useGetCandidateJobInterestQuery } from '../store/modules/candidate-job-interest';
import { useGetWorkHistoryByCandidateIdQuery } from '../store/modules/work-experience';
import { useGetCandidateLanguagesQuery } from '../store/modules/candidate-language';
import { useGetCandidateSkillsQuery } from '../store/modules/candidate-skill';

export const useCandidateInfo = (candidateId: number | undefined) => {
  const [, i18n] = useTranslation('global');
  const { data: educationalInfo } = useGetCandidateEducationQuery(
    {
      candidateId: candidateId ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !candidateId }
  );
  const { data: additionalDocuments } =
    useGetAdditionalDocumentsByCandidateIdQuery(
      {
        candidateId: candidateId ?? 0,
        lang: i18n.language as 'en' | 'es',
      },
      { skip: !candidateId }
    );
  const { data: additionalTags } = useGetAdditionalDocumentsByCandidateIdQuery(
    {
      candidateId: candidateId ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !candidateId }
  );
  const { data: extracurricular } = useGetCandidateExtracurricularQuery(
    {
      candidateId: candidateId ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !candidateId }
  );
  const { data: jobInterest } = useGetCandidateJobInterestQuery(
    {
      candidateId: candidateId ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !candidateId }
  );
  const { data: languages } = useGetCandidateLanguagesQuery(
    {
      candidateId: candidateId ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !candidateId }
  );
  const { data: skills } = useGetCandidateSkillsQuery(
    {
      candidateId: candidateId ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !candidateId }
  );
  const { data: technicalInfo } = useGetCandidateTechnicalInfoQuery(
    {
      candidateId: candidateId ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !candidateId }
  );
  const { data: workHistory } = useGetWorkHistoryByCandidateIdQuery(
    {
      candidateId: candidateId ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !candidateId }
  );

  return {
    additionalDocuments,
    educationalInfo,
    extracurricular,
    additionalTags,
    technicalInfo,
    jobInterest,
    workHistory,
    languages,
    skills,
  };
};
