import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { languagesDefaultValue } from '../../../../constants/formDefaultValues';
import { FormTypes } from '../../../../types/user_info';
import { errorHandler } from '../../../utils';
import {
  CandidateLanguageBuilder,
  GetCandidateLanguageInput,
  GetCandidateLanguageOutput,
} from '../candidate-language.types';

export const getCandidateLanguages = (builder: CandidateLanguageBuilder) =>
  builder.query<GetCandidateLanguageOutput, GetCandidateLanguageInput>({
    query: ({ candidateId }) => ({
      url: `/candidate-languages/${candidateId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map((lang) => ({
              type: 'candidateLanguages' as const,
              id: lang?.Id,
            })),
            { type: 'candidateLanguages', id: 'LIST' },
          ]
        : [{ type: 'candidateLanguages', id: 'LIST' }],

    transformResponse: (response: FormTypes['languagesBackendPayload'][]) => {
      if (response.length === 0) {
        return languagesDefaultValue;
      }
      return response.map((language: FormTypes['languagesBackendPayload']) => ({
        Id: language?.Id,
        LanguageName: { val: language?.LanguageName },
        LanguageProficiency: { val: language?.LanguageProficiency },
        Score: { val: `${language?.Score}` },
      }));
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setLanguagesAction]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
