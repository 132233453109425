import { ChangeEventHandler, FC, Ref, useState } from 'react';
import { ChangeHandler } from 'react-hook-form';
import classes from './BaseTestArea.module.css';

interface LineTextAreaProps {
  label: string;
  onBlur: ChangeHandler;
  name: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement> | undefined;
  refProp: Ref<any>;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  errorText?: string;
  counter: number;
  maxLength?: number;
  secondary?: boolean;
}

const defaultProps = {
  defaultValue: '',
  disabled: false,
  placeholder: '',
  errorText: '',
  maxLength: 600,
  secondary: false,
};

const BaseTextArea: FC<LineTextAreaProps> = ({
  label,
  onBlur,
  name,
  defaultValue,
  disabled,
  placeholder,
  errorText,
  refProp,
  onChange,
  counter,
  maxLength,
  secondary,
}) => {
  const [focused, setFocused] = useState(false);

  const color = secondary ? 'var(--brand-green-100)' : 'var(--brand-blue-100)';
  return (
    <div data-testid="textarea-container" className={classes.TextAreaContainer}>
      <p className={classes.Label} data-testid="textarea-placeholder">
        {label}
      </p>
      <div>
        <div className={classes.TextAreaFlex}>
          <textarea
            style={{
              borderColor: focused ? color : '',
            }}
            onFocus={() => setFocused(true)}
            data-testid="base-textarea"
            ref={refProp}
            name={name}
            className={classes.LineStyle}
            onBlur={onBlur}
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={disabled}
            maxLength={maxLength}
            onChange={onChange}
          />
        </div>
        <div data-testid="textarea-counter" className={classes.TextAreaCounter}>
          {`${counter}/${maxLength}`}
        </div>
      </div>
      {errorText !== '' && (
        <p className={classes.ErrorText} data-testid="textarea-error">
          {errorText}
        </p>
      )}
    </div>
  );
};
BaseTextArea.defaultProps = defaultProps;
export default BaseTextArea;
