import { useAppDispatch, useAppSelector } from '../../../../hooks';
import PositionDto from '../../../../types/serverTypes/positionDto';
import {
  addPosition,
  concatPositions,
  removePosition,
  updateStatePosition,
  setPositions,
  activatePositionSlice,
  deactivatePositionSlice,
} from '../companies.reducer';

export const usePositions = () => {
  const dispatch = useAppDispatch();
  const { positions } = useAppSelector((state) => state.company);

  const concatPositionsAction = (data: PositionDto[]) => {
    dispatch(concatPositions(data));
  };
  const addPositionAction = (position: PositionDto) => {
    dispatch(addPosition(position));
  };
  const removePositionAction = (positionId: number) => {
    dispatch(removePosition(positionId));
  };

  const updatePositionAction = (position: PositionDto) => {
    dispatch(updateStatePosition(position));
  };

  const deactivatePositionAction = (positionId: number) => {
    dispatch(deactivatePositionSlice(positionId));
  };

  const activatePositionAction = (positionId: number) => {
    dispatch(activatePositionSlice(positionId));
  };

  const setPositionsAction = (data: PositionDto[]) => {
    dispatch(setPositions(data));
  };

  return {
    concatPositionsAction,
    addPositionAction,
    removePositionAction,
    updatePositionAction,
    setPositionsAction,
    deactivatePositionAction,
    activatePositionAction,
    positions,
  };
};
