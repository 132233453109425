import { FC } from 'react';
import Slider from 'rc-slider';
import { TFunction } from 'react-i18next';
import { Controller, UseFormSetValue } from 'react-hook-form';
import ChipList from '../ChipList/ChipList';
import MultipleTagSelector from '../MultipleTagSelector/MultipleTagSelector';
import {
  SliderActiveDotStyle,
  SliderDefaultValue,
  SliderDotStyle,
  SliderHandleStyle,
  SliderHandleStyleInternal,
  SliderMax,
  SliderMin,
  SliderRailStyle,
  SliderTrackStyle,
  SliderTrackStyleInternal,
} from '../../constants/sliderStyles';
import NumberInput from '../NumberInput/NumberInput';
import classes from './JobInterestsForm.module.css';
import FormsCard from '../FormsCard/FormsCard';
import { JobPosition } from '../../types/candidates';
import { AutocompleteOption, JobInterest } from '../../types/user_info';

const { Range } = Slider;

interface JobInterestsFormProps {
  jobPositions: JobPosition[] | undefined;
  setValue: UseFormSetValue<JobInterest>;
  selectedTags: AutocompleteOption[] | undefined;
  addToJobInterestList: (option: AutocompleteOption) => void;
  salary: number[];
  t: TFunction<'global', undefined>;
  secondary?: boolean;
  control: any;
}
const JobInterestsForm: FC<JobInterestsFormProps> = ({
  jobPositions,
  setValue,
  selectedTags,
  addToJobInterestList,
  salary,
  t,
  control,
  secondary,
}) => {
  const trackStyle = !secondary
    ? [SliderTrackStyle]
    : [SliderTrackStyleInternal];

  const handleStyle = !secondary
    ? [SliderHandleStyle, SliderHandleStyle]
    : [SliderHandleStyleInternal, SliderHandleStyleInternal];

  return (
    <FormsCard>
      <MultipleTagSelector
        arrayName="JobInterest"
        label={t('jobs.jobTitle')}
        options={jobPositions ?? []}
        setValue={setValue}
        selectedTags={selectedTags ?? []}
        secondary={secondary}
      />
      <ChipList
        defaultTags={jobPositions ? jobPositions.slice(0, 7) : []}
        onClickAction={addToJobInterestList}
        selectedTags={selectedTags ?? []}
        secondary={secondary}
      />
      <div>
        <p className={classes.SalaryLabel}>{t('jobs.salary')}</p>
        <div className={classes.salaryButtonsContainer}>
          <NumberInput
            label={t('jobs.salaryMin')}
            value={`$${salary?.[0].toLocaleString().replace(',', '.')}`}
            separator
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, '');
              if (Number(value) < SliderMax) {
                setValue('Salary', [Number(value), salary?.[1]], {
                  shouldDirty: selectedTags ? selectedTags?.length > 0 : false,
                });
              }
            }}
          />

          <NumberInput
            label={t('jobs.salaryMax')}
            value={`$${salary?.[1].toLocaleString().replace(',', '.')}`}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, '');
              if (Number(value) <= SliderMax)
                setValue('Salary', [salary?.[0], Number(value)], {
                  shouldDirty: selectedTags ? selectedTags?.length > 0 : false,
                });
            }}
          />
        </div>

        <div className={classes.SalaryContainer}>
          <Controller
            name="Salary"
            control={control}
            defaultValue={SliderDefaultValue}
            rules={{
              validate: {
                salaryMin: (value) =>
                  value[0] < value[1] || `${t('jobs.errorSalary')}`,
                hasSelectedTags: () =>
                  (selectedTags?.length || 0) > 0 ||
                  `${t('jobs.ErrorShouldSelectOne')}`,
              },
            }}
            render={({ field, formState: { errors } }) => {
              return (
                <div className={classes.rangeContainer}>
                  {errors?.Salary && (
                    <p className={classes.errorText}>
                      {(errors?.Salary as any)?.message}
                    </p>
                  )}
                  <div className={classes.rangeContainerSlider}>
                    <p className={classes.leftSalary}>$1</p>
                    <div style={{ width: '100%' }}>
                      <Range
                        min={SliderMin}
                        max={SliderMax}
                        className={classes.SalarySlider}
                        allowCross
                        step={50}
                        defaultValue={SliderDefaultValue}
                        railStyle={SliderRailStyle}
                        dotStyle={SliderDotStyle}
                        activeDotStyle={SliderActiveDotStyle}
                        handleStyle={handleStyle}
                        trackStyle={trackStyle}
                        onChange={field.onChange}
                        ref={field.ref}
                        value={field.value.map((e: any) => Number(e))}
                      />
                    </div>
                    <p className={classes.rightSalary}>+$25K</p>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    </FormsCard>
  );
};
JobInterestsForm.defaultProps = {
  secondary: false,
};
export default JobInterestsForm;
