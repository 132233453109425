import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { successHandler, errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  HireCandidateInput,
  HireCandidateOutput,
} from '../candidates.types';

export const hireCandidate = (builder: CandidateBuilder) =>
  builder.mutation<HireCandidateOutput, HireCandidateInput>({
    query: (args) => ({
      url: '/candidate-position-status/hire-candidate',
      method: 'PATCH',
      body: args.data,
      responseHandler: (response) => response.text(),
    }),
    invalidatesTags: (res, err, { data: { candidateId } }) => [
      { type: 'Candidate', Id: candidateId },
      { type: 'Candidates' },
      { type: 'CandidatePositionStatus' },
    ],
    transformResponse: (res, meta, args) => {
      successHandler(
        (args.lang ?? 'en') === 'en'
          ? SuccessMessageEn.HIRE_CANDIDATE
          : SuccessMessageEs.HIRE_CANDIDATE,
        true
      );

      return args.data;
    },
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[hireCandidateEndpoint]',
        (args.lang ?? 'en') === 'es'
          ? ErrorMessageEs.CandidatePositionStatusError
          : ErrorMessageEn.CandidatePositionStatusError,
        err,
        true
      );

      return err.data;
    },
  });
