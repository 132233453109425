import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { JobInterest } from '../../types/user_info';
import classes from './JobInterestsEditableContainer.module.css';

interface Props {
  secondary?: boolean;
  jobInterest: JobInterest | undefined;
  isEmployee?: boolean;
}
const DEFAULT_PRICE = '600';
const JobInterestReadOnly: FC<Props> = ({
  secondary,
  jobInterest,
  isEmployee,
}) => {
  const simeraPriceString = process.env.REACT_APP_SIMERA_PRICE ?? DEFAULT_PRICE;
  const simeraPrice = parseFloat(simeraPriceString);
  const [t] = useTranslation('global');
  const borderColor = secondary
    ? 'var(--brand-green-10-new)'
    : 'var(--brand-blue-10)';
  const color = secondary ? 'var(--brand-green-10)' : 'var(--brand-blue-100)';

  const chipClass = secondary ? classes.ChipSecondary : classes.Chip;

  return (
    <section
      data-testid="jobs-readonly"
      className={classes.JobsReadOnlyContainer}
    >
      <div className={classes.Row}>
        {jobInterest?.JobInterest?.map((job) => (
          <div key={job.Id} className={chipClass}>
            <p className={classes.ChipText}>{job.title}</p>
          </div>
        ))}
      </div>

      {!isEmployee ? (
        <p
          data-testid="jobs-readonly-subtitle"
          style={{
            borderColor,
            color,
          }}
          className={classes.SalaryTitle}
        >
          {t('jobs.salaryShort')}
          <span
            className={classes.SalaryNumbers}
            style={{
              color,
            }}
          >
            {`$${jobInterest?.Salary[0]} - $${jobInterest?.Salary[1]}`}
          </span>
        </p>
      ) : (
        <div
          data-testid="jobs-readonly-subtitle"
          style={{
            borderColor,
            color,
          }}
          className={classes.SalaryTitle}
        >
          {t('jobs.salaryShortEstimated')}
          <span
            className={classes.SalaryNumbers}
            style={{
              color,
            }}
          >
            {`$${(jobInterest?.Salary[0] ?? 0) + simeraPrice} - $${
              (jobInterest?.Salary[1] ?? 0) + simeraPrice
            }`}
          </span>
          <span className={classes.SalaryTitleDetails}>
            <span>i</span>
            <div
              style={{
                borderColor,
                color,
              }}
              className={classes.SalaryTitleMoreDetails}
            >
              {t('jobs.salaryShortEstimatedDescription')}
            </div>
          </span>
        </div>
      )}
    </section>
  );
};
JobInterestReadOnly.defaultProps = {
  secondary: false,
  isEmployee: false,
};

export default JobInterestReadOnly;
