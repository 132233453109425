import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { segmentTrack } from '../../utils/handleSegment';

interface LastPlayedVideoInterface {
  data: any;
  element: null | HTMLVideoElement;
}

export const lastPlayedVideo = createSlice({
  name: 'lastPlayedVideo',
  initialState: {
    data: null,
    element: null,
  },
  reducers: {
    setPlayedVideoData: (
      state: LastPlayedVideoInterface,
      action: PayloadAction<any>
    ) => {
      state.data = action.payload.data;
      state.element = action.payload.element;
    },
    triggerSegmentOnCloseModalTrack: (state: LastPlayedVideoInterface) => {
      if (state.data && state.element) {
        if (
          state?.element?.currentTime !== state?.element?.duration &&
          state?.element?.currentTime !== 0
        ) {
          segmentTrack('Early Video Exit', {
            ...state.data,
            duration: state?.element?.duration,
            time_watched: state?.element?.currentTime,
          });
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPlayedVideoData, triggerSegmentOnCloseModalTrack } =
  lastPlayedVideo.actions;

export default lastPlayedVideo.reducer;
