/* eslint-disable no-param-reassign */
import { FC, useState, ChangeEventHandler, Ref } from 'react';
import { ChangeHandler } from 'react-hook-form';
import classes from './BulletTextArea.module.css';

interface Props {
  label: string;
  onBlur: ChangeHandler;
  name: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement> | undefined;
  refProp: Ref<any>;
  defaultValue?: string;
  placeholder?: string;
  errorText?: string;
  maxLength?: number;
  secondary?: boolean;
}
const BulletTextArea: FC<Props> = ({
  label,
  onBlur,
  name,
  onChange,
  refProp,
  defaultValue,
  placeholder,
  errorText,
  maxLength,
  secondary,
}) => {
  const [length, setLength] = useState(0);
  const [focused, setFocused] = useState(false);
  const bullet = '\u2022';

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);
    if (newLength > length) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    setLength(newLength);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={classes.BulletTextAreaContainer}>
      <p data-testid="textarea-placeholder" className={classes.Label}>
        {label}
      </p>
      <textarea
        data-testid="bullet-textarea"
        style={{
          borderColor:
            focused && !secondary
              ? 'var(--brand-blue-100)'
              : 'var(--brand-green-100)',
        }}
        name={name}
        ref={refProp}
        onBlur={onBlur}
        placeholder={placeholder}
        defaultValue={defaultValue}
        maxLength={maxLength}
        onChange={(e) => {
          handleInput(e);
        }}
        onFocus={() => setFocused(true)}
        rows={10}
      />
      <div data-testid="textarea-counter" className={classes.TextAreaCounter}>
        {`${length}/${maxLength}`}
      </div>
      {errorText !== '' && (
        <p className={classes.ErrorText} data-testid="textarea-error">
          {errorText}
        </p>
      )}
    </div>
  );
};

BulletTextArea.defaultProps = {
  defaultValue: '',
  placeholder: '',
  errorText: '',
  maxLength: 4096,
  secondary: false,
};

export default BulletTextArea;
