import { ReactNode } from 'react';
import HeaderDefault from './HeaderDefault';

const ShareLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="w-full min-h-screen bg-[#F1F3F6] flex flex-col">
      <HeaderDefault />
      {children}
    </div>
  );
};

export default ShareLayout;
