import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFindHiredCandidatePositionStatusByCompanyQuery } from '../../../store/modules/candidates';
import EmptyState from '../../Positions/EmptyState/EmptyState';
import EmployeeCard from '../EmployeeCard/EmployeeCard';
import classes from './EmployeesList.module.css';
import Spinner from '../../Spinner/Spinner';

const EmployeesList: FC = () => {
  const [t, i18n] = useTranslation('global');
  const { data, isLoading } = useFindHiredCandidatePositionStatusByCompanyQuery(
    {
      lang: i18n.language as 'en' | 'es',
    }
  );

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (data && data.length === 0) {
    return (
      <div className={classes.Container}>
        <EmptyState
          text={t('EmployeesPage.NoEmployeesText')}
          description={t('EmployeesPage.NoEmployeesDescription')}
        />
      </div>
    );
  }
  return (
    <div className={classes.Container}>
      <h1>{t('EmployerDashboard.TeamMembers')} </h1>
      <ul>
        {data?.map((position) => (
          <EmployeeCard
            key={position.Id}
            employee={position.Candidate}
            jobTitle={position.Position.JobPosition.title}
            acceptanceDate={new Date(position.acceptedTime)}
            positionId={position.Position.Id}
            statusId={position.Id}
          />
        ))}
      </ul>
    </div>
  );
};

export default EmployeesList;
