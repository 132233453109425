import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { AdditionalTagResponse } from '../../../../types/candidates';
import { errorHandler } from '../../../utils';
import {
  AdditionalTagBuilder,
  GetAdditionalTagsByCandidateIdInput,
  GetAdditionalTagsByCandidateIdOutput,
} from '../additional-tag.types';

export const getAdditionalTagsByCandidateId = (builder: AdditionalTagBuilder) =>
  builder.query<
    GetAdditionalTagsByCandidateIdOutput,
    GetAdditionalTagsByCandidateIdInput
  >({
    query: ({ candidateId }) => ({
      url: `/candidate-additional-tag/candidate/${candidateId}`,
      method: 'GET',
    }),
    transformResponse(response: AdditionalTagResponse[]) {
      return response.map((tag) => ({
        Id: tag?.Id,
        title: tag?.Name,
      }));
    },
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ Id }) => ({
              type: 'additionalTags' as const,
              id: Id,
            })),
            { type: 'additionalTags', id: 'LIST' },
          ]
        : [{ type: 'additionalTags', id: 'LIST' }],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[LOAD_ADDITIONAL_TAGS_BY_CANDIDATE_IN]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
