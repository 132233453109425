import { FC, useState } from 'react';
import { TFunction } from 'react-i18next';
import dayjs from 'dayjs';
import { UseFormRegisterReturn } from 'react-hook-form';
import classes from './WorkHistoryFormContainer.module.css';
import { ReactComponent as LocationIcon } from '../../icons/Location.svg';
import { ReactComponent as TrashIcon } from '../../icons/TrashIcon.svg';
import BasicInput from '../Inputs/BaseInput';
import AutocompleteRHF from '../Autocomplete/AutocompleteRHF';
import countries from '../../constants/countries';
import FormsCard from '../FormsCard/FormsCard';
import ConfirmDeletionModal from '../ConfirmDeletionModal/ConfirmDeletionModal';
import BulletTextArea from '../BulletTextArea/BulletTextArea';
import { JobPosition } from '../../types/candidates';

interface WorkHistoryProps {
  headerText: {
    [key: number]: string;
  };
  row: any;
  index: number;
  removeCard: (index: number, deleteId: number) => void;
  errors: any;
  clearErrors: any;
  setValue: any;
  getValues: any;
  jobPositionName: UseFormRegisterReturn;
  jobTitle: UseFormRegisterReturn;
  company: UseFormRegisterReturn;
  admission: UseFormRegisterReturn;
  egress: UseFormRegisterReturn;
  country: UseFormRegisterReturn;
  description: UseFormRegisterReturn;
  t: TFunction<'global', undefined>;
  jobPositions: JobPosition[] | undefined;
  Id: number;
  removeUnsavedCard: (index: number) => void;
  delId: any;
  newWork?: boolean;
  secondary?: boolean;
}

const WorkHistoryForm: FC<WorkHistoryProps> = ({
  headerText,
  row,
  index,
  removeCard,
  errors,
  clearErrors,
  setValue,
  getValues,
  jobPositionName,
  jobTitle,
  company,
  admission,
  egress,
  country,
  description,
  t,
  jobPositions,
  Id,
  removeUnsavedCard,
  delId,
  newWork,
  secondary,
}) => {
  let egressErrorDate = '';
  let admissionErrorDate = '';
  const [Modal, setModal] = useState(false);

  const workHistoryErrorValue = errors?.workHistory?.[index]
    ? errors?.workHistory?.[index]
    : errors;

  if (
    workHistoryErrorValue?.Egress?.val?.type === 'isValidDate' ||
    workHistoryErrorValue?.Egress?.val?.type === 'isValidFormat'
  ) {
    egressErrorDate = t('workHistory.invalidDate');
  }

  if (
    workHistoryErrorValue?.Admission?.val?.type === 'isValidDate' ||
    workHistoryErrorValue?.Admission?.val?.type === 'isValidFormat'
  ) {
    admissionErrorDate = t('workHistory.invalidDate');
  }

  return (
    <FormsCard key={`whf-${row.id}`}>
      {Modal && (
        <ConfirmDeletionModal
          SetModal={setModal}
          Delete={removeCard}
          Index={index}
          DeleteId={Id}
          secondary={secondary}
        />
      )}
      <div className={classes.formHeader}>
        {!newWork && (
          <div className={classes.formHeaderLabel}>
            <p className={classes.formTitle}>{`${headerText[index]} ${t(
              'workHistory.experience'
            )}`}</p>
          </div>
        )}
        <div className={classes.formHeaderIconContainer}>
          {index !== 0 && delId !== undefined ? (
            <TrashIcon
              className={classes.formHeaderIcon}
              onClick={() => {
                setModal(true);
              }}
            />
          ) : (
            index !== 0 && (
              <TrashIcon
                className={classes.formHeaderIcon}
                onClick={() => removeUnsavedCard(index)}
              />
            )
          )}
        </div>
      </div>
      <div className={classes.jobPositionField}>
        <AutocompleteRHF
          label={t('workHistory.jobPosition')}
          name={jobPositionName.name}
          onBlur={jobPositionName.onBlur}
          onChange={jobPositionName.onChange}
          refProp={jobPositionName.ref}
          options={jobPositions ?? []}
          setValue={setValue}
          currSearchVal={(row.JobPosition && row.JobPosition.title) || ''}
          placeholder={t('workHistory.jobPositionPlaceholder')}
          errorText={
            errors?.workExperience?.[index]?.JobPosition?.title?.type ===
              'required' ||
            errors?.workExperience?.[index]?.JobPosition?.title.type ===
              'jobPositionListed'
              ? t('workHistory.notListed')
              : ''
          }
          clearErrors={clearErrors}
        />
      </div>
      {/* Name of the position in the company */}
      <BasicInput
        name={jobTitle.name}
        onBlur={jobTitle.onBlur}
        onChange={jobTitle.onChange}
        refProp={jobTitle.ref}
        type="text"
        label={t('workHistory.jobTitle')}
        placeholder={t('workHistory.jobTitlePlaceholder')}
        errorText={
          errors?.workExperience?.[index]?.JobTitle?.val?.type ||
          errors?.JobTitle?.val?.type
            ? t('workHistory.requiredField')
            : ''
        }
      />
      <div className={classes.Row}>
        <AutocompleteRHF
          label={t('workHistory.country')}
          name={country.name}
          onBlur={country.onBlur}
          onChange={country.onChange}
          refProp={country.ref}
          options={countries ?? []}
          setValue={setValue}
          placeholder={t('workHistory.countryPlaceholder')}
          icon={LocationIcon}
          errorText={
            errors?.workExperience?.[index]?.Country?.val?.message ||
            errors?.Country?.val?.message
          }
          clearErrors={clearErrors}
          currSearchVal={(row.Country && row.Country.val) || ''}
        />
        {/* Previous company name */}
        <BasicInput
          name={company.name}
          onBlur={company.onBlur}
          onChange={company.onChange}
          refProp={company.ref}
          type="text"
          label={t('workHistory.prevCompany')}
          placeholder={t('workHistory.prevCompanyPlaceholder')}
          errorText={
            errors?.workExperience?.[index]?.Company?.val?.type ||
            errors?.Company?.val?.type
              ? t('workHistory.requiredField')
              : ''
          }
        />
      </div>

      <div className={classes.Dates}>
        <div className={classes.EgressContainer}>
          <BasicInput
            name={admission.name}
            onBlur={admission.onBlur}
            onChange={admission.onChange}
            refProp={admission.ref}
            value={
              getValues && getValues(`workExperience.${index}.Admission.val`)
            }
            type="month"
            minDate="1950-01"
            label={t('workHistory.admission')}
            maxDate={dayjs().format('YYYY/MM').replace('/', '-')}
            placeholder="YYYY-MM"
            errorText={
              errors?.workExperience?.[index]?.Admission?.val?.type ===
                'required' || errors?.Admission?.val?.type === 'required'
                ? t('workHistory.requiredField')
                : ''
            }
            errorDate={admissionErrorDate}
          />
        </div>
        <div className={classes.EgressContainer}>
          <BasicInput
            name={egress.name}
            onBlur={egress.onBlur}
            onChange={egress.onChange}
            refProp={egress.ref}
            value={getValues && getValues(`workExperience.${index}.Egress.val`)}
            type="month"
            minDate="1950-01"
            label={t('workHistory.egress')}
            maxDate={dayjs().format('YYYY/MM').replace('/', '-')}
            placeholder="YYYY-MM"
            errorText={
              errors?.workExperience?.[index]?.Egress?.val?.type ===
                'required' || errors?.Egress?.val?.type === 'required'
                ? t('workHistory.requiredField')
                : ''
            }
            errorDate={egressErrorDate}
          />
          <p className={classes.EgressLabel}>{t('workHistory.egressLabel')}</p>
        </div>
      </div>

      <BulletTextArea
        name={description.name}
        onBlur={description.onBlur}
        onChange={description.onChange}
        refProp={description.ref}
        label={t('workHistory.description')}
        placeholder={t('workHistory.descriptionPlaceholder')}
        errorText={
          errors?.workExperience?.[index]?.Description?.val?.type ||
          errors?.Description?.val?.type
            ? t('workHistory.requiredField')
            : ''
        }
        secondary={secondary}
      />
    </FormsCard>
  );
};

WorkHistoryForm.defaultProps = {
  newWork: false,
  secondary: false,
};

export default WorkHistoryForm;
