import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { UserCandidate } from '../../../../types/serverTypes/userDto';
import { ICreateUser } from '../../../legacy/auth/auth.actions';
import { emailSignUp } from '../../../../api/auth.service';
import {
  CandidateBuilder,
  EmailInternalAuthInput,
  EmailInternalAuthOutput,
} from '../candidates.types';
import {
  SuccessMessageEs,
  SuccessMessageEn,
  ErrorMessageEn,
  ErrorMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import { segmentTrack } from '../../../../utils/handleSegment';

class CustomFetchQueryError extends Error {
  constructor(
    public status: number,
    public data: unknown,
    public error: string
  ) {
    super();
  }
}

const mapUserData = (
  user: ICreateUser,
  auth0Id: string,
  referrerId: string | null
) => ({
  FirstName: user.firstName,
  LastName: user.lastName,
  Email: user.email,
  Phone: user.phone,
  ResidenceCountry: user.residenceCountry,
  SignUpType: 0,
  ...(referrerId && { referrerId }),
  Auth0Id: auth0Id,
  Score: 0,
  IsInternallyCreated: true,
});

export const emailInternalAuth = (builder: CandidateBuilder) =>
  builder.mutation<EmailInternalAuthOutput, EmailInternalAuthInput>({
    async queryFn(arg, _api, _extraOptions, baseQuery) {
      const referralId = localStorage.getItem('referralId');
      const emailResponse = await emailSignUp(
        arg.user.email,
        arg.user.password,
        'candidate'
      );
      const createUserResponse = (await baseQuery({
        url: '/candidates/create',
        method: 'POST',
        body: mapUserData(arg.user, emailResponse.Id ?? '', referralId),
      })) as QueryReturnValue<UserCandidate>;

      if (!createUserResponse.data) {
        errorHandler(
          '[EmailInternalAuthAction]',
          (arg.lang ?? 'en') === 'es'
            ? ErrorMessageEs.USER_NOT_CREATED
            : ErrorMessageEn.USER_NOT_CREATED,
          'Could not create user',
          true
        );
        throw new CustomFetchQueryError(
          500,
          createUserResponse.data,
          'Could not create user'
        );
      }

      window.analytics.identify(createUserResponse.data.user.Id.toString(), {
        name: `${createUserResponse.data.user.FirstName} ${createUserResponse.data.user.LastName}`,
        email: createUserResponse.data.user.Email,
        type: 'Candidate',
        role: 'Candidate',
      });

      segmentTrack('User Registered', {
        account_type: 'Candidate',
        register_type: 'email_pass',
        site_version: localStorage.getItem('appVersion') ?? arg.siteVersion,
      });

      successHandler(
        (arg.lang ?? 'en') === 'es'
          ? SuccessMessageEs.USER_CREATED
          : SuccessMessageEn.USER_CREATED,
        true
      );

      return createUserResponse;
    },
    invalidatesTags: ['Candidates'],
  });
