// WARN: THIS COMPONENT IS DISPLAYED ON GUEST MODE
import React, { FC, useState } from 'react';
import {
  useSearchParams,
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { ReactComponent as CircleDot } from '$icons/CircleDot.svg';
import { useOriginPath } from '$hooks/use-origin-path';
import isNewCandidate from '$utils/isNewCandidate';
import ActiveValues from '$constants/ActiveValues';
import StatusCandidatePosition from '$types/serverTypes/StatusCandidatePosition';
import capitalizedName from '$utils/capitalizedName';
import { ReactComponent as CloseModal } from '$icons/CloseBlue.svg';
import { ReactComponent as GoTo } from '$icons/ForwardArrow.svg';
import { segmentTrack } from '$utils/handleSegment';
import ModalInTailwind from '$components/ModalInTailwind/ModalInTailwind';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';
import { GetCandidateInterviewByCPSOutput } from '$store/modules/interview';
import { DAYJS_DATE_AND_TIME } from '$constants/datetimes';
import classes from './SummaryCardHeader.module.css';

interface Props {
  imgUrl?: string;
  firstName: string | undefined;
  lastName: string | undefined;
  createTime: Date;
  status: string | undefined;
  score?: number;
  activeValue: ActiveValues;
  candidateId: number;
  positionId: number | string;
  statusId: number;
  lastJob: {
    title: string;
    company: string | null;
  };
  yearsOfExperience: number | undefined;
  actionButtons: React.ReactNode;
  isGuest?: boolean;
  jobInterest: { Salary: (number | undefined)[] };
  candidateInterviewStatus?: GetCandidateInterviewByCPSOutput;
}

const DEFAULT_PRICE = '600';

const SummaryCardHeader: FC<Props> = ({
  imgUrl,
  firstName,
  lastName,
  createTime,
  status,
  score,
  activeValue,
  candidateId,
  positionId,
  statusId,
  lastJob,
  yearsOfExperience,
  actionButtons,
  isGuest,
  jobInterest,
  candidateInterviewStatus,
}) => {
  dayjs.extend(timezone);
  const [t] = useTranslation('global');
  const [searchParams] = useSearchParams();
  const { setOriginPath } = useOriginPath();
  const { shareReferenceId } = useParams();
  const navigate = useNavigate();
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const name = `${firstName?.split(' ')[0] || ''} ${
    lastName?.split(' ')[0] || ''
  }`;

  const simeraPriceString = process.env.REACT_APP_SIMERA_PRICE ?? DEFAULT_PRICE;
  const simeraPrice = parseFloat(simeraPriceString);

  const cps = (searchParams.get('cps') ?? ActiveValues.New) as ActiveValues;

  const candidatePositionStatusMap: {
    [key: string]: string;
  } = {
    [StatusCandidatePosition.new]: t(
      'CandidatePositionSummaryCard.NewCandidate'
    ),
    [StatusCandidatePosition.offerSent]: t(
      'CandidatePositionSummaryCard.offerSent'
    ),
    [StatusCandidatePosition.requestInterview]: t(
      'CandidatePositionSummaryCard.requestInterview'
    ),
  };

  return (
    <>
      <div className="flex flex-row justify-between">
        <header className={`w-full ${classes.Container}`}>
          <div className={`gap-4 ${classes.HeaderContainer}`}>
            <div className="flex items-center gap-4">
              <button
                type="button"
                onClick={() => {
                  if (!isGuest) {
                    setOriginPath(window.location.pathname);
                    navigate(
                      `/employer-dashboard/positions/${positionId}/candidates/${candidateId}?statusId=${statusId}`
                    );
                  }
                }}
                className="w-14 shrink-0"
                aria-label={`Go to ${name} profile`}
              >
                <DefaultIcon2
                  picture={imgUrl}
                  firstName={firstName}
                  lastName={lastName}
                  fontSize="text-3xl"
                  addPadding="p-1"
                  hideBorder={false}
                />
              </button>
              <div>
                {candidateInterviewStatus && (
                  <div className="text-employer-main">
                    {`Interview ${candidateInterviewStatus?.Status}: ${dayjs
                      .tz(
                        candidateInterviewStatus?.InterviewDate,
                        candidateInterviewStatus.UserSchedule.Timezone
                      )
                      .local()
                      .format(DAYJS_DATE_AND_TIME)} ${t(
                      'candidateJobInterview.localTime'
                    )}`}
                  </div>
                )}
                <div className="w-full flex flex-row gap-4">
                  <div className="flex items-center gap-4">
                    {!isGuest ? (
                      <Link
                        onClick={() => {
                          setOriginPath(window.location.pathname);
                        }}
                        data-testid="viewProfile"
                        to={`/employer-dashboard/positions/${positionId}/candidates/${candidateId}?statusId=${statusId}`}
                        className={classes.FullName}
                      >
                        {capitalizedName(name)}
                      </Link>
                    ) : (
                      capitalizedName(name)
                    )}
                  </div>
                  {!isGuest && (
                    <Link
                      className={classes.ProfileLink}
                      to={`/employer-dashboard/positions/${positionId}/candidates/${candidateId}?statusId=${statusId}`}
                      onClick={() => {
                        setOriginPath(
                          `${window.location.pathname}?${searchParams}`
                        );
                      }}
                    >
                      {t('CandidatePositionSummaryCard.ViewProfile')}
                    </Link>
                  )}
                </div>
              </div>
              <div className={classes.Labels}>
                {!isGuest && (
                  <div className="pointer-default flex flex-row">
                    <div
                      className={`group relative font-semibold items-center justify-end flex gap-2 ${classes.ScoreContainer}`}
                    >
                      <span className="hidden md:inline">Demand Score</span>
                      <span className="text-2xl">{score}</span>
                      <div
                        className="group-hover:block hidden text-sm font-normal p-2 border rounded z-[1] bg-slate-700 text-white absolute top-full mt-1 md:mt-0 md:top-0 md:left-full w-52 ml-2"
                        data-testid="demandScoreExplanation"
                      >
                        {t(
                          'CandidatePositionSummaryCard.demandScoreExplanation'
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {(isNewCandidate(createTime, true) ||
                  (status as string) === (activeValue as string)) && (
                  <div
                    className={classes.StatusContainer}
                    style={{ display: 'none' }}
                  >
                    <p>
                      {(status && candidatePositionStatusMap[status]) ||
                        t('CandidatePositionSummaryCard.NewCandidate')}
                    </p>
                  </div>
                )}
                {cps === 'all' &&
                  !isNewCandidate(createTime, true) &&
                  (status as string) !== 'new' && (
                    <div
                      className={classes.StatusContainer}
                      style={{ display: 'none' }}
                    >
                      <p>
                        {(status && candidatePositionStatusMap[status]) ||
                          t('CandidatePositionSummaryCard.NewCandidate')}
                      </p>
                    </div>
                  )}
              </div>
            </div>
            {!isGuest && (
              <div className={classes.HeaderRight}>{actionButtons}</div>
            )}
          </div>
          <div className={classes.HeaderData}>
            <div className={classes.HeaderDataTwo}>
              <div className={classes.Data}>
                <p>{t('CandidatePositionSummaryCard.Experience')}</p>
                <p>
                  {yearsOfExperience
                    ? `${yearsOfExperience ?? 0} years +`
                    : 'N/A'}
                </p>
              </div>

              <div className={classes.Data}>
                <p>Salary range</p>
                <p>
                  {jobInterest.Salary &&
                  jobInterest.Salary[0] &&
                  jobInterest.Salary[1]
                    ? `$${(jobInterest?.Salary[0] ?? 0) + simeraPrice} - $${
                        (jobInterest?.Salary[1] ?? 0) + simeraPrice
                      }`
                    : 'N/A'}
                </p>
              </div>
            </div>
            <div className={classes.Data}>
              <p>{t('CandidatePositionSummaryCard.LastJob')}</p>
              <p className={classes.Company}>
                {lastJob?.title || 'N/A'}
                {!isGuest && lastJob.company ? (
                  <>
                    <CircleDot
                      fill="green"
                      width="4"
                      height="4"
                      className={classes.CircDot}
                    />
                    {lastJob.company}
                  </>
                ) : (
                  ''
                )}
              </p>
            </div>
          </div>
        </header>
        {isGuest && (
          <button
            className="bg-guest-button w-fit p-4 text-white rounded self-start text-sm md:text-base"
            type="button"
            onClick={() => {
              setIsGuestModalOpen(true);
              segmentTrack('Shared Position - Log In To See More Clicked');
            }}
          >
            {t('sharedPosition.logInToSeeMore')}
          </button>
        )}
      </div>
      <ModalInTailwind
        isOpen={isGuestModalOpen}
        closeModal={() => {
          setIsGuestModalOpen(false);
          segmentTrack('Shared Position - Closed Log In To See More Modal');
        }}
      >
        <div className="max-w-2xl w-full bg-white p-8 rounded relative">
          <div>
            <span className="font-bold">
              {t('sharedPosition.logInModalTitle')}
            </span>
            <button
              aria-label="close modal"
              type="button"
              onClick={() => {
                segmentTrack(
                  'Shared Position - Closed Log In To See More Modal'
                );
                setIsGuestModalOpen(false);
              }}
            >
              <CloseModal className="absolute right-0 top-0 mr-2 mt-2" />
            </button>
          </div>
          <hr className="my-4" />
          <div className="flex align-middle items-center gap-6 font-semibold">
            <div className="flex align-middle items-center flex-1 w-full">
              <div className="w-12 mr-2">
                <DefaultIcon2
                  picture={imgUrl}
                  firstName={name?.split(' ')[0]}
                  lastName={name?.split(' ')[1]}
                />
              </div>
              <span className="flex-1">{name}</span>
            </div>
            <button
              type="button"
              className="text-brand-blue100"
              onClick={() => {
                segmentTrack('Shared Position - Login Clicked');
                navigate('/employer_login', {
                  state: { shareReferenceId },
                });
              }}
            >
              {t('sharedPosition.login')}
            </button>
            <button
              type="button"
              className="flex align-middle items-center gap-2 text-white bg-brand-blue100 rounded p-3"
              onClick={() => {
                segmentTrack('Shared Position - Register Clicked');
                navigate('/employer_register', {
                  state: { shareReferenceId },
                });
              }}
            >
              {t('sharedPosition.createAccount')}{' '}
              <GoTo className="fill-white" />
            </button>
          </div>
        </div>
      </ModalInTailwind>
    </>
  );
};

SummaryCardHeader.defaultProps = {
  imgUrl: '',
  isGuest: false,
  score: 0,
  candidateInterviewStatus: undefined,
};
export default SummaryCardHeader;
