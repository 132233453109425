import { FC } from 'react';
import { TFunction } from 'react-i18next';
import { useGetProfilePictureQuery } from '$store/modules/uploads';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';
import classes from './AccountButton.module.css';
import { useAppSelector } from '../../hooks';

interface LoginButtonProps {
  action?: () => void;
  t: TFunction<'global', undefined>;
}

const defaultProps = {
  action: () => {},
};
const LoginButton: FC<LoginButtonProps> = ({ action, t }) => {
  const { serverUser } = useAppSelector((state) => state.auth);

  const { data = { Url: '' } } = useGetProfilePictureQuery({});

  return (
    <button
      data-testid="account-button"
      type="button"
      className={classes.Container}
      onClick={action}
    >
      <p data-testid="title" className={classes.TitleLogin}>
        {serverUser
          ? `${serverUser.FirstName.split(' ')[0]} ${
              serverUser.LastName.split(' ')[0]
            }`
          : t('session.login')}
      </p>
      <div className="w-8">
        <DefaultIcon2
          picture={data?.Url}
          hideBorder
          firstName={serverUser?.FirstName}
          lastName={serverUser?.LastName}
        />
      </div>
    </button>
  );
};

LoginButton.defaultProps = defaultProps;

export default LoginButton;
