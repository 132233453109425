import ReactDOM from 'react-dom';
import React, { useRef } from 'react';

interface ModalInTailwindProps {
  isOpen: boolean;
  children: JSX.Element;
  closeModal: () => void;
}

const ModalInTailwind: React.FC<ModalInTailwindProps> = ({
  isOpen,
  children,
  closeModal,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const handleBackgroundClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === modalRef.current) {
      closeModal();
    }
  };
  const modal = document.getElementById('modal');
  if (!modal) return null;
  return isOpen
    ? ReactDOM.createPortal(
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 overflow-hidden flex justify-center items-center cursor-default z-50"
          ref={modalRef}
          onClick={handleBackgroundClick}
          role="button"
          tabIndex={0}
          aria-hidden
        >
          {children}
        </div>,
        modal
      )
    : null;
};

export default ModalInTailwind;
