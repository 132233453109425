import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  UpdateCompanyInput,
  UpdateCompanyOutput,
} from '../companies.types';

export const updateCompany = (builder: CompanyBuilder) =>
  builder.mutation<UpdateCompanyOutput, UpdateCompanyInput>({
    query: (options) => ({
      url: `/companies/${options.companyId}`,
      method: 'PUT',
      body: {
        Name: options.Name,
      },
    }),
    transformResponse: (queryResponse: UpdateCompanyOutput, _, arg) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEs.COMPANY_UPDATE
          : SuccessMessageEn.COMPANY_UPDATE,
        true
      );
      return queryResponse;
    },
    transformErrorResponse: (error, meta, args) => {
      errorHandler(
        '[updateCompany]',
        args.lang === 'en'
          ? ErrorMessageEn.UpdateCompany
          : ErrorMessageEs.UpdateCompany,
        error,
        true
      );
      return error.data;
    },
    invalidatesTags: () => [{ type: 'Company' }],
  });
