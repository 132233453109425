import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import classes from './FilterSelect.module.css';
import { ReactComponent as ArrowDown } from '../../../../../icons/ArrowDown.svg';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import DropdownMenuItem from '../../../../CandidateHome/DropdownMenuItem';
import { toPascalCase } from '../../../../../utils/toPascalCase';

interface Props {
  title: string;
  options: { id: string; text: string }[];
  setCategory: Dispatch<SetStateAction<string>>;
  placeholder: string;
}

export const FilterSelect: FC<Props> = ({
  title,
  options,
  placeholder,
  setCategory,
}) => {
  const [t] = useTranslation('global');
  const [searchParams, setSearchParams] = useSearchParams();
  const [showContent, setShowContent] = useState<boolean>(false);
  const parentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(parentRef, () => setShowContent(false));
  const noneOption = [{ id: 'None', text: t('forms.none') }];
  const filterOptions: { id: string; text: string }[] =
    noneOption.concat(options);

  const handleClick = () => {
    setShowContent((s) => !s);
  };

  const onClick = (option: { id: string; text: string }) => {
    const newParams = new URLSearchParams(searchParams);
    setCategory(option.id);
    if (option.id !== 'None') {
      newParams.set(toPascalCase(title), option.id);
      setSearchParams(newParams);
    } else {
      newParams.delete(toPascalCase(title));
      setSearchParams(newParams);
    }
    setShowContent(false);
  };

  useEffect(() => {
    const optionSelect = searchParams.get(toPascalCase(title));
    if (optionSelect) {
      setCategory(optionSelect);
    }
  }, [title, searchParams, setCategory]);

  return (
    <div className={classes.FilterContainer} ref={parentRef}>
      <p className={classes.Title}>{title}</p>
      <div
        role="button"
        className={classes.Button}
        onClick={handleClick}
        onKeyDown={handleClick}
        tabIndex={0}
        data-testid="test-button"
      >
        <span className={classes.Placeholder}>
          {placeholder === t('forms.none') ? t('forms.select') : placeholder}
        </span>
        <ArrowDown />
      </div>
      {showContent && (
        <div className={classes.DropdownContent} data-testid="dropdownContent">
          {filterOptions.map((option) => (
            <DropdownMenuItem
              text={option.text}
              key={option.id}
              onClick={() => onClick(option)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
