import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  ReferralBuilder,
  GetCandidateReferralIdInput,
  GetCandidateReferralIdOutput,
} from '../referrals.types';

export const getCandidateReferralId = (builder: ReferralBuilder) =>
  builder.query<GetCandidateReferralIdOutput, GetCandidateReferralIdInput>({
    query: () => '/referral-unique-id',
    providesTags: (result, error, Id) => [{ type: 'Referral', Id }],
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[getCandidateReferralId]',
        (args.lang ?? 'en') === 'en'
          ? ErrorMessageEn.InfoFail
          : ErrorMessageEs.InfoFail,
        err,
        true
      );

      return err.data;
    },
  });
