import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { DayPicker } from './DayPicker';
import classes from './LeftCalendarModal.module.css';
import { TimePicker } from './TimePicker';
import TimeZoneDropwdown from './TimeZoneDropdown/TimeZoneDropdown';
import { getZone } from '../../../../utils/getZone';
import { SetSchedulesType } from '../CalendarModalTypes';

dayjs.tz.setDefault('America/New_York');

interface LeftCalendarModalProps {
  setSchedules: React.Dispatch<React.SetStateAction<SetSchedulesType[]>>;
  schedules: SetSchedulesType[];
  setTimezone: React.Dispatch<React.SetStateAction<string>>;
  timezone: string;
}

export const LeftCalendarModal = ({
  setSchedules,
  schedules,
  setTimezone,
  timezone,
}: LeftCalendarModalProps) => {
  const [day, setDay] = useState<Dayjs>(dayjs().startOf('day'));
  const [t] = useTranslation('global');

  const selectDay = useCallback((date) => {
    if (date.format('d').includes('0')) {
      return;
    }
    // not allow days in past to be selected
    if (date.isBefore(dayjs(), 'day')) {
      return;
    }
    setDay(date);
  }, []);

  const zone = getZone(timezone);
  return (
    <div className={classes.leftContainer}>
      <h1 className={classes.title}>{t('Calendar.selectDate')}</h1>
      <h2 className={classes.subtitle}>{dayjs().format('MMM YYYY')}</h2>
      <DayPicker day={day} selectDay={selectDay} />
      <TimePicker
        schedules={schedules}
        setSchedules={setSchedules}
        day={day}
        zone={zone}
        timezone={timezone}
      />
      <TimeZoneDropwdown
        timezone={timezone}
        setTimezone={setTimezone}
        zone={zone}
      />
    </div>
  );
};
