import { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classes from './CandidateInternalForm.module.css';
import BaseInput from '../../Inputs/BaseInput';
import { ReactComponent as LocationIcon } from '../../../icons/Location.svg';
import { emailRegex, NameRegex, phoneRegex } from '../../../constants/regex';
import Button from '../../Buttons/Button';
import AutocompleteRHF from '../../Autocomplete/AutocompleteRHF';
import countries from '../../../constants/countries';
import Spinner from '../../Spinner/Spinner';
import { useEmailInternalAuthMutation } from '../../../store/modules/candidates';
import { useAppSelector } from '../../../hooks';

interface FormTypes {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  companyName?: string;
  phone: string;
  residenceCountry?: string;
}

interface Props {
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CandidateInternalForm = ({ setModalIsOpen }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormTypes>({ mode: 'onChange' });
  const [t, i18next] = useTranslation('global');
  const { version } = useAppSelector((state) => state.auth);
  const [emailInternalAuth, { isLoading }] = useEmailInternalAuthMutation();

  // Form declaration
  const firstName = register('firstName', {
    required: t('register.nameInputValid').toString(),
    maxLength: {
      value: 100,
      message: t('register.invalidFirstNameInput').toString(),
    },
    pattern: {
      value: NameRegex,
      message: t('register.invalidFirstNameInput').toString(),
    },
  });
  const lastName = register('lastName', {
    required: t('register.lastNameInputValid').toString(),
    maxLength: {
      value: 100,
      message: t('register.invalidLastNameInput').toString(),
    },
    pattern: {
      value: NameRegex,
      message: t('register.invalidLastNameInput').toString(),
    },
  });
  const email = register('email', {
    required: t('register.emailInputValid').toString(),
    maxLength: {
      value: 100,
      message: t('register.invalidEmailInput').toString(),
    },
    pattern: {
      value: emailRegex,
      message: 'Invalid email',
    },
  });
  const phone = register('phone', {
    pattern: {
      value: phoneRegex,
      message: t('register.phoneInputValid'),
    },
    setValueAs: (value) => {
      if (value) {
        return value.replace(/\s/g, '');
      }
      return value;
    },
  });
  const residenceCountry = register('residenceCountry');

  const submitForm = (values: FormTypes) => {
    emailInternalAuth({
      user: {
        firstName: values.firstName.trim().replace(/\s\s+/g, ' '),
        lastName: values.lastName.trim().replace(/\s\s+/g, ' '),
        email: values.email.toLowerCase().trim(),
        password: process.env.INTERNAL_CANDIDATE_PASSWORD || 'Simera2023',
        phone: values.phone,
        residenceCountry: values?.residenceCountry,
      },
      lang: i18next.language as 'es' | 'en',
      siteVersion: version ?? 'v1',
    });
    setModalIsOpen(false);
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <form
      id="register-form"
      data-testid="register-form"
      className={classes.Form}
      onSubmit={handleSubmit(submitForm)}
    >
      <div className={classes.InputsContainer}>
        <div className={classes.NameInputs}>
          <BaseInput
            onChange={(e: ChangeEvent) => firstName.onChange(e)}
            name={firstName.name}
            onBlur={firstName.onBlur}
            refProp={firstName.ref}
            label={t('register.nameInput')}
            type="text"
            errorText={errors.firstName?.message}
            placeholder={t('common.typeHere')}
            isRequired
          />
          <BaseInput
            onChange={(e: ChangeEvent) => lastName.onChange(e)}
            name={lastName.name}
            onBlur={lastName.onBlur}
            refProp={lastName.ref}
            label={t('register.lastNameInput')}
            type="text"
            errorText={errors.lastName?.message}
            placeholder={t('common.typeHere')}
            isRequired
          />
        </div>
        <div className={classes.companyContainer}>
          <BaseInput
            onChange={(e: ChangeEvent) => email.onChange(e)}
            name={email.name}
            onBlur={email.onBlur}
            refProp={email.ref}
            label={t('register.emailInput')}
            type="text"
            errorText={errors.email?.message}
            placeholder={t('common.typeHere')}
            isRequired
          />
          <BaseInput
            onChange={(e: ChangeEvent) => email.onChange(e)}
            name={phone.name}
            onBlur={phone.onBlur}
            refProp={phone.ref}
            label={t('register.phone')}
            type="text"
            errorText={errors.phone?.message}
            placeholder="+1 123 456 7890"
          />
        </div>
        <AutocompleteRHF
          label={t('infoContainer.ResidenceCountry')}
          name={residenceCountry.name}
          onBlur={residenceCountry.onBlur}
          onChange={residenceCountry.onChange}
          refProp={residenceCountry.ref}
          options={countries}
          setValue={setValue}
          placeholder={t('infoContainer.ResidenceCountryPlaceholder')}
          icon={LocationIcon}
        />
      </div>
      <div className={classes.CreateAccountButton}>
        <Button
          submit
          onClick={handleSubmit(submitForm)}
          text={t('register.createAccBtn')}
          bgColor="#079E88"
          showText
        />
      </div>
    </form>
  );
};

export default CandidateInternalForm;
