import { FC } from 'react';
import classes from './Chip.module.css';
import { ReactComponent as NewDeleteIcon } from '../../icons/NewDeleteIcon.svg';
import { AutocompleteOption } from '../../types/user_info';

interface ChipProps {
  onClickChip: (id: number) => void;
  option: AutocompleteOption;
  secondary?: boolean;
}

const Chip: FC<ChipProps> = ({ onClickChip, option, secondary }) => {
  const clickOption = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    evt.stopPropagation();
    onClickChip(option.Id);
  };
  return (
    <div
      className={classes.chip}
      style={{
        background: secondary ? '#079E88' : 'var(--brand-blue-100)',
      }}
      aria-hidden="true"
      onClick={(e) => clickOption(e)}
    >
      <p className={classes.title} style={{ color: secondary ? '#fff' : ' ' }}>
        {option.title ?? option.Title}
      </p>
      <NewDeleteIcon fill={secondary ? '#E6F5F3' : '#05668D'} />
    </div>
  );
};

Chip.defaultProps = {
  secondary: false,
};

export default Chip;
