import { FC, KeyboardEventHandler, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './EditableContainer.module.css';
import { ReactComponent as PlusIcon } from '../../icons/PlusIcon.svg';
import HoverTooltip from '../Tooltip/HoverTooltip';
import AddMoreFooter from '../Buttons/AddMoreFooter';

interface EditableContainerProps {
  title: string;
  children: React.ReactNode;
  onClickPlus?:
    | MouseEventHandler<HTMLDivElement>
    | KeyboardEventHandler<HTMLDivElement>
    | MouseEventHandler<HTMLButtonElement>;

  hasAddMore?: boolean;
  mobile: boolean;
  addMore?: boolean | string | null | object;
  isActive?: boolean;
  pulseEffect?: boolean;
  hoverText?: string;
  isGeneralNotes?: boolean;
}

const EditableContainerNotes: FC<EditableContainerProps> = ({
  title,
  children,
  onClickPlus,
  hasAddMore,
  mobile,
  addMore,
  isActive,
  pulseEffect,
  hoverText,
  isGeneralNotes,
}) => {
  const [t] = useTranslation('global');

  const className = pulseEffect
    ? classes.editableNotesContainerActionItem
    : classes.editableContainerActionItem;
  return (
    <section
      className={`${
        !mobile ? classes.editableContainer : classes.editableContainerMobile
      } ${isActive ? classes.activeSection : ''}`}
      data-testid="editable-container"
    >
      {!isGeneralNotes && (
        <div className={classes.editableContainerHeader}>
          <p className={classes.editableContainerTitle}>
            {addMore ? `${t('common.new')} ${addMore}` : title}
          </p>

          <div
            data-testid="editable-actions"
            className={classes.editableContainerActionsNotes}
          >
            {hasAddMore && (
              <HoverTooltip text={hoverText || ''}>
                <div
                  aria-label="Add more"
                  className={className}
                  onClick={onClickPlus as MouseEventHandler}
                  onKeyDown={onClickPlus as KeyboardEventHandler}
                  role="button"
                  tabIndex={0}
                >
                  <PlusIcon width={20} />
                </div>
              </HoverTooltip>
            )}
          </div>
        </div>
      )}
      {addMore && <div className={classes.editableContainerDivider} />}

      <div className={classes.editableContainerContent}>
        {children}
        {isGeneralNotes && (
          <>
            <div className={classes.editableContainerDivider} />
            <br />
            <AddMoreFooter
              label={t('Notes.AddGeneralNote')}
              onClick={onClickPlus as () => void}
              secondary
            />
          </>
        )}
      </div>
    </section>
  );
};

EditableContainerNotes.defaultProps = {
  onClickPlus: undefined,
  hasAddMore: false,
  addMore: false,
  isActive: false,
  pulseEffect: false,
  hoverText: '',
  isGeneralNotes: false,
};
export default EditableContainerNotes;
