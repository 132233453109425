import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import useOnClickOutside from '$hooks/useOnClickOutside';
import { ReactComponent as DownArrow } from '../../../../../icons/ArrowDownTimezone.svg';
import { ReactComponent as WorldIcon } from '../../../../../icons/WorldIcon.svg';
import classes from './TimeZoneDropDown.module.css';
import { TIMEZONES } from '../../../../../constants/datetimes';
import { getZone } from '../../../../../utils/getZone';

interface TimeZoneDropdownProps {
  setTimezone: React.Dispatch<React.SetStateAction<string>>;
  timezone: string;
  zone: string;
}

const TimeZoneDropwdown = ({
  timezone,
  setTimezone,
  zone,
}: TimeZoneDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const handleClick = (value: string) => {
    setTimezone(value);
    setShowDropdown(false);
  };
  const timezoneRef = useRef(null);

  useOnClickOutside(timezoneRef, () => setShowDropdown(false));
  return (
    <div className={classes.container} ref={timezoneRef}>
      <div
        className={classes.dropdownMenu}
        onClick={() => setShowDropdown((prev) => !prev)}
        aria-hidden
      >
        <WorldIcon />
        <p className={classes.selected}>{`${zone} Time - ${timezone.replace(
          '_',
          ' '
        )} (${dayjs().tz(timezone).format('h:mma')})`}</p>
        <DownArrow />
      </div>
      {showDropdown && (
        <div className="rounded border-solid shadow mt-2 absolute bg-white border border-gray-300">
          {Object.keys(TIMEZONES).map((singleTimezoneName) => {
            return (
              <div
                className={`${classes.dropdownItem} hover:bg-gray-200 px-2 py-1`}
                onClick={() =>
                  handleClick(
                    TIMEZONES[singleTimezoneName as keyof typeof TIMEZONES]
                  )
                }
                aria-hidden
                key={singleTimezoneName}
              >
                <WorldIcon />
                <span className={classes.menuItems}>{`${getZone(
                  TIMEZONES[singleTimezoneName as keyof typeof TIMEZONES]
                )} Time - ${TIMEZONES[
                  singleTimezoneName as keyof typeof TIMEZONES
                ].replace('_', ' ')} (${dayjs()
                  .tz(TIMEZONES[singleTimezoneName as keyof typeof TIMEZONES])
                  .format('h:mma')})`}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TimeZoneDropwdown;
