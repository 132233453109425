import PasswordRecoveryRequestDto from '../types/serverTypes/passwordRecoveryRequestDto';
import TokenValidationDto from '../types/serverTypes/tokenValidationDto';

const baseURL = process.env.REACT_APP_API_URL;

// Internal
export const GET_SIGNED_VIDEOS = (candidateId: number) =>
  `${baseURL}/upload/candidate-signed-videos/${candidateId}`;

// User
export const CREATE_USER = `${baseURL}/candidates/create`;
export const CREATE_EMPLOYER = `${baseURL}/employers/create`;
export const CREATE_INTERNAL = `${baseURL}/internal`;

// get candidates
export const GET_CANDIDATES = `${baseURL}/candidates/find-all`;
export const GET_INTERNAL_CANDIDATES = `${baseURL}/candidates/`;

// get user by email
export const GET_USER_BY_EMAIL = (email: string) =>
  `${baseURL}/users/get/email/${email}`;

// get user with token
export const GET_USER_WITH_TOKEN = () => `${baseURL}/users`;

// TODO: Update the endpoint to getByUserId/userId
export const GET_CANDIDATE_BY_USERID = (userId: number) =>
  `${baseURL}/candidates/get/user/${userId}`;

export const GET_CANDIDATE_INFO = () => `${baseURL}/candidates/info-candidate`;

export const GET_CANDIDATE = (candidateId: number) =>
  `${baseURL}/candidates/get/${candidateId}`;
export const UPDATE_CANDIDATE = (candidateId: number) =>
  `${baseURL}/candidates/${candidateId}`;
// CANDIATE NOTES
export const GET_NOTES_BY_CANDIDATE = (
  candidateId: number,
  positionId: number
) =>
  `${baseURL}/candidate-notes/candidate/${candidateId}/position/${positionId}`;

export const CREATE_CANDIDATE_NOTE = () => `${baseURL}/candidate-notes/`;

export const SET_USER_WORK_HISTORY_UNIQUE = (candidateID: number) =>
  `${baseURL}/candidate-experience/add/${candidateID}`;

// Educational Information
export const SET_USER_EDUCATIONAL_INFO = () =>
  `${baseURL}/candidate-education/add-range`;

export const SET_USER_EDUCATIONAL_INFO_INTERNAL = (candidateId: number) =>
  `${baseURL}/candidate-education/add-range/internal/${candidateId}`;

export const GET_USER_EDUCATIONAL_INFO = (candidateId: number) =>
  `${baseURL}/candidate-education/${candidateId}`;

export const SET_USER_EDUCATIONAL_INFO_UNIQUE = (candidateId: number) =>
  `${baseURL}/candidate-education/add/${candidateId}`;

// User skills
export const SET_USER_SKILLS = () => `${baseURL}/candidate-skills/add-range`;
export const SET_USER_SKILLS_INTERNAL = (candidateId: number) =>
  `${baseURL}/candidate-skills/add-range/internal/${candidateId}`;

// Job Interest
export const SET_CANDIDATE_JOB_INTEREST = () =>
  `${baseURL}/candidate-job-interest/add`;

export const SET_CANDIDATE_JOB_INTEREST_INTERNAL = (candidateId: number) =>
  `${baseURL}/candidate-job-interest/add/internal/${candidateId}`;

export const GET_CANDIDATE_JOB_INTEREST = (candidateId: number) =>
  `${baseURL}/candidate-job-interest/${candidateId}`;

export const UPDATE_CANDIDATE_JOB_INTEREST = (jobInterestId: number) =>
  `${baseURL}/candidate-job-interest/${jobInterestId}`;

// POSITIONS
export const SET_POSITION = () => `${baseURL}/positions`;
export const DUPLICATE_POSITION = () => `${baseURL}/positions/duplicate`;
export const DUPLICATE_POSITION_CUSTOM_ROSTER = () =>
  `${baseURL}/positions/duplicate/custom`;
export const GET_CUSTOM_ROSTER = (positionId: number) =>
  `${baseURL}/candidate-position-status/custom-roster/${positionId}`;
export const GET_ALL_POSITIONS = `${baseURL}/positions/all`;
export const GET_ONE_POSITION = (id: number) =>
  `${baseURL}/positions/one/${id}`;
export const DELETE_POSITION = (id: number) => `${baseURL}/positions/${id}`;
export const DEACTIVATE_POSITION = (id: number) =>
  `${baseURL}/positions/deactivate/${id}`;
export const UPDATE_COMPANY = (companyId: number) =>
  `${baseURL}/companies/${companyId}`;

export const ACTIVATE_POSITION = (id: number) =>
  `${baseURL}/positions/activate/${id}`;

// COMPANIES
export const GET_ALL_COMPANIES = `${baseURL}/companies`;
export const GET_PAGINATED_COMPANIES = `${baseURL}/companies/paginated`;
export const GET_EMPLOYEES_BY_COMPANY = (companyId: number) =>
  `${baseURL}/candidate-position-status/employees/company/${companyId}`;
export const TRACKING_HIRED_CANDIDATE = (candidatePositionStatusId: number) =>
  `${baseURL}/candidate-position-status/trackingCandidates/${candidatePositionStatusId}`;
// Position Status
export const ADD_CANDIDATE_TO_POSITION = () =>
  `${baseURL}/candidate-position-status/add-candidate`;

export const REMOVE_CANDIDATE_FROM_POSITION = `${baseURL}/candidate-position-status/remove-candidate`;
export const REJECT_CANDIDATE_FROM_POSITION = `${baseURL}/candidate-position-status/reject-candidate`;

export const ADD_CANDIDATE_TO_POSITION_AGAIN = `${baseURL}/candidate-position-status/add-candidate-again`;

export const HIRE_CANDIDATE = `${baseURL}/candidate-position-status/hire-candidate`;

export const UPDATE_CANDIDATE_POSITION_STATUS_TO_OFFER_SENT = `${baseURL}/candidate-position-status/make-offer`;

export const GET_CANDIDATES_FILTERED = `${baseURL}/candidate-position-status/candidates-filtered`;

export const GET_POSITIONS_FILTERED = `${baseURL}/positions/filter`;

// User extracurriculars
export const SET_USER_EXTRACURRICULARS = () =>
  `${baseURL}/candidate-extracurricular/add-range`;

export const SET_USER_EXTRACURRICULARS_INTERNAL = (candidateId: number) =>
  `${baseURL}/candidate-extracurricular/add-range/internal/${candidateId}`;

export const GET_USER_EXTRACURRICULARS = (candidateId: number) =>
  `${baseURL}/candidate-extracurricular/${candidateId}`;

// Additonal Tags
export const GET_ADDITIONAL_TAGS_BY_CANDIDATE = (candidateId: number) =>
  `${baseURL}/candidate-additional-tag/candidate/${candidateId}`;

export const ADD_ADDITIONAL_TAGS = () => `${baseURL}/candidate-additional-tag/`;
export const UPDATE_ADDITIONAL_TAGS = (candidateId: number) =>
  `${baseURL}/candidate-additional-tag/candidate/${candidateId}`;
// Employers

export const GET_ALL_EMPLOYERS = `${baseURL}/employers/getAll`;

export const GET_EMPLOYER_BY_USERID = (userId: number) =>
  `${baseURL}/employers/getByUserId/${userId}`;

export const GET_EMPLOYER = (employerId: number) =>
  `${baseURL}/employers/get/${employerId}`;

export const UPDATE_EMPLOYER_TERM_OF_SERVICE = (employerId: number) =>
  `${baseURL}/employers/term-of-service/${employerId}`;

export const GET_EMPLOYERS_PAGINATED = () => `${baseURL}/employers`;
export const UPDATE_EMPLOYERS = (employerId: number) =>
  `${baseURL}/employers/${employerId}`;

export const DUPLICATE_POSITION_BY_SHARE_ID = `${baseURL}/positions/duplicate/shared`;

// Candidate Profile
export const UPDATE_SCORE = (formType: string) =>
  `${baseURL}/simera-score/${formType}`;

export const UPDATE_CANDIDATE_PROFILE = () =>
  `${baseURL}/candidates/update-profile`;

export const UPDATE_CANDIDATE_SCORE = () =>
  `${baseURL}/candidates/update-score`;

export const INTERESTED_EMAIL = `${baseURL}/candidates/email`;

export const CONFIRM_EMAIL = (token: string) =>
  `${baseURL}/users/confirm/${token}`;

export const REQUEST_CONFIRMATION_EMAIL = (email: string) =>
  `${baseURL}/mail/confirmation-email/resend/${email}`;

export const GET_CANDIDATE_REFERRAL_ID = () => `${baseURL}/referral-unique-id`;

export const GET_CANDIDATE_REFERRALS = () => `${baseURL}/candidates/referrals`;

export const IS_VALID_REFERRAL = (referralId: string) =>
  `${baseURL}/referral-unique-id/is-referral/${referralId}`;

export const DELETE_USER_EDUCATION = (id: number) =>
  `${baseURL}/candidate-education/delete/${id}`;

export const DELETE_USER_EXPERIENCE = (id: number) =>
  `${baseURL}/candidate-experience/delete/${id}`;

export const DELETE_USER_EXTRACURRICULAR = (id: number) =>
  `${baseURL}/candidate-extracurricular/delete/${id}`;

export const GET_USER_SKILL = (id: number) =>
  `${baseURL}/candidate-skills/${id}`;

export const DELETE_USER_SKILL = (id: number) =>
  `${baseURL}/candidate-skills/delete/${id}`;

export const UPLOAD_PROFILE_PICTURE = `${baseURL}/upload/profile-picture`;

export const DELETE_PROFILE_PICTURE = `${baseURL}/upload/profile-picture`;
export const UPDATE_FIRST_LOGIN = () =>
  `${baseURL}/candidates/update-first-login`;
export const PASSWORD_RECOVERY_REQUEST = (
  passwordRecoveryRequestDto: PasswordRecoveryRequestDto
): string => {
  const { email, timezoneOffset } = passwordRecoveryRequestDto;
  return `${baseURL}/reset-password-token/${email}?timezoneOffset=${timezoneOffset}`;
};

export const TOKEN_VALIDATION = (
  tokenValidationDto: TokenValidationDto
): string => {
  const { token, timezoneOffset } = tokenValidationDto;
  return `${baseURL}/reset-password-token/validate?token=${token}&timezoneOffset=${timezoneOffset}`;
};

export const RESET_PASSWORD = (): string => `${baseURL}/reset-password-token`;

export const DELETE_CV = `${baseURL}/upload/cv`;

export const GET_EMPLOYEES = `${baseURL}/candidate-position-status/employees`;

//  backgroundtasks
export const GET_TASKS = `${baseURL}/background-tasks`;
export const POST_TASK = `${baseURL}/background-tasks/user-migration`;

// share
export const GET_SHARE = (id: string) => `${baseURL}/share/${id}`;
