import React, { FC } from 'react';
import classes from './CandidateInformationCard.module.css';

interface Props {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  onClick: () => void;
  secondary?: boolean;
}
const SocialMediaLink: FC<Props> = ({
  label,
  icon: Icon,
  onClick,
  secondary,
}) => {
  const fill = secondary ? 'var(--primary-green)' : 'var(--brand-blue-100)';
  return (
    <div
      tabIndex={0}
      role="button"
      className={classes.webLink}
      style={{ color: fill, border: `1px solid ${fill}` }}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <Icon fill={fill} />
      <p className={classes.webLabel}>{label}</p>
    </div>
  );
};

SocialMediaLink.defaultProps = {
  secondary: false,
};

export default SocialMediaLink;
