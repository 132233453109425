import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { RedirectAfterLoginStateType } from '$types/LocationState';
import Spinner from '../Spinner/Spinner';
import { useIsValidReferralQuery } from '../../store/modules/referrals';

const LandingRedirect = () => {
  const [skip, setSkip] = useState<boolean>(true);

  const [, i18next] = useTranslation('global');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referralIdQuery = searchParams.get('referralId');

  const location = useLocation() as RedirectAfterLoginStateType;

  const { isSuccess, isError } = useIsValidReferralQuery(
    {
      lang: i18next.language,
      referralId: referralIdQuery || '',
      navigate,
    },
    { skip }
  );

  useEffect(() => {
    if (referralIdQuery) {
      setSkip((prev) => !prev);
    } else {
      navigate(
        '/login',
        location.state ? { state: { ...location.state } } : undefined
      );
    }
  }, [navigate, referralIdQuery, location.state]);

  useEffect(() => {
    if (isSuccess || isError) {
      navigate('/register');
    }
  }, [isSuccess, isError, navigate]);

  return <Spinner />;
};

export default LandingRedirect;
