import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
import { ReactComponent as UploadIcon } from '../../icons/UploadImportIcon.svg';
import classes from './EmployerLayout.module.css';
import { useAppSelector } from '../../hooks';

interface Props {
  setModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setImportModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  importCandidate?: boolean;
}

const defaultProps = {
  setModalIsOpen: () => {},
  setImportModalIsOpen: () => {},
  importCandidate: false,
};

const AddNewImportCandidateButton: FC<Props> = ({
  setModalIsOpen,
  importCandidate,
  setImportModalIsOpen,
}) => {
  const [t] = useTranslation('global');
  const { serverInternal } = useAppSelector((state) => state.auth);
  const backgroundColor = serverInternal
    ? 'var(--primary-green)'
    : 'var(--brand-blue-100)';

  const setModal = useCallback(() => {
    if (setModalIsOpen && setImportModalIsOpen) {
      if (importCandidate) {
        setImportModalIsOpen(true);
      } else {
        setModalIsOpen(true);
      }
    }
  }, [setModalIsOpen, setImportModalIsOpen, importCandidate]);

  return (
    <div
      className={classes.NewJob}
      role="button"
      style={{
        backgroundColor,
      }}
      data-testid="add-new-position-button"
      onClick={() => {
        setModal();
      }}
      onKeyDown={() => {
        setModal();
      }}
      tabIndex={0}
    >
      <p className={classes.NewJobText}>
        {importCandidate
          ? t('SimeraInternal.ImportCandidates')
          : t('SimeraInternal.AddNewCandidate')}
      </p>
      {importCandidate ? <UploadIcon /> : <AddIcon />}
    </div>
  );
};

AddNewImportCandidateButton.defaultProps = defaultProps;

export default AddNewImportCandidateButton;
