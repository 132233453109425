import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  AdditionalVideoBuilder,
  DeleteAdditionalVideoInput,
} from '../additional-videos.types';

export const deleteAdditionalVideo = (builder: AdditionalVideoBuilder) =>
  builder.mutation<void, DeleteAdditionalVideoInput>({
    query: ({ id }) => ({
      url: `additional-video/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, { id }) => [
      { type: 'additionalVideos', id },
    ],
    transformResponse: (response, meta, { lang }) => {
      if (lang === 'en') {
        successHandler(SuccessMessageEn.AdditionalVideoDeletedSuccess, true);
      } else {
        successHandler(SuccessMessageEs.AdditionalVideoDeletedSuccess, true);
      }
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[AdditionalVideoDeleteError]',
        lang === 'es'
          ? ErrorMessageEs.AdditionalVideoDeleteError
          : ErrorMessageEn.AdditionalVideoDeleteError,
        error,
        true
      );
    },
  });
