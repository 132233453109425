import type { FC } from 'react';
import { Link } from 'react-router-dom';
import classes from './SimeraNavTab.module.css';

interface SimeraNavTabProps {
  isActive: boolean;
  route: string;
  text: string;
}

const SimeraNavTab: FC<SimeraNavTabProps> = ({ isActive, route, text }) => {
  return (
    <Link
      to={route}
      aria-hidden
      className={`${classes.Positions} ${isActive && classes.ActiveNavItem}`}
    >
      <p>{text}</p>
    </Link>
  );
};

export default SimeraNavTab;
