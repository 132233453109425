import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ActiveValues from '../../../../constants/ActiveValues';
import classes from './PositionSwitch.module.css';
import StatusCandidatePosition from '../../../../types/serverTypes/StatusCandidatePosition';

interface Props {
  value: ActiveValues;
  setSwitchingId: React.Dispatch<React.SetStateAction<number | null>>;
  candidatesCount: {
    new: number;
    shortlisted: number;
    declined: number;
    requestInterview: number;
    hired: number;
    offerSent: number;
    all: number;
  };
}

export const PositionSwitch: FC<Props> = ({
  value,
  setSwitchingId,
  candidatesCount,
}) => {
  const [searchParams, setSeachParams] = useSearchParams();

  const [t] = useTranslation('global');

  const changeHandler = (positionStatus: ActiveValues) => {
    const paramsCopy = new URLSearchParams(searchParams);
    paramsCopy.set('cps', positionStatus);
    setSeachParams(paramsCopy);
    setSwitchingId(null);
  };

  return (
    <div className={classes.SwitchContainer}>
      {/* <p
        className={`${classes.SwitchContainerItem} ${
          value === ActiveValues.All && classes.active
        }`}
        onClick={() => changeHandler(ActiveValues.All)}
        aria-hidden
      >
        {t('ChipGroup.All')}
        {candidatesCount.all !== 0 && (
          <span className={classes.candidateCounts}>{candidatesCount.all}</span>
        )}
      </p> */}
      <p
        className={`${classes.SwitchContainerItem} ${
          value === ActiveValues.New && classes.active
        }`}
        onClick={() => changeHandler(ActiveValues.New)}
        aria-hidden
      >
        {t('ChipGroup.New')}
        {candidatesCount[StatusCandidatePosition.new] !== 0 && (
          <span className={classes.candidateCounts}>
            {candidatesCount[StatusCandidatePosition.new]}
          </span>
        )}
      </p>
      <p
        className={`${classes.SwitchContainerItem} ${
          value === ActiveValues.ShortList && classes.active
        }`}
        onClick={() => changeHandler(ActiveValues.ShortList)}
        aria-hidden
      >
        {t('ChipGroup.ShortList')}
        {candidatesCount[StatusCandidatePosition.shortlisted] !== 0 && (
          <span className={classes.candidateCounts}>
            {candidatesCount[StatusCandidatePosition.shortlisted]}
          </span>
        )}
      </p>
      <p
        className={`${classes.SwitchContainerItem} ${
          value === ActiveValues.requestInterview && classes.active
        }`}
        onClick={() => changeHandler(ActiveValues.requestInterview)}
        aria-hidden
      >
        {t('ChipGroup.BeingInterviewed')}
        {candidatesCount[StatusCandidatePosition.requestInterview] !== 0 && (
          <span className={classes.candidateCounts}>
            {candidatesCount[StatusCandidatePosition.requestInterview]}
          </span>
        )}
      </p>
      <p
        className={`${classes.SwitchContainerItem} ${
          value === ActiveValues.OfferSent && classes.active
        }`}
        onClick={() => changeHandler(ActiveValues.OfferSent)}
        aria-hidden
      >
        {t('ChipGroup.OfferSent')}
        {candidatesCount[StatusCandidatePosition.offerSent] !== 0 && (
          <span className={classes.candidateCounts}>
            {candidatesCount[StatusCandidatePosition.offerSent]}
          </span>
        )}
      </p>
    </div>
  );
};
