import { FC } from 'react';
import { UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { InitialFormStateTypes, FieldTypes } from '../../types/user_info';
import classes from './FormContainer.module.css';
import { ReactComponent as FlagLanguage } from '../../icons/LanguageFlag.svg';
import { ReactComponent as TrashIcon } from '../../icons/TrashIcon.svg';
import AutocompleteRHF from '../Autocomplete/AutocompleteRHF';
import SelectInput from '../SelectInput/SelectInput';
import languages from '../../constants/languaje';
import FormsCard from '../FormsCard/FormsCard';
import ConfirmDeletionModal from '../ConfirmDeletionModal/ConfirmDeletionModal';

interface LanguagesFormProps {
  index: number;
  removeLanguage: (index: number, deleteId: number) => void;
  t: TFunction<'global', undefined>;
  setValue: UseFormSetValue<
    | InitialFormStateTypes
    | {
        Id?: number;
        LanguageName: FieldTypes;
        LanguageProficiency: FieldTypes;
        Score?: FieldTypes;
      }
  >;
  headerText: {
    [key: number]: string;
  };
  language: UseFormRegisterReturn;
  languageProf: UseFormRegisterReturn;
  row: any;
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  Id: number;
  removeUnsaved: (index: number) => void;
  delId: any;
  setDelId: any;
  newLanguage?: boolean;
  errors?: any;
  secondary?: boolean;
}
const LanguagesForm: FC<LanguagesFormProps> = ({
  index,
  removeLanguage,
  t,
  setValue,
  headerText,
  language,
  languageProf,
  row,
  modal: Modal,
  Id, // row.Id
  setModal,
  removeUnsaved,
  delId,
  setDelId,
  newLanguage,
  errors,
  secondary,
}) => {
  return (
    <FormsCard>
      {Modal && delId === Id && (
        <ConfirmDeletionModal
          SetModal={setModal}
          Delete={removeLanguage}
          Index={index}
          DeleteId={Id}
          secondary={secondary}
        />
      )}
      {!newLanguage && (
        <div className={classes.languageHeaderText}>
          <p>{`${headerText[index]} ${t('formNames.language')}`}</p>
        </div>
      )}
      {index !== 0 && Id !== undefined ? (
        <TrashIcon
          className={classes.Remove}
          onClick={() => {
            setModal(true);
            setDelId(Id);
          }}
        />
      ) : (
        index !== 0 && (
          <TrashIcon
            className={classes.Remove}
            onClick={() => {
              removeUnsaved(index);
            }}
          />
        )
      )}
      <div className={classes.cardLanguages} key={row.id}>
        <div className={classes.languageContainer}>
          <AutocompleteRHF
            label={t('skills.name')}
            name={language.name}
            onBlur={language.onBlur}
            onChange={language.onChange}
            refProp={language.ref}
            options={languages}
            setValue={setValue}
            icon={FlagLanguage}
            placeholder={t('formNames.languagesPlaceHolder')}
            currSearchVal={row.LanguageName.val}
            errorText={
              errors?.LanguageName ? t('workHistory.requiredField') : ''
            }
          />
        </div>
        <div
          className={classes.languageContainer}
          style={{ marginTop: '0.34rem' }}
        >
          <SelectInput
            name={languageProf.name}
            onBlur={languageProf.onBlur}
            onChange={languageProf.onChange}
            refProp={languageProf.ref}
            label={t('languages.prof')}
            options={[
              { id: 'Basic', text: t('languages.basic') },
              { id: 'Intermediate', text: t('languages.intermediate') },
              { id: 'Advanced', text: t('languages.advanced') },
              { id: 'Native', text: t('languages.native') },
            ]}
            errorText={
              errors?.LanguageProficiency ? t('workHistory.requiredField') : ''
            }
          />
        </div>
      </div>
    </FormsCard>
  );
};

LanguagesForm.defaultProps = {
  newLanguage: false,
  errors: null,
  secondary: false,
};

export default LanguagesForm;
