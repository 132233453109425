import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ActionButtons.module.css';
import { ReactComponent as RightArrow } from '../../../../../icons/ArrowRight.svg';

interface Props {
  setIsOpen: (show: boolean) => void;
  disabled: boolean;
  isLoading: boolean;
}

export const ActionButtons = ({ setIsOpen, disabled, isLoading }: Props) => {
  const [t] = useTranslation('global');
  return (
    <div className={classes.buttonsContainer}>
      {!isLoading && (
        <button
          type="button"
          onClick={() => {
            setIsOpen(false);
          }}
          className={classes.cancelButton}
        >
          {t('AdditionalVideos.buttons.cancel')}
        </button>
      )}
      <button
        type="submit"
        className={classes.importButton}
        disabled={disabled}
        data-testid="submitButton"
      >
        {!isLoading ? (
          <>
            <p>{t('AdditionalVideos.buttons.uploadText')}</p>
            <RightArrow />
          </>
        ) : (
          <div className={classes.Loading} />
        )}
      </button>
    </div>
  );
};
