import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';
import { useOriginPath } from '$hooks/use-origin-path';
import classes from './EmployeeCard.module.css';
import { Candidate } from '../../../types/candidates';

interface Props {
  employee: Candidate;
  jobTitle: string;
  acceptanceDate: Date;
  positionId: number;
  statusId: number;
}

const EmployeeCard: FC<Props> = ({
  employee,
  jobTitle,
  acceptanceDate,
  positionId,
  statusId,
}) => {
  const { setOriginPath } = useOriginPath();
  const [t] = useTranslation('global');
  const navigate = useNavigate();

  const handleNavigate = () => {
    setOriginPath(window.location.pathname);
    navigate(
      `/employer-dashboard/positions/${positionId}/employees/${employee.Id}?statusId=${statusId}`
    );
  };
  return (
    <li className={classes.Card} data-testid="employeeCard">
      <div className={classes.Content}>
        <div className=" w-24">
          <DefaultIcon2
            firstName={employee?.User?.FirstName}
            lastName={employee?.User?.LastName}
            picture={employee?.User?.ProfilePicture?.Url}
            fontSize="text-4xl"
          />
        </div>
        <h3
          className={classes.Name}
        >{`${employee.User?.FirstName} ${employee.User?.LastName}`}</h3>
        <div className={classes.Chip}>
          <p className={classes.ChipText}>{jobTitle}</p>
        </div>
        <p className={classes.EmployeeSince}>
          {`${t('TeamMembers.StartDate')} `}
          {acceptanceDate.toLocaleString('en-us', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </p>
      </div>
      <div
        className={classes.ViewProfileLink}
        onClick={handleNavigate}
        onKeyDown={handleNavigate}
        role="button"
        tabIndex={0}
      >
        {t('CandidatePositionSummaryCard.ViewProfile')}
      </div>
    </li>
  );
};

export default EmployeeCard;
