import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FooterButton from '../FooterButton/FooterButton';
import classes from './Footer.module.css';

interface FooterProps {
  changeStep: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    n: number
  ) => void;
}
const Footer: FC<FooterProps> = ({ changeStep }) => {
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate('/profile', { replace: true });
  };
  const [t] = useTranslation('global');

  return (
    <div data-testid="footer" className={classes.footer}>
      <FooterButton onClickAction={changeStep} direction="up" />
      <p
        aria-hidden="true"
        onClick={goToProfile}
        className={classes.footerText}
      >
        {t('footer.profileLater')}
      </p>
      <FooterButton onClickAction={changeStep} direction="down" />
    </div>
  );
};

export default Footer;
