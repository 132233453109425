import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFieldArray, useForm, useFormState } from 'react-hook-form';
import Skills from './Skills';
import classes from './FormContainer.module.css';
import { InitialFormStateTypes } from '../../types/user_info';
import AddButton from '../Buttons/AddButton';
import { SaveState } from '../../types/ForrmType';
import SaveButton from '../Buttons/SaveButton';
import FormFooter from '../FormFooter/FormFooter';
import CancelModal from '../CancelModal/CancelModal';
import AddMoreFooter from '../Buttons/AddMoreFooter';
import {
  useAddMultipleCandidateSkillsInternalMutation,
  useAddMultipleCandidateSkillsMutation,
  useDeleteCandidateSkillsMutation,
  useGetCandidateSkillsQuery,
} from '../../store/modules/candidate-skill';
import { errorHandler } from '../../store/utils';
import { useAppDispatch } from '../../hooks';
import { candidatesApi } from '../../store/modules/candidates';

interface ContainerProps {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean, continueEditing: boolean) => void;
  show?: boolean;
  secondary?: boolean;
}

const SkillsContainer: FC<ContainerProps> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  secondary,
}) => {
  const MAX_SKILLS_LIMIT = 25;

  const [t, i18next] = useTranslation('global');
  const { candidateId } = useParams();
  const candidateIdFromLocalStorage = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;
  const id = candidateId || candidateIdFromLocalStorage;

  const { data: skills } = useGetCandidateSkillsQuery({
    candidateId: +id,
    lang: i18next.language as 'en' | 'es',
  });
  const dispatch = useAppDispatch();

  const [
    addMultipleCandidateSkillsInternal,
    {
      isLoading: isLoadingInternal,
      isSuccess: isSuccessInternal,
      isError: isErrorInternal,
    },
  ] = useAddMultipleCandidateSkillsInternalMutation();
  const [addMultipleCandidateSkills, { isLoading, isSuccess, isError }] =
    useAddMultipleCandidateSkillsMutation();
  const [deleteCandidateSkill, { isSuccess: isDeleteSuccess }] =
    useDeleteCandidateSkillsMutation();

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: { skills },
  });

  const { dirtyFields, isDirty } = useFormState({ control });

  const {
    fields: fieldsSkills,
    append: appendSkill,
    remove: removeSkill,
  } = useFieldArray({
    control,
    name: 'skills' as never,
  });

  const loadingSkills = useMemo(() => {
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }
    if (isSuccess || isSuccessInternal) {
      return SaveState.FULFILLED;
    }
    if (isError || isErrorInternal) {
      return SaveState.FAILED;
    }
    return SaveState.IDLE;
  }, [
    isError,
    isErrorInternal,
    isSuccess,
    isSuccessInternal,
    isLoading,
    isLoadingInternal,
  ]);

  const [numberOfSkills, setNumberOfSkills] = useState(
    fieldsSkills.length || 1
  );

  useEffect(() => {
    if ((loadingSkills === SaveState.FULFILLED || isDeleteSuccess) && skills) {
      reset({ skills });
    }
  }, [isDeleteSuccess, skills, loadingSkills, reset]);

  const removeFromSkills = async (index: number, deleteId: number) => {
    await deleteCandidateSkill({
      candidateId: deleteId,
      lang: i18next.language as 'en' | 'es',
    });
    dispatch(candidatesApi.util.invalidateTags(['Score']));
    if (isDeleteSuccess) {
      removeSkill(index);
      setNumberOfSkills((count) => count - 1);
    }
  };

  const removeUnsavedCard = (index: number) => {
    removeSkill(index);
    setNumberOfSkills((count) => count - 1);
  };

  const appendFields = () => {
    if (numberOfSkills < MAX_SKILLS_LIMIT) {
      appendSkill({
        CertificationId: { val: '', mult: 3 },
      });
      setNumberOfSkills((s) => s + 1);
    } else {
      errorHandler(
        'skillsScreen',
        'Number of skills limit reached',
        null,
        true
      );
    }
  };

  const saveInfo = async (data: InitialFormStateTypes) => {
    if (dirtyFields?.skills) {
      const modifiedCardIndexes = Object.keys(dirtyFields?.skills);
      if (data?.skills) {
        const modifiedCards = data?.skills.filter((_, ind) =>
          modifiedCardIndexes.includes(ind.toString())
        );

        try {
          if (Number(candidateId)) {
            await addMultipleCandidateSkillsInternal({
              candidateId: Number(candidateId),
              skills: modifiedCards,
              lang: i18next.language as 'en' | 'es',
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
          } else {
            await addMultipleCandidateSkills({
              skills: modifiedCards,
              lang: i18next.language as 'en' | 'es',
            });
            dispatch(candidatesApi.util.invalidateTags(['Score']));
          }
          if (setEdit) {
            setEdit(true);
          }
          window.analytics.track('Skills form submitted');
          reset(
            {},
            {
              keepValues: true,
            }
          );
        } catch (error) {
          // error
        }
      }
    }
  };
  return (
    <div data-testid="skills-form" className={classes.MainContainer}>
      <div className={classes.CardContainer}>
        <form
          data-testid="form-container"
          onSubmit={handleSubmit(saveInfo)}
          className={classes.Container}
        >
          {!isEdit && (
            <div className={classes.ButtonContainer}>
              <AddButton onClick={appendFields} />
            </div>
          )}
          <Skills
            errors={errors.skills}
            t={t}
            register={register}
            fields={fieldsSkills}
            removeSkill={removeFromSkills}
            setValue={setValue}
            removeUnsaved={removeUnsavedCard}
            getValues={getValues}
            secondary={secondary}
          />
          {fieldsSkills.length > 1 && !isEdit && (
            <AddMoreFooter onClick={appendFields} label={t('skills.add')} />
          )}

          <div className={classes.ButtonContainer}>
            {isEdit ? (
              <FormFooter
                onClickCancel={onClickCancel}
                disabled={loadingSkills === SaveState.PENDING || !isDirty}
                saveState={isDirty ? SaveState.IDLE : loadingSkills}
                translate={t}
                submit
              />
            ) : (
              <SaveButton
                disabled={loadingSkills === SaveState.PENDING || !isDirty}
                state={isDirty ? SaveState.IDLE : loadingSkills}
                t={t}
                submit
              />
            )}
          </div>
        </form>
        {show && onClickCancelModal && (
          <CancelModal onClickCancelModal={onClickCancelModal} />
        )}
      </div>
    </div>
  );
};

SkillsContainer.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  secondary: false,
};

export default SkillsContainer;
