import React, { FC } from 'react';

import BasicInput from '../../../../Inputs/BaseInput';
import FormFooter from '../../../../FormFooter/FormFooter';
import CancelModal from '../../../../CancelModal/CancelModal';
import useUpdateCompany from '../../../../../hooks/useUpdateCompany';

interface Props {
  companyId: number;
  companyName: string;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const UpdateCompanyForm: FC<Props> = ({ companyId, companyName, setModal }) => {
  const {
    name,
    handleSubmit,
    t,
    errors,
    onSubmit,
    saveState,
    showCancel,
    handleCancelModal,
    handleCancel,
    isDisabled,
  } = useUpdateCompany(companyId, companyName, setModal);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BasicInput
        type="text"
        onChange={name.onChange}
        onBlur={name.onBlur}
        refProp={name.ref}
        name={name.name}
        label={t('CompaniesTable.Name')}
        isRequired
        errorText={errors?.Name?.message?.toString() || ''}
      />
      <div style={{ marginTop: '1rem' }}>
        <FormFooter
          translate={t}
          saveState={saveState}
          submit
          onClickCancel={handleCancel}
          disabled={isDisabled}
        />
      </div>
      {showCancel && handleCancelModal && (
        <CancelModal onClickCancelModal={handleCancelModal} />
      )}
    </form>
  );
};

export default UpdateCompanyForm;
