import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CandidateWebPortfoliosModal from '$components/CandidateWebPortfoliosModal/CandidateWebPortfoliosModal';
import { useAppSelector } from '$hooks/use-app-selector.hook';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';
import { InitialFormStateTypes } from '../../../types/user_info';
import CandidateInfo from './CandidateInfo/CandidateInfo';
import classes from './CandidateInformationCardEmployer.module.css';
import CandidateScore from './CandidateScore/CandidateScore';
import CandidateSocialMedia from './CandidateSocialMedia/CandidateSocialMedia';
import { Candidate } from '../../../types/candidates';
import { ReactComponent as GoldMedalIcon } from '../../../icons/GoldMedal.svg';
import { useGetScoreQuery } from '../../../store/modules/candidates';
import ActiveValues from '../../../constants/ActiveValues';

interface CandidateInformationCardEmployerProps {
  candidate: Candidate | undefined;
  secondary?: boolean;
  candidateWorkExperience: InitialFormStateTypes['workHistory'];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CandidateInformationCardEmployer: FC<
  CandidateInformationCardEmployerProps
> = ({ candidate, secondary, candidateWorkExperience, setShowModal }) => {
  const [t] = useTranslation('global');
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);
  const { serverUser } = useAppSelector((state) => state.auth);

  const sortedWorkHistory = candidateWorkExperience
    ?.slice()
    .sort(
      (a, b) =>
        new Date(a?.Admission.val!).getFullYear() -
        new Date(b?.Admission.val!).getFullYear()
    );

  const showScore = window.location.href.includes('employer');

  const lastJob = sortedWorkHistory?.slice(-1)[0];

  const lastJobTitle =
    lastJob?.JobPosition?.title ??
    lastJob?.JobTitle?.val ??
    lastJob?.Company?.val;

  const { data: candidateScore } = useGetScoreQuery(
    {
      id: candidate?.Id ?? 0,
    },
    { refetchOnMountOrArgChange: 3, pollingInterval: 3 * 60 * 1000 }
  );

  const cps = candidate?.CandidatesPositionStatus?.filter(
    (position) => position.Status === 'hired'
  );
  return (
    <>
      <div className={classes.CandidateInfoContainer}>
        <h2 className={classes.pageTittle}>Candidate Profile</h2>
        {candidate?.CandidatesPositionStatus?.find(
          (item) => item.Status === ActiveValues.requestInterview
        ) && (
          <h3 className={classes.interviewScheduled}>
            {t('EmployerPositions.interviewScheduled')}
          </h3>
        )}
        {cps &&
          cps?.length > 0 &&
          cps.map(({ Status, Position, Id }) => {
            return (
              <div className={classes.positionStatus} key={Id}>
                <GoldMedalIcon className={classes.icon} />
                <p>
                  <span>{t('CandidateStatus.Status')}: </span>
                  {Status}
                </p>
                <p>
                  <span>{t('CandidateStatus.Company')}: </span>
                  {Position?.Company?.Name}
                </p>
              </div>
            );
          })}

        <div className={classes.Header}>
          <div className="w-52 md:w-full">
            <DefaultIcon2
              picture={candidate?.User?.ProfilePicture?.Url}
              firstName={candidate?.User?.FirstName}
              lastName={candidate?.User?.LastName}
              addPadding
              fontSize="text-6xl"
            />
          </div>
          {secondary && !showScore && (
            <CandidateScore score={candidateScore?.Score} />
          )}
          <CandidateInfo
            name={`${candidate?.User?.FirstName.split(' ')[0]}
        ${candidate?.User?.LastName.split(' ')[0]}`}
            degree={lastJobTitle ?? 'N/A'}
            isInternal={secondary}
            candidateId={candidate?.Id}
          />
          {secondary && (
            <>
              <button
                type="button"
                className={classes.contactButton}
                onClick={() => setShowModal(true)}
              >
                {t('infoContainer.basicInformation')}
              </button>
              <button
                type="button"
                className={classes.contactButton}
                onClick={() => setShowPortfolioModal(true)}
              >
                {t('infoContainer.PortfolioAndWebsites')}
              </button>
            </>
          )}
          <CandidateSocialMedia
            custom={candidate?.CustomUrl ?? ''}
            github={candidate?.GithubUrl ?? ''}
            behance={candidate?.BehanceUrl ?? ''}
            linkedIn={candidate?.LinkedinUrl ?? ''}
            whatsappNumber={candidate?.WhatsAppNumber ?? ''}
            secondary={secondary || false}
          />
        </div>
      </div>
      <CandidateWebPortfoliosModal
        isOpen={showPortfolioModal}
        onClose={() => {
          setShowPortfolioModal(false);
        }}
        candidateId={candidate?.Id as number}
        userId={serverUser?.Id as number}
        isInternal
      />
    </>
  );
};

CandidateInformationCardEmployer.defaultProps = {
  secondary: false,
};
export default CandidateInformationCardEmployer;
