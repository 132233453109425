import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './VideoUpdateModal.module.css';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import { ReactComponent as Close } from '../../../../../icons/CloseX.svg';

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode | React.ReactNode[];
  isInternal?: boolean;
}
export const VideoUpdateModal = ({
  setModal,
  children,
  isInternal = false,
}: Props) => {
  const [t] = useTranslation('global');
  const parentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(parentRef, () => setModal(false));

  const color = isInternal
    ? 'var(--primary-green)'
    : 'var(--blue-100, #05668d)';
  return (
    <div
      className={classes.container}
      onClick={() => setModal(false)}
      aria-hidden
      ref={parentRef}
    >
      <div
        className={classes.centered}
        onClick={(e) => e.stopPropagation()}
        aria-hidden
      >
        <div className={classes.content}>
          <div className={classes.headerContent}>
            <div className={classes.header}>
              <p>{t('AdditionalVideos.modals.update')}</p>
              <Close
                className={classes.close}
                width={18}
                height={18}
                onClick={() => setModal(false)}
                fill={color}
              />
            </div>
            <div className={classes.divider} />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

VideoUpdateModal.defaultProps = {
  isInternal: false,
};
