import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import InternalChipGroup from './InternalChipGroup/InternalChipGroup';
import InternalSearchBar from './InternalSearchBar/InternalSearchBar';
import classes from './PositionHeader.module.css';
import DropdownButton from '../../DropdownButton/DropdownButton';
import FilterContent from './FilterContent/FilterContent';
import FilterTypeSearchBar, {
  parseFilterTypeSearchBar,
} from './FilterContent/FilterTypeSearchBar';
import PositionInternalDto from '../../../types/serverTypes/positionInternalDto';

interface Props {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  allPositions: PositionInternalDto[];
}
const PositionHeader: FC<Props> = ({ setPage, allPositions }) => {
  const [searchParmas, setSearchParams] = useSearchParams();
  const [t] = useTranslation('global');
  const [option, setOption] = useState(() => {
    const searchOption = searchParmas.get('option');
    return parseFilterTypeSearchBar(searchOption);
  });

  useEffect(() => {
    const searchOption = searchParmas.get('option');
    const parsedSearchOption = parseFilterTypeSearchBar(searchOption);

    if (searchOption !== parsedSearchOption) {
      const newSearchParams = new URLSearchParams(searchParmas);
      newSearchParams.set('option', parsedSearchOption);
      setSearchParams(newSearchParams);
    }

    if (parsedSearchOption !== option) {
      setOption(parsedSearchOption);
    }
  }, [option, searchParmas, setSearchParams]);

  const handleSetOption = (newOption: FilterTypeSearchBar) => {
    const params = new URLSearchParams(searchParmas);
    params.set('option', newOption);
    setSearchParams(params);
  };

  return (
    <div className={classes.header}>
      <div className={classes.leftContent}>
        <h1 className={classes.Tittle}>{t('EmployerDashboard.Positions')} </h1>
        <InternalChipGroup setPage={setPage} />
      </div>
      <div className={classes.rightContent}>
        <InternalSearchBar
          setPage={setPage}
          allPositions={allPositions}
          barType={option}
        />
      </div>
      <DropdownButton label="Filter by" isInternal isPosition>
        <FilterContent setOption={handleSetOption} />
      </DropdownButton>
    </div>
  );
};

export default PositionHeader;
