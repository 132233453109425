import { FC, lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { errorHandler } from '$store/utils';
import { userLanguage } from '$translations/index';
import { ErrorMessageEn, ErrorMessageEs } from '$constants/alerts';
import InternalDashboardPages from '../constants/InternalDashboardPages';
import { SIMERA_COMPANIES_ROUTE } from '../constants/routes';
import { useAppSelector } from '../hooks';
import RouteTypes from '../types/routeTypes';
import AuthRoute from './AuthRoute/AuthRoute';
import ContainerCandidateDetails from './CandidateDetails/ContainterCandidateDetails';
import EmailSubscription from './EmailSubscriptions/EmailSubscription';
import EmployeesList from './EmployerDashboard/EmployeesList/EmployeesList';
import EmployerLayout from './EmployerDashboard/EmployerLayout';
import LandingRedirect from './LandingRedirect/LandingRedirect';
import Policy from './Policy/Policy';
import PositionPageLayout from './PositionPageLayout/PositionPageLayout';
import CandidatesPositionPage from './Positions/CandidatesPositionPage/CandidatesPositionPage';
import { AdditionalVideosEmployerScreen } from './Screens/AdditionalVideosScreen/AdditionalVideosEmployer/AdditionalVideosEmployerScreen';
import { AdditionalVideosInternalScreen } from './Screens/AdditionalVideosScreen/AdditionalVideosInternal/AdditionalVideosInternal';
import CandidateNotesScreen from './Screens/CandidateNotesScreen/CandidateNotesScreen';
import ConfirmEmailScreen from './Screens/ConfirmEmail/ConfirmEmailScreen';
import EmployerRegisterScreen from './Screens/EmployerRegisterScreen/EmployerRegisterScreen';
import SimeraRegisterScreen from './Screens/EmployerRegisterScreen/SimeraRegisterScreen';
import EmployersScreen from './Screens/EmployersScreen/EmployersScreen';
import ForgotPasswordScreen from './Screens/ForgotPassword/ForgotPasswordScreen';
import HandleHomePagePrevius from './Screens/HandleHomePage/HandleHomePagePrevius';
import JobDashboardScreen from './Screens/JobDashboardScreen/JobDashboardScreen';
import LoginScreen from './Screens/LoginScreen/LoginScreen';
import SimeraLoginScreen from './Screens/LoginScreen/SimeraLoginScreen';
import PasswordResetScreen from './Screens/PasswordResetScreen/PasswordResetScreen';
import PasswordResetSuccess from './Screens/PasswordResetSuccess/PasswordResetSuccess';
import ReferralsScreen from './Screens/ReferralsScreen/ReferralsScreen';
import RegisterScreen from './Screens/RegisterScreen/RegisterScreen';
import SimeraDashboardScreen from './Screens/SimeraDashboardScreen/SimeraDashboardScreen';
import SimeraDashboardScreenCandidates from './Screens/SimeraDashboardScreen/SimeraDashboardScreenCandidates';
import SimeraDashboardScreenCompanies from './Screens/SimeraDashboardScreen/SimeraDashboardScreenCompanies';
import SimeraDashboardScreenCompany from './Screens/SimeraDashboardScreen/SimeraDashboardScreenCompany';
import SimeraDashboardScreenPosition from './Screens/SimeraDashboardScreen/SimeraDashboardScreenPosition';
import SimeraDashboardLayout from './SimeraDashboard/SimeraDashboardLayout';
import Spinner from './Spinner/Spinner';
import Terms from './Terms/Terms';
import ShareVideo from './Share/ShareVideo';
import ShareLayout from './Share/ShareLayout';
import SharePosition from './Share/SharePosition/SharePosition';
import HomeLayout from './CandidateHome/HomeLayout';
import CandidateInterviewLayout from './Interview/CandidateInterviewLayout';
import InternalJobInterviewLayout from './InternalJobInterview/InternalJobInterviewLayout';

const LazyLoadedVideoScreen = lazy(
  () => import('./Screens/VideoRecordingScreen/VideoRecordingScreen')
);

interface CustomSwitchProps {}

const CustomSwitch: FC<CustomSwitchProps> = () => {
  const location = useLocation();
  const authState = useAppSelector((state) => state.auth);
  const [referrer, setReferrer] = useState(document.referrer);

  useEffect(() => {
    if (
      (authState.isLoggedIn === true && referrer !== window.location.href) ||
      document.referrer !== ''
    ) {
      window.analytics.page({
        referrer: document.referrer !== '' ? document.referrer : referrer,
        url: window.location.href,
      });
    }
    setReferrer(window.location.href);
  }, [location.pathname, referrer, authState]);

  useEffect(() => {
    if (sessionStorage.getItem('sessionExpired')) {
      sessionStorage.removeItem('sessionExpired');
      errorHandler(
        '[SessionExpired]',
        userLanguage === 'es'
          ? ErrorMessageEs.SessionExpired
          : ErrorMessageEn.SessionExpired,
        new Error('Session expired'),
        true
      );
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {/* public routes */}
        <Route path="/" element={<LandingRedirect />} />
        <Route element={<LandingRedirect />} path="/landing" />

        <Route
          path="/interview"
          element={
            <AuthRoute type={RouteTypes.CANDIDATE}>
              <LazyLoadedVideoScreen />
            </AuthRoute>
          }
        />
        <Route
          path="/video"
          element={
            <AuthRoute type={RouteTypes.CANDIDATE}>
              <LazyLoadedVideoScreen />
            </AuthRoute>
          }
        />

        <Route
          path="/job-dashboard"
          element={
            <AuthRoute type={RouteTypes.CANDIDATE}>
              <JobDashboardScreen />
            </AuthRoute>
          }
        />

        <Route
          path="/job-dashboard/:positionId"
          element={
            <AuthRoute type={RouteTypes.CANDIDATE}>
              <JobDashboardScreen />
            </AuthRoute>
          }
        />

        <Route
          path="/internal/video/:candidateId"
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <AdditionalVideosInternalScreen />
            </AuthRoute>
          }
        />

        <Route
          path="/employer/video/:candidateId"
          element={
            <AuthRoute type={RouteTypes.EMPLOYER}>
              <AdditionalVideosEmployerScreen />
            </AuthRoute>
          }
        />

        <Route path="/recover_password" element={<ForgotPasswordScreen />} />
        <Route path="/reset_password" element={<PasswordResetScreen />} />
        <Route path="/reset_success" element={<PasswordResetSuccess />} />

        <Route path="/terms" element={<Terms />} />
        <Route path="/policy" element={<Policy />} />

        {/* login routes */}
        {/* type redirect means that it will redirect to the default auth route if user is already logged in */}
        <Route
          path="/login"
          element={
            <AuthRoute type={RouteTypes.AUTH}>
              <LoginScreen />
            </AuthRoute>
          }
        />

        <Route
          path="/register"
          element={
            <AuthRoute type={RouteTypes.AUTH}>
              <RegisterScreen />
            </AuthRoute>
          }
        />
        <Route
          path="/employer_register"
          element={
            <AuthRoute type={RouteTypes.AUTH}>
              <EmployerRegisterScreen />
            </AuthRoute>
          }
        />
        <Route
          path="/internal_register"
          element={
            <AuthRoute type={RouteTypes.AUTH}>
              <SimeraRegisterScreen />
            </AuthRoute>
          }
        />

        <Route
          path="/employer_login"
          element={
            <AuthRoute type={RouteTypes.AUTH}>
              <LoginScreen isEmployee />
            </AuthRoute>
          }
        />
        <Route
          path="/internal_login"
          element={
            <AuthRoute type={RouteTypes.AUTH}>
              <SimeraLoginScreen />
            </AuthRoute>
          }
        />

        {/* private routes */}
        <Route
          element={
            <AuthRoute type={RouteTypes.CANDIDATE}>
              <HandleHomePagePrevius />
            </AuthRoute>
          }
          path="/home"
        />

        <Route
          element={
            <AuthRoute type={RouteTypes.CANDIDATE}>
              <HandleHomePagePrevius forceProfile />
            </AuthRoute>
          }
          path="/profile"
        />

        <Route
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <SimeraDashboardScreen />
            </AuthRoute>
          }
          path="/internal-dashboard"
        />
        <Route
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <SimeraDashboardScreenCompany />
            </AuthRoute>
          }
          path="/internal-dashboard/company/:id"
        />
        <Route
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <SimeraDashboardScreenCandidates />
            </AuthRoute>
          }
          path="/internal-dashboard/candidates"
        />
        <Route
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <SimeraDashboardScreenCompanies />
            </AuthRoute>
          }
          path={SIMERA_COMPANIES_ROUTE}
        />

        <Route
          element={
            <AuthRoute type={RouteTypes.CANDIDATE}>
              <ReferralsScreen />
            </AuthRoute>
          }
          path="/referrals"
        />

        <Route
          path="/internal/confirm_email/:token"
          element={<ConfirmEmailScreen userRole="internal" />}
        />

        <Route
          path="/employer/confirm_email/:token"
          element={<ConfirmEmailScreen userRole="employer" />}
        />

        <Route
          path="/candidate/confirm_email/:token"
          element={<ConfirmEmailScreen userRole="candidate" />}
        />

        <Route
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <SimeraDashboardScreenPosition />
            </AuthRoute>
          }
          path="/simera-dashboard/positions/:id"
        />
        <Route
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <CandidateNotesScreen />
            </AuthRoute>
          }
          path="/simera-dashboard/candidate/:candidateId/notes/:positionId"
        />
        <Route
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <EmployersScreen />
            </AuthRoute>
          }
          path="/simera-dashboard/employers"
        />
        <Route
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <SimeraDashboardLayout
                activeTab={InternalDashboardPages.POSITION}
              >
                <div
                  style={{
                    width: '100%',
                    position: 'absolute',
                  }}
                >
                  <ContainerCandidateDetails secondary />
                </div>
              </SimeraDashboardLayout>
            </AuthRoute>
          }
          path="/simera-dashboard/positions/:positionId/candidates/:candidateId"
        />

        <Route
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <SimeraDashboardLayout
                activeTab={InternalDashboardPages.POSITION}
              >
                <div
                  style={{
                    width: '100%',
                    position: 'absolute',
                  }}
                >
                  <ContainerCandidateDetails secondary />
                </div>
              </SimeraDashboardLayout>
            </AuthRoute>
          }
          path="/simera-dashboard/candidates/:candidateId"
        />

        <Route
          element={
            <AuthRoute type={RouteTypes.EMPLOYER}>
              <EmployerLayout isProfile>
                <ContainerCandidateDetails isEmployee />
              </EmployerLayout>
            </AuthRoute>
          }
          path="/employer-dashboard/positions/:positionId/candidates/:candidateId"
        />
        <Route
          element={
            <AuthRoute type={RouteTypes.EMPLOYER}>
              <EmployerLayout isProfile>
                <ContainerCandidateDetails isEmployee />
              </EmployerLayout>
            </AuthRoute>
          }
          path="/employer-dashboard/positions/:positionId/employees/:candidateId"
        />

        <Route
          element={
            <AuthRoute type={RouteTypes.EMPLOYER}>
              <PositionPageLayout />
            </AuthRoute>
          }
          path="/employer-dashboard/positions"
        />

        <Route
          element={
            <AuthRoute type={RouteTypes.EMPLOYER}>
              <EmployerLayout isPositions>
                <CandidatesPositionPage />
              </EmployerLayout>
            </AuthRoute>
          }
          path="/employer-dashboard/positions/:positionId"
        />

        {/* Keeping this in case somewhere external is used */}
        <Route
          element={<Navigate to="/employer-dashboard/team-members/" replace />}
          path="/employer-dashboard/employees/"
        />
        {/* ***** */}
        <Route
          element={
            <AuthRoute type={RouteTypes.EMPLOYER}>
              <EmployerLayout isEmployees>
                <EmployeesList />
              </EmployerLayout>
            </AuthRoute>
          }
          path="/employer-dashboard/team-members/"
        />

        <Route
          path="/employer-dashboard"
          element={<Navigate to="/employer-dashboard/positions" replace />}
        />

        <Route path="/unsubscribe/:token" element={<EmailSubscription />} />

        {/* Keeping for share video url backward compability */}
        <Route
          path="/share/video/:shareReferenceId"
          element={
            <ShareLayout>
              <ShareVideo />
            </ShareLayout>
          }
        />
        {/* The new url for shared videos */}
        <Route
          path="/share/candidate/:candidateId/video/:shareReferenceId"
          element={
            <ShareLayout>
              <ShareVideo />
            </ShareLayout>
          }
        />
        {/* end of sharing video url */}

        <Route
          path="/share/position/:shareReferenceId"
          element={
            <ShareLayout>
              <SharePosition />
            </ShareLayout>
          }
        />

        <Route
          path="/job-interview"
          element={
            <AuthRoute type={RouteTypes.CANDIDATE}>
              <HomeLayout isProfile>
                <CandidateInterviewLayout />
              </HomeLayout>
            </AuthRoute>
          }
        />

        <Route
          element={
            <AuthRoute type={RouteTypes.INTERNAL}>
              <SimeraDashboardLayout
                activeTab={InternalDashboardPages.POSITION}
              >
                <InternalJobInterviewLayout />
              </SimeraDashboardLayout>
            </AuthRoute>
          }
          path="/internal-dashboard/interviews"
        />
      </Routes>
    </Suspense>
  );
};

export default CustomSwitch;
