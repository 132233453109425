import { FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { SaveState } from '../../types/ForrmType';
import SaveButton from '../Buttons/SaveButton';
import classes from './FormFooter.module.css';

interface FormFooterProps {
  onClickCancel?: () => void;
  disabled?: boolean;
  submit?: boolean;
  translate: TFunction<'global', undefined>;
  saveState?: SaveState;
  showSaveButton?: boolean;
  useMobile?: boolean;
}

const defaultProps = {
  onClickCancel: () => {},
  disabled: false,
  submit: false,
  saveState: SaveState.IDLE,
  showSaveButton: true,
  useMobile: true,
};

const FormFooter: FC<FormFooterProps> = ({
  onClickCancel,
  disabled,
  submit,
  translate,
  saveState,
  showSaveButton,
  useMobile,
}) => {
  const [t] = useTranslation('global');
  return (
    <div
      data-testid="button"
      aria-hidden="true"
      className={`${classes.FormFooter} ${useMobile ? classes.mobile : ''}`}
    >
      <button
        data-testid="cancel-modal"
        type="button"
        className={classes.CancelButton}
        onClick={onClickCancel}
      >
        {t('common.cancel')}
      </button>
      {showSaveButton && (
        <SaveButton
          disabled={disabled}
          state={saveState}
          t={translate}
          submit={submit}
        />
      )}
    </div>
  );
};

FormFooter.defaultProps = defaultProps;

export default FormFooter;
