import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { successHandler, errorHandler } from '../../../utils';
import {
  AddCandidateToPositionAgainInput,
  AddCandidateToPositionAgainOutput,
  CandidateBuilder,
} from '../candidates.types';

export const addCandidateToPositionAgain = (builder: CandidateBuilder) =>
  builder.mutation<
    AddCandidateToPositionAgainOutput,
    AddCandidateToPositionAgainInput
  >({
    query: (args) => ({
      url: 'candidate-position-status/add-candidate-again',
      method: 'PATCH',
      body: args.data,
    }),
    transformResponse: (res, meta, args) => {
      successHandler(
        (args.lang ?? 'en') === 'en'
          ? SuccessMessageEn.ADD_CANDIDATE_TO_POSITION
          : SuccessMessageEs.ADD_CANDIDATE_TO_POSITION,
        true
      );
      return args.data;
    },
    transformErrorResponse: (err, meta, arg) => {
      errorHandler(
        '[addCandidateToPositionAction]',
        (arg.lang ?? 'en') === 'es'
          ? ErrorMessageEs.AddCandidateToPositionError
          : ErrorMessageEn.AddCandidateToPositionError,
        err,
        true
      );

      return err.data;
    },
    invalidatesTags: () => [{ type: 'Candidates' }],
  });
