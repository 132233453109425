import { useState } from 'react';
import classes from './AdditionalDocumentsContainer.module.css';
import { ReactComponent as Pencil } from '../../../icons/Pencil.svg';
import { ReactComponent as Close } from '../../../icons/CloseX.svg';
import AdditionalFile from '../AdditionalFile/AdditionalFile';
import { AdditionalDocument } from '../../../types/candidates';

interface Props {
  title: string;
  data: AdditionalDocument[];
  secondary?: boolean;
}
export const AdditionalDocumentsContainer = ({
  title,
  data,
  secondary,
}: Props) => {
  const [isUpdate, setIsUpdate] = useState(false);
  return (
    <section className={classes.section}>
      <header className={classes.header}>
        <p>{title}</p>
        <button
          className={classes.containerActionItem}
          type="button"
          onClick={() => {
            setIsUpdate((prev) => !prev);
          }}
          tabIndex={0}
        >
          {isUpdate ? <Close width="1.25rem" fill="#5A5B5E" /> : <Pencil />}
        </button>
      </header>
      {data.map((doc) => (
        <AdditionalFile
          data={doc}
          key={`${doc.Id}-additionalDocs`}
          secondary={secondary}
          isUpdate={isUpdate}
        />
      ))}
    </section>
  );
};

AdditionalDocumentsContainer.defaultProps = {
  secondary: false,
};
