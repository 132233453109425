import { FC } from 'react';
import classes from './NumberInput.module.css';

interface NumberInputProps {
  label?: string;
  readonly?: boolean;
  value: number | string;
  separator?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NumberInput: FC<NumberInputProps> = ({
  label,
  readonly,
  value,
  separator,
  onChange,
}) => {
  return (
    <div data-testid="container">
      {label && (
        <p data-tesid="label" className={classes.label}>
          {label}
        </p>
      )}
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        className={classes.LineStyle}
      >
        <input
          data-testid="number-input"
          style={{ width: '100%' }}
          value={value}
          className={classes.numberInput}
          readOnly={readonly}
          onChange={onChange}
          disabled={readonly}
        />
        {separator && <div className={classes.separator} />}
      </div>
    </div>
  );
};

NumberInput.defaultProps = {
  label: undefined,
  readonly: false,
  separator: false,
};

export default NumberInput;
