import { FC } from 'react';
import classes from './ScoreStep.module.css';
import { ReactComponent as WhiteCheck } from '../../icons/WhiteCheck.svg';

interface Props {
  completed?: number;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  title?: string;
}

const defaultProps = {
  completed: 40,
  icon: null,
  title: '',
};

const ScoreStep: FC<Props> = ({ completed, icon: Icon, title }) => {
  return (
    <div className={classes.ContainerStyles}>
      <div
        className={
          completed !== 100
            ? classes.FillerStylesIncomplete
            : classes.FillerStylesComplete
        }
        style={{ width: `${completed}%` }}
      />
      {Icon && (
        <Icon
          className={classes.Icon}
          fill={completed !== 100 ? '#5A5B5E' : '#FFFFFF'}
        />
      )}
      <span
        className={
          completed !== 100
            ? classes.StepNameIncomplete
            : classes.StepNameComplete
        }
      >
        {title}
      </span>
      {completed !== 100 ? (
        <span className={classes.LabelStyles}>{`${completed}%`}</span>
      ) : (
        <WhiteCheck fill="#fff" className={classes.LabelStyles} />
      )}
    </div>
  );
};

ScoreStep.defaultProps = defaultProps;
export default ScoreStep;
