import { FC } from 'react';
import FooterArrow from '../../icons/FooterArrow';
import classes from './FooterButton.module.css';

interface FooterButtonProps {
  onClickAction: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    n: number
  ) => void;
  direction: 'up' | 'down';
}
const FooterButton: FC<FooterButtonProps> = ({ onClickAction, direction }) => {
  return (
    <div
      data-testid={`button-${direction}`}
      aria-hidden="true"
      onClick={(e) => onClickAction(e, direction === 'down' ? 1 : -1)}
      className={classes.FooterButton}
    >
      <FooterArrow direction={direction} />
    </div>
  );
};

export default FooterButton;
