import type { FC } from 'react';
import classes from './Home.module.css';

const Home: FC = ({ children }) => {
  return (
    <div data-testid="home-container" className={classes.HomeContainer}>
      {children}
    </div>
  );
};

export default Home;
