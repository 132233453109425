import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import classes from './AddNewJobModal.module.css';
import EditJobForm from './EditJobForm';
import PositionDto from '../../types/serverTypes/positionDto';
import PositionInternalDto from '../../types/serverTypes/positionInternalDto';

interface Props {
  modalIsOpen: boolean;
  position: PositionDto | PositionInternalDto;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditJobModal: FC<Props> = ({ modalIsOpen, position, setModal }) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const [t] = useTranslation('global');
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModal(false)}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      contentLabel="Add New Position"
      ariaHideApp={false}
    >
      <h1 className={classes.modalTitle} data-testid="add-new-job-title">
        Edit job
      </h1>
      <p>Edit the job here, and click save changes.</p>

      <p className={classes.modalSubtitle} data-testid="add-new-job-subtitle">
        {t('EmployerAddPosition.Position')}
      </p>
      <EditJobForm position={position} setModal={setModal} />
    </Modal>
  );
};

export default EditJobModal;
