import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { AdditionalTagResponse } from '../../../../types/candidates';
import { successHandler, errorHandler } from '../../../utils';
import {
  AdditionalTagBuilder,
  UpdateAdditionalTagInput,
  UpdateAdditionalTagOutput,
} from '../additional-tag.types';

export const updateAdditionalTags = (builder: AdditionalTagBuilder) =>
  builder.mutation<UpdateAdditionalTagOutput, UpdateAdditionalTagInput>({
    query: ({ candidateId, additionalTags }) => ({
      url: `/candidate-additional-tag/candidate/${candidateId}`,
      method: 'PATCH',
      body: {
        AdditionalTags: additionalTags.map((tag) => ({
          Id: tag.Id,
          Name: tag.title,
        })),
      },
    }),
    invalidatesTags: [{ type: 'additionalTags', id: 'LIST' }],
    transformResponse: (response: AdditionalTagResponse[], meta, { lang }) => {
      successHandler(
        lang === 'es'
          ? SuccessMessageEs.AdditionalTagUpdated
          : SuccessMessageEn.AdditionalTagUpdated,
        true
      );
      return response.map((tag) => ({
        Id: tag?.Id,
        title: tag?.Name,
      }));
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[UPDATE_ADDITIONAL_TAGS]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
