import { ErrorMessageEn, ErrorMessageEs } from '../constants/alerts';
import { errorHandler } from '../store/utils';

const errorMessages = (type: string, error: Error, lang: string) => {
  switch (error.message) {
    case ErrorMessageEn.InternalError:
      errorHandler(
        type,
        lang === 'en'
          ? ErrorMessageEn.InternalError
          : ErrorMessageEs.InternalError,
        error,
        true
      );
      break;
    case ErrorMessageEn.WrongUserType:
      errorHandler(
        type,
        lang === 'en'
          ? ErrorMessageEn.WrongUserType
          : ErrorMessageEs.WrongUserType,
        error,
        true
      );
      break;
    case ErrorMessageEn.UserNotFoundOnServer:
      errorHandler(
        type,
        lang === 'en'
          ? ErrorMessageEn.UserNotFoundOnServer
          : ErrorMessageEs.UserNotFoundOnServer,
        error,
        true
      );
      break;
    case ErrorMessageEn.UserAlreadyRegistered:
      errorHandler(
        type,
        lang === 'en'
          ? ErrorMessageEn.UserAlreadyRegistered
          : ErrorMessageEs.UserAlreadyRegistered,
        error,
        true
      );
      break;
    default:
      errorHandler(
        type,
        lang === 'en' ? ErrorMessageEn.AuthFail : ErrorMessageEs.AuthFail,
        error,
        true
      );
  }
};

export default errorMessages;
