import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from '../../../../../icons/CloseX.svg';
import { ReactComponent as ArrowRight } from '../../../../../icons/ArrowRight.svg';
import classes from './VideoDeleteModal.module.css';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import { useDeleteAdditionalVideoMutation } from '../../../../../store/modules/additional-videos';
import { useAdditionalVideo } from '../../../../../store/modules/additional-videos/hooks';

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRecordedChunks?: React.Dispatch<React.SetStateAction<BlobPart[]>>;
  videoId?: number;
  isInternal?: boolean;
}

export const VideoDeleteModal: FC<Props> = ({
  setModal,
  setRecordedChunks,
  videoId,
  isInternal,
}) => {
  const { closeAdditionalVideoModal } = useAdditionalVideo();
  const [t, i18next] = useTranslation('global');
  const parentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(parentRef, () => setModal(false));
  const [deleteVideo, { isLoading }] = useDeleteAdditionalVideoMutation();
  const { setVideo } = useAdditionalVideo();

  const handleDelete = async () => {
    if (videoId) {
      await deleteVideo({
        id: videoId,
        lang: i18next.language as 'en' | 'es',
      });
      setVideo(null);
    }

    if (setRecordedChunks) {
      setRecordedChunks([]);
    }
    closeAdditionalVideoModal();
    setModal(false);
  };

  const color = isInternal
    ? 'var(--primary-green)'
    : 'var(--blue-100, #05668d)';

  return (
    <div
      className={classes.Container}
      onClick={() => setModal(false)}
      aria-hidden
      ref={parentRef}
    >
      <div
        className={classes.Centered}
        onClick={(e) => e.stopPropagation()}
        aria-hidden
      >
        <div className={classes.Content}>
          <div className={classes.HeaderContent}>
            <div className={classes.Header}>
              <p>{t('AdditionalVideos.modals.delete')}</p>
              <Close
                className={classes.Close}
                width={18}
                height={18}
                onClick={() => setModal(false)}
                fill={color}
              />
            </div>
            <div className={classes.divider} />
          </div>

          <div className={classes.DeleteContainer}>
            <p className={classes.Text}>
              {t('AdditionalVideos.modals.question')}
            </p>
            <div className={classes.ButtonContainer}>
              <button
                type="button"
                className={classes.Cancel}
                style={{ color }}
                onClick={() => setModal(false)}
              >
                {t('AdditionalVideos.modals.cancel')}
              </button>
              <button
                type="button"
                className={classes.Yes}
                onClick={handleDelete}
                style={{ background: color }}
                disabled={isLoading}
              >
                {t('AdditionalVideos.modals.delete')}
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VideoDeleteModal.defaultProps = {
  setRecordedChunks: () => {},
  videoId: undefined,
  isInternal: false,
};
