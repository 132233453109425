import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ReadOnly.module.css';

interface ReadOnlyProps {
  title: string;
  subtitle: string;
  startDate?: string | number;
  endDate: string | number;
  secondary?: boolean;
  isWorkExperience?: boolean;
  workExperienceDescription?: string | null | undefined;
}
const ReadOnly: FC<ReadOnlyProps> = ({
  title,
  subtitle,
  startDate,
  endDate,
  secondary,
  isWorkExperience,
  workExperienceDescription,
}) => {
  const [t] = useTranslation('global');
  const color = secondary ? 'var(--primary-green)' : 'var(--brand-blue-100)';
  const formattedWorkExperienceDescription =
    workExperienceDescription?.split(/\r?\n|\r|\n/g);

  return (
    <div className={classes.Fragment}>
      <section
        data-testid="readonly-container"
        className={classes.readOnlyContainer}
      >
        <div className={classes.readOnlyContent}>
          <p data-testid="readonly-title" className={classes.readOnlyTitle}>
            {title}
          </p>
          <p
            data-testid="readonly-subtitle"
            className={classes.readOnlySubitle}
          >
            {subtitle}
          </p>
        </div>
        <div
          data-testid="readonly-chip"
          className={classes.readOnlyChipContainer}
        >
          <div className={classes.readOnlyChip}>
            <p
              style={{
                color,
              }}
            >{`${startDate ? `${startDate} - ` : ''}  ${endDate}`}</p>
          </div>
        </div>
      </section>
      {isWorkExperience && (
        <div className={classes.DescriptionContainer}>
          <p className={classes.DescriptionTitle}>
            {t('SimeraInternal.TasksPerformed')}
          </p>
          <ul className={classes.Description}>
            {formattedWorkExperienceDescription?.map((item) => (
              <li key={`${item} ${Math.random()}`}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

ReadOnly.defaultProps = {
  startDate: '',
  secondary: false,
  isWorkExperience: false,
  workExperienceDescription: '',
};
export default ReadOnly;
