import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CandidateEducationBuilder,
  DeleteCandidateEducationInput,
} from '../candidate-education.types';

export const deleteCandidateEducation = (builder: CandidateEducationBuilder) =>
  builder.mutation<void, DeleteCandidateEducationInput>({
    query: ({ id }) => ({
      url: `/candidate-education/delete/${id}`,
      method: 'DELETE',
    }),

    invalidatesTags: [{ type: 'candidatesEducation', id: 'LIST' }, 'Score'],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[deleteEducation]',
        lang === 'es' ? ErrorMessageEs.DeleteError : ErrorMessageEn.DeleteError,
        error,
        true
      );
    },
    transformResponse(_data, meta, { lang }) {
      successHandler(
        lang === 'es'
          ? SuccessMessageEs.SuccessfullyDeleted
          : SuccessMessageEn.SuccessfullyDeleted,
        true
      );
    },
  });
