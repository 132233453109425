import type { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classes from './AddNoteForm.module.css';
import BaseInput from '../../../../Inputs/BaseInput';
import BaseTextArea from '../../../../TextArea/BaseTextArea';
import SaveButton from '../../../../Buttons/SaveButton';
import { SaveState } from '../../../../../types/ForrmType';
import { useSaveCandidateNoteMutation } from '../../../../../store/modules/candidates';

interface FormData {
  Title: string;
  Content: string;
}

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  positionId?: number;
}

const AddNoteForm: FC<Props> = ({ setIsOpen, positionId }) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      Title: '',
      Content: '',
    },
    mode: 'onChange',
  });
  const [t] = useTranslation('global');
  const title = register('Title', {
    required: `${t('Notes.TitleError')}`,
    maxLength: {
      value: 100,
      message: `${t('Notes.TitleErrorLength')}`,
    },
  });
  const content = register('Content', {
    required: `${t('Notes.ContentError')}`,
  });
  const contentValue = watch('Content');
  const { candidateId } = useParams();
  const [saveCandidateNote] = useSaveCandidateNoteMutation();

  const saveNote = async (data: FormData) => {
    const CandidateId = Number(candidateId!);
    const PositionId =
      Number(positionId!) === 0 ? undefined : Number(positionId!);
    await saveCandidateNote({
      data: { ...data, CandidateId, PositionId },
      lang: 'en',
    });
    setIsOpen(false);
  };

  const hasErrors = Object.keys(errors).length > 0;
  return (
    <form onSubmit={handleSubmit(saveNote)} data-testid="add-note-form">
      <div className={classes.content}>
        <BaseInput
          name={title.name}
          onChange={title.onChange}
          label={t('Notes.Title')}
          onBlur={title.onBlur}
          type="text"
          refProp={title.ref}
          errorText={errors.Title?.message}
        />
        <BaseTextArea
          name={content.name}
          onChange={content.onChange}
          label={t('Notes.Content')}
          onBlur={content.onBlur}
          refProp={content.ref}
          counter={contentValue.length}
          maxLength={4096}
          errorText={errors.Content?.message}
          secondary
        />

        <div className={classes.actions}>
          <button
            type="button"
            className={classes.normalButton}
            onClick={() => setIsOpen(false)}
          >
            {t('ConfirmAddToPositionModal.goBack')}
          </button>
          <SaveButton
            submit
            t={t}
            state={SaveState.IDLE}
            disabled={hasErrors}
          />
        </div>
      </div>
    </form>
  );
};

AddNoteForm.defaultProps = {
  positionId: 0,
};

export default AddNoteForm;
