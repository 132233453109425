import {
  UseFormRegister,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { TFunction } from 'react-i18next';
import { FC, useState } from 'react';
import { FieldTypes, InitialFormStateTypes } from '../../types/user_info';
import SkillsForm from './SkillsForm';
import { useGetSkillsQuery } from '../../store/modules/form-info';
import { GetCandidateSkillsOutput } from '../../store/modules/candidate-skill';

interface SkillsProps {
  register: UseFormRegister<{ skills: GetCandidateSkillsOutput | undefined }>;
  fields: Record<'id', string>[];
  removeSkill: (index: number, deleteId: number) => void;
  t: TFunction<'global', undefined>;
  setValue: UseFormSetValue<{ skills: GetCandidateSkillsOutput | undefined }>;
  removeUnsaved: (index: number) => void;
  errors: any;
  getValues: UseFormGetValues<{ skills: GetCandidateSkillsOutput | undefined }>;
  secondary?: boolean;
}
const Skills: FC<SkillsProps> = ({
  register,
  fields,
  removeSkill,
  t,
  setValue,
  removeUnsaved,
  errors,
  getValues,
  secondary,
}) => {
  const { data: skills } = useGetSkillsQuery({ t });
  const [Modal, setModal] = useState<boolean>(false);
  const [delId, setDelId] = useState();
  dayjs.extend(isSameOrBefore);
  dayjs.extend(customParseFormat);

  return (
    <>
      {fields.map((row: any, index) => {
        const { Id } = row;
        const SkillLevel = register(`skills.${index}.SkillLevel.val`, {
          required: true,
        });
        const SkillName = register(`skills.${index}.Skill.Title`, {
          required: true,
          validate: {
            skillListed: (value) =>
              value ? skills?.some((skill) => skill.title === value) : false,
          },
        });
        const CertificationId = register(`skills.${index}.CertificationId.val`);
        const CertificationUrl = register(
          `skills.${index}.CertificationUrl.val`
        );
        const certDate = register(`skills.${index}.certDate.val`, {
          validate: {
            isValidYear: (value) =>
              value
                ? dayjs(value).isSameOrBefore(dayjs(), 'month') &&
                  dayjs(value).isAfter('1950', 'year')
                : true,
            isValidDate: (value) =>
              value ? dayjs(value, 'YYYY-MM', true).isValid() : true,
          },
        });

        return (
          <SkillsForm
            key={row.id}
            errors={errors?.[index]}
            index={index}
            removeSkill={removeSkill}
            t={t}
            setValue={
              setValue as UseFormSetValue<
                | InitialFormStateTypes
                | {
                    Id?: number;
                    CertificationId: FieldTypes;
                    Skill: {
                      val: string;
                      mult: number;
                      Id: number;
                      title?: string;
                    };
                    SkillLevel: FieldTypes;
                    certDate: FieldTypes;
                    CertificationUrl: FieldTypes;
                  }
              >
            }
            skills={skills}
            SkillLevel={SkillLevel}
            SkillName={SkillName}
            CertificationId={CertificationId}
            CertificationUrl={CertificationUrl}
            certDate={certDate}
            row={row}
            Id={Id}
            secondary={secondary}
            Modal={Modal}
            delId={delId && delId}
            setDelId={setDelId}
            removeUnsaved={removeUnsaved}
            setModal={setModal}
            getValues={
              getValues as UseFormGetValues<
                | InitialFormStateTypes
                | {
                    Id?: number;
                    CertificationId: FieldTypes;
                    Skill: {
                      val: string;
                      mult: number;
                      Id: number;
                      title?: string;
                    };
                    SkillLevel: FieldTypes;
                    certDate: FieldTypes;
                    CertificationUrl: FieldTypes;
                  }
              >
            }
          />
        );
      })}
    </>
  );
};
Skills.defaultProps = {
  secondary: false,
};

export default Skills;
