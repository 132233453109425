import classes from './SuccessAnimation.module.css';
import { ReactComponent as CheckIcon } from '../../../../icons/CheckIconEmployer.svg';

const SuccessAnimation = () => {
  return (
    <div className="flex self-center">
      <div className={classes.circle}>
        <div className={classes.checkIcon}>
          <CheckIcon />
        </div>
      </div>
    </div>
  );
};

export default SuccessAnimation;
