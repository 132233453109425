import type { FC } from 'react';
import type { Dayjs } from 'dayjs';
import classes from './DayPicker.module.css';
import { isDisabled, WORKING_DAYS } from '../../../../../constants/datetimes';

export type DayPickerProps = {
  day: Dayjs;
  selectDay: (day: Dayjs) => void;
};

export const DayPicker: FC<DayPickerProps> = ({ day, selectDay }) => {
  return (
    <div className={classes.daysContainer}>
      <div className={classes.weekContainer}>
        {WORKING_DAYS().map((el) => (
          <div key={el.format('d')} className={classes.circle}>
            <span className={classes.days} key={el.format()}>
              {el.format('ddd')}
            </span>
          </div>
        ))}
      </div>
      <div className={classes.divider} />
      <div className={classes.weekContainer}>
        {WORKING_DAYS().map((workingDay) => (
          <button
            data-testid={`dayPicker-selectDay-${workingDay.format('d')}`}
            className={`${classes.circle} ${
              !workingDay.format('d').includes('0') && classes.circleColored
            } ${
              day &&
              day.format('d') === workingDay.format('d') &&
              classes.circleSelected
            }`}
            onKeyDown={() => selectDay(workingDay)}
            onClick={() => selectDay(workingDay)}
            type="button"
            tabIndex={0}
            key={workingDay.format()}
            disabled={isDisabled(workingDay)}
          >
            <span
              className={`${classes.days} ${
                day &&
                day.format('d') === workingDay.format('d') &&
                classes.daySelected
              }`}
            >
              {workingDay.format('D')}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};
