import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ReactComponent as ArrowRight } from '../../../../../icons/ArrowRight.svg';
import classes from './UpdateVideoForm.module.css';
import { useUpdateAdditionalVideoMutation } from '../../../../../store/modules/additional-videos';
import { useAdditionalVideo } from '../../../../../store/modules/additional-videos/hooks';

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  videoName: string;
  videoId: number;
  isInternal?: boolean;
}

interface FormData {
  name: string;
}
export const UpdateVideoForm = ({
  setModal,
  videoName,
  videoId,
  isInternal,
}: Props) => {
  const [t, i18next] = useTranslation('global');
  const [updateAdditionalVideo, { isLoading }] =
    useUpdateAdditionalVideoMutation();

  const { renameVideo } = useAdditionalVideo();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      name: videoName,
    },
  });

  const nameRequired = t('AdditionalVideos.errors.name');
  const name = register('name', {
    required: nameRequired,
    maxLength: { value: 255, message: t('AdditionalVideos.errors.maxLength') },
  });

  const handleUpdate = async (data: FormData) => {
    await updateAdditionalVideo({
      id: videoId,
      data: { Name: data.name },
      lang: i18next.language as 'en' | 'es',
    });
    renameVideo(data.name);
    setModal(false);
  };

  const color = isInternal
    ? 'var(--primary-green)'
    : 'var(--blue-100, #05668d)';

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      <div className={classes.saveContainer}>
        <div className={classes.inputContainer}>
          <p className={classes.text}>{t('AdditionalVideos.modals.name')}</p>
          <input
            className={classes.inputField}
            type="text"
            onChange={name.onChange}
            onBlur={name.onBlur}
            ref={name.ref}
            name={name.name}
            maxLength={255}
          />
          <p className={classes.errorText}>{errors?.name?.message}</p>
        </div>
        <div className={classes.buttonContainer}>
          <button
            type="button"
            className={classes.cancel}
            style={{ color }}
            onClick={() => setModal(false)}
          >
            {t('AdditionalVideos.modals.cancel')}
          </button>
          <button
            type="submit"
            className={classes.yes}
            style={{ background: color }}
            disabled={isLoading}
          >
            {t('AdditionalVideos.modals.confirmUpdate')}
            <ArrowRight />
          </button>
        </div>
      </div>
    </form>
  );
};

UpdateVideoForm.defaultProps = {
  isInternal: false,
};
