import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { getCandidateVideoAnalyzer } from './queries';
import { baseQuery } from '../../utils/baseQuery';

export const candidateVideoAnalyzerApi = createApi({
  reducerPath: ReducerPaths.CANDIDATE_VIDEO_ANALYZER,
  baseQuery,
  tagTypes: ['candidateVideoAnalyzer'],
  endpoints: (builder) => ({
    getCandidateVideoAnalyzer: getCandidateVideoAnalyzer(builder),
  }),
});

export const { useGetCandidateVideoAnalyzerQuery } = candidateVideoAnalyzerApi;
