import useOnClickOutside from '$hooks/useOnClickOutside';
import { ChangeEvent, useRef, useState } from 'react';
import { segmentTrack } from '$utils/handleSegment';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { ReactComponent as DownArrow } from '$icons/OpenArrow.svg';
import { JobInterviewsViewModeTypes } from './JobInterviewViewsFilterButton';

type FiltersStatusType = {
  id: string;
  text: string;
  styles: string;
};

const JobInterviewStatusFilterButton = ({
  formSetValueHook,
  statusFilters,
}: {
  formSetValueHook: UseFormSetValue<FieldValues>;
  statusFilters?: string[];
}) => {
  const filtersStatus: FiltersStatusType[] = [
    {
      id: 'NeedToSchedule',
      text: 'Need to schedule',
      styles:
        'bg-jobInterviewStatus-needToScheduleBg text-internal-jobInterviewStatus-needToScheduleText',
    },
    {
      id: 'Scheduled',
      styles:
        'bg-jobInterviewStatus-scheduledBg text-jobInterviewStatus-scheduledText',
      text: 'Scheduled',
    },
    {
      id: 'CancelledCandidate',
      styles:
        'bg-jobInterviewStatus-canceledBg text-jobInterviewStatus-canceledText',
      text: 'Cancelled - Candidate',
    },
    {
      id: 'CancelledClient',
      styles:
        'bg-jobInterviewStatus-canceledBg text-jobInterviewStatus-canceledText',
      text: 'Cancelled - Client',
    },
    {
      id: 'CompleteCandidateLate',
      styles:
        'bg-jobInterviewStatus-completeBg text-jobInterviewStatus-completeText',
      text: 'Complete - Candidate Late',
    },
    {
      id: 'CompleteClientLate',
      styles:
        'bg-jobInterviewStatus-completeBg text-jobInterviewStatus-completeText',
      text: 'Complete - Client Late',
    },
    {
      id: 'CompleteOnTime',
      styles:
        'bg-jobInterviewStatus-completeBg text-jobInterviewStatus-completeText',
      text: 'Complete - On Time',
    },
    {
      id: 'Rescheduled',
      styles:
        'bg-jobInterviewStatus-rescheduledBg text-jobInterviewStatus-rescheduledText',
      text: 'Rescheduled',
    },
  ];

  const filtersDOM = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenStatus = () => {
    setIsOpen((prev) => !prev);
  };

  useOnClickOutside(filtersDOM, () => setIsOpen(false));

  const onChangeCheckboxHandler = (
    ev: ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (ev.target.checked) {
      formSetValueHook('status', [...(statusFilters ?? []), id]);
    } else {
      formSetValueHook(
        'status',
        (statusFilters || []).filter((item) => item !== id)
      );
    }
    segmentTrack('Interviews - Modified Status Filter');
  };

  return (
    <div className="relative" ref={filtersDOM}>
      <button
        className="bg-internal-main rounded text-white p-2 flex flex-row items-center gap-2"
        type="button"
        onClick={handleOpenStatus}
      >
        {`Status${
          statusFilters && statusFilters?.length > 0
            ? ` (${statusFilters?.length})`
            : ''
        }`}
        <DownArrow
          className={`w-3 transition-transform ${isOpen && 'rotate-180'}`}
        />
      </button>
      {isOpen && (
        <div className="rounded overflow-hidden mt-2 absolute whitespace-nowrap border shadow z-20">
          {filtersStatus.map(({ styles, text, id }) => {
            return (
              <label
                htmlFor={id}
                className={`flex items-center px-4 py-2 ${styles}`}
                key={id}
              >
                <input
                  type="checkbox"
                  id={id}
                  className="mr-2"
                  value={0}
                  onClick={() =>
                    formSetValueHook(
                      'viewModes',
                      JobInterviewsViewModeTypes.custom
                    )
                  }
                  defaultChecked={
                    statusFilters?.some((item) => item === id) || false
                  }
                  onChange={(ev) => onChangeCheckboxHandler(ev, id)}
                />
                {text}
              </label>
            );
          })}
        </div>
      )}
    </div>
  );
};

JobInterviewStatusFilterButton.defaultProps = {
  statusFilters: undefined,
};

export default JobInterviewStatusFilterButton;
