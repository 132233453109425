import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { agreeCandidateTos } from './mutations';

export const candidateTechnicalInfoApi = createApi({
  reducerPath: ReducerPaths.CANDIDATE_TECHNICAL_INFO,
  baseQuery,
  endpoints: (builder) => ({
    agreeCandidateTos: agreeCandidateTos(builder),
  }),
});

export const { useAgreeCandidateTosMutation } = candidateTechnicalInfoApi;
