import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseArrow } from '../../icons/CloseArrowColor.svg';
import { ReactComponent as OpenArrow } from '../../icons/OpenArrowColor.svg';
import getRangeOfColor from '../../utils/rangeofColors';
import useWindowWidth from '../../hooks/useWindowWidth';
import classes from './SimeraScore.module.css';
import { useAppSelector } from '../../hooks';
import ScoreStep from './ScoreStep';
import { useGetScoreQuery } from '../../store/modules/candidates';

interface StepState {
  name: string;
  percentage: number;
  icon?: FC | null;
}
interface Props {
  open?: boolean;
  stepState: StepState[];
}
const defaultProps = {
  open: false,
};

const SimeraScore: FC<Props> = ({ open, stepState }) => {
  const { serverCandidate } = useAppSelector((state) => state.auth);
  const isMobile = useWindowWidth() < 834;
  const [isOpen, setIsOpen] = useState(open || false);
  const [height, setHeight] = useState<number | undefined>(
    open ? undefined : 0
  );
  const navigate = useNavigate();
  const [t] = useTranslation('global');
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  const { data: candidate } = useGetScoreQuery(
    {
      id: serverCandidate?.Id ?? 0,
    },
    { refetchOnMountOrArgChange: 3, pollingInterval: 3 * 60 * 1000 }
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    if (height && isOpen && ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setHeight(ref.current?.getBoundingClientRect().height);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const goToProfile = () => {
    navigate('/profile', { replace: true });
  };

  return (
    <div className={classes.SimeraScoreContainer}>
      <div
        className={classes.ScoreHeader}
        onClick={handleFilterOpening}
        onKeyDown={handleFilterOpening}
        aria-hidden="true"
      >
        <div
          className={classes.ScoreValue}
          style={{ backgroundColor: getRangeOfColor(candidate?.Score ?? 0) }}
        >
          <p>{candidate?.Score}</p>
        </div>

        <div className={classes.Description}>
          <strong data-testid="title" className={classes.Rank}>
            {t('score.completeInfo')}
          </strong>
          <p> {t('score.moreInfo')}</p>
        </div>
        {isMobile && (
          <button type="button">
            {!isOpen ? (
              <OpenArrow className={classes.Arrow} />
            ) : (
              <CloseArrow className={classes.Arrow} />
            )}
          </button>
        )}
      </div>
      {isMobile ? (
        <div className={classes.Collapsible} style={{ height }}>
          <div ref={ref}>
            <div className={classes.ScoreBody}>
              {stepState.map((step) => (
                <ScoreStep
                  key={step.name}
                  title={step.name}
                  completed={Math.round(step.percentage)}
                  icon={step.icon}
                />
              ))}
            </div>
            <div
              aria-hidden="true"
              onClick={goToProfile}
              className={classes.ScoreFooter}
            >
              <h5>{t('score.completeProfileLater')}</h5>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.ScoreBody}>
            {stepState.map((step) => (
              <ScoreStep
                key={step.name}
                title={step.name}
                completed={Math.round(step.percentage)}
                icon={step.icon}
              />
            ))}
          </div>
          <div className={classes.ScoreFooter}>
            <h5 aria-hidden="true" onClick={goToProfile}>
              {t('score.completeProfileLater')}
            </h5>
          </div>
        </>
      )}
    </div>
  );
};

SimeraScore.defaultProps = defaultProps;
export default SimeraScore;
