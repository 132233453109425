import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  GetInternalCandidatesInput,
  GetInternalCandidatesOutput,
} from '../candidates.types';

export const getInternalCandidates = (builder: CandidateBuilder) =>
  builder.query<GetInternalCandidatesOutput, GetInternalCandidatesInput>({
    query: (args) => ({
      url: '/candidates',
      method: 'GET',
      params: {
        take: args.query?.take ?? 10,
        page: args.query?.page ?? 1,
        ...args.query,
      },
    }),
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[getInternalCandidates]',
        (args.lang ?? 'en') === 'en'
          ? ErrorMessageEn.ErrorGettingInternalCandidates
          : ErrorMessageEs.ErrorGettingInternalCandidates,
        err,
        true
      );

      return err.data;
    },
    providesTags: (result) =>
      result
        ? [
            ...result.data.map(({ Id }) => ({
              type: 'Candidate' as const,
              Id,
            })),
            { type: 'Candidates' },
          ]
        : [{ type: 'Candidates' }],
  });
