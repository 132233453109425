import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateCandidateWorkSummaryMutation } from '../../store/modules/candidates';
import CancelModal from '../CancelModal/CancelModal';
import BaseTextArea from '../TextArea/BaseTextArea';
import { SaveState } from '../../types/ForrmType';
import FormFooter from '../FormFooter/FormFooter';
import { Candidate } from '../../types/candidates';

interface FormInfo {
  WorkSummary: string;
}

interface Props {
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel: () => void;
  onClickCancelModal: (isCancel: boolean, cotinueEditing: boolean) => void;
  show: boolean;
  candidate: Candidate;
}

const WorkSummaryFormContainer: FC<Props> = ({
  setIsEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  candidate,
}) => {
  const [t] = useTranslation('global');
  const [counter, setCounter] = useState<number>(0);
  const [updateCandidateWorkSummary, { isSuccess }] =
    useUpdateCandidateWorkSummaryMutation();

  const { register, handleSubmit, formState } = useForm<FormInfo>({
    defaultValues: {
      WorkSummary: candidate.WorkSummary || '',
    },
  });

  const { errors, isDirty } = formState;

  const workSummary = register('WorkSummary', {
    required: `${t('workHistory.requiredField')}`,
  });

  useEffect(() => {
    if (isSuccess) {
      setIsEdit(true);
    }
  }, [isSuccess, setIsEdit]);

  const saveInfo = async (data: FormInfo) => {
    updateCandidateWorkSummary({
      candidateId: candidate.Id,
      data,
    });
  };

  return (
    <div data-testid="work-summary-form-container">
      <form onSubmit={handleSubmit(saveInfo)}>
        <BaseTextArea
          counter={counter}
          name={workSummary.name}
          onChange={(e) => {
            setCounter(e.target.value.length);
            workSummary.onChange(e);
          }}
          refProp={workSummary.ref}
          label={t('workHistory.descriptionSummary')}
          onBlur={workSummary.onBlur}
          secondary
          errorText={errors?.WorkSummary?.message}
          maxLength={1000}
        />
        <br />
        <FormFooter
          onClickCancel={onClickCancel}
          disabled={!isDirty}
          saveState={SaveState.IDLE}
          translate={t}
          submit
        />
      </form>
      {show && <CancelModal onClickCancelModal={onClickCancelModal} />}
    </div>
  );
};

export default WorkSummaryFormContainer;
