import { ErrorMessageEn } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  GetCandidatesOuput,
  GetCandidatesInput,
} from '../candidates.types';

export const getCandidates = (builder: CandidateBuilder) =>
  builder.query<GetCandidatesOuput, GetCandidatesInput>({
    query: (options) => ({
      url: '/candidates/find-all',
      params: {
        take: 10,
        page: options.page,
        ...(options.query ?? {}),
      },
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.data.map(({ Id }) => ({
              type: 'Candidate' as const,
              Id,
            })),
            { type: 'Candidates' },
          ]
        : [{ type: 'Candidates' }],
    transformErrorResponse: (error) => {
      errorHandler(
        '[getCandidatesEndpoint]',
        ErrorMessageEn.GetCandidatesFail,
        error.data,
        true
      );
      return error.data;
    },
  });
