import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import CreatePositionDto from '../../types/serverTypes/createPositionDto';
import EducationDegreeTypes from '../../types/DegreeTypes';
import AutocompleteRHF from '../Autocomplete/AutocompleteRHF';
import BaseTextArea from '../TextArea/BaseTextArea';
import MultipleTagSelector from '../MultipleTagSelector/MultipleTagSelector';
import BasicInput from '../Inputs/BaseInput';
import NumberInput from '../NumberInput/NumberInput';
import { ReactComponent as ArrowUp } from '../../icons/CloseArrowColor.svg';
import { ReactComponent as ArrowDown } from '../../icons/OpenArrowColor.svg';
import FormFooter from '../FormFooter/FormFooter';
import CancelModal from '../CancelModal/CancelModal';
import classes from './AddNewJobModal.module.css';
import SelectInput from '../SelectInput/SelectInput';
import { SaveState } from '../../types/ForrmType';
import {
  useGetJobPositionsQuery,
  useGetSkillsQuery,
} from '../../store/modules/form-info';
import {
  useLoadCompaniesQuery,
  useSavePositionMutation,
} from '../../store/modules/companies';
import { useAppSelector } from '../../hooks';
import { usePositions } from '../../store/modules/companies/hooks/use-positions';

export type AddNewJobFormProps = {
  onClose: () => void;
};

const AddNewJobForm: FC<AddNewJobFormProps> = ({ onClose }) => {
  const [showContent, setShowContent] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [counterObjective, setCounterObjective] = useState<number>(0);
  const [showCancel, setShowCancel] = useState(false);
  const { data: fetchedCompanies } = useLoadCompaniesQuery({});
  const companies = useMemo(
    () =>
      fetchedCompanies?.map((company) => ({
        Id: company.Id,
        title: company.Name,
      })),
    [fetchedCompanies]
  );
  const { addPositionAction } = usePositions();
  const [savePosition, { data: newPosition, status, isSuccess }] =
    useSavePositionMutation();

  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      onClose();
    }
    setShowCancel(false);
  };

  const handleCancel = () => {
    setShowCancel(true);
  };
  const [t] = useTranslation('global');
  const { data: skills } = useGetSkillsQuery({ t });
  const { data: jobPositions } = useGetJobPositionsQuery({ t });

  const { serverInternal } = useAppSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<CreatePositionDto>({
    defaultValues: {
      Name: '',
      JobDescription: '',
      Responsibilities: '',
      DegreeName: '',
      JobPosition: { title: '' },
      SalaryLowerBound: 0,
      SalaryUpperBound: 0,
      Skills_position: [],
      DealId: '',
    },
  });
  const responsibilities = register('Responsibilities', {
    required: 'Responsibilities is required',
  });
  const experienceYear = register('ExperienceYears', {
    required: 'Experience years is required',
    min: {
      value: 0,
      message: 'Experience years must be greater than 0',
    },
    max: {
      value: 30,
      message: 'Experience years must be less than 30',
    },
  });

  const salaryLowerBound = useWatch({ control, name: 'SalaryLowerBound' });
  const salaryUpperBound = useWatch({ control, name: 'SalaryUpperBound' });
  const name = register('JobPosition.title', {
    required: 'Job Position is required',
    validate: {
      jopPositionListed: (value) => {
        return value ? jobPositions?.some((job) => job.title === value) : false;
      },
    },
  });
  const company = register('Company.title', {
    required: Boolean(serverInternal),
    validate: {
      companyListed: (value) => {
        return serverInternal
          ? companies?.some((comp) => comp.title === value)
          : true;
      },
    },
  });
  const jobDescription = register('JobDescription');
  const degreeType = register('DegreeType');
  const degreeName = register('DegreeName');
  const dealId = register('DealId', { required: !!serverInternal });

  const optionsSelect = [
    {
      id: EducationDegreeTypes.ASSOCIATE_DEGREE,
      text: EducationDegreeTypes.ASSOCIATE_DEGREE,
    },
    {
      id: EducationDegreeTypes.BACHELORS_DEGREE,
      text: EducationDegreeTypes.BACHELORS_DEGREE,
    },
    {
      id: EducationDegreeTypes.HIGH_SCHOOL,
      text: EducationDegreeTypes.HIGH_SCHOOL,
    },
    {
      id: EducationDegreeTypes.MASTERS_DEGREE,
      text: EducationDegreeTypes.MASTERS_DEGREE,
    },
    { id: EducationDegreeTypes.PHD, text: EducationDegreeTypes.PHD },
    {
      id: EducationDegreeTypes.VOCATIONAL,
      text: EducationDegreeTypes.VOCATIONAL,
    },
  ];

  const save = async (data: CreatePositionDto) => {
    const newData = { ...data, ExperienceYears: Number(data.ExperienceYears) };
    if (newData.DegreeType === '') delete newData.DegreeType;
    await savePosition({ position: newData, lang: 'en' });

    onClose();
  };

  useEffect(() => {
    if (status === QueryStatus.fulfilled && newPosition && isSuccess) {
      addPositionAction({ ...newPosition!, CandidatesPositionStatus: [] });
    }
  }, [addPositionAction, isSuccess, newPosition, status]);

  return (
    <form onSubmit={handleSubmit(save)} data-testid="add-new-job-form">
      {serverInternal && (
        <>
          <AutocompleteRHF
            options={companies ?? []}
            label={t('EmployerAddPosition.Company')}
            placeholder={t('EmployerAddPosition.CompanyNamePlaceholder')}
            onChange={company.onChange}
            onBlur={company.onBlur}
            refProp={company.ref}
            name={company.name}
            setValue={setValue}
            errorText={errors.Company ? 'Company should be listed' : ''}
          />
          <div style={{ marginTop: '15px' }}>
            <BasicInput
              type="text"
              placeholder={t('EmployerAddPosition.DealIdPlaceholder')}
              onChange={dealId.onChange}
              onBlur={dealId.onBlur}
              refProp={dealId.ref}
              name={dealId.name}
              label={t('EmployerAddPosition.DealId')}
              errorText={errors.DealId ? 'Deal ID is required' : ''}
            />
          </div>
        </>
      )}
      <AutocompleteRHF
        options={jobPositions ?? []}
        label={t('EmployerAddPosition.PositionName')}
        placeholder={t('EmployerAddPosition.PositionNamePlaceholder')}
        onChange={name.onChange}
        onBlur={name.onBlur}
        refProp={name.ref}
        name={name.name}
        setValue={setValue}
        errorText={
          (errors?.JobPosition as any)?.title?.message ||
          ((errors?.JobPosition as any)?.title.type === 'jopPositionListed'
            ? 'Job Position should be listed'
            : '')
        }
      />
      <br />
      <BaseTextArea
        placeholder={t('EmployerAddPosition.PositionInputPlaceholder')}
        label={t('EmployerAddPosition.PositionResponsibilities')}
        onBlur={responsibilities.onBlur}
        name={responsibilities.name}
        onChange={(e) => {
          setCounter(e.target.value.length);
          responsibilities.onChange(e);
        }}
        refProp={responsibilities.ref}
        counter={counter}
        maxLength={4096}
        errorText={errors.Responsibilities?.message}
      />
      <Controller
        control={control}
        name="Skills_position"
        rules={{
          validate: (value) =>
            value.length > 0 ? true : 'Please select at least one skill',
        }}
        render={({ field, formState }) => (
          <div>
            <p>{t('EmployerAddPosition.PositionSkills')}</p>
            <MultipleTagSelector
              placeholder={t('EmployerAddPosition.PositionSkillsPlaceHolder')}
              arrayName="Skills_position"
              label="position"
              options={skills ?? []}
              secondary={Boolean(serverInternal)}
              setValue={setValue}
              selectedTags={field.value ?? []}
            />
            {formState.errors?.Skills_position && (
              <p className={classes.errorText}>
                {(formState.errors.Skills_position as any).message}
              </p>
            )}
          </div>
        )}
      />

      <div className={classes.ExperienceSalaryContainer}>
        <div className={classes.ExperienceSalary}>
          <BasicInput
            type="number"
            placeholder={t('EmployerAddPosition.PositionInputPlaceholder')}
            onChange={(e) => {
              e.target.value =
                Number(e.target.value) < 0 ? '0' : e.target.value;
              experienceYear.onChange(e);
            }}
            onBlur={experienceYear.onBlur}
            refProp={experienceYear.ref}
            name={experienceYear.name}
            label={t('EmployerAddPosition.PositionYears')}
            errorText={errors?.ExperienceYears?.message}
            minDate="0"
          />
        </div>
        <div className={classes.salaryInputsContainer}>
          <p className={classes.SalaryTitle}>
            {t('EmployerAddPosition.PositionSalary')}:
          </p>
          <div className={classes.SalaryInputs}>
            <Controller
              control={control}
              name="SalaryLowerBound"
              rules={{
                min: {
                  value: 0,
                  message: 'Salary lower bound must be greater than 0',
                },
                validate: (value) =>
                  value < salaryUpperBound ||
                  'The lower bound must be less than the upper bound',
              }}
              render={({ field, formState }) => (
                <div className={classes.TextSalaryContainer}>
                  <NumberInput
                    label={t('EmployerAddPosition.BudgetMin')}
                    value={`$${field.value.toLocaleString().replace(',', '.')}`}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      setValue('SalaryLowerBound', Number(value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    separator
                  />
                  {formState.errors.SalaryLowerBound && (
                    <p className={classes.errorText}>
                      {formState.errors.SalaryLowerBound.message}
                    </p>
                  )}
                </div>
              )}
            />

            <Controller
              control={control}
              name="SalaryUpperBound"
              rules={{
                min: {
                  value: 0,
                  message: 'Salary upper bound must be greater than 0',
                },
                validate: (value) =>
                  value > salaryLowerBound ||
                  'The upper bound must be greater than the lower bound',
              }}
              render={({ field, formState }) => (
                <div className={classes.TextSalaryContainer}>
                  <NumberInput
                    label={t('EmployerAddPosition.BudgetMax')}
                    value={`$${field.value.toLocaleString().replace(',', '.')}`}
                    onChange={async (e) => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      await setValue('SalaryUpperBound', Number(value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                      // reset the error in the lower bound
                      setValue('SalaryLowerBound', salaryLowerBound, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                  />
                  {formState.errors.SalaryUpperBound && (
                    <p className={classes.errorText}>
                      {formState.errors.SalaryUpperBound.message}
                    </p>
                  )}
                </div>
              )}
            />
          </div>
        </div>
      </div>

      <button
        className={classes.accordionTitle}
        type="button"
        onClick={() => setShowContent((v) => !v)}
      >
        {t('EmployerAddPosition.PositionAdditional')}
        {!showContent ? <ArrowDown /> : <ArrowUp />}
      </button>

      {showContent && (
        <div className={classes.additionalSettingsContent}>
          <BaseTextArea
            placeholder={t('EmployerAddPosition.PositionInputPlaceholder')}
            label={t('EmployerAddPosition.PositionObjective')}
            onBlur={jobDescription.onBlur}
            name={jobDescription.name}
            onChange={(e) => {
              setCounterObjective(e.target.value.length);
              jobDescription.onChange(e);
            }}
            refProp={jobDescription.ref}
            counter={counterObjective}
            maxLength={600}
          />
          <div className={classes.additionalSettingsDegree}>
            <SelectInput
              onChange={degreeType.onChange}
              onBlur={degreeType.onBlur}
              refProp={degreeType.ref}
              name={degreeType.name}
              options={optionsSelect}
              label={t('EmployerAddPosition.PositionDegreeType')}
              height="55px"
              placeholder="Select Degree Type"
            />
            <BasicInput
              type="text"
              placeholder={t('EmployerAddPosition.PositionInputPlaceholder')}
              onChange={degreeName.onChange}
              onBlur={degreeName.onBlur}
              refProp={degreeName.ref}
              name={degreeName.name}
              label={t('EmployerAddPosition.PositionDegreeName')}
              height="56px"
            />
          </div>
        </div>
      )}
      <br />

      <div style={{ marginTop: '1rem' }}>
        <FormFooter
          translate={t}
          saveState={SaveState.IDLE}
          submit
          onClickCancel={handleCancel}
          useMobile={false}
        />
      </div>
      {showCancel && <CancelModal onClickCancelModal={handleCancelModal} />}
    </form>
  );
};

export default AddNewJobForm;
