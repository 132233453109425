import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ShowVideo.module.css';
import { BaseCheckbox } from '../../../../UI';
import { ReactComponent as EyeGreen } from '../../../../../icons/EyeGreen.svg';
import { ReactComponent as EyeOff } from '../../../../../icons/EyeOff.svg';
import { AdditionalVideo } from '../../../../../types/additional-video/additional-video';
import { Video } from '../../../../../store/modules/uploads';

interface HideVideoProps {
  showCheckbox: boolean;
  video: AdditionalVideo | Video;
  selectedVideos: number[];
  setShowVideoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedVideos: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ShowVideo = memo(
  ({
    showCheckbox,
    video,
    selectedVideos,
    setShowVideoModal,
    setSelectedVideos,
  }: HideVideoProps) => {
    const [t] = useTranslation('global');
    const [isHovered, setIsHovered] = useState(false);

    const handleVisible = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setShowVideoModal(true);
      },
      [setShowVideoModal]
    );

    const handleCheckboxChange = useCallback(
      (isChecked: boolean) => {
        const videoId = video.Id;
        if (videoId) {
          if (!isChecked) {
            setSelectedVideos(selectedVideos.filter((id) => id !== videoId));
          } else {
            setSelectedVideos([...selectedVideos, videoId]);
          }
        }
      },
      [selectedVideos, setSelectedVideos, video.Id]
    );

    return !showCheckbox ? (
      <button
        type="button"
        className={classes.revealButton}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleVisible}
      >
        {isHovered
          ? t('AdditionalVideos.buttons.visible')
          : t('AdditionalVideos.buttons.hidden')}
        {isHovered ? <EyeGreen /> : <EyeOff />}
      </button>
    ) : (
      <div className={classes.checkbox}>
        {video.Id && (
          <BaseCheckbox
            id={video.Id}
            isChecked={selectedVideos.includes(video.Id)}
            onChange={handleCheckboxChange}
          />
        )}
      </div>
    );
  }
);
