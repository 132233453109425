import { userLanguage } from '$translations/index';
import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import { AdditionalVideoBuilder } from '../additional-videos.types';

export const getEnglishAssessment = (builder: AdditionalVideoBuilder) =>
  builder.query<any, any>({
    query: ({ candidateId }) => ({
      url: `english-assessment/candidate/${candidateId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ Id }: any) => ({
              type: 'additionalVideosEnglisAssessment' as const,
              id: Id,
            })),
            { type: 'additionalVideosEnglisAssessment', id: 'LIST' },
          ]
        : [{ type: 'additionalVideosEnglisAssessment', id: 'LIST' }],
    transformErrorResponse: (error) => {
      errorHandler(
        '[GET_ENGLISH_ASSESSMENT]',
        userLanguage === 'es'
          ? ErrorMessageEs.InfoFail
          : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
