import { ErrorMessageEn, ErrorMessageEs } from '$constants/alerts';
import { userLanguage } from '$translations/index';
import { errorHandler } from '../../../utils';
import {
  AdditionalVideoBuilder,
  InitiateUploadChunkAdditionalVideoInput,
  InitiateUploadChunkAdditionalVideoOutput,
} from '../additional-videos.types';

export const initiateChunkUploadAdditionalVideo = (
  builder: AdditionalVideoBuilder
) =>
  builder.mutation<
    InitiateUploadChunkAdditionalVideoOutput,
    InitiateUploadChunkAdditionalVideoInput
  >({
    query: ({ key }) => ({
      url: '/additional-video/presigned-url/initiate-upload',
      method: 'POST',
      body: { Key: key },
    }),
    transformErrorResponse: (error) => {
      errorHandler(
        '[INITIATE UPLOAD ADDITIONAL VIDEO IN CHUNKS]',
        userLanguage === 'es'
          ? ErrorMessageEs.videoUpload
          : ErrorMessageEn.videoUpload,
        error,
        true
      );
    },
  });
