import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '$constants/alerts';
import { errorHandler, successHandler } from '$store/utils';
import { ValidLanguages, userLanguage } from '$translations/index';
import { DeleteInterviewSlotInput, InterviewBuilder } from '../interview.types';

export const deleteInterviewSlot = (builder: InterviewBuilder) =>
  builder.mutation<any, DeleteInterviewSlotInput>({
    query: ({ scheduleId }) => ({
      url: `/interview/slots/${scheduleId}`,
      method: 'DELETE',
    }),
    invalidatesTags: [{ type: 'Slots' }],
    transformResponse: (response) => {
      successHandler(
        userLanguage === 'es'
          ? SuccessMessageEs.SuccessfullyDeleted
          : SuccessMessageEn.SuccessfullyDeleted,
        true
      );
      return response;
    },
    transformErrorResponse: (err) => {
      errorHandler(
        '[DeleteInterviewSlot]',
        userLanguage === ValidLanguages.es
          ? ErrorMessageEs.DeleteInterviewSlot
          : ErrorMessageEn.DeleteInterviewSlot,
        err,
        true
      );

      return err;
    },
  });
