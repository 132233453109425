import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CompanyBuilder,
  CompanyDataInput,
  CompanyDataOutput,
} from '../companies.types';

export const getPaginatedCompanies = (builder: CompanyBuilder) =>
  builder.query<CompanyDataOutput, CompanyDataInput>({
    query: ({ options }) => ({
      url: '/companies/paginated',
      method: 'GET',
      params: options,
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.data.map(({ companyId }) => ({
              type: 'Company' as const,
              companyId,
            })),
            { type: 'Company', Id: 'LIST' },
          ]
        : [{ type: 'Company', Id: 'LIST' }],
    transformErrorResponse: (error, _, arg) => {
      errorHandler(
        '[getCompaniesPaginated]',
        arg.lang === 'es'
          ? ErrorMessageEs.loadPaginatedCompaniesError
          : ErrorMessageEn.loadPaginatedCompaniesError,
        error,
        true
      );
    },
  });
