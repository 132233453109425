import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import en from 'dayjs/locale/en';
import es from 'dayjs/locale/es';
import { GetCandidateInterviewsOutput } from '$store/modules/interview';
import { ValidLanguages, userLanguage } from '$translations/index';
import AvailableInterviewSlotCard from './AvailableInterviewSlotCard';

type InterviewPositionCardProps = {
  interviewData: GetCandidateInterviewsOutput;
};

const InterviewPositionCard = ({
  interviewData,
}: InterviewPositionCardProps) => {
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.locale(userLanguage === ValidLanguages.es ? es : en);

  const [t] = useTranslation('global');

  return (
    <div className="bg-white p-8 mb-4">
      <h1
        className="font-semibold text-2xl mb-4"
        data-testid="interviewPositionCardTitle"
      >
        {`${t('candidateJobInterview.position')} ${
          interviewData.CandidatePositionStatus.Position.JobPosition.title
        } ${t('candidateJobInterview.for')} ${
          interviewData.User.Employer.Company.Name
        }`}
      </h1>
      <div className="flex flex-row gap-4 overflow-x-auto">
        {interviewData.UserSchedules.map(
          ({ StartDate, Id, Timezone, CreateTime }) => {
            const formattedDate = dayjs.tz(StartDate, Timezone);

            const createTime = dayjs(CreateTime);

            return (
              <AvailableInterviewSlotCard
                datetime={formattedDate}
                scheduleId={Id}
                key={`available_interviwe_slot_${Id}`}
                createTime={createTime}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default InterviewPositionCard;
