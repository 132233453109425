import { ErrorMessageEn } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CandidateBuilder,
  GetScoreOuput,
  GetScoreInput,
} from '../candidates.types';

export const getScore = (builder: CandidateBuilder) =>
  builder.query<GetScoreOuput, GetScoreInput>({
    query: ({ id }) => ({
      url: '/candidates/score',
      params: { id },
      method: 'GET',
    }),
    providesTags: (result, error, { id }) => [
      {
        type: 'Score',
        id,
      },
    ],
    transformErrorResponse: (error) => {
      errorHandler(
        '[getCandidatesEndpoint]',
        ErrorMessageEn.GetCandidatesFail,
        error.data,
        true
      );
      return error.data;
    },
  });
