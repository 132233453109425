export const getZone = (
  timezone: string
): 'eastern' | 'central' | 'pacific' | 'mountain' => {
  switch (timezone) {
    case 'America/New_York':
      return 'eastern';
    case 'America/Chicago':
      return 'central';
    case 'America/Los_Angeles':
      return 'pacific';
    case 'America/Denver':
      return 'mountain';
    default:
      return 'eastern';
  }
};
