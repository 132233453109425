import { useEffect, useRef } from 'react';
import classes from './IndeterminateCheckbox.module.css';

interface IndeterminateCheckboxProps {
  indeterminate: boolean;
  checked: boolean;
}

const IndeterminateCheckbox = ({
  indeterminate,
  ...rest
}: IndeterminateCheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input className={classes.checkbox} type="checkbox" ref={ref} {...rest} />
  );
};

export default IndeterminateCheckbox;
