import React, { useState } from 'react';
import ShareVideoButton from '$components/ShareVideoButton/ShareVideoButton';
import { ReactComponent as Loading } from '$icons/loading-buffer.svg';
import capitalizedName from '../../../../../../../utils/capitalizedName';
import { AdditionalVideo } from '../../../../../../../types/additional-video/additional-video';
import { useAdditionalVideo } from '../../../../../../../store/modules/additional-videos/hooks';
import { calculateDays } from '../../../../utils';
import classes from './VideoCard.module.css';
import { HideVideoModal } from '../../../HideVideoModal';
import { HideVideo } from '../../../HideVideo';
import { ShowVideoModal } from '../../../ShowVideoModal';
import { ShowVideo } from '../../../ShowVideo';
import VideoGenericElement from '../../../../../../GenericElements/VideoGenericElement';

interface Props {
  candidateId: number;
  video: AdditionalVideo;
  showCheckbox: boolean;
  selectedVideos: number[];
  setSelectedVideos: React.Dispatch<React.SetStateAction<number[]>>;
  internal?: boolean;
  setSelectedShowVideos: React.Dispatch<React.SetStateAction<number[]>>;
  selectedShowVideos: number[];
  selectedVideosType: number[];
  selectedShowVideosType: number[];
}
const VideoCardFull = ({
  candidateId,
  video,
  showCheckbox,
  selectedVideos,
  setSelectedVideos,
  internal,
  setSelectedShowVideos,
  selectedShowVideos,
  selectedShowVideosType,
  selectedVideosType,
}: Props) => {
  const { setVideo, openAdditionalVideoModal, setVideoType } =
    useAdditionalVideo();
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const isProcessingVideo = video.Key === '' || video.Url === '';
  const styleOpacityVideos =
    ((selectedShowVideos.length && video.IsVisible) ||
      (selectedVideos.length && !video.IsVisible) ||
      (selectedShowVideosType.length && video.IsVisible) ||
      (selectedVideosType.length && !video.IsVisible)) &&
    showCheckbox
      ? classes.opacityVideos
      : '';

  const handleHideVideos = () => {
    setSelectedVideos((prevSelectedVideos) => {
      if (prevSelectedVideos.includes(video.Id)) {
        return prevSelectedVideos.filter((id) => id !== video.Id);
      }
      return [...prevSelectedVideos, video.Id];
    });
  };

  const handleShowVideos = () => {
    setSelectedShowVideos((prevSelectedVideos) => {
      if (prevSelectedVideos.includes(video.Id)) {
        return prevSelectedVideos.filter((id) => id !== video.Id);
      }
      return [...prevSelectedVideos, video.Id];
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isProcessingVideo) return;
    if (showCheckbox) {
      if (video.IsVisible && !selectedShowVideos.length) {
        handleHideVideos();
      }
      if (!video.IsVisible && !selectedVideos.length) {
        handleShowVideos();
      }
    } else {
      e.stopPropagation();
      setVideoType(null);
      setVideo(video);
      openAdditionalVideoModal();
    }
  };

  // Hides processing video card to employers
  if (isProcessingVideo && !internal) return null;

  return (
    <>
      <div
        className={`${classes.card} ${styleOpacityVideos} ${
          isProcessingVideo ? 'opacity-60' : null
        }`}
        tabIndex={0}
        role="button"
        onKeyDown={() => {
          setVideoType(null);
          setVideo(video);
        }}
        onClick={handleClick}
      >
        {internal &&
          video.IsVisible &&
          !selectedShowVideos.length &&
          !selectedShowVideosType.length && (
            <HideVideo
              selectedVideos={selectedVideos}
              setSelectedVideos={setSelectedVideos}
              setShowVisibilityModal={setShowVisibilityModal}
              showCheckbox={showCheckbox}
              video={video}
            />
          )}
        {!video.IsVisible && internal && (
          <div className={classes.hideVideo}>
            {!selectedVideos.length && !selectedVideosType.length && (
              <ShowVideo
                video={video}
                showCheckbox={showCheckbox}
                setShowVideoModal={setShowVideoModal}
                selectedVideos={selectedShowVideos}
                setSelectedVideos={setSelectedShowVideos}
              />
            )}
          </div>
        )}
        {isProcessingVideo ? (
          <div
            className={`bg-white flex flex-col items-center ${classes.video}`}
          >
            <div className="pt-4">Processing</div>
            <Loading />
          </div>
        ) : (
          <VideoGenericElement
            className={classes.video}
            muted
            videoData={video}
          />
        )}
        {internal && video.Key !== '' && (
          <div className="absolute top-2 left-2 bg-white rounded cursor-pointer z-10">
            <ShareVideoButton
              shareReferenceId={video.ShareReferenceId}
              internal={internal}
              candidateId={candidateId}
            />
          </div>
        )}
        <div className={`bg-white ${classes.container}`}>
          <p className={`break-words ${classes.cardTitle}`}>
            {capitalizedName(video.Name)}
          </p>
          <p className={classes.videoDate}>{`${calculateDays(
            video.CreateTime
          )}`}</p>
        </div>
      </div>

      {showVisibilityModal && (
        <HideVideoModal setModal={setShowVisibilityModal} video={video} />
      )}

      {showVideoModal && (
        <ShowVideoModal setModal={setShowVideoModal} video={video} />
      )}
    </>
  );
};

VideoCardFull.defaultProps = {
  internal: false,
};

export default VideoCardFull;
