import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  CandidateTechnicalInfoBuilder,
  SaveCandidateTechnicalInfoInternalInput,
  SaveCandidateTechnicalInfoInternalOutput,
} from '../candidate-technical-info.types';

export const saveCandidateTechnicalInfoInternal = (
  builder: CandidateTechnicalInfoBuilder
) =>
  builder.mutation<
    SaveCandidateTechnicalInfoInternalOutput,
    SaveCandidateTechnicalInfoInternalInput
  >({
    query: ({ data, candidateId }) => {
      const url = data?.Id
        ? `/candidate-technical-information/${data?.Id}`
        : `/candidate-technical-information/add/internal/${candidateId}`;
      const method = data?.Id ? 'PUT' : 'POST';
      return {
        url,
        method,
        body: {
          ...data,
          Id: data?.Id,
          AvailableSpace: data?.AvailableSpace!,
          CPUModel: data?.CPUModel,
          OperatingSystem: data?.OperatingSystem,
          RAM: parseInt(data?.RAM!, 10),
          Storage: data?.Storage!,
        },
      };
    },
    invalidatesTags: [
      {
        type: 'CandidateTechnicalInfo',
        id: 'LIST',
      },
      'Score',
    ],
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[setTechnicalInformation]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
