import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { getCandidateJobInterest } from './queries';
import {
  addCandidateJobInterest,
  addCandidateJobInterestInternal,
} from './mutations';

export const candidateJobInterestApi = createApi({
  reducerPath: ReducerPaths.CANDIDATE_JOB_INTEREST,
  baseQuery,
  tagTypes: ['CandidateJobInterest', 'Score'],
  endpoints: (builder) => ({
    getCandidateJobInterest: getCandidateJobInterest(builder),
    addCandidateJobInterest: addCandidateJobInterest(builder),
    addCandidateJobInterestInternal: addCandidateJobInterestInternal(builder),
  }),
});

export const {
  useGetCandidateJobInterestQuery,
  useLazyGetCandidateJobInterestQuery,
  useAddCandidateJobInterestMutation,
  useAddCandidateJobInterestInternalMutation,
} = candidateJobInterestApi;
