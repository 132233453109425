import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classes from './SinglePositionCard.module.css';
import { ReactComponent as FlipToggle } from '../../icons/FlipToggle.svg';
import { useLoadOnePositionQuery } from '../../store/modules/companies';
import CardSkeleton from './CandidateSummaryCardList/CardSkeleton/CardSkeleton';

interface SinglePositionCardProps {
  candidatesCount: number;
}

const SinglePositionCard: FC<SinglePositionCardProps> = ({
  candidatesCount,
}) => {
  const [t] = useTranslation('global');
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const { data: position, isLoading } = useLoadOnePositionQuery({ id });

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <CardSkeleton rows={5} hasProfilePicture={false} />
      </div>
    );
  }

  return (
    <div className={classes.PositionCardContainer}>
      <div className={classes.FirstGroupContainer}>
        <div className={classes.PositionCardHeader}>
          <p className={classes.PositionCardHeaderCompany}>
            {position?.Company?.Name?.toUpperCase()}
          </p>
          <p className={classes.PositionCardHeaderCandidates}>{`${
            candidatesCount ?? 0
          } ${t('SimeraInternal.Candidates')}`}</p>
        </div>
        <div className={classes.PositionCardHeader}>
          <p className={classes.PositionCardHeaderTitle}>
            {position?.JobPosition?.title}
          </p>
          <p className={classes.PositionId}>{`ID: ${position?.Id}`}</p>
        </div>
      </div>
      <div className={classes.PositionCardSkills}>
        <p className={classes.PositionSkillTitle}>{t('PositionCard.Skills')}</p>
        {position?.Skills_position?.map((skill, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <p className={classes.PositionSkill} key={`${skill?.Title}-${index}`}>
            {skill?.Title}
          </p>
        ))}
      </div>
      <div className={classes.PositionCardDescription}>
        <p
          className={classes.PositionCardDescriptionItem}
          data-prefix={t('PositionCard.Experience')}
          data-suffix={t('PositionCard.Years')}
        >
          {position?.ExperienceYears}
        </p>
        <p
          className={classes.PositionCardDescriptionItem}
          data-prefix={t('PositionCard.SalaryRange')}
        >
          {`$${position?.SalaryLowerBound} - $${position?.SalaryUpperBound}`}
        </p>
        <p
          className={classes.PositionCardDescriptionItem}
          data-prefix={t('PositionCard.Degree')}
          data-suffix={`${
            position?.DegreeType ? `- ${position?.DegreeType}` : ''
          }`}
        >
          {position?.DegreeName}
        </p>
      </div>
      {show && (
        <div className={classes.PositionCardDescriptionBody}>
          <div className={classes.PositionCardDescriptionBodyItem}>
            <p className={classes.PositionCardDescriptionBodyItemTitle}>
              {t('PositionCard.Responsibilities')}
            </p>
            <p className={classes.PositionCardDescriptionBodyItemText}>
              {position?.Responsibilities}
            </p>
          </div>
          <div className={classes.PositionCardDescriptionBodyItem}>
            <p className={classes.PositionCardDescriptionBodyItemTitle}>
              {t('PositionCard.JobObjective')}
            </p>
            <p className={classes.PositionCardDescriptionBodyItemText}>
              {position?.JobDescription}
            </p>
          </div>
        </div>
      )}

      <div
        className={classes.CardToggleButton}
        onClick={() => setShow(!show)}
        aria-hidden
      >
        <p>{show ? t('PositionCard.ViewLess') : t('PositionCard.ViewMore')}</p>
        <FlipToggle
          className={classes.CardToggleButtonIcon}
          transform={`scale(${show ? -1 : 1})`}
        />
      </div>
    </div>
  );
};

export default SinglePositionCard;
