import { toast } from 'react-toastify';

export const errorHandler = (
  from: string,
  customError: string,
  error: any,
  showToast: boolean,
  loadingBar?: boolean
) => {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable-next-line no-console */
    console.debug(from, error);
  }
  if (showToast) {
    toast(customError, {
      type: 'error',
      hideProgressBar: loadingBar,
      // if we want to change color to match figma
      // style: { backgroundColor: 'blue' },
    });
  }
};
