import { FC } from 'react';
import { TFunction } from 'react-i18next';
import { SaveState } from '../../types/ForrmType';
import { ReactComponent as CheckIcon } from '../../icons/CheckIcon.svg';
import classes from './Button.module.css';
import { useAppSelector } from '../../hooks';

interface Props {
  disabled?: boolean;
  submit?: boolean;
  t: TFunction<'global', undefined>;
  state: SaveState | undefined;
}

const SaveButton: FC<Props> = ({ disabled, state, submit, t }) => {
  const renderText: { [key: number]: string } = {
    [SaveState.IDLE]: t('common.save'),
    [SaveState.PENDING]: t('common.saving'),
    [SaveState.FULFILLED]: t('common.saved'),
  };

  const { serverInternal } = useAppSelector((reduxState) => reduxState.auth);
  const background = serverInternal
    ? 'var(--primary-green)'
    : 'var(--brand-blue-100)';
  const color = serverInternal ? 'var(--neutral-20)' : '#05668D';
  return (
    <button
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      style={{
        color: state === SaveState.FULFILLED ? color : '',
        background,
      }}
      className={classes.saveButton}
      data-testid="save-button"
    >
      <span className={classes.saveButtonContainer}>
        <p className={classes.saveButtonText}>{renderText[state!]}</p>
        {state === SaveState.FULFILLED && <CheckIcon fill={color} />}
      </span>
    </button>
  );
};

SaveButton.defaultProps = {
  disabled: false,
  submit: false,
};

export default SaveButton;
