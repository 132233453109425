import { useState } from 'react';
import { Dayjs } from 'dayjs';
import classes from './TimeOption.module.css';

interface TimeOptionProps {
  time: Dayjs;
  setSchedule: (hour: Dayjs) => void;
  isSelected: boolean;
  disable: boolean;
}

export const TimeOption = ({
  time,
  setSchedule,
  isSelected,
  disable,
}: TimeOptionProps) => {
  const [confirm, setConfirm] = useState(false);

  const onConfirm = () => {
    if (!isSelected && !disable) {
      setConfirm((prev) => !prev);
    }
  };

  const onSetSchedule = () => {
    setSchedule(time);
    setConfirm(false);
  };

  const onEnter = (cb: () => void) => (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      cb();
    }
  };

  return (
    <div
      className={`${
        confirm ? classes.hourContainerConfirm : classes.hourContainer
      } ${(isSelected || disable) && classes.containerOpacity}`}
    >
      <div
        data-testid="TimeOption-ConfirmTrigger"
        className={`${confirm ? classes.hourConfirm : classes.hour} ${
          disable && classes.hourDisable
        }`}
        role="button"
        tabIndex={0}
        onClick={onConfirm}
        onKeyDown={onEnter(onConfirm)}
      >
        <span>{time.format('h:00 a')}</span>
      </div>
      {confirm && (
        <div
          data-testid="TimeOption-cofirmText"
          role="button"
          tabIndex={0}
          onClick={onSetSchedule}
          onKeyDown={onEnter(onSetSchedule)}
          className={classes.confirm}
        >
          Confirm
        </div>
      )}
    </div>
  );
};
