import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { getAdditionalTagsByCandidateId } from './queries';
import { saveAdditionalTag } from './mutations/save-additional-tag.mutation';
import { updateAdditionalTags } from './mutations';

export const additionalTagApi = createApi({
  reducerPath: ReducerPaths.ADDITIONAL_TAG,
  baseQuery,
  tagTypes: ['additionalTags'],
  endpoints: (builder) => ({
    getAdditionalTagsByCandidateId: getAdditionalTagsByCandidateId(builder),
    saveAdditionalTag: saveAdditionalTag(builder),
    updateAdditionalTag: updateAdditionalTags(builder),
  }),
});

export const {
  useGetAdditionalTagsByCandidateIdQuery,
  useLazyGetAdditionalTagsByCandidateIdQuery,
  useSaveAdditionalTagMutation,
  useUpdateAdditionalTagMutation,
} = additionalTagApi;
