import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { FormTypes } from '../../../../types/user_info';
import { errorHandler, successHandler } from '../../../utils';
import {
  AddMultipleCandidateSkillsInputInternal,
  AddMultipleCandidateSkillsOutput,
  CandidateSkillBuilder,
} from '../candidate-skill.types';

export const addMultipleCandidateSkillsInternal = (
  builder: CandidateSkillBuilder
) =>
  builder.mutation<
    AddMultipleCandidateSkillsOutput,
    AddMultipleCandidateSkillsInputInternal
  >({
    query: ({ candidateId, skills }) => ({
      url: `/candidate-skills/add-range/internal/${candidateId}`,
      method: 'POST',
      body: skills.map((o: FormTypes['skills']) => ({
        Id: o?.Id,
        SkillLevel: o?.SkillLevel.val,
        Skill: o?.Skill.Id,
        CertificationId: o?.CertificationId.val,
        CertificationUrl: o?.CertificationUrl.val,
        CertificationDate: o?.certDate.val ? `${o?.certDate.val}-02` : null,
      })),
    }),
    invalidatesTags: [{ type: 'candidateSkills', id: 'LIST' }, 'Score'],
    transformResponse: (
      response: FormTypes['skillsBackendPayload'][],
      meta,
      { lang }
    ) => {
      if (lang === 'en') {
        successHandler(SuccessMessageEn.SKILLS_SAVE, true);
      } else {
        successHandler(SuccessMessageEs.SKILLS_SAVE, true);
      }
      return response.map((skill: FormTypes['skillsBackendPayload']) => {
        return {
          Id: skill?.Id,
          CertificationId: { val: skill?.CertificationId },
          Skill: { Title: skill?.Skill.Title },
          SkillLevel: { val: skill?.SkillLevel },
          certDate: {
            val: skill?.CertificationDate
              ? `${skill?.CertificationDate.split('-')[0]}-${
                  skill?.CertificationDate.split('-')[1]
                }`
              : '',
          },

          CertificationUrl: { val: skill?.CertificationUrl },
        };
      });
    },
    transformErrorResponse: (error, meta, { lang }) => {
      if (error.status === 409) {
        errorHandler(
          '[setSkillsAction]',
          lang === 'es'
            ? ErrorMessageEs.SkillExists
            : ErrorMessageEn.SkillExists,
          error,
          true
        );
      } else {
        errorHandler(
          '[setSkillsAction]',
          lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
          error,
          true
        );
      }
    },
  });
