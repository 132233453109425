import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditableContainer from '../../EditableContainer/EditableContainer';
import WorkSummaryReadOnly from '../../ReadOnly/WorkSummaryReadOnly';
import { useAppSelector } from '../../../hooks';
import { WorkSummaryFormContainer } from './WorkSummaryFormContainer';
import { useCandidateId } from '../../../hooks/useCandidateId';
import { useGetCandidateInfoQuery } from '../../../store/modules/candidates';

interface Props {
  isActive: boolean;
}

export const WorkSummaryEditableContainer: FC<Props> = ({ isActive }) => {
  const [t] = useTranslation('global');
  const candidateId = useCandidateId();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const { serverUser } = useAppSelector((state) => state.auth);
  const { data: candidate } = useGetCandidateInfoQuery(candidateId);

  const onClickPencil = () => {
    setIsEdit(false);
  };
  const handleCancel = () => {
    setShow(true);
  };

  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    setShow(false);
  };

  return (
    <EditableContainer
      title={t('workHistory.aboutMe')}
      isEditable={isEdit}
      onClickPencil={onClickPencil}
      addMore={false}
      isActive={isActive}
    >
      {!isEdit ? (
        <WorkSummaryFormContainer
          setIsEdit={setIsEdit}
          onClickCancel={handleCancel}
          onClickCancelModal={handleCancelModal}
          show={show}
          candidate={serverUser?.Candidate}
        />
      ) : (
        candidate?.WorkSummary && (
          <WorkSummaryReadOnly
            workExperienceDescription={candidate.WorkSummary}
          />
        )
      )}
    </EditableContainer>
  );
};
