import { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import classes from './ImportCandidatesModal.module.css';
import MigrationTasks from './MigrationTasks';
import Spinner from '../../Spinner/Spinner';
import TableFooter from '../../TableFooter/TableFooter';
import {
  useGetTasksQuery,
  usePostTasksMutation,
} from '../../../store/modules/background-tasks';
import { warningHandlerInvalidFiles } from '../../../store/utils';

const ImportCandidatesModal = () => {
  const [t] = useTranslation('global');
  const [acceptedFile, setAcceptedFile] = useState<File[]>([]);
  const [page, setPage] = useState<number>(1);
  const [postTasks] = usePostTasksMutation();

  const { data, isLoading, isFetching } = useGetTasksQuery(
    {
      page,
    },
    { pollingInterval: 30000 }
  );

  useEffect(() => {
    if (data?.meta.page) {
      setPage(data.meta.page);
    }
  }, [data?.meta.page]);

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejection: FileRejection[]) => {
      warningHandlerInvalidFiles(true, fileRejection, 'xml and json');
      if (acceptedFiles) {
        setAcceptedFile((prev: File[]) => {
          return [...prev, ...acceptedFiles];
        });
      }
    },
    [setAcceptedFile]
  );

  const nextPage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const previousPage = useCallback(() => {
    setPage((prev) => prev - 1);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    maxFiles: 10,
    accept: 'application/xml, text/xml',
    onDrop,
  });
  return (
    <div>
      <div
        {...getRootProps({ className: 'dropzone' })}
        className={classes.DropZoneContainer}
      >
        {!acceptedFile.length ? (
          <>
            <input className="input-zone" {...getInputProps()} />
            <div className={classes.Center}>
              <p className={classes.Title}>
                {t('SimeraInternal.ImportCandidates')}
              </p>
              {isDragActive ? (
                <p className={classes.Text}>{t('VideosInternal.Release')}</p>
              ) : (
                <p className={classes.Text}>{t('VideosInternal.Drag')}</p>
              )}
              <button
                type="button"
                onClick={() => {}}
                className={classes.Button}
              >
                {t('VideosInternal.browse')}
              </button>
            </div>
            <div className={classes.Center}>
              <p className={classes.TextTypes}>
                {t('AdditionalDocs.AllowedImportFormats')}
              </p>
            </div>
          </>
        ) : (
          <div className={classes.Center}>
            <div>
              {acceptedFile.map((e) => (
                <p key={`${e.name}`}>{e.name}</p>
              ))}
            </div>
            <button
              type="button"
              onClick={() => setAcceptedFile([])}
              className={classes.Button}
            >
              {t('SimeraInternal.DeleteFiles')}
            </button>
          </div>
        )}
      </div>
      <div className={classes.uploadContainer}>
        <button
          type="button"
          onClick={() => {
            postTasks({ files: acceptedFile });
            setAcceptedFile([]);
          }}
          className={classes.Button}
          disabled={!acceptedFile.length}
        >
          {t('SimeraInternal.ImportFiles')}
        </button>
      </div>
      <div className={classes.divider} />
      <h2 className={classes.taskTitle}>
        {t('MigrationTasks.MigrationTasks')}
      </h2>
      {isFetching && isLoading ? (
        <div className={classes.spinner}>
          <Spinner
            secondary
            height="60px"
            width="60px"
            centerHeight="60px"
            centerWidth="60px"
          />
        </div>
      ) : (
        <>
          {data?.data.map((e) => (
            <MigrationTasks task={e} key={e.Id} />
          ))}

          <TableFooter
            pageSize={4}
            pageCount={data?.meta.pageCount}
            hasPreviousPage={data?.meta.hasPreviousPage}
            hasNextPage={data?.meta.hasNextPage}
            pageState={data?.meta.page}
            nextPage={nextPage}
            previousPage={previousPage}
            migration
          />
        </>
      )}
    </div>
  );
};

export default ImportCandidatesModal;
