/**
 * Gets the stored UUID on localstorage
 * @returns UUID if available or null
 */
export const getTraceId = (): string | null => {
  return localStorage.getItem('traceId');
};

/**
 * Sets in local storage the UUID
 * @param traceId UUID that you want to set
 */
export const setTraceId = (traceId: string): void => {
  const uuid = getTraceId() ?? traceId;
  if (uuid) localStorage.setItem('traceId', uuid);
};
