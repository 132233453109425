import { FC } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import DragDropContainer from './DragDropContainer';
import classes from './DragDrop.module.css';

interface DragDropProps {
  isMobile: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  isDragActive: boolean;
  supportedFilesText: string;
  disabled?: boolean;
  secondary?: boolean;
}

const DragDrop: FC<DragDropProps> = ({
  isMobile,
  getRootProps,
  getInputProps,
  isDragActive,
  supportedFilesText,
  disabled,
  secondary,
}) => {
  return (
    <div className={classes.CenterAdjust}>
      <section {...getRootProps()} id="drag-drop-container2">
        <DragDropContainer
          data-testid="text-container"
          isMobile={isMobile}
          active={isDragActive}
          supportedFilesText={supportedFilesText}
          disabled={disabled}
          secondary={secondary}
        />
      </section>
      <input
        className={classes.Input}
        data-testid="drop-section"
        {...getInputProps()}
        id="drag-drop-container"
        disabled={disabled}
      />
    </div>
  );
};

DragDrop.defaultProps = {
  disabled: false,
  secondary: false,
};

export default DragDrop;
