import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '$constants/alerts';
import { errorHandler, successHandler } from '$store/utils';
import { ValidLanguages, userLanguage } from '$translations/index';
import {
  ApplyInterviewSlotInput,
  ApplyInterviewSlotOutput,
  InterviewBuilder,
} from '../interview.types';

export const applyInterviewSlot = (builder: InterviewBuilder) =>
  builder.mutation<ApplyInterviewSlotOutput, ApplyInterviewSlotInput>({
    query: ({ scheduleId, candidateId }) => ({
      url: `/interview/slots/${scheduleId}`,
      method: 'PATCH',
      body: candidateId && { candidateId },
    }),
    transformResponse: (response: ApplyInterviewSlotOutput) => {
      successHandler(
        userLanguage === ValidLanguages.es
          ? SuccessMessageEs.CandidateInterviewConfirmTimeSuccess
          : SuccessMessageEn.CandidateInterviewConfirmTimeSuccess,
        true
      );
      return response;
    },
    transformErrorResponse: (err) => {
      errorHandler(
        '[CandidateApplyInterviewSlot]',
        userLanguage === ValidLanguages.es
          ? ErrorMessageEs.CandidateInterViewJobError
          : ErrorMessageEn.CandidateInterViewJobError,
        err,
        true
      );
      return err;
    },
    invalidatesTags: [{ type: 'Slots' }],
  });
