import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import SwitchTypes from '../../constants/CandidatesSwitchTypes';
import classes from './CandidatesSwitch.module.css';
import StatusCandidatePosition from '../../types/serverTypes/StatusCandidatePosition';

interface CandidatesSwitchProps {
  value: SwitchTypes;
  setSwitchingId: Dispatch<SetStateAction<number | null>>;
  candidatesCount: {
    new: number;
    declined: number;
    requestInterview: number;
    hired: number;
    offerSent: number;
    shortlisted: number;
  };
}

const CandidatesSwitch: FC<CandidatesSwitchProps> = ({
  value,
  setSwitchingId,
  candidatesCount,
}) => {
  const { t } = useTranslation('global');
  const [searchParams, setSeachParams] = useSearchParams();

  const changeHandler = (positionStatus: SwitchTypes) => {
    const paramsCopy = new URLSearchParams(searchParams);
    paramsCopy.set('cps', positionStatus);
    setSeachParams(paramsCopy);
    setSwitchingId(null);
  };

  return (
    <div className={classes.SwitchContainer}>
      <p
        className={`${classes.SwitchContainerItem} ${
          value === SwitchTypes.ACTIVE && classes.active
        }`}
        onClick={() => changeHandler(SwitchTypes.ACTIVE)}
        aria-hidden
      >
        {t('CandidatesSwitch.Active')}
        {candidatesCount[StatusCandidatePosition.new] !== 0 && (
          <span className={classes.candidateCounts}>
            {candidatesCount[StatusCandidatePosition.new]}
          </span>
        )}
      </p>

      <p
        className={`${classes.SwitchContainerItem} ${
          value === SwitchTypes.SHORTLISTED && classes.active
        }`}
        onClick={() => changeHandler(SwitchTypes.SHORTLISTED)}
        aria-hidden
      >
        {t('CandidatesSwitch.Shortlist')}
        {candidatesCount[StatusCandidatePosition.shortlisted] !== 0 && (
          <span className={classes.candidateCounts}>
            {candidatesCount[StatusCandidatePosition.shortlisted]}
          </span>
        )}
      </p>

      <p
        className={`${classes.SwitchContainerItem} ${
          value === SwitchTypes.INACTIVE && classes.active
        }`}
        onClick={() => changeHandler(SwitchTypes.INACTIVE)}
        aria-hidden
      >
        {t('CandidatesSwitch.Inactive')}
        {candidatesCount[StatusCandidatePosition.declined] !== 0 && (
          <span className={classes.candidateCounts}>
            {candidatesCount[StatusCandidatePosition.declined]}
          </span>
        )}
      </p>
      <p
        className={`${classes.SwitchContainerItem} ${
          value === SwitchTypes.REQUEST_INTERVIEW && classes.active
        }`}
        onClick={() => changeHandler(SwitchTypes.REQUEST_INTERVIEW)}
        aria-hidden
      >
        {t('CandidatesSwitch.interviewRequested')}
        {candidatesCount[StatusCandidatePosition.requestInterview] !== 0 && (
          <span className={classes.candidateCounts}>
            {candidatesCount[StatusCandidatePosition.requestInterview]}
          </span>
        )}
      </p>
      <p
        className={`${classes.SwitchContainerItem} ${
          value === SwitchTypes.HIRED && classes.active
        }`}
        onClick={() => changeHandler(SwitchTypes.HIRED)}
        aria-hidden
      >
        {t('CandidatesSwitch.Hired')}
        {candidatesCount[StatusCandidatePosition.hired] !== 0 && (
          <span className={classes.candidateCounts}>
            {candidatesCount[StatusCandidatePosition.hired]}
          </span>
        )}
      </p>
      <p
        className={`${classes.SwitchContainerItem} ${
          value === SwitchTypes.OFFER_SENT && classes.active
        }`}
        onClick={() => changeHandler(SwitchTypes.OFFER_SENT)}
        aria-hidden
      >
        {t('CandidatesSwitch.Offersent')}
        {candidatesCount[StatusCandidatePosition.offerSent] !== 0 && (
          <span className={classes.candidateCounts}>
            {candidatesCount[StatusCandidatePosition.offerSent]}
          </span>
        )}
      </p>
      <p
        className={`${classes.SwitchContainerItem} ${
          value === SwitchTypes.FIND && classes.active
        }`}
        onClick={() => changeHandler(SwitchTypes.FIND)}
        aria-hidden
      >
        {t('CandidatesSwitch.Find')}
      </p>
    </div>
  );
};

export default CandidatesSwitch;
