import computeYearsOfExperience from '../../../../utils/computeYearsOfExperience';
import {
  CandidateBuilder,
  GetCandidatesByPositionIdInput,
  GetCandidatesByPositionIdOuput,
} from '../candidates.types';

export const getCandidatesByPositionId = (builder: CandidateBuilder) =>
  builder.query<GetCandidatesByPositionIdOuput, GetCandidatesByPositionIdInput>(
    {
      query: (arg) => {
        return {
          url: `positions/${arg.positionId}/candidates?take=50`,
          method: 'GET',
        };
      },
      transformResponse: (response: GetCandidatesByPositionIdOuput) => {
        if (response && response.data) {
          response.data = response?.data?.map((singleCandidate) => {
            singleCandidate.YearsOfExperience = computeYearsOfExperience(
              singleCandidate.CandidateWorkExperience
            );
            return singleCandidate;
          });
        }
        return response;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ Id }) => ({
                type: 'Candidate' as const,
                Id,
              })),
              { type: 'Candidates' },
            ]
          : [{ type: 'Candidates' }],
    }
  );
