import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import classes from './DuplicateCustomRoster.module.css';
import CustomRosterTable from './CustomRosterTable';
import { useLoadRoasterQuery } from '../../store/modules/companies';
import CardSkeleton from '../Positions/CandidateSummaryCardList/CardSkeleton/CardSkeleton';

interface DuplicateCustomRosterProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  positionId: number;
}

const DuplicateCustomRoster: FC<DuplicateCustomRosterProps> = ({
  isOpen,
  setIsOpen,
  positionId,
}) => {
  const [t] = useTranslation('global');
  Modal.setAppElement(document.getElementById('root') as HTMLElement);

  const handleClose = () => {
    setIsOpen(false);
  };
  const { data, isLoading, isSuccess } = useLoadRoasterQuery({
    positionId,
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
    >
      <header data-testid="custom-roster-modal">
        <h3 className={classes.title}>
          {t('DuplicateJob.CandidatesInRoster')}
        </h3>
      </header>
      {isSuccess && <CustomRosterTable setIsOpen={setIsOpen} data={data!} />}
      {isLoading && <CardSkeleton hasProfilePicture={false} rows={3} />}
    </Modal>
  );
};

export default DuplicateCustomRoster;
