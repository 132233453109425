import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './PositionPage.module.css';
import PositionCard from './PositionCard';
import { ReactComponent as NoPosition } from '../../icons/NoPositionIcon.svg';
import AddNewPositionButton from '../EmployerDashboard/AddNewPositionButton';
import Spinner from '../Spinner/Spinner';
import { useLoadPositionsQuery } from '../../store/modules/companies';

export type PositionPageProps = {
  toggleIsModalPositionOpen: () => void;
};

const PositionPage: FC<PositionPageProps> = ({ toggleIsModalPositionOpen }) => {
  const [t] = useTranslation('global');
  const { data: position = [], isLoading } = useLoadPositionsQuery({});

  return isLoading ? (
    <div style={{ display: 'flex' }} data-testid="spinner">
      <Spinner />
    </div>
  ) : (
    <div className={classes.wrapper} data-testid="position-page">
      <h1>{t('EmployerDashboard.Positions')} </h1>
      {position.filter((pos) => pos.Status !== 'Closed').length > 0 ? (
        <div className={classes.content} data-testid="position-card">
          {position
            .filter((pos) => pos.Status !== 'Closed')
            .map((posit) => {
              return (
                <PositionCard key={`position-${posit.Id}`} position={posit} />
              );
            })}
        </div>
      ) : (
        <div className={classes.NoPositions} data-testid="position-card">
          <NoPosition />
          <h4 className={classes.NoPositionsTitle}>
            You have not added any position.
          </h4>
          <p className={classes.NoPositionsSubtitle}>
            Add as many position you need by clicking in the ”add new position”
            button.
          </p>
          <div
            style={{
              marginTop: '1.5rem',
            }}
          >
            <AddNewPositionButton onClick={toggleIsModalPositionOpen} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PositionPage;
