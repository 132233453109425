import { toast } from 'react-toastify';

export const successHandler = (
  message: string,
  showToast: boolean,
  loadingBar?: boolean
) => {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable-next-line no-console */
    console.debug('Success', message);
  }
  if (showToast) {
    toast(message, { type: 'success', hideProgressBar: loadingBar });
  }
};
