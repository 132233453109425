import { errorHandler, successHandler } from '../../../utils';
import { UploadBuilder, UploadCVInput, UploadCVOutput } from '../uploads.types';
import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';

export const uploadCV = (builder: UploadBuilder) =>
  builder.mutation<UploadCVOutput, UploadCVInput>({
    query: (args) => ({
      url: '/upload/cv',
      method: 'POST',
      body: args.data,
    }),
    invalidatesTags: () => [{ type: 'Upload', id: 'List' }],
    transformResponse: (res: UploadCVOutput, meta, args) => {
      successHandler(
        (args.language ?? 'en') === 'en'
          ? SuccessMessageEn.UploadCV
          : SuccessMessageEs.UploadCV,
        true,
        true
      );
      return res;
    },
    transformErrorResponse: (err, meta, args) => {
      errorHandler(
        '[uploadCVAction]',
        args.language === 'es'
          ? ErrorMessageEs.UploadCV
          : ErrorMessageEn.UploadCV,
        err,
        true
      );
      return err.data;
    },
  });
