/* eslint-disable no-param-reassign */
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import dayjs from 'dayjs';
import { SaveState } from '../types/ForrmType';
import { TrackingCandidatesData } from '../constants/TrackingCandidates';
import { EmployeesData } from '../types/candidates';
import { useTrackingHiredCandidatesMutation } from '../store/modules/companies';
import { EmployeeColumn } from '../constants/CompanyColumn';

const useTrackingForm = (
  employee: EmployeesData | undefined,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  cps: EmployeeColumn
) => {
  const [showContent, setShowContent] = useState(false);
  const [buttonState, setButtonState] = useState(SaveState.IDLE);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [trackingHiredCandidates] = useTrackingHiredCandidatesMutation();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<TrackingCandidatesData>({
    defaultValues: {
      Salary: Number(employee?.Salary) || null,
      SimeraFee: employee?.SimeraFee || null,
      EndReason: employee?.EndReason || null,
      AdditionalNotesEndDate: employee?.AdditionalNotesEndDate || null,
      EndDate: dayjs(employee?.EndDate).format('YYYY-MM-DD') || null,
      StartDate: dayjs(employee?.StartDate).format('YYYY-MM-DD') || null,
      Benefits: employee?.Benefits || null,
    },
    shouldUnregister: true,
  });
  const saveInfo = async (data: TrackingCandidatesData) => {
    setIsButtonDisabled(true);
    setButtonState(SaveState.PENDING);
    Object.entries(data).forEach(([key, value]) => {
      if (
        value === null ||
        value === undefined ||
        value === '' ||
        value === 'Invalid Date'
      ) {
        delete data[key as keyof TrackingCandidatesData];
      }
    });

    setButtonState(SaveState.IDLE);
    setIsButtonDisabled(false);
    setIsOpen(false);

    trackingHiredCandidates({
      candidatePositionStatusId: cps.candidatePositionStatusId,
      trackingCandidateData: data,
    });
  };

  return {
    register,
    watch,
    saveInfo,
    handleSubmit,
    errors,
    isDirty,
    showContent,
    setShowContent,
    buttonState,
    isButtonDisabled,
  };
};

export default useTrackingForm;
