import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { AdditionalVideo } from '../../../../types/additional-video/additional-video';
import { errorHandler, successHandler } from '../../../utils';
import {
  AdditionalVideoBuilder,
  UpdateVisibilityAdditionalVideosInput,
  UpdateVisibilityAdditionalVideosOutput,
} from '../additional-videos.types';

export const updateVisibilityAdditionalVideos = (
  builder: AdditionalVideoBuilder
) =>
  builder.mutation<
    UpdateVisibilityAdditionalVideosOutput,
    UpdateVisibilityAdditionalVideosInput
  >({
    query: (args) => ({
      url: 'additional-video/visibility',
      method: 'PATCH',
      body: args.data,
      responseHandler: (response) => response.text(),
    }),
    invalidatesTags: [{ type: 'additionalVideos', id: 'LIST' }],
    transformResponse: (response: AdditionalVideo[], meta, { lang }) => {
      successHandler(
        lang === 'es'
          ? SuccessMessageEs.UpdateAdditionalVideos
          : SuccessMessageEn.UpdateAdditionalVideos,
        true
      );
      return response;
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        'updateVisibilityAdditionalVideos',
        lang === 'es'
          ? ErrorMessageEs.UpdateAdditionalVideosError
          : ErrorMessageEn.UpdateAdditionalVideosError,
        error,
        true
      );
      return error;
    },
  });
