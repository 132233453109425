import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  DeleteWorkExperienceInput,
  WorkExperienceBuilder,
} from '../work-experience.types';

export const deleteWorkExperience = (builder: WorkExperienceBuilder) =>
  builder.mutation<void, DeleteWorkExperienceInput>({
    query: ({ id }) => ({
      url: `/candidate-experience/delete/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: [{ type: 'workExperience', id: 'LIST' }],
    transformResponse: (_data, meta, { lang }) => {
      successHandler(
        lang === 'es'
          ? SuccessMessageEs.SuccessfullyDeleted
          : SuccessMessageEn.SuccessfullyDeleted,
        true
      );
    },
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[deleteExperience]',
        lang === 'es' ? ErrorMessageEs.DeleteError : ErrorMessageEn.DeleteError,
        error,
        true
      );
    },
  });
