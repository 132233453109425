import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../Buttons/Button';
import BaseInput from '../Inputs/BaseInput';
import classes from './RegisterForm.module.css';
import { ReactComponent as GoogleIcon } from '../../icons/GoogleColorIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../icons/LinkedInBlueIcon.svg';
import { ReactComponent as SimeraBlueLogo } from '../../icons/SimeraLogoBlue.svg';
import { NameRegex, simeraEmailRegex } from '../../constants/regex';
import { ReactComponent as EyeIcon } from '../../icons/PasswordEye.svg';
import RoundedButton from '../Buttons/RoundedButton';
import {
  InternalEmailAuthAction,
  internalGoogleAuthAction,
  internalLinkedInAuthAction,
} from '../../store/legacy/auth';
import InternalRegistrationModal from './InternalRegistrationModal/InternalRegistrationModal';
import { useAppDispatch, useAppSelector } from '../../hooks';

interface FormTypes {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  companyName?: string;
}

const SimeraRegisterForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<FormTypes>({ mode: 'onChange' });
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const [t, i18next] = useTranslation('global');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [passStrength, setPassStrength] = useState(0);

  const passwordWatch = watch('password', '');

  const passColors = [
    'white',
    '#f44336',
    'purple',
    'blueviolet',
    'cyan',
    '#05668D',
  ];

  const passwordValidator = (pass: string) => {
    let strength = 0;
    if (!pass || pass.length === 0) {
      strength = 0;
      setPassStrength(0);
    }

    if (pass.length >= 6) {
      strength = 1;
    }
    if (pass.match(/^(?=.*\d)(?=.*[a-zA-Z]).{6,}/)) {
      // has at least 6 characters, and contains a number and letters
      strength = 2;
    }
    if (pass.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      // contains at least 8 letters, a number, a lowercase and uppercase letter
      strength = 3;
    }
    if (
      pass.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      )
    ) {
      // contains at least 8 letters, a number, a lowercase, uppercase and special character
      strength = 4;
    }

    setPassStrength(strength);
    return strength;
  };

  useEffect(() => {
    if (authState.isLoggedIn) {
      navigate('/home');
    }
  }, [authState, navigate]);

  useEffect(() => {
    if (!authState.loading && authState.internal?.internalRegisterSuccess) {
      setShowModal(true);
    }
  }, [authState.loading, authState.internal?.internalRegisterSuccess]);

  // Form declaration
  const firstName = register('firstName', {
    required: t('register.nameInputValid').toString(),
    maxLength: {
      value: 100,
      message: t('register.invalidFirstNameInput').toString(),
    },
    pattern: {
      value: NameRegex,
      message: t('register.invalidFirstNameInput').toString(),
    },
  });
  const lastName = register('lastName', {
    required: t('register.lastNameInputValid').toString(),
    maxLength: {
      value: 100,
      message: t('register.invalidLastNameInput').toString(),
    },
    pattern: {
      value: NameRegex,
      message: t('register.invalidLastNameInput').toString(),
    },
  });
  const email = register('email', {
    required: t('register.emailInputValid').toString(),
    maxLength: {
      value: 100,
      message: t('register.invalidEmailInput').toString(),
    },
    pattern: {
      value: simeraEmailRegex,
      message: 'Invalid email',
    },
  });
  const password = register('password', {
    validate: {
      validPass: (val) =>
        passwordValidator(val) >= 1 || 'Must contain at least 6 characters',
    },
  });
  const confirmPassword = register('confirmPassword', {
    required: t('register.confirmPasswordValid').toString(),
    validate: {
      passwordEqual: (value) =>
        value === getValues().password || t('register.matchPass').toString(),
    },
  });

  const submitForm = (values: FormTypes) => {
    const sendValues = { ...values, email: values.email.toLowerCase() };

    dispatch(InternalEmailAuthAction(sendValues, i18next.language));
  };

  const onGoogleSignIn = () => {
    dispatch(internalGoogleAuthAction(i18next.language));
  };

  const onLinkedInSignIn = () => {
    dispatch(internalLinkedInAuthAction(i18next.language));
  };

  const handleCloseModal = (show: boolean): void => setShowModal(show);

  return (
    <>
      <InternalRegistrationModal
        modalIsOpen={showModal}
        handleClose={handleCloseModal}
        internalEmail={authState.internal.internalEmail!}
        resendConfirmationEmail={authState.resendConfirmationEmail}
      />
      <form
        id="register-form"
        data-testid="register-form"
        className={classes.Form}
        onSubmit={handleSubmit(submitForm)}
      >
        <div className={classes.AlignCenter}>
          <SimeraBlueLogo />
        </div>
        <div className={classes.InputsContainer}>
          <div className={classes.NameInputs}>
            <BaseInput
              onChange={(e: ChangeEvent) => firstName.onChange(e)}
              name={firstName.name}
              onBlur={firstName.onBlur}
              refProp={firstName.ref}
              label={t('register.nameInput')}
              type="text"
              errorText={errors.firstName?.message}
              placeholder={t('common.typeHere')}
            />
            <BaseInput
              onChange={(e: ChangeEvent) => lastName.onChange(e)}
              name={lastName.name}
              onBlur={lastName.onBlur}
              refProp={lastName.ref}
              label={t('register.lastNameInput')}
              type="text"
              errorText={errors.lastName?.message}
              placeholder={t('common.typeHere')}
            />
          </div>
          <BaseInput
            onChange={(e: ChangeEvent) => email.onChange(e)}
            name={email.name}
            onBlur={email.onBlur}
            refProp={email.ref}
            label={t('register.emailInput')}
            type="text"
            errorText={errors.email?.message}
            placeholder={t('common.typeHere')}
          />
          <BaseInput
            onChange={(e: ChangeEvent) => password.onChange(e)}
            name={password.name}
            onBlur={password.onBlur}
            refProp={password.ref}
            label={t('register.passwordInput')}
            type="password"
            errorText={errors.password?.message}
            placeholder={t('common.typeHere')}
            icon={EyeIcon}
            isPassword
          />
          <div className={classes.passContainer}>
            <div className={classes.ProgressBarContainer}>
              <div
                style={{
                  width: `${(passStrength / 4) * 100}%`,
                  background: passColors[passStrength],
                }}
                className={classes.ProgressBar}
              />
            </div>
            {passwordWatch.length >= 1 && (
              <p className={classes.passStrength}>
                {t('register.passStrength')}
                {t(`register.strength${passStrength}`)}
              </p>
            )}
          </div>
          <BaseInput
            onChange={(e: ChangeEvent) => confirmPassword.onChange(e)}
            name={confirmPassword.name}
            onBlur={confirmPassword.onBlur}
            refProp={confirmPassword.ref}
            label={t('register.confirmPassword')}
            type="password"
            errorText={errors.confirmPassword?.message}
            placeholder={t('common.typeHere')}
            icon={EyeIcon}
            isPassword
          />
        </div>
        <div className={classes.CreateAccountButton}>
          <Button
            submit
            onClick={handleSubmit(submitForm)}
            text={t('register.createAccBtn')}
            bgColor="#079E88"
            showText
          />
        </div>
        <div className={classes.OrFlex}>
          <div className={classes.OrTags}>
            <hr className={classes.ORLine} />
            <p className={classes.Or}>{t('common.or')}</p>
            <hr className={classes.ORLine} />
          </div>
        </div>
        <div data-testid="login-buttons" className={classes.ButtonFlex}>
          <RoundedButton icon={GoogleIcon} onClick={onGoogleSignIn} />
          <RoundedButton icon={LinkedInIcon} onClick={onLinkedInSignIn} />
        </div>
        <p className={classes.AccountSimera}>
          {t('register.alreadyHave')}
          <Link to="/internal_login" className={classes.SignUp}>
            &nbsp;{t('register.login')}
          </Link>
        </p>
      </form>
    </>
  );
};

export default SimeraRegisterForm;
