import { ErrorMessageEn, ErrorMessageEs } from '$constants/alerts';
import { errorHandler } from '$store/utils';
import {
  SendVideoCommentInput,
  SendVideoCommentOutput,
  VideoFeedbackBuilder,
} from '../video-feedback.types';

export const sendVideoComment = (builder: VideoFeedbackBuilder) =>
  builder.mutation<SendVideoCommentOutput, SendVideoCommentInput>({
    query: ({ videoId, videoType, videoComment }) => ({
      url: `/feedbacks`,
      method: 'POST',
      body: { EntityName: videoType, EntityId: videoId, Comment: videoComment },
    }),
    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[SendVideoCommentAction]',
        lang === 'es'
          ? ErrorMessageEs.FeedbackError
          : ErrorMessageEn.FeedbackError,
        error,
        true
      );
    },
    invalidatesTags: (baseQuery, meta, { videoId }) => [
      { type: 'videoFeedback', id: videoId },
    ],
  });
