import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CandidatesList from '$components/Positions/CandidatesPositionPage/CandidatesList';
import PositionDetailsCard from '$components/Employers/Position/PositionDetailsCard/PositionDetailsCard';
import ActiveValues from '$constants/ActiveValues';
import useAxios from '$hooks/useAxios';
import computeYearsOfExperience from '$utils/computeYearsOfExperience';
import { GET_SHARE } from '$api/endpoints';
import { jobInterestsDefaultValue } from '$constants/formDefaultValues';
import Spinner from '../../Spinner/Spinner';
import classes from './SharePosition.module.css';

const SharePosition = () => {
  const { shareReferenceId } = useParams();
  const [positionData, setPositionData] = useState<any>(undefined);
  const { data, isLoading, error } = useAxios(
    GET_SHARE(shareReferenceId as string)
  );

  useEffect(() => {
    if (data) {
      const formattedData = { ...data };
      formattedData.CandidatesPositionStatus =
        data?.CandidatesPositionStatus?.map((singleCandidate: any) => {
          singleCandidate.Candidate.YearsOfExperience =
            computeYearsOfExperience(
              singleCandidate.Candidate.CandidateWorkExperience
            );

          if (!singleCandidate.Candidate.CandidateJobInterest) {
            singleCandidate.Candidate.CandidateJobInterest = {
              SalaryLowerBound: jobInterestsDefaultValue.Salary[0],
              SalaryUpperBound: jobInterestsDefaultValue.Salary[1],
            };
          }
          return singleCandidate.Candidate;
        });

      setPositionData(formattedData);
    }
  }, [data, setPositionData]);

  return (
    <div
      className={`max-w-screen-2xl ${classes.wrapper} mx-auto`}
      data-testid="candidatePositionPage"
    >
      <div className={`gap-4 ${classes.container}`}>
        {isLoading && (
          <div className={classes.spinner}>
            <Spinner height="100%" width="100%" />
          </div>
        )}
        {error && <div className="m-auto">Invalid Request</div>}

        {!isLoading && positionData && (
          <>
            <PositionDetailsCard
              position={positionData}
              candidatesCount={data?.CandidatesPositionStatus?.length ?? 0}
              isGuest
            />
            <CandidatesList
              activeValue={ActiveValues.New}
              candidates={positionData?.CandidatesPositionStatus}
              setSwitchingId={() => {}}
              existingOffer={false}
              isGuest
              position={positionData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SharePosition;
