import { getTraceId } from '../store/utils/traceId';

type ValidProperties = 'internalId' | 'role' | 'employerId';

type DefaultProps = {
  employer_id?: number;
  internal_id?: number;
  trace_id: string | null;
  type?: string;
  role?: string;
};
/**
 * Triggers a track event on segment
 * @param event Event name
 * @param properties JSON data that will be sent to segment
 * @param addProperties An array of properties that will automatically add the property and value to the payload
 */
export const segmentTrack = (
  event: string,
  properties?: any,
  addProperties: ValidProperties[] = []
) => {
  const ServerUserFromLocalStorage = JSON.parse(
    localStorage.getItem('ServerUserResponse') || '{}'
  );
  const defaultProps: DefaultProps = {
    trace_id: getTraceId(),
  };

  addProperties.forEach((propName) => {
    if (propName === 'internalId') {
      defaultProps.internal_id = ServerUserFromLocalStorage?.Internal?.Id;
    }

    if (propName === 'role') {
      if (ServerUserFromLocalStorage.Candidate) {
        defaultProps.role = 'candidate';
        defaultProps.type = 'candidate';
      } else if (ServerUserFromLocalStorage.Employer) {
        defaultProps.type = 'employer';
        defaultProps.role = 'employer';
      } else if (ServerUserFromLocalStorage.Internal) {
        defaultProps.type = 'internal';
        defaultProps.role = 'internal';
      }
    }

    if (propName === 'employerId') {
      defaultProps.employer_id = ServerUserFromLocalStorage.Employer?.Id;
    }
  });
  // Observation: enable property override default props
  properties = { ...defaultProps, ...properties };
  window.analytics.track(event, properties);
};
