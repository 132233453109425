import { CandidateData } from '../../../../types/candidates';

const filterByEducation = (
  singleCandidate: CandidateData,
  searchedEducation: string
) => {
  return (
    singleCandidate?.CandidateEducation?.some(
      (candidateEducation) =>
        candidateEducation?.DegreeType &&
        candidateEducation?.DegreeType?.toLowerCase() ===
          searchedEducation.toLowerCase()
    ) ?? false
  );
};

export default filterByEducation;
