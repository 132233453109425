import { SaveState } from '../../../types/ForrmType';
import {
  PasswordResetAction,
  PasswordResetActionTypes,
  PasswordResetState,
} from './password-reset.types';

const initialState: PasswordResetState = {
  emailSentStatus: SaveState.IDLE,
  validateTokenStatus: SaveState.IDLE,
  passwordChangeStatus: SaveState.IDLE,
  token: null,
};

export const passwordResetReducer = (
  state = initialState,
  action: PasswordResetAction = { type: PasswordResetActionTypes.DEFAULT_TYPE }
): PasswordResetState => {
  switch (action.type) {
    case PasswordResetActionTypes.PASSWORD_RECOVERY_REQUEST_START:
      return {
        ...state,
        emailSentStatus: SaveState.PENDING,
      };
    case PasswordResetActionTypes.PASSWORD_RECOVERY_REQUEST_SUCCESS: {
      return {
        ...state,
        emailSentStatus: SaveState.FULFILLED,
      };
    }
    case PasswordResetActionTypes.PASSWORD_RECOVERY_REQUEST_FAIL: {
      return {
        ...state,
        emailSentStatus: SaveState.FAILED,
        error: action.error,
      };
    }
    case PasswordResetActionTypes.VALIDATE_TOKEN_START:
      return {
        ...state,
        validateTokenStatus: SaveState.PENDING,
      };
    case PasswordResetActionTypes.VALIDATE_TOKEN_SUCCESS: {
      return {
        ...state,
        token: action.token,
        validateTokenStatus: SaveState.FULFILLED,
      };
    }
    case PasswordResetActionTypes.VALIDATE_TOKEN_FAIL: {
      return {
        ...state,
        validateTokenStatus: SaveState.FAILED,
        error: action.error,
      };
    }
    case PasswordResetActionTypes.RESET_PASSWORD_AUTH_START:
      return {
        ...state,
        passwordChangeStatus: SaveState.PENDING,
      };
    case PasswordResetActionTypes.RESET_PASSWORD_AUTH_SUCCESS: {
      return {
        ...state,
        passwordChangeStatus: SaveState.FULFILLED,
      };
    }
    case PasswordResetActionTypes.RESET_PASSWORD_AUTH_FAIL: {
      return {
        ...state,
        passwordChangeStatus: SaveState.FAILED,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
