import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import EditableContainer from '../EditableContainer/EditableContainer';
import classes from './ExtracurricularEditableContainer.module.css';
import useWindowWidth from '../../hooks/useWindowWidth';
import ExtracurricularReadOnly from './ExtracurricularReadOnly';
import ExtracurricularFormContainer from '../ExtracurricularForm/ExtracurricularFormContainer';
import NewExtracurricular from '../ExtracurricularForm/NewExtracurricular';
import { useGetCandidateExtracurricularQuery } from '../../store/modules/candidate-extracurricular';

interface ExtracurricularEditableContainerProps {
  isActive: boolean;
}

const ExtracurricularEditableContainer: FC<
  ExtracurricularEditableContainerProps
> = ({ isActive }) => {
  const [t, i18n] = useTranslation('global');
  const candidateId: number = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;
  const { data, isLoading } = useGetCandidateExtracurricularQuery({
    candidateId,
    lang: i18n.language as 'en' | 'es',
  });

  const labels = [
    {
      id: 0,
      label: t('extracurricular.vol'),
    },
    {
      id: 1,
      label: t('extracurricular.charity'),
    },
    {
      id: 2,
      label: t('extracurricular.other'),
    },
  ];

  const [isEdit, setIsEdit] = useState(true);
  const [show, setShow] = useState(false);
  const [newExtra, setNewExtra] = useState(false);

  const isMobile = useWindowWidth() <= 650;

  const onClickPencil = () => {
    if (isMobile && window) window.scrollTo(0, 0);
    setIsEdit(false);
  };

  const onClickCancel = () => {
    setShow(true);
  };
  const onClickCancelModal = (isCancel: boolean, continueEditing: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    if (newExtra && continueEditing) {
      setNewExtra(false);
    }
    setShow(false);
  };

  const onClickSave = () => {
    setNewExtra(false);
  };

  const onClickPlus = () => {
    setNewExtra(!newExtra);
  };

  const newExtraOrField = () => {
    if (newExtra) {
      return (
        <NewExtracurricular
          onClickCancel={onClickCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={onClickCancelModal}
          show={show}
          onClickSave={onClickSave}
          newExtra
          countForms={data?.length || 1}
        />
      );
    }
    return (
      !isLoading &&
      data?.map((extra, idx) => (
        <ExtracurricularReadOnly
          key={` extraEditable-${extra?.Id}`}
          extracurricular={extra}
          idx={idx}
          labels={labels}
          extracurricularsLength={data?.length ?? -1}
        />
      ))
    );
  };

  return (
    <EditableContainer
      title={t('extracurricular.title')}
      isEditable={isEdit}
      onClickPencil={onClickPencil}
      onClickPlus={onClickPlus}
      data-testid="extracurricular-editableForm"
      hasAddMore
      addMore={newExtra ? t('extracurricular.nonacademic') : false}
      isActive={isActive}
    >
      {!isEdit ? (
        <div className={isMobile ? '' : classes.ExtraEditModeContainer}>
          <ExtracurricularFormContainer
            onClickCancel={onClickCancel}
            isEdit
            setEdit={setIsEdit}
            onClickCancelModal={onClickCancelModal}
            show={show}
          />
        </div>
      ) : (
        newExtraOrField()
      )}
    </EditableContainer>
  );
};

export default ExtracurricularEditableContainer;
