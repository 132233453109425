import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import classes from './FilterRadio.module.css';
import { ReactComponent as Circle } from '../../../../../icons/Circle.svg';
import { toPascalCase } from '../../../../../utils/toPascalCase';

interface Props {
  options: { id: string | number; text: string }[];
  title: string;
}

export const FilterRadio: FC<Props> = ({ options, title }) => {
  const [t] = useTranslation('global');
  const [selectedOption, setSelectedOption] = useState<string | number | null>(
    null
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRadioChange = (optionId: string | number | null) => {
    const newParams = new URLSearchParams(searchParams);
    setSelectedOption(optionId);
    if (!optionId) {
      newParams.delete(toPascalCase(title));
    } else {
      if (optionId.toString().match(/^\[\d+(,\s*\d+)*\]$/)) {
        const rangeArray = JSON.parse(optionId.toString());
        newParams.set(toPascalCase(title), rangeArray.join(','));
      }
      newParams.set(toPascalCase(title), optionId.toString());
    }
    setSearchParams(newParams);
  };

  useEffect(() => {
    const option = searchParams.get(toPascalCase(title));
    if (option) {
      setSelectedOption(option);
    }
  }, [searchParams, title]);

  return (
    <div className={classes.FilterContainer}>
      <p className={classes.Title}>{title}</p>
      <label htmlFor="radio-none" className={classes.Radio}>
        <input
          type="radio"
          name={title}
          id="radio-none"
          value="none"
          checked={selectedOption === null}
          onChange={() => handleRadioChange(null)}
        />
        <span className={classes.Checkmark}>
          {selectedOption === null && <Circle fill="#079E88" height="10px" />}
        </span>
        {t('forms.none')}
      </label>
      {options.map((option, index) => (
        <label
          key={option.id}
          htmlFor={`radio-${option.id}`}
          className={classes.Radio}
        >
          <input
            type="radio"
            data-testid={`radio${index}`}
            value={option.id}
            checked={selectedOption === option.id}
            onChange={() => {
              handleRadioChange(option.id);
            }}
          />
          <span className={classes.Checkmark}>
            {selectedOption === option.id && (
              <Circle fill="#079E88" height="10px" />
            )}
          </span>
          {option.text}
        </label>
      ))}
    </div>
  );
};
