import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../../utils/baseQuery';
import { ReducerPaths } from '../../constants/reducerPaths';
import { getCandidateExtracurricular } from './queries';
import {
  addCandidateExtracurriculars,
  addCandidateExtracurricularsInternal,
  deleteCandidateExtracurricular,
} from './mutations';

export const candidateExtracurricularApi = createApi({
  reducerPath: ReducerPaths.CANDIDATE_EXTRACURRICULAR,
  baseQuery,
  endpoints: (builder) => ({
    getCandidateExtracurricular: getCandidateExtracurricular(builder),
    deleteCandidateExtracurricular: deleteCandidateExtracurricular(builder),
    addCandidateExtracurricularsInternal:
      addCandidateExtracurricularsInternal(builder),
    addCandidateExtracurriculars: addCandidateExtracurriculars(builder),
  }),
});

export const {
  useGetCandidateExtracurricularQuery,
  useDeleteCandidateExtracurricularMutation,
  useAddCandidateExtracurricularsInternalMutation,
  useAddCandidateExtracurricularsMutation,
} = candidateExtracurricularApi;
