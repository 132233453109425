import { FC } from 'react';
import Modal from 'react-modal';

import classes from './InternalRegistrationModal.module.css';
import InternalRegistrationModalContent from './InternalRegistrationModalContent/InternalRegistrationModalContent';

interface Props {
  modalIsOpen: boolean;
  handleClose: (show: boolean) => void;
  internalEmail: string;
  resendConfirmationEmail: boolean | null;
}

const InternalRegistrationModal: FC<Props> = ({
  modalIsOpen,
  handleClose,
  internalEmail,
  resendConfirmationEmail,
}) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => handleClose(false)}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      contentLabel="Internal registration success"
      ariaHideApp={false}
    >
      <InternalRegistrationModalContent
        handleClose={handleClose}
        internalEmail={internalEmail}
        resendConfirmationEmail={resendConfirmationEmail}
      />
    </Modal>
  );
};

export default InternalRegistrationModal;
