import { CandidateData } from '../../../../types/candidates';

const filterByName = (
  singleCandidate: CandidateData,
  listOfNames: string[]
) => {
  return listOfNames.every((searchedName: string) =>
    `${singleCandidate?.User?.FirstName ?? ''} ${
      singleCandidate?.User?.LastName ?? ''
    }`
      .toLowerCase()
      .includes(searchedName)
  );
};

export default filterByName;
