import { ReactElement, useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import DragAndDropSortableItem from './DragAndDropSortableItem';

type DragAndDropSortableDataType = {
  component: ReactElement;
  id: number;
};

type DragAndDropSortableContainerProps = {
  data: DragAndDropSortableDataType[];
  onChange: (ids: number[]) => void;
};

const DragAndDropSortableContainer = ({
  data,
  onChange,
}: DragAndDropSortableContainerProps) => {
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState(data);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 100, tolerance: 100 },
    })
  );

  const handleDragStart = (event: any) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((allItems) => {
        const oldIndex = allItems.findIndex(
          (item: DragAndDropSortableDataType) => active.id === item.id
        );
        const newIndex = allItems.findIndex(
          (item: DragAndDropSortableDataType) => over.id === item.id
        );

        const newSortResult = arrayMove(allItems, oldIndex, newIndex);
        onChange(newSortResult.map((item) => item.id));
        return newSortResult;
      });
    }

    setActiveId(null);
  };

  useEffect(() => {
    setItems(data);
  }, [data]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items.map((item) => item.id)}>
        {items.map(({ component, id }) => {
          return (
            <DragAndDropSortableItem key={id} childrenId={id}>
              <div className={activeId === id ? 'opacity-50' : ''}>
                {component}
              </div>
            </DragAndDropSortableItem>
          );
        })}
      </SortableContext>
      <DragOverlay>
        {activeId ? data.find((item) => activeId === item.id)?.component : null}
      </DragOverlay>
    </DndContext>
  );
};
export default DragAndDropSortableContainer;
