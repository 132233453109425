import { FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';

export const warningHandlerInvalidFiles = (
  showToast: boolean,
  errors: FileRejection[],
  typesAccepted: string = 'pdf, doc, docx',
  fileSize: string = '50'
) => {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable-next-line no-console */
    console.debug('File Error', errors);
  }
  if (showToast) {
    errors.forEach((error: FileRejection) => {
      error.errors.forEach(({ code }) => {
        if (code === 'file-invalid-type') {
          toast(`File invalid type, accepted types are: ${typesAccepted}`, {
            type: 'warning',
          });
        } else if (code === 'file-too-large') {
          toast(`File is too large, max size is ${fileSize}}MB`, {
            type: 'warning',
          });
        }
      });
    });
  }
};
