import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormTypes } from '../../types/user_info';
import classes from './LanguageEditableContainer.module.css';

interface LanguageReadOnlyProps {
  language: FormTypes['languages'];
  isLastOne: boolean;
}
const LanguageReadOnly: FC<LanguageReadOnlyProps> = ({
  language,
  isLastOne,
}) => {
  const [t] = useTranslation('global');

  const changeLanguage = () => {
    if (language) {
      switch (language?.LanguageProficiency.val) {
        case 'Basic':
          return t('languages.basic');
        case 'Intermediate':
          return t('languages.intermediate');
        case 'Advanced':
          return t('languages.advanced');
        case 'Native':
          return t('languages.native');
        default:
          break;
      }
    }
    return null;
  };
  return (
    <section
      data-testid="language-readonly"
      className={classes.languageEditContainer}
    >
      <p
        data-testid="language-readonly-title"
        className={classes.languageEditContainerTitle}
      >
        {language?.LanguageName.val}
      </p>
      <p
        data-testid="language-readonly-subtitle"
        className={classes.languageEditContainerSubtitle}
      >
        {changeLanguage()}
      </p>
      {!isLastOne && <div className={classes.languageEditContainerDivider} />}
    </section>
  );
};

export default LanguageReadOnly;
