import { FC } from 'react';
import Modal from 'react-modal';
import MakeOfferToCandidateDto from '../../../../../types/serverTypes/makeOfferToCandidateDto';
import classes from './UpdateToOfferSentConfirmModal.module.css';
import UpdateToOfferSentConfirmModalContent from './UpdateToOfferSentConfirmModalContent/UpdateToOfferSentConfirmModalContent';

interface Props {
  modalIsOpen: boolean;
  handleClose: (show: boolean) => void;
  makeOfferToCandidateDto: MakeOfferToCandidateDto;
}

const UpdateToOfferSentConfirmModal: FC<Props> = ({
  modalIsOpen,
  handleClose,
  makeOfferToCandidateDto,
}) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => handleClose(false)}
      className={classes.Modal}
      overlayClassName={classes.Overlay}
      contentLabel="Send Offer Request"
      ariaHideApp={false}
    >
      <UpdateToOfferSentConfirmModalContent
        handleClose={handleClose}
        makeOfferToCandidateDto={makeOfferToCandidateDto}
      />
    </Modal>
  );
};

export default UpdateToOfferSentConfirmModal;
