import { ErrorMessageEn, ErrorMessageEs } from '../../../../constants/alerts';
import { errorHandler } from '../../../utils';
import {
  AdditionalVideoBuilder,
  GetAdditionalVideoByCandidateIdInput,
  GetAdditionalVideoByCandidateIdOutput,
} from '../additional-videos.types';

export const getAdditionalVideosByCandidateId = (
  builder: AdditionalVideoBuilder
) =>
  builder.query<
    GetAdditionalVideoByCandidateIdOutput,
    GetAdditionalVideoByCandidateIdInput
  >({
    query: ({ candidateId }) => ({
      url: `additional-video/candidate/${candidateId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ Id }) => ({
              type: 'additionalVideos' as const,
              id: Id,
            })),
            { type: 'additionalVideos', id: 'LIST' },
          ]
        : [{ type: 'additionalVideos', id: 'LIST' }],

    transformErrorResponse: (error, meta, { lang }) => {
      errorHandler(
        '[LOAD_ADDITIONAL_VIDEO_BY_CANDIDATE_ID]',
        lang === 'es' ? ErrorMessageEs.InfoFail : ErrorMessageEn.InfoFail,
        error,
        true
      );
    },
  });
