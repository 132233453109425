import 'rc-slider/assets/index.css';
import { FC, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks';
import { useCandidateId } from '../../hooks/useCandidateId';
import {
  useAddCandidateJobInterestInternalMutation,
  useAddCandidateJobInterestMutation,
  useGetCandidateJobInterestQuery,
} from '../../store/modules/candidate-job-interest';
import { candidatesApi } from '../../store/modules/candidates';
import { useGetJobPositionsQuery } from '../../store/modules/form-info';
import { errorHandler, successHandler } from '../../store/utils';
import { SaveState } from '../../types/ForrmType';
import { AutocompleteOption, JobInterest } from '../../types/user_info';
import SaveButton from '../Buttons/SaveButton';
import CancelModal from '../CancelModal/CancelModal';
import FormFooter from '../FormFooter/FormFooter';
import JobInterestsForm from './JobInterestsForm';
import classes from './JobInterestsForm.module.css';

interface ContainerProps {
  isEdit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCancel?: () => void;
  onClickCancelModal?: (isCancel: boolean) => void;
  show?: boolean;
  secondary?: boolean;
}

const MAX_SALARY_RANGE = 1000;

const JobInterestsFormContainer: FC<ContainerProps> = ({
  isEdit,
  setEdit,
  onClickCancel,
  onClickCancelModal,
  show,
  secondary,
}) => {
  const [t, i18next] = useTranslation('global');
  const candidateId = useCandidateId();
  const dispatch = useAppDispatch();

  const { data: jobInterest } = useGetCandidateJobInterestQuery({
    candidateId,
    lang: i18next.language as 'en' | 'es',
  });

  const [addCandidateJobInterest, { isLoading, isSuccess }] =
    useAddCandidateJobInterestMutation();
  const [
    addCandidateJobInterestInternal,
    { isLoading: isLoadingInternal, isSuccess: isSuccessInternal },
  ] = useAddCandidateJobInterestInternalMutation();

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { isDirty },
    reset,
  } = useForm<JobInterest>({
    defaultValues: jobInterest,
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('JobInterest', jobInterest?.JobInterest ?? []);
    setValue('Salary', jobInterest?.Salary ?? []);
  }, [jobInterest, setValue]);

  const { data } = useGetJobPositionsQuery({ t });
  const salary = useWatch({ control, name: 'Salary' });
  const selectedTags = useWatch({
    control,
    name: 'JobInterest',
  });

  useEffect(() => {
    if (selectedTags?.length === 0) {
      setError('Salary', { message: t('jobs.ErrorShouldSelectOne') });
    } else {
      clearErrors('Salary');
    }
  }, [clearErrors, selectedTags, setError, t]);

  const addToJobInterestList = (option: AutocompleteOption) => {
    if (selectedTags && !selectedTags.find((o) => o.Id === option.Id)) {
      setValue('JobInterest', [...selectedTags, option], { shouldDirty: true });
    } else if (selectedTags) {
      const filteredList = selectedTags.filter((o) => o.Id !== option.Id);
      setValue('JobInterest', filteredList, { shouldDirty: true });
    }
  };

  const saveInfo = async (saveJobInterest: JobInterest) => {
    if (
      saveJobInterest.Salary[1] - saveJobInterest.Salary[0] >
      MAX_SALARY_RANGE
    ) {
      errorHandler('jobInterestForm', t('jobs.errorSalaryRange'), null, true);
      return;
    }
    try {
      if (saveJobInterest && !secondary) {
        await addCandidateJobInterest({
          data: {
            Salary: saveJobInterest.Salary,
            JobInterest: saveJobInterest.JobInterest ?? [],
            Id: jobInterest?.Id,
          },
          lang: i18next.language as 'en' | 'es',
        });
        dispatch(candidatesApi.util.invalidateTags(['Score']));
      } else if (saveJobInterest && secondary) {
        await addCandidateJobInterestInternal({
          data: {
            Salary: saveJobInterest.Salary,
            JobInterest: saveJobInterest.JobInterest ?? [],
            Id: jobInterest?.Id,
          },
          candidateId: candidateId!,
          lang: i18next.language as 'en' | 'es',
        });
        dispatch(candidatesApi.util.invalidateTags(['Score']));
      }
      if (setEdit) {
        setEdit(true);
      }
      successHandler(t('common.saved'), true);
      reset({}, { keepValues: true });

      window.analytics.track('Job interests form submitted');
    } catch (error) {
      // error
    }
  };
  const buttonState = () => {
    if (isDirty) {
      return SaveState.IDLE;
    }
    if (isLoading || isLoadingInternal) {
      return SaveState.PENDING;
    }

    if (isSuccess || isSuccessInternal) {
      return SaveState.FULFILLED;
    }

    return SaveState.IDLE;
  };
  return (
    <div>
      <form data-testid="job-interest-form" onSubmit={handleSubmit(saveInfo)}>
        <JobInterestsForm
          addToJobInterestList={addToJobInterestList}
          control={control}
          jobPositions={data}
          salary={salary}
          selectedTags={selectedTags}
          setValue={setValue}
          t={t}
          secondary={secondary}
        />

        <div className={classes.ButtonContainer}>
          {isEdit ? (
            <FormFooter
              onClickCancel={onClickCancel}
              disabled={buttonState() === SaveState.PENDING || !isDirty}
              saveState={buttonState()}
              translate={t}
              submit
            />
          ) : (
            <SaveButton
              disabled={buttonState() === SaveState.PENDING || !isDirty}
              state={buttonState()}
              t={t}
              submit
            />
          )}
        </div>
      </form>
      {show && onClickCancelModal && (
        <CancelModal onClickCancelModal={onClickCancelModal} />
      )}
    </div>
  );
};

JobInterestsFormContainer.defaultProps = {
  isEdit: false,
  setEdit: undefined,
  onClickCancel: () => {},
  onClickCancelModal: () => {},
  show: false,
  secondary: false,
};

export default JobInterestsFormContainer;
