// import { useState } from 'react';
import { ReactComponent as Check } from '../../../icons/CheckIcon.svg';
import classes from './BaseCheckbox.module.css';

interface Props {
  label?: string;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  id: number;
}

export const BaseCheckbox = ({ label, isChecked, onChange, id }: Props) => {
  const toggleCheckbox = () => onChange(!isChecked);

  return (
    <label className={classes.Checkbox} htmlFor={`checkbox-${id}`}>
      <input
        type="checkbox"
        className="custom-checkbox-input"
        checked={isChecked}
        onChange={toggleCheckbox}
        onClick={(e) => e.stopPropagation()}
      />
      <span className={classes.Checkmark}>
        {isChecked && <Check fill="#fff" />}
      </span>
      {label && <span>{label}</span>}
    </label>
  );
};

BaseCheckbox.defaultProps = {
  label: '',
};
