import { FC } from 'react';
import DefaultIcon2 from '$components/DefaultIcon2/DefaultIcon2';
import CandidateVideosRate from './CandidateVideosRate';
import Button from '../../Buttons/Button';
import { ReactComponent as CloseX } from '../../../icons/CloseX.svg';
import classes from './CandidateVideosModalHeader.module.css';
import { Candidate } from '../../../types/candidates';

interface Props {
  primary?: boolean;
  handleClose: () => void;
  candidate: Candidate | undefined;
  videoId: number;
}

const CandidateVideosModalHeader: FC<Props> = ({
  primary,
  handleClose,
  candidate,
  videoId,
}) => {
  return (
    <div>
      <div className={classes.CloseModal}>
        <Button
          icon={CloseX}
          iconStyles={{
            width: '14',
            height: '14',
            fill: primary ? 'var(--brand-blue-100)' : 'var(--primary-green)',
          }}
          bgColor="transparent"
          showText={false}
          onClick={handleClose}
          customStyles={{
            width: '0.875rem',
            height: '0.875rem',
            flexGrow: '0',
            marginBottom: '0',
          }}
        />
      </div>
      <div className={classes.HeaderContainer}>
        <div className={classes.HeaderCandidate}>
          <div
            className={classes.DefaultIconContainer}
            data-testid="defaultIcon"
          >
            <DefaultIcon2
              picture={candidate?.User?.ProfilePicture?.Url}
              firstName={candidate?.User?.FirstName}
              lastName={candidate?.User?.LastName}
              isEmployer={primary}
              isInternal={!primary}
            />
          </div>
          <div className={classes.Info}>
            <div>Candidate:</div>
            {candidate ? (
              <div className={classes.InfoName}>
                {candidate?.User?.FirstName.split(' ')[0]}&nbsp;
                {candidate?.User?.LastName.split(' ')[0]}
              </div>
            ) : undefined}
          </div>
        </div>
        <CandidateVideosRate additionalVideoId={videoId} primary={primary} />
      </div>
    </div>
  );
};

CandidateVideosModalHeader.defaultProps = {
  primary: false,
};

export default CandidateVideosModalHeader;
