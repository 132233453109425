import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCandidateExtracurricularQuery } from '../../../store/modules/candidate-extracurricular';
import { useGetCandidateTechnicalInfoQuery } from '../../../store/modules/candidate-technical-info';
import { useGetCandidateJobInterestQuery } from '../../../store/modules/candidate-job-interest';
import { useGetWorkHistoryByCandidateIdQuery } from '../../../store/modules/work-experience';
import { useGetCandidateEducationQuery } from '../../../store/modules/candidate-education';
import { useGetCandidateLanguagesQuery } from '../../../store/modules/candidate-language';
import { useGetCandidateSkillsQuery } from '../../../store/modules/candidate-skill';
import Profile from '../../CandidateProfile/Profile';
import Spinner from '../../Spinner/Spinner';
import { useAppSelector } from '../../../hooks';

export type HandleHomePageProps = {
  forceProfile?: boolean;
  setIsOpenSplash: React.Dispatch<React.SetStateAction<boolean>>;
};

const HandleHomePage: FC<HandleHomePageProps> = ({
  forceProfile,
  setIsOpenSplash,
}) => {
  const { serverCandidate } = useAppSelector((state) => state.auth);
  const [, i18n] = useTranslation('global');
  const { isLoading: loadWork } = useGetWorkHistoryByCandidateIdQuery(
    {
      candidateId: serverCandidate?.Id ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !serverCandidate?.Id }
  );
  const { isLoading: loadingTechnicalInfo } = useGetCandidateTechnicalInfoQuery(
    {
      candidateId: serverCandidate?.Id ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !serverCandidate?.Id }
  );
  const { isLoading: loadingJobInterest } = useGetCandidateJobInterestQuery(
    {
      candidateId: serverCandidate?.Id ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !serverCandidate?.Id }
  );

  const { isLoading: loadingExtracurricularInfo } =
    useGetCandidateExtracurricularQuery(
      {
        candidateId: serverCandidate?.Id ?? 0,
        lang: i18n.language as 'en' | 'es',
      },
      { skip: !serverCandidate?.Id }
    );
  const { isLoading: loadingEducationInfo } = useGetCandidateEducationQuery(
    {
      candidateId: serverCandidate?.Id ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !serverCandidate?.Id }
  );
  const { isLoading: loadingLanguage } = useGetCandidateLanguagesQuery(
    {
      candidateId: serverCandidate?.Id ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !serverCandidate?.Id }
  );
  const { isLoading: loadingSkills } = useGetCandidateSkillsQuery(
    {
      candidateId: serverCandidate?.Id ?? 0,
      lang: i18n.language as 'en' | 'es',
    },
    { skip: !serverCandidate?.Id }
  );

  if (
    loadWork ||
    loadingLanguage ||
    loadingTechnicalInfo ||
    loadingExtracurricularInfo ||
    loadingJobInterest ||
    loadingEducationInfo ||
    loadingSkills
  ) {
    return <Spinner />;
  }

  if (forceProfile) {
    return <Profile setIsOpenSplash={setIsOpenSplash} />;
  }

  return <Profile setIsOpenSplash={setIsOpenSplash} />;
};

HandleHomePage.defaultProps = {
  forceProfile: false,
};

export default HandleHomePage;
