import { useMemo, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import FilterType from '../constants/InternalFIlterTypes';
import { useInternalFilterPositionsQuery } from '../store/modules/companies';
import { usePositions } from '../store/modules/companies/hooks/use-positions';
import { FilterPositionQuery } from '../types/candidates';
import FilterTypeSearchBar from '../components/Positions/PositionHeader/FilterContent/FilterTypeSearchBar';

export const useFilteredPositions = (page: number) => {
  const pageRef = useRef<number | null>(null);
  const { concatPositionsAction, setPositionsAction, positions } =
    usePositions();
  const emptyPositionData = {
    data: [],
    meta: {
      hasNextPage: false,
      hasPreviousPage: false,
      itemCount: 0,
    },
  };
  const [searchParams] = useSearchParams();
  const query = useMemo<FilterPositionQuery>(() => {
    const option = searchParams.get('option') as FilterTypeSearchBar | null;
    const queries = searchParams.get('query');
    const term = (searchParams.get('term') as FilterType) ?? FilterType.All;

    return {
      term,
      ...(option === FilterTypeSearchBar.COMPANY &&
        queries && {
          companies: queries?.split(',').map((id) => parseInt(id, 10)),
        }),
      ...(option === FilterTypeSearchBar.JOB_POSITION &&
        queries && {
          jobPositions: queries?.split(',').map((id) => parseInt(id, 10)),
        }),
      ...(option === FilterTypeSearchBar.JOB_ID &&
        queries && {
          jobIds: queries?.split(',').map((id) => parseInt(id, 10)),
        }),
    };
  }, [searchParams]);
  const queryRef = useRef<FilterPositionQuery | null>(null);
  const {
    data = emptyPositionData,
    isLoading,
    isFetching,
    isSuccess,
  } = useInternalFilterPositionsQuery({ page, query });

  useEffect(() => {
    if (
      data &&
      !isFetching &&
      pageRef.current !== page &&
      !isLoading &&
      queryRef.current === query &&
      page !== 1
    ) {
      pageRef.current = page;
      concatPositionsAction(data.data);
    }
  }, [concatPositionsAction, data, isFetching, isLoading, page, query]);

  useEffect(() => {
    if (queryRef.current !== query && !isLoading && !isFetching && isSuccess) {
      queryRef.current = query;
      setPositionsAction(data.data);
    }
  }, [data.data, isFetching, isLoading, isSuccess, query, setPositionsAction]);

  return { query, positions, pageInfo: data.meta, isLoading, isFetching };
};
