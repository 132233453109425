import {
  ErrorMessageEn,
  ErrorMessageEs,
  SuccessMessageEn,
  SuccessMessageEs,
} from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  CompanyBuilder,
  RejectCandidateFromPositionEmployeeInput,
  RejectCandidateFromPositionEmployeeOutput,
} from '../companies.types';

export const rejectCandidateFromPositionEmployee = (builder: CompanyBuilder) =>
  builder.mutation<
    RejectCandidateFromPositionEmployeeOutput,
    RejectCandidateFromPositionEmployeeInput
  >({
    query: ({ query }) => ({
      url: '/candidate-position-status/reject-candidate',
      method: 'PATCH',
      body: query,
    }),
    transformResponse: (
      queryResponse: RejectCandidateFromPositionEmployeeOutput,
      _,
      arg
    ) => {
      successHandler(
        arg.lang === 'es'
          ? SuccessMessageEs.CandidateDeletion
          : SuccessMessageEn.CandidateDeletion,
        true
      );
      return queryResponse;
    },
    transformErrorResponse: (error, meta, args) => {
      errorHandler(
        '[rejectCandidate]',
        args.lang === 'es'
          ? ErrorMessageEs.CandidateDeletionError
          : ErrorMessageEn.CandidateDeletionError,
        error,
        true
      );
      // return error.data;
    },
    invalidatesTags: () => [{ type: 'Company' }],
  });
