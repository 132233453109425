import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowWidth from '../../hooks/useWindowWidth';
import EditableContainer from '../EditableContainer/EditableContainer';
import ReadOnly from '../ReadOnly/ReadOnly';
import EducationalInformationForm from '../EducationalHistoryForm/EducationalInformationForm';
import NewEducation from '../EducationalHistoryForm/NewEducation';
import { useGetCandidateEducationQuery } from '../../store/modules/candidate-education';

interface Props {
  isActive: boolean;
}

const EducationalInfoEditableContainer: FC<Props> = ({ isActive }) => {
  const [t, i18n] = useTranslation('global');
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [show, setShow] = useState(false);
  const [newEducation, setNewEducation] = useState(false);
  const isMobile = useWindowWidth() <= 650;

  const candidateId: number = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;

  const { data, isLoading } = useGetCandidateEducationQuery({
    candidateId,
    lang: i18n.language as 'en' | 'es',
  });

  const onClickPencil = () => {
    setIsEdit(false);
  };
  const handleCancel = () => {
    setShow(true);
  };
  const handleCancelModal = (isCancel: boolean, continueEditing: boolean) => {
    if (isCancel) {
      setIsEdit(true);
    }
    if (newEducation && continueEditing) {
      setNewEducation(false);
    }
    setShow(false);
  };

  const onClickSave = () => {
    setNewEducation(false);
  };

  const onClickPlus = () => {
    setNewEducation(!newEducation);
    if (isMobile && window) {
      window.scrollTo(0, 0);
    }
  };

  const newEducationOrField = () => {
    if (newEducation) {
      return (
        <NewEducation
          onClickCancel={handleCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={handleCancelModal}
          show={show}
          onClickSave={onClickSave}
          newEducation
          countForms={data?.length || 1}
        />
      );
    }
    return (
      !isLoading &&
      data?.map(
        (education) =>
          education?.Id && (
            <ReadOnly
              key={`educationalInfo-${education.Id}`}
              title={education.SchoolName.val!}
              subtitle={
                education.DegreeName.val
                  ? `${
                      education.DegreeType.val
                        ? `${education.DegreeType.val}, `
                        : ''
                    }${education.DegreeName.val}`
                  : ''
              }
              endDate={
                !education?.Enrolled
                  ? education?.YearGraduated.val!
                  : `${t('formNames.enrolled')}`
              }
            />
          )
      )
    );
  };
  return (
    <EditableContainer
      title={t('formNames.education')}
      onClickPencil={onClickPencil}
      onClickPlus={onClickPlus}
      isEditable={isEdit}
      hasAddMore
      addMore={newEducation ? t('education.experience') : false}
      isActive={isActive}
    >
      {!isEdit ? (
        <EducationalInformationForm
          isEdit
          setEdit={setIsEdit}
          onClickCancel={handleCancel}
          onClickCancelModal={handleCancelModal}
          show={show}
        />
      ) : (
        newEducationOrField()
      )}
    </EditableContainer>
  );
};

export default EducationalInfoEditableContainer;
