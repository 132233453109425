import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import classes from './TrackHiredCandidatesModal.module.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '800px',
  },
};

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: JSX.Element | JSX.Element[];
}

const TrackHiredCandidatesModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  children,
}) => {
  const [t] = useTranslation('global');
  Modal.setAppElement(document.getElementById('root') as HTMLElement);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
      style={customStyles}
    >
      <header data-testid="tracking-modal">
        <h3 className={classes.title}>{t('Tracking.Title')}</h3>
      </header>
      {children}
    </Modal>
  );
};

export default TrackHiredCandidatesModal;
