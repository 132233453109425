import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Modal from 'react-modal';
import { ReactComponent as CloseBlue } from '$icons/CloseBlue.svg';
import {
  useGetInterviewSlotsQuery,
  useSetInterviewSlotsMutation,
} from '$store/modules/interview';
import classes from './CalendarModal.module.css';
import { LeftCalendarModal } from './LeftCalendarModal';
import { RightCalendarModal } from './RightCalendarModal/RightCalendarModal';
import SuccessAnimation from './SuccessAnimation/SuccessAnimation';
import Spinner from '../../Spinner/Spinner';
import { DAYJS_FORMAT_DATETIME, TIMEZONES } from '../../../constants/datetimes';
import { diffOffset } from '../../../utils/utcDifference';
import { useAppDispatch } from '../../../hooks';
import { companiesApi } from '../../../store/modules/companies';
import { segmentTrack } from '../../../utils/handleSegment';
import { candidatesApi } from '../../../store/modules/candidates';
import { SetSchedulesType } from './CalendarModalTypes';

dayjs.extend(utc);
dayjs.extend(timezone);

interface CalendarModalProps {
  candidateId: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSwitchingId: React.Dispatch<React.SetStateAction<number | null>>;
  candidatePositionStatusId: number;
}
export const CalendarModal = ({
  candidateId,
  isOpen,
  setIsOpen,
  setSwitchingId,
  candidatePositionStatusId,
}: CalendarModalProps) => {
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const [schedules, setSchedules] = useState<SetSchedulesType[]>([]);
  const [done, setDone] = useState(false);
  const [timeZone, setTimeZone] = useState(TIMEZONES.EASTERN);

  const { data: interviewData, isLoading: isLoadingInterviewData } =
    useGetInterviewSlotsQuery();
  const [setInterview] = useSetInterviewSlotsMutation();

  const dispatch: any = useAppDispatch();

  useEffect(() => {
    if (!isLoadingInterviewData) {
      const interviewParsedData = interviewData?.map((singleSchedule) => {
        const difference = diffOffset(
          timeZone,
          singleSchedule.Timezone === null
            ? TIMEZONES.EASTERN
            : singleSchedule.Timezone
        );
        return {
          id: singleSchedule.Id,
          status: singleSchedule?.Interview?.Status,
          date: dayjs(singleSchedule.StartDate.replace('Z', ''))
            .add(difference, 'h')
            .tz(timeZone, true),
        };
      });

      setSchedules(interviewParsedData ?? []);
    }
  }, [timeZone, interviewData, isLoadingInterviewData]);

  const submitSchedules = () => {
    const interviewSchedules = schedules.map((singleSchedule) => {
      return {
        StartDate: singleSchedule.date.format(DAYJS_FORMAT_DATETIME),
        EndDate: singleSchedule.date
          .add(1, 'hour') // assuming 1 hour of duration
          .format(DAYJS_FORMAT_DATETIME),
        Timezone: timeZone,
      };
    });
    setInterview({
      schedules: interviewSchedules,
      candidateId,
      candidatePositionStatusId,
    }).then(() => {
      segmentTrack('Interview Requested', {
        candidate_id: candidateId,
      });
      dispatch(companiesApi.util.invalidateTags(['PositionsList']));
      dispatch(candidatesApi.util.invalidateTags(['Candidates']));
    });
    setDone(true);
    setTimeout(() => {
      setSwitchingId(candidateId);
      setIsOpen(false);
    }, 1000);
  };

  const handleClose = () => {
    setIsOpen(false);
    setDone(false);
  };

  return (
    <Modal
      className={classes.modal}
      overlayClassName={classes.overlay}
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
    >
      {!isLoadingInterviewData && (
        <div className={`items-stretch overflow-scroll ${classes.container}`}>
          <CloseBlue
            onClick={() => setIsOpen(false)}
            className="block md:hidden cursor-pointer w-8 h-8 absolute top-1 right-1"
          />
          {!done ? (
            <>
              <LeftCalendarModal
                setSchedules={setSchedules}
                schedules={schedules}
                setTimezone={setTimeZone}
                timezone={timeZone}
              />
              <div className={classes.divider} />
              <RightCalendarModal
                schedules={schedules}
                setSchedules={setSchedules}
                submitSchedules={submitSchedules}
              />
            </>
          ) : (
            <SuccessAnimation />
          )}
        </div>
      )}
      {isLoadingInterviewData && (
        <div
          style={{ display: 'flex', justifyContent: 'center', height: '100%' }}
        >
          <Spinner height="35rem" />
        </div>
      )}
    </Modal>
  );
};
