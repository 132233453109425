import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { SaveState } from '../types/ForrmType';
import { useUpdateCompaniesMutation } from '../store/modules/companies';

const useUpdateCompany = (
  companyId: number,
  companyName: string,
  setModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [t] = useTranslation('global');
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      Name: companyName,
    },
  });

  const [showCancel, setShowCancel] = useState(false);
  const [saveState, setSaveState] = useState<SaveState>(SaveState.IDLE);
  const [updateCompany] = useUpdateCompaniesMutation();

  const name = register('Name', {
    required: `${t('CompaniesTable.ErrorName')}`,
  });

  const onSubmit = async (data: any) => {
    setSaveState(SaveState.PENDING);
    await updateCompany({ companyId, Name: data.Name.trim() });
    setSaveState(SaveState.IDLE);
    setModal(false);
  };

  const handleCancel = () => {
    setShowCancel(true);
  };
  const handleCancelModal = (isCancel: boolean) => {
    if (isCancel) {
      setModal(false);
    }
    setShowCancel(false);
  };

  const isDisabled = saveState === SaveState.PENDING || !isDirty;

  return {
    isDisabled,
    handleSubmit,
    name,
    t,
    handleCancel,
    handleCancelModal,
    errors,
    onSubmit,
    saveState,
    showCancel,
  };
};

export default useUpdateCompany;
