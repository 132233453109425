import { FC, useMemo } from 'react';
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import classes from './CompaniesTable.module.css';
import { CompanyColumn } from '../../../../constants/CompanyColumn';
import ActionEmployees from './Actions/ActionEmployees';
import { CompaniesTable as ICompaniesTable } from '../../../../types/candidates';

interface Props {
  data: ICompaniesTable[];
  children: React.ReactNode | React.ReactNode[];
}

const CompaniesTable: FC<Props> = ({ data, children }) => {
  const [t] = useTranslation('global');

  const columns = useMemo<ColumnDef<CompanyColumn>[]>(
    () => [
      {
        header: `${t('CompaniesTable.CompanyId')}`,
        accessorKey: 'companyId',
      },
      {
        header: `${t('CompaniesTable.CompanyName')}`,
        accessorKey: 'companyName',
      },
      {
        header: `${t('CompaniesTable.OpenPositions')}`,
        accessorKey: 'openPositions',
      },
      {
        header: `${t('CompaniesTable.HiredCandidates')}`,
        accessorKey: 'totalHired',
      },
      {
        header: `${t('CompaniesTable.FormerEmployees')}`,
        accessorKey: 'formerEmployeesTotal',
      },
      {
        header: `${t('CompaniesTable.TotalPositions')}`,
        accessorKey: 'totalPositions',
      },
    ],
    [t]
  );
  const { getHeaderGroups, getRowModel } = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <div className={classes.tableContainer} data-testid="candidate-table">
      <div className={classes.tableScroll}>
        <table>
          <thead>
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={classes.tableHead}
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
                <th
                  className={classes.tableHead}
                  style={{
                    textAlign: 'center',
                    zIndex: 1,
                  }}
                >
                  {t('CompaniesTable.Actions')}
                </th>
              </tr>
            ))}
          </thead>
          <tbody>
            {getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  title={t('CompaniesTable.Details')}
                  className={classes.clickableRow}
                >
                  {row.getVisibleCells().map((cell, idx) => {
                    return (
                      <td
                        key={cell.id}
                        className={`${classes.cell}${
                          idx !== 1 ? classes.centerAlign : ''
                        } ${
                          cell.column.columnDef.header === 'Company name' &&
                          classes.capitalize
                        }`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                  <td
                    aria-label="action employees label"
                    className={classes.cell}
                  >
                    <ActionEmployees
                      companyId={row.original.companyId}
                      companyName={row.original.companyName.trim()}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {children}
    </div>
  );
};

export default CompaniesTable;
