import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReadOnly from '../ReadOnly/ReadOnly';
import NewWorkHistory from '../WorkHistoryFormContainer/NewWorkHistory';
import WorkHistoryFormContainer from '../WorkHistoryFormContainer/WorkHistoryFormContainer';
import classes from './WorkHistoryEditableContainer.module.css';
import EditableContainerInternal from '../EditableContainer/EditableContainerInternal';
import useInternalEditableContainer from '../../hooks/useInternalEditableContainer';
import { useGetWorkHistoryByCandidateIdQuery } from '../../store/modules/work-experience';

interface Props {
  isActive: boolean;
  secondary: boolean;
}

const WorkHistoryEditableContainerInternal: FC<Props> = ({
  isActive,
  secondary,
}) => {
  const { candidateId } = useParams();
  const [t, i18Next] = useTranslation('global');

  const { data, isLoading } = useGetWorkHistoryByCandidateIdQuery({
    lang: i18Next.language as 'es' | 'en',
    candidateId: +candidateId!,
  });

  const {
    newFormStep: newWorkHistory,
    handleCancel,
    setIsEdit,
    handleCancelModal,
    show,
    isEdit,
    onClickSave,
    onClickPencil,
    onClickPlus,
  } = useInternalEditableContainer();

  const newWorkOrField = () => {
    if (newWorkHistory) {
      return (
        <NewWorkHistory
          onClickCancel={handleCancel}
          isEdit
          setEdit={setIsEdit}
          onClickCancelModal={handleCancelModal}
          show={show}
          onClickSave={onClickSave}
          newWorkHistory
          secondary={secondary}
          countForms={data?.length || 1}
        />
      );
    }

    return data?.map((work) => {
      const defaultDate = work?.Admission?.val
        ? `${t('formNames.actually')}`
        : '';
      return (
        <ReadOnly
          key={`workHistoryInternal-${work?.Id}`}
          title={work?.JobTitle?.val!}
          subtitle={work?.Company.val ?? 'N/A'}
          secondary={secondary}
          startDate={work?.Admission.val?.split('-')[0]}
          endDate={
            work?.Egress?.val ? work?.Egress.val.split('-')[0] : defaultDate
          }
          isWorkExperience={(work?.Description?.val?.length || 0) > 0}
          workExperienceDescription={work?.Description?.val ?? 'N/A'}
        />
      );
    });
  };

  return (
    <EditableContainerInternal
      title={t('formNames.work')}
      isEditable={secondary && isEdit}
      onClickPencil={onClickPencil}
      onClickPlus={onClickPlus}
      hasAddMore
      addMore={newWorkHistory ? t('workHistory.experience') : false}
      isActive={isActive}
      isLoading={isLoading}
    >
      {!isEdit ? (
        <div className={classes.content}>
          <WorkHistoryFormContainer
            isEdit
            setEdit={setIsEdit}
            onClickCancel={handleCancel}
            onClickCancelModal={handleCancelModal}
            show={show}
            secondary={secondary}
          />
        </div>
      ) : (
        newWorkOrField()
      )}
    </EditableContainerInternal>
  );
};

export default WorkHistoryEditableContainerInternal;
