import { ErrorMessageEn, SuccessMessageEn } from '../../../../constants/alerts';
import { errorHandler, successHandler } from '../../../utils';
import {
  UnsuscribeUserFromEmailInput,
  UserInfoBuilder,
} from '../user-info.types';

export const unsubscribeUserFromEmail = (builder: UserInfoBuilder) =>
  builder.mutation<void, UnsuscribeUserFromEmailInput>({
    query: ({ token }) => ({
      url: '/users/unsubscribe',
      body: { token },
      method: 'PUT',
    }),
    transformResponse: () => {
      successHandler(SuccessMessageEn.SUCCESSFULLY_UNSUBSCRIBED, true);
    },
    transformErrorResponse: (error) => {
      errorHandler(
        '[Error unsubscribing user from emails]',
        ErrorMessageEn.ErrorUnsubscribing,
        error,
        true
      );
    },
  });
