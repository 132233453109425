import { useState } from 'react';
import axios from 'axios';
import ModalInTailwind from '$components/ModalInTailwind/ModalInTailwind';
import { ReactComponent as Loading } from '$icons/loading-buffer.svg';
import classes from './SingleAttachmentSummary.module.css';
import ModalDocumentPDF from './ModalDocumentPDF';

export enum IconValidColorsType {
  YELLOW = 'yellow',
  BLUE = 'blue',
  GREEN = 'green',
}

interface SingleAttachmentSummaryInterface {
  icon: any;
  title: string;
  color: IconValidColorsType;
  link?: string;
  behanceFix?: boolean;
  onClick?: () => void;
  additionalClassNames?: string;
  segmentEvent?: () => void;
  isPdf?: boolean;
}

function blobToBase64(blob: any) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const SingleAttachmentSummary = ({
  link,
  onClick,
  icon,
  title,
  color,
  behanceFix,
  additionalClassNames,
  segmentEvent,
  isPdf,
}: SingleAttachmentSummaryInterface) => {
  const [modalPdf, setModalPdf] = useState(false);
  const [filePDF, setFilePDF] = useState<null | string>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  let iconBackgroundColor;

  if (link && !link?.startsWith('http:') && !link?.startsWith('https:')) {
    link = `//${link}`;
  }

  if (color === IconValidColorsType.YELLOW) {
    iconBackgroundColor = classes.singleAttachmentSummaryIconYellow;
  } else if (color === IconValidColorsType.BLUE) {
    iconBackgroundColor = classes.singleAttachmentSummaryIconBlue;
  } else {
    iconBackgroundColor = classes.singleAttachmentSummaryIconGreen;
  }

  const loadPdf = async () => {
    if (link && isPdf) {
      if (segmentEvent) segmentEvent();
      if (filePDF) return;
      setLoading(true);
      try {
        if (!link.toLowerCase().endsWith('.pdf')) {
          setError(true);
          return;
        }
        const downloadFile = await axios.get(link, {
          responseType: 'blob',
        });
        if (
          downloadFile.data.type !== 'application/pdf' &&
          downloadFile.data.type !== 'application/octet-stream' &&
          downloadFile.data.type !== 'binary/octet-stream'
        ) {
          setError(true);
          return;
        }
        const base64 = (await blobToBase64(downloadFile.data)) as string;
        if (base64 === 'data:') {
          setError(true);
          return;
        }
        setError(false);
        if (
          downloadFile.data.type === 'application/octet-stream' ||
          downloadFile.data.type === 'binary/octet-stream'
        ) {
          const base64PDF = base64.replace(
            /data:(binary|application)\/octet-stream;/,
            'data:application/pdf;'
          );
          setFilePDF(base64PDF as string);
        } else {
          setFilePDF(base64 as string);
        }
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePdfModal = async () => {
    setModalPdf(true);
    if (!filePDF) await loadPdf();
  };

  const onClickHandler = (ev: any) => {
    if (!link || link === '') ev.preventDefault();
    if (isPdf) {
      ev.preventDefault();
      handlePdfModal();
      return;
    }
    if (onClick) onClick();
    if (segmentEvent) segmentEvent();
  };

  return (
    <>
      <ModalInTailwind isOpen={modalPdf} closeModal={() => setModalPdf(false)}>
        <ModalDocumentPDF
          filePDF={filePDF}
          error={error}
          loading={loading}
          closeModal={() => setModalPdf(false)}
        />
      </ModalInTailwind>
      <div className={additionalClassNames}>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          style={{ display: 'flex', alignSelf: 'self-start' }}
          onClick={onClickHandler}
        >
          <div className={classes.singleAttachmentSummaryContainer}>
            <div
              className={`${
                classes.singleAttachmentSummaryIcon
              } ${iconBackgroundColor} ${
                behanceFix ? classes.behanceIconFix : ''
              }`}
            >
              {icon}
            </div>
            <div className={classes.singleAttachmentSummaryInfoContainer}>
              <div
                className={`capitalize ${classes.singleAttachmentSummaryTitle}`}
              >
                {title}
              </div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

SingleAttachmentSummary.defaultProps = {
  link: '',
  behanceFix: false,
  onClick: () => {},
  additionalClassNames: '',
  segmentEvent: () => {},
  isPdf: false,
};

export default SingleAttachmentSummary;

export const SingleAttachmentSummaryLoading = ({
  additionalClassNames,
}: {
  additionalClassNames?: string;
}) => {
  return (
    <div className={additionalClassNames}>
      <div
        className={`animate-pulse ${classes.singleAttachmentSummaryContainer}`}
      >
        <div
          className={`${classes.singleAttachmentSummaryIcon} ${classes.singleAttachmentSummaryIconBlue}`}
          style={{ padding: '10px' }}
        >
          <Loading />
        </div>
        <div className={classes.singleAttachmentSummaryInfoContainer}>
          <div className={`capitalize ${classes.singleAttachmentSummaryTitle}`}>
            Loading...
          </div>
        </div>
      </div>
    </div>
  );
};

SingleAttachmentSummaryLoading.defaultProps = {
  additionalClassNames: '',
};
