import { useParams } from 'react-router-dom';
import { useAppSelector } from './use-app-selector.hook';

export const useCandidateId = (): number => {
  const { serverCandidate } = useAppSelector((state) => state.auth);
  const { candidateId } = useParams();
  if (serverCandidate?.Id) {
    return Number(serverCandidate.Id);
  }

  if (candidateId) {
    return Number(candidateId);
  }

  const candidateIdLocalStorage: string = JSON.parse(
    localStorage.getItem('ServerCandidateResponse') || '{}'
  )?.Id;

  if (candidateIdLocalStorage) {
    return Number(candidateIdLocalStorage);
  }
  return 0;
};
