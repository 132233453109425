import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { warningHandlerInvalidFiles } from '../../../../../store/utils';
import DragDrop from '../../../../DragDrop/DragDrop';
import classes from './DropzoneAdditionalVideo.module.css';

import { AdditionalVideoFormValues } from '../../../../../types/additional-video/additional-video';

interface Props {
  control: Control<AdditionalVideoFormValues, any>;
  setValue: UseFormSetValue<AdditionalVideoFormValues>;
}

const MAX_FILE_SIZE = 150 * 1024 * 1024; // 150MB
export const DropzoneAdditionalVideo = ({ control, setValue }: Props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'video/mp4',
    maxSize: MAX_FILE_SIZE,
    onDropAccepted: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setValue('file', acceptedFiles[0]);
      }
    },
    onDropRejected: (fileRejection) => {
      warningHandlerInvalidFiles(true, fileRejection, 'mp4', '150');
    },
    maxFiles: 1,
  });

  return (
    <div>
      <Controller
        control={control}
        name="file"
        rules={{
          required: 'Please upload a video',
        }}
        render={({ formState: { errors } }) => (
          <div className={classes.DropZoneContainer}>
            <div className={classes.Center}>
              <DragDrop
                isMobile={false}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
                supportedFilesText="Only MP4 files supported"
                disabled={false}
                secondary
              />
            </div>
            {errors.file && (
              <p className={classes.error}>{errors.file.message}</p>
            )}
          </div>
        )}
      />
    </div>
  );
};
