import { ErrorMessageEn } from '../../../../constants/alerts';
import { Candidate } from '../../../../types/candidates';
import { errorHandler } from '../../../utils';
import { CandidateBuilder } from '../candidates.types';

export const getCandidateInfo = (builder: CandidateBuilder) =>
  builder.query<Candidate, string | number>({
    query: (candidateId) => ({
      url: `/candidates/get/${candidateId}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [
            {
              type: 'Candidate' as const,
              Id: result.Id,
            },
          ]
        : [],
    transformErrorResponse: (error) => {
      errorHandler(
        '[getCandidateInfoEndpoint]',
        ErrorMessageEn.GetCandidatesFail,
        error,
        true
      );
      return error.data;
    },
  });
