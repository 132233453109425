import { useParams, useNavigate } from 'react-router-dom';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmEmailAction } from '../../../store/legacy/auth';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { ReactComponent as LightTear } from '../../../icons/LightTear.svg';
import classes from './ConfirmEmailScreen.module.css';
import DescriptionText from '../../DescriptionText/DescriptionText';
import Spinner from '../../Spinner/Spinner';
import Button from '../../Buttons/Button';
import { ReactComponent as Logo } from '../../../images/logoBlue.svg';
import { ReactComponent as Email } from '../../../images/emailConfirmation.svg';
import { ReactComponent as EmailError } from '../../../images/emailError.svg';
import { SaveState } from '../../../types/ForrmType';
import { useAppDispatch, useAppSelector } from '../../../hooks';

interface Props {
  userRole: string;
}

const ConfirmEmailScreen: FC<Props> = ({ userRole }) => {
  const isMobile = useWindowWidth() < 768;
  const [t] = useTranslation('global');
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const { loadingConfirmEmail } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(confirmEmailAction(token!));
  }, [dispatch, token]);

  const ComponentSwitch = useCallback(() => {
    switch (loadingConfirmEmail) {
      case SaveState.FULFILLED:
        return (
          <>
            <Logo />{' '}
            <Email fill={userRole !== 'internal' ? '#05668D' : '#079E88'} />
            <h1 className={classes.Title}>{t('confirmEmailScreen.success')}</h1>
            <div className={classes.HomeButton}>
              <Button
                showText
                bgColor={
                  userRole !== 'internal'
                    ? 'var(--brand-blue-100)'
                    : 'var(--primary-green)'
                }
                onClick={() => {
                  navigate(
                    userRole === 'candidate' ? '/login' : `/${userRole}_login`
                  );
                }}
                text={t('confirmEmailScreen.goHome')}
                customStyles={{ height: 40 }}
              />
            </div>
          </>
        );

      case SaveState.FAILED:
        return (
          <>
            <Logo /> <EmailError />
            <h1 className={classes.TitleError}>
              {t('confirmEmailScreen.error')}
            </h1>
            <DescriptionText
              darkText
              text={t('confirmEmailScreen.ErrorHint')}
            />
            <div className={classes.HomeButton}>
              <Button
                showText
                bgColor={
                  userRole !== 'internal'
                    ? 'var(--brand-blue-100)'
                    : 'var(--primary-green)'
                }
                onClick={() => {
                  navigate(
                    userRole === 'candidate' ? '/login' : `/${userRole}_login`
                  );
                }}
                text={t('confirmEmailScreen.goHome')}
                customStyles={{ height: 40 }}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  }, [loadingConfirmEmail, navigate, t, userRole]);

  if (loadingConfirmEmail === SaveState.PENDING) {
    return <Spinner />;
  }

  return (
    <div
      className={`${classes.container} ${
        userRole !== 'internal'
          ? classes.employerPrimary
          : classes.internalPrimary
      }`}
    >
      {!isMobile && <LightTear className={classes.LightTear} />}

      <div className={classes.TextContainer}>
        <ComponentSwitch />
      </div>
    </div>
  );
};

export default ConfirmEmailScreen;
