const getRangeOfColor = (value: number) => {
  if (value <= 10 && value > 0) {
    return '#cc4130';
  }
  if (value <= 20 && value > 10) {
    return '#bb5d27';
  }
  if (value <= 30 && value > 20) {
    return '#ac7320';
  }
  if (value <= 40 && value > 30) {
    return '#9f891a';
  }
  if (value <= 50 && value > 40) {
    return '#959b14';
  }
  if (value <= 60 && value > 50) {
    return '#779926';
  }
  if (value <= 70 && value > 60) {
    return '#4e864b';
  }
  if (value <= 80 && value > 70) {
    return '#397d5e';
  }
  if (value <= 90 && value > 80) {
    return '#1a6f79';
  }
  if (value <= 100 && value > 90) {
    return '#05668c';
  }
  return 'red';
};

export default getRangeOfColor;
