import { FC } from 'react';
import { SaveState } from '../../../../../types/ForrmType';
import classes from './ConfirmModalContent.module.css';

type Props = {
  handleClose: () => void;
  pendingActionState: SaveState | undefined;
  handleConfirm: () => void;
  textContent: {
    confirmTitle: string;
    confirmText: string | undefined;
    confirmSubText: string;
    confirmBack: string;
    confirmButton: string;
  };
};

const ConfirmModalContent: FC<Props> = ({
  handleClose,
  pendingActionState,
  handleConfirm,
  textContent,
}) => {
  const {
    confirmTitle,
    confirmText,
    confirmSubText,
    confirmBack,
    confirmButton,
  } = textContent;

  return (
    <>
      <strong>{confirmTitle}</strong>
      {confirmText === 'Delete' && <p>{confirmText}</p>}
      <p>{confirmSubText}</p>
      <div className={classes.actions}>
        <button
          type="button"
          className={classes.normalButton}
          onClick={() => handleClose()}
        >
          {confirmBack}
        </button>
        <button
          disabled={pendingActionState === SaveState.PENDING}
          type="button"
          onClick={handleConfirm}
          className={classes.ConfirmBtn}
        >
          {confirmButton}
        </button>
      </div>
    </>
  );
};

export default ConfirmModalContent;
