import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import classes from './TopicInput.module.css';
import { AdditionalVideoFormValues } from '../../../../../types/additional-video/additional-video';

interface Props {
  setValue: UseFormSetValue<AdditionalVideoFormValues>;
  control: Control<AdditionalVideoFormValues, any>;
  file: File | null;
  getValues: UseFormGetValues<AdditionalVideoFormValues>;
}
export const TopicInput = ({ setValue, control, file, getValues }: Props) => {
  const [t] = useTranslation('global');

  useEffect(() => {
    if (file && getValues('name') === '') {
      const fileName = file.name.split('.');
      fileName.pop();
      setValue('name', fileName.join(''), {
        shouldValidate: true,
      });
    }
  }, [setValue, file, getValues]);

  return (
    <Controller
      name="name"
      control={control}
      rules={{
        required: `${t('AdditionalVideos.modals.topicError')}`,
      }}
      render={({ formState: { errors }, field }) => (
        <div className={classes.dropdownContainer}>
          <p className={classes.subtitle}>
            {t('AdditionalVideos.modals.topicTitle')}
          </p>
          <input
            {...field}
            className="w-full rounded border p-4"
            type="text"
            onChange={(e) => {
              setValue('name', e.target.value, { shouldValidate: true });
            }}
            placeholder="Video Title"
            data-testid="topicInput"
          />
          {errors?.name && (
            <p className={classes.error}>{errors.name?.message}</p>
          )}
        </div>
      )}
    />
  );
};

TopicInput.defaultProps = {
  error: '',
};
