import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ForgotPasswordForm.module.css';
import logo from '../../images/logoBlue.svg';

interface ForgotPasswordModalProps {
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPasswordModal: FC<ForgotPasswordModalProps> = ({
  setModalIsOpen,
}) => {
  const [t] = useTranslation('global');
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (!modalRef.current?.contains(event.target as Node)) {
        setModalIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [setModalIsOpen]);

  return (
    <div className={classes.modal}>
      <div ref={modalRef} className={classes.modal_content}>
        <img src={logo} alt="logo" />
        <b className={classes.modalTitle}>{t('forgotPass.recovery')}</b>
        <p className={classes.modalText}>{t('forgotPass.recoveryText')}</p>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
