import { FC } from 'react';
import classes from './InfoTitle.module.css';

interface InfoTitleProps {
  title: string;
  highlight?: string;
  breakLine?: boolean;
  highlightText?: boolean;
  highlightPrimary?: boolean;
}

const defaultProps = {
  highlight: '',
  breakLine: true,
  highlightText: true,
  highlightPrimary: true,
};

const InfoTitle: FC<InfoTitleProps> = ({
  title,
  highlight,
  breakLine,
  highlightText,
  highlightPrimary,
}) => {
  return (
    <p className={classes.InfoTitle} data-testid="info-title">
      {title}{' '}
      <span
        data-testid="info-highlight"
        className={
          highlightText
            ? (highlightPrimary && classes.HighlightPrimary) ||
              classes.HighlightSecondary
            : undefined
        }
      >
        {breakLine ? `\n${highlight}` : highlight}
      </span>
    </p>
  );
};

InfoTitle.defaultProps = defaultProps;

export default InfoTitle;
