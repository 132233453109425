import {
  Auth0Error,
  Auth0Result,
  Auth0UserProfile,
  Auth0DecodedHash,
} from 'auth0-js';
import axios, { Method } from 'axios';
import auth0Client from '../Auth0.config';
import PasswordRecoveryRequestDto from '../types/serverTypes/passwordRecoveryRequestDto';
import ResetPasswordDto from '../types/serverTypes/resetPasswordDto';
import TokenValidationDto from '../types/serverTypes/tokenValidationDto';
import {
  CONFIRM_EMAIL,
  CREATE_EMPLOYER,
  CREATE_INTERNAL,
  CREATE_USER,
  DUPLICATE_POSITION_BY_SHARE_ID,
  GET_CANDIDATE_BY_USERID,
  GET_EMPLOYER_BY_USERID,
  GET_USER_BY_EMAIL,
  GET_USER_WITH_TOKEN,
  PASSWORD_RECOVERY_REQUEST,
  REQUEST_CONFIRMATION_EMAIL,
  RESET_PASSWORD,
  TOKEN_VALIDATION,
  UPDATE_EMPLOYER_TERM_OF_SERVICE,
  UPDATE_FIRST_LOGIN,
} from './endpoints';
import { IAuth0Response } from '../types/candidates';
import CandidateEntity from '../types/entities/candidate.entity';
import EmployerDto from '../types/serverTypes/employerDto';
import { InternalEntity } from '../types/entities/internal.entity';
import {
  UserCandidate,
  UserDto,
  UserEmployer,
} from '../types/serverTypes/userDto';

// Auth0 Params
const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
const redirectUriLogin = `${window.location.origin}/login`;
const redirectEmployerUriLogin = `${window.location.origin}/employer_login`;
const redirectInternalUriLogin = `${window.location.origin}/internal_login`;
const responseType = 'id_token';
const dbConnection = 'Username-Password-Authentication';
const uri: { [key: string]: string } = {
  employer: redirectEmployerUriLogin,
  candidate: redirectUriLogin,
  internal: redirectInternalUriLogin,
};

export const emailSignUp = (
  email: string,
  password: string,
  userType: string
): Promise<IAuth0Response> =>
  new Promise((resolve, reject) => {
    auth0Client.signup(
      {
        connection: dbConnection,
        email,
        password,
        userMetadata: { userType },
      },
      (error: Auth0Error | null, authResult: IAuth0Response) => {
        if (error) {
          reject(error);
        }
        if (authResult) {
          resolve(authResult);
        }
      }
    );
  });

export const emailLogin = (email: string, password: string) =>
  new Promise<Auth0Result>((resolve, reject) => {
    auth0Client.client.login(
      {
        username: email,
        password,
        realm: 'Username-Password-Authentication',
        scope: 'offline_access',
      },
      (error: Auth0Error | null, authResult: Auth0Result) => {
        if (error) {
          reject(error);
        }
        if (authResult) {
          localStorage.setItem('id_token', String(authResult.idToken));
          resolve(authResult);
        }
      }
    );
  });

export const setUser = (accessToken: string): Promise<Auth0UserProfile> =>
  new Promise<Auth0UserProfile>((resolve, reject) => {
    auth0Client.client.userInfo(accessToken, (err, result) => {
      if (err) return reject(err);
      localStorage.setItem('user', JSON.stringify(result));
      return resolve(result);
    });
  });

export const googleSignIn = (userType: string): Promise<Auth0DecodedHash> =>
  new Promise((resolve, reject) => {
    auth0Client.popup.authorize(
      {
        connection: 'google-oauth2',
        domain,
        redirectUri: uri[userType],
        responseType,
      },
      (error: Auth0Error | null, authResult: Auth0DecodedHash) => {
        if (error) {
          reject(error);
        }
        if (authResult) {
          localStorage.setItem('id_token', String(authResult.idToken));
          resolve(authResult);
        }
      }
    );
  });

export const linkedInSignIn = (userType: string): Promise<Auth0DecodedHash> =>
  new Promise((resolve, reject) => {
    auth0Client.popup.authorize(
      {
        connection: 'linkedin',
        domain,
        redirectUri: uri[userType],
        responseType,
      },
      (error: Auth0Error | null, authResult: Auth0DecodedHash) => {
        if (error) {
          reject(error);
        }
        if (authResult) {
          localStorage.setItem('id_token', String(authResult.idToken));
          resolve(authResult);
        }
      }
    );
  });

export const Logout = (userType: string) =>
  new Promise<void>((resolve, reject) => {
    try {
      auth0Client.logout({
        returnTo: uri[userType],
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });

export const resetPassword = async (
  passwordRecoveryRequestDto: PasswordRecoveryRequestDto
): Promise<void> => {
  interface RequestParam {
    url: string;
    method: Method;
  }

  const options: RequestParam = {
    method: 'GET',
    url: `${PASSWORD_RECOVERY_REQUEST(passwordRecoveryRequestDto)}`,
  };

  await axios.request(options);
};

export const validateToken = async (
  tokenValidationDto: TokenValidationDto
): Promise<void> => {
  interface RequestParam {
    url: string;
    method: Method;
  }

  const options: RequestParam = {
    method: 'GET',
    url: `${TOKEN_VALIDATION(tokenValidationDto)}`,
  };

  await axios.request(options);
};

export const submitPasswordChange = async (
  resetPasswordDto: ResetPasswordDto
): Promise<void> => {
  const { token, timezoneOffset, password } = resetPasswordDto;
  interface RequestParam {
    url: string;
    method: Method;
    data: {
      token: string;
      timezoneOffset: string;
      password: string;
    };
  }

  const options: RequestParam = {
    method: 'POST',
    url: `${RESET_PASSWORD()}`,
    data: {
      token,
      timezoneOffset: timezoneOffset.toString(),
      password,
    },
  };

  await axios.request(options);
};

export const checkSession = (nonce: string): Promise<any> =>
  new Promise((resolve, reject) => {
    auth0Client.checkSession(
      {
        nonce,
        redirectUri: redirectUriLogin,
        responseType,
      },
      (error: Auth0Error | null, authResult: Auth0Result) => {
        if (error) {
          reject(error);
        }
        if (authResult) {
          resolve(authResult);
        }
      }
    );
  });

export const getCandidateByUserId = async (
  userId: number
): Promise<CandidateEntity> => {
  const idToken = `Bearer ${localStorage.getItem('id_token')!}`;
  const response = await axios.get(GET_CANDIDATE_BY_USERID(userId), {
    headers: {
      Authorization: idToken,
    },
  });

  if (response.status !== 200) {
    throw Error(response.data);
  }

  return response.data;
};

export const setFirstLoginStatusFalse = async (): Promise<string> => {
  const response = await axios.patch(
    UPDATE_FIRST_LOGIN(),
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      },
    }
  );

  if (response.status !== 200) {
    throw Error('First Login update fail');
  }

  return response.data;
};

export const getEmployerByUserId = async (
  userId: number
): Promise<EmployerDto> => {
  const response = await axios.get(GET_EMPLOYER_BY_USERID(userId), {
    headers: { Authorization: `Bearer ${localStorage.getItem('id_token')}` },
  });
  if (response.status !== 200) {
    throw Error(response.data);
  }

  return response.data;
};

export const updateTermOfServiceEmployer = async (
  employerId: number
): Promise<EmployerDto> => {
  const response = await axios.patch(
    UPDATE_EMPLOYER_TERM_OF_SERVICE(employerId),
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('id_token')}` },
    }
  );
  if (response.status !== 200) {
    throw Error(response.data);
  }

  const data = {
    AgreedTermsOfServiceDate: response.data.AgreedTermsOfServiceDate,
    Company: response.data.Company,
    Id: response.data.Id,
  };

  localStorage.setItem('ServerEmployerResponse', JSON.stringify(data));

  return response.data;
};

export const createInternal = async (user: {}): Promise<InternalEntity> => {
  const idToken = `Bearer ${localStorage.getItem('id_token')!}`;
  const response = await axios.post(CREATE_INTERNAL, user, {
    headers: {
      Authorization: idToken,
    },
  });

  if (response.status !== 201) {
    throw Error(response.data);
  }

  return response.data;
};

export const resendConfirmationEmail = async (email: string): Promise<void> => {
  try {
    await axios.get(REQUEST_CONFIRMATION_EMAIL(email));
  } catch (error: any) {
    throw Error(error);
  }
};

export const createEmployer = async (user: {}): Promise<UserEmployer> => {
  const response = await axios.post(CREATE_EMPLOYER, user);

  if (response.status !== 201) {
    throw Error(response.data);
  }
  return response.data;
};

export const getUserByEmail = async (email: string): Promise<UserDto> => {
  try {
    const idToken = `Bearer ${localStorage.getItem('id_token')!}`;
    const response = await axios.get(GET_USER_BY_EMAIL(email), {
      headers: {
        Authorization: idToken,
      },
    });

    return response.data;
  } catch (error: any) {
    throw Error(error);
  }
};

export const checkIfUserExistsByEmail = async (
  email: string
): Promise<boolean> => {
  try {
    // I think we should get an endpoint just to see if the user exists, without bringing all the info
    const getUserResponse = await getUserByEmail(email);
    if (getUserResponse) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getUserWithToken = async (id_token: string): Promise<UserDto> => {
  try {
    const response = await axios.get(GET_USER_WITH_TOKEN(), {
      headers: {
        Authorization: `Bearer ${id_token.toString()}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw Error(error);
  }
};

export const createUser = async (user: {}): Promise<UserCandidate> => {
  const idToken = `Bearer ${localStorage.getItem('id_token')!}`;
  const response = await axios.post(CREATE_USER, user, {
    headers: {
      Authorization: idToken,
    },
  });

  if (response.status !== 201) {
    throw Error(response.data);
  }

  return response.data;
};

export const confirmEmailByToken = async (token: string): Promise<boolean> => {
  try {
    const response = await axios.get(CONFIRM_EMAIL(token));
    return response.data;
  } catch (error: any) {
    throw Error(error);
  }
};

export const duplicatePositionAfterLogin = async (shareReferenceId: string) => {
  const idToken = `Bearer ${localStorage.getItem('id_token')!}`;
  const response = await axios.post(
    DUPLICATE_POSITION_BY_SHARE_ID,
    { ShareReferenceId: shareReferenceId },
    {
      headers: {
        Authorization: idToken,
      },
    }
  );

  if (response.status !== 201) {
    throw Error(response.data);
  }

  return response.data;
};
