import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormTypes } from '../../types/user_info';
import classes from './SkillsEditableContainer.module.css';

interface SkillsReadOnlyProps {
  skill: FormTypes['skills'];
  idx: number;
  skillsLength: number;
  secondary?: boolean;
}
const SkillsReadOnly: FC<SkillsReadOnlyProps> = ({
  skill,
  idx,
  skillsLength,
  secondary = false,
}) => {
  const [t] = useTranslation('global');

  const tagClassName = secondary ? classes.TagSecondary : classes.Tag;
  const changeLanguage = () => {
    if (skill) {
      switch (skill?.SkillLevel.val) {
        case 'Novice':
          return t('skills.novice');
        case 'Advanced Beginner':
          return t('skills.advancedBeginner');
        case 'Competent':
          return t('skills.competent');
        case 'Proficient':
          return t('skills.proficient');
        case 'Expert':
          return t('skills.expert');
        default:
          break;
      }
    }
    return null;
  };
  return (
    <>
      <section data-testid="skills-readonly" className={classes.SkillContainer}>
        <div>
          <p
            data-testid="skills-readonly-title"
            className={classes.skillsTitle}
          >
            {skill?.Skill?.Title}
          </p>
        </div>
        <div className={tagClassName}>{changeLanguage()}</div>
      </section>
      {idx !== skillsLength - 1 && <div className={classes.divider} />}
    </>
  );
};
SkillsReadOnly.defaultProps = {
  secondary: false,
};
export default SkillsReadOnly;
