import { useEffect, useState } from 'react';
import useWindowSize from '$hooks/useWindowSize';
import { ReactComponent as LogoBlue } from '$icons/SimeraLogoBlue.svg';
import { ReactComponent as SimeraLogoNoTexto } from '$icons/SimeraLogoNoTexto.svg';

const HeaderDefault = () => {
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (windowSize && windowSize.width) {
      setIsMobile(windowSize.width < 768);
    }
  }, [windowSize, setIsMobile]);
  return (
    <div className="flex justify-between items-center bg-white h-20 px-4 md:px-8">
      {isMobile ? <SimeraLogoNoTexto /> : <LogoBlue />}{' '}
    </div>
  );
};

export default HeaderDefault;
