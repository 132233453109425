import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './ProgressModal.module.css';
import ProgressState from '../../../types/ProgressState';
import { ReactComponent as CloseIcon } from '../../../icons/CloseBlue.svg';
import { ReactComponent as Arrow } from '../../../icons/ArrowRight.svg';
import {
  useGetCandidateInfoTokenQuery,
  useUpdateCandidateCompletedMutation,
} from '../../../store/modules/candidates';
import { segmentTrack } from '../../../utils/handleSegment';
import { useAppSelector } from '../../../hooks';

interface ProgressModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  progressState: ProgressState;
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
  check: boolean;
  candidateId: number;
  setIsOpenSplash: React.Dispatch<React.SetStateAction<boolean>>;
}
const ProgressModal = ({
  isOpen,
  setIsOpen,
  progressState,
  setReady,
  check,
  candidateId,
  setIsOpenSplash,
}: ProgressModalProps) => {
  const { serverUser } = useAppSelector((state) => state.auth);
  Modal.setAppElement(document.getElementById('root') as HTMLElement);
  const navigate = useNavigate();
  const [t, i18next] = useTranslation('global');

  function waitForScrollEnd(): Promise<void> {
    let lastChangedFrame = 0;
    let lastY = window.scrollY;

    return new Promise((resolve) => {
      function tick(frames: number) {
        if (frames >= 500 || frames - lastChangedFrame > 4) {
          resolve();
        } else {
          if (window.scrollY !== lastY) {
            lastChangedFrame = frames;
            lastY = window.scrollY;
          }
          requestAnimationFrame(tick.bind(null, frames + 1));
        }
      }
      tick(0);
    });
  }
  const replaceSpaces = (string: string) => {
    return string.replace(/ /g, '+');
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const { to, handleSection, section, updateLinkClicked } = progressState;

  const { data: tokenData } = useGetCandidateInfoTokenQuery();

  const handlerClick = async () => {
    setIsOpen(false);
    if (to) {
      segmentTrack(
        'Click on link',
        {
          link: to,
          title: progressState.title,
          candidate_id: candidateId,
        },
        ['role']
      );
      navigate(to);
    } else if (handleSection !== undefined && updateLinkClicked !== undefined) {
      segmentTrack(
        'Go to skills',
        {
          title: progressState.title,
          candidate_id: candidateId,
        },
        ['role']
      );
      if (typeof window !== 'undefined') {
        updateLinkClicked(true);
        const element = document.querySelector(`#section-${section}`);

        if (section === 1) {
          window.scrollTo({ top: 0 });
          await waitForScrollEnd();
          updateLinkClicked(false);
        } else if (element) {
          element.scrollIntoView(true);
          await waitForScrollEnd();
          updateLinkClicked(false);
        }
      }
    }
    if (progressState.copy) {
      const text = progressState.copy;
      try {
        const firstName = replaceSpaces(serverUser?.FirstName ?? '');
        const lastName = replaceSpaces(serverUser?.LastName ?? '');
        const email = serverUser?.Email ?? '';
        let link = text.replace('*first_name*', firstName);
        link = link.replace('*last_name*', lastName);
        link = link.replace('*email*', email);
        await navigator.clipboard.writeText(link);
        toast(t('onboardingModalCandidate.alertCopySuccess'), {
          type: 'success',
          hideProgressBar: false,
        });
      } catch (err) {
        toast(t('onboardingModalCandidate.alertCopyError'), {
          type: 'error',
          hideProgressBar: false,
        });
      }
      segmentTrack(
        'Copying reference link',
        {
          link: progressState.copy,
          candidate_id: candidateId,
        },
        ['role']
      );
    }
    if (section === 10) {
      window.open(
        `https://assessments.simera.io/-NywQ5vJzu7d/tests/29/start?assign_by=-NxyurvTK9&profile=1717512699377&assign=1720818976&p=eyJpbWdfaGVhZGVyIjoiaHR0cHM6Ly9jZG4uaGlyZXguYXBwL3BhcnRuZXJzJTJGc2ltZXJhLndlYnAifQ%3D%3D&lang=en&token=${tokenData?.token}`,
        '_blank'
      );
      segmentTrack(
        'Go to video',
        {
          title: progressState.title,
          candidate_id: candidateId,
        },
        ['role']
      );
      setIsOpenSplash(true);
    }
  };
  const [updateCandidateCompleted] = useUpdateCandidateCompletedMutation();

  const handleReady = () => {
    segmentTrack(
      'Ready to interview',
      {
        title: progressState.title,
        candidate_id: candidateId,
      },
      ['role']
    );
    window.open(
      `https://assessments.simera.io/-NywQ5vJzu7d/tests/29/start?assign_by=-NxyurvTK9&profile=1717512699377&assign=1720818976&p=eyJpbWdfaGVhZGVyIjoiaHR0cHM6Ly9jZG4uaGlyZXguYXBwL3BhcnRuZXJzJTJGc2ltZXJhLndlYnAifQ%3D%3D&lang=en&token=${tokenData?.token}`,
      '_blank'
    );
    if (setReady) {
      setReady(true);
      updateCandidateCompleted({
        candidateId,
        lang: i18next.language as 'en' | 'es',
      });
    }
  };

  const handleGameTest = () => {
    segmentTrack('Game Test', {
      title: progressState.title,
      candidate_id: candidateId,
    });
    window.open(`https://game.simera.io?token=${tokenData?.token}`, '_blank');
    if (setReady) {
      setReady(true);
      updateCandidateCompleted({
        candidateId,
        lang: i18next.language as 'en' | 'es',
      });
    }
  };

  return (
    <Modal
      className={`${classes.modal} max-w-xl min-h-64 justify-between gap-4`}
      overlayClassName={classes.overlay}
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
    >
      <div className={classes.titleSection}>
        <h1 className={classes.title}>
          {t(`candidateProgressBarModal.${progressState.property}.title`)}
        </h1>
        <div className={classes.closeIcon} onClick={handleClose} aria-hidden>
          <CloseIcon />
        </div>
      </div>

      <div>
        {progressState.property === 'CompleteAssessments' && (
          <p className="font-semibold">
            {t(
              `candidateProgressBarModal.${progressState.property}.DescriptionTitle`
            )}
          </p>
        )}
        <p className={classes.description}>
          {t(`candidateProgressBarModal.${progressState.property}.Description`)}
        </p>
      </div>
      <div className={classes.buttonsSection}>
        <button className={classes.cancel} type="button" onClick={handleClose}>
          {t('common.cancel')}
        </button>
        {progressState.property === 'CompleteAssessments' && check ? (
          <button className={classes.link} type="button" onClick={handleReady}>
            {t('candidateProgressBarModal.CompleteAssessments.Link')}
            <Arrow />
          </button>
        ) : (
          <button className={classes.link} type="button" onClick={handlerClick}>
            {t(`candidateProgressBarModal.${progressState.property}.Link`)}
            <Arrow />
          </button>
        )}
      </div>
      {progressState.property === 'CompleteAssessments' && (
        <>
          <div>
            <p className="font-semibold">
              {t(
                `candidateProgressBarModal.${progressState.property}.DescriptionTitle2`
              )}
            </p>
            <p className={classes.description}>
              {t(
                `candidateProgressBarModal.${progressState.property}.Description2`
              )}
            </p>
          </div>
          <div className={classes.buttonsSection}>
            <button
              className={classes.cancel}
              type="button"
              onClick={handleClose}
            >
              {t('common.cancel')}
            </button>
            <button
              className={classes.link}
              type="button"
              onClick={handleGameTest}
            >
              {t('candidateProgressBarModal.CompleteAssessments.Link2')}
              <Arrow />
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ProgressModal;
