import { createApi } from '@reduxjs/toolkit/query/react';
import { ReducerPaths } from '../../constants/reducerPaths';
import { baseQuery } from '../../utils/baseQuery';
import { addSchedulesByCps } from './mutations';
import { getSchedulesByCps } from './queries';

export const candidateScheduleApi = createApi({
  reducerPath: ReducerPaths.CANDIDATE_SCHEDULE,
  tagTypes: ['candidatesSchedule'],
  baseQuery,
  endpoints: (builder) => ({
    addSchedulesByCps: addSchedulesByCps(builder),
    getSchedulesByCps: getSchedulesByCps(builder),
  }),
});

export const { useAddSchedulesByCpsMutation, useGetSchedulesByCpsQuery } =
  candidateScheduleApi;
